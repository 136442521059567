/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../../PatientMaintenance/PatientMaintenance.css";
import "../../DiagnosisMaintenance/DiagnosisMaintenance.css";
import TextForm from "../../DiagnosisMaintenance/TextForm";
import Help from "../../../../UI/Help";
import * as Global from "../../../../../store/Global";
import Draggable from 'react-draggable';
import * as TextPopup from "../../TitleOfTextPopup";

class Clinical56Maint extends Component {
    state = {
        //activeTab: "1",
        cAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'clinicalForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty clinicalForm back to what it was on load
        initialClinicalForm: null,
        clinicalForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "wbc",
        isHelpVisible: false,
        isDialogVisible: true,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: flag });
    }

    closeWarningDialog = () => {
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = () => {
        this.setState({ isShowTextVisible: false });
    }

    componentDidMount() {
        if (!this.state.clinicalForm && this.props.selectedClinicalRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.clinicalForm && this.props.selectedClinicalRecord)
            || (prevProps.selectedClinicalRecord && prevProps.selectedClinicalRecord !== this.props.selectedClinicalRecord)
            || (this.props.initialAbstractPages && this.props.selectedClinicalRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.clinicalForm && this.state.cAllItemFields
        ) {
            console.log("[Clinical] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    cAllItemFields: { ...this.state.cAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    clinicalForm: { ...this.state.clinicalForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    isFormDirty: true
                });
            } else {
                this.setState({
                    cAllItemFields: { ...this.state.cAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    clinicalForm: { ...this.state.clinicalForm, [this.props.currentSelectedField + "Name"]: "" },
                    isFormDirty: true
                });
            }
            this.props.clearCommonLookupItem();
        }

        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;
                console.log("[Clinical] = componentDidUpdate maxlenght");
                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem && maxLengthItem[obj[0]] !== undefined ? (maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1])) : null;
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Clinical", accessToken: localStorage.getItem('token')};

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

            let newAccessToken = res && res.data ? res.data.accessToken : null;
            if (newAccessToken) {
                localStorage.setItem('token', newAccessToken);
            };

            this.setState({
                hasSetMaxLength: true,
                tableStructureList: res ? res.data.result : null
            });
    }

    setFormData = () => {
        this.setState({
            clinicalForm: { ...this.props.selectedClinicalRecord },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.cAllItemFields && this.props.selectedClinicalRecord) {
            let fields = {};
            Object.keys(this.props.selectedClinicalRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ cAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                cAllItemFields: {
                    ...this.state.cAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                },
                isFormDirty: true
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            cAllItemFields: {
                                ...this.state.cAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            },
                            isFormDirty: true
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveClinical = (event) => {
        event.preventDefault();

        let isValid = true;
        let formItems = this.state.cAllItemFields;
        const allowSaveAnyway = this.state.allowSaveAnyway;
        
        Object.keys(formItems).map((t) => {
            if (t === "noStateDate" ) {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let clinicalFormData = this.state.clinicalForm;
            clinicalFormData.cid = this.props.selectedMedicalRecord.cid;

            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(clinicalFormData).map((t, k) => {
                if (t === "noStateDate" ) {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(clinicalFormData, t);
                }
            });

            if (!isValidDateInput && !allowSaveAnyway) return false;

            this.props.saveClinical(clinicalFormData);

            return false;
        }
    }

    resetClinical = () => {
        this.setState({
            clinicalForm: { ...this.state.initialClinicalForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

        //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ cAllItemFields: { ...this.state.cAllItemFields, [event.target.name]: "IN_VALID" }, isFormDirty: true });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ cAllItemFields: { ...this.state.cAllItemFields, [event.target.name]: "IN_VALID" }, isFormDirty: true });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                this.setState({ cAllItemFields: { ...this.state.cAllItemFields, [event.target.name]: "IN_VALID" }, isFormDirty: true });
            } else {
                // clear all error style
                this.setState({ cAllItemFields: { ...this.state.cAllItemFields, [event.target.name]: "" }, isFormDirty: true });
            }
        //#20210125 - END

        } else {
            this.setState({ cAllItemFields: { ...this.state.cAllItemFields, [event.target.name]: "" }, isFormDirty: true });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ clinicalForm: { ...this.state.clinicalForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ clinicalForm: { ...this.state.clinicalForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        const clinicalSiteMaint = this.state.clinicalForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveClinical} id="clinicalForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp} name="btnHelp">
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-6 Clin56Miant1">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginRight: "8px" }}>
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus} autoFocus
                                                    changed={this.handleFormChange} field="wbc"
                                                    value={this.state.clinicalForm.wbc} labelText="Wbc"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="205"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["wbc"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="hgb"
                                                    value={this.state.clinicalForm.hgb} labelText="Hgb"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="216"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hgb"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="hct"
                                                    value={this.state.clinicalForm.hct} labelText="Hct"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="217"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hct"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="plt"
                                                    value={this.state.clinicalForm.plt} labelText="Plt"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="206"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["plt"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="bun"
                                                    value={this.state.clinicalForm.bun} labelText="Bun"
                                                    labelWidth={Global.LabelWidth} width="65px" itemNbr="245"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["bun"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="creat"
                                                    value={this.state.clinicalForm.creat} labelText="Creat"
                                                    labelWidth={Global.LabelWidth} width="80px" itemNbr="274"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["creat"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ldh"
                                                    value={this.state.clinicalForm.ldh} labelText="Ldh"
                                                    labelWidth={Global.LabelWidth} width="80px" itemNbr="233"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ldh"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="sgot"
                                                    value={this.state.clinicalForm.sgot} labelText="Sgot"
                                                    labelWidth={Global.LabelWidth} width="80px" itemNbr="246"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sgot"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="alkPhos"
                                                    value={this.state.clinicalForm.alkPhos} labelText="Alk Phos"
                                                    labelWidth={Global.LabelWidth} width="65px" itemNbr="207"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["alkPhos"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="cea"
                                                    value={this.state.clinicalForm.cea} labelText="Cea"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="283"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cea"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="calcium"
                                                    value={this.state.clinicalForm.calcium} labelText="Calcium"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="301"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["clacium"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="albumin"
                                                    value={this.state.clinicalForm.albumin} labelText="Albumin"
                                                    labelWidth={Global.LabelWidth} width={Global.TextWidthEmptyDefault} itemNbr="284"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["albumin"] : ""}
                                                />
                                            </div>
                                            <div style={{ marginRight: "16px" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="urinalysis"
                                                    value={this.state.clinicalForm.urinalysis} labelText="Urinalysis"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="285" titleDialog="Urinalysis" aliasNo="285"
                                                    inValid={this.state.cAllItemFields["urinalysis"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["urinalysis"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="cxr"
                                                    value={this.state.clinicalForm.cxr} labelText="Chest Xray"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="265" titleDialog="Cxr" aliasNo="265"
                                                    inValid={this.state.cAllItemFields["cxr"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cxr"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="liverSplScan"
                                                    value={this.state.clinicalForm.liverSplScan} labelText="Liver Spl Scan"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="234" titleDialog="Liver Spl Scan" aliasNo="234"
                                                    inValid={this.state.cAllItemFields["liverSplScan"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["liverSplScan"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="boneScan"
                                                    value={this.state.clinicalForm.boneScan} labelText="Bone Scan"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="208" titleDialog="Bone Scan" aliasNo="208"
                                                    inValid={this.state.cAllItemFields["boneScan"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["boneScan"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="mriHead"
                                                    value={this.state.clinicalForm.mriHead} labelText="Mri Head"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="209" titleDialog="Mri Head" aliasNo="209"
                                                    inValid={this.state.cAllItemFields["mriHead"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mriHead"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="mriChest"
                                                    value={this.state.clinicalForm.mriChest} labelText="Mri Chest"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="225" titleDialog="Mri Chest" aliasNo="225"
                                                    inValid={this.state.cAllItemFields["mriChest"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mriChest"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="mriAbd"
                                                    value={this.state.clinicalForm.mriAbd} labelText="Mri Abd"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="218" titleDialog="Mri Abd" aliasNo="218"
                                                    inValid={this.state.cAllItemFields["mriAbd"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mriAbd"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="mriPelvis"
                                                    value={this.state.clinicalForm.mriPelvis} labelText="Mri Pelvis"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="266" titleDialog="Mri Pelvis" aliasNo="266"
                                                    inValid={this.state.cAllItemFields["mriPelvis"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mriPelvis"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ctHead"
                                                    value={this.state.clinicalForm.ctHead} labelText="Ct Head"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="282" titleDialog="Ct Head" aliasNo="282"
                                                    inValid={this.state.cAllItemFields["ctHead"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ctHead"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ctChest"
                                                    value={this.state.clinicalForm.ctChest} labelText="Ct Chest"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="235" titleDialog="Ct Chest" aliasNo="235"
                                                    inValid={this.state.cAllItemFields["ctChest"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ctChest"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ctAbd"
                                                    value={this.state.clinicalForm.ctAbd} labelText="Ct Abd"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="256" titleDialog="Ct Abd" aliasNo="256"
                                                    inValid={this.state.cAllItemFields["ctAbd"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ctAbd"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ctPelvis"
                                                    value={this.state.clinicalForm.ctPelvis} labelText="Ct Pelvis"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="266" titleDialog="Ct Pelvis" aliasNo="266"
                                                    inValid={this.state.cAllItemFields["ctPelvis"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ctPelvis"] : ""}
                                                />
                                            </div>
                                            <div>
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="marrowBx"
                                                    value={this.state.clinicalForm.marrowBx} labelText="Marrow Bx"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="210" titleDialog="Marrow Bx" aliasNo="210"
                                                    inValid={this.state.cAllItemFields["marrowBx"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["marrowBx"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="marrowTransplant"
                                                    value={this.state.clinicalForm.marrowTransplant} labelText="Marrow Xplant"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="388" titleDialog="Marrow Xplant" aliasNo="388"
                                                    inValid={this.state.cAllItemFields["marrowTransplant"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["marrowTransplant"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="height"
                                                    value={this.state.clinicalForm.height} labelText="Height"
                                                    labelWidth={Global.LabelWidth} width="65px" itemNbr="275"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["height"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="weight"
                                                    value={this.state.clinicalForm.weight} labelText="Weight"
                                                    labelWidth={Global.LabelWidth} width="65px" itemNbr="238"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["weight"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="bsa"
                                                    value={this.state.clinicalForm.bsa} labelText="Bsa"
                                                    labelWidth={Global.LabelWidth} width="80px" itemNbr="248"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["bsa"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="funcStatus"
                                                    value={this.state.clinicalForm.funcStatus} labelText="Func Status"
                                                    labelWidth={Global.LabelWidth} width="40px"
                                                    dataSource="Global_Dir_Mv" itemNbr="247" titleDialog="Func Status" aliasNo="247"
                                                    inValid={this.state.cAllItemFields["funcStatus"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["funcStatus"] : ""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 Clin56Miant2">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginRight: "16px" }}>
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ca125"
                                                    value={this.state.clinicalForm.ca125} labelText="Ca125"
                                                    labelWidth="115px" width="70px" itemNbr="233"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ca125"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="bariumEnema"
                                                    value={this.state.clinicalForm.bariumEnema} labelText="Barium Enema"
                                                    labelWidth="115px" width="30px"
                                                    dataSource="Global_Dir_Mv" itemNbr="221" titleDialog="Barium Enema" aliasNo="221"
                                                    inValid={this.state.cAllItemFields["bariumEnema"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["bariumEnema"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="ivp"
                                                    value={this.state.clinicalForm.ivp} labelText="Ivp"
                                                    labelWidth="115px" width="30px"
                                                    dataSource="Global_Dir_Mv" itemNbr="222" titleDialog="Ivp" aliasNo="222"
                                                    inValid={this.state.cAllItemFields["ivp"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ivp"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="pelvicUltrasound"
                                                    value={this.state.clinicalForm.pelvicUltrasound} labelText="Pelvic Ultrasound"
                                                    labelWidth="115px" width="30px"
                                                    dataSource="Global_Dir_Mv" itemNbr="244" titleDialog="Pelvic Ultrasound" aliasNo="244"
                                                    inValid={this.state.cAllItemFields["pelvicUltrasound"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pelvicUltrasound"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="sigmoidoscopy"
                                                    value={this.state.clinicalForm.sigmoidoscopy} labelText="Sigmoidoscopy"
                                                    labelWidth="115px" width="30px"
                                                    dataSource="Global_Dir_Mv" itemNbr="297" titleDialog="Sigmoidoscopy" aliasNo="297"
                                                    inValid={this.state.cAllItemFields["sigmoidoscopy"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sigmoidoscopy"] : ""}
                                                />
                                            </div>
                                            <div style={{ marginRight: "16px" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="washings"
                                                    value={this.state.clinicalForm.washings} labelText="Washings"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="270" titleDialog="Washings" aliasNo="270"
                                                    inValid={this.state.cAllItemFields["washings"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["washings"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="laparotomy"
                                                    value={this.state.clinicalForm.laparotomy} labelText="Laparotomy"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="224" titleDialog="Laparotomy" aliasNo="224"
                                                    inValid={this.state.cAllItemFields["laparotomy"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["laparotomy"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="laparoscopy"
                                                    value={this.state.clinicalForm.laparoscopy} labelText="Laparoscopy"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="271" titleDialog="Laparoscopy" aliasNo="271"
                                                    inValid={this.state.cAllItemFields["laparoscopy"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["laparoscopy"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="omentectomy"
                                                    value={this.state.clinicalForm.omentectomy} labelText="Omentectomy"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="262" titleDialog="Omentectomy" aliasNo="262"
                                                    inValid={this.state.cAllItemFields["omentectomy"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["omentectomy"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} field="debulking"
                                                    value={this.state.clinicalForm.debulking} labelText="Debulking"
                                                    labelWidth={Global.LabelWidth} width="25px"
                                                    dataSource="Global_Dir_Mv" itemNbr="292" titleDialog="Debulking" aliasNo="292"
                                                    inValid={this.state.cAllItemFields["debulking"]} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["debulking"] : ""}
                                                />
                                            </div>
                                            <div>
                                                <fieldset>
                                                    <legend>Clinical Indicators</legend>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="histoType"
                                                        value={this.state.clinicalForm.histoType} labelText="Histo Type"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="460" titleDialog="Histo Type" aliasNo="460"
                                                        inValid={this.state.cAllItemFields["histoType"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["histoType"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="tumorSize"
                                                        value={this.state.clinicalForm.tumorSize} labelText="Tumor Size"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="461" titleDialog="Tumor Size" aliasNo="461"
                                                        inValid={this.state.cAllItemFields["tumorSize"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSize"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="statusMargins"
                                                        value={this.state.clinicalForm.statusMargins} labelText="Status Margins"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="462" titleDialog="Status Margins" aliasNo="462"
                                                        inValid={this.state.cAllItemFields["statusMargins"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["statusMargins"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="lnExam"
                                                        value={this.state.clinicalForm.lnExam} labelText="Ln Exam"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="463" titleDialog="Ln Exam" aliasNo="463"
                                                        inValid={this.state.cAllItemFields["lnExam"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lnExam"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="invExtn"
                                                        value={this.state.clinicalForm.invExtn} labelText="Inv Extn"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="464" titleDialog="Inv Extn" aliasNo="464"
                                                        inValid={this.state.cAllItemFields["invExtn"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["invExtn"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="pathAjcc"
                                                        value={this.state.clinicalForm.pathAjcc} labelText="Path Ajcc"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="465" titleDialog="Path Ajcc" aliasNo="465"
                                                        inValid={this.state.cAllItemFields["pathAjcc"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pathAjcc"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} field="phyAjcc"
                                                        value={this.state.clinicalForm.phyAjcc} labelText="Phy Ajcc"
                                                        labelWidth={Global.LabelWidth} width="25px"
                                                        dataSource="Global_Dir_Mv" itemNbr="466" titleDialog="Phy Ajcc" aliasNo="466"
                                                        inValid={this.state.cAllItemFields["phyAjcc"]} 
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["phyAjcc"] : ""}
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetClinical} formId="clinicalForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        extraAction={this.extraAction}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">Site Specific Clinical data loading...</p>;
        return (
            <React.Fragment>

                <React.Fragment>
                    {clinicalSiteMaint}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

Clinical56Maint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedClinicalRecord: state.patientAbstract.selectedClinicalRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        selectedHospital: state.patientAbstract.selectedHospital,
        //currentShowingState: state.patientAbstract.currentShowingState,
        
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,

        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveClinical: (formData) => dispatch(actionCreators.saveClinical(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),

        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clinical56Maint);