/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import "./UserMaintenance.css";
//import PropTypes from "prop-types";
//import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
//import { Dialog } from "@progress/kendo-react-dialogs";
import "../../../components/AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance.css";
import Help from "../../UI/Help";
import axios from "../../../axios-instance";
import * as dialogTypes from "../../../components/AbstractManagement/maintDialogs";
import * as actions from '../../../store/actions/index';
import Draggable from 'react-draggable';

class TwoFactorAuthDialog extends Component {
    state = {
        uAllItemFields: [],
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        loaded: false,
        fieldInFocus: "securityCode",
        isHelpVisible: false,
        hasErrorOccurred: false,
        showQrCodeImg: false,
        scannedQrCode: false,
    };

    hideErrorMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ hasErrorOccurred: false });

        this.props.setChildrenOpening(false);//clear this flag when confirm message is closed

        setTimeout(function () {
            document.getElementById("securityCode") ? document.getElementById("securityCode").focus() : "";
        }, 100);

        return false;
    }

    componentDidMount() {
        console.log('[componentDidMount] Drawing TwoFactorAuthDialog...', this.props.key2FA);
        if (!this.state.dialogForm && !this.state.loaded) {
            let url = "ers/GetQRCode";
            let params = { userId: this.props.userId, key: this.props.key2FA, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

            axios.post(url, params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ loaded: true, hasErrorOccurred: false });

                        this.props.setChildrenOpening(false);

                        this.setFormData(res.data.user2FaRequest);                       
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
        }
    }

    componentDidUpdate(prevProps) {
        console.log('[componentDidUpdate] Drawing TwoFactorAuthDialog...', this.props.key2FA);
        if (!this.state.dialogForm && !this.state.loaded) {
            let url = "ers/GetQRCode";
            let params = { userId: this.props.userId, key: this.props.key2FA, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

            axios.post(url, params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ loaded: true, hasErrorOccurred: false });
                        this.setFormData(res.data.user2FaRequest);
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
        }

        if (prevProps.user2Fa != this.props.user2Fa && this.props.user2Fa != null) {
            console.log('[TwoFactorAuth] user2Fa=', this.props.user2Fa);
            this.setFormData(this.props.user2Fa);
        }

        if (this.state.showQrCodeImg) {
            setTimeout(function () {
                document.getElementById("btnScannedQrCode") ? document.getElementById("btnScannedQrCode").focus() : "";
            }, 1000);
            return false;
        } else if (this.props.tfaConfigTitle && !this.state.hasErrorOccurred) {
            setTimeout(function () {
                document.getElementById("securityCode") ? document.getElementById("securityCode").focus() : "";
            }, 0);
            return false;
        }
    }

    setFormData = (selectedUserInfo) => {
        this.setState({
            initialDialogForm: { ...selectedUserInfo },
            dialogForm: { ...selectedUserInfo }
        });

        if (!this.state.uAllItemFields && selectedUserInfo) {
            let fields = {};
            Object.keys(selectedUserInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields });
        };

        if (selectedUserInfo && selectedUserInfo["verified"] === "True") {
            this.props.setTwoFactorAuthDialogVisible(false);

            // first close all current existing tabs
            //this.props.closeOtherTabItems();
            this.props.setPatientMenu(true);
            this.props.setPageLoaded(false);

        } else if (selectedUserInfo && selectedUserInfo["verified"] === "Failed") {
            // show error message
            this.setState({ hasErrorOccurred: true });

            this.props.setChildrenOpening(true);//not allow parent popup is draggable

            // set focus into OK button
            setTimeout(function () {
                document.getElementById("2fa_validationErr_btnOk_Cls") ? document.getElementById("2fa_validationErr_btnOk_Cls").focus() : "";
            }, 500);

            return false;
        }

        if (selectedUserInfo && selectedUserInfo["mfaQrcUrl"] != ""
            && this.props.lastAuth == null && !this.state.showQrCodeImg
            && this.props.tfaPopupTitle != "") {
            this.setState({ showQrCodeImg: true });
        } else if (this.state.scannedQrCode) {
            this.setState({ showQrCodeImg: false });
            this.props.setTfaPopupTitle("", null);
        }

        return false;
    }

    resetDataForm = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            loaded: false
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }, isFormDirty: true });
        }
        return false;
    }

    closeTwoFactorAuthPopup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.setTwoFactorAuthDialogVisible(false);
        return false;
    }

    ignoreTfaConfig = (event) => {
        event.preventDefault();
        this.props.setTab(dialogTypes.LOGIN);
        this.props.onLogout(this.props.userId);
        return false;
    }

    scannedQrCode = (event) => {
        event.preventDefault();
        this.props.setTfaPopupTitle("", null);
        this.setState({ showQrCodeImg: false, scannedQrCode: true });
        return false;
    }

    confirmSecurityCode = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;

        // TODO - check form validation
        let isValid = true;

        Object.keys(dialogFormData).map((t, k) => {
            if ((t === "securityCode" || t === "SecurityCode" || t === "securitycode")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
        });

        const formItems = this.state.uAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid) {
            return false;
        } else {
            // to make sure not show image qrcode form
            dialogFormData.mfaQrcUrl = "";
            this.setState({ showQrCodeImg: false });

            this.props.confirmSecurityCode(dialogFormData);
        }
    }

    render() {
        let hasErrorOccurred;
        {
            hasErrorOccurred = this.state.hasErrorOccurred ?
                /*
                <Dialog title="Two Factor Code Error"
                    onClose={this.hideErrorMsg}
                    width={450} height={"auto"}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallDialogCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideErrorMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Security Code is incorrect</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button id="2fa_validationErr_btnOk_Cls" className="validationErr_btnOk_Cls" onClick={this.hideErrorMsg} autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}
                    <form onSubmit={this.confirmSecurityCode} id="securityTwoFactorAuthForm" className="DialogForm2x">
                        <div className="emptyLine"></div>
                        {
                            this.state.dialogForm.mfaQrcUrl != "" && this.props.lastAuth == null && this.state.showQrCodeImg
                                ?
                                (
                                    <div>
                                        <div className="row">
                                            <div className={"col-sm-12" + (this.state.dialogForm.mfaQrcUrl != "" && this.props.lastAuth == null ? "" : " hidden")}>
                                                <div>
                                                    <p>2-Factor Authentication is enabled. If you have not configured Google Authenticator for CRStar-{this.props.userId}, please do so before continuing.</p>
                                                    <p>1. In Google Authenticator, select Scan a Quick Response Code</p>
                                                    <p>2. Use your phone's camere to scan this Quick Response Code</p>
                                                    <p>
                                                        <label style={{
                                                            backgroundImage: "url('" + this.state.dialogForm.mfaQrcUrl + "')",
                                                            backgroundSize: "150px",
                                                            width: "150px",
                                                            height: "150px",
                                                        }}
                                                        >
                                                            <image id="qr-code-2fa" alt="QR code" src={this.state.dialogForm.mfaQrcUrl} />
                                                        </label>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 center btnBottom">
                                                <button type="button" id="btnScannedQrCode" name="btnScannedQrCode" value="yes" onClick={this.scannedQrCode} autoFocus>OK</button>
                                                <button type="button" id="btnIgnoreTfaConfig" name="btnIgnoreTfaConfig" value="no" onClick={(e) => this.ignoreTfaConfig(e)}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div id="securityCodeInputForm">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Input type="input" changed={this.handleFormChange}
                                                    focused={this.handleOnFocus} autoFocus
                                                    labelText="Security Code" field="securityCode"
                                                    value={this.state.dialogForm.securityCode}
                                                    isRequired labelWidth="120px"
                                                    inValid={this.state.uAllItemFields["securityCode"]}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 center btnBottom">
                                                <button type="submit" id="btnScannedQrCode" value="yes">OK</button>
                                                <button type="button" id="btnIgnoreTfaConfig" value="no" onClick={(e) => this.ignoreTfaConfig(e)}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </form>
                </div>
            )
            : <p>Loading...</p>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {hasErrorOccurred}
                </React.Fragment>

                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

TwoFactorAuthDialog.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired,
    //email: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        isDialogLoading: state.patientAbstract.isDialogLoading,
        user2Fa: state.patientAbstract.user2Fa,
        tfaPopupTitle: state.patientAbstract.tfaPopupTitle,
        tfaConfigTitle: state.patientAbstract.tfaConfigTitle,

        /* Duplicated setting at Layout.js
        //lastAuth: state.auth.lastAuth,
        //lastResult: state.auth.lastResult,
        //key2FA: state.auth.key2FA,
        //userId: state.auth.userId,
        */

        tabItems: state.patientAbstract.tabItems,
        tabStatuses: state.patientAbstract.tabStatuses,
        //
        clientgroup: state.auth.clientgroup,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        confirmSecurityCode: (dialogForm) => dispatch(actionCreators.confirmSecurityCode(dialogForm)),
        setTwoFactorAuthDialogVisible: (flg) => dispatch(actionCreators.setTwoFactorAuthDialogVisible(flg)),
        //
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        onLogout: (uid) => dispatch(actions.logout(uid)),
        setTfaPopupTitle: (title, form) => dispatch(actionCreators.setTfaPopupTitle(title, form)),
        //
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setCurrentDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (flg) => dispatch(actionCreators.setChildrenOpening(flg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthDialog);