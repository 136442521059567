/* eslint-disable */
import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
//import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Help from "../../UI/Help";
import Draggable from 'react-draggable';
//import ConfigData from '../../../store/config.json';
import * as actions from '../../../store/actions/index';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import "./DBValidation.css";

class DBValidation extends Component {

    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "validationType",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        changed: false,

        showClockTimer: false,
        titleDialog: null,

        showMessageError: null,
        isDrawingCharts: false,
        htmlOutput: null,
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);
        
        // Add all tabs in maintDialogs.js
        dialogTypes.sysAdmUtilitiesTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        this.setState({ isLoading: true });

        //#4: get data from the props... had assigned at the store
        if (!this.state.dialogForm && this.props.databaseValidationInfo) {
            this.setFormData(this.props.databaseValidationInfo);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                },
                errorMessage: this.props.errorMessage,
            });

            this.props.clearErrorMessage();
        }

        //#4: get data from the props... had assigned at the store
        if (!this.state.dialogForm && this.props.databaseValidationInfo) {
            this.setFormData(this.props.databaseValidationInfo);
        }

        if (this.state.dialogForm && (prevProps.databaseValidationInfo && this.props.databaseValidationInfo && (prevProps.databaseValidationInfo.startTime !== this.props.databaseValidationInfo.startTime
            || prevProps.databaseValidationInfo.endTime !== this.props.databaseValidationInfo.endTime))
            && (this.props.databaseValidationInfo.htmlOutput || this.props.databaseValidationInfo.urlOutputFile)) {

            clearInterval(this.sInVal);
            const databaseValidationInfo = this.props.databaseValidationInfo;

            if (databaseValidationInfo && databaseValidationInfo.outputType == "Screen" && databaseValidationInfo.htmlOutput)
                this.drawingCharts(databaseValidationInfo);
            else if (databaseValidationInfo && databaseValidationInfo.outputType == "Excel" && databaseValidationInfo.urlOutputFile && !this.downloadFile) {
                this.setState({ showClockTimer: false, endTime: new Date().toLocaleTimeString() });
                var _this = this;
                var url = this.props.clientgroup + "/DownloadFile";
                axios.post(url, {
                    urlOutputFile: databaseValidationInfo.urlOutputFile,
                    method: 'POST',
                    responseType: 'blob',//important
                    accessToken: localStorage.getItem('token')
                }).then((response) => {
                    // get file name from url
                    var filename = databaseValidationInfo.urlOutputFile.substring(databaseValidationInfo.urlOutputFile.lastIndexOf('\\') + 1);
                    console.log("filename=" + filename);

                    const link = document.createElement('a');

                    let excel;
                    if (databaseValidationInfo.urlOutputFile.indexOf('/') != -1) {
                        excel = databaseValidationInfo.urlOutputFile.substring(databaseValidationInfo.urlOutputFile.lastIndexOf('/') + 1);
                    } else {
                        excel = databaseValidationInfo.urlOutputFile.substring(databaseValidationInfo.urlOutputFile.lastIndexOf('\\') + 1);
                    }
                    link.href = this.props.clientgroup + "/" + excel;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    _this.downloadFile = true;
                    return false;
                })
                .catch(function (error) {
                    console.log(error);
                });

                return false;
            }
        }
    }

    setFormData = (databaseValidationInfo) => {
        this.setState({
            initialDialogForm: { ...databaseValidationInfo },
            dialogForm: { ...databaseValidationInfo },
            ["validationType"]: databaseValidationInfo && databaseValidationInfo.validationType ? databaseValidationInfo.validationType : "NameDob",
            ["outputType"]: databaseValidationInfo && databaseValidationInfo.outputType ? databaseValidationInfo.outputType : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
            reportStatus: "Not Running", 
        });
        this.newRecord();
        return false;
        
    }

    setValidationValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            validationType: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }

    setOutputValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            outputType: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }

    cancelReport = (e, _this) => {
        e.preventDefault();
        setTimeout(function () {
            _this.setState({
                showClockTimer: false,
                reload: false,
            });
            return false;
        }, 0);
        clearInterval(this.sInVal);
        return false;
    }

    drawingCharts = (data) => {
        console.log("Chart start")
        this.setState({
            showClockTimer: false,
            htmlOutput: data.htmlOutput,
            isDrawingCharts: true,
            reload: false,
            endTime: new Date().toLocaleTimeString(),
        });
        return false;
    }

    hideDrawingCharts = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
        dialogFormData.htmlOutput = null;

        this.setState({
            isDrawingCharts: false,
            reload: false,
            dialogForm: dialogFormData,
        });
        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({
            validationType: "NameDob",
            outputType: "Screen",
            isFormDirty: true,
            reload: false,
            startTime: null,
            endTime: null,
            currentTime: null,
        })
        return false;
    }

    showEvent(e) {
        try {e.preventDefault();} catch (ex) { }

        var _this = this;
        setTimeout(function () {
            console.log("Running Database Validation ...");
            _this.callDatabaseValidaiton();
            _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString() }); // set clock timer...
            return false;
        }, 0);

        this.sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.sInVal);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);
        
        return false;
    }

    callDatabaseValidaiton = () => {
        let dialogFormData = this.state.dialogForm;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.validationType = this.state.validationType;
        dialogFormData.outputType = this.state.outputType;
        
        dialogFormData.urlOutputFile = "";
        dialogFormData.htmlOutput = "";

        this.props.runDatabaseValidation(dialogFormData);
        return false;
    }

    hideMessageError = (e) => {
        try {e.preventDefault();} catch (ex) { };

        this.setState({
            showMessageError: false,
            hasError: false,
        });
        this.props.setChildrenOpening(false);
        return false;
    }

    printReport = (e) => {
        var printStyle = document.getElementById('PrintStyle');
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Database Validation List</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render () {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.props.errorMessage ? this.props.errorMessage : this.state.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.cancelReport(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
            : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts && this.state.outputType == "Screen" ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Database Validation List</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="databaseValidationShowingCharts">
                                {/*Drawing table here*/}
                                <div dangerouslySetInnerHTML={{ __html: this.state.htmlOutput ? this.state.htmlOutput : "" }} className="" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.props.initialPageVisible ?
        (
            <div className="container-fluid2">
                {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                <form id="DBValidationForm" className="CRStar_Window">
                    <div className="emptyLine"></div>

                    <div className="row">
                        <div className="col-md-7" style={{ height: "400px"}}>
                            <div style={{ display: "flex" }}>
                                <div id="validationTypeLabel">Validation Type:</div>
                                <div id="outputTypeLabel">Send Report to:</div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className="validationTypeRadio" style={{ display: "grid"}}>
                                    <label style={{ width: "300px", height: "15px", cursor: "pointer" }}>
                                        <input type="radio" onClick={(e) => {this.setValidationValue(e)}}
                                        checked={this.state.validationType === "NameDob"}
                                        name="validationType" value="NameDob" id="validationType_NameDob"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Check for Duplicate Names and Dates of Birth
                                    </label>
                                    <label style={{ width: "300px", height: "15px", cursor: "pointer" }}>
                                        <input type="radio" onClick={(e) => {this.setValidationValue(e)}}
                                        checked={this.state.validationType === "SSN"}
                                        name="validationType" value="SSN" id="validationType_SSN"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Check for Duplicate Social Security Numbers
                                    </label>
                                </div>
                                <div className="outputTypeRadio" style={{ display: "grid"}}>
                                    <label style={{ width: "300px", height: "15px", cursor: "pointer" }}>
                                        <input type="radio" onClick={(e) => {this.setOutputValue(e)}}
                                        checked={this.state.outputType === "Excel"}
                                        name="outputType" value="Excel" id="outputType_Excel"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Excel
                                    </label>
                                    <label style={{ width: "300px", height: "15px", cursor: "pointer" }}>
                                        <input type="radio" onClick={(e) => {this.setOutputValue(e)}}
                                        checked={this.state.outputType === "Screen"}
                                        name="outputType" value="Screen" id="outputType_Screen"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Screen
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="statusBar" className="reportScreenStatusBar" style={{ display: "flex" }}>
                        <div id="startTime" style={{ width: "400px" }}>Start Time: {this.state.startTime}</div>
                        <div id="endTime">End Time: {this.state.endTime}</div>
                    </div>

                    <fieldset className={this.props.showToggleBtn ? "bottomAreaButtonCls _hidden" : "bottomAreaButtonCls"}>
                        <legend></legend>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="A5CWLayout" style={{ display: this.props.showToggleBtn ? "none" : "" }}>
                                    <button id="BUTTON_DATABASE_VALIDATION"
                                        className={this.state.dialogForm ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                        onClick={(e) => { this.showEvent(e) }}
                                        disabled={this.state.dialogForm ? false : true}
                                    >
                                        <img id="BUTTON_DATABASE_VALIDATION_ICON"
                                            src={this.state.dialogForm ? "https://crstar.ers-can.com/images/$$code.execute.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.execute.png.a5image"}
                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                        Ok
                                    </button>
                                </div>

                                <div className="A5CWLayout" style={{ width: "100px", display: this.props.showToggleBtn ? "none" : "" }}>
                                    <button id="BUTTON_NEWRECORD"
                                        className="ErsGrBlueButton _ScreenButton"
                                        onClick={(e) => { this.newRecord(e) }}
                                    >
                                        <img id="BUTTON_NEWRECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.new.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                        New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        ) 
        : <p className="">Database Validation loading...</p>

        return(
        <React.Fragment>
            <React.Fragment>
                {showMessageError}
            </React.Fragment>

            <React.Fragment>
                {clockJs}
            </React.Fragment>
            
            <React.Fragment>
                {dialogForm}
            </React.Fragment>

            <React.Fragment>
                {drawingCharts}
            </React.Fragment>
        </React.Fragment>
        )
    }
}

DBValidation.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        databaseValidationInfo: state.patientAbstract.databaseValidationInfo,

        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
        runDatabaseValidation: (dialogForm) => dispatch(actionCreators.runDatabaseValidation(dialogForm)),

        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DBValidation);