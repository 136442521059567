/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import "../DataExportsStyle.css";
import "../../SelectAPop/SelectAPop.css";
import * as actions from '../../../../store/actions/index';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";
import ConfigData from '../../../../store/config.json';

class NcdbRcrsExport extends Component {

    downloadFile = false;
    populationLabel = null;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "autoSelect",
        focusingField: null,
        loaded: false,
        getAvailableHosps: false,
        hospitalCodeMsgErr: null,
        dxYearMsgErr: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowConfirmDeleteMsg: false,
        isShowConfirmDeleteLabelMsg: false,
        confirmed: null,
        monitoring: null,
        allowSaveAnyway: true,

        //
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        showMessageError: false,
        selectedAutoSelectSelection: "Auto",
        selectedDxYearsSelection: "2022",
        selectedRqrsNcdbSelection: "2",
        setEnabledRunBtn: false,
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "excludeCases") {
            const checked = this.state.isCheckedExcludeCases;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                isCheckedExcludeCases: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }

    selectionAutoSelect = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        /*
        if (!this.state.mouseOverHandled && dialogFormData["populationLabel"] != "" && value == "Pop") {
            dialogFormData["populationLabel"] = "";
        } else if (!this.state.mouseOverHandled && dialogFormData["populationLabel"] == "" && value == "Auto") {
            var hosp = dialogFormData.hospitalNumber ? dialogFormData.hospitalNumber : "";
            var year = dialogFormData.dxYear ? dialogFormData.dxYear : "";
            var DxYears = dialogFormData.dxYears ? dialogFormData.dxYears : "2021";
            if (DxYears != "Other") { year = "All"; }
            var pop_label;
            if (hosp != "") {
                pop_label = hosp + "-Autoselect-NCDB-" + year;
            } else {
                pop_label = "All-Autoselect-NCDB-" + year;
            }
            dialogFormData.populationLabel = pop_label;
        }
        */

        this.setState({
            selectedAutoSelectSelection: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true
        });
    }
    selectionDxYears = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedDxYearsSelection: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true
        });
    }
    selectionRqrsNcdb = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        var filename = dialogFormData.filename ? dialogFormData.filename : "";
        /*
        if (value == "1" && filename.indexOf("NCDB") != -1) {
            filename = filename.replace("NCDB", "RCRS");
            dialogFormData["filename"] = filename;
        } else if (value == "2" && filename.indexOf("RCRS") != -1) {
            filename = filename.replace("RCRS", "NCDB");
            dialogFormData["filename"] = filename;
        }
        */
        var RCRS_NCDB = value;
        if (filename.substring(0, 4) == 'NCDB' && RCRS_NCDB == '1') {
            filename = 'RCRS' + filename.substring(4, filename.length)
        }
        if (filename.substring(0, 4) == 'RCRS' && RCRS_NCDB == '2') {
            filename = 'NCDB' + filename.substring(4, filename.length)
        }
        dialogFormData["filename"] = filename;

        this.setState({
            selectedRqrsNcdbSelection: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true
        });
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ allowedHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ allowedHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_RUN_REPORT") ? document.querySelector("#BUTTON_RUN_REPORT").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.dataExportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        if (!this.state.dialogForm && this.props.selectedReportLabelsInfo) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        this.setState({ isLoading: true, reload: true });
    }
    componentDidUnmount() {
        this.props.selectReportLabels("");
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, allowedHosps: null });

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                    startTime: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.startTime : "",
                    endTime: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.endTime : "",
                },
                errorMessage: this.props.errorMessage,
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                /*
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
                */
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedReportLabelsInfo)
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.reportLabelId !== this.props.selectedReportLabelsInfo.reportLabelId || prevProps.selectedReportLabelsInfo.reportLabel !== this.props.selectedReportLabelsInfo.reportLabel))
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.startTime !== this.props.selectedReportLabelsInfo.startTime || prevProps.selectedReportLabelsInfo.endTime !== this.props.selectedReportLabelsInfo.endTime))
            || (this.state.reload && this.props.selectedReportLabelsInfo)
        ) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--

        /*
        var runBtn = document.getElementById("BUTTON_RUN_REPORT");
        var dialogFormData = this.state.dialogForm;
        if (!this.state.setEnabledRunBtn && runBtn && dialogFormData) {
            var autoselect = dialogFormData.autoSelect;
            if (autoselect == "Auto" && (!dialogFormData.populationLabel || dialogFormData.populationLabel == "")) {
                var hosp = dialogFormData.hospitalNumber ? dialogFormData.hospitalNumber : "";
                var year = dialogFormData.dxYear ? dialogFormData.dxYear : "";
                var DxYears = dialogFormData.dxYears ? dialogFormData.dxYears : "2021";
                if (DxYears != "Other") { year = "All"; }
                var pop_label;
                if (hosp != "") {
                    pop_label = hosp + "-Autoselect-NCDB-" + year;
                } else {
                    pop_label = "All-Autoselect-NCDB-" + year;
                }
                dialogFormData.populationLabel = pop_label;
                this.setState({ dialogForm: dialogFormData, setEnabledRunBtn: true, mouseOverHandled: false });
            }
        }
        */
    }

    async getTableStructure() {
        var maxLoadNumber = this.state.maxLoadNumber ? this.state.maxLoadNumber : 0;
        if (maxLoadNumber > 1) return false;
        console.log("maxLoadNumber=" + maxLoadNumber);

        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Report_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null,
            maxLoadNumber: maxLoadNumber + 1,
        });
    }

    /*
    drawingHtmlTable(dataForm) {
        this.setState({
            isDrawingHtmlTable: true,
            Fn_Html_Display_URL: dataForm.htmlDisplay,//fnHtmlDisplayUrl
            publicFolder: dataForm.publicFolder,
            showClockTimer: false,//hide clock timer
        });
        return false;
    }
    */

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });
        /*
        //Deleting output file at public folder...
        const fileName = encodeURIComponent(this.state.publicFolder);
        var _this = this;
        axios.get(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/DeleteOutputFile?file=" + fileName)
            .then(res => {
                if (!res.data.error) {
                    _this.setState({ publicFolder: null });
                }
            });
        */
        return false;
    }

    printTable = () => {
        var printStyle = document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : (document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : this.state.printStyle);

        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>' + "NCDB Export" + '</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    setFormData = (selectedReportLabelsInfo) => {
        this.setState({
            initialDialogForm: {
                ...selectedReportLabelsInfo,
                filename: !selectedReportLabelsInfo["filename"] || selectedReportLabelsInfo["filename"] == "" ? "NCDB Export" : selectedReportLabelsInfo["filename"],
            },
            dialogForm: {
                ...selectedReportLabelsInfo,
                filename: !selectedReportLabelsInfo["filename"] || selectedReportLabelsInfo["filename"] == "" ? "NCDB Export" : selectedReportLabelsInfo["filename"],
                autoSelect: !selectedReportLabelsInfo["autoSelect"] || selectedReportLabelsInfo["autoSelect"] == "" ? "Auto" : selectedReportLabelsInfo["autoSelect"],
                dxYears: !selectedReportLabelsInfo["dxYears"] || selectedReportLabelsInfo["dxYears"] == "" ? "2022" : selectedReportLabelsInfo["dxYears"],
                rqrsNcdb: !selectedReportLabelsInfo["rqrsNcdb"] || selectedReportLabelsInfo["rqrsNcdb"] == "" ? "2" : selectedReportLabelsInfo["rqrsNcdb"],
            },
            isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,

            showClockTimer: false,
            titleDialog: null,

            isCheckedExcludeCases: selectedReportLabelsInfo && selectedReportLabelsInfo.excludeCases !== '1' ? true : false,

            ["selectedAutoSelectSelection"]: selectedReportLabelsInfo && selectedReportLabelsInfo.autoSelect ? selectedReportLabelsInfo.autoSelect : "Auto",
            ["selectedDxYearsSelection"]: selectedReportLabelsInfo && selectedReportLabelsInfo.dxYears ? selectedReportLabelsInfo.dxYears : "2022",
            ["selectedRqrsNcdbSelection"]: selectedReportLabelsInfo && selectedReportLabelsInfo.rqrsNcdb ? selectedReportLabelsInfo.rqrsNcdb : "2",
        });

        if ((!this.state.uAllItemFields && selectedReportLabelsInfo)) {
            let fields = {};
            Object.keys(selectedReportLabelsInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };

        // Processing output...
        if (selectedReportLabelsInfo.endTime && selectedReportLabelsInfo.endTime != "") {
            this.setState({
                isDrawingHtmlTable: selectedReportLabelsInfo.fnDisplayURL1 && selectedReportLabelsInfo.fnDisplayURL1 != "",
                //showClockTimer: !(selectedReportLabelsInfo.fnDisplayURL1 && selectedReportLabelsInfo.fnDisplayURL1 != ""),
                fnDisplayURL1: selectedReportLabelsInfo.fnDisplayURL1,
                fnDownloadFile1: selectedReportLabelsInfo.fnDownloadFile1,
                fnHtmlDisplayURL1: selectedReportLabelsInfo.fnHtmlDisplayURL1 ? selectedReportLabelsInfo.fnHtmlDisplayURL1 : "Loading... Please wait!",
            });

            if (!selectedReportLabelsInfo.fnHtmlDisplayURL1) {
                let _url2 = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/LoadExportedData";
                let _params2 = { fileName: selectedReportLabelsInfo.fnDisplayURL1, accessToken: localStorage.getItem('token') };
                var _this = this;
                axios.post(_url2, _params2)
                    .then(function (response) {
                        _this.setState({
                            fnHtmlDisplayURL1: response.data ? response.data.output : "",
                        });

                        // Also add process to download output file
                        if (!_this.downloadFile) {
                            setTimeout(function () {
                                selectedReportLabelsInfo.endTime = null;
                                if (document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE")) {
                                    document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").removeAttribute('disabled');
                                    document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").click();
                                    _this.downloadFile = true;
                                }
                                return false;
                            }, 0);
                            return false;
                        }
                        return false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        return false;
                    });
                return false;
            } else {
                if (!this.downloadFile) {
                    var _this = this;
                    setTimeout(function () {
                        selectedReportLabelsInfo.endTime = null;
                        if (document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE")) {
                            document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").removeAttribute('disabled');
                            document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").click();
                            _this.downloadFile = true;
                        }
                        return false;
                    }, 0);
                    return false;
                }
            }
        }
        return false;
    }

    async downloadFileFnc(event, obj) {
        event.preventDefault();

        if (this.downloadFile) {
            this.downloadFile = false;
            return false;
        }

        var dialogFormData = obj.state.dialogForm;
        if (dialogFormData.urlOutputFileZip && dialogFormData.urlOutputFileZip != "") {
            // get file name from url
            var filename = dialogFormData.filename ? dialogFormData.filename : dialogFormData.populationLabel;
            filename = filename.replaceAll("_", "");
            filename = filename.replaceAll(".", "");
            filename = filename + ".zip";

            let zip;
            if (dialogFormData.urlOutputFileZip.indexOf('/') != -1) {
                zip = dialogFormData.urlOutputFileZip.substring(dialogFormData.urlOutputFileZip.lastIndexOf('/') + 1);
            } else {
                zip = dialogFormData.urlOutputFileZip.substring(dialogFormData.urlOutputFileZip.lastIndexOf('\\') + 1);
            }

            //display error message "Could not find the exported file: ..." and stop the process to prevent Excel grab the index file for downloading
            let userId = obj.props.userId;
            var url = ConfigData.CRStarAPI_URL + obj.props.clientgroup + "/CheckExistedDownloadFile";
            var _params = { fileName: filename, clientAlias: userId, accessToken: localStorage.getItem('token') };
            var result = await axios.post(url, _params)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });

            if (result && result.data && result.data.error && (result.data.errorCode == "204" || result.data.errorCode == "404")) {
                let path = dialogFormData.urlOutputFileZip ? dialogFormData.urlOutputFileZip : "";
                path = path.substr(0, path.lastIndexOf("\\") + 1);

                obj.setState({ outputFileNotFound: true, fileName: path + filename });
                return false;
            }

            const link = document.createElement('a');
            link.href = obj.props.clientgroup + "/" + zip;

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            //obj.downloadFile = true;
            return false;
        }
        return false;
    }

    handleOnFocus = (event) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    async Get_Data(reportLabel) {
        var _this = this;
        //Labels
        await _this.props.selectReportLabels(reportLabel);

        if (!_this.props.selectedReportLabelsInfo) {
            setTimeout(function () {
                _this.setState({
                    reload: true,
                    isShowConfirmDeleteMsg: false,
                    isShowConfirmDeleteLabelMsg: false,
                    confirmed: null,
                    monitoring: null,
                    isFormDirty: false,
                });
            }, 1000);
        }
        return false;
    }

    validationHospitalNumber(data) {
        var allowedHosps = this.state.allowedHosps;//{dialog.Object}.getValue('Allowed_Hosps');
        if ((allowedHosps.indexOf(',All,') > -1) || (allowedHosps.indexOf('' + data + ',') > -1) && data != '') {
            if (isNaN(data)) {
                return 'The hospital code must be numeric';
            } else if (data == '') {
                return 'A hospital code must be filled in to run this report';
            }
            return false;
        } else {
            if (isNaN(data)) {
                return 'The hospital code must be numeric';
            } else if (data == '') {
                return 'A hospital code must be filled in to run this report';
            } else {
                return 'You do not have permission to run reports for this hospital';
            }
        }
    }

    validationDxYear(year) {
        if (year.length > 0) {
            if (isNaN(year) == true) {
                return 'Invalid Year. The year must be numeric';
            } else {
                if (year.length != 4) {
                    return 'Invalid Year. The year for this NCDB submission must be four digits';
                } else {
                    if (year < 2022 || year > 2022) {
                        return 'Invalid Year. The year for this NCDB submission is 2022';
                    }
                }
            }
        } else {
            return false;
        }
    }

    handleLostFocus = (event) => {
        if (event.target.name === "_populationLabel_") {
            this.populationLabel = event.target.value;
        } else if (event.target.name === "hospitalNumber") {
            let _fName = event.target.name;
            let _fValue = event.target.value;
            var result = this.validationHospitalNumber(_fValue);
            if (result && result != '') {
                //@TODO: ...display warning/error message when mouse is hover on this field
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_fName]: "IN_VALID" },
                    // And also update name again
                    dialogForm: {
                        ...this.state.dialogForm,
                        [_fName + "Name"]: _fValue == "" ? "" : this.state.dialogForm[_fName + "Name"],
                        populationLabel: ""
                    },
                    hospitalCodeMsgErr: result,
                });
            } else {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_fName]: "" },
                    // And also update name again
                    dialogForm: {
                        ...this.state.dialogForm,
                        [_fName + "Name"]: _fValue == "" ? "" : this.state.dialogForm[_fName + "Name"],
                        populationLabel: ""
                    },
                    hospitalCodeMsgErr: "",
                });

                let name = event.target.name;
                let value = event.target.value;
                let table = event.target.getAttribute('data-table');
                let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
                let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

                this.props.checkValidInput(name, value, table, key, -1, flag);
            }
        } else if (event.target.name === "dxYear") {
            let _fName = event.target.name;
            let _fValue = event.target.value;
            var result = this.validationDxYear(_fValue);
            if (result && result != '') {
                //@TODO: ...display warning/error message when mouse is hover on this field
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_fName]: "IN_VALID" },
                    // And also update name again
                    dxYearMsgErr: result,
                });
            } else {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_fName]: "" },
                    // And also update name again
                    dxYearMsgErr: "",
                });
            }
        } else if (event.target.name === "filename") {
            let _fName = event.target.name;
            let _fValue = event.target.value;
            if (_fValue && (_fValue.indexOf("_") != -1 || _fValue.indexOf(".") != -1)) {
                _fValue = _fValue.replaceAll("_", "");
                _fValue = _fValue.replaceAll(".", "");

                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [_fName]: _fValue,
                    },
                });
            }
        }
    }

    //NCDB_Export - call to server
    NCDB_Export(flag=false) {
        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.populationLabel == "") {
            return false;
        }

        //
        if (document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE")) {
            document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").setAttribute('disabled', true);
        }

        dialogFormData.autoSelect = this.state.selectedAutoSelectSelection ? this.state.selectedAutoSelectSelection : "Auto";
        dialogFormData.dxYears = this.state.selectedDxYearsSelection ? this.state.selectedDxYearsSelection : "2022";
        dialogFormData.rqrsNcdb = this.state.selectedRqrsNcdbSelection ? this.state.selectedRqrsNcdbSelection : "2";
        dialogFormData.excludeCases = this.state.isCheckedExcludeCases ? "1" : "0";

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        var autoselect = dialogFormData.autoSelect;
        if (autoselect == "Auto") {
            var hosp = dialogFormData.hospitalNumber ? dialogFormData.hospitalNumber : "";
            var year = dialogFormData.dxYear ? dialogFormData.dxYear : "";
            var DxYears = dialogFormData.dxYears;
            if (DxYears != "Other") { year = "All"; }
            var pop_label;
            if (hosp != "") {
                pop_label = hosp + "-Autoselect-NCDB-" + year;
            } else {
                pop_label = "All-Autoselect-NCDB-" + year;
            }
            dialogFormData.populationLabel = pop_label;
        }

        //clear all unnecessary values
        dialogFormData["allowedLabels"] = null;
        dialogFormData["startTime"] = null;
        dialogFormData["endTime"] = null;
        dialogFormData["fnDisplayURL1"] = null;
        dialogFormData["fnDownloadFile1"] = null;
        dialogFormData["fnHtmlDisplayURL1"] = null;
        dialogFormData["urlOutputFileZip"] = null;

        dialogFormData["incompleteOnly"] = flag ? "true" : "";

        //
        this.props.runNcdbRcrsExport(dialogFormData);        

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }

    validateHosp_NCDB() {
        var form = this.state.dialogForm;
        var allowedHosps = this.state.allowedHosps ? this.state.allowedHosps : ",All,";//{dialog.Object}.getValue('Allowed_Hosps');
        var hosp = form.hospitalNumber;//{dialog.Object}.getValue('Hospital_Number');

        if ((allowedHosps.indexOf(',All,') > -1) || (allowedHosps.indexOf(hosp + ',') > -1 && hosp != '')) {
            return true;
        } else {
            return false;
        }
    }

    //Alpha::Run button -> onMouseOver event
    mouseOverEventOnBtn(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = this.state.dialogForm;
        var autoselect = dialogFormData.autoSelect;
        if (autoselect == "Auto") {
            var hosp = dialogFormData.hospitalNumber ? dialogFormData.hospitalNumber : "";
            var year = dialogFormData.dxYear ? dialogFormData.dxYear : "";
            var DxYears = dialogFormData.dxYears ? dialogFormData.dxYears : "2022";
            if (DxYears != "Other") { year = "All"; }
            var pop_label;
            if (hosp != "") {
                pop_label = hosp + "-Autoselect-NCDB-" + year;
            } else {
                pop_label = "All-Autoselect-NCDB-" + year;
            }
            dialogFormData.populationLabel = pop_label;
            this.setState({ dialogForm: dialogFormData, mouseOverHandled: true });
        }
        return false;
    }

    //on before show event
    async showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.populationLabel == "") {
            return false;
        }

        var _this = this;
        let url = _this.props.clientgroup + "/ResetNcdbExport";
        await axios.post(url, { printForm: dialogFormData, accessToken: localStorage.getItem('token') })
            .then(res => {
                _this.NCDB_Export();
                return false;
            }).catch(error => {
                console.log(error);
                return false;
            });

        setTimeout(function () {
            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom, downloadFile: false });
            _this.downloadFile = false;
            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckNcdbRcrsExportStatus";
        axios.post(url, { printForm: _this.state.dialogForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    let status = res.data.result;
                    if (status && status.indexOf("Finished") != -1) {
                        _this.NCDB_Export(true);
                    }
                    eval(res.data.result);
                }
            }).catch(error => {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    //btnLogout.querySelector("a").click();
                }
                return false;
            });

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);
        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.Get_Data("");
        return false;
    }

    hideValidationError = (event) => {
        try {
            event.preventDefault();
        } catch (e) { }
        this.setState({ outputFileNotFound: false, fileName: null });
        return false;
    }

    render() {
        let validationError;
        {
            validationError = this.state.outputFileNotFound ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" id="PrintAndDownloadSePopup">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Validation Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideValidationError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">
                                    {"Could not find the exported file: " + this.state.fileName}
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideValidationError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showMessageError;
        {
            showMessageError = this.state.showMessageError && this.state.dialogForm ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.state.errorMessage && this.state.errorMessage.indexOf("You do not have permission to export data for this population.") != -1 ? "Access Denied" : "Error"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.errorMessage ? this.state.errorMessage : "Can't update this label."}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton hidden" value="no">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingHtmlTable;
        {
            drawingHtmlTable = this.state.isDrawingHtmlTable && this.state.fnDisplayURL1 ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls" id="dataExportMainPopup">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Data Exported</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable"
                                style={{ overflowY: this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <div id="useForPrintHtml" className={this.state.publicFolder && this.state.publicFolder != "" ? "_hidden" : ""}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.fnHtmlDisplayURL1 ? this.state.fnHtmlDisplayURL1 : "" }} className="state_export_popup" />
                                </div>

                                <iframe src={this.state.publicFolder ? (this.state.publicFolder + "?alias=" + this.props.clientgroup) : this.state.Fn_Html_Display_URL}
                                    //id="displayReportIframe"
                                    className={this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "hidden"} />
                            </div>

                            <div className="emptyLine"></div>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="REPORTDATA_STATEEXPOR_XDRU_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable}>
                                            <img id="REPORTDATA_STATEEXPOR_XDRU_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE" className="ScreenButton ScreenButtonHover" title="Download the Exported Data File"
                                            onClick={(e) => { this.downloadFileFnc(e, this) }}>
                                            <img id="REPORTDATA_STATEEXPOR_XDRU_ICON_DOWNLOAD_FILE" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" className="" />
                                            Download</button>
                                        <button type="button" id="REPORTDATA_STATEEXPOR_XDRU_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideDrawingHtmlTable} value="exit" autoFocus>
                                            <img id="REPORTDATA_STATEEXPOR_XDRU_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded && this.props.initialPageVisible ?
            (
                <div className="container-fluid2">
                    <form id="NcdbRcrsExportForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>
                        <div className="row">
                            <div className="">
                                <label style={{ width: "auto", height: "0px" }}>
                                    <input type="radio" onClick={this.selectionAutoSelect} checked={this.state.selectedAutoSelectSelection === "Auto"}
                                        name="autoSelect" field="autoSelect" value="Auto"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Auto Select</label>
                                <label style={{ width: "auto", height: "0px" }}>
                                    <input type="radio" onClick={this.selectionAutoSelect} checked={this.state.selectedAutoSelectSelection === "Pop"}
                                        name="autoSelect" field="autoSelect" value="Pop" width="240px"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Use Existing Population</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="_frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset style={{ padding: "0", border: "none", borderBottom: "1px solid #ccc" }}>
                                        <legend></legend>
                                        <div className="row" style={{ visibility: this.state.selectedAutoSelectSelection === "Pop" ? "visible" : "hidden" }}>
                                            <div className="col-sm-10">
                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="310px"
                                                    labelText="Population Label"
                                                    field="populationLabel" lineStyle="oneLine"
                                                    value={this.state.dialogForm.populationLabel}
                                                    dataSource="Labels"
                                                    titleDialog="Population Lookup" labelWidth="120px"
                                                    inValid={this.state.uAllItemFields["populationLabel"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["populationLabel"] : ""}
                                                    lostfocused={this.handleLostFocus} autoFocus
                                                    flag={this.state.allowedHosps}
                                                />
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                        <fieldset style={{ visibility: this.state.selectedAutoSelectSelection === "Auto" ? "visible" : "hidden", width: "550px" }}>
                                            <legend style={{ color: "black" }}>Auto Selection Criteria</legend>
                                            <div className="row">
                                                <div className="col-sm-5" style={{ display: "flex" }}>
                                                    <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Hospital" field="hospitalNumber" lostfocused={this.handleLostFocus}
                                                        value={this.state.dialogForm.hospitalNumber}
                                                        dataSource="Hospital2L" titleDialog="Hospital Lookup" labelWidth="60px" width="40px"
                                                        inValid={this.state.uAllItemFields["hospitalNumber"]} lineStyle="oneLine" itemNbr=""
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalNumber"] : ""}
                                                        flag={this.state.allowedHosps ? this.state.allowedHosps : ""} codeLength="2"
                                                        hospNbr={this.state.dialogForm.hospitalNumber ? this.state.dialogForm.hospitalNumber : this.state.allowedHosps}
                                                    />
                                                    <Input type="label" labelText="" field="hospitalNumberName" labelWidth="2px"
                                                        style={{ color: "#05004c", fontWeight: "bold" }} width="285px" lineStyle="oneLine"
                                                        value={this.state.dialogForm.hospitalNumberName} />
                                                </div>
                                            </div>
                                            <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "" }}>
                                                <div className="col-sm-12 ncdbExport">
                                                    <label className={this.state.hospitalCodeMsgErr && this.state.hospitalCodeMsgErr != "" ? "hospitalCodeMsgErr shown" : "hospitalCodeMsgErr"}>
                                                        {this.state.hospitalCodeMsgErr && this.state.hospitalCodeMsgErr != "" ? this.state.hospitalCodeMsgErr : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="">
                                                    <label style={{ width: "auto", height: "0px" }}>
                                                        <input type="radio" onClick={this.selectionDxYears} checked={this.state.selectedDxYearsSelection === "2022"}
                                                            name="dxYears" field="dxYears" value="2022"
                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Cases Diagnosed in 2022
                                                        </label>
                                                    <br />
                                                    <label style={{ width: "auto", height: "0px" }}>
                                                        <input type="radio" onClick={this.selectionDxYears} checked={this.state.selectedDxYearsSelection === "Other"}
                                                            name="dxYears" field="dxYears" value="Other" width="240px"
                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Cases Diagnosed in
                                                        </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-3" style={{ visibility: this.state.selectedDxYearsSelection === "Other" ? "" : "hidden", marginLeft: "15px" }}>
                                                    <Input type="input" focused={this.handleOnFocus}
                                                        labelWidth="40px" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                        labelText="Year" field="dxYear" width="60px"
                                                        value={this.state.dialogForm.dxYear ? this.state.dialogForm.dxYear : ""}
                                                        changed={this.handleFormChange}
                                                        lostfocused={this.handleLostFocus}
                                                        inValid={this.state.uAllItemFields["dxYear"]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 ncdbExport" style={{ visibility: this.state.selectedDxYearsSelection === "Other" ? "" : "hidden" }}>
                                                    <label className={this.state.dxYearMsgErr && this.state.dxYearMsgErr != "" ? "hospitalCodeMsgErr shown" : "hospitalCodeMsgErr"}>
                                                        {this.state.dxYearMsgErr && this.state.dxYearMsgErr != "" ? this.state.dxYearMsgErr : ""}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row" style={{ visibility: this.state.selectedDxYearsSelection === "2022" ? "" : "hidden" }}>
                                                <div className="col-sm-4 checkboxTypeCls">
                                                    <Input type="checkbox" lineStyle="oneLine"
                                                        labelText="Exclude Cases Prior to Reference Year" field="excludeCases"
                                                        labelWidth="265px"
                                                        labelStyle="lblOnCheckbox" width="50px"
                                                        value={this.state.isCheckedExcludeCases ? "1" : "0"}
                                                        checked={this.state.isCheckedExcludeCases}
                                                        clicked={this.toggleChecked}
                                                        style={{ cursor: "pointer", fontWeight: "bold" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" style={{ visibility: this.state.selectedDxYearsSelection === "2022" ? "" : "hidden" }}>
                                                <div className="col-sm-3" style={{ display: this.state.isCheckedExcludeCases ? "" : "none", marginLeft: "15px" }}>
                                                    <Input type="input" focused={this.handleOnFocus}
                                                        labelWidth="40px" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                        labelText="Year" field="refYear" width="60px"
                                                        value={this.state.dialogForm.refYear ? this.state.dialogForm.refYear : ""}
                                                        changed={this.handleFormChange}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Input type="input" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange}
                                                    labelText="Filename" labelWidth="70px" width="180px"
                                                    field="filename" lineStyle="oneLine" keyId="filename0"
                                                    value={this.state.dialogForm.filename}
                                                    lostfocused={this.handleLostFocus} rowIndex="0"
                                                />
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>Export Data For:</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="">
                                                <label style={{ width: "auto", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionRqrsNcdb} checked={this.state.selectedRqrsNcdbSelection === "2"}
                                                        name="rqrsNcdb" field="rqrsNcdb" value="2"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                NCDB</label>
                                                <br />
                                                <label style={{ width: "auto", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionRqrsNcdb} checked={this.state.selectedRqrsNcdbSelection === "1"}
                                                        name="rqrsNcdb" field="rqrsNcdb" value="1" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                RCRS</label>
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                    </fieldset>
                                    <div className="emptyLine"></div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-6 hidden">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Processed" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>
                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN_REPORT"
                                                        className={((this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "") || this.validateHosp_NCDB()) ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        onMouseOver={(e) => { this.mouseOverEventOnBtn(e) }}
                                                        disabled={((this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "") || this.validateHosp_NCDB()) ? false : true}
                                                    >
                                                        <img id="BUTTON_RUN_REPORT_ICON"
                                                            src={((this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "") || this.validateHosp_NCDB()) ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        disabled={this.state.isFormDirty ? false : true}
                                                        onClick={(e) => { this.newRecord(e) }}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )
            : <p className="">Export NCDB Data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {validationError}
                </React.Fragment>

                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    <div className={this.props.initialPageVisible ? "" : "hidden"}>
                        {dialogForm}
                    </div>
                </React.Fragment>

                <React.Fragment>
                    {drawingHtmlTable}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

NcdbRcrsExport.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedReportLabelsInfo: state.patientAbstract.selectedReportLabelsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,

        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        currentShowingHospitalVersion: state.patientAbstract.currentShowingHospitalVersion,

        //link to alpha pages
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        alphaToken: state.patientAbstract.tokenOnSession,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectReportLabels: (label) => dispatch(actionCreators.selectReportLabels(label)),
        deleteReportLabels: (dialogForm) => dispatch(actionCreators.deleteReportLabels(dialogForm)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),

        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        runNcdbRcrsExport: (dialogForm) => dispatch(actionCreators.runNcdbRcrsExport(dialogForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NcdbRcrsExport);