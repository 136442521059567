/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../../PatientMaintenance/PatientMaintenance.css";
import "../StateSpecificMaintenance.css";
import "../../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../../DiagnosisMaintenance/TextForm";
import Help from "../../../../UI/Help";
import classnames from "classnames";
import Draggable from 'react-draggable';
import * as TextPopup from "../../TitleOfTextPopup";

class StateFieldsCAMaint extends Component {
    state = {
        activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stateFieldsForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stateFieldsForm back to what it was on load
        initialStateFieldsForm: null,
        stateFieldsForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "caFirstNameAlias",
        isHelpVisible: false,
        isDialogVisible: true,

        stateFieldsCaDx: null,
        stateFieldsCaDxItemFields: null,
        stateFieldsCaDxLoading: false,
        selectedStateFieldsCaDxRowIndex: -1,

        rxSurgMv: null,
        rxSurgMvItemFields: null,
        rxSurgMvLoading: false,
        selectedRxSurgMvRowIndex: -1,

        stateFieldsCaDxMaxlengthInput: null,
        rxSurgMvMaxlengthInput: null,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,
    }

    setSelectedStateFieldsCaDxRow = (event, index) => {
        this.setState({ selectedStateFieldsCaDxRowIndex: index });
    }

    setSelectedRxSurgMvRow = (event, index) => {
        this.setState({ selectedRxSurgMvRowIndex: index });
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: flag });
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "StateFieldsCaDx":
                    this.deleteStateFieldsCaDxGrid();
                    break;
                case "RxSurgMv":
                    this.deleteRxSurgMvGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.stateFieldsForm && this.props.selectedStateFieldsRecord) {
            this.setFormData();
        }

        if (this.props.selectedHospital !== "") {
            //
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.stateFieldsForm && this.props.selectedStateFieldsRecord)
            || (prevProps.selectedStateFieldsRecord && prevProps.selectedStateFieldsRecord !== this.props.selectedStateFieldsRecord)
            || (this.props.initialAbstractPages && this.props.selectedStateFieldsRecord)
        ) {
            this.setFormData();
        }

        // StateFieldsCaDx List
        if (this.props.selectedPrimarySummary && !this.state.stateFieldsCaDx && !this.state.stateFieldsCaDxLoading) {
            this.setState({ stateFieldsCaDxLoading: true });
            //axios.get(this.props.clientgroup + "/GetStateFieldsCaDxList?cId=" + this.props.selectedPrimarySummary.cid)
            axios.post(this.props.clientgroup + "/GetStateFieldsCaDxList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ stateFieldsCaDx: res.data.stateFieldsCaDxList });

                        //
                        let maxLengthItem = [];//#1
                        if (!this.state.stateFieldsCaDxItemFields && res.data.stateFieldsCaDxList) {
                            let objArr = [];
                            res.data.stateFieldsCaDxList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            this.setState({ stateFieldsCaDxItemFields: objArr });
                        };

                        // get maxlength for State_Fields_CA_DX grid
                        //axios.get(this.props.clientgroup + "/GetTableStructure?table=State_Fields_CA_DX")
                        axios.post(this.props.clientgroup + "/GetTableStructure", { table: "State_Fields_CA_DX", accessToken: localStorage.getItem('token') })
                        .then(res => {
                            var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res.data !== null && res.data.result !== "") {
                                let maxLengthValue = res.data.result;

                                maxLengthValue = maxLengthValue.split(',');
                                for (var i = 0; i < maxLengthValue.length; i++) {
                                    let obj = maxLengthValue[i].split(':');
                                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                }

                                this.setState({
                                    stateFieldsCaDxMaxlengthInput: { ...maxLengthItem }
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            /*
                            let btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    let btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
        // RxSurgMv List
        if (this.props.selectedPrimarySummary && !this.state.rxSurgMv && !this.state.rxSurgMvLoading) {
            this.setState({ rxSurgMvLoading: true });
            //axios.get(this.props.clientgroup + "/GetRxSurgMvList?primarySummaryId=" + this.props.selectedPrimarySummary.cid + "&itemNo=3")
            axios.post(this.props.clientgroup + "/GetRxSurgMvList", { primarySummaryId: this.props.selectedPrimarySummary.cid, itemNo: 3, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ rxSurgMv: res.data.rxSurgMvList });

                        //
                        let maxLengthItem = [];//#1
                        if (!this.state.rxSurgMvItemFields && res.data.rxSurgMvList) {
                            let objArr = [];
                            res.data.rxSurgMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            this.setState({ rxSurgMvItemFields: objArr });
                        };

                        // get maxlength for Rx_Surg_Mv grid
                        //axios.get(this.props.clientgroup + "/GetTableStructure?table=Rx_Surg_Mv").then(res => {
                        axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Rx_Surg_Mv", accessToken: localStorage.getItem('token')})
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res.data !== null && res.data.result !== "") {
                                let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                this.setState({
                                    rxSurgMvMaxlengthInput: { ...maxLengthItem }
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            /*
                            let btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                    }
                })
                .catch (function (error) {
                    console.log(error);
                    /*
                    let btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.stateFieldsForm && this.state.sAllItemFields
        ) {
            console.log("[StateFields] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    stateFieldsForm: { ...this.state.stateFieldsForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name }
                });
            } else {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    stateFieldsForm: { ...this.state.stateFieldsForm, [this.props.currentSelectedField + "Name"]: "" }
                });
            }
            this.props.clearCommonLookupItem();
        }

        if (this.state.tableStructureList === null && !this.state.hasSetMaxLength) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = {table: "State_Fields", accessToken: localStorage.getItem('token')};

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    // StateFieldsCaDx grid
    handleStateFieldsCaDxGridLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, index, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.stateFieldsCaDx];
                        newItems[index][name] = value;
                        return { stateFieldsCaDx: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.stateFieldsCaDxItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { stateFieldsCaDxItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.stateFieldsCaDxItemFields];
                    newItems[index][name] = "";
                    return { stateFieldsCaDxItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.stateFieldsCaDxItemFields];
                newItems[index][name] = "";
                return { stateFieldsCaDxItemFields: newItems };
            });
        }
    }

    handleStateFieldsCaDxGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.stateFieldsCaDx];
            newItems[index][name] = value;
            return { stateFieldsCaDx: newItems, isFormDirty: true };
        });
    }

    addNewStateFieldsCaDx = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.stateFieldsCaDx];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.stateFieldsCaDxItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { stateFieldsCaDx: newItems, stateFieldsCaDxItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedStateFieldsCaDx = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedStateFieldsCaDxRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.stateFieldsCaDx.length) {
            selectedRow = this.state.stateFieldsCaDx.length - 1;
        }

        if (this.state.stateFieldsCaDx[selectedRow] && this.state.stateFieldsCaDx[selectedRow]["id"] !== undefined && this.state.stateFieldsCaDx[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "StateFieldsCaDx" });
        } else {
            this.deleteStateFieldsCaDxGrid();
        }
        return false;
    }

    deleteStateFieldsCaDxGrid = () => {
        let selectedRow = this.state.selectedStateFieldsCaDxRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.stateFieldsCaDx.length) {
            selectedRow = this.state.stateFieldsCaDx.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.stateFieldsCaDx];
            if (newItems.length === 1) {
                const _newItems = [...this.state.stateFieldsCaDx];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.stateFieldsCaDxItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.stateFieldsCaDxItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { stateFieldsCaDx: newItems, stateFieldsCaDxItemFields: newFields, selectedStateFieldsCaDxRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // RxSurgMv grid
    handleRxSurgMvLostFocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, index, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.rxSurgMv];
                        newItems[index][name] = value;
                        return { rxSurgMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.rxSurgMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { rxSurgMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.rxSurgMvItemFields];
                    newItems[index][name] = "";
                    return { rxSurgMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.rxSurgMvItemFields];
                newItems[index][name] = "";
                return { rxSurgMvItemFields: newItems };
            });
        }
    }

    handleRxSurgMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.rxSurgMv];
            newItems[index][name] = value;
            return { rxSurgMv: newItems, isFormDirty: true };
        });
    }

    addNewRxSurgMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.rxSurgMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.rxSurgMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { rxSurgMv: newItems, rxSurgMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedRxSurgMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedRxSurgMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.rxSurgMv.length) {
            selectedRow = this.state.rxSurgMv.length - 1;
        }

        if (this.state.rxSurgMv[selectedRow] && this.state.rxSurgMv[selectedRow]["id"] !== undefined && this.state.rxSurgMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "RxSurgMv" });
        } else {
            this.deleteRxSurgMvGrid();
        }
        return false;
    }

    deleteRxSurgMvGrid = () => {
        let selectedRow = this.state.selectedRxSurgMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.rxSurgMv.length) {
            selectedRow = this.state.rxSurgMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.rxSurgMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.rxSurgMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.rxSurgMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.rxSurgMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }
            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { rxSurgMv: newItems, rxSurgMvItemFields: newFields, selectedRxSurgMvRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    setFormData = () => {
        this.setState({
            stateFieldsForm: { ...this.props.selectedStateFieldsRecord },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedStateFieldsRecord) {
            let fields = {};
            Object.keys(this.props.selectedStateFieldsRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ sAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "__thisPageNoHasList__") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                },
                isFormDirty: true
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1]) || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    this.setState({
                        dAllItemFields: {
                            ...this.state.dAllItemFields,
                            [tYear]: "IN_VALID",
                            [tMonth]: "IN_VALID",
                            [tDay]: "IN_VALID",
                        },
                        isFormDirty: true
                    });
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveStateFields = (event) => {
        event.preventDefault();
        const allowSaveAnyway = this.state.allowSaveAnyway;
        let isValid = true;
        let formItems = this.state.sAllItemFields;
        
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "caAltDob" || t === "caAltDxDate" || t === "caAltScreenDate" || t === "caAltAdmContDate" 
                || t === "caAltAdmDate" || t === "caAltRecurDate" || t === "caAltBxDate" || t === "caAltLastContDate"
                || t === "caAltCaConfDate" || t === "caAltDischDate") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        // StateFieldsCaDx grid
        if (isValid) {
            const cDxItmes = this.state.stateFieldsCaDxItemFields;
            cDxItmes.map((cdx, index) => {
                Object.keys(cdx).map((t) => {
                    if (cdx[t] === "dxRxReportDate" ) {
                        this.clearInvalidOfDateFields(cdx, t, "stateFieldsCaDxItemFields", index)
                    }
                });
            });
        }

        // RxSurgMv grid
        if (isValid) {
            const rxSItems = this.state.rxSurgMvItemFields;
            rxSItems.map((rxs, index) => {
                Object.keys(rxs).map((t) => {
                    if (rxs[t] === "surgDt" || t === "surgDischDt" ) {
                        this.clearInvalidOfDateFields(rxs, t, "rxSurgMvItemFields", index)
                    }
                });
            });
        }

        if (!isValid  && !allowSaveAnyway ) {
            return false;
        } else {
            let stateFieldsFormData = this.state.stateFieldsForm;
            let stateFieldsCaDxList = this.state.stateFieldsCaDx;
            let rxSurgMvList = this.state.rxSurgMv;
            
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(stateFieldsFormData).map((t, k) => {
                if (t === "caAltDob" || t === "caAltDxDate" || t === "caAltScreenDate" || t === "caAltAdmContDate" 
                || t === "caAltAdmDate" || t === "caAltRecurDate" || t === "caAltBxDate" || t === "caAltLastContDate"
                || t === "caAltCaConfDate" || t === "caAltDischDate") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(stateFieldsFormData, t)
                } 
            });

            stateFieldsCaDxList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "dxRxReportDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "stateFieldsCaDxList", index);
                    }
                });
            });

            rxSurgMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "surgDt" || t === "surgDischDt") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "rxSurgMvList", index);
                    }
                });
            });

            if (!isValidDateInput  && !allowSaveAnyway ) return false;

            stateFieldsFormData.stateFieldsCaDxList = stateFieldsCaDxList;
            stateFieldsFormData.rxSurgMvList = rxSurgMvList;

            if (this.props.selectedPrimarySummary && (!stateFieldsFormData.cid || stateFieldsFormData.cid == 0)) {
                stateFieldsFormData.cid = this.props.selectedPrimarySummary.cid;
            }

            this.props.saveStateFields(stateFieldsFormData);
            return false;
        }
    }

    resetStateFields = () => {
        this.setState({
            stateFieldsForm: { ...this.state.initialStateFieldsForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: value } });
                } else {
                    this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }
    }
    // --END

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".dragableSmallWindowCls">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const stateSpecificMaintenance = this.state.stateFieldsForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveStateFields} id="stateFieldsForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="_row">
                            <div className="__frameInner">
                                <div className="header">&nbsp;</div>
                                <div style={{ border: "1px solid #eee", marginBottom: "15px" }}>
                                    <Nav tabs>
                                        <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={() => { this.toggleTab("1") }}>
                                            CA STATE 1
                                        </NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={() => { this.toggleTab("2") }}>
                                            CA STATE 2
                                        </NavLink>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <div className="row">
                                                <div className="col-sm-3 tabInnerCls">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <div className="ssmTab1Col1">
                                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus} autoFocus
                                                                changed={this.handleFormChange} width="100px" labelWidth="140px"
                                                                labelText="First Name Alias" field="caFirstNameAlias"
                                                                value={this.state.stateFieldsForm.caFirstNameAlias} itemNbr="1438"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caFirstNameAlias"] : ""}
                                                            />
                                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="100px" labelWidth="140px"
                                                                labelText="Mother First Name" field="caMotherFirstName"
                                                                value={this.state.stateFieldsForm.caMotherFirstName} itemNbr="1439"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caMotherFirstName"] : ""}
                                                            />
                                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="100px" labelWidth="140px"
                                                                labelText="Reg Id Nbr" field="regIdNbr"
                                                                value={this.state.stateFieldsForm.regIdNbr} itemNbr="502"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["regIdNbr"] : ""}
                                                            />
                                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="55px" labelWidth="185px"
                                                                labelText="Reg Tum Nbr" field="caRegTumNbr"
                                                                value={this.state.stateFieldsForm.caRegTumNbr} itemNbr="505"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caRegTumNbr"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="185px"
                                                                labelText="Code Procedure" field="caCodeProc" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caCodeProc ? this.state.stateFieldsForm.caCodeProc : this.state.stateFieldsForm.CaCodeProc}
                                                                dataSource="Global_Dir_Mv" aliasNo="1434" titleDialog="Ca Code Procedure"
                                                                inValid={this.state.sAllItemFields["caCodeProc"]} itemNbr="1434"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caCodeProc"] : ""}
                                                            />

                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="185px"
                                                                labelText="Discovered by Screening" field="discScreening" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.discScreening ? this.state.stateFieldsForm.discScreening : this.state.stateFieldsForm.DiscScreening}
                                                                dataSource="Global_Dir_Mv" aliasNo="1443" titleDialog="Disc Screening" 
                                                                inValid={this.state.sAllItemFields["discScreening"]} itemNbr="1443"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["discScreening"] : ""}
                                                            />
                                                            <Input type="Hospital" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="95px" labelWidth="125px"
                                                                labelText="Last Follow-Up Hosp" field="lastFuHosp" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.lastFuHosp ? this.state.stateFieldsForm.lastFuHosp : this.state.stateFieldsForm.LastFuHosp}
                                                                dataSource="Hospital" aliasNo="" titleDialog="Last F/U Hospital"
                                                                inValid={this.state.sAllItemFields["lastFuHosp"]} itemNbr="520"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastFuHosp"] : ""}
                                                            />
                                                            <Input type="Hospital" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="95px" labelWidth="125px"
                                                                labelText="Next Follow-Up Hosp" field="nextFuHosp" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.nextFuHosp ? this.state.stateFieldsForm.nextFuHosp : this.state.stateFieldsForm.NextFuHosp}
                                                                dataSource="Hospital" aliasNo="521" titleDialog="Next F/U Hospital"
                                                                inValid={this.state.sAllItemFields["nextFuHosp"]} itemNbr="521"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nextFuHosp"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="185px"
                                                                labelText="Last Tumor Follow-Up" field="caLastFuHosp" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caLastFuHosp ? this.state.stateFieldsForm.caLastFuHosp : this.state.stateFieldsForm.CaLastFuHosp}
                                                                dataSource="Global_Dir_Mv" aliasNo="1432" titleDialog="Ca Last Tumor Followup"
                                                                inValid={this.state.sAllItemFields["caLastFuHosp"]} itemNbr="1432"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caLastFuHosp"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="185px"
                                                                labelText="Last Patient Follow-Up" field="caLastPatHosp" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caLastPatHosp ? this.state.stateFieldsForm.caLastPatHosp : this.state.stateFieldsForm.CaLastPatHosp}
                                                                dataSource="Global_Dir_Mv" aliasNo="1433" titleDialog="Ca Last Patient Followup"
                                                                inValid={this.state.sAllItemFields["caLastPatHosp"]} itemNbr="1433"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caLastPatHosp"] : ""}
                                                            />

                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="185px"
                                                                labelText="Tumor Marker (Her2/Neu)" field="tumorMarkerCa1" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.tumorMarkerCa1}
                                                                dataSource="Global_Dir_Mv" aliasNo="1489" titleDialog="Tumor Marker (Her2/Neu)"
                                                                inValid={this.state.sAllItemFields["tumorMarkerCa1"]} itemNbr="1489"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorMarkerCa1"] : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 extendDiv tabInnerCls">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <div className="ssmTab1Col2">

                                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="50px" labelWidth="145px"
                                                                labelText="Height (inch)" field="height"
                                                                value={this.state.stateFieldsForm.height} itemNbr="3623"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["height"] : ""}
                                                            />
                                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="50px" labelWidth="145px"
                                                                labelText="Weight (lbs)" field="weight"
                                                                value={this.state.stateFieldsForm.weight} itemNbr="3624"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["weight"] : ""}
                                                            />

                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="30px" labelWidth="145px"
                                                                labelText="Source Comorbidity" field="sourceCom" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.sourceCom ? this.state.stateFieldsForm.sourceCom : this.state.stateFieldsForm.SourceCom}
                                                                dataSource="Global_Dir_Mv" aliasNo="3638" titleDialog="Source Com"
                                                                inValid={this.state.sAllItemFields["sourceCom"]} itemNbr="3638"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sourceCom"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="30px" labelWidth="145px"
                                                                labelText="Tobacco Use Cigarette" field="tobUseCigarette" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.tobUseCigarette ? this.state.stateFieldsForm.tobUseCigarette : this.state.stateFieldsForm.TobUseCigarette}
                                                                dataSource="Global_Dir_Mv" aliasNo="3634" titleDialog="Tob Use Cigarette"
                                                                inValid={this.state.sAllItemFields["tobUseCigarette"]} itemNbr="3634"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseCigarette"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="30px" labelWidth="145px"
                                                                labelText="Tobacco Use Other" field="tobUseOther" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.tobUseOther ? this.state.stateFieldsForm.tobUseOther : this.state.stateFieldsForm.TobUseOther}
                                                                dataSource="Global_Dir_Mv" aliasNo="3635" titleDialog="Tob Use Other"
                                                                inValid={this.state.sAllItemFields["tobUseOther"]} itemNbr="3635"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseOther"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="30px" labelWidth="145px"
                                                                labelText="Tobacco Use Smokeless" field="tobUseSmokeless" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.tobUseSmokeless ? this.state.stateFieldsForm.tobUseSmokeless : this.state.stateFieldsForm.TobUseSmokeless}
                                                                dataSource="Global_Dir_Mv" aliasNo="3636" titleDialog="Tob Use Smokeless"
                                                                inValid={this.state.sAllItemFields["tobUseSmokeless"]} itemNbr="3636"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseSmokeless"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="30px" labelWidth="145px"
                                                                labelText="Tobacco Nos" field="tobUseNos" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.tobUseNos ? this.state.stateFieldsForm.tobUseNos : this.state.stateFieldsForm.TobUseNos}
                                                                dataSource="Global_Dir_Mv" aliasNo="3637" titleDialog="Tob Use Nos"
                                                                inValid={this.state.sAllItemFields["tobUseNos"]} itemNbr="3637"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseNos"] : ""}
                                                            />

                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="145px"
                                                                labelText="Religion" field="caReligion" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caReligion ? this.state.stateFieldsForm.caReligion : this.state.stateFieldsForm.CaReligion}
                                                                dataSource="Global_Dir_Mv" aliasNo="1437" titleDialog="Ca Religion"
                                                                inValid={this.state.sAllItemFields["caReligion"]} itemNbr="1437"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caReligion"] : ""}
                                                            />

                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="145px"
                                                                labelText="Primary Payer Dx" field="caPrimPayerDx" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caPrimPayerDx ? this.state.stateFieldsForm.caPrimPayerDx : this.state.stateFieldsForm.CaPrimPayerDx}
                                                                dataSource="Global_Dir_Mv" aliasNo="2093" titleDialog="Ca Primary Payer"
                                                                inValid={this.state.sAllItemFields["caPrimPayerDx"]} itemNbr="2093"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caPrimPayerDx"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="35px" labelWidth="145px"
                                                                labelText="Pay Source 2" field="caPaySource2" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caPaySource2 ? this.state.stateFieldsForm.caPaySource2 : this.state.stateFieldsForm.CaPaySource2}
                                                                dataSource="Global_Dir_Mv" aliasNo="4136" titleDialog="Ca Pay Source 2"
                                                                inValid={this.state.sAllItemFields["caPaySource2"]} itemNbr="4136"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caPaySource2"] : ""}
                                                            />

                                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                changed={this.handleFormChange} width="30px" labelWidth="145px"
                                                                labelText="Patient Contact" field="caPatContact" lineStyle="oneLine"
                                                                value={this.state.stateFieldsForm.caPatContact ? this.state.stateFieldsForm.caPatContact : this.state.stateFieldsForm.CaPatContact}
                                                                dataSource="Global_Dir_Mv" aliasNo="1435" titleDialog="Ca Patient Contact"
                                                                inValid={this.state.sAllItemFields["caPatContact"]} itemNbr="1435"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caPatContact"] : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 tabInnerCls">
                                                    <div className="frameInner">
                                                        <div className="header">Diagnosis / Treatment Report</div>
                                                        <div className="ssmTab1Col3">
                                                            <div className="row">
                                                                <div className="col-sm-12 stateFieldsCaDxListArea">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                                <th style={{ width: "145px" }}>
                                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                                        labelText="Month Day Year" field="dxRxReportDate"
                                                                                    /></th>
                                                                                <th style={{ width: "180px" }}>
                                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                                        labelText="Number" field="dxRxReportNbr"
                                                                                    /></th>
                                                                                <th style={{ width: "60px" }}>
                                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                                        labelText="Type" field="dxRxReportType"
                                                                                    /></th>
                                                                                <th style={{ width: "100px" }}>
                                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                                        labelText="Facility ID" field="dxRxReportFacId"
                                                                                    /></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                            <tr>
                                                                                <th style={{ width: "15px" }} colSpan="5">&nbsp;</th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="innerBody">
                                                                        <table>
                                                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                <tr>
                                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                                    <th style={{ width: "145px" }}>Month Day Year</th>
                                                                                    <th style={{ width: "180px" }}>Number</th>
                                                                                    <th style={{ width: "60px" }}>Type</th>
                                                                                    <th style={{ width: "100px" }}>Facility ID</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.stateFieldsCaDxItemFields
                                                                                    && this.state.stateFieldsCaDx !== null && this.state.stateFieldsCaDx.length > 0
                                                                                    ? this.state.stateFieldsCaDx.map((prm, index) => (
                                                                                        <tr key={"data-key-" + index} onClick={(e) => { this.setSelectedStateFieldsCaDxRow(e, index) }} className={this.state.selectedStateFieldsCaDxRowIndex === index ? "selectedRowCls" : ""} >
                                                                                            <td style={{ width: "15px" }}>
                                                                                                {index + 1}
                                                                                            </td>
                                                                                            <td>
                                                                                                <Input type="date" focused={this.handleOnFocus} labelWidth="0px"
                                                                                                    changed={(e) => { this.handleStateFieldsCaDxGridChange(e, index) }}
                                                                                                    labelText="" field="dxRxReportDate" width="145px" lineStyle="oneLine"
                                                                                                    value={prm.dxRxReportDate != null && prm.dxRxReportDate !== "" ? moment(new Date(prm.dxRxReportDate)).format("MM/DD/YYYY") : ""}
                                                                                                    rowIndex={index}//keyId={"dxRxReportDate" + index}
                                                                                                    lostfocused={(e) => { this.handleStateFieldsCaDxGridLostfocus(e, index) }}
                                                                                                    //#20210125 - START
                                                                                                    // Add day/month/year values
                                                                                                    inValidDay={this.state.stateFieldsCaDxItemFields[index]["dxRxReportDateDay"]}
                                                                                                    inValidMonth={this.state.stateFieldsCaDxItemFields[index]["dxRxReportDateMonth"]}
                                                                                                    inValidYear={this.state.stateFieldsCaDxItemFields[index]["dxRxReportDateYear"]}
                                                                                                    dayVal={prm.dxRxReportDateDay}
                                                                                                    monthVal={prm.dxRxReportDateMonth}
                                                                                                    yearVal={prm.dxRxReportDateYear}
                                                                                                    //#20210125 - END
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                    changed={(e) => { this.handleStateFieldsCaDxGridChange(e, index) }} labelWidth="0px"
                                                                                                    labelText="" field="dxRxReportNbr" value={prm.dxRxReportNbr} width="180px"
                                                                                                    lostfocused={(e) => { this.handleStateFieldsCaDxGridLostfocus(e, index) }}
                                                                                                    //maxLength="20"
                                                                                                    maxLength={this.state.stateFieldsCaDxMaxlengthInput ? this.state.stateFieldsCaDxMaxlengthInput["dxRxReportNbr"] : ""}
                                                                                                    keyId={"dxRxReportNbr" + index}
                                                                                                    rowIndex={index}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                    changed={(e) => { this.handleStateFieldsCaDxGridChange(e, index) }}
                                                                                                    labelText="" field="dxRxReportType" value={prm.dxRxReportType} labelWidth="0px"
                                                                                                    dataSource="Global_Dir_Mv" titleDialog="Ca Dx/rx Report Type" aliasNo="3991" width="40px"
                                                                                                    inValid={this.state.stateFieldsCaDxItemFields[index]["dxRxReportType"]}
                                                                                                    lostfocused={(e) => { this.handleStateFieldsCaDxGridLostfocus(e, index) }}
                                                                                                    //maxLength="2"
                                                                                                    maxLength={this.state.stateFieldsCaDxMaxlengthInput ? this.state.stateFieldsCaDxMaxlengthInput["dxRxReportType"] : ""}
                                                                                                    keyId={"dxRxReportType" + index}
                                                                                                    rowIndex={index}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <Input type="Hospital" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                    changed={(e) => { this.handleStateFieldsCaDxGridChange(e, index) }}
                                                                                                    labelText="" field="dxRxReportFacId" value={prm.dxRxReportFacId} labelWidth="0px"
                                                                                                    dataSource="Hospital" titleDialog="Ca Dx/rx Report Facility Id" aliasNo="" width="80px"
                                                                                                    inValid={this.state.stateFieldsCaDxItemFields[index]["dxRxReportFacId"]}
                                                                                                    lostfocused={(e) => { this.handleStateFieldsCaDxGridLostfocus(e, index) }}
                                                                                                    //maxLength="15"
                                                                                                    maxLength={this.state.stateFieldsCaDxMaxlengthInput ? this.state.stateFieldsCaDxMaxlengthInput["dxRxReportFacId"] : ""}
                                                                                                    keyId={"dxRxReportFacId" + index}
                                                                                                    rowIndex={index}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )) : <tr><td colSpan="17">No records available</td></tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="contactBtnArea right">
                                                                        <button onClick={this.addNewStateFieldsCaDx}>Add</button>
                                                                        <button onClick={this.deleteSelectedStateFieldsCaDx}>Delete</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Trans Remarks" labelWidth="auto" field="transRemarks"
                                                                        value={this.state.stateFieldsForm.transRemarks} itemNbr="519"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Pay Source Txt" labelWidth="auto" field="caPaySourceTxt"
                                                                        value={this.state.stateFieldsForm.caPaySourceTxt} itemNbr="860"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <div className="row">

                                                <div className="col-sm-2 tabInnerCls extendRightDiv">
                                                    <div className="frameInner">
                                                        <div className="header">Over-ride</div>
                                                        <div className="ssmTab2Col3">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Dx/Admit Date" field="caOvrDxAdmDt" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caOvrDxAdmDt}
                                                                        dataSource="Global_Dir_Mv" aliasNo="2193" titleDialog="Ca Override Diagnosis Admit Day " labelWidth="135px"
                                                                        inValid={this.state.sAllItemFields["caOvrDxAdmDt"]} itemNbr="2193"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caOvrDxAdmDt"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Race/Span Pob" field="caOvrRaceSpanPob" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caOvrRaceSpanPob}
                                                                        dataSource="Global_Dir_Mv" aliasNo="2194" titleDialog="Ca Override Race Spanish Pob"
                                                                        labelWidth="135px"
                                                                        inValid={this.state.sAllItemFields["caOvrRaceSpanPob"]} itemNbr="2194"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caOvrRaceSpanPob"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Spanish Birthplace" field="caOvrSpanPob" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caOvrSpanPob}
                                                                        dataSource="Global_Dir_Mv" aliasNo="2195" titleDialog="Ca Override Spanish Birthplace"
                                                                        labelWidth="135px"
                                                                        inValid={this.state.sAllItemFields["caOvrSpanPob"]} itemNbr="2195"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caOvrSpanPob"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Site/Stage" field="caOvrSiteStage" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caOvrSiteStage}
                                                                        dataSource="Global_Dir_Mv" aliasNo="2196" titleDialog="Ca Override Site Stage"
                                                                        labelWidth="135px"
                                                                        inValid={this.state.sAllItemFields["caOvrSiteStage"]} itemNbr="2196"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caOvrSiteStage"] : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-sm-5 tabInnerCls">
                                                    <div className="frameInner">
                                                        <div className="header">Alternate Dates</div>
                                                        <div className="ssmTab2Col4">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Birthdate" field="caAltDob" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltDob != null && this.state.stateFieldsForm.caAltDob !== "" ? moment(new Date(this.state.stateFieldsForm.caAltDob)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltDobDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltDobMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltDobYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltDobDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltDobMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltDobYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Screen Date" field="caAltScreenDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltScreenDate != null && this.state.stateFieldsForm.caAltScreenDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltScreenDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltScreenDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltScreenDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltScreenDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltScreenDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltScreenDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltScreenDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Admit Date" field="caAltAdmDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltAdmDate != null && this.state.stateFieldsForm.caAltAdmDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltAdmDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltAdmDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltAdmDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltAdmDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltAdmDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltAdmDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltAdmDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Positve Bx Date" field="caAltBxDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltBxDate != null && this.state.stateFieldsForm.caAltBxDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltBxDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltBxDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltBxDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltBxDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltBxDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltBxDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltBxDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Conf Date" field="caAltCaConfDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltCaConfDate != null && this.state.stateFieldsForm.caAltCaConfDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltCaConfDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltCaConfDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltCaConfDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltCaConfDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltCaConfDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltCaConfDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltCaConfDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Dx Date" field="caAltDxDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltDxDate != null && this.state.stateFieldsForm.caAltDxDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltDxDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltDxDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltDxDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltDxDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltDxDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltDxDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltDxDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Adm/First Cont" field="caAltAdmContDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltAdmContDate != null && this.state.stateFieldsForm.caAltAdmContDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltAdmContDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltAdmContDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltAdmContDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltAdmContDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltAdmContDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltAdmContDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltAdmContDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Recur Date" field="caAltRecurDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltRecurDate != null && this.state.stateFieldsForm.caAltRecurDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltRecurDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltRecurDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltRecurDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltRecurDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltRecurDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltRecurDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltRecurDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Last Cont" field="caAltLastContDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltLastContDate != null && this.state.stateFieldsForm.caAltLastContDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltLastContDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltLastContDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltLastContDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltLastContDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltLastContDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltLastContDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltLastContDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="100px" lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange}
                                                                        labelText="Disch Date" field="caAltDischDate" width="90px" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caAltDischDate != null && this.state.stateFieldsForm.caAltDischDate !== "" ? moment(new Date(this.state.stateFieldsForm.caAltDischDate)).format("MM/DD/YYYY") : ""}
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.sAllItemFields["caAltDischDateDay"]}
                                                                        inValidMonth={this.state.sAllItemFields["caAltDischDateMonth"]}
                                                                        inValidYear={this.state.sAllItemFields["caAltDischDateYear"]}
                                                                        dayVal={this.state.stateFieldsForm.caAltDischDateDay}
                                                                        monthVal={this.state.stateFieldsForm.caAltDischDateMonth}
                                                                        yearVal={this.state.stateFieldsForm.caAltDischDateYear}
                                                                        //#20210125 - END
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-5 tabInnerCls">
                                                    <div className="frameInner">
                                                        <div className="header">Retired Fields</div>
                                                        <div className="ssmTab2Col1">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="1st Fam Hist" field="ca1stFamHistCa" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.ca1stFamHistCa}
                                                                        dataSource="Global_Dir_Mv" aliasNo="542" titleDialog="Ca (1st) Fam Hist Any Ca" labelWidth="125px"
                                                                        inValid={this.state.sAllItemFields["ca1stFamHistCa"]} itemNbr="542"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ca1stFamHistCa"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="2nd Fam Hist" field="ca2ndFamHistCa" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.ca2ndFamHistCa}
                                                                        dataSource="Global_Dir_Mv" aliasNo="544" titleDialog="Ca (2nd) Fam Hist Any Ca" labelWidth="125px"
                                                                        inValid={this.state.sAllItemFields["ca2ndFamHistCa"]} itemNbr="544"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ca2ndFamHistCa"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="1st Hist This Ca" field="ca1stFamHistThisCa" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.ca1stFamHistThisCa}
                                                                        dataSource="Global_Dir_Mv" aliasNo="543" titleDialog="Ca (1st) Fam Hist This Ca" labelWidth="125px"
                                                                        inValid={this.state.sAllItemFields["ca1stFamHistThisCa"]} itemNbr="543"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ca1stFamHistThisCa"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="2nd Hist This Ca" field="ca2ndFamHistThisCa" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.ca2ndFamHistThisCa}
                                                                        dataSource="Global_Dir_Mv" aliasNo="545" titleDialog="Ca (2nd) Fam Hist This Ca" labelWidth="125px"
                                                                        inValid={this.state.sAllItemFields["ca2ndFamHistThisCa"]} itemNbr="545"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ca2ndFamHistThisCa"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Rad Brain This Hosp" field="caRadBrainHosp" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caRadBrainHosp ? this.state.stateFieldsForm.caRadBrainHosp : this.state.stateFieldsForm.CaRadBrainHosp}
                                                                        dataSource="Global_Dir_Mv" aliasNo="508" titleDialog="Rad Br Cns this Hosp" labelWidth="125px"
                                                                        inValid={this.state.sAllItemFields["caRadBrainHosp"]} itemNbr="508"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caRadBrainHosp"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Recon Here" field="caReconHere" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caReconHere ? this.state.stateFieldsForm.caReconHere : this.state.stateFieldsForm.CaReconHere}
                                                                        dataSource="Global_Dir_Mv" aliasNo="1436" titleDialog="Recon Immediate" labelWidth="125px"
                                                                        inValid={this.state.sAllItemFields["caReconHere"]} itemNbr="1436"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caReconHere"] : ""}
                                                                    />
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="105px" labelWidth="90px"
                                                                        labelText="Path Nbr S" field="caPathNbrS"
                                                                        value={this.state.stateFieldsForm.caPathNbrS} itemNbr="1440"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caPathNbrS"] : ""}
                                                                    />
                                                                    <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="105px" labelWidth="90px"
                                                                        labelText="Path Nbr B" field="caPathNbrB"
                                                                        value={this.state.stateFieldsForm.caPathNbrB} itemNbr="1441"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caPathNbrB"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Surg Margins" field="caSurgMargins" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caSurgMargins}
                                                                        dataSource="Global_Dir_Mv" aliasNo="1428" titleDialog="Ca Surgical Margins" labelWidth="95px"
                                                                        inValid={this.state.sAllItemFields["caSurgMargins"]} itemNbr="1428"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caSurgMargins"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Surg Margins 2" field="caSurgMargins2" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caSurgMargins2}
                                                                        dataSource="Global_Dir_Mv" aliasNo="1429" titleDialog="Ca Surgical Margins 2" labelWidth="95px"
                                                                        inValid={this.state.sAllItemFields["caSurgMargins2"]} itemNbr="1429"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caSurgMargins2"] : ""}
                                                                    />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        changed={this.handleFormChange} width="30px"
                                                                        labelText="Surg Margins 3" field="caSurgMargins3" lineStyle="oneLine"
                                                                        value={this.state.stateFieldsForm.caSurgMargins3}
                                                                        dataSource="Global_Dir_Mv" aliasNo="1430" titleDialog="Ca Surgical Margins 3" labelWidth="95px"
                                                                        inValid={this.state.sAllItemFields["caSurgMargins3"]} itemNbr="1430"
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caSurgMargins3"] : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className="row">                                                
                                                <div className="col-sm-12 rxSurgMvListArea">
                                                    <div className="primaryListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "140px" }}>Rx Date</th>
                                                                    <th style={{ width: "60px" }}><Input type="gridHeader" labelWidth="auto" labelText="Surg" field="surg" /></th>
                                                                    <th style={{ width: "60px" }}>SLNS</th>
                                                                    <th style={{ width: "5px" }}></th>
                                                                    <th style={{ width: "60px" }}>LNR</th>
                                                                    <th style={{ width: "60px" }}>SORS</th>
                                                                    <th style={{ width: "5px" }}></th>
                                                                    <th style={{ width: "140px" }}>Surg Disch Dt</th>
                                                                    <th style={{ width: "60px" }}> <Input type="gridHeader" labelWidth="auto" labelText="Readmit" field="surgReadmit30Days" /></th>
                                                                    <th style={{ width: "60px" }}>F/S Flag</th>
                                                                    <th style={{ width: "130px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hospital" field="Rx_Hosp" /></th>
                                                                    <th style={{ width: "280px" }}><Input type="gridHeader" labelWidth="auto" labelText="Ca Surgery Text" field="CA_Surgery_Text" /></th>
                                                                    <th>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <td colSpan="13">&nbsp;</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "140px" }}>Rx Date</th>
                                                                        <th style={{ width: "60px" }}>Surg</th>
                                                                        <th style={{ width: "60px" }}>SLNS</th>
                                                                        <th style={{ width: "5px" }}></th>
                                                                        <th style={{ width: "60px" }}>LNR</th>
                                                                        <th style={{ width: "60px" }}>SORS</th>
                                                                        <th style={{ width: "5px" }}></th>
                                                                        <th style={{ width: "140px" }}>Surg Disch Dt</th>
                                                                        <th style={{ width: "60px" }}>Redmit</th>
                                                                        <th style={{ width: "60px" }}>F/S Flag</th>
                                                                        <th style={{ width: "130px" }}>Hospital</th>
                                                                        <th style={{ width: "280px" }}>Ca Surgery Text</th>
                                                                        <th>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.rxSurgMvItemFields
                                                                        && this.state.rxSurgMv !== null && this.state.rxSurgMv.length > 0
                                                                        ? this.state.rxSurgMv.map((prm, index) => (
                                                                            <tr className="caGridRow" key={"data-key-" + index} onClick={(e) => { this.setSelectedRxSurgMvRow(e, index) }} /* className={this.state.selectedRxSurgMvRowIndex === index ? "selectedRowCls" : ""} */ >
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td style={{ width: "140px" }}>
                                                                                    <Input type="date" focused={this.handleOnFocus}
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="surgDt" width="130px" lineStyle="oneLine" labelWidth="0px"
                                                                                        value={prm.surgDt != null && prm.surgDt !== "" ? moment(new Date(prm.surgDt)).format("MM/DD/YYYY") : ""}
                                                                                        rowIndex={index}//keyId={"surgDt" + index}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}

                                                                                        //#20210125 - START
                                                                                        // Add day/month/year values
                                                                                        inValidDay={this.state.rxSurgMvItemFields[index]["surgDtDay"]}
                                                                                        inValidMonth={this.state.rxSurgMvItemFields[index]["surgDtMonth"]}
                                                                                        inValidYear={this.state.rxSurgMvItemFields[index]["surgDtYear"]}
                                                                                        dayVal={prm.surgDtDay}
                                                                                        monthVal={prm.surgDtMonth}
                                                                                        yearVal={prm.surgDtYear}
                                                                                        //#20210125 - END
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="surg" value={prm.surg} labelWidth="0px"
                                                                                        dataSource="Site_Surg_Codes"
                                                                                        flag={this.state.stateFieldsForm.fnSurgId}//Fixing #PR00377
                                                                                        titleDialog="Surg" width="40px" lineStyle="oneLine"
                                                                                        inValid={this.state.rxSurgMvItemFields[index]["surg"]}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                        //maxLength="2"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["surg"] : ""}
                                                                                        keyId={"surg" + index}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="scopeLymphSurg" value={prm.scopeLymphSurg} lineStyle="oneLine"
                                                                                        dataSource="Global_Dir_Mv" titleDialog="Scope Lymph Surg" aliasNo="1389" width="40px" labelWidth="0px"
                                                                                        inValid={this.state.rxSurgMvItemFields[index]["scopeLymphSurg"]}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                        //maxLength="1"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["scopeLymphSurg"] : ""}
                                                                                        keyId={"scopeLymphSurg" + index}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ width: "5px" }}></td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="lymphNodesRemoved" value={prm.lymphNodesRemoved} width="60px" labelWidth="0px"
                                                                                        //maxLength="2"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["lymphNodesRemoved"] : ""}
                                                                                        keyId={"lymphNodesRemoved" + index}
                                                                                        rowIndex={index}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="surgRegionSites" value={prm.surgRegionSites} labelWidth="0px"
                                                                                        dataSource="Global_Dir_Mv" titleDialog="Class" aliasNo="1418" width="40px"
                                                                                        inValid={this.state.rxSurgMvItemFields[index]["surgRegionSites"]}
                                                                                        //maxLength="1"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["surgRegionSites"] : ""}
                                                                                        keyId={"surgRegionSites" + index}
                                                                                        rowIndex={index}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ width: "5px" }}></td>
                                                                                <td style={{ width: "140px" }}>
                                                                                    <Input type="date" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="surgDischDt" width="130px" labelWidth="0px"
                                                                                        value={prm.surgDischDt != null && prm.surgDischDt !== "" ? moment(new Date(prm.surgDischDt)).format("MM/DD/YYYY") : ""}
                                                                                        rowIndex={index}//keyId={"surgDischDt" + index}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}

                                                                                        //#20210125 - START
                                                                                        // Add day/month/year values
                                                                                        inValidDay={this.state.rxSurgMvItemFields[index]["surgDischDtDay"]}
                                                                                        inValidMonth={this.state.rxSurgMvItemFields[index]["surgDischDtMonth"]}
                                                                                        inValidYear={this.state.rxSurgMvItemFields[index]["surgDischDtYear"]}
                                                                                        dayVal={prm.surgDischDtDay}
                                                                                        monthVal={prm.surgDischDtMonth}
                                                                                        yearVal={prm.surgDischDtYear}
                                                                                        //#20210125 - END
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="surgReadmit30Days" value={prm.surgReadmit30Days}
                                                                                        dataSource="Global_Dir_Mv" titleDialog="Surg Readmit 30 Days" aliasNo="2110" width="40px" labelWidth="0px"
                                                                                        inValid={this.state.rxSurgMvItemFields[index]["surgReadmit30Days"]}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                        //maxLength="1"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["surgReadmit30Days"] : ""}
                                                                                        keyId={"surgReadmit30Days" + index}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="surgFsFlag" value={prm.surgFsFlag}
                                                                                        dataSource="Global_Dir_Mv" titleDialog="Surg Fs Flag" aliasNo="160" width="40px" labelWidth="0px"
                                                                                        inValid={this.state.rxSurgMvItemFields[index]["surgFsFlag"]}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                        //maxLength="1"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["surgFsFlag"] : ""}
                                                                                        keyId={"surgFsFlag" + index}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="Hospital" focused={this.handleOnFocus}
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" field="rxHosp" value={prm.rxHosp} lineStyle="oneLine"
                                                                                        dataSource="Hospital" titleDialog="Rx Hosp" aliasNo="147" width="110px" labelWidth="0px"
                                                                                        inValid={this.state.rxSurgMvItemFields[index]["rxHosp"]}
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                        //maxLength="15"
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["rxHosp"] : ""}
                                                                                        keyId={"rxHosp" + index}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td className="CaTextCol">
                                                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                                                        changed={(e) => { this.handleRxSurgMvGridChange(e, index) }}
                                                                                        labelText="" labelWidth="0px" field="caSurgeryText"
                                                                                        value={prm.caSurgeryText} rows="1"
                                                                                        lostfocused={(e) => { this.handleRxSurgMvLostFocus(e, index) }}
                                                                                        maxLength={this.state.rxSurgMvMaxlengthInput ? this.state.rxSurgMvMaxlengthInput["caSurgeryText"] : ""}
                                                                                        keyId={"caSurgeryText" + index}
                                                                                        rowIndex={index} lineStyle="oneLine"
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="13">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewRxSurgMv}>Add</button>
                                                            <button onClick={this.deleteSelectedRxSurgMv}>Delete</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>

                                </div>
                            </div>
                        </div>
                    </form>
                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetStateFields} formId="stateFieldsForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">State Specific data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {stateSpecificMaintenance}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

StateFieldsCAMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedStateFieldsRecord: state.patientAbstract.selectedStateFieldsRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveStateFields: (formData) => dispatch(actionCreators.saveStateFields(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),

        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateFieldsCAMaint);