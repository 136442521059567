/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Draggable from 'react-draggable';

class PrintOutputForm extends Component {

    state = {
        selectedPrintOption: "Forms",
    };
    drawingHtmlTable(dataForm) {
        //
        this.setState({
            isDrawingHtmlTable: true,
            Fn_Display: dataForm.fnDisplay,
            Fn_Display_URL: dataForm.formUrlInPublicFolder,//fnDisplayUrl,
            publicFolder: dataForm.publicFolder,
            showClockTimer: false,//hide clock timer
            currentTime: dataForm.endTime,
            selectedPrintOption: "Forms",//dataForm.selectedPrintOption ? dataForm.selectedPrintOption : dataForm.reportingDevice,
            List_Display: dataForm.listDisplay,
            List_Display_URL: dataForm.listUrlInPublicFolder,//listDisplayUrl,
            showOptions: dataForm.populationLabel && dataForm.populationLabel != "" ? true : false,
        });

        if (document.querySelector('.statusBarOnFooterCls')) {
            var _startTime = "Start Time: " + dataForm.startTime;
            var space = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: ";
            document.querySelector('.statusBarOnFooterCls').innerHTML = _startTime + space + dataForm.endTime;
        }

        setTimeout(function () {
            document.getElementById("selectedPrintFormsOption") ? document.getElementById("selectedPrintFormsOption").click() : null;
            return false;
        }, 100);

        return false;
    };
    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.props.closeWindow();

        return false;
    }
        
    updatePrintValue(inputControl) {
        inputControl.target.defaultValue = inputControl.target.value;
    }

    updateCheckedValue(inputControl) {
        inputControl.target.defaultChecked = inputControl.target.checked;
    }

    componentDidMount() {
        if (this.props.patientAbsInfo && this.props.patientAbsInfo.endTime) {
            let dataForm = this.props.patientAbsInfo;
            if ((dataForm.outputDevice == "Screen" || dataForm.reportingDevice == "Screen") && dataForm.endTime) {
                this.drawingHtmlTable(dataForm);

                //
                var _this = this;
                var printHtml = document.getElementById("useForPrintHtml");
                if (printHtml && printHtml.querySelectorAll("input")) {
                    printHtml.querySelectorAll("input").forEach((input, index) => {
                        /*
                        if (input.getAttribute("type") == "checkbox" || input.getAttribute("type") == "checkBox" || input.getAttribute("type") == "radio") {
                            input.addEventListener("change", _this.updateCheckedValue);
                        } else {
                            input.addEventListener("change", _this.updatePrintValue);
                        }
                        */
                        input.addEventListener('change', function (e) {
                            if (input.getAttribute("type") == "checkbox" || input.getAttribute("type") == "checkBox" || input.getAttribute("type") == "radio") {
                                input.defaultChecked = input.checked;
                            } else {
                                input.defaultValue = input.value;
                            }
                        });
                    });
                }
                if (printHtml && printHtml.querySelectorAll("textarea")) {
                    printHtml.querySelectorAll("textarea").forEach((ta, index) => {
                        //ta.addEventListener("change", _this.updatePrintValue);
                        ta.addEventListener('change', function (e) {
                            ta.defaultValue = ta.value;
                        });
                    });
                }
            }
        }
    }

    printTable = () => {
        var printStyle = document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : this.state.printStyle;
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Print SCP Form</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
        return;
    }

    selectedPrintOption = (event) => {
        this.setState({
            selectedPrintOption: event.target.value,
        });

        setTimeout(function () {
            document.getElementById("option1").click();
            return false;
        }, 0);
    }

    render() {
        return this.state.Fn_Display && this.state.selectedPrintOption ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.props.pTitle}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container" id="printAbtractsMainTable" style={{ overflowY: this.state.formUrlInPublicFolder || this.state.listUrlInPublicFolder ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <div id="useForPrintHtml" className={this.state.formUrlInPublicFolder || this.state.listUrlInPublicFolder ? "hidden" : ""}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.selectedPrintOption == "Forms" ? this.state.Fn_Display : this.state.List_Display }} className="" />
                                </div>

                                <iframe src={this.state.formUrlInPublicFolder ? (this.state.formUrlInPublicFolder + "?alias=" + this.props.clientgroup) : (this.state.listUrlInPublicFolder ? (this.state.listUrlInPublicFolder + "?alias=" + this.props.clientgroup) : "")} className={this.state.formUrlInPublicFolder || this.state.listUrlInPublicFolder ? "" : "hidden"} />

                                {
                                    /*
                                    this.state.Fn_Display && this.state.Fn_Display.indexOf("Sorry! Can't load this output file, it's too large.") != -1 ?
                                        <a href={this.state.Fn_Display_URL} target="_blank" rel="noreferrer" onClick={(e) => { this.showOutputFile(e) }}>Click here to open the output file in the new window.</a>
                                        : ""
                                    */
                                }
                            </div>

                            <div className="emptyLine"></div>

                            <div className="emptyLine hidden" style={{ display: "none" }}>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option1" id="option1"
                                            checked={this.state.selectedOption === 'option1'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 1
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option2" id="option2"
                                            checked={this.state.selectedOption === 'option2'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 2
                                        </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option3" id="option3"
                                            checked={this.state.selectedOption === 'option3'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 3
                                    </label>
                                </div>
                            </div>

                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-4" style={{ display: "flex", margin: "15px" }}>
                                            <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                                onClick={this.printTable}>
                                                <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                                Print</button>
                                            <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                                style={{ marginLeft: "15px" }}
                                                onClick={this.hideDrawingHtmlTable} value="exit" autoFocus>
                                                <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                                Exit</button>
                                    </div>
                                    <div className="col-sm-4" style={{ top: "20px", display: !this.props.isTxSum || (this.state.showOptions && this.props.isTxSum) ? "" : "_none" }}>
                                        <label style={{ width: "auto" }}>
                                            <input type="radio" onChange={(e) => { this.selectedPrintOption(e) }}
                                                checked={this.state.selectedPrintOption == "Forms" ? true : false}
                                                name="selectedPrintOption" id="selectedPrintFormsOption" value="Forms"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />Forms</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label style={{ width: "auto" }}>
                                            <input type="radio" onChange={(e) => { this.selectedPrintOption(e) }}
                                                checked={this.state.selectedPrintOption == "List" ? true : false}
                                                name="selectedPrintOption" id="selectedPrintListOption" value="List"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />List</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null;
    }
}

PrintOutputForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        //patientAbsInfo: state.patientAbstract.patientAbsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        userId: state.auth.userId,
        selectedHospital: state.patientAbstract.selectedHospital,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),

        runGenSurvivorshipCarePlan: (dialogForm) => dispatch(actionCreators.runGenSurvivorshipCarePlan(dialogForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOutputForm);