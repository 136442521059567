import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import axios from "../../axios-instance";

export default withOktaAuth(class OktaAuthHome extends Component {

    state = {
        actived: false,
    }

    constructor(props) {
        super(props);
        this.oktaLogin = this.oktaLogin.bind(this);
        this.oktaLogout = this.oktaLogout.bind(this);
    }

    async oktaLogin(e) {
        e.preventDefault();
        sessionStorage.removeItem('CrstarLogout');

        /*
        const res = await fetch('https://dev-42708933-admin.okta.com/api/v1/sessions/me', { method: 'DELETE', credentials: "include" })
            .then((response) => {
                return response.json();
            });
        console.log(res);
        */

        await this.props.oktaAuth.signInWithRedirect();
        return false;
    }

    async oktaLogout(e) {
        e.preventDefault();
        await this.props.oktaAuth.signOut();
        return false;
    }

    render() {
        let body;

        const user = JSON.parse(sessionStorage.getItem('crstar_user_by_okta'));
        if (user) {
            this.setState({ actived: true });
            window.location.href = window.location.origin;
            return false;
        } else {
            var okta = localStorage.getItem('okta-token-storage');
            const oktaAuthFailed = sessionStorage.getItem('oktaAuthFailed');
            const crstarLoggedOut = sessionStorage.getItem('CrstarLogout');

            if ((this.props.authState?.isAuthenticated || okta) && !this.state.actived) {
                if (crstarLoggedOut || oktaAuthFailed) {
                    body = oktaAuthFailed == "1" ? null : (
                        /*
                        <div className="WarningMessage">
                            <span style={{ color: "red" }}>You have been logged out from CRStar.</span>
                        </div>
                        */
                        <div className="Buttons">
                            <button onClick={this.oktaLogin}>Okta Login</button>
                        </div>
                    );
                } else {
                    //window.location.href = window.location.origin + "/ok2callback";
                    if (!this.state.actived) {
                        this.setState({ actived: true });
                    }
                    sessionStorage.removeItem('CrstarLogout');
                }
            } else {
                if (crstarLoggedOut || oktaAuthFailed) {
                    body = (
                        <div className="WarningMessage">
                            <span style={{ color: "red", display: oktaAuthFailed == "1" ? "none" : "" }}>You have been logged out from CRStar.</span>
                            <div className="Buttons">
                                <button onClick={this.oktaLogin}>Okta Login</button>
                            </div>
                        </div>
                    );
                    sessionStorage.removeItem('CrstarLogout');
                } else {
                    body = (
                        <div className="Buttons">
                            <button onClick={this.oktaLogin}>Okta Login</button>
                        </div>
                    );
                }
            }
        }

        return (
            <>
                {body}
            </>
        );
    }
});