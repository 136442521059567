/* eslint-disable */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';
import { actionCreators } from "../../../store/PatientAbstract";

class Logout extends Component {
    componentDidMount() {
        //this.props.onLogout(this.props.userId);
        //this.props.setPatientMenu(false);

        var okta = localStorage.getItem('okta-token-storage');

        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.setItem('CrstarLogout', true);

        if (okta) {
            localStorage.setItem('okta-token-storage', okta);
        }
    }

    render() {
        return <Redirect to="/logout" />;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (uid, cg) => dispatch(actions.logout(uid, cg)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
    };
};

export default connect(null, mapDispatchToProps)(Logout);