/* eslint-disable */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    userId: null,
    hasChangePass: null,
    enable2fa: null,
    clientgroup: null,
    securityGroup: null,
    passValidation: null,
    passValidationMsg: null,
    lastAuth: null,
    lastResult: null,
    key2FA: null,
    expiresIn: 0,
    passExpired: false,
    error: null,
    loading: false,
    authRedirectPath: '/',
    isSubmitForm: false,
    inquiringMode: false,
    availableHospsData: null,
    availableMenuGroups: null,
    customizations: null,
    userList: null
};

const showMultiGroup = (state, action) => {
    return updateObject(state, { userList: action.userList });
};

const authStart = ( state, action ) => {
    return updateObject(state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        hasChangePass: action.hasChangePass,
        enable2fa: action.enable2fa,
        clientgroup: action.clientgroup,
        securityGroup: action.securityGroup,
        passValidation: action.passValidation,
        passValidationMsg: action.passValidationMsg,
        lastAuth: action.lastAuth,
        lastResult: action.lastResult,
        key2FA: action.key2FA,
        expiresIn: action.expiresIn,
        passExpired: action.passExpired,
        error: null,
        availableMenuGroups: action.availableMenuGroups,
        customizations: action.customizations,
        loading: false
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path });
};

const doStart = (state, action) => {
    return updateObject(state, { error: null, loading: true, isSubmitForm: false });
};
const doSuccess = (state, action) => {
    return updateObject(state, {
        isSubmitForm: true,
        loading: true
    });
};

const doFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        isSubmitForm: false
    });
};

const authCheckState = (state, action) => {
    return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        hasChangePass: action.hasChangePass,
        enable2fa: action.enable2fa,
        clientgroup: action.clientgroup,
        securityGroup: action.securityGroup,
        passValidation: action.passValidation,
        passValidationMsg: action.passValidationMsg,
        lastAuth: action.lastAuth,
        lastResult: action.lastResult,
        key2FA: action.key2FA,
        expiresIn: action.expiresIn,
        passExpired: action.passExpired,
        availableMenuGroups: action.availableMenuGroups,
        customizations: action.customizations,
    });
};

const setInquiringMode = (state, action) => {
    return updateObject(state, {
        inquiringMode: action.inquiringMode,
    });
};

const setAvailableHospsDataOfUser = (state, action) => {
    return updateObject(state, {
        availableHospsData: action.availableHospsData,
    });
};

const setCustomizationsOfUser = (state, action) => {
    return updateObject(state, {
        customizations: action.customizations,
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.AUTH_CHECK_STATE: return authCheckState(state, action);
        case actionTypes.FORGOT_PASSWORD: return doStart(state, action);
        case actionTypes.DO_SUCCESS: return doSuccess(state, action);
        case actionTypes.DO_FAIL: return doFail(state, action);
        case actionTypes.INQUIRING_MODE: return setInquiringMode(state, action);
        case actionTypes.AVAILABLE_HOSPS_DATA_OF_USER: return setAvailableHospsDataOfUser(state, action);
        case actionTypes.CUSTOMIZATIONS_OF_USER: return setCustomizationsOfUser(state, action);
        case actionTypes.MULTI_GROUP: return showMultiGroup(state, action);
        default: return state;
    }
};

export default reducer;