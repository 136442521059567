/* eslint-disable */
import React, { Component } from "react";
import ConfigData from '../../store/config.json';
import { connect } from "react-redux";
import Draggable from 'react-draggable';
import { actionCreators } from "../../store/PatientAbstract";
import axios from "../../axios-instance";

class HelpV21 extends Component {

    state = {        
        helpResult: null,
    }

    addEventListener = () => {
        var hp = this;

        document.addEventListener('keydown', function (e) {
            if (e.keyCode == 27) {
                hp.props.closeCode();
                hp.closeHelpDialog(e);
                hp.props.setChildrenOpening(false);
            }
        });
    }

    componentDidMount() {
        this.props.showingHelpInfoFunc(true);
        this.props.setChildrenOpening(true);

        //let columnName = this.props.fieldItemNbr ? this.props.fieldItemNbr : this.props.fieldInFocus;
        //let itemNbr = this.props.itemNbr ? this.props.itemNbr : "";
        //const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHelpV212?columnName=" + columnName + "&itemNbr=" + itemNbr;

        let itemNbr = this.props.focusingField.getAttribute("data-help-id");
        if (itemNbr == null || itemNbr == undefined || itemNbr == "") {
            itemNbr = this.props.focusingField.getAttribute("data-key");
        }
        var _this = this;
        const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHelpV21?itemNbr=" + itemNbr;
        axios.post(url, { itemNbr: itemNbr, accessToken: localStorage.getItem('token') })
            //.then(res => res.json())
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data.StatusCode == "408") {
                    console.log(res.data.ErrorMessage);
                    _this.props.showSessionTimeoutMsg(true);
                    return false;
                }
                return res.data.helpString ? this.setState({ helpResult: res.data.helpString}) : "Failed";
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        // Fixing item #PR00116
        this.addEventListener();
    }
    componentDidUnmount() {
        this.props.showingHelpInfoFunc(false);
        this.props.setChildrenOpening(false);
    }

    setHelpTitle = (title) => {
        let result = title ? title.replace(/(([A-Z0-9])+[0-9]|[A-Z0-9])/g, "_$1") : "";

        if (this.props.ssdiTitle) {
            result = this.props.ssdiTitle;
            if (result.indexOf('(') != -1) {
                result = result.substr(0, result.indexOf('('));
            }
        }
        return this.props.ssdiTitle ? "SSDI Help - " + result : "Help for " + result.toUpperCase();
    }

    closeHelpDialog = (event) => {
        this.props.closeCode();
        this.props.showingHelpInfoFunc(false);
        this.props.setChildrenOpening(false);

        try {
            event ? event.preventDefault() : "";
            /**/
            if (this.props.fieldInFocus !== "") {
                let focusingField = this.props.fieldItemNbr ? this.props.fieldItemNbr : this.props.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
            /**/
            return false;
        } catch (ex) { }
    }

    render() {
        return <div id="HelpDialogArea">
            <div className="fake_popup_cls"></div>
            <div className="CRStar_Window">
                <div className="fake_popup_cls" style={{ zIndex: 8 }}></div>
                <Draggable handle=".k-window-titlebar">
                    <div className="dragableWindowCls" style={{ zIndex: 9 }}>
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <div className="k-window-title k-dialog-title">{this.setHelpTitle(this.props.fieldItemNbr ? this.props.fieldItemNbr : this.props.fieldInFocus)}</div>
                            <div className="k-window-actions k-dialog-actions">
                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close" autoFocus>
                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeHelpDialog(e) }}></span>
                                </a>
                            </div>
                        </div>
                        <h5 style={{ display: "none" }}>{this.props.fieldInFocus}</h5>
                        <div dangerouslySetInnerHTML={{ __html: this.state.helpResult }} className="explainForHelping" />
                    </div>
                </Draggable>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        showingHelpInfo: state.patientAbstract.showingHelpInfo,
        itemNbr: state.patientAbstract.itemNbr,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        showingHelpInfoFunc: (value) => dispatch(actionCreators.showingHelpInfo(value)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpV21);