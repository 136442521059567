/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../../PatientMaintenance/PatientMaintenance.css";
import "../StateSpecificMaintenance.css";
import "../../DiagnosisMaintenance/DiagnosisMaintenance.css";
import "./StateFieldsNMMaint.css"
import moment from "moment";
import TextForm from "../../DiagnosisMaintenance/TextForm";
import Help from "../../../../UI/Help";
import Draggable from 'react-draggable';
import * as TextPopup from "../../TitleOfTextPopup";

class StateFieldsNMMaint extends Component {
    state = {
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stateFieldsNmForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stateFieldsNmForm back to what it was on load
        initialStateFieldsNmForm: null,
        stateFieldsNmForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "tobUseCigarette",
        isHelpVisible: false,
        isDialogVisible: true,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.stateFieldsNmForm && this.props.selectedStateFieldsRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.stateFieldsNmForm && this.props.selectedStateFieldsRecord)
            || (prevProps.selectedStateFieldsRecord && prevProps.selectedStateFieldsRecord !== this.props.selectedStateFieldsRecord)
            || (this.props.initialAbstractPages && this.props.selectedStateFieldsRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.stateFieldsNmForm && this.state.sAllItemFields
        ) {
            console.log("[StateFields] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    stateFieldsNmForm: { ...this.state.stateFieldsNmForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    stateFieldsNmForm: { ...this.state.stateFieldsNmForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.hasSetMaxLength) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = {table: "State_Fields_NM", table2: "State_Fields", accessToken: localStorage.getItem('token')};

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        this.setState({
            stateFieldsNmForm: {
                ...this.props.selectedStateFieldsRecord,
                ["covid19DxDate"]: (this.props.selectedStateFieldsRecord != null && this.props.selectedStateFieldsRecord.covid19DxDate !== null && this.props.selectedStateFieldsRecord.covid19DxDate !== "" ? moment(new Date(this.props.selectedStateFieldsRecord.covid19DxDate)).format("MM/DD/YYYY") : ""),
                ["covid19ViralTestDate"]: (this.props.selectedStateFieldsRecord != null && this.props.selectedStateFieldsRecord.covid19ViralTestDate !== null && this.props.selectedStateFieldsRecord.covid19ViralTestDate !== "" ? moment(new Date(this.props.selectedStateFieldsRecord.covid19ViralTestDate)).format("MM/DD/YYYY") : ""),
                ["covid19AntibodyTestDate"]: (this.props.selectedStateFieldsRecord != null && this.props.selectedStateFieldsRecord.covid19AntibodyTestDate !== null && this.props.selectedStateFieldsRecord.covid19AntibodyTestDate !== "" ? moment(new Date(this.props.selectedStateFieldsRecord.covid19AntibodyTestDate)).format("MM/DD/YYYY") : ""),
                ["covid19DecisionToDelayDate"]: (this.props.selectedStateFieldsRecord != null && this.props.selectedStateFieldsRecord.covid19DecisionToDelayDate !== null && this.props.selectedStateFieldsRecord.covid19DecisionToDelayDate !== "" ? moment(new Date(this.props.selectedStateFieldsRecord.covid19DecisionToDelayDate)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedStateFieldsRecord) {
            let fields = {};
            Object.keys(this.props.selectedStateFieldsRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ sAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "__thisPageNoHasList__") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                sAllItemFields: {
                    ...this.state.sAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1]) || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    this.setState({
                        sAllItemFields: {
                            ...this.state.sAllItemFields,
                            [tYear]: "IN_VALID",
                            [tMonth]: "IN_VALID",
                            [tDay]: "IN_VALID",
                        }
                    });
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveStateFieldsNm = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        let formItems = this.state.sAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "nmOtherDiagnosisDate1" || t === "nmOtherDiagnosisDate2" || t === "nmOtherDiagnosisDate3"
                || t === "nmOtherDiagnosisDate4" || t === "nmOtherDiagnosisDate5" || t === "nmOtherDiagnosisDate6") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let stateFieldsNmFormData = this.state.stateFieldsNmForm;
            //stateFieldsNmFormData.cid = this.props.selectedMedicalRecord.cid;

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(stateFieldsNmFormData).map((t, k) => {
                if (t === "nmOtherDiagnosisDate1" || t === "nmOtherDiagnosisDate2" || t === "nmOtherDiagnosisDate3"
                    || t === "nmOtherDiagnosisDate4" || t === "nmOtherDiagnosisDate5" || t === "nmOtherDiagnosisDate6") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(stateFieldsNmFormData, t);
                }
            });
            //#20210125 - END

            if (!isValidDateInput && !allowSaveAnyway) return false;

            this.props.saveStateFieldsNm(stateFieldsNmFormData);
            return false;
        }
    }

    resetStateFields = () => {
        this.setState({
            stateFieldsNmForm: { ...this.state.initialStateFieldsNmForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ stateFieldsNmForm: { ...this.state.stateFieldsNmForm, [event.target.name]: value } });
                } else {
                    this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }

        let fName = event.target.name;
        if (fName === "covid19DxDate" || fName === "covid19ViralTestDate" || fName === "covid19AntibodyTestDate" || fName === "covid19DecisionToDelayDate") {
            let thisValue = event.target.value;
            let thisDate = this.NM_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    stateFieldsNmForm: {
                        ...this.state.stateFieldsNmForm,
                        [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }
    }
    // --END

    NM_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ stateFieldsNmForm: { ...this.state.stateFieldsNmForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ stateFieldsNmForm: { ...this.state.stateFieldsNmForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        const stateSpecificMaintenance = this.state.stateFieldsNmForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveStateFieldsNm} id="stateFieldsNmForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-2 NCStateMaint1">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="35px"
                                            labelText="Tobacco History" field="nmSmokingHistory" lineStyle="oneLine"
                                            value={this.state.stateFieldsNmForm.nmSmokingHistory ? this.state.stateFieldsNmForm.nmSmokingHistory : this.state.stateFieldsNmForm.NmSmokingHistory}
                                            dataSource="Global_Dir_Mv" aliasNo="4131" titleDialog="Smoking History" labelWidth="135px"
                                            inValid={this.state.sAllItemFields["nmSmokingHistory"]} itemNbr="4131"
                                            lostfocused={this.handleLostFocus} autoFocus
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmSmokingHistory"] : "2"}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="35px"
                                            labelText="Alcohol History" field="nmAlcoholUse" lineStyle="oneLine"
                                            value={this.state.stateFieldsNmForm.nmAlcoholUse ? this.state.stateFieldsNmForm.nmAlcoholUse : this.state.stateFieldsNmForm.NmAlcoholUse}
                                            dataSource="Global_Dir_Mv" aliasNo="4134" titleDialog="Alcohol History" labelWidth="135px"
                                            inValid={this.state.sAllItemFields["nmAlcoholUse"]} itemNbr="4134"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmAlcoholUse"] : "2"}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="35px"
                                            labelText="Contact Type" field="nmContactType" lineStyle="oneLine"
                                            value={this.state.stateFieldsNmForm.nmContactType ? this.state.stateFieldsNmForm.nmContactType : this.state.stateFieldsNmForm.NmContactAllowed}
                                            dataSource="Global_Dir_Mv" aliasNo="4109" titleDialog="Contact Type" labelWidth="135px"
                                            inValid={this.state.sAllItemFields["nmContactType"]} itemNbr="4109"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmContactType"] : "2"}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="35px"
                                            labelText="Tribe" field="nmTribe" lineStyle="oneLine"
                                            value={this.state.stateFieldsNmForm.nmTribe ? this.state.stateFieldsNmForm.nmTribe : this.state.stateFieldsNmForm.nmTribe}
                                            dataSource="Global_Dir_Mv" aliasNo="4498" titleDialog="Tribe" labelWidth="135px"
                                            inValid={this.state.sAllItemFields["nmTribe"]} itemNbr="4498"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmTribe"] : "3"}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 NCStateMaint2">
                                    <div style={{ /*display: "inline-block"*/ }}>
                                        <div className="frameInner">
                                            <div className="header">Family History</div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="35px"
                                                        labelText="Family History" field="nmFamilyHistory" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyHistory ? this.state.stateFieldsNmForm.nmFamilyHistory : this.state.stateFieldsNmForm.NmFamilyHistory}
                                                        dataSource="Global_Dir_Mv" aliasNo="4110" titleDialog="Family History" labelWidth="120px"
                                                        inValid={this.state.sAllItemFields["nmFamilyHistory"]} itemNbr="4110"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyHistory"] : "2"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="35px"
                                                        labelText="Family Member 1" field="nmFamilyMember1" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyMember1 ? this.state.stateFieldsNmForm.nmFamilyMember1 : this.state.stateFieldsNmForm.NmFamilyMember1}
                                                        dataSource="Global_Dir_Mv" aliasNo="4111" titleDialog="Family Member 1" labelWidth="120px"
                                                        inValid={this.state.sAllItemFields["nmFamilyMember1"]} itemNbr="4111"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyMember1"] : "2"}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="50px"
                                                        labelText="Site 1" field="nmFamilyMember1Site" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyMember1Site ? this.state.stateFieldsNmForm.nmFamilyMember1Site : this.state.stateFieldsNmForm.nmFamilyMember1Site}
                                                        dataSource="Topo_Codes" aliasNo="4112" titleDialog="Family Member 1 Site of Cancer" labelWidth="50px"
                                                        inValid={this.state.sAllItemFields["nmFamilyMember1Site"]} itemNbr="4112"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyMember1Site"] : "4"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="35px"
                                                        labelText="Family Member 2" field="nmFamilyMember2" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyMember2 ? this.state.stateFieldsNmForm.nmFamilyMember2 : this.state.stateFieldsNmForm.NmFamilyMember2}
                                                        dataSource="Global_Dir_Mv" aliasNo="4113" titleDialog="Family Member 2" labelWidth="120px"
                                                        inValid={this.state.sAllItemFields["nmFamilyMember2"]} itemNbr="4113"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyMember2"] : "2"}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="50px"
                                                        labelText="Site 2" field="nmFamilyMember2Site" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyMember2Site ? this.state.stateFieldsNmForm.nmFamilyMember2Site : this.state.stateFieldsNmForm.nmFamilyMember2Site}
                                                        dataSource="Topo_Codes" aliasNo="4114" titleDialog="Family Member 2 Site of Cancer" labelWidth="50px"
                                                        inValid={this.state.sAllItemFields["nmFamilyMember2Site"]} itemNbr="4114"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyMember2Site"] : "4"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="35px"
                                                        labelText="Family Member 3" field="nmFamilyMember3" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyMember3 ? this.state.stateFieldsNmForm.nmFamilyMember3 : this.state.stateFieldsNmForm.NmFamilyMember3}
                                                        dataSource="Global_Dir_Mv" aliasNo="4115" titleDialog="Family Member 3" labelWidth="120px"
                                                        inValid={this.state.sAllItemFields["nmFamilyMember3"]} itemNbr="4115"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyMember3"] : "2"}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="50px"
                                                        labelText="Site 3" field="nmFamilyMember3Site" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmFamilyMember3Site ? this.state.stateFieldsNmForm.nmFamilyMember3Site : this.state.stateFieldsNmForm.nmFamilyMember3Site}
                                                        dataSource="Topo_Codes" aliasNo="4116" titleDialog="Family Member 3 Site of Cancer" labelWidth="50px"
                                                        inValid={this.state.sAllItemFields["nmFamilyMember3Site"]} itemNbr="4116"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmFamilyMember3Site"] : "4"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="frameInner" style={{ height: "245px" }}>
                                            <div className="header">Other Sites of Cancer</div>
                                            <div className="row" style={{ paddingLeft: "20px" }}>
                                                {
                                                    /*
                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} width="50px"
                                                        labelText="Number of Other Sites" field="ncNativeAmerTribe" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.ncNativeAmerTribe ? this.state.stateFieldsNmForm.ncNativeAmerTribe : this.state.stateFieldsNmForm.NcNativeAmerTribe}
                                                        dataSource="Global_Dir_Mv" aliasNo="857" titleDialog="Number of Other Sites" labelWidth="200px"
                                                        inValid={this.state.sAllItemFields["ncNativeAmerTribe"]} itemNbr="4077"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ncNativeAmerTribe"] : ""}
                                                    />
                                                    */
                                                }
                                                <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} labelText="Number of Other Sites" field="nmNbrOtherSites"
                                                    value={this.state.stateFieldsNmForm.nmNbrOtherSites} labelWidth="140px" width="30px"
                                                    itemNbr="4077"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmNbrOtherSites"] : "1"} />

                                                <div style={{ display: "flex" }}>
                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="75px" changed={this.handleFormChange}
                                                        labelText="Dx Date 1" field="nmOtherDiagnosisDate1" width="100px" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmOtherDiagnosisDate1}
                                                        // Add day/month/year values
                                                        inValidDay={this.state.sAllItemFields["nmOtherDiagnosisDate1Day"]}
                                                        inValidMonth={this.state.sAllItemFields["nmOtherDiagnosisDate1Month"]}
                                                        inValidYear={this.state.sAllItemFields["nmOtherDiagnosisDate1Year"]}
                                                        dayVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate1Day}
                                                        monthVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate1Month}
                                                        yearVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate1Year}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                    <div style={{ paddingLeft: "5px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="50px"
                                                            labelText="Other Sites 1" field="nmOtherSite1" lineStyle="oneLine"
                                                            value={this.state.stateFieldsNmForm.nmOtherSite1 ? this.state.stateFieldsNmForm.nmOtherSite1 : this.state.stateFieldsNmForm.NmOtherSite1}
                                                            dataSource="Topo_Codes" aliasNo="4078" titleDialog="Other Site of Cancer 1" labelWidth="85px"
                                                            inValid={this.state.sAllItemFields["nmOtherSite1"]} itemNbr="4078"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmOtherSite1"] : "4"}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="75px" changed={this.handleFormChange}
                                                        labelText="Dx Date 2" field="nmOtherDiagnosisDate2" width="100px" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmOtherDiagnosisDate2}
                                                        // Add day/month/year values
                                                        inValidDay={this.state.sAllItemFields["nmOtherDiagnosisDate2Day"]}
                                                        inValidMonth={this.state.sAllItemFields["nmOtherDiagnosisDate2Month"]}
                                                        inValidYear={this.state.sAllItemFields["nmOtherDiagnosisDate2Year"]}
                                                        dayVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate2Day}
                                                        monthVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate2Month}
                                                        yearVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate2Year}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                    <div style={{ paddingLeft: "5px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="50px"
                                                            labelText="Other Sites 2" field="nmOtherSite2" lineStyle="oneLine"
                                                            value={this.state.stateFieldsNmForm.nmOtherSite2 ? this.state.stateFieldsNmForm.nmOtherSite2 : this.state.stateFieldsNmForm.NmOtherSite2}
                                                            dataSource="Topo_Codes" aliasNo="4079" titleDialog="Other Site of Cancer 2" labelWidth="85px"
                                                            inValid={this.state.sAllItemFields["nmOtherSite2"]} itemNbr="4079"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmOtherSite2"] : "4"}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="75px" changed={this.handleFormChange}
                                                        labelText="Dx Date 3" field="nmOtherDiagnosisDate3" width="100px" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmOtherDiagnosisDate3}
                                                        // Add day/month/year values
                                                        inValidDay={this.state.sAllItemFields["nmOtherDiagnosisDate3Day"]}
                                                        inValidMonth={this.state.sAllItemFields["nmOtherDiagnosisDate3Month"]}
                                                        inValidYear={this.state.sAllItemFields["nmOtherDiagnosisDate3Year"]}
                                                        dayVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate3Day}
                                                        monthVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate3Month}
                                                        yearVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate3Year}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                    <div style={{ paddingLeft: "5px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="50px"
                                                            labelText="Other Sites 3" field="nmOtherSite3" lineStyle="oneLine"
                                                            value={this.state.stateFieldsNmForm.nmOtherSite3 ? this.state.stateFieldsNmForm.nmOtherSite3 : this.state.stateFieldsNmForm.NmOtherSite3}
                                                            dataSource="Topo_Codes" aliasNo="4080" titleDialog="Other Site of Cancer 3" labelWidth="85px"
                                                            inValid={this.state.sAllItemFields["nmOtherSite3"]} itemNbr="4080"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmOtherSite3"] : "4"}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="75px" changed={this.handleFormChange}
                                                        labelText="Dx Date 4" field="nmOtherDiagnosisDate4" width="100px" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmOtherDiagnosisDate4}
                                                        // Add day/month/year values
                                                        inValidDay={this.state.sAllItemFields["nmOtherDiagnosisDate4Day"]}
                                                        inValidMonth={this.state.sAllItemFields["nmOtherDiagnosisDate4Month"]}
                                                        inValidYear={this.state.sAllItemFields["nmOtherDiagnosisDate4Year"]}
                                                        dayVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate4Day}
                                                        monthVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate4Month}
                                                        yearVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate4Year}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                    <div style={{ paddingLeft: "5px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="50px"
                                                            labelText="Other Sites 4" field="nmOtherSite4" lineStyle="oneLine"
                                                            value={this.state.stateFieldsNmForm.nmOtherSite4 ? this.state.stateFieldsNmForm.nmOtherSite4 : this.state.stateFieldsNmForm.NmOtherSite4}
                                                            dataSource="Topo_Codes" aliasNo="4081" titleDialog="Other Site of Cancer 4" labelWidth="85px"
                                                            inValid={this.state.sAllItemFields["nmOtherSite4"]} itemNbr="4081"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmOtherSite4"] : "4"}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="75px" changed={this.handleFormChange}
                                                        labelText="Dx Date 5" field="nmOtherDiagnosisDate5" width="100px" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmOtherDiagnosisDate5}
                                                        // Add day/month/year values
                                                        inValidDay={this.state.sAllItemFields["nmOtherDiagnosisDate5Day"]}
                                                        inValidMonth={this.state.sAllItemFields["nmOtherDiagnosisDate5Month"]}
                                                        inValidYear={this.state.sAllItemFields["nmOtherDiagnosisDate5Year"]}
                                                        dayVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate5Day}
                                                        monthVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate5Month}
                                                        yearVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate5Year}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                    <div style={{ paddingLeft: "5px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="50px"
                                                            labelText="Other Sites 5" field="nmOtherSite5" lineStyle="oneLine"
                                                            value={this.state.stateFieldsNmForm.nmOtherSite5 ? this.state.stateFieldsNmForm.nmOtherSite5 : this.state.stateFieldsNmForm.NmOtherSite5}
                                                            dataSource="Topo_Codes" aliasNo="4082" titleDialog="Other Site of Cancer 5" labelWidth="85px"
                                                            inValid={this.state.sAllItemFields["nmOtherSite5"]} itemNbr="4082"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmOtherSite5"] : "4"}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <Input type="date" focused={this.handleOnFocus} labelWidth="75px" changed={this.handleFormChange}
                                                        labelText="Dx Date 6" field="nmOtherDiagnosisDate6" width="100px" lineStyle="oneLine"
                                                        value={this.state.stateFieldsNmForm.nmOtherDiagnosisDate6}
                                                        // Add day/month/year values
                                                        inValidDay={this.state.sAllItemFields["nmOtherDiagnosisDate6Day"]}
                                                        inValidMonth={this.state.sAllItemFields["nmOtherDiagnosisDate6Month"]}
                                                        inValidYear={this.state.sAllItemFields["nmOtherDiagnosisDate6Year"]}
                                                        dayVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate6Day}
                                                        monthVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate6Month}
                                                        yearVal={this.state.stateFieldsNmForm.nmOtherDiagnosisDate6Year}
                                                        lostfocused={this.handleLostFocus}
                                                    />
                                                    <div style={{ paddingLeft: "5px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="50px"
                                                            labelText="Other Sites 6" field="nmOtherSite6" lineStyle="oneLine"
                                                            value={this.state.stateFieldsNmForm.nmOtherSite6 ? this.state.stateFieldsNmForm.nmOtherSite6 : this.state.stateFieldsNmForm.NmOtherSite6}
                                                            dataSource="Topo_Codes" aliasNo="4083" titleDialog="Other Site of Cancer 6" labelWidth="85px"
                                                            inValid={this.state.sAllItemFields["nmOtherSite6"]} itemNbr="4083"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nmOtherSite6"] : "4"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 NCStateMaint3">
                                    <div className="frameInner">
                                        <div className="header">COVID-19 Fields</div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Diagnosed" field="covid19Diagnosed" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Diagnosed ? this.state.stateFieldsNmForm.covid19Diagnosed : this.state.stateFieldsNmForm.Covid19Diagnosed}
                                                    dataSource="Global_Dir_Mv"//it is GlobalDirPopup in alpha
                                                    aliasNo="4480" titleDialog="COVID-19 Diagnosed" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Diagnosed"]} itemNbr="4480"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Diagnosed"] ? this.state.pageMaxlengthInput["covid19Diagnosed"] : "1"}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="COVID-19 Diagnosis Date" field="covid19DxDate"
                                                    //value={this.state.stateFieldsNmForm.covid19DxDate != null && this.state.stateFieldsNmForm.covid19DxDate !== "" ? moment(new Date(this.state.stateFieldsNmForm.covid19DxDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.stateFieldsNmForm != null ? this.state.stateFieldsNmForm.covid19DxDate : ""}
                                                    maxLength="10" aliasNo="4481" labelWidth="200px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4481"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Viral Test" field="covid19ViralTest" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19ViralTest ? this.state.stateFieldsNmForm.covid19ViralTest : this.state.stateFieldsNmForm.Covid19ViralTest}
                                                    dataSource="Global_Dir_Mv"//it is GlobalDirPopup in alpha
                                                    aliasNo="4482" titleDialog="COVID-19 Viral Test" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19ViralTest"]} itemNbr="4482"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19ViralTest"] ? this.state.pageMaxlengthInput["covid19ViralTest"] : "1"}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="COVID-19 Viral Test Date" field="covid19ViralTestDate"
                                                    //value={this.state.stateFieldsNmForm.covid19ViralTestDate != null && this.state.stateFieldsNmForm.covid19ViralTestDate !== "" ? moment(new Date(this.state.stateFieldsNmForm.covid19ViralTestDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.stateFieldsNmForm != null ? this.state.stateFieldsNmForm.covid19ViralTestDate : ""}
                                                    maxLength="10" aliasNo="4483" labelWidth="200px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4483"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Antibody Test" field="covid19AntibodyTest" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19AntibodyTest ? this.state.stateFieldsNmForm.covid19AntibodyTest : this.state.stateFieldsNmForm.Covid19AntibodyTest}
                                                    dataSource="Global_Dir_Mv"//it is GlobalDirPopup in alpha
                                                    aliasNo="4484" titleDialog="COVID-19 Antibody Test" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19AntibodyTest"]} itemNbr="4484"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19AntibodyTest"] ? this.state.pageMaxlengthInput["covid19AntibodyTest"] : "1"}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="COVID-19 Antibody Test Date" field="covid19AntibodyTestDate"
                                                    //value={this.state.stateFieldsNmForm.covid19AntibodyTestDate != null && this.state.stateFieldsNmForm.covid19AntibodyTestDate !== "" ? moment(new Date(this.state.stateFieldsNmForm.covid19AntibodyTestDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.stateFieldsNmForm != null ? this.state.stateFieldsNmForm.covid19AntibodyTestDate : ""}
                                                    maxLength="10" aliasNo="4483" labelWidth="200px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4485"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Delayed CA Events" field="covid19DelayedCaEvents" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19DelayedCaEvents ? this.state.stateFieldsNmForm.covid19DelayedCaEvents : this.state.stateFieldsNmForm.Covid19DelayedCaEvents}
                                                    dataSource="Global_Dir_Mv"//it is GlobalDirPopup in alpha
                                                    aliasNo="4486" titleDialog="COVID-19 Delayed CA Events" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19DelayedCaEvents"]} itemNbr="4486"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19DelayedCaEvents"] ? this.state.pageMaxlengthInput["covid19DelayedCaEvents"] : "1"}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="COVID-19 Decision To Delay Date" field="covid19DecisionToDelayDate"
                                                    //value={this.state.stateFieldsNmForm.covid19DecisionToDelayDate != null && this.state.stateFieldsNmForm.covid19DecisionToDelayDate !== "" ? moment(new Date(this.state.stateFieldsNmForm.covid19DecisionToDelayDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.stateFieldsNmForm != null ? this.state.stateFieldsNmForm.covid19DecisionToDelayDate : ""}
                                                    maxLength="10" aliasNo="4487" labelWidth="200px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4487"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Changed 1st Course Tx" field="covid19Changed1stCrsTx" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Changed1stCrsTx ? this.state.stateFieldsNmForm.covid19Changed1stCrsTx : this.state.stateFieldsNmForm.Covid19Changed1stCrsTx}
                                                    dataSource="Global_Dir_Mv" aliasNo="4488" titleDialog="COVID-19 Changed 1st Course Treatment" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Changed1stCrsTx"]} itemNbr="4488"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Changed1stCrsTx"] ? this.state.pageMaxlengthInput["covid19Changed1stCrsTx"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Chemo" field="covid19Chemo" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Chemo ? this.state.stateFieldsNmForm.covid19Chemo : this.state.stateFieldsNmForm.Covid19Chemo}
                                                    dataSource="Global_Dir_Mv" aliasNo="4489" titleDialog="COVID-19 Chemo" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Chemo"]} itemNbr="4489"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Chemo"] ? this.state.pageMaxlengthInput["covid19Chemo"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Hormone" field="covid19Hormone" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Hormone ? this.state.stateFieldsNmForm.covid19Hormone : this.state.stateFieldsNmForm.Covid19Hormone}
                                                    dataSource="Global_Dir_Mv" aliasNo="4490" titleDialog="COVID-19 Hormone" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Hormone"]} itemNbr="4490"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Hormone"] ? this.state.pageMaxlengthInput["covid19Hormone"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 BRM" field="covid19Brm" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Brm ? this.state.stateFieldsNmForm.covid19Brm : this.state.stateFieldsNmForm.Covid19Brm}
                                                    dataSource="Global_Dir_Mv" aliasNo="4491" titleDialog="COVID-19 BRM" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Brm"]} itemNbr="4491"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Brm"] ? this.state.pageMaxlengthInput["covid19Brm"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 BMT" field="covid19Bmt" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Bmt ? this.state.stateFieldsNmForm.covid19Bmt : this.state.stateFieldsNmForm.Covid19Bmt}
                                                    dataSource="Global_Dir_Mv" aliasNo="4492" titleDialog="COVID-19 BMT" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Bmt"]} itemNbr="4492"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Bmt"] ? this.state.pageMaxlengthInput["covid19Bmt"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Radiation" field="covid19Radiation" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19Radiation ? this.state.stateFieldsNmForm.covid19Radiation : this.state.stateFieldsNmForm.Covid19Radiation}
                                                    dataSource="Global_Dir_Mv" aliasNo="4493" titleDialog="COVID-19 Radiation" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19Radiation"]} itemNbr="4493"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19Radiation"] ? this.state.pageMaxlengthInput["covid19Radiation"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Radiation Other RT" field="covid19RadiationOtherRt" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19RadiationOtherRt ? this.state.stateFieldsNmForm.covid19RadiationOtherRt : this.state.stateFieldsNmForm.Covid19RadiationOtherRt}
                                                    dataSource="Global_Dir_Mv" aliasNo="4494" titleDialog="COVID-19 Radiation Other RT" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19RadiationOtherRt"]} itemNbr="4494"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19RadiationOtherRt"] ? this.state.pageMaxlengthInput["covid19RadiationOtherRt"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Radiation Other ICB" field="covid19RadiationOtherIcb" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19RadiationOtherIcb ? this.state.stateFieldsNmForm.covid19RadiationOtherIcb : this.state.stateFieldsNmForm.Covid19RadiationOtherIcb}
                                                    dataSource="Global_Dir_Mv" aliasNo="4495" titleDialog="COVID-19 Radiation Other ICB" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19RadiationOtherIcb"]} itemNbr="4495"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19RadiationOtherIcb"] ? this.state.pageMaxlengthInput["covid19RadiationOtherIcb"] : "1"}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="30px"
                                                    labelText="COVID-19 Surgery Impact" field="covid19SurgeryImpact" lineStyle="oneLine"
                                                    value={this.state.stateFieldsNmForm.covid19SurgeryImpact ? this.state.stateFieldsNmForm.covid19SurgeryImpact : this.state.stateFieldsNmForm.Covid19SurgeryImpact}
                                                    dataSource="Global_Dir_Mv" aliasNo="4496" titleDialog="COVID-19 Surgery Impact" labelWidth="200px"
                                                    inValid={this.state.sAllItemFields["covid19SurgeryImpact"]} itemNbr="4496"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["covid19SurgeryImpact"] ? this.state.pageMaxlengthInput["covid19SurgeryImpact"] : "1"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetStateFields} formId="stateFieldsNmForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        extraAction={this.extraAction}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">State Specific data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {stateSpecificMaintenance}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

StateFieldsNMMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedStateFieldsRecord: state.patientAbstract.selectedStateFieldsRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,

        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveStateFieldsNm: (formData) => dispatch(actionCreators.saveStateFields(formData, "Nm")),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),

        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateFieldsNMMaint);