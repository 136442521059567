/* eslint-disable */
import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Help from "../../../UI/Help";
import Draggable from 'react-draggable';
import * as actions from '../../../../store/actions/index';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";
import "./MassDelete.css";

class MassDelete extends Component {

    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "populationLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        changed: false,

        showClockTimer: false,
        titleDialog: null,

        showMessageError: null,
        isDrawingCharts: false,
        htmlOutput: null,
        
        labelMaxlengthInput: null, 
        pageMaxlengthInput: null,
        tableMaxlengthInput: null,

        labelStructureList: null,
        tableStructureList: null,

        popDisable: false,
        rfLabelDisable: false,

        confirmed: true,
        isShowConfirmMassDeleteMsg: false,

        reportStatus: null,
        reportStatus2: null,
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);
        /*
        //Add all tabs in maintDialogs.js
        dialogTypes.dataManagmentTabDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog);
            this.props.setEnabledStatus(dialog);
        });
        */
        this.setState({ isLoading: true });

        if (!this.state.dialogForm && this.props.massDeleteInfo) {
            this.setFormData(this.props.massDeleteInfo);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                },
                errorMessage: this.props.errorMessage,
            });

            this.props.clearErrorMessage();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields)
        {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "populationLabel"]: this.props.commonLookupItem.name },
                });
            } else {
                /*
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
                */
            }
            this.props.clearCommonLookupItem();
        }
        
        if (!this.state.dialogForm && this.props.massDeleteInfo) {
            this.setFormData(this.props.massDeleteInfo);
        }

        if (this.state.labelStructureList === null) {
            this.getLabelTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.labelStructureList && this.state.labelStructureList.length > 0 && this.state.labelMaxlengthInput) {
                let maxLengthValue = this.state.labelStructureList;
                let maxLengthItem = this.state.labelMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    labelMaxlengthInput: {
                        ...this.state.labelMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.tableMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.tableMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    tableMaxlengthInput: {
                        ...this.state.tableMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.dialogForm && this.state.isSetTable ) {
            if (this.state.dialogForm.populationLabel != "" && this.state.dialogForm.populationLabel != null) {
                document.getElementById("tableAbstType_Abst").click();
            }
            if (this.state.dialogForm.reportLabel != "" && this.state.dialogForm.reportLabel != null) {
                document.getElementById("tableAbstType_Table").click();
            }
            this.setState({isSetTable: false, });
        }

        if (this.state.dialogForm && prevProps.selectedRfLabelsPopInfo !== this.props.selectedRfLabelsPopInfo &&
                this.props.selectedRfLabelsPopInfo.reportLabel != null && this.props.selectedRfLabelsPopInfo.reportLabel != ""
                && (prevProps.selectedRfLabelsPopInfo.reportLabel !== this.props.selectedRfLabelsPopInfo.reportLabel ) ) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    tableName: this.props.selectedRfLabelsPopInfo.tableName,
                }
            })
        }

        if (this.state.dialogForm && this.props.massDeleteInfo && prevProps.massDeleteInfo && prevProps.massDeleteInfo !== this.props.massDeleteInfo &&
            (prevProps.massDeleteInfo.startTime !== this.props.massDeleteInfo.startTime || prevProps.massDeleteInfo.endTime !== this.props.massDeleteInfo.endTime) ) {

            clearInterval(this.sInVal);
            const queryResults = this.props.massDeleteInfo.queryResults;
            const queryResults2 = this.props.massDeleteInfo.queryResults2;

            let reportStatus = "Success - No Cases Changed";
            let reportStatus2 = "";

            if (this.state.tableAbstType == "Table" && queryResults > 0) {
                reportStatus = "Success - "+queryResults+" Records Affected";
            } 
            
            if (this.state.tableAbstType == "Abst" && queryResults > 0) {
                reportStatus = "Patient Records Deleted: "+queryResults;
                reportStatus2 = "Additional Individual Primaries Deleted: "+queryResults2;
            }

            this.setState({ 
                showClockTimer: false,
                isShowResultsMsg: true,
                reportStatus: reportStatus,
                reportStatus2: reportStatus2,
            });
        }
    }

    async getLabelTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Report_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        
        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            labelStructureList: res && res.data ? res.data.result : null
        });

        return false;
    }
    
    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "RF_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });

        return false;
    }

    setFormData = (massDeleteInfo) => {
        this.setState({
            initialDialogForm: { ...massDeleteInfo },
            dialogForm: { 
                ...massDeleteInfo,
                ["tableAbstType"]: massDeleteInfo && massDeleteInfo.tableAbstType ? massDeleteInfo.tableAbstType : "Table",
            },
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
            reportStatus: "Not Running", 
        });

        if ((!this.state.uAllItemFields && massDeleteInfo)) {
            let fields = {};
            Object.keys(massDeleteInfo).map((t) => {
                fields[t] = "";
            });
            this.setState({ uAllItemFields: fields });
        };
        this.GetRFLabelData("");
        return false;
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value,
                },
                popDisable : event.target.name === "reportLabel" ? true : false,
                rfLabelDisable: event.target.name === "populationLabel" ? true : false,
                isFormDirty: true,
                isSetTable: true,
            });

            //load all related infos after clicked
            if (event.target.name === "reportLabel") {
                var label = event.target.value;
                var _this = this;
                //let url = this.props.clientgroup + "/CheckRfLabelsHasExisted?label=" + label;
                let url = this.props.clientgroup + "/CheckRfLabelsHasExisted";
                let params = { label: label, accessToken: localStorage.getItem('token') };

                axios.post(url, params)
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        if (!res.data.error) {
                            setTimeout(function () {
                                _this.GetRFLabelData(label);
                            }, 0);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;

                    });
                return false;
            };

        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        };
    }

    async GetRFLabelData(rfLabel) {
        var _this = this;
        await _this.props.selectRfLabels(rfLabel);
        if (_this.props.selectedRfLabelsPopInfo) {
            setTimeout(function () {
                _this.setState({
                    reload: true,
                });
            }, 1000);
        }        
        return false;
    }

    handlePopLabelChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    handleOnFocus = (event, index, alias) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleLostFocus = () => {
        return false;
    }

    setTableAbstValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            tableAbstType: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }

    confirmMassDelete = (e) => {
        try {e.preventDefault();} catch (ex) { };

        if (this.props.currentShowingState == "CA" && this.state.dialogForm.tableAbstType === "Abst" && (this.state.dialogForm.transmitText == null || this.state.dialogForm.transmitText == "") ) {
            this.setState({
                confirm: false,
                isShowCAStateMsg: true,
            });
        } else {
            this.setState({
                confirm: false,
                isShowConfirmMassDeleteMsg: true,
            });
        }
        return false;
    }

    callRunMassDelete(e) {
        try {e.preventDefault();} catch (ex) { }

        var _this = this;
        setTimeout(function () {
            console.log("Running Mass Delete ...");
            _this.callMassDelete();
            _this.setState({ showClockTimer: true, isShowConfirmMassDeleteMsg: false, startTime: new Date().toLocaleTimeString() });
            return false;
        }, 0);

        this.sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.sInVal);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);
        return false;
    }

    callMassDelete = () => {
        let dialogFormData = this.state.dialogForm;

        dialogFormData.userId = this.props.userId;
        dialogFormData.hospIsCa = this.props.currentShowingState;

        //dialogFormData.populationLabel = this.

        //dialogFormData.urlOutputFile = "";
        //dialogFormData.htmlOutput = "";

        this.props.runMassDelete(dialogFormData);
        return false;
    }

    cancelReport = (e, _this) => {
        e.preventDefault();
        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);
        return false;
    }

    hideMessageError = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };
        this.setState({
            showMessageError: false,
            hasError: false,
        });
        this.props.setChildrenOpening(false);
        return false;
    }

    hideConfirmMassDeleteMsg = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };
        if (event.target.value === "yes") {
            this.setState({confirmed: true, showClockTimer: true, isShowConfirmMassDeleteMsg: false});
        }
        else
        {
            this.setState({confirmed: false, isShowConfirmMassDeleteMsg: false});
        }
        return false;
    }

    hideCAStateMsg = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };
        this.setState({isShowCAStateMsg: false});
        return false;
    }

    hideResultsMsg = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };

        this.setState({ isShowResultsMsg: false });
        this.props.setChildrenOpening(false);
        return false;
    }

    newRecord = (event) => {
        try {event.preventDefault();} catch (ex) { };
        this.setState({
            dialogForm: {
                //...this.state.dialogForm,
                populationLabel: null,
                reportLabel: null,
                tableAbstType: "Table",
                tableName: null,
                transmitText: null,
            },
            isFormDirty: false,
            reload: true,
            fieldInFocus: "populationLabel",
            focusingField: null,
            popDisable: false,
            rfLabelDisable: false,
        });
        return false;
    }

    render () {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.props.errorMessage ? this.props.errorMessage : this.state.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmMassDelete;
        {
            confirmMassDelete = this.state.isShowConfirmMassDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Mass Delete</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmMassDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Please confirm that you would like to proceed with this</label>
                                <br/>
                                <label>Mass Delete and that you have a backup of your data.</label>
                                <br/>
                                <br/>
                                <label>Click "Ok" to continue, otherwise click "Cancel"</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.callRunMassDelete(e) }} value="yes" autoFocus>Ok</button>
                                <button onClick={(e) => { this.hideConfirmMassDeleteMsg(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let caStateMsg;
        {
            caStateMsg = this.state.isShowCAStateMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Mass Delete CA Requirements</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideCAStateMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>California requires that a reason be given for deleting cases</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.hideCAStateMsg(e) }} value="yes" autoFocus>Ok</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let resultsMsg;
        {
            resultsMsg = this.state.isShowResultsMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Mass Delete Complete</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideResultsMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    {this.state.reportStatus}
                                </label>
                                <br/>
                                <label>
                                    {this.state.reportStatus2}
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideResultsMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.cancelReport(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
            : null;
        }

        const dialogForm = this.state.dialogForm && this.props.initialPageVisible ?
        (
            <div className="container-fluid2">
                {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                <form id="MassDeleteForm" className="CRStar_Window">
                    <div className="emptyLine"></div>

                    <div className="row">
                        <div className="col-md-7" style={{ height: "400px"}}>
                            <div style={{ display: "flex" }}>
                                <fieldset style={{ padding: "0", border: "none", borderBottom: "1px solid #ccc" }}>
                                    <legend></legend>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Input type="LabelPopup" focused={this.handleOnFocus}
                                                changed={this.handleFormChange} width="480px"
                                                labelText="Select a Pop Label"
                                                field="populationLabel" lineStyle="oneLine"
                                                value={this.state.dialogForm.populationLabel}
                                                dataSource="Labels"
                                                titleDialog="Population Lookup" labelWidth="140px"
                                                inValid={this.state.uAllItemFields["populationLabel"]}
                                                maxLength={this.state.labelMaxlengthInput ? this.state.labelMaxlengthInput["populationLabel"] : ""}
                                                lostfocused={this.handleLostFocus} autoFocus
                                                disabled={this.state.popDisable}
                                            />
                                            <div className="emptyLine"></div>
                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                changed={this.handleFormChange} width="480px"
                                                labelText="Report for a File Label"
                                                field="reportLabel" lineStyle="oneLine" keyId="reportLabel0"
                                                value={this.state.dialogForm.reportLabel}
                                                dataSource="RF_Labels"
                                                titleDialog="" labelWidth="140px"
                                                inValid={this.state.uAllItemFields["reportLabel"]}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reportLabel"] : ""}
                                                lostfocused={this.handleLostFocus} shownType="dropdown" rowIndex="0"
                                                disabled={this.state.rfLabelDisable}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="row">
                                <div className="emptyLine"></div>
                                <div>
                                    <div id="TableAbstLabel">Single Table or Abstract/Suspense Cases</div>
                                    <div id="tableAbstRadio" style={{ display: "grid"}}>
                                        <label style={{ width: "300px", height: "15px", cursor: "pointer" }}>
                                            <input type="radio" onClick={(e) => {this.setTableAbstValue(e)}}
                                            checked={this.state.dialogForm.tableAbstType === "Table"}
                                            name="tableAbstType" value="Table" id="tableAbstType_Table"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Single Table
                                        </label>
                                        <label style={{ width: "300px", height: "15px", cursor: "pointer" }}>
                                            <input type="radio" onClick={(e) => {this.setTableAbstValue(e)}}
                                            checked={this.state.dialogForm.tableAbstType === "Abst"}
                                            name="tableAbstType" value="Abst" id="tableAbstType_Abst"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Abst/Susp Primary
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="emptyLine"></div>
                                <div id="MassDeleteTableSelect" className={this.state.isFormDirty && this.state.dialogForm && this.state.tableAbstType == "Abst" ? "hidden" : ""}>
                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                        changed={this.handleFormChange} width="200px"
                                        labelText="Table Name"
                                        field="tableName" lineStyle="oneLine" keyId="tableName0"
                                        value={this.state.dialogForm.tableName}
                                        dataSource="ReportFile_TableName"
                                        titleDialog="" labelWidth="80px"
                                        inValid={this.state.uAllItemFields["tableName"]}
                                        maxLength={this.state.tableMaxlengthInput ? this.state.tableMaxlengthInput["tableName"] : ""}
                                        lostfocused={this.handleLostFocus} shownType="dropdown"
                                        displayingCodeOnly={true} rowIndex="0"
                                        limitByWidth={true} hideFilter={true}
                                    />
                                </div>
                                {/* </div><div id="CaComments" className={this.state.isFormDirty && this.state.dialogForm && this.state.dialogForm.hospState == "CA" && this.state.tableAbstType == "Abst" ? "" : "hidden"}> */}
                                <div id="CaComments" className={this.state.isFormDirty && this.state.dialogForm && this.props.currentShowingState == "CA" && this.state.tableAbstType == "Abst" ? "" : "hidden"}>
                                    <Input type="textarea" lostfocused={this.handleLostFocus}
                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Please document for the State of California the reason for deleting this record"
                                        labelWidth="auto" field="transmitText" value={this.state.dialogForm.transmitText}
                                        width="470px" height="80px" normalTaField={true}
                                        maxLength="150"
                                        title="If you are deleting abstracts previously sent to the State, the text entered here will be included when these deletions are sent to the State."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <fieldset className={this.props.showToggleBtn ? "bottomAreaButtonCls _hidden" : "bottomAreaButtonCls"}>
                        <legend></legend>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="A5CWLayout" style={{ display: this.props.showToggleBtn ? "none" : "" }}>
                                    <button id="BUTTON_MASS_DELETE"
                                        className={this.state.dialogForm ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                        onClick={(e) => { this.confirmMassDelete(e) }}
                                        disabled={this.state.dialogForm && this.state.isFormDirty ? false : true}
                                    >
                                        <img id="BUTTON_MASS_DELETE_ICON"
                                            src={this.state.dialogForm ? "https://crstar.ers-can.com/images/$$code.execute.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.execute.png.a5image"}
                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                        Ok
                                    </button>
                                </div>

                                <div className="A5CWLayout" style={{ width: "100px", display: this.props.showToggleBtn ? "none" : "" }}>
                                    <button id="BUTTON_NEWRECORD"
                                        className="ErsGrBlueButton _ScreenButton"
                                        onClick={(e) => { this.newRecord(e) }}
                                    >
                                        <img id="BUTTON_NEWRECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.new.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                        New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        ) 
        : <p className="">Mass Delete loading...</p>

        return(
        <React.Fragment>
            <React.Fragment>
                {showMessageError}
            </React.Fragment>

            <React.Fragment>
                {confirmMassDelete}
            </React.Fragment>

            <React.Fragment>
                {caStateMsg}
            </React.Fragment>

            <React.Fragment>
                {resultsMsg}
            </React.Fragment>

            <React.Fragment>
                {clockJs}
            </React.Fragment>
            
            <React.Fragment>
                {dialogForm}
            </React.Fragment>
        </React.Fragment>
        )
    }
}

MassDelete.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}
const mapStateToProps = state => {
    return {
        massDeleteInfo: state.patientAbstract.massDeleteInfo,

        selectedRfLabelsPopInfo: state.patientAbstract.selectedRfLabelsPopInfo,
        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,

        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
        runMassDelete: (dialogForm) => dispatch(actionCreators.runMassDelete(dialogForm)),
        selectRfLabels: (label) => dispatch(actionCreators.selectRfLabels(label)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MassDelete);