/* eslint-disable */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from 'prop-types';
import Tab from './Tab';
import Login from './UI/Login/Login';
import Auth from '../containers/Auth/Auth';
import ForgotPassword from './UI/ForgotPassword/ForgotPassword';
import * as actions from '../store/actions/index';
import axios from "../axios-instance";
import { actionCreators } from "../store/PatientAbstract";
import * as dialogTypes from "./AbstractManagement/maintDialogs";
import PatientAbstract from "./AbstractManagement/PatientAbstract/PatientAbstract";
import PatientMaintenance from "./AbstractManagement/PatientAbstract/PatientMaintenance/PatientMaintenance";
import DiagnosisMaintenance from "./AbstractManagement/PatientAbstract/DiagnosisMaintenance/DiagnosisMaintenance";
import StagingMaintenance from "./AbstractManagement/PatientAbstract/StagingMaintenance/StagingMaintenance";
import TreatmentMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance";
import OutcomesMaintenance from "./AbstractManagement/PatientAbstract/OutcomesMaintenance/OutcomesMaintenance";
import CancerProgramMaintenance from "./AbstractManagement/PatientAbstract/CancerProgramMaintenance/CancerProgramMaintenance";
import BreastProgramMaintenance from "./AbstractManagement/PatientAbstract/BreastProgramMaintenance/BreastProgramMaintenance";
import RectalProgramMaintenance from "./AbstractManagement/PatientAbstract/RectalProgramMaintenance/RectalProgramMaintenance";
import CaseAdminMaintenance from "./AbstractManagement/PatientAbstract/CaseAdminMaintenance/CaseAdminMaintenance";
import ClinicalMaintenance from "./AbstractManagement/PatientAbstract/ClinicalMaintenance/ClinicalMaintenance";
import ResearchMaintenance from "./AbstractManagement/PatientAbstract/ResearchMaintenance/ResearchMaintenance";
import UserDefinedMaintenance from "./AbstractManagement/PatientAbstract/UserDefinedMaintenance/UserDefinedMaintenance";
import StateSpecificMaintenance from "./AbstractManagement/PatientAbstract/StateSpecificMaintenance/StateSpecificMaintenance";
import RetiredMaintenance from "./AbstractManagement/PatientAbstract/RetiredMaintenance/RetiredMaintenance";
import Aframe from './Aframe';
import UserSecurityMaintLookup from './SystemAdministration/UserSecurityMaintLookup';
import UserMaintenance from './SystemAdministration/SecurityMaintenance/UserMaintenance';
import PasswordPolicyMaintenance from './SystemAdministration/SecurityMaintenance/PasswordPolicyMaintenance';
import DisplayLockedUsers from './SystemAdministration/SecurityMaintenance/DisplayLockedUsers';

import UtilMainTabs from './SystemAdministration/Utilities/GlobalDir';
import ImportConfig from './SystemAdministration/Utilities/CRStarImportOptions';
import DBValidation from './SystemAdministration/Utilities/DBValidation';
import UserSecurityLookup from './SystemAdministration/SecurityMaintenance/UserSecurityLookup';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from './UI/Modal/IdleModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import UtilityPage from './SystemAdministration/UtilityPage';
import ConfigData from '../store/config.json';
import Draggable from 'react-draggable';

//import ReaSystemMgmt from "./SystemManagement/ReaSystemMgmtMaint";
import HospitalsMaintLookup from "./SystemManagement/HospitalsMaintLookup/HospitalsMaintLookup";
import RegistrarMaintLookup from "./SystemManagement/RegistrarMaintLookup/RegistrarMaintLookup";
import PhysiciansMaintLookup from "./SystemManagement/PhysiciansMaintLookup/PhysiciansMaintLookup";
import PhysicianRoster from "./SystemManagement/PhysicianRoster/GenPhysicianRoster";
import InitializeAccNbr from "./SystemManagement/InitializeAccNbr/AccNbrsMaint";
import FollowUpOrder from "./SystemManagement/FollowUpOrder/FollowUpOrder";

import SelectAPop from "./Reporting/SelectAPop/SelectAPop";
//#PR00485
//import ReaCannedReports from "./Reporting/ReaCannedReports/ReaCannedReports";
//import SiteDistIncidence from "./Reporting/ReaCannedReports/Tabs/SiteDistIncidence";
import SiteDistIncidence from "./Reporting/SiteDistribution/SiteDistribution";
import TreatmentRpt from "./Reporting/TreatmentCombination/TreatmentCombination";
import SurvivalByStage from "./Reporting/SurvivalByStage/SurvivalByStageCustomize";//#!Survival

//import ReaRegistryMgmtReports from "./Reporting/ReaRegistryMgmtReports/ReaRegistryMgmtReports";
import Suspense from './Reporting/ReaRegistryMgmtReports/Tabs/Suspense';
import Incomplete from './Reporting/ReaRegistryMgmtReports/Tabs/Incomplete';
//import Productivity from './Reporting/ReaRegistryMgmtReports/Tabs/Productivity';
import Productivity from './Reporting/Productivity/Productivity';
/*import Timeliness from './Reporting/ReaRegistryMgmtReports/Tabs/Timeliness';*/
import Timeliness from './Reporting/Timeliness/Timeliness';

//import ReaAdminReports from "./Reporting/ReaAdminReports/ReaAdminReports";
//import AccRosterMpi from './Reporting/ReaAdminReports/Tabs/AccRosterMpi';
//import RequestLog from './Reporting/ReaAdminReports/Tabs/RequestLog';
import AccRosterMpi from './Reporting/AccessionRegister/AccessionRegister';
import RequestLog from './Reporting/RequestLog/RequestLog';
import ReportFile from './Reporting/ReaAdminReports/Tabs/ReportFile';

//import ReaAccreditationReports from "./Reporting/ReaAccreditationReports/ReaAccreditationReports";
import Coc from './Reporting/ReaAccreditationReports/Tabs/Coc';
import Napbc from './Reporting/ReaAccreditationReports/Tabs/Napbc';
import Naprc from './Reporting/ReaAccreditationReports/Tabs/Naprc';
//import QualityMeasures from './Reporting/ReaAccreditationReports/Tabs/QualityMeasures';
import QualityMeasures from './Reporting/QualityMeasures/QualityMeasures';

//import ReaComparisonReports from "./Reporting/ReaComparisonReports/ReaComparisonReports";
//import StageComparisons from './Reporting/ReaComparisonReports/Tabs/StageComparisons';
import StageComparisons from './Reporting/CrossTab/CrossTab';
//import CrossTab from './Reporting/ReaComparisonReports/Tabs/CrossTab';
import CrossTab from './Reporting/CrossTab/CrossTab';

//import ReaAdhocReports from "./Reporting/ReaAdhocReports/ReaAdhocReports";
//import ListPop from './Reporting/ReaAdhocReports/Tabs/ListPop';//Alpha::AdhocReport.a5wcmp
import ListPop from './Reporting/ReaAdhocReports/ReaAdhocReports';

import GraphAnItem from "./Reporting/GraphAnItem/GraphAnItemCustomize";//#!Graph Pop

//import ReaMiscellaneousReports from "./Reporting/ReaMiscellaneousReports/ReaMiscellaneousReports";
//import AgeBySex from './Reporting/ReaMiscellaneousReports/Tabs/AgeBySex';
//import StageBySex from './Reporting/ReaMiscellaneousReports/Tabs/StageBySex';
//import TnmGraph from './Reporting/ReaMiscellaneousReports/Tabs/TnmGraph';
import TNMGraph from './Reporting/TNMGraph/TNMGraph';
import DaysToTreatment from './Reporting/ReaMiscellaneousReports/Tabs/DaysToTreatment';

//import ReaDataExports from "./Reporting/ReaDataExports/ReaDataExports";
import StateExport from './Reporting/ReaDataExports/Tabs/StateExport';
import NcdbRcrsExport from './Reporting/ReaDataExports/Tabs/NcdbRcrsExport';
import ExportViewer from './Reporting/ReaDataExports/Tabs/ExportViewer';

//import DataManagment from './SystemAdministration/DataManagment/DataManagment';
import GlobalChange from './SystemAdministration/DataManagment/GlobalDataChange/GlobalChange';
import CrstarImport from './SystemAdministration/DataManagment/CrstarImport/CrstarImport';

//Report Utility menu
import ClearPopulations from './Reporting/ReportUtility/Tabs/ClearPopulations';

//System Configuration menu
import EditsSettingsMaint from "./SystemConfiguration/GenEdits/EditsSettingsMaint";
import DashboardSettings from "./SystemConfiguration/Dashboard/DashboardSettings";

//Abstract Utils menu
import DeletePatientAbstactMaint from "./AbstractManagement/AbstractUtils/DeleteAbstract/DeletePatientAbstactMaint";
import CopyAbstract from "./AbstractManagement/AbstractUtils/CopyAbstract/CopyAbstract";
import MergePatients from "./AbstractManagement/AbstractUtils/CombinePatients/MergePatients";

// Print Forms menu
import PrintAbstractsMaint from "./AbstractManagement/PrintForms/PrintAbstractsMaint";
import PrintTxSummary from "./AbstractManagement/PrintForms/PrintTxSummary";
import PrintQAForm from "./AbstractManagement/PrintForms/PrintQAForm";
import PrintSCPForm from "./AbstractManagement/PrintForms/PrintSCPForm";

// NLP Work Queue
import NlpWorkQueue from './AbstractManagement/NlpWorkQueue/NlpWorkQueue';

import LettersMaint from "./SystemManagement/FollowUpQaLetters/LettersMaint";
import CustomFormsMaint from "./SystemManagement/CustomFormsMaint/CustomFormsMaint";

// SystemAdminUtilities 
import GlobalDirMaintLookup from './SystemAdministration/GlobalDirMaintLookup/GlobalDirMaintLookup';
import MassDelete from './SystemAdministration/DataManagment/MassDelete/MassDelete';

import ZipCodesLookup from "./SystemManagement/ZipCodesMaintenance/ZipCodeMaintLookup";

class Tabs extends Component {

    // SESSION TIMEOUT - START
    constructor(props) {
        super(props);

        var crstarSessionTimeout = this.props.crstarSessionTimeout;
        if (!crstarSessionTimeout || crstarSessionTimeout == '0') {
            crstarSessionTimeout = ConfigData.CRSTAR_SESSION_TIMEOUT;
        }

        //console.log("[Logging] crstarSessionTimeout = " + crstarSessionTimeout);

        const sessionTimeout = 1000 * crstarSessionTimeout * 60;

        this.state = {
            timeout: sessionTimeout,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            activeTab: this.props.currentDialog,
            alphaUrl: this.props.alphaUrl,
            hasError: false,
            resetCurrentSelectedIndex: false,
        }

        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    _onAction(e) {
        this.setState({ isTimedOut: false });
    }

    _onActive(e) {
        this.setState({ isTimedOut: false });
    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut;
        if (isTimedOut) {
            /*
            // @TODO - call logout action to api server
            const uid = this.props.userId;
            this.props.onLogout(uid);
            */
        } else {
            this.setState({ showModal: true });
            this.idleTimer.reset();
            this.setState({ isTimedOut: true });
        }
    }

    handleClose = (event) => {
        try {
            event ? event.preventDefault() : null;
        } catch (ex) { }
        this.setState({ showModal: false });
        return false;
    }

    handleLogout(event, msg) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showModal: false, hasError: false });

        // @TODO - call logout action to api server
        const uid = this.props.userId;
        const cg = this.props.clientgroup;
        const sessionTimeout = this.state.timeout;

        var publicFolder = document.getElementById("usr_public_folder") ? document.getElementById("usr_public_folder").value : "";

        var _this = this;
        let _url = ConfigData.CRStarAPI_URL + cg + "/preLogout" + publicFolder;
        axios.post(_url, { path: publicFolder, accessToken: localStorage.getItem('token') })
            .then(response => {
                _this.props.onLogout(uid, cg, sessionTimeout, msg);
            })
            .catch(err => {
                console.log(err);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });

        localStorage.setItem('token', " ");
        // clear all previous session
        this.props.resetAllStoringOfUser();
    }
    // SESSION TIMEOUT - END

    state = {
        isNewMenu: false,
        isNewPatientAbstract: false,
        isLogin: false,
        isAddNewUser: false,
        hasError: false,
        expandedMenu: false,
    }

    showOrHide = (index) => {
        const btnName = dialogTypes.buttonNamesOnTab[index];
        const availableItems = this.props.availableItems ? this.props.availableItems : "";
        const availableItemsArr = availableItems.split("|");
        if (availableItems === null || availableItems.length === 0
            || availableItemsArr.includes(btnName) //availableItems.indexOf(btnName) != -1
        ) {
            //console.log("[show] btnName=", btnName);
            return "show";
        } else {
            //console.log("[hide] btnName=", btnName);
            return "hide";
        }
    }

    visibleType = (btnName) => {
        const availableItems = this.props.availableItems ? this.props.availableItems : "";
        const availableItemsArr = availableItems.split("|");
        if (availableItems === null || availableItems.length === 0
            || availableItemsArr.includes(btnName) || btnName === "User Logins"
        ) {
            return "show";
        } else {
            return "hide";
        }
    }

    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });

        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    componentDidMount(prevProps) {
        this.setState({ expandedMenu: false });
    }

    componentDidUpdate(prevProps) {
        //console.log('Rapid Abstract mode: ' + this.props.rapidAbstract);

        if (!this.state.hasError && this.props.showSessionTimeoutMsg) {
            this.setState({ hasError: true });
            return false;
        }

        if (this.props.alphaUrl.indexOf('.a5w') !== -1 || this.props.alphaUrl.indexOf('Launch') !== -1) {
            this.props.setRapidAbstract(false);//#PR00236
        } else if (this.props.alphaUrl === '/patient-abstract' || this.props.alphaUrl === '/rapid-abstract') {
            //
            if (this.props.alphaUrl === '/rapid-abstract') {
                this.props.setRapidAbstract(true);
            } else {
                this.props.setRapidAbstract(false);
            }

            let hasAlphaMenu = false;
            this.props.tabItems.map((child) => {
                if (child === dialogTypes.ALPHA_MENU) {
                    return hasAlphaMenu = true;
                }
            });
            if (hasAlphaMenu) {
                this.props.removeTabItems(dialogTypes.ALPHA_MENU);
            }

            if (!this.state.isNewPatientAbstract) {
                this.props.routingPage(dialogTypes.LOOKUP);
                this.props.addTabItems(dialogTypes.LOOKUP);

                this.setState({ isNewPatientAbstract: true });
            }

            let hasAbstractMenu = false;
            this.props.tabItems.map((child) => {
                if (child === dialogTypes.LOOKUP) {
                    return hasAbstractMenu = true;
                }
            });

            if (!hasAbstractMenu) {
                this.props.routingPage(dialogTypes.LOOKUP);
                this.props.addTabItems(dialogTypes.LOOKUP);
                this.props.setTab(dialogTypes.LOOKUP);
            }
        } else if (this.props.alphaUrl === '/abstract-utils') {
            // @TODO...
            this.props.setRapidAbstract(false);//#PR00236
        } else if (this.props.alphaUrl === '/logout') {

            this.props.onLogout(this.props.userId, this.props.clientgroup);
            // @TODO...
            this.props.setTab(dialogTypes.LOGIN);
            this.setState({ isLogin: true });

            this.props.setRapidAbstract(false);//#PR00236

        } else if ((this.props.alphaUrl === '/SysAdmSecurityMaint'
            || this.props.alphaUrl === '/SysAdmSecurityP2Maint'
            || this.props.alphaUrl === '/ReaSystemMgmt'
            || this.props.alphaUrl === '/ReportToSelectAPop'
            //|| this.props.alphaUrl === '/ReportToGraphAnItem'
            //|| this.props.alphaUrl === '/ReportToSurvivalByStage'

            //#PR00485
            || this.props.alphaUrl === '/ReaCannedReports'
            || this.props.alphaUrl === '/ReaRegistryMgmtReports'
            || this.props.alphaUrl === '/ReaAdminReports'
            || this.props.alphaUrl === '/ReaAccreditationReports'
            || this.props.alphaUrl === '/ReaComparisonReports'
            || this.props.alphaUrl === '/ReaAdhocReports'
            || this.props.alphaUrl === '/ReaMiscellaneousReports'
            || this.props.alphaUrl === '/ReaDataExports'

            || this.props.alphaUrl === '/ReportUtility'

            || this.props.alphaUrl === '/GenEditsSettings'
            || this.props.alphaUrl === '/AbstractUtils'
            || this.props.alphaUrl === '/PrintForms'
            || this.props.alphaUrl === '/NlpWorkQueue'
            || this.props.alphaUrl === '/SysAdmUtilitiesMaint'
            || this.props.alphaUrl === '/SysAdmUtility'
            || this.props.alphaUrl === '/DataManagment'
            || this.props.alphaUrl === '/ZipCodesMaint'
        )
            && (this.props.alphaUrlInnerTab === null || this.props.alphaUrlInnerTab === "")) {

            this.props.setRapidAbstract(false);//#PR00236

            let _screenType;
            if (this.props.alphaUrl === '/SysAdmSecurityMaint') {
                _screenType = dialogTypes.USER_LOGINS;//USER_MAINTENANCE
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/SysAdmSecurityP2Maint') {
                _screenType = dialogTypes.PASSWORD_POLICY_MAINTENANCE;

            } else if (this.props.alphaUrl === '/ReportToSelectAPop') {
                _screenType = dialogTypes.REPORTING_SELECT_A_POPULATION;
                this.props.setInitialPageVisible(true);

                /*
            } else if (this.props.alphaUrl === '/ReportToGraphAnItem') {
                _screenType = dialogTypes.REPORTING_GRAPH_AN_ITEM;
                this.props.setInitialPageVisible(true);

            } else if (this.props.alphaUrl === '/ReportToSurvivalByStage') {
                _screenType = dialogTypes.REPORTING_SURVIVAL_BY_STAGE;
                this.props.setInitialPageVisible(true);
                */

                //#PR00485
            } else if (this.props.alphaUrl === '/ReaCannedReports') {
                _screenType = dialogTypes.REPORTING_REA_CANNED_REPORTS_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaRegistryMgmtReports') {
                _screenType = dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaAdminReports') {
                _screenType = dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaAccreditationReports') {
                _screenType = dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB1;
                this.props.setInitialPageVisible(true);

            } else if (this.props.alphaUrl === '/ReaComparisonReports') {
                _screenType = dialogTypes.REPORTING_REA_COMPARISON_REPORTS_TAB1;
                this.props.setInitialPageVisible(true);

            } else if (this.props.alphaUrl === '/ReaAdhocReports') {
                _screenType = dialogTypes.REPORTING_REA_ADHOC_REPORTS_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaMiscellaneousReports') {
                _screenType = dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaDataExports') {
                _screenType = dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReportUtility') {
                _screenType = dialogTypes.REPORTING_UTILITY_TAB1;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaSystemMgmt') {
                //_screenType = dialogTypes.SYSTEM_MANAGEMENT_MAINT;
                _screenType = dialogTypes.HOSPITALS_MAINT_LOOKUP;//
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/PrintForms') {
                _screenType = dialogTypes.PF_PRINT_ABSTRACTS;//
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/ReaSystemMgmt') {
                //_screenType = dialogTypes.SYSTEM_MANAGEMENT_MAINT;
                _screenType = dialogTypes.HOSPITALS_MAINT_LOOKUP;//
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/GenEditsSettings') {
                _screenType = dialogTypes.SYSTEM_CONFIGURATION_GENEDITS;//
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/AbstractUtils') {
                _screenType = dialogTypes.ABSTRACT_UTILITIES_DELETE_ABSTRACT;//
                this.props.setInitialPageVisible(false);
            } else if (this.props.alphaUrl === '/SysAdmUtilitiesMaint') {
                _screenType = dialogTypes.GLOBAL_DIR_LOOKUP;
                this.props.setInitialPageVisible(false);
            }

            else if (this.props.alphaUrl === '/NlpWorkQueue') {
                _screenType = dialogTypes.NLP_WORK_QUEUE_TAB;//
                this.props.setInitialPageVisible(false);
            } else if (this.props.alphaUrl === '/SysAdmUtility') {

                _screenType = dialogTypes.UTILITIES_GLOBAL_DIR;
                this.props.setInitialPageVisible(false);

            } else if (this.props.alphaUrl === '/DataManagment') {
                _screenType = dialogTypes.DATA_MANAGMENT_TAB1;
                this.props.setInitialPageVisible(false);
            } else if (this.props.alphaUrl === '/ZipCodesMaint') {
                _screenType = dialogTypes.SYSMAN_ZIP_CODES_MAINT;//
                this.props.setInitialPageVisible(false);
            }

            // @TODO...
            let hasAlphaMenu = false;
            this.props.tabItems.map((child) => {
                if (child === dialogTypes.ALPHA_MENU) {
                    return hasAlphaMenu = true;
                }
            });
            if (hasAlphaMenu) {
                this.props.removeTabItems(dialogTypes.ALPHA_MENU);
            }

            if (!this.state.isAddNewUser) {
                this.props.routingPage(_screenType);
                this.props.addTabItems(_screenType);

                this.setState({ isAddNewUser: true });
            }

            let hasSystemMenu = false;
            this.props.tabItems.map((child) => {
                if (child === _screenType) {
                    return hasSystemMenu = true;
                }
            });

            if (!hasSystemMenu) {
                this.props.routingPage(_screenType);
                this.props.addTabItems(_screenType);

                this.props.setTab(_screenType);//
            }
        }

        //
        if (this.props.showingTabName && this.props.showingTabName.indexOf("_From_CRStar_Shortcuts") != -1) {
            var activedTab;
            if (this.props.showingTabName == "Abstract_From_CRStar_Shortcuts") {
                activedTab = "Tabid_Abstract";
            } else if (this.props.showingTabName == "TxSum_From_CRStar_Shortcuts") {
                activedTab = "Tabid_Tx_Summary/Custom";
            } else if (this.props.showingTabName == "SCP_From_CRStar_Shortcuts") {
                activedTab = "Tabid_SCP";
            } else if (this.props.showingTabName == "Delete_From_CRStar_Shortcuts") {
                activedTab = "Tabid_Delete_Abstract";
            } else if (this.props.showingTabName == "Copy_From_CRStar_Shortcuts") {
                activedTab = "Tabid_Copy_Abstract";
            }

            setTimeout(function () {
                document.getElementById("alphaAbsMngMenu") && document.getElementById("alphaAbsMngMenu").nextSibling.querySelector(".collapse") && document.getElementById("alphaAbsMngMenu").nextSibling.querySelector(".collapse").getAttribute("class").indexOf("show") == -1 ? document.getElementById("alphaAbsMngMenu").click() : null;
                document.getElementById(activedTab) ? document.getElementById(activedTab).click() : null;
            }, 0);

            this.props.setTabName(null);
            this.setState({ expandedMenu: false });
        } else if (this.props.showingTabName == " Select a Population " || this.props.showingTabName == " Select A Pop ") {
            if (!this.state.expandedMenu) {
                this.setState({ expandedMenu: true });
                setTimeout(function () {
                    document.getElementById("crstarReportingMenu") && document.getElementById("crstarReportingMenu").nextSibling.querySelector(".collapse") && document.getElementById("crstarReportingMenu").nextSibling.querySelector(".collapse").getAttribute("class").indexOf("show") == -1 ? document.getElementById("crstarReportingMenu").click() : null;
                }, 0);
                return false;
            }
        } else if (/*this.props.showingTabName == "Lookup" ||*/ this.props.showingTabName == " Patient Lookup ") {
            if (!this.state.expandedMenu) {
                this.setState({ expandedMenu: true });
                setTimeout(function () {
                    document.getElementById("alphaAbsMngMenu") && document.getElementById("alphaAbsMngMenu").nextSibling.querySelector(".collapse") && document.getElementById("alphaAbsMngMenu").nextSibling.querySelector(".collapse").getAttribute("class").indexOf("show") == -1 ? document.getElementById("alphaAbsMngMenu").click() : null;
                }, 0);
                return false;
            }
        }
    }

    closeWindow = () => {
        this.props.setParentWindowVisible(false);
        // @TODO: Tabs component...
        this.props.routingPage(dialogTypes.LOOKUP);
        this.props.addTabItems(dialogTypes.LOOKUP);
    }

    onClickTabItem = async (e, tab, controlNameId) => {

        //#region:: Abstract Date that need validation adjustments.
        /*
        Diagnosis - CoV-2 Pos Date
        Outcomes - Date Last Contact + Date Cancer Status Last Changed
        CPM - Date SCP Generated/Delivered + Date QA Performed + Screening Date
        BPM - Mammogram Date + Ultrasound Date + Mri Date + Plastic Surg Referral Date + Screening Date + Date SCP Generated/Delivered
        RPM - Date of Pretreatment MRI + Date of PET/CT + Date of Initial Eval at RCP + Date Post Surg Tx Outcome Discussed + Date Post Surg Tx Outcome Provided
        User Defined - Udef 41 -> Udef 50
        Case Admin - Date Abstract
        */
        var badDateId = null;
        if (document.getElementById("diagnosisForm")) {
            console.log('==diagnosisForm==');
            let dId = "ncdbSarsCov2PositiveDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("outcomesForm")) {
            console.log('==outcomesForm==');
            let dId = "dateLastCancerStatus";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            let gLen = document.getElementById("outcomesMvListArea").querySelectorAll("table>tbody>tr").length;
            for (var gi = 0; gi < gLen; gi++) {
                let gId = "dtLstCont" + gi;
                let gVl = document.getElementById(gId) ? document.getElementById(gId).value : null;
                if (gVl && !moment(gVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = gId;
                    break;
                }
            }

        } else if (document.getElementById("stateFieldsForm")) {
            console.log('==stateFieldsForm==');//IA State Fields screen
            let dId = "iaHeightWeightDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("cocForm")) {
            console.log('==cocForm==');
            let dId = "dateScpGenerated";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "dateQaPerformed";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "screenDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (badDateId) {
                document.getElementById("linkOfCCServicesTab") ? document.getElementById("linkOfCCServicesTab").click() : null;
            }

        } else if (document.getElementById("napbcForm")) {
            console.log('==napbcForm==');
            let dId = "mammoDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "ultrasoundDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "mriDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "plasticSurgRefDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "screeningDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "dateScpGeneratedBpm";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

        } else if (document.getElementById("rectalProgramForm")) {
            console.log('==rectalProgramForm==');
            let dId = "rpmMriDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "rpmPetCtDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateInitEval";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateTxOutcomeDiscussed";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateTxOutcomeProvided";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

        } else if (document.getElementById("userDefinedForm")) {
            console.log('==userDefinedForm==');
            let dId = "udef";
            let iBadNo = 0;
            for (var si = 41; si <= 50; si++) {
                let gId = dId + si;
                let gVl = document.getElementById(gId) ? document.getElementById(gId).value : null;
                if (gVl && !moment(gVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = gId;
                    iBadNo = si;
                    break;
                }
            }

            if (iBadNo != 0 && iBadNo <= 45) {
                document.getElementById("linkOfUserDefined1Tab") ? document.getElementById("linkOfUserDefined1Tab").click() : null;
            } else if (iBadNo != 0 && iBadNo > 45) {
                document.getElementById("linkOfUserDefined2Tab") ? document.getElementById("linkOfUserDefined2Tab").click() : null;
            }

        } else if (document.getElementById("caseAdminForm")) {
            console.log('==caseAdminForm==');
            let dId = "dateAbst";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("dashboardSettingsForm")) {
            console.log('==dashboardSettingsForm==');
            let dId = "casesCompletedBeginDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "casesCompletedEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "casesAbstractedBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "casesAbstractedEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpEnteredBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpEnteredEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "lastFollowUpEnteredBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "lastFollowUpEnteredEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            /*
             * Disabled input fields so don't need to check valid input
            if (!badDateId) {
                dId = "followUpCalcsBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpCalcsEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            */
            if (!badDateId) {
                dId = "rqrsBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "rqrsEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "stagedByBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "stagedByEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "suspenseBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "suspenseBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "timelinessBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "timelinessEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "top5SitesBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "top5SitesEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredFromBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredFromEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredToBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredToEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "classBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "classBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "countyBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "countyBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "inOutMigrationBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "inOutMigrationEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
        }

        if (!this.props.inquiringMode && badDateId) {
            //alert('Please input a valid date');
            document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
            setTimeout(function () {
                document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
                return false;
            }, 500);
            return false;
        }
        // --End #region

        document.querySelector('#showLoadingOnTopPage') ? document.querySelector('#showLoadingOnTopPage').setAttribute('class', '') : null;
        this.props.showPageLoading(true);

        if (controlNameId) {
            this.props.setControlName(controlNameId);
            console.log("Control name = " + controlNameId);
        }

        var tabId = null;
        try {
            e ? e.preventDefault() : "";
            tabId = e.target.id;

            if (tabId == "Tabid_Delete_Abstract") {
                setTimeout(function () {
                    document.getElementById("btnAddNewItem0") ? document.getElementById("btnAddNewItem0").click() : null;
                }, 2000);
            }
        } catch (ex) { }

        /*
        var _this = this;
        setTimeout(function () {
            if (!_this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") !== -1) {
                document.querySelector(".inquiringCls") ? document.querySelector(".inquiringCls").setAttribute("class", "inquiringCls hidden") : null;
            } else {
                document.querySelector(".inquiringCls") ? document.querySelector(".inquiringCls").setAttribute("class", "inquiringCls") : null;
            }
        }, 3000);
        */

        //
        var stopThisProcess = false;
        var diagnosisForm = document.getElementById("diagnosisForm");
        if (diagnosisForm) {
            var Topo = document.getElementById("topoCode") ? document.getElementById("topoCode").value : "";
            var Hist = document.getElementById("histCode") ? document.getElementById("histCode").value : "";

            var Dx_Year = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
            var Previous_Topo = this.props.selectedDiagnosisRecord ? this.props.selectedDiagnosisRecord["topoCode"] : "";
            var Previous_Hist = this.props.selectedDiagnosisRecord ? this.props.selectedDiagnosisRecord["histCode"] : "";
            if (((Previous_Topo != '' && Previous_Topo != Topo) || (Previous_Hist != '' && Previous_Hist != Hist)) && Dx_Year >= 2018) {
                stopThisProcess = true;
            }
        }

        if (!stopThisProcess) {
            await this.props.showPageLoading(true);
        }

        // Fixing #PR00225: save data on form FIRST
        var isFormDirty = document.getElementById("isFormDirtyAlias_TextHiddenField") && document.getElementById("isFormDirtyAlias_TextHiddenField").value == "1" ? true : false;
        if ((this.props.inquiringMode && this.props.alphaUrl !== "/patient-abstract" && this.props.alphaUrl !== "/rapid-abstract") ||
            (!this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") != -1) ||
            (!this.props.inquiringMode && isFormDirty && (!this.props.availableHospsData
                || (this.props.availableHospsData != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "01") !== -1
                    && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1)))) {
            var saveBtn = document.getElementById("btnSubmitFormIsHidden");

            var _thisObj = this;
            await setTimeout(function () {
                _thisObj.props.clearCommonLookupItem();
                // Not allow auto call submit form with all screen on Print Forms menu
                saveBtn && (tabId != "Tabid_Abstract" && tabId != "Tabid_Tx_Summary/Custom" && tabId != "Tabid_QA" && tabId != "Tabid_SCP"
                    && tabId != "Tabid_Follow-up/QA_Letters" && tabId != "Tabid_Custom_Forms") ? saveBtn.click() : "";
                return false;
            }, 0);
        }

        if (stopThisProcess && !this.props.inquiringMode) {
            await this.props.setHandlerControlItem(tabId);
            return false;
        }

        if (tab === dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB1 ||
            tab === dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB2) {
            this.props.clearAllExportData();
        }

        var _this = this;
        await setTimeout(function () {
            _this.setState({ activeTab: tab });
            _this.props.setTab(tab);
            //_this.props.setDialog(tab);

            // Starting all the Abstarct pages
            if (tab === "Patient") {
                _this.props.selectPatient(_this.props.patientId, false, true);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Diagnosis") {
                _this.props.selectMedicalRecord(_this.props.selectedPrimarySummary, _this.props.patientId);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Staging") {
                _this.props.selectStagingRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Treatment") {
                _this.props.selectTreatmentRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Outcomes") {
                _this.props.selectOutcomesRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "CPM") {
                _this.props.selectCocRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "BPM") {
                _this.props.selectNapbcRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "RPM") {
                _this.props.selectRectalProgramRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Case Admin") {
                _this.props.selectCaseAdminRecord(_this.props.selectedPrimarySummary, _this.props.loggedOnUid);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Clinical") {
                _this.props.selectClinicalRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Research") {
                _this.props.selectResearchRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "User Defined") {
                _this.props.selectUserDefinedRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "State Specific") {
                //check if whether current page is NM State Screen? ...to get data correctly
                const state = _this.props.currentShowingState;
                if (state === "NM") {
                    _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "Nm");
                } else {
                    _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "");
                }
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Retired") {
                _this.props.selectRetiredRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            }/* Ending of Abstract pages list */

            // System Administration/Security Maintenance
            else if (tab === "User Maintenance") {
                let email = _this.props.selectedUserOnGrid ? _this.props.selectedUserOnGrid.userid : "";
                let cligrp = _this.props.clientgroup ? _this.props.clientgroup : "";
                _this.props.selectUserInfoRecord(email, cligrp);
            }
            else if (tab === "Password Policy")
                _this.props.loadPwdPolicy(_this.props.loggedOnUid);
            else if (tab === "Locked Users")
                _this.props.showLockedUsers(_this.props.clientgroup);
            else if (tab === "User Security") {
                _this.props.setAlphaUrl("/UserSecurityMaintLookup");
                _this.props.setPageLoaded(false);
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === "User Logins") {
                _this.props.setAlphaUrl("/SysAdmSecurityMaint");
                _this.props.setPageLoaded(false);
                _this.props.setInitialPageVisible(true);
            }
            // CRStar Reporting Menu
            //#1
            else if (tab === "Site Distribution") {
                _this.props.setAlphaUrl("/SiteDistIncidence");
                _this.props.setInitialPageVisible(true);
                _this.props.selectAPopLabel("");
            }
            else if (tab === "Treatment Distribution") {
                _this.props.setAlphaUrl("/TreatmentRpt");
                _this.props.setInitialPageVisible(true);
                _this.props.selectAPopLabel("");
            }
            else if (tab === "Survival") {
                _this.props.setAlphaUrl("/Survival");
                _this.props.setInitialPageVisible(true);
                _this.props.selectAPopLabel("");
            }
            //#2
            else if (tab === dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB1) {
                _this.props.setAlphaUrl("/Suspense");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB2) {
                _this.props.setAlphaUrl("/Incomplete");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB3) {
                _this.props.setAlphaUrl("/Productivity");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB4) {
                _this.props.setAlphaUrl("/Timeliness");
                _this.props.setInitialPageVisible(true);
                _this.props.selectAPopLabel("");
            }
            //#3
            else if (tab === dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB1) {
                _this.props.setAlphaUrl("/AccRosterMpi");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB2) {
                _this.props.setAlphaUrl("/RequestLog");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB3) {
                _this.props.setAlphaUrl("/ReportFile");
                _this.props.setInitialPageVisible(true);
                _this.props.selectRfLabels();
            }
            //#4
            else if (tab === dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB1) {
                _this.props.setAlphaUrl("/QualityMeasures");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB2) {
                _this.props.setAlphaUrl("/CoC");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB3) {
                _this.props.setAlphaUrl("/Napbc");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB4) {
                _this.props.setAlphaUrl("/Naprc");
                _this.props.setInitialPageVisible(true);
            }
            //#5
            else if (tab === dialogTypes.REPORTING_REA_COMPARISON_REPORTS_TAB1) {
                _this.props.setAlphaUrl("/StageComparisons");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_COMPARISON_REPORTS_TAB2) {
                _this.props.setAlphaUrl("/CrossTab");
                _this.props.setInitialPageVisible(true);
            }
            //#6
            else if (tab === dialogTypes.REPORTING_REA_ADHOC_REPORTS_TAB1) {
                _this.props.setAlphaUrl("/ListPop");
                _this.props.setInitialPageVisible(true);
                // Loading form data...
                _this.props.selectReportLabels();
                _this.props.selectAPopLabel("");//Loading data for Population Label component
            }
            else if (tab === dialogTypes.REPORTING_REA_ADHOC_REPORTS_TAB2) {
                _this.props.setAlphaUrl("/GraphPop");
                _this.props.setInitialPageVisible(true);
                _this.props.selectAPopLabel("");
            }
            //#7
            //else if (tab === dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1) {
            //    _this.props.setAlphaUrl("/AgeBySex");
            //    _this.props.setInitialPageVisible(true);
            //}
            //else if (tab === dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB2) {
            //    _this.props.setAlphaUrl("/StageBySex");
            //    _this.props.setInitialPageVisible(true);
            //}
            else if (tab === dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1) {
                _this.props.setAlphaUrl("/TNMGraph");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB2) {
                _this.props.setAlphaUrl("/DaysToTreatment");
                _this.props.setInitialPageVisible(true);
            }
            //#8
            else if (tab === dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB1) {
                _this.props.clearAllExportData();
                _this.props.setAlphaUrl("/StateExport");
                _this.props.setInitialPageVisible(true);
                // Loading form data...
                _this.props.selectReportLabels("");
            }
            else if (tab === dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB2) {
                _this.props.clearAllExportData();
                _this.props.setAlphaUrl("/NcdbRcrsExport");
                _this.props.setInitialPageVisible(true);
                // Loading form data...
                _this.props.selectReportLabels("");
            }
            else if (tab === dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB3) {
                _this.props.setAlphaUrl("/ExportViewer");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.REPORTING_UTILITY_TAB1) {
                _this.props.setAlphaUrl("/ClearPopulations");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === "Hospitals") {
                _this.props.setAlphaUrl("/HospitalsMaintLookup");
                _this.props.setInitialPageVisible(true);
            }
            /*
            else if (tab === "Registrars")
                _this.props.setAlphaUrl("/HospitalsMaintLookup");
                _this.props.setInitialPageVisible(true);
            else if (tab === "Physicians")
                _this.props.setAlphaUrl("/HospitalsMaintLookup");
                _this.props.setInitialPageVisible(true);
            */
            else if (tab === "Phys Roster") {
                _this.props.generatePhysiciansRoster();
                _this.props.setAlphaUrl("/HospitalsMaintLookup");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === "Initialize Acc") {
                _this.props.loadAccNbrsMaint();
                _this.props.loadAccNbrsMaintInit();
                _this.props.setAlphaUrl("/InitializeAcc");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === "Follow-up Order") {
                _this.props.loadFollowUpOrder(0);
                _this.props.setAlphaUrl("/FollowUpOrder");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === "Follow-up/QA Letters") {
                //_this.props.setAlphaUrl("/HospitalsMaintLookup");
                _this.props.loadLettersMaint();
                _this.props.setAlphaUrl("/LettersMaint");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === "Custom Forms") {
                _this.props.loadCustomFormsMaint();
                _this.props.setAlphaUrl("/CustomFormsMaint");
                _this.props.setInitialPageVisible(true);
            }

            //--
            else if (tab === "Genedits") {
                _this.props.setAlphaUrl("/EditsSettingsMaint");
                _this.props.loadGenEditsOnInitial();
                _this.props.loadGenEditsInfo(_this.props.userId);
                _this.props.setInitialPageVisible(true);
            } else if (tab === "Dashboard ") {
                _this.props.setAlphaUrl("/DashboardSettings");
                _this.props.loadDashboardSettingsInfo(_this.props.userId, _this.props.selectedHospital);
                _this.props.setInitialPageVisible(true);
            }

            else if (tab == "Delete Abstract") {
                _this.props.setAlphaUrl("/AbstractUtils");
                _this.props.loadPatientAbsInfo(0);
                _this.props.setInitialPageVisible(true);
            } else if (tab === "Copy Abstract") {
                _this.props.setAlphaUrl("/CopyAbstract");
                _this.props.loadPatientAbsInfo(0);
                _this.props.setInitialPageVisible(true);
            } else if (tab === "Combine Patients") {
                _this.props.setAlphaUrl("/CombinePatients");
                _this.props.loadPatientAbsInfo(0);
                _this.props.setInitialPageVisible(true);
            } else if (tab === "Abstract" || tab === "Tx Summary/Custom" || tab === "QA" || tab === "SCP") {
                _this.props.loadPatientAbsInfo(0);
                _this.props.setAlphaUrl("/PrintForms");
                _this.props.setInitialPageVisible(true);
            }
            //--
            else if (tab === dialogTypes.NLP_WORK_QUEUE_TAB) {
                _this.props.setAlphaUrl("/NlpWorkQueue");
                _this.props.setInitialPageVisible(true);
            }
            // System Administration/DataManagment
            //else if (tab === dialogTypes.DATA_MANAGMENT_TAB1) {
            else if (tab === "Global Change") {
                _this.props.setAlphaUrl("/GlobalChange");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.DATA_MANAGMENT_TAB3) {
                _this.props.setAlphaUrl("/MassDelete");
                _this.props.setInitialPageVisible(true);
                _this.props.massDeleteInfo();
            }
            else if (tab == "Import") {
                _this.props.setAlphaUrl("/CrstarImport");
                _this.props.setInitialPageVisible(true);
                _this.props.loadImportConfig("Initial_Import_Screen");
            }

            // System Administration/Utilities
            else if (tab === "Global Dir") {
                _this.props.setAlphaUrl("/GlobalDirMaintLookup");
                _this.props.setInitialPageVisible(true);
            }
            else if (tab === dialogTypes.UTILITIES_IMPORT_CONFIG) {
                _this.props.setInitialPageVisible(true);
                _this.props.loadImportConfig("");
            }
            else if (tab === dialogTypes.UTILITIES_DB) {
                _this.props.setAlphaUrl("/DBValidation");
                _this.props.setInitialPageVisible(true);
                _this.props.databaseValidationInfo();

            } else if (tab === dialogTypes.SYSMAN_ZIP_CODES_MAINT) {
                _this.props.setAlphaUrl("/ZipCodesMaint");
                _this.props.setInitialPageVisible(true);
            }

            _this.props.showPageLoading(false);
        }, 1000);

        return false;
    }

    render() {
        if (!this.state.resetCurrentSelectedIndex) {
            this.props.resetCurrentSelectedIndex(-1);
            this.setState({ resetCurrentSelectedIndex: true });
        }

        let dialog;
        switch (this.props.currentDialog) {
            case dialogTypes.LOOKUP:
                dialog = <PatientAbstract closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PATIENT:
                dialog = <PatientMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.DIAGNOSIS:
                dialog = <DiagnosisMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.STAGING:
                dialog = <StagingMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.TREATMENT:
                dialog = <TreatmentMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.OUTCOMES:
                dialog = <OutcomesMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.CPM:
                dialog = <CancerProgramMaintenance closeWindow={this.closeWindow} />
                break;
            case dialogTypes.BPM:
                dialog = <BreastProgramMaintenance closeWindow={this.closeWindow} />
                break;
            case dialogTypes.RPM:
                dialog = <RectalProgramMaintenance closeWindow={this.closeWindow} />
                break;
            case dialogTypes.CASE_ADMIN:
                dialog = <CaseAdminMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.CLINICAL:
                dialog = <ClinicalMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.RESEARCH:
                dialog = <ResearchMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.USER_DEFINED:
                dialog = <UserDefinedMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.STATE_SPECIFIC:
                dialog = <StateSpecificMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.RETIRED:
                dialog = <RetiredMaintenance closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.ALPHA_MENU:
                dialog = <Aframe closeWindow={this.closeWindow} url={this.props.alphaUrl} />;
                break;

            case dialogTypes.LOGIN:
                dialog = <Login>
                    <Switch>
                        <Route path="/login" component={Auth} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Redirect to="/login" />
                    </Switch>
                </Login>;
                break;

            // Reporting - Select A Population
            case dialogTypes.REPORTING_SELECT_A_POPULATION:
                dialog = <SelectAPop closeWindow={this.closeWindow} />;
                break;

            /*
            case dialogTypes.REPORTING_GRAPH_AN_ITEM:
                this.props.selectAPopLabel();
                dialog = <GraphAnItem closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_SURVIVAL_BY_STAGE:
                this.props.selectAPopLabel();
                dialog = <SurvivalByStage closeWindow={this.closeWindow} />;
                break;
                */

            //#PR00485
            //#1
            case dialogTypes.REPORTING_REA_CANNED_REPORTS_TAB1:
                dialog = <SiteDistIncidence closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_CANNED_REPORTS_TAB2:
                dialog = <TreatmentRpt closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_CANNED_REPORTS_TAB3://Survival By Stage
                //!this.props.selectedLabelPopInfo ? this.props.selectAPopLabel() : null;
                dialog = <SurvivalByStage closeWindow={this.closeWindow} />;
                break;

            //#2
            case dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB1:
                dialog = <Suspense closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB2:
                dialog = <Incomplete closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB3:
                dialog = <Productivity closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB4:
                dialog = <Timeliness closeWindow={this.closeWindow} />;
                break;

            //#3
            case dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB1:
                dialog = <AccRosterMpi closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB2:
                dialog = <RequestLog closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ADMIN_REPORTS_TAB3:
                dialog = <ReportFile closeWindow={this.closeWindow} />;
                break;

            //#4
            case dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB2:
                dialog = <Coc closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB3:
                dialog = <Napbc closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB4:
                dialog = <Naprc closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS_TAB1:
                dialog = <QualityMeasures closeWindow={this.closeWindow} />;
                break;

            //#5
            case dialogTypes.REPORTING_REA_COMPARISON_REPORTS_TAB1:
                dialog = <StageComparisons closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_COMPARISON_REPORTS_TAB2:
                dialog = <CrossTab closeWindow={this.closeWindow} />;
                break;

            //#6
            case dialogTypes.REPORTING_REA_ADHOC_REPORTS_TAB1:
                dialog = <ListPop closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ADHOC_REPORTS_TAB2:
                //!this.props.selectedLabelPopInfo ? this.props.selectAPopLabel() : null;
                dialog = <GraphAnItem closeWindow={this.closeWindow} />;
                break;

            //#7
            //case dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1:
            //    dialog = <AgeBySex closeWindow={this.closeWindow} />;
            //    break;
            //case dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB2:
            //    dialog = <StageBySex closeWindow={this.closeWindow} />;
            //    break;
            case dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1:
                dialog = <TNMGraph closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS_TAB2:
                dialog = <DaysToTreatment closeWindow={this.closeWindow} />;
                break;

            //#8
            case dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB1:
                dialog = <StateExport closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB2:
                dialog = <NcdbRcrsExport closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_DATA_EXPORTS_TAB3:
                dialog = <ExportViewer closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.REPORTING_UTILITY_TAB1:
                dialog = <ClearPopulations closeWindow={this.closeWindow} />;
                break;

            /*
            case dialogTypes.REPORTING_REA_REGISTRY_MGMT_REPORTS:
                dialog = <ReaRegistryMgmtReports closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ADMIN_REPORTS:
                dialog = <ReaAdminReports closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ACCREDITATION_REPORTS:
                dialog = <ReaAccreditationReports closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_COMPARISON_REPORTS:
                dialog = <ReaComparisonReports closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_ADHOC_REPORTS:
                dialog = <ReaAdhocReports closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_MISCELLANEOUS_REPORTS:
                dialog = <ReaMiscellaneousReports closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_REA_DATA_EXPORTS:
                dialog = <ReaDataExports closeWindow={this.closeWindow} />;
                break;

            /*
            case dialogTypes.REPORTING_GRAPH_AN_ITEM:
                this.props.selectAPopLabel();
                dialog = <GraphAnItem closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_SURVIVAL_BY_STAGE:
                this.props.selectAPopLabel();
                dialog = <SurvivalByStage closeWindow={this.closeWindow} />;
                break;
            */

            //
            case dialogTypes.USER_LOGINS:
                dialog = <UserSecurityMaintLookup closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.USER_MAINTENANCE:
                dialog = <UserMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PASSWORD_POLICY:
                dialog = <PasswordPolicyMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.LOCKED_USER:
                dialog = <DisplayLockedUsers closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.USER_SECURITY:
                dialog = <UserSecurityLookup closeWindow={this.closeWindow} />;
                // let url = "/UserSecurityMaintLookup";
                // this.props.setAlphaUrl(url);
                // dialog = <Aframe closeWindow={this.closeWindow} url={url} />;
                break;

            case dialogTypes.DATA_MANAGMENT_TAB1:
                dialog = <GlobalChange closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.DATA_MANAGMENT_TAB2:
                dialog = <CrstarImport closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.DATA_MANAGMENT_TAB3:
                dialog = <MassDelete closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.UTILITY_PAGE:
                dialog = <UtilityPage closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.GLOBAL_DIR_LOOKUP:
                dialog = <GlobalDirMaintLookup closeWindow={this.closeWindow} />;
                break;

            /*
            case dialogTypes.SYSTEM_MANAGEMENT_MAINT://#
                dialog = <ReaSystemMgmt closeWindow={this.closeWindow} />;
                break;
            */
            case dialogTypes.HOSPITALS_MAINT_LOOKUP:
                dialog = <HospitalsMaintLookup closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REGISTRAR_MAINT_LOOKUP:
                dialog = <RegistrarMaintLookup closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PHYSICIANS_MAINT_LOOKUP:
                dialog = <PhysiciansMaintLookup closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PHYSICIAN_ROSTER:
                dialog = <PhysicianRoster closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.INITIALIZE_ACC_NBR:
                dialog = <InitializeAccNbr closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.FOLLOW_UP_ORDER:
                dialog = <FollowUpOrder closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.FOLLOW_UP_QA_LETTERS:
                //let _url = "/LettersMaint";
                //this.props.setAlphaUrl(_url);
                //dialog = <Aframe closeWindow={this.closeWindow} url={_url} />;
                dialog = <LettersMaint closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.CUSTOM_FORMS:
                //let _url2 = "/CustomFormsMaint";
                //this.props.setAlphaUrl(_url2);
                //dialog = <Aframe closeWindow={this.closeWindow} url={_url2} />;
                dialog = <CustomFormsMaint closeWindow={this.closeWindow} />;
                break;

            //
            case dialogTypes.SYSTEM_CONFIGURATION_GENEDITS:
                dialog = <EditsSettingsMaint closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.SYSTEM_CONFIGURATION_DASHBOARD:
                dialog = <DashboardSettings closeWindow={this.closeWindow} />;
                break;
            //

            case dialogTypes.ABSTRACT_UTILITIES_DELETE_ABSTRACT:
                dialog = <DeletePatientAbstactMaint closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.ABSTRACT_UTILITIES_COPY_ABSTRACT:
                dialog = <CopyAbstract closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.ABSTRACT_UTILITIES_COMBINE_PATIENTS:
                dialog = <MergePatients closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.PF_PRINT_ABSTRACTS:
                dialog = <PrintAbstractsMaint closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PF_PRINT_TX_SUMMARY:
                dialog = <PrintTxSummary closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PF_PRINT_QA_FORM:
                dialog = <PrintQAForm closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PF_PRINT_SCP_FORM:
                dialog = <PrintSCPForm closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.NLP_WORK_QUEUE_TAB:
                dialog = <NlpWorkQueue closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.UTILITIES_GLOBAL_DIR:
                dialog = <UtilMainTabs closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.UTILITIES_IMPORT_CONFIG:
                dialog = <ImportConfig closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.UTILITIES_DB:
                dialog = <DBValidation closeWindow={this.closeWindow} />;
                break;

            // Zip Codes Maintenance - Lookup screen
            case dialogTypes.SYSMAN_ZIP_CODES_MAINT:
                dialog = <ZipCodesLookup closeWindow={this.closeWindow} />;
                break;

            default:
                dialog = null;
                break;
        }

        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        let showSessionTimeoutMsg;
        {
            const msg = "Server session has been timeout. Please re-login.";
            showSessionTimeoutMsg = this.state.hasError ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.handleLogout(e, msg) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{msg}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.handleLogout(e, msg) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let isSecurityTabs = false;
        {
            this.props.tabItems ? this.props.tabItems.map((child, index) => {
                if (child.name) isSecurityTabs = true;
            }) : null;
        }

        return (
            this.state.isLogin ?
                <Login>
                    <Switch>
                        <Route path="/login" component={Auth} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Redirect to="/login" />
                    </Switch>
                </Login>
                :
                <div className={"tabs " + (isSecurityTabs ? this.visibleType(this.props.controlName ? this.props.controlName : this.props.currentDialog) : "") + " " + (this.props.controlName ? this.props.controlName : this.props.currentDialog)}>
                    <ol className="tab-list">
                        {
                            this.props.tabItems ? this.props.tabItems.map((child, index) => {
                                return (isSecurityTabs ? (child.name ?
                                    <Tab
                                        activeTab={this.props.currentDialog}
                                        key={this.props.currentDialog == dialogTypes.ALPHA_MENU ? this.props.showingTabName : (child.name ? child.name : child)}
                                        label={this.props.currentDialog == dialogTypes.ALPHA_MENU && this.props.showingTabName != "ON_LEFT_MENU" && this.props.showingTabName != "ALPHA MENU" ? this.props.showingTabName : (child.name ? child.name : child)}
                                        onClick={(e) => { this.onClickTabItem(e, (child.name ? child.name : child), isSecurityTabs ? child.id : null) }}
                                        disabled={!this.props.tabStatuses[index]}
                                        showOrHide={isSecurityTabs ? this.visibleType(child.id ? child.id : child) : this.showOrHide(index)}
                                    />
                                    : null) :
                                    <Tab
                                        activeTab={this.props.currentDialog}
                                        key={this.props.currentDialog == dialogTypes.ALPHA_MENU ? this.props.showingTabName : (child.name ? child.name : child)}
                                        label={this.props.currentDialog == dialogTypes.ALPHA_MENU && this.props.showingTabName != "ON_LEFT_MENU" && this.props.showingTabName != "ALPHA MENU" ? this.props.showingTabName : (child.name ? child.name : child)}
                                        onClick={(e) => { this.onClickTabItem(e, (child.name ? child.name : child), isSecurityTabs ? child.id : null) }}
                                        disabled={!this.props.tabStatuses[index]}
                                        showOrHide={isSecurityTabs ? this.visibleType(child.id ? child.id : child) : this.showOrHide(index)}
                                    />
                                );
                            }) : null
                        }
                    </ol>

                    <div className="tab-content" id="identify_main_view_area">
                        {
                            children ? children.map((child, index) => {
                                if (activeTab !== dialogTypes.LOOKUP)
                                    return <div id="showingDataForm" className="dialogAliasCls" key={index}><div id="showLoadingOnTopPage">Working...</div>{dialog}</div>;
                                return dialog ? <div className="dialogAliasCls"><div id="showLoadingOnTopPage">Working...</div>{dialog}</div> : <div className="childrenPageAliasCls">{child.props.children}</div>;
                            }) : null
                        }
                    </div>

                    <div style={{ display: "none" }}>
                        <IdleTimer
                            ref={ref => { this.idleTimer = ref }}
                            element={document}
                            onActive={this.onActive}
                            onIdle={this.onIdle}
                            onAction={this.onAction}
                            //debounce={250}
                            timeout={this.state.timeout} />
                        <div className="">
                            <Switch>
                                <Route
                                    exact
                                    path="/alpha-dashboard"
                                    render={(props) => <Aframe closeWindow={this.closeWindow} url={this.props.alphaUrl} />}
                                />
                            </Switch>

                            {
                                this.props.changedPwd ? null :
                                    <IdleTimeOutModal
                                        showModal={this.state.showModal}
                                        handleClose={this.handleClose}
                                        handleLogout={this.handleLogout}
                                    />
                            }

                        </div>
                    </div>

                    <div>
                        {showSessionTimeoutMsg}
                    </div>

                </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        authRedirectPath: state.auth.authRedirectPath,
        routingPage: state.patientAbstract.routingPage,
        currentDialog: state.patientAbstract.currentDialog,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        tabItems: state.patientAbstract.tabItems,
        tabStatuses: state.patientAbstract.tabStatuses,
        patientId: state.patientAbstract.patientId,
        userId: state.auth.userId,
        alphaUrlInnerTab: state.patientAbstract.alphaUrl,
        alphaUrl: window.location ? window.location.pathname : "",
        showingTabName: state.patientAbstract.showingTabName,
        availableItems: state.patientAbstract.availableItems,
        loggedOnUid: state.auth.userId,
        selectedUserOnGrid: state.patientAbstract.selectedUserOnGrid,
        selectedUserInfo: state.patientAbstract.selectedUserInfo,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,
        clientgroup: state.auth.clientgroup,
        crstarSessionTimeout: state.auth.expiresIn,
        currentShowingState: state.patientAbstract.currentShowingState,
        inquiringMode: state.auth.inquiringMode,
        rapidAbstract: state.patientAbstract.rapidAbstract,
        showSessionTimeoutMsg: state.patientAbstract.showSessionTimeoutMsg,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        selectedHospital: state.patientAbstract.selectedHospital,
        availableHospsData: state.auth.availableHospsData,
        controlName: state.patientAbstract.controlName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (uid, cg, sval, msg) => dispatch(actions.logout(uid, cg, sval, msg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        selectUser: (uId) => dispatch(actionCreators.selectUser(uId)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        selectPatient: (patientId, isAddNew, onTab) => dispatch(actionCreators.selectPatient(patientId, isAddNew, onTab)),
        selectStagingRecord: (medial) => dispatch(actionCreators.selectStagingRecord(medial)),
        selectTreatmentRecord: (medial) => dispatch(actionCreators.selectTreatmentRecord(medial)),
        selectOutcomesRecord: (medial) => dispatch(actionCreators.selectOutcomesRecord(medial)),
        selectCocRecord: (medial) => dispatch(actionCreators.selectCocRecord(medial)),
        selectNapbcRecord: (medial) => dispatch(actionCreators.selectNapbcRecord(medial)),
        selectRectalProgramRecord: (medial) => dispatch(actionCreators.selectRectalProgramRecord(medial)),
        selectCaseAdminRecord: (medial, uid) => dispatch(actionCreators.selectCaseAdminRecord(medial, uid)),
        selectClinicalRecord: (medial) => dispatch(actionCreators.selectClinicalRecord(medial)),
        selectResearchRecord: (medial) => dispatch(actionCreators.selectResearchRecord(medial)),
        selectUserDefinedRecord: (medial) => dispatch(actionCreators.selectUserDefinedRecord(medial)),
        selectStateFieldsRecord: (medial, nm) => dispatch(actionCreators.selectStateFieldsRecord(medial, nm)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        selectRetiredRecord: (medial) => dispatch(actionCreators.selectedRetiredRecord(medial)),
        selectUserInfoRecord: (email, cligrp) => dispatch(actionCreators.selectUserInfoRecord(email, cligrp)),
        selectUserSecurityRecord: (email) => dispatch(actionCreators.selectUserSecurityRecord(email)),
        loadPwdPolicy: (uid) => dispatch(actionCreators.loadPwdPolicy(uid)),
        showLockedUsers: (group) => dispatch(actionCreators.showLockedUsers(group)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        resetCurrentSelectedIndex: (idx) => dispatch(actionCreators.resetCurrentSelectedIndex(idx)),
        setRapidAbstract: (value) => dispatch(actionCreators.setRapidAbstract(value)),
        showPageLoading: (value) => dispatch(actionCreators.showPageLoading(value)),
        //
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
        // Crstar Reporting Menu
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        //System Management Menu
        generatePhysiciansRoster: (flag) => dispatch(actionCreators.generatePhysiciansRoster(flag)),
        loadAccNbrsMaint: (val) => dispatch(actionCreators.loadAccNbrsMaint(val)),
        loadFollowUpOrder: (val) => dispatch(actionCreators.loadFollowUpOrder(val)),
        loadAccNbrsMaintInit: () => dispatch(actionCreators.loadAccNbrsMaintInit()),

        //
        loadGenEditsInfo: (name) => dispatch(actionCreators.loadGenEditsInfo(name)),
        loadGenEditsOnInitial: () => dispatch(actionCreators.loadGenEditsOnInitial()),
        loadDashboardSettingsInfo: (name, hosp) => dispatch(actionCreators.loadDashboardSettingsInfo(name, hosp)),

        //
        loadPatientAbsInfo: (pid) => dispatch(actionCreators.loadPatientAbsInfo(pid)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        setControlName: (name) => dispatch(actionCreators.setControlName(name)),

        selectRfLabels: (label) => dispatch(actionCreators.selectRfLabels(label)),
        selectReportLabels: (label) => dispatch(actionCreators.selectReportLabels(label)),

        loadLettersMaint: (name) => dispatch(actionCreators.loadLettersMaint(name)),
        loadCustomFormsMaint: (name) => dispatch(actionCreators.loadCustomFormsMaint(name)),//
        loadImportConfig: (label) => dispatch(actionCreators.loadImportConfigInfo(label)),
        databaseValidationInfo: () => dispatch(actionCreators.databaseValidationInfo()),
        massDeleteInfo: () => dispatch(actionCreators.massDeleteInfo()),

        selectMedicalRecord: (medicalRecord, patientId) => dispatch(actionCreators.selectMedicalRecord(medicalRecord, patientId)),
        clearAllExportData: () => dispatch(actionCreators.clearAllExportData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
