/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../TreatmentMaintenance.css";
import "./SurgeryMaintenance.css";
import moment from "moment";
import Help from "../../../../UI/Help";
import Draggable from 'react-draggable';
import * as Global from "../../../../../store/Global";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";

class SurgeryMaintenance extends Component {

    state = {
        activeTab: "1",
        dAllItemFields: null,

        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'surgeryForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty surgeryForm back to what it was on load
        initialSurgeryForm: null,
        surgeryForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "rsnNoSurg",
        fieldItemNbr: "",
        focusingField: null,
        isHelpVisible: false,

        primaryItemFields: null,
        primaries: null,
        initialPrimaries: null,
        isPrimaryDirty: false,
        primTopo: null,
        primSite: null,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,
        isSaving: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        allowSaveAnyway: true,

        primariesMaxlengthInput: null,

        selectedRowIndex: -1,

        isShowAlertMsg: false,
        rowIndex: 0,
    }

    setSelectionRow = (event, index) => {
        this.setState({ selectedRowIndex: index });
    }

    componentDidMount() {
        if (!this.state.surgeryForm && this.props.selectedSurgeryRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.surgeryForm && this.props.selectedSurgeryRecord) || (prevProps.selectedSurgeryRecord && prevProps.selectedSurgeryRecord !== this.props.selectedSurgeryRecord)) {
            this.setFormData();
        }

        if (this.state.isFormDirty && this.state.isSaving && prevProps.isFormDirty !== this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }
        if (this.props.selectedPrimarySummary && !this.state.primaries && !prevProps.primaries) {
            axios.post(this.props.clientgroup + "/GetRxSurgMvList", { primarySummaryId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ primaries: res.data.rxSurgMvList, initialPrimaries: res.data.rxSurgMvList });
                        this.setState({ surgeryForm: { ...this.state.surgeryForm, primarySummary: res.data.rxSurgMvList } });

                        // Primary List
                        if (!this.state.primaryItemFields && res.data.rxSurgMvList) {
                            let primariesArr = [];
                            res.data.rxSurgMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                primariesArr.push(fields);
                            });

                            this.setState({ primaryItemFields: primariesArr });

                            // get maxlength for Primary grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Rx_Surg_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = primariesArr && primariesArr[0] ? primariesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            primariesMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.surgeryForm && this.state.dAllItemFields
        ) {
            console.log("[Surgery] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        surgeryForm: { ...this.state.surgeryForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        surgeryForm: { ...this.state.surgeryForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Rx_Summary", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        this.setState({
            surgeryForm: { ...this.props.selectedSurgeryRecord },
            isFormDirty: this.props.isFormDirty,

            primTopo: this.props.selectedMedicalRecord.topoCode
        });

        if (this.props.selectedPrimarySummary) {
            this.setState({
                primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "",
                primSite: this.props.selectedMedicalRecord.topoCode ? this.props.selectedMedicalRecord.topoCode.substring(2) : "",
            });
        }

        if (!this.state.dAllItemFields && this.props.selectedSurgeryRecord) {
            let fields = {};
            Object.keys(this.props.selectedSurgeryRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        return false;
    }

    toggleTab = (event, tab) => {

        this.setState({ activeTab: tab });

        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("rsnNoSurg") ? document.getElementById("rsnNoSurg").focus() : "";
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("surgHereBreast") ? document.getElementById("surgHereBreast").focus() : "";
            }, 0);
        }

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveSurgery = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        var allowSaveAnyway = this.state.allowSaveAnyway;

        let formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "dateRegLnDissection" || t === "dateSentinelLnBx") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                isValid = false;
                return;
            }
        });

        if (isValid) {
            let pItems = this.state.primaryItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field == "surgDt" || field == "surgDischDt") {
                        this.clearInvalidOfDateFields(prm, field, "primaryItemList", index);
                    }
                    /*
                    let val = this.state.primaries[index][field];
                    let surgFsFlag = this.state.primaries[index]["surgFsFlag"];
                    if (((field === "surgDtYear" || field === "surgDtMonth" || field === "surgDtDay" || field.startsWith("surgDt")) && val !== null && val !== undefined && val.toString() !== "Invalid Date" && (surgFsFlag === null || surgFsFlag === ""))) {
                        // update status invalid on view
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            if (newItems[index]) {
                                newItems[index]["surgFsFlag"] = "IN_VALID";
                            }
                            return { primaryItemFields: newItems };
                        });

                        isValid = false;
                        return;
                    }
                    */
                });
            });
        }

        let primaryList = this.state.primaries;
        var rowIdx = 0;
        var isValidFsFlag = true;
        primaryList.map((obj, index) => {
            if (obj["surgDtYear"] && obj["surgDtYear"] != ""
                && obj["surgDtMonth"] && obj["surgDtMonth"] != ""
                && obj["surgDtDay"] && obj["surgDtDay"] != ""
                && obj["surgFsFlag"] != "F" && obj["surgFsFlag"] != "S") {
                isValidFsFlag = false;
                rowIdx = index;
                return false;
            }
        });

        if (!isValidFsFlag /*&& !allowSaveAnyway*/) {
            /*
            alert('Must have a First Course/Subsequent flag if start date is present.');
            document.getElementById("surgFsFlag" + rowIdx) ? document.getElementById("surgFsFlag" + rowIdx).focus() : "";
            */
            this.setState({ isShowAlertMsg: true, rowIndex: rowIdx });
            this.props.setChildrenOpening(true);
            return false;
        } else {
            let surgeryFormData = this.state.surgeryForm;

            let primaryList = this.state.primaries;

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(surgeryFormData).map((t, k) => {
                if (t === "dateRegLnDissection" || t === "dateSentinelLnBx") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(surgeryFormData, t);
                }
            });

            primaryList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "surgDt" || t === "surgDischDt") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "primaryList", index);
                    }
                });
            });
            //#20210125 - END

            if (!isValidDateInput && !allowSaveAnyway) return false;

            surgeryFormData.rxSurgMv = primaryList;
            surgeryFormData.aliasForm = "Surgery";

            this.props.saveSurgery(surgeryFormData);

            this.setState({
                isSaving: true,
            });

            return false;
        }
    }

    resetSurgery = () => {
        this.setState({
            surgeryForm: { ...this.state.initialSurgeryForm },
            isFormDirty: false,
            primaries: null,
            primaryItemFields: null,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({
            fieldInFocus: event.target.name,
            fieldItemNbr: event.target.getAttribute("data-help-id"),
            focusingField: event.target
        });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy === 'day' && value !== '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy === 'month' && value !== '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy === 'year' && value !== '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            // check required fields input
            if (event.target.name === "suspenseRemarks" && event.target.value === "") {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                // check validation of date field input
            } else if (event.target._element && event.target._element.className === "k-widget k-datepicker" && event.target.dateInput._element.value !== "month/day/year") {
                if (event.target.dateInput._element.validity.valid) {
                    //this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
                }
            } else {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
        }
    }
    // --END

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    handlePrimaryLostfocus = (event, index) => {
        var name = event.target.name;
        var value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            if (name === "surgFsFlag" && value !== "") {
                var fsVal = value.toUpperCase();
                value = fsVal;
                this.setState(prevState => {
                    const newItems = [...prevState.primaries];
                    newItems[index][name] = fsVal;

                    const iFields = [...prevState.primaryItemFields];
                    iFields[index][name] = "";
                    return {
                        primaries: newItems,
                        primaryItemFields: iFields
                    };
                });
            }

            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "";
            this.props.checkValidInput(name, value, table, key, index, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy === 'day' && value !== '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy === 'month' && value !== '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy === 'year' && value !== '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.primaries];
                        newItems[index][name] = value;
                        return { primaries: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { primaryItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "";
                    return { primaryItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.primaryItemFields];
                newItems[index][name] = "";
                return { primaryItemFields: newItems };
            });
        }
    }

    handlePrimaryGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            newItems[index][name] = value;
            newItems[index]["isPrimaryDirty"] = true;
            return { primaries: newItems, isFormDirty: true };
        });
    }

    addNewPrimary = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.primaries];
            const firstPrimary = newItems[0];

            const newPrimary = {};
            Object.keys(firstPrimary).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newPrimary[t] = 0;
                } else {
                    newPrimary[t] = "";
                }
            });
            newItems.push(newPrimary);

            // 
            const newFields = [...this.state.primaryItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { primaries: newItems, primaryItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedItemPrimary = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.primaries.length) {
            selectedRow = this.state.primaries.length - 1;
        }

        if (this.state.primaries[selectedRow] && this.state.primaries[selectedRow]["id"] !== undefined && this.state.primaries[selectedRow]["id"] !== 0) {
            this.props.setChildrenOpening(true);
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "surgery" });
        } else {
            this.deleteItemOnGrid();
        }

        return false;
    }

    deleteItemOnGrid = () => {
        let selectedRow = this.state.selectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.primaries.length) {
            selectedRow = this.state.primaries.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            if (newItems.length === 1) {
                const _newItems = [...this.state.primaries];
                const firstPrim = _newItems[0];

                const newPrim = {};
                Object.keys(firstPrim).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newPrim[t] = 0;
                    } else {
                        newPrim[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newPrim);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.primaryItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.primaryItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { primaries: newItems, primaryItemFields: newFields, selectedRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowConfirmDeleteMsg: false });

        this.props.setChildrenOpening(false);

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "surgery":
                    this.deleteItemOnGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    //
    hideAlertMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowAlertMsg: false });
        this.props.setChildrenOpening(false);

        var rowIdx = this.state.rowIndex;

        setTimeout(function () {
            document.getElementById("surgFsFlag" + rowIdx) ? document.getElementById("surgFsFlag" + rowIdx).focus() : "";
        }, 100);

        return false;
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let alertMsg;
        {
            alertMsg = this.state.isShowAlertMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideAlertMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Must have a First Course/Subsequent flag if start date is present.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideAlertMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const surgeryMaintenance = this.state.surgeryForm && !this.props.isChildrenLoading ?
            (
                <div className="container-fluid">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} fieldItemNbr={this.state.fieldItemNbr} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveSurgery} id="surgeryForm" className="DialogForm PopupChildrenPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}>
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="frameInner" style={{ /*height: "400px"*/ }}>
                            <div className="header">&nbsp;</div>

                            <div>
                                <div>
                                    <div className="col-md-13" style={{ display: "flex" }}>
                                        <div className="surgTabs" style={{ width: "550px" }}>
                                            <Nav tabs>
                                                <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(event) => { this.toggleTab(event,"1") }} href="#" id="linkOfCommonTab">
                                                    Common
                                                </NavLink>
                                                <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(event) => { this.toggleTab(event,"2") }} href="#" id="linkofBreast22Tab">
                                                    Breast 2022+
                                                </NavLink>
                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab} style={{ height: "85%"}}>
                                                <TabPane tabId="1">
                                                    <div className="surgCommon" style={{ display: "flex", marginLeft: "5px" }}>
                                                        <div className="surgComTab1" style={{ width: "240px", marginleft: "15px" }}> 
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Rsn No Surg" field="rsnNoSurg" lineStyle="oneLine"
                                                                value={this.state.surgeryForm.RsnNoSurg ? this.state.surgeryForm.RsnNoSurg : this.state.surgeryForm.rsnNoSurg}
                                                                dataSource="Global_Dir_Mv" aliasNo="141"
                                                                titleDialog="Rsn No Surg" labelWidth={Global.LabelWidth} width="40px"
                                                                inValid={this.state.dAllItemFields["rsnNoSurg"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus} autoFocus
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rsnNoSurg"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText={this.state.surgeryForm.surgUserLblText1 ? this.state.surgeryForm.surgUserLblText1 : "Surg User 1"}//labelText="Surg User 1"
                                                                field="surgUser1" lineStyle="oneLine"
                                                                value={this.state.surgeryForm.SurgUser1 ? this.state.surgeryForm.SurgUser1 : this.state.surgeryForm.surgUser1}
                                                                dataSource="Global_Dir_Mv" aliasNo="3711"
                                                                titleDialog="Surg User 1" labelWidth={Global.LabelWidth} width="100px"
                                                                inValid={this.state.dAllItemFields["surgUser1"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["surgUser1"] : ""}
                                                                flag="ERS_UserDefinedMaintenance"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText={this.state.surgeryForm.surgUserLblText2 ? this.state.surgeryForm.surgUserLblText2 : "Surg User 2"}//labelText="Surg User 2"
                                                                field="surgUser2" lineStyle="oneLine"
                                                                value={this.state.surgeryForm.SurgUser2 ? this.state.surgeryForm.SurgUser2 : this.state.surgeryForm.surgUser2}
                                                                dataSource="Global_Dir_Mv" aliasNo="3712"
                                                                titleDialog="Surg User 2" labelWidth={Global.LabelWidth} width="100px"
                                                                inValid={this.state.dAllItemFields["surgUser2"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["surgUser2"] : ""}
							                                    flag="ERS_UserDefinedMaintenance"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Mesorectum" field="macroscopicEvalMesorectum" lineStyle="oneLine"
                                                                value={this.state.surgeryForm.macroscopicEvalMesorectum ? this.state.surgeryForm.macroscopicEvalMesorectum : this.state.surgeryForm.MacroscopicEvalMesorectum}
                                                                dataSource="Global_Dir_Mv" aliasNo="4522"
                                                                titleDialog="Macroscopic Evaluation of Mesorectum" labelWidth={Global.LabelWidth} width="100px"
                                                                inValid={this.state.dAllItemFields["macroscopicEvalMesorectum"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["macroscopicEvalMesorectum"] : ""}
                                                            />
                                                        </div>
                                                        <div className="surgComTab2" style={{ width: "300px" }}>
                                                            <div style={{ display: "flex" }}>
                                                                <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                    labelText="Regional LN Dissection" field="dateRegLnDissection"
                                                                    value={this.state.surgeryForm.dateRegLnDissection != null && this.state.surgeryForm.dateRegLnDissection !== "" ? moment(new Date(this.state.surgeryForm.dateRegLnDissection)).format("MM/DD/YYYY") : ""}
                                                                    labelWidth="145px" lineStyle="oneLine"
                                                                    monthWidth="40px" dayWidth="30px" yearWidth="45px"

                                                                    //#20210125 - START
                                                                    // Add day/month/year values
                                                                    inValidDay={this.state.dAllItemFields["dateRegLnDissectionDay"]}
                                                                    inValidMonth={this.state.dAllItemFields["dateRegLnDissectionMonth"]}
                                                                    inValidYear={this.state.dAllItemFields["dateRegLnDissectionYear"]}
                                                                    dayVal={this.state.surgeryForm.dateRegLnDissectionDay}
                                                                    monthVal={this.state.surgeryForm.dateRegLnDissectionMonth}
                                                                    yearVal={this.state.surgeryForm.dateRegLnDissectionYear}
                                                                    //#20210125 - END
                                                                    lostfocused={this.handleLostFocus}
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex" }}>
                                                                <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                    labelText="Sentinel LN Biopsy" field="dateSentinelLnBx"
                                                                    value={this.state.surgeryForm.dateSentinelLnBx != null && this.state.surgeryForm.dateSentinelLnBx !== "" ? moment(new Date(this.state.surgeryForm.dateSentinelLnBx)).format("MM/DD/YYYY") : ""}
                                                                    labelWidth="145px" lineStyle="oneLine"
                                                                    monthWidth="40px" dayWidth="30px" yearWidth="45px"

                                                                    //#20210125 - START
                                                                    // Add day/month/year values
                                                                    inValidDay={this.state.dAllItemFields["dateSentinelLnBxDay"]}
                                                                    inValidMonth={this.state.dAllItemFields["dateSentinelLnBxMonth"]}
                                                                    inValidYear={this.state.dAllItemFields["dateSentinelLnBxYear"]}
                                                                    dayVal={this.state.surgeryForm.dateSentinelLnBxDay}
                                                                    monthVal={this.state.surgeryForm.dateSentinelLnBxMonth}
                                                                    yearVal={this.state.surgeryForm.dateSentinelLnBxYear}
                                                                    //#20210125 - END
                                                                    lostfocused={this.handleLostFocus}
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex" }}>
                                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                    labelText="Sentinel LN Positive" field="sentinelLnPositive"
                                                                    value={this.state.surgeryForm.SentinelLnPositive ? this.state.surgeryForm.SentinelLnPositive : this.state.surgeryForm.sentinelLnPositive}
                                                                    dataSource="Global_Dir_Mv" aliasNo="4304" lineStyle="oneLine"
                                                                    titleDialog="Sentinel LN Positive" labelWidth="145px" width="35px"
                                                                    inValid={this.state.dAllItemFields["sentinelLnPositive"]} itemNbr=""
                                                                    lostfocused={this.handleLostFocus}
                                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sentinelLnPositive"] : ""}
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex" }}>
                                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                    labelText="Sentinel LN Examined" field="sentinelLnExamined"
                                                                    value={this.state.surgeryForm.SentinelLnExamined ? this.state.surgeryForm.SentinelLnExamined : this.state.surgeryForm.sentinelLnExamined}
                                                                    dataSource="Global_Dir_Mv" aliasNo="4305" lineStyle="oneLine"
                                                                    titleDialog="Sentinel LN Examined" labelWidth="145px" width="35px"
                                                                    inValid={this.state.dAllItemFields["sentinelLnExamined"]} itemNbr=""
                                                                    lostfocused={this.handleLostFocus}
                                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sentinelLnExamined"] : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                <div className="surgBreastTab" style={{ width: "535px", marginLeft: "5px" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Recon Here Breast" field="reconHereBreast"
                                                                value={this.state.surgeryForm.reconHereBreast}
                                                                dataSource="Global_Dir_Mv" aliasNo="4530" lineStyle="oneLine"
                                                                titleDialog="NCDB Treating Hospital for Breast Reconstruction" labelWidth="170px" width="35px"
                                                                inValid={this.state.dAllItemFields["reconHereBreast"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reconHereBreast"] : ""}
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Recon Summ Breast" field="reconSummBreast"
                                                                value={this.state.surgeryForm.reconSummBreast}
                                                                dataSource="Global_Dir_Mv" aliasNo="4531" lineStyle="oneLine"
                                                                titleDialog="NCDB Treating Hospital for Breast Reconstruction" labelWidth="170px" width="35px"
                                                                inValid={this.state.dAllItemFields["reconSummBreast"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reconSummBreast"] : ""}
                                                            />
                                                        </div>

                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Surg Here Breast (2022-23)" field="surgHereBreast"
                                                                value={this.state.surgeryForm.surgHereBreast}
                                                                dataSource="Global_Dir_Mv" aliasNo="4528" lineStyle="oneLine"
                                                                titleDialog="NCDB Treating Hospital for Breast Surgery" labelWidth="170px" width="35px"
                                                                inValid={this.state.dAllItemFields["surgHereBreast"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["surgHereBreast"] : ""}
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Surg Summ Breast (2022-23)" field="surgSummBreast"
                                                                value={this.state.surgeryForm.surgSummBreast}
                                                                dataSource="Global_Dir_Mv" aliasNo="4529" lineStyle="oneLine"
                                                                titleDialog="NCDB Treatment Summary for Breast Surgery" labelWidth="170px" width="35px"
                                                                inValid={this.state.dAllItemFields["surgSummBreast"]} itemNbr=""
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["surgSummBreast"] : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>

                                        <div className="surgTxtSumm" style={{ display: "flex", marginLeft: "10px" }}>
                                            <div className="" style={{ width: "350px", marginTop: "5px" }}>
                                                <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Surgery Text" field="surgeryText"
                                                    value={this.state.surgeryForm.surgeryText}
                                                    labelWidth="auto" width="300px" height="100px" rows="4" itemNbr=""
                                                    hasDoubleClickEvent="warning" maxlen="1000" />
                                            </div>
                                            <div className="" style={{ width: "360px", margin: "5px", marginLeft: "-30px" }}>
                                                <div className="labelAreaCls" style={{ padding: "5px" }}>
                                                    <div style={{ width: "175px" }}>
                                                        <Input type="label" labelWidth="80px" width="80px" labelText="Surgery Date"
                                                            field="surgDtSumm"
                                                            value={this.state.surgeryForm.surgDtSumm !== null && this.state.surgeryForm.surgDtSumm !== "" ? moment(new Date(this.state.surgeryForm.surgDtSumm)).format("MM/DD/YYYY") : ""}
                                                        />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="40px" labelText="Surgery Code"
                                                            field={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? "surgeryCode" : "surgSumm2023"}
                                                            value={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? this.state.surgeryForm.surgeryCode : this.state.surgeryForm.surgSumm2023} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="80px" labelText="Surgery Here"
                                                            field={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? "cdSurgHere" : "surgHere2023"} 
                                                            value={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? this.state.surgeryForm.cdSurgHere : this.state.surgeryForm.surgHere2023} />
                                                        <Input type="label" labelWidth="80px" width="80px" labelText="Def Surg Dt"
                                                            field="mostDefSurgDate"
                                                            value={this.state.surgeryForm.mostDefSurgDate !== null && this.state.surgeryForm.mostDefSurgDate !== "" ? moment(new Date(this.state.surgeryForm.mostDefSurgDate)).format("MM/DD/YYYY") : ""}
                                                        />
                                                        <Input type="label" labelWidth="80px" width="80px" labelText="Dx Stg Date"
                                                            field="ncdSurgDtSumm"
                                                            value={this.state.surgeryForm.ncdSurgDtSumm !== null && this.state.surgeryForm.ncdSurgDtSumm !== "" ? moment(new Date(this.state.surgeryForm.ncdSurgDtSumm)).format("MM/DD/YYYY") : ""}
                                                        />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="40px" labelText="Dx Stg Code"
                                                            field="ncdSurgeryCode" value={this.state.surgeryForm.ncdSurgeryCode} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="40px" labelText="Dx Stg Here"
                                                            field="ncdSurgeryHere" value={this.state.surgeryForm.ncdSurgeryHere} />
                                                        <Input type="label" labelWidth="80px" width="80px" labelText="Surg Disch Dt"
                                                            field="surgDischDtSumm"
                                                            value={this.state.surgeryForm.surgDischDtSumm !== null && this.state.surgeryForm.surgDischDtSumm !== "" ? moment(new Date(this.state.surgeryForm.surgDischDtSumm)).format("MM/DD/YYYY") : ""}
                                                        />
                                                    </div>
                                                    <div style={{ width: "175px" }}>
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="20px" labelText="Readmit 30 Days"
                                                            field="surgReadmit30DaysSum" value={this.state.surgeryForm.surgReadmit30DaysSum} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="20px" labelText="Scp LN Surg"
                                                            field="scopeLymphSurgSum" value={this.state.surgeryForm.scopeLymphSurgSum} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="20px" labelText="Scp Ln Surg Here"
                                                            field="scopeLymphSurgHere" value={this.state.surgeryForm.scopeLymphSurgHere} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="20px" labelText="Oth Reg Sites"
                                                            field="surgRegionSitesSum" value={this.state.surgeryForm.surgRegionSitesSum} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="20px" labelText="Oth Reg Here"
                                                            field="surgRegionSitesHere" value={this.state.surgeryForm.surgRegionSitesHere} />
                                                        <Input type="label" labelWidth={Global.LabelWidth} width="20px" labelText="LN Removed"
                                                            field="nbrNodesRemovedSumm" value={this.state.surgeryForm.nbrNodesRemovedSumm} />
                                                        <Input type="label" labelWidth="80px" width="40px" labelText="Days From Dx"
                                                            field="daysDxToSurg" value={this.state.surgeryForm.daysDxToSurg} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="emptyLine"></div>
                            <div className="row" /* was blank */>
                                <div className="col-sm-13" /* was blank */>
                                    <div className="primaryListArea" style={{ /*marginTop: "-10px"*/ }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "20px" }}>&nbsp;</th>
                                                    <th style={{ width: "131px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Surgery Date" field="surgDt"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px"}}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Dx Stg" field="dxStageProc" /* #770011 confirm filed name */
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Surg" field="surg"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="SLNS" field="scopeLymphSurg"
                                                        />
                                                    </th>
                                                    <th style={{ width: "62px" /* +2px for column is input */ }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="LNR" field="lymphNodesRemoved"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="SORS" field="surgRegionSites"
                                                        />
                                                    </th>
                                                    <th style={{ width: "131px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Surg Disch Date" field="surgDischDt"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Readmit" field="surgReadmit30Days"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="F/S Flag" field="surgFsFlag"
                                                        />
                                                    </th>
                                                    <th style={{ width: "105px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Physician" field="surgPhysician"
                                                        />
                                                    </th>
                                                    <th style={{ width: "100px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Hospital" field="Rx_Hosp"
                                                        />
                                                    </th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                <tr>
                                                    <td colSpan="12">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="innerBody" style={{ overflow: "auto", width: "932px", height: "170px" }}>
                                            <table>
                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                    <tr>
                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                        <th style={{ width: "131px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Surgery Date" field="surgDt"
                                                            />
                                                        </th>
                                                        <th style={{ width: "60px"}}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Dx Stg" field="dxStageProc" /* #770011 confirm field name */
                                                            />
                                                        </th>
                                                        <th style={{ width: "60px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Surg" field="surg"
                                                            />
                                                        </th>
                                                        <th style={{ width: "60px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="SLNS" field="scopeLymphSurg"
                                                            />
                                                        </th>
                                                        <th style={{ width: "62px" /* +2px for column is input */ }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="LNR" field="lymphNodesRemoved"
                                                            />
                                                        </th>
                                                        <th style={{ width: "60px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="SORS" field="surgRegionSites"
                                                            />
                                                        </th>
                                                        <th style={{ width: "131px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Surg Disch Date" field="surgDischDt"
                                                            />
                                                        </th>
                                                        <th style={{ width: "60px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Readmit" field="surgReadmit30Days"
                                                            />
                                                        </th>
                                                        <th style={{ width: "60px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="F/S Flag" field="surgFsFlag"
                                                            />
                                                        </th>
                                                        <th style={{ width: "105px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Physician" field="surgPhysician"
                                                            />
                                                        </th>
                                                        <th style={{ width: "100px" }}>
                                                            <Input type="gridHeader" labelWidth="auto"
                                                                labelText="Hospital" field="Rx_Hosp"
                                                            />
                                                        </th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.primaryItemFields
                                                        && this.state.primaries !== null && this.state.primaries.length > 0
                                                        ? this.state.primaries.map((prm, index) => (
                                                            <tr onClick={(e) => { this.setSelectionRow(e, index) }} className={this.state.selectedRowIndex === index ? "selectedRowCls" : ""}>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    <Input type="date" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="surgDt" lineStyle="oneLine"
                                                                        value={prm.surgDt != null && prm.surgDt !== "" && prm.surgDt !== undefined ? moment(new Date(prm.surgDt)).format("MM/DD/YYYY") : ""}
                                                                        rowIndex={index}//keyId={"surgDt" + index}

                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.primaryItemFields[index]["surgDtDay"]}
                                                                        inValidMonth={this.state.primaryItemFields[index]["surgDtMonth"]}
                                                                        inValidYear={this.state.primaryItemFields[index]["surgDtYear"]}
                                                                        dayVal={prm.surgDtDay}
                                                                        monthVal={prm.surgDtMonth}
                                                                        yearVal={prm.surgDtYear}
                                                                        //#20210125 - END
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        monthWidth="33px" dayWidth="30px" yearWidth="50px"//width="90px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="dxStageProc" value={prm.dxStageProc}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Diagnostic and Staging Procedures" aliasNo="4544"
                                                                        inValid={this.state.primaryItemFields[index]["dxStageProc"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["dxStageProc"] : ""}
                                                                        keyId={"dxStageProc" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="40px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ?
                                                                        (<Input type="codeSelect" focused={this.handleOnFocus} //this.props.selectedPrimarySummary.diagnosisDateYear
                                                                            changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                            labelText="" field="surg" value={prm.surg} itemNbr="127"
                                                                            dataSource="Site_Surg_Codes" //flag={this.state.primSite ? this.state.primSite : "00"}
                                                                            titleDialog="Surg" lineStyle="oneLine"
                                                                            inValid={this.state.primaryItemFields[index]["surg"]}
                                                                            lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                            maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["surg"] : ""}
                                                                            keyId={"surg" + index}
                                                                            rowIndex={index}
                                                                            labelWidth="0px" width="40px"
                                                                            flag={this.state.surgeryForm.fnSurgId}//Fixing #PR00269
                                                                            filterBy={this.props.selectedPrimarySummary.diagnosisDateYear}
                                                                        /> )
                                                                        :
                                                                        ( <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                            labelText="" field="surg2023" value={prm.surg2023} itemNbr="4543"
                                                                            dataSource="Site_Surg_Codes_2023"
                                                                            titleDialog="Surg" lineStyle="oneLine"
                                                                            inValid={this.state.primaryItemFields[index]["surg2023"]}
                                                                            lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                            maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["surg2023"] : ""}
                                                                            keyId={"surg2023" + index}
                                                                            rowIndex={index}
                                                                            labelWidth="0px" width="40px"
                                                                            flag={this.state.surgeryForm.fnSurgId}
                                                                            filterBy={this.props.selectedPrimarySummary.diagnosisDateYear}
                                                                        /> )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="scopeLymphSurg" value={prm.scopeLymphSurg} lineStyle="oneLine"
                                                                        dataSource="Global_Dir_Mv" titleDialog="Scope Lymph Surg" aliasNo="1389"
                                                                        inValid={this.state.primaryItemFields[index]["scopeLymphSurg"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["scopeLymphSurg"] : ""}
                                                                        keyId={"scopeLymphSurg" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="40px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        labelWidth="0px" width="60px"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="lymphNodesRemoved" value={prm.lymphNodesRemoved}
                                                                        //maxLength="2"
                                                                        rowIndex={index}
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["lymphNodesRemoved"] : ""}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="surgRegionSites" value={prm.surgRegionSites}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Class" aliasNo="1418"
                                                                        inValid={this.state.primaryItemFields[index]["surgRegionSites"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["surgRegionSites"] : ""}
                                                                        keyId={"surgRegionSites" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="40px"
                                                                    />
                                                                </td>
                                                                <td style={{ width: "150px" }}>
                                                                    <Input type="date" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="surgDischDt"
                                                                        value={prm.surgDischDt != null && prm.surgDischDt !== "" ? moment(new Date(prm.surgDischDt)).format("MM/DD/YYYY") : ""}
                                                                        rowIndex={index}//keyId={"surgDischDt" + index}

                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.primaryItemFields[index]["surgDischDtDay"]}
                                                                        inValidMonth={this.state.primaryItemFields[index]["surgDischDtMonth"]}
                                                                        inValidYear={this.state.primaryItemFields[index]["surgDischDtYear"]}
                                                                        dayVal={prm.surgDischDtDay}
                                                                        monthVal={prm.surgDischDtMonth}
                                                                        yearVal={prm.surgDischDtYear}
                                                                        //#20210125 - END
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        monthWidth="33px" dayWidth="30px" yearWidth="50px"//width="90px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="surgReadmit30Days" value={prm.surgReadmit30Days}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Surg Readmit 30 Days" aliasNo="2110"
                                                                        inValid={this.state.primaryItemFields[index]["surgReadmit30Days"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["surgReadmit30Days"] : ""}
                                                                        keyId={"surgReadmit30Days" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="40px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="surgFsFlag" value={prm.surgFsFlag}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Surg Fs Flag" aliasNo="160"
                                                                        inValid={this.state.primaryItemFields[index]["surgFsFlag"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["surgFsFlag"] : ""}
                                                                        keyId={"surgFsFlag" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="40px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="Physician" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="surgPhysician" value={prm.surgPhysician}
                                                                        dataSource="Physician" titleDialog="Surg Physician" aliasNo="4499"
                                                                        inValid={this.state.primaryItemFields[index]["surgPhysician"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="15"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["surgPhysician"] : ""}
                                                                        keyId={"surgPhysician" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="85px"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="Hospital" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="rxHosp" value={prm.rxHosp} lineStyle="oneLine"
                                                                        dataSource="Hospital" titleDialog="Rx Hosp" aliasNo="147"
                                                                        inValid={this.state.primaryItemFields[index]["rxHosp"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="15"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["rxHosp"] : ""}
                                                                        keyId={"rxHosp" + index}
                                                                        rowIndex={index}
                                                                        labelWidth="0px" width="80px"
                                                                    />
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        )) : <tr><td colSpan="12">No records available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="contactBtnArea right" style={{ width: "872px" }}>
                                            <button onClick={this.addNewPrimary}>Add</button>
                                            <button onClick={this.deleteSelectedItemPrimary}>Delete</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetSurgery} formId="surgeryForm"
                        isFormDirty={this.state.isFormDirty}
                        isDisabledOnChildren={true}
                        isChildren={true}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p>Surgery data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>
                <React.Fragment>
                    {alertMsg}
                </React.Fragment>
                <React.Fragment>
                    {surgeryMaintenance}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

SurgeryMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedSurgeryRecord: state.patientAbstract.selectedSurgeryRecord,
        isChildrenLoading: state.patientAbstract.isChildrenLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem2,//#2
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,

        isFormDirty: state.patientAbstract.isFormDirty,
        childrenOpening: state.patientAbstract.childrenOpening,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveSurgery: (surgeryData) => dispatch(actionCreators.saveSurgery(surgeryData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem2()),//#2
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurgeryMaintenance);