/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";
import "../AbstractUtils.css";
import Patient from "../../../UI/Lookup/Patient";

class CopyAbstract extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        enabledDeletedBtn: false,
        loaded: false,

        metafilesMv: null,
        initialMetafilesMv: null,
        metafilesMvItemFields: null,
        metafilesMvMaxlengthInput: null,
        selectedItemRowIndex: 0,
        showPatientsLookup: false,
        isNewFlg: false,
        clickedAndSelectionPatient: false,
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        var selectedRowIndex = this.state.selectedItemRowIndex;
        if (event.target.getAttribute("data-index") != "0") {
            selectedRowIndex = event.target.getAttribute("data-index");
        }

        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedItemRowIndex: selectedRowIndex
        });

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnDeleteItem_Fake") ? document.querySelector(".Footer2>#btnDeleteItem_Fake").focus() : "";
        }, 0);

        return false;
    }

    hidePatientsLookup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showPatientsLookup: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            //ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP
            document.querySelector("#siteCode") ? document.querySelector("#siteCode").focus() : "";
        }, 1000);

        return false;
    }

    componentWillUnmount() {
        this.setState({
            initialDialogForm: null,
            dialogForm: null,
            isFormDirty: false,
            reload: false,

            pAllItemFields: null,
            // set max-length for input fields
            tableStructureList: null,
            pageMaxlengthInput: null,
            hasSetMaxLength: false,

            showMessageError: false,
            hasError: false,
            savedForm: false,
            fieldInFocus: null,
            focusingField: null,
            isSaving: false,
            enabledDeletedBtn: false,
            loaded: false,

            metafilesMv: null,
            initialMetafilesMv: null,
            metafilesMvItemFields: null,
            metafilesMvMaxlengthInput: null,
            selectedItemRowIndex: 0,
            showPatientsLookup: false,
            clickedAndSelectionPatient: false,
            isNewFlg: false,
        });
    }

    componentDidMount() {
        this.setState({ selectedItemRowIndex: 0 });

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        if (!this.props.onRightMenu) {
            // Add all tabs in maintDialogs.js
            dialogTypes.absUtilsTabsDisplay.map((dialog, index) => {
                this.props.addTabItems(dialog.name, dialog.id);
                this.props.setEnabledStatus(dialog.name);
            });
        }

        this.setState({ isLoading: true, loaded: false });//#End

        if (!this.state.dialogForm && this.props.patientAbsInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        setTimeout(function () {
            document.getElementById("btnAddNewItem0") ? document.getElementById("btnAddNewItem0").click() : null;
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.patientAbsInfo)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.pid !== this.props.patientAbsInfo.pid)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.firstName !== this.props.patientAbsInfo.firstName)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.lastName !== this.props.patientAbsInfo.lastName)
        ) {
            this.setFormData();
        } else if (this.state.dialogForm && !this.props.patientAbsInfo && !this.state.reload) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: "0",
                    firstName: "",
                    lastName: "",
                    isFormDirty: false,
                    isNewFlg: false,
                },
                reload: true,
            });
        }

        if ((this.state.dialogForm && !this.state.dialogForm.pid && this.props.selectedPatientOnPopup)
            || (this.state.dialogForm && this.state.dialogForm.pid && this.props.selectedPatientOnPopup && this.props.selectedPatientOnPopup.pid != this.state.dialogForm.pid)) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: this.props.selectedPatientOnPopup.pid,
                    firstName: this.state.clickedAndSelectionPatient ? this.props.selectedPatientOnPopup.firstName : "",
                    lastName: this.state.clickedAndSelectionPatient ? this.props.selectedPatientOnPopup.lastName : "",
                    isFormDirty: true,
                    //Also clear all primary_summary details
                    ["cid"]: "",
                    ["siteCode"]: "",
                    ["siteDesc"]: "",
                    ["siteCodeName"]: "",
                    ["seqPrim"]: "",
                    ["hosp"]: "",
                    ["hospName"]: "",
                    ["medRecNbr"]: "",
                },
            });
        } else if (this.state.dialogForm && !this.props.selectedPatientOnPopup && !this.state.reload) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: "0",
                    firstName: "",
                    lastName: "",
                    isFormDirty: false,
                    isNewFlg: false,
                },
                reload: true,
            });
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,
            });
        }

        //
        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.pAllItemFields && !this.state.isNewFlg
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: {
                        ...this.state.dialogForm,
                        [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name,
                        [this.props.currentSelectedField + "State"]: this.props.commonLookupItem.state,
                    },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: {
                        ...this.state.dialogForm,
                        [this.props.currentSelectedField + "Name"]: "",
                        [this.props.currentSelectedField + "State"]: "",
                    },
                });
            }
            this.props.clearCommonLookupItem();
        }
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Patient";
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios            
            .post(url, { table: "Patient", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,            
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.patientAbsInfo;

        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: { ...fields },
            dialogForm: { ...dataForm },
            isFormDirty: false,
            reload: true,
            savedForm: false,
            hasError: false,
            loaded: true,
            isNewFlg: false,
        });

        if (!this.state.pAllItemFields && this.props.patientAbsInfo) {
            let _fields = {};
            Object.keys(this.props.patientAbsInfo).map((t) => {
                _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };

        //
        setTimeout(function () {
            document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP") ? document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP").focus() : "";
        }, 500);
    }

    handleFormChange = (event) => {
        if (event.target2 && event.target2.name != "newHospName") {
            var pid = this.state.dialogForm.pid;
            var cid = event.target2["cid"];
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["cid"]: event.target2["cid"],//!Important
                    ["siteCode"]: event.target2["siteCode"],
                    ["siteDesc"]: event.target2["siteDesc"],//
                    ["seqPrim"]: event.target2["seqPrim"],
                    ["hosp"]: event.target2["hosp"],
                    ["hospName"]: event.target2["hospName"],//
                    ["medRecNbr"]: event.target2["medRecNbr"],
                },
                isFormDirty: true,
                isNewFlg: false
            });

            this.Copy_Abstract_Load_Data(pid, cid, event.target2["siteCode"], event.target2["hosp"]);

            //
            setTimeout(function () {
                document.getElementById("siteCode") ? document.getElementById("siteCode").focus() : "";
            }, 0);
        } else if (event.target2 && event.target2.name == "newHospName") {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value,
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                },
                isFormDirty: true,
                isNewFlg: false
            });
        }
    }

    async Copy_Abstract_Load_Data(pid, cid, siteCode, hospVal) {
        let name = "siteCode";
        let value = siteCode;
        let table = "Site_Codes";

        var _this = this;
        await setTimeout(function () {
            _this.props.checkValidInput(name, value, table);
            /*
            // Copy_Abstract_Load_Data
            axios.get(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/CopyAbstractLoadData?pId=" + pid + "&cId=" + cid)
                .then(res => {
                    if (!res.data.error && res.data.copyPatientAbs) {
                        _this.setState({
                            dialogForm: {
                                ..._this.state.dialogForm,
                                ["siteDesc"]: res.data.copyPatientAbs["siteDesc"],
                                ["medRecNbr"]: res.data.copyPatientAbs["medRecNbr"],
                                ["hosp"]: res.data.copyPatientAbs["hosp"],
                            }
                        });

                        setTimeout(function () {
                            document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "" : null;
                        }, 0);
                    }
                });
            */
        }, 0);

        let name2 = "hosp";
        let value2 = hospVal;
        let table2 = "Hospital";
        await setTimeout(function () {
            _this.props.checkValidInput(name2, value2, table2);
        }, 1000);
    }

    handleOnBlur = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 0);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        if (event.target.name == "newHosp") {
            let Last_Name = this.state.dialogForm && this.state.dialogForm.lastName ? this.state.dialogForm.lastName : '';
            let First_Name = this.state.dialogForm && this.state.dialogForm.firstName ? this.state.dialogForm.firstName : '';
            let Site_Code = this.state.dialogForm && this.state.dialogForm.siteCode ? this.state.dialogForm.siteCode : '';
            let Seq_Prim = this.state.dialogForm && this.state.dialogForm.seqPrim ? this.state.dialogForm.seqPrim : '';
            let Hosp = this.state.dialogForm && this.state.dialogForm.hosp ? this.state.dialogForm.hosp : '';
            let Hospital_Code = this.state.dialogForm && this.state.dialogForm.newHosp ? this.state.dialogForm.newHosp : '';

            //(Last_Name <> '' Or First_Name <> '') And Site_Code <> '' And Seq_Prim <> '' And Hosp <> '' And Hospital_Code <> '' And Hosp <> Hospital_Code
            if ((Last_Name != '' || First_Name != '') && Site_Code != '' && Seq_Prim != '' && Hosp != '' && Hospital_Code != '' && Hosp != Hospital_Code) {
                document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').removeAttribute("disabled") : null;//
            }
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.patientAbsInfo) {
            Object.keys(this.props.patientAbsInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: {
                ...this.state.initialDialogForm,
                ["firstName"]: "",
                ["lastName"]: "",
                ["siteDesc"]: "",
                ["siteCodeName"]: "",
                ["newHosp"]: "",
                ["newHospName"]: "",
            },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false,
            isNewFlg: true,
            clickedAndSelectionPatient: false,
        });

        //
        setTimeout(function () {
            document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : "";
            document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP") ? document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP").focus() : "";
        }, 500);

        //
        setTimeout(function () {
            document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : null;
        }, 1000);
    }

    closeWindow = (e) => {
        e.preventDefault();

        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });

        if (this.props.onRightMenu) {
            this.props.closeWindow();
        }

        return false;
    }

    showPatientsLookup = (event) => {
        event.preventDefault();
        this.setState({
            showPatientsLookup: true,
            clickedAndSelectionPatient: true,
        });
        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">This primary could not be copied.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        let showPatientsLookup;
        {
            showPatientsLookup = this.state.showPatientsLookup ? <Patient field="firstName" closeCode={this.hidePatientsLookup} titleDialog="Patient Lookup" /> : null;
        }

        let enabledCopyBtn = false;
        {
            let Last_Name = this.state.dialogForm && this.state.dialogForm.lastName ? this.state.dialogForm.lastName : '';
            let First_Name = this.state.dialogForm && this.state.dialogForm.firstName ? this.state.dialogForm.firstName : '';
            let Site_Code = this.state.dialogForm && this.state.dialogForm.siteCode ? this.state.dialogForm.siteCode : '';
            let Seq_Prim = this.state.dialogForm && this.state.dialogForm.seqPrim ? this.state.dialogForm.seqPrim : '';
            let Hosp = this.state.dialogForm && this.state.dialogForm.hosp ? this.state.dialogForm.hosp : '';
            let Hospital_Code = this.state.dialogForm && this.state.dialogForm.newHosp ? this.state.dialogForm.newHosp : '';

            //(Last_Name <> '' Or First_Name <> '') And Site_Code <> '' And Seq_Prim <> '' And Hosp <> '' And Hospital_Code <> '' And Hosp <> Hospital_Code
            if ((Last_Name != '' || First_Name != '') && Site_Code != '' && Seq_Prim != '' && Hosp != '' && Hospital_Code != '' && Hosp != Hospital_Code) {
                enabledCopyBtn = true;
            }
        }

        let dialogFrm;
        if (this.props.onRightMenu) {
            dialogFrm = <Draggable handle=".k-window-titlebar">
                <div className="dragableLargestDialogCls" id="printAbstractsModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Copy Abstract</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>

                    <div className="limitedInGridCls">
                        <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                            {loading}
                            <div className="parentWindow">
                                <div className="patientAbstractModal" id="printAbstractsBody" style={{ height: "600px" }}>
                                    {
                                        this.state.dialogForm ?
                                            <div className="container-fluid2">
                                                <form id="copyPatientAbstactMainForm" className="copyPatientAbstactMainForm">
                                                    <div id="inputAreaCls">
                                                        <div style={{ width: "750px" }}>
                                                            <div className="frameInner">
                                                                <div className="header hidden" style={{ display: "none" }}>Copy Abstract</div>

                                                                <div className="emptyLine"></div>
                                                                <div className="row">
                                                                    <div className="col-sm-11">
                                                                        <div className="oneLine">
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="Patient Name" field="firstName" disabled={true}
                                                                                width="185px" labelWidth="120px"
                                                                                value={this.state.dialogForm.firstName}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                                                                inValid={this.state.pAllItemFields["firstName"]} />
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="" field="lastName" disabled={true}
                                                                                width="230px" labelWidth="5px"
                                                                                value={this.state.dialogForm.lastName}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                                                                inValid={this.state.pAllItemFields["lastName"]} />
                                                                &nbsp;&nbsp;
                                                                <button type="button" id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP"
                                                                                className="ErsGrBlueButton _ScreenButton" title=""
                                                                                style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                                onClick={(e) => { this.showPatientsLookup(e) }}
                                                                            >
                                                                                <img id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP-ICON" src="https://crstar.ers-can.com/images/$$edit.find.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="oneLine">
                                                                            <Input type="Primary_Summary" shownType="dropdown"
                                                                                focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                                labelText="Site Code" field="siteCode"
                                                                                width="40px" labelWidth="120px" lostfocused={this.handleOnBlur}
                                                                                value={this.state.dialogForm.siteCode}//SELECT Site_Code, Abst_Suna_Flag, Seq_Prim, Hosp, PID, CID FROM Primary_Summary
                                                                                dataSource="Site_Codes" titleDialog="Primary Popup"//Site_Code<Site_code,Seq_Prim<Seq_prim,Hosp<Hosp
                                                                                flag={this.state.dialogForm.pid}//PID (N)|Equals|Pid
                                                                                disabled={this.state.dialogForm.pid == null || this.state.dialogForm.pid == "" || this.state.dialogForm.pid == undefined || this.state.dialogForm.pid == "0"}
                                                                                inValid={this.state.pAllItemFields["siteCode"]}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteCode"] : ""}
                                                                                patientId={this.state.dialogForm.pid}
                                                                            />
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="" field="siteDesc" disabled={true}
                                                                                width="320px" labelWidth="5px"
                                                                                value={this.state.dialogForm.siteDesc ? this.state.dialogForm.siteDesc : this.state.dialogForm.siteCodeName}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDesc"] : ""}
                                                                                inValid={this.state.pAllItemFields["siteDesc"]} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="oneLine">
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="Sequence" field="seqPrim" disabled={true}
                                                                                width="30px" labelWidth="120px"
                                                                                value={this.state.dialogForm.seqPrim}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["seqPrim"] : ""}
                                                                                inValid={this.state.pAllItemFields["seqPrim"]} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="oneLine">
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="Med Rec Nbr" field="medRecNbr" disabled={true}
                                                                                width="115px" labelWidth="120px"
                                                                                value={this.state.dialogForm.medRecNbr}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medRecNbr"] : ""}
                                                                                inValid={this.state.pAllItemFields["medRecNbr"]} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-11">
                                                                        <div className="oneLine">
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="Old Hospital Code" field="hosp" disabled={true}
                                                                                dataSource="Hospital"
                                                                                width="60px" labelWidth="120px"
                                                                                value={this.state.dialogForm.hosp}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hosp"] : ""}
                                                                                inValid={this.state.pAllItemFields["hosp"]} />
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="" field="hospName" disabled={true}
                                                                                width="300px" labelWidth="5px"
                                                                                value={this.state.dialogForm.hospName}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospName"] : ""}
                                                                                inValid={this.state.pAllItemFields["hospName"]} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-11">
                                                                        <div className="oneLine">
                                                                            <Input type="Hospital" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="New Hospital Code" field="newHosp"
                                                                                dataSource="Hospital" titleDialog="Hospital Lookup" codeLength="2"
                                                                                width="40px" labelWidth="120px"
                                                                                value={this.state.dialogForm.newHosp}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["newHosp"] : ""}
                                                                                inValid={this.state.pAllItemFields["newHosp"]} />
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                labelText="" field="newHospName" disabled={true}
                                                                                width="300px" labelWidth="5px"
                                                                                value={this.state.dialogForm.newHospName}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["newHospName"] : ""}
                                                                                inValid={this.state.pAllItemFields["newHospName"]} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="emptyLine"></div>

                                                <Footer closeWindow={this.props.closeWindow}
                                                    refreshForm={this.refreshFormFnc} formId="copyPatientAbstactMainForm"
                                                    isFormDirty={this.state.isFormDirty}
                                                    hideSaveBtn={true}
                                                    showBtnNew="CopyPatientAbs"
                                                    showBtnDelete="CopyPatientAbs"
                                                    btnDeleteText="Copy"
                                                    className="Footer2"
                                                    initialPageVisible={true}
                                                    exitFnc={this.props.closeWindow}
                                                    focusingField={this.state.focusingField}
                                                    patientAbsData={this.state.dialogForm}
                                                    ignoreExit={true}
                                                    enabledDeletedBtn={enabledCopyBtn}//
                                                    enabledAlways={true}
                                                />

                                            </div>
                                            : <p>Copy Patient Abstract data loading...</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        } else {
            dialogFrm = <div className="limitedInGridCls">
                <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                    {loading}
                    <div className="parentWindow">
                        <div className="patientAbstractGridView" id="copyPatientAbstactMainPage" style={{ height: "600px" }}>
                            {
                                this.state.dialogForm ?
                                    <div className="container-fluid2">
                                        <form id="copyPatientAbstactMainForm" className="copyPatientAbstactMainForm">
                                            <div id="inputAreaCls">
                                                <div style={{ width: "750px" }}>
                                                    <div className="frameInner">
                                                        <div className="header hidden" style={{ display: "none" }}>Copy Abstract</div>

                                                        <div className="emptyLine"></div>
                                                        <div className="row">
                                                            <div className="col-sm-11">
                                                                <div className="oneLine">
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="Patient Name" field="firstName" disabled={true}
                                                                        width="185px" labelWidth="120px"
                                                                        value={this.state.dialogForm.firstName}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                                                        inValid={this.state.pAllItemFields["firstName"]} />
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="" field="lastName" disabled={true}
                                                                        width="230px" labelWidth="5px"
                                                                        value={this.state.dialogForm.lastName}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                                                        inValid={this.state.pAllItemFields["lastName"]} />
                                                                &nbsp;&nbsp;
                                                                <button type="button" id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP"
                                                                        className="ErsGrBlueButton _ScreenButton" title=""
                                                                        style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                        onClick={(e) => { this.showPatientsLookup(e) }}
                                                                    >
                                                                        <img id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP-ICON" src="https://crstar.ers-can.com/images/$$edit.find.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="oneLine">
                                                                    <Input type="Primary_Summary" shownType="dropdown"
                                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Site Code" field="siteCode"
                                                                        width="40px" labelWidth="120px" lostfocused={this.handleOnBlur}
                                                                        value={this.state.dialogForm.siteCode}//SELECT Site_Code, Abst_Suna_Flag, Seq_Prim, Hosp, PID, CID FROM Primary_Summary
                                                                        dataSource="Site_Codes" titleDialog="Primary Popup"//Site_Code<Site_code,Seq_Prim<Seq_prim,Hosp<Hosp
                                                                        flag={this.state.dialogForm.pid}//PID (N)|Equals|Pid
                                                                        disabled={this.state.dialogForm.pid == null || this.state.dialogForm.pid == "" || this.state.dialogForm.pid == undefined || this.state.dialogForm.pid == "0"}
                                                                        inValid={this.state.pAllItemFields["siteCode"]}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteCode"] : ""}
                                                                        patientId={this.state.dialogForm.pid}
                                                                    />
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="" field="siteDesc" disabled={true}
                                                                        width="320px" labelWidth="5px"
                                                                        value={this.state.dialogForm.siteDesc ? this.state.dialogForm.siteDesc : this.state.dialogForm.siteCodeName}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDesc"] : ""}
                                                                        inValid={this.state.pAllItemFields["siteDesc"]} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="oneLine">
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="Sequence" field="seqPrim" disabled={true}
                                                                        width="30px" labelWidth="120px"
                                                                        value={this.state.dialogForm.seqPrim}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["seqPrim"] : ""}
                                                                        inValid={this.state.pAllItemFields["seqPrim"]} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="oneLine">
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="Med Rec Nbr" field="medRecNbr" disabled={true}
                                                                        width="115px" labelWidth="120px"
                                                                        value={this.state.dialogForm.medRecNbr}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medRecNbr"] : ""}
                                                                        inValid={this.state.pAllItemFields["medRecNbr"]} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-11">
                                                                <div className="oneLine">
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="Old Hospital Code" field="hosp" disabled={true}
                                                                        dataSource="Hospital"
                                                                        width="60px" labelWidth="120px"
                                                                        value={this.state.dialogForm.hosp}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hosp"] : ""}
                                                                        inValid={this.state.pAllItemFields["hosp"]} />
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="" field="hospName" disabled={true}
                                                                        width="300px" labelWidth="5px"
                                                                        value={this.state.dialogForm.hospName}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospName"] : ""}
                                                                        inValid={this.state.pAllItemFields["hospName"]} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-11">
                                                                <div className="oneLine">
                                                                    <Input type="Hospital" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="New Hospital Code" field="newHosp"
                                                                        dataSource="Hospital" titleDialog="Hospital Lookup" codeLength="2"
                                                                        width="40px" labelWidth="120px"
                                                                        value={this.state.dialogForm.newHosp}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["newHosp"] : ""}
                                                                        inValid={this.state.pAllItemFields["newHosp"]} />
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                        labelText="" field="newHospName" disabled={true}
                                                                        width="300px" labelWidth="5px"
                                                                        value={this.state.dialogForm.newHospName}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["newHospName"] : ""}
                                                                        inValid={this.state.pAllItemFields["newHospName"]} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="emptyLine"></div>

                                        <Footer closeWindow={this.props.closeWindow}
                                            refreshForm={this.refreshFormFnc} formId="copyPatientAbstactMainForm"
                                            isFormDirty={this.state.isFormDirty}
                                            hideSaveBtn={true}
                                            showBtnNew="CopyPatientAbs"
                                            showBtnDelete="CopyPatientAbs"
                                            btnDeleteText="Copy"
                                            className="Footer2"
                                            initialPageVisible={true}
                                            exitFnc={this.props.closeWindow}
                                            focusingField={this.state.focusingField}
                                            patientAbsData={this.state.dialogForm}
                                            ignoreExit={true}
                                            enabledDeletedBtn={enabledCopyBtn}//
                                            enabledAlways={true}
                                        />

                                    </div>
                                    : <p>Copy Patient Abstract data loading...</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        };

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {showPatientsLookup}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

CopyAbstract.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        patientAbsInfo: state.patientAbstract.patientAbsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        user_id: state.auth.userId,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyAbstract);