/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';
import classes from './ForgotPassword.css';
import * as actions from '../../../store/actions/index';

class ForgotPassword extends Component {
    state = {
        controls: {
            email: {
                elementType: 'input',
                label: 'Email Address',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            }
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid;
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({ controls: updatedControls });
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onSubmitForm(this.state.controls.email.value);
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let form = formElementsArray.map(formElement => (
            <div key="email_input">
                <Input
                    label={formElement.config.label}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event) => this.inputChangedHandler(event, formElement.id)} />
            </div>
        ));

        if (this.props.loading) {
            form = <Spinner />
        }

        let errorMessage = null;

        return (
            <div className="LoginLayout">

                <Logo />

                <fieldset className="fieldset">
                    <div className={classes.Auth}>
                        {errorMessage}

                        {this.props.isSubmitForm ?
                            <div className="btnArea">
                                <label>Password reset link has been successfully sent to your email address...</label>
                                <br/>
                                <Link to="/login" className="btn btn-link">Back to Login</Link>
                            </div>
                            : 
                            <form onSubmit={this.submitHandler}>
                                {form}
                                <div className="btnArea">
                                    <Button btnType="Success">Submit</Button>
                                    <Link to="/login" className="btn btn-link">Cancel</Link>
                                </div>
                            </form>
                        }
                    </div>
                </fieldset>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isSubmitForm: state.auth.isSubmitForm
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmitForm: (email) => dispatch(actions.forgotPassword(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
