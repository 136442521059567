/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn as Column } from '@progress/kendo-react-grid';
//import { getter } from "@progress/kendo-react-common";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import ConfigData from '../../../../store/config.json';
import axios from "axios";
import "./ClearPopulations.css";
//import { setTwoFactorAuthDialogVisible } from "../../../../store/actions/actionTypes";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";

class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value ? formatDate(new Date(value), "MM/dd/yyyy") : ""}
            </td>
        );
    }
}

class ClearPopulations extends Component {

    state = {
        codes: [],
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        pageable: {
            pageSizes: [15, 20, 30, 40, 50, 100, 1000]
        },
        selectable: {
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple"
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 15,

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        focusedItem: null,
        filterIndexNo: 0,

        isShowConfirmDeleteLabelMsg: false,

        dataItemKey: "",
        selectedField: "",

        selectedIds: [],
        reload: false,

        showMessageError: false,
        confirm: null,
    }

    addEventListener = () => {
        var cl = this;
        console.log(cl)
        var currentLookupModal = document.querySelector('#ClearPopView');
        currentLookupModal.addEventListener('keydown', function (e) {
            if (e.keyCode == 40) {// down arrow
                var filterInputFocusing = e.target.getAttribute("class") === "k-textbox" ? true : false;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing && trs && trs[0] && trs[0].firstChild) {
                    trs[0].firstChild.setAttribute('tabindex', '0');
                    trs[0].firstChild.focus();

                    setTimeout(function () {
                        trs[0].scrollIntoView();
                        trs[0].scrollTop = 0;
                    }, 100);

                    let currentClasses = trs[0].getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') == -1)
                        trs[0].setAttribute("class", currentClasses + " k-state-selected");
                }

                var itemInRowFocusing = e.target.getAttribute("class") === "" ? true : false;
                if (itemInRowFocusing && e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    let currentClasses = e.target.parentNode.getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') != -1)
                        e.target.parentNode.setAttribute("class", currentClasses.replace("k-state-selected", ""));
                }
            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        if (e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelector('input.k-textbox:last-child') ? currentLookupModal.querySelector('input.k-textbox:last-child').focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "TH") {//Don't allow press shift+tab on these items
                        e.preventDefault();
                        return false;
                    } else if (e.target.nodeName === "INPUT" && e.target.getAttribute('class') === "k-textbox") {
                        if (!e.target.parentNode.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.parentNode.previousSibling === null) {
                            // set focus into Exit button
                            setTimeout(function () {
                                document.getElementById('btnExitClearPopulations') ? document.getElementById('btnExitClearPopulations').focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "INPUT" && e.target.id === "popLabel_searchByFilterInput") {
                        // set focus into Exit button
                        setTimeout(function () {
                            document.getElementById('btnExitClearPopulations') ? document.getElementById('btnExitClearPopulations').focus() : "";
                        }, 0);
                        return false;
                    }
                } else {
                    if (e.target.nodeName === "BUTTON" && e.target.id == "btnExitClearPopulations") {
                        if (!e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";//popLabel_searchByFilterInput
                            }, 0);
                        }
                    }
                }
            } else if (e.keyCode == 38) {// up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 ? true : false) : false;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-state-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }

            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-textbox" && e.target.value != "") {//enter
                !cl.enterKeyPressed ? cl.setState({
                    enterKeyPressed: true,
                    focusedItem: e.target,
                    filterIndexNo: e.target.getAttribute("data-index")
                }) : "";
                cl.props.setPageLoaded(false);
                e.preventDefault();
                return false;
            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-textbox" && e.target.value == "") {//enter
                cl.setState({
                    enterKeyPressed: false,
                });
                e.preventDefault();
                return false;
            } else if (e.keyCode == 13 && e.target.id === "popLabel_searchByFilterInput" && e.target.value != "") {//enter
                cl.searchByFunc(e);
                return false;
            } else {
                cl.setState({
                    enterKeyPressed: false,
                });
            }
        });
    }

    init = () => {
        var hospopup = document.getElementById("ClearPopView");
        var cl = this;
        var filterIndexNo = cl.state.filterIndexNo;
        if (hospopup && hospopup.querySelectorAll(".k-textbox")) {
            var inputs = hospopup.querySelectorAll(".k-textbox");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.setAttribute("id", "hosp-lookup_filter-k-textbox-" + idx);
                input.setAttribute("data-index", idx);
                input.addEventListener('focus', function (e) {
                    cl.setState({ focusedItem: input });
                });
            }
        }

        setTimeout(function () {
            if (cl.state.focusedItem && document.getElementById(cl.state.focusedItem.id)) {
                document.getElementById(cl.state.focusedItem.id).focus();
            } else if (filterIndexNo > 0) {
                document.getElementById("hosp-lookup_filter-k-textbox-" + filterIndexNo) ? document.getElementById("hosp-lookup_filter-k-textbox-" + filterIndexNo).focus() : "";
            } else {
                hospopup && hospopup.querySelector(".k-textbox") ? hospopup.querySelector(".k-textbox").focus() : "";
            }
            return false;
        }, 1000);
    }

    componentDidMount() {
        // must close add new dialog before show it
        this.closeAddNewDialog();

        //this.addEventListener();

        if (!this.props.onRightMenu) {
            // Add all tabs in maintDialogs.js
            dialogTypes.reportingUtilityTabsDisplay.map((dialog, index) => {
                this.props.addTabItems(dialog.name, dialog.id);
                this.props.setEnabledStatus(dialog.name);
                this.props.setControlName(dialog.id);
            });
        }

        this.props.setPageLoaded(false);

        this.getLabelLookupView();

        // must close add new dialog before show it
        this.closeAddNewDialog();

        this.props.setChildrenOpening(true);
    }

    async getLabelLookupView() {
        this.props.setPageLoaded(true);

        const skipNo = this.state.skip;
        const takeNo = this.state.take;
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetLabelsLookupView?skip=" + this.state.skip + "&take=" + this.state.take;        

        var access_token = localStorage.getItem('token');
        var params = {
            skip: skipNo,
            take: takeNo,
            accessToken: access_token
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                //url += "&" + f["field"] + "=" + encodeURIComponent("%" + f["value"] + "%");
                params[f["field"]] = ("%" + f["value"] + "%");
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                //url += "&sortby=" + s["field"] + "&orderby=" + s["dir"];
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }

        let data = await axios
            //.get(url)
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data ? data.data.commonLookupList : null;

        this.setState({
            codes: pItems,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
        });

        this.props.setPageLoaded(true);

        this.init();

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.pageLoaded || this.state.codes == null || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
            this.getLabelLookupView();
        }

        // check if whether deletion is success then research page again
        if (this.props.selectedLabelPopInfo && this.props.selectedLabelPopInfo.endTime && this.props.selectedLabelPopInfo.endTime != "" && !this.state.reload) {
            document.getElementById("btnSearchByFilter") ? document.getElementById("btnSearchByFilter").click() : null;
            this.setState({ reload: true });
        }

        //console.log("cDU - check")

        if (this.props.error && this.props.errorMessage) {
            this.setState({ showMessageError: true, errorMessage: this.props.errorMessage });
            //this.setState({ showClockTimer: false, showMessageError: true, errorMessage: this.props.errorMessage });

            document.querySelector('#BUTTON_DELETE') ? document.querySelector('#BUTTON_DELETE').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }
    }

    async searchByFilter(_filter='') {
        this.props.setPageLoaded(true);

        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetLabelsLookupView2";
        var _params = {
            skip: this.state.skip,
            take: this.state.take,
            filter: "%" + _filter + "%",
            accessToken: localStorage.getItem('token')
        };

        let data = await axios
            .post(url, _params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data ? data.data.commonLookupList : null;

        this.setState({
            codes: pItems,
            total: data && data.data ? data.data.total : 0,
            enterKeyPressed: false,
        });

        this.props.setPageLoaded(true);

        this.init();

        return false;
    }

    searchByFunc = (event, flag) => {
        event.preventDefault();

        var _filter = this.state.searchByFilterInput;
        if (flag == "all") {
            _filter = "";
            this.setState({
                searchByFilterInput: _filter
            });
        }
        this.searchByFilter(_filter);

        return false;
    }
 
    pageChange = (event, isFilter) => {
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            return false;
        }
        //

        let _filter = {
            logic: "",//and
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",//and
                filters: []
            };

            let allowSearch = event.nativeEvent === undefined ? true : event.nativeEvent.target.value != "";
            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;
        }
        this.props.setPageLoaded(!enterKeyPressed);
    }

    //TODO #FF0000 Currently not used.
    addNewItem = (event) => {
        event.preventDefault();
        this.props.addNewItem("");
        this.props.setChildrenOpening(false);
        return false;
    }

    closeAddNewDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeAddNewDialog();
        this.props.setChildrenOpening(false);
        return false;
    }

    //TODO #FF0000 Currently not used.
    selectCode = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.setCode(event.dataItem.label, event.dataItem.hospitalCode);

        this.setState({ isDialogVisible: true });
        return false;
    }

    deleteLabels = (event) => {
        try {
            event ? event.preventdefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: true });
        return false;
    }

    deleteLabel() {
        const selectedIdList = this.state.selectedIds;
        
        if (!selectedIdList || selectedIdList == "") {
            return false;
        }

        this.props.deletePopulationLabels(selectedIdList.join(","));

        this.setState({
            isShowConfirmDeleteLabelMsg: false,
            dataItemKey: "",
            selectedField: "",
            selectedIds: [],
            reload: false,
        });

        return false;
    }

    hideConfirmDeleteLabelMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: false });

        if (event.target.value === "yes") {
            this.deleteLabel();
        }
        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            //hasError: false,
            //confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_DELETE") ? document.querySelector("#BUTTON_DELETE").focus() : "";
        }, 0);

        return false;
    }

    // To get more reference at https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.codes;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.labelId) === rowId,
            }));
            this.setState({ codes: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.labelId,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }

    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeCode();
        return false;
    }

    resetFilterInputFnc = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        var _value = e.target.value;

        this.setState({
            searchByFilterInput: _value,
        });

        return false;
    }

    selectionChange = (event) => {

        if (event.dataItem === null || event.dataItem === undefined) {
            return
        }

        if (!event.dataItem.selected) {
            let ids = this.state.selectedIds
            if (ids.indexOf(event.dataItem.labelId) === -1) {
                ids.push(event.dataItem.labelId)
            }
            this.setState({ selectedIds: ids })
        } else {
            let ids = this.state.selectedIds
            let index = ids.indexOf(event.dataItem.labelId)
            ids.splice(index, 1)
            this.setState({ selectedIds: ids })
        }
    }

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        if (checked) {
            let ids = this.state.selectedIds
            this.state.codes.forEach(item => {
                if (ids.indexOf(item.labelId) === -1) {
                    ids.push(item.labelId)
                }
            })
            this.setState({ selectedIds: ids })
        } else {
            let ids = this.state.selectedIds
            this.state.codes.forEach(item => {
                let index = ids.indexOf(item.labelId)
                ids.splice(index, 1)
            })
            this.setState({ selectedIds: ids })
        }
    }

    render() {
        let loading = (
            this.props.isPatientSaving ? <div className="loader"></div> : ""
        );

        let confirmDeleteLabelMsg;
        {
            confirmDeleteLabelMsg = this.state.isShowConfirmDeleteLabelMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteLabelMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Please confirm that you would like to proceed with deleting the selected (checked) population labels?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                            <label className="invalidMsgErrorCls">{this.props.errorMessage ? this.props.errorMessage : this.state.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.filter ?
        (
            <div>
                {loading}
                <div className="CRStar_Window" id="ClearPopView">
                    <div className="col-sm-12">
                        <div className="_row">
                            <div className="col-sm-11" style={{ display: "flex", padding: "10px" }}>
                                <div>
                                    <label>Search:</label>
                                </div>
                                <div style={{ width: "30%" }}>
                                    <input id="popLabel_searchByFilterInput" className="" name="popLabel_searchByFilterInput"
                                        value={this.state.searchByFilterInput}
                                        onChange={this.resetFilterInputFnc} autoFocus
                                        style={{ width: "90%", margin: "0px 12px" }}
                                    />
                                </div>
                                <div style={{ paddingRight: "8px" }}>
                                    <button onClick={(e) => { this.searchByFunc(e) }} id="btnSearchByFilter">Search</button>
                                </div>
                                <div>
                                    <button onClick={(e) => { this.searchByFunc(e, 'all') }} id="btnSearchAll">All</button>
                                </div>
                            </div>
                        </div>

                        <Grid
                            style={{ height: "500px", width: "850px" }}
                            data={this.state.codes.map(item => {
                                if (this.state.selectedIds.indexOf(item.labelId) >= 0) {
                                    item.selected = true
                                } else {
                                    item.selected = false
                                }
                                return item
                            })}
                            dataItemKey={"labelId"}
                            filter={this.state.filter}
                            filterable
                            filterOperators={this.state.filterOperators}
                            onFilterChange={e => { this.pageChange(e, true) }}
                            //onRowClick={this.selectCode}
                            pageable={this.state.pageable}
                            onPageChange={e => { this.pageChange(e, false) }}
                            //scrollable
                            sortable
                            sort={this.state.sort}
                            onSortChange={e => { this.pageChange(e, "sorting") }}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.total}
                            onSelectionChange={this.selectionChange}
                            onHeaderSelectionChange={this.headerSelectionChange}
                            selectable={this.state.selectable}
                            selectedField="selected"
                            rowRender={this.rowRender}
                            navigatable={true}
                            onNavigationAction={this.handleNavigationAction}
                            onKeyDown={this.handleKeyDownAction}
                        >
                            <Column
                                field="selected"
                                width="30px" //30
                                filterable={false}
                                headerSelecttionValue={
                                    this.state.codes[0] !== undefined && this.state.codes.findIndex(dataItem => dataItem.selected === false) === -1
                                } />
                            <GridColumn field="hospitalCode" filter="text" title="Hospital Code" filterable={false} width="100px" />
                            <GridColumn field="label" filter="text" title="Population Label" filterable={false} width="385px"/>
                            <Column field="requestDate" filter="text" title="Request Date" filterable={false} width="95px" cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                            <GridColumn field="requestedBy" filter="text" title="Requested By" filterable={false} width="140px"/>
                            <GridColumn field="numberOfRecords" filter="text" title="Nbr Cases" filterable={false} textAlign="right" width="80px" style={{ textAlign: "right" }} />
                        </Grid>

                        <fieldset className="bottomAreaButtonCls">
                            <legend></legend>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="A5CWLayout">
                                        <button id="BUTTON_DELETE"
                                            className={this.state.selectedIds && this.state.selectedIds != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                            onClick={(e) => { this.deleteLabels(e) }}
                                            disabled={this.state.selectedIds && this.state.selectedIds != "" ? false : true}
                                        >
                                            <img id="BUTTON_DELETE_ICON"
                                                src={this.state.selectedIds && this.state.selectedIds != "" ? "https://crstar.ers-can.com/images/$$edit.delete.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$edit.delete.png.a5image"}
                                                style={{ verticalAlign: "middle", border: "none" }}></img>
                                            Delete Labels
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        )
        : <p className="data_loading_page">Clear Populations data loading...</p>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteLabelMsg}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAddNewDialogVisible: state.patientAbstract.isAddNewDialogVisible,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        //
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        childrenOpening: state.patientAbstract.childrenOpening,
        inquiringMode: state.auth.inquiringMode,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        //deleteReportLabels: (dialogForm) => dispatch(actionCreators.deleteReportLabels(dialogForm)),
        deletePopulationLabels: (selectedList) => dispatch(actionCreators.deletePopulationLabels(selectedList)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setCurrentDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        setControlName: (name) => dispatch(actionCreators.setControlName(name)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClearPopulations);