/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Footer from "../../AbstractManagement/PatientAbstract/Shared/Footer";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import "../../AbstractManagement/PatientAbstract/PatientMaintenance/PatientMaintenance.css";
import "./ImportOptionStyle.css";
import moment from "moment";
import Draggable from 'react-draggable';
import * as actions from '../../../store/actions/index';
import ConfigData from '../../../store/config.json';
//import arrowImg from "../../../assets/images/$$navigate.arrow.right.png.a5image";
//import moveFirstImg from "../../../assets/images/$$navigate.move.first.png.a5image";
//import movePrevImg from "../../../assets/images/$$navigate.move.prev.png.a5image";
//import moveNextImg from "../../../assets/images/$$navigate.move.next.png.a5image";
//import moveLastImg from "../../../assets/images/$$navigate.move.last.png.a5image";
//import imageIcdo from "../../../assets/images/$$generic.condition.true.png.a5image";
//->https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image

class CRStarImportOptions extends Component {

    newPrimariesOptions = [
        { opt0: "Do not create new primary if 2 digit Site Code and Hospital Code match." },
        { opt1: "Do not create new primary if 2 digit Site and Hosp match a suspense case." },
        { opt2: "Do not create new primary if Site/Hosp match an abstract or a suspense case (any site) exists." },
        { opt3: "Do not create new primary if a suspense case already exists (any site)." },
        { opt4: "Do not create new primaries regardless of what primaries already exist." },
        { opt5: "Do not filter the file. Import all records into CRStar as new primaries." }
    ];
    
    dateTemplateFormatting = [
        "",
        "mm/dd/yyyy",
        "mm-dd-yyyy",
        "mmddyyyy",
        "dd/mm/yyyy",
        "dd-mm-yyyy",
        "ddmmyyyy",
        "yyyy/mm/dd",
        "yyyy-mm-dd",
        "yyyymmdd",
        "mm/dd/yy",
        "mm-dd-yy",
        "mmddyy",
        "dd/mm/yy",
        "dd-mm-yy",
        "ddmmyy",
        "yy/mm/dd",
        "yy-mm-dd",
        "yymmdd",
        "yyyy/mm",
        "yyyy-mm",
        "yyyymm"
    ];

    udefValuesMapping = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75"
    ];

    enableResetBtnCases = [
        'Redsson', 'FL Deathlist', 'IN Deathlist', 'MN Deathlist', 'MS Deathlist', 'NC Deathlist', 'OH Deathlist', 'TN Deathlist', 'TX Deathlist', 'VA Deathlist', 'WI Deathlist', 'CA Follow-Up', 'Import FL Follow Up', 'GA Follow-Up'
    ];

    importNameOptions = [
        "",
        "SSN"
        , "MRN"
        , "Hospital_Code"
        , "Last_Name"
        , "First_Name"
        , "Middle_Name"
        , "Admit_Date"
        , "Disch_Date"
        , "Dob"
        , "Disch_Disposition"
        , "Race"
        , "Sex"
        , "Marital_Status"
        , "Address_1"
        , "Address_2"
        , "City"
        , "State"
        , "Zip"
        , "Phone"
        , "Email"
        , "ICD_Code_1"
        , "ICD_Code_2"
        , "ICD_Code_3"
        , "ICD_Code_4"
        , "ICD_Code_5"
        , "ICD_Code_6"
        , "ICD_Code_7"
        , "ICD_Code_8"
        , "ICD_Code_9"
        , "ICD_Code_10"
        , "ICD_Code_11"
        , "ICD_Code_12"
        , "ICD_Code_13"
        , "ICD_Code_14"
        , "ICD_Code_15"
        , "ICD_Desc_1"
        , "ICD_Desc_2"
        , "ICD_Desc_3"
        , "ICD_Desc_4"
        , "ICD_Desc_5"
        , "ICD_Desc_6"
        , "ICD_Desc_7"
        , "ICD_Desc_8"
        , "ICD_Desc_9"
        , "ICD_Desc_10"
        , "ICD_Desc_11"
        , "ICD_Desc_12"
        , "ICD_Desc_13"
        , "ICD_Desc_14"
        , "ICD_Desc_15"
        , "Death_Date"
        , "ICD_Cause"
        , "Autopsy"
        , "County"
        , "Specimen"
        , "Path_Number"
        , "Path_Text"
        , "Morphology"
        , "Patient_Type"
        , "User_Defined_1"
        , "User_Defined_2"
        , "User_Defined_3"
        , "User_Defined_4"
        , "User_Defined_5"
        , "User_Defined_6"
        , "User_Defined_7"
        , "User_Defined_8"
        , "User_Defined_9"
        , "User_Defined_10"
        , "First_Contact_Date"
        , "Diagnosis_Date"
        , "Site_Code"
        , "Topo_Code"
        , "Name_Prefix"
        , "Name_Suffix"
        , "Birth_Surname"
        , "Occupation"
        , "Industry"
        , "Alcohol_History"
        , "Family_History"
        , "Tobacco_History"
        , "Tobacco_Use"
        , "Dx_Comments"
        , "Dx_Confirmation"
        , "Grade_Path_System"
        , "Laterality"
        , "Primary_Payer"
        , "Lymphatic_Vascular_Inv"
        , "TNM_Edition"
        , "AJCC_Basis"
        , "Clinical_T"
        , "Clinical_N"
        , "Clinical_M"
        , "Clinical_Group"
        , "Clinical_Staged_By"
        , "Clinical_Stage_Descriptor"
        , "Path_T"
        , "Path_N"
        , "Path_M"
        , "Path_Group"
        , "Path_Staged_By"
        , "Path_Stage_Descriptor"
        , "Tumor_Size"
        , "Nodes_Examined"
        , "Nodes_Positive"
        , "Chemo_Start_Date"
        , "Chemo_End_Date"
        , "Chemo_Code"
        , "Chemo_Drug"
        , "Chemo_Regimen"
        , "Chemo_Discontinued"
        , "Rad_Onc"
        , "Rad_Onc_NPI"
        , "Med_Onc"
        , "Med_Onc_NPI"
        , "Surgeon"
        , "Surgeon_NPI"
        , "Managing_Phys"
        , "Managing_Phys_NPI"
        , "National_Guidelines_Used"
        , "Admit_Class"
        , "Grade"
    ];

    importLabelBk = null;
    state = {
        activeTab: "1",
        activeTabInner: "t1",
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'crstarImportForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty crstarImportForm back to what it was on load
        initialImportForm: null,
        crstarImportForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        fieldInFocus: "importLabel",

        moid: 0,

        // XMergeOptionsImportColumnsMv
        xMergeOptionsImpo: null,
        xMergeOptionsImpoItemFields: null,
        xMergeOptionsImpoLoading: false,
        selectedXMergeOptionsImpoRowIndex: -1,
        xMergeOptionsImpoMaxlengthInput: null,

        // XMergeOptionsHospCodesMv
        xMergeOptionsHosp: null,
        xMergeOptionsHospItemFields: null,
        xMergeOptionsHospLoading: false,
        selectedXMergeOptionsHospRowIndex: 0,
        xMergeOptionsHospMaxlengthInput: null,

        // XMergeOptionsMaritalStatusMv
        xMergeOptionsMari: null,
        xMergeOptionsMariItemFields: null,
        xMergeOptionsMariLoading: false,
        selectedXMergeOptionsMariRowIndex: -1,
        xMergeOptionsMariMaxlengthInput: null,

        // XMergeOptionsRaceMv
        xMergeOptionsRace: null,
        xMergeOptionsRaceItemFields: null,
        xMergeOptionsRaceLoading: false,
        selectedXMergeOptionsRaceRowIndex: -1,
        xMergeOptionsRaceMaxlengthInput: null,

        //XMergeOptionsSexMv
        xMergeOptionsSex: null,
        xMergeOptionsSexItemFields: null,
        xMergeOptionsSexLoading: false,
        selectedXMergeOptionsSexRowIndex: -1,
        xMergeOptionsSexMaxlengthInput: null,

        //XMergeOptionsIcdMv
        xMergeOptionsIcd: null,
        xMergeOptionsIcdItemFields: null,
        xMergeOptionsIcdLoading: false,
        selectedXMergeOptionsIcdRowIndex: -1,
        xMergeOptionsIcdMaxlengthInput: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,

        loadedTableStructure: false,
        focusingField: null,

        // Initially, no file is selected
        selectedFile: null,
        showUploadSampleFile: false,
    }

    setSelectionXMergeOptionsImpoRow = (event, index) => {
        this.setState({ selectedXMergeOptionsImpoRowIndex: index });
    }
    setSelectionXMergeOptionsHospRow = (event, index) => {
        this.setState({ selectedXMergeOptionsHospRowIndex: index });
    }
    setSelectionXMergeOptionsMariRow = (event, index) => {
        this.setState({ selectedXMergeOptionsMariRowIndex: index });
    }
    setSelectionXMergeOptionsRaceRow = (event, index) => {
        this.setState({ selectedXMergeOptionsRaceRowIndex: index });
    }
    setSelectionXMergeOptionsSexRow = (event, index) => {
        this.setState({ selectedXMergeOptionsSexRowIndex: index });
    }
    setSelectionXMergeOptionsIcdRow = (event, index) => {
        this.setState({ selectedXMergeOptionsIcdRowIndex: index });
    }

    keydownHandler = (event) => {
        if (event.key === "Tab" && !event.shiftKey) {
            document.getElementById("linkOfRetiredTab2").click();
        }
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    showUploadFile = (event, flag) => {
        this.setState({ showUploadSampleFile: flag, selectedFile: null });
    }

    // On file select (from the pop up)
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

        // Continue to submit this file to server???
        this.onFileUpload(event.target.files[0]);
    };
    // On file upload (click the upload button)
    async onFileUpload(file) {
        const form = document.getElementById("uploadTemplateSampleFile");
        // Create an object of formData
        const formData = new FormData(form);

        // Update the formData object
        formData.append(
            "file",
            file,
            file.name
        );

        // Details of the uploaded file
        //console.log(this.state.selectedFile, file);

        // Request made to the backend api
        // Send formData object
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            const res = await axios.post(this.props.clientgroup + "/UploadFile", formData, config);
            //console.log("UploadFile::success", res);
            let crstarImportFormData = this.state.crstarImportForm;
            crstarImportFormData["fileName"] = res && res.data ? res.data.filename : null;
            crstarImportFormData["previewRecord"] = res && res.data ? res.data.count : null;
            crstarImportFormData["previewRecordNumber"] = res && res.data ? res.data.count : null;
            crstarImportFormData["totalRecords"] = res && res.data ? res.data.count : null;
            this.setState({
                crstarImportForm: crstarImportFormData,
                showUploadSampleFile: false
            });
            return false;
        } catch (ex) {
            console.log(ex);
        }
        return false;
    };

    toggleTabInner = (event, tab) => {
        this.setState({ activeTabInner: tab });
        if (tab === "t1") {
            setTimeout(function () {
                document.getElementById("fileTypeOpt_Delimited") ? document.getElementById("fileTypeOpt_Delimited").focus() : null;
            }, 0);
        } else if (tab === "t2") {
            setTimeout(function () {
                document.getElementById("hosp_importValue0") ? document.getElementById("hosp_importValue0").focus() : null;
            }, 0);
        } else if (tab === "t3") {
            setTimeout(function () {
                document.getElementById("mari_importValue0") ? document.getElementById("mari_importValue0").focus() : null;
            }, 0);
        } else if (tab === "t4") {
            setTimeout(function () {
                document.getElementById("race_importValue0") ? document.getElementById("race_importValue0").focus() : null;
            }, 0);
        } else if (tab === "t5") {
            setTimeout(function () {
                document.getElementById("importValue0") ? document.getElementById("importValue0").focus() : null;
            }, 0);
        } else if (tab === "t6") {
            setTimeout(function () {
                document.getElementById("icdOpt_9") ? document.getElementById("icdOpt_9").focus() : null;
            }, 0);
        } else if (tab === "t7") {
            setTimeout(function () {
                document.getElementById("specimenToTopoMapping") ? document.getElementById("specimenToTopoMapping").focus() : null;
            }, 0);
        } else if (tab === "t8") {
            setTimeout(function () {
                document.getElementById("dateFormat") ? document.getElementById("dateFormat").focus() : null;
            }, 0);
        } else if (tab === "t9") {
            setTimeout(function () {
                document.getElementById("defaultSequence") ? document.getElementById("defaultSequence").focus() : null;
            }, 0);
        } else if (tab === "t10") {
            setTimeout(function () {
                document.getElementById("patientTypeCodes") ? document.getElementById("patientTypeCodes").focus() : null;
            }, 0);
        } else if (tab === "t11") {
            setTimeout(function () {
                document.getElementById("numberOfHeaderRows") ? document.getElementById("numberOfHeaderRows").focus() : null;
            }, 0);
        } else if (tab === "t12") {
            setTimeout(function () {
                document.getElementById("userText1") ? document.getElementById("userText1").focus() : null;
            }, 0);
        } else if (tab === "t13") {
            setTimeout(function () {
                document.getElementById("doubleSpace") ? document.getElementById("doubleSpace").focus() : null;
            }, 0);
        }
        return false;
    }

    hideCopyErrorMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ showCopyError: false, showCopyDialog: true });
        setTimeout(function () {
            document.getElementById("newImportLabel") ? document.getElementById("newImportLabel").focus() : null;
        }, 0);
        return false;
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "XMergeOptionsImpo":
                case "XMergeOptionsImportColumnsMv":
                    this.deleteXMergeOptionsImpoGrid();
                    break;
                case "XMergeOptionsHosp":
                case "XMergeOptionsHospCodesMv":
                    this.deleteXMergeOptionsHospGrid();
                    break;
                case "XMergeOptionsMari":
                case "XMergeOptionsMaritalStatusMv":
                    this.deleteXMergeOptionsMariGrid();
                    break;
                case "XMergeOptionsRace":
                case "XMergeOptionsRaceMv":
                    this.deleteXMergeOptionsRaceGrid();
                    break;
                case "XMergeOptionsSex":
                case "XMergeOptionsSexMv":
                    this.deleteXMergeOptionsSexGrid();
                    break;
                case "XMergeOptionsIcd":
                case "XMergeOptionsIcdMv":
                    this.deleteXMergeOptionsIcdGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    showPreviewData = (data) => {
        console.log("Preview data: ", data);
        this.setState({
            previewed: true,
            showingPreviewData: true,
            previewData: { ...data },
            fnDisplay: data.fnDisplay,
        });
        var htmlOutput = data && data.previewHtml ? data.previewHtml : "";

        var previewHtmlIframe = document.getElementById("previewHtmlIframe");
        if (previewHtmlIframe) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            previewHtmlIframe.src = "about:blank";
            var previewHtmlContent = previewHtmlIframe.contentWindow || previewHtmlIframe.contentDocument.document || previewHtmlIframe.contentDocument;
            if (previewHtmlContent.write) {
                previewHtmlContent.write(htmlOutput);
            } else {
                previewHtmlContent.document.write(htmlOutput);
            }
        }

        setTimeout(function () {
            var previewHtmlIframe = document.getElementById("previewHtmlIframe");
            if (previewHtmlIframe) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
                previewHtmlIframe.src = "about:blank";
                var previewHtmlContent = previewHtmlIframe.contentWindow || previewHtmlIframe.contentDocument.document || previewHtmlIframe.contentDocument;
                if (previewHtmlContent.write) {
                    previewHtmlContent.write(htmlOutput);
                } else {
                    previewHtmlContent.document.write(htmlOutput);
                }
            }
            return false;
        }, 500);

        return false;
    }
    hidePreviewData = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingPreviewData: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnPreview') ? document.getElementById('btnPreview').focus() : "";
            return false;
        }, 100);
        return false;
    }

    moveFirstItem = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        var previewData = this.state.previewData;
        previewData.currentRecord = 1;
        this.setState({ previewData: previewData, previewed: false });

        var _this = this;
        setTimeout(function () {
            _this.previewFuncHandler(1);
        }, 1000);
        return false;
    }
    movePrevItem = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        let curNo = this.state.previewData && this.state.previewData.currentRecord ? this.state.previewData.currentRecord : 1;

        var previewData = this.state.previewData;
        previewData.currentRecord = curNo - 1;
        this.setState({ previewData: previewData, previewed: false });

        var _this = this;
        setTimeout(function () {
            _this.previewFuncHandler(curNo - 1);
        }, 1000);
        return false;
    }
    moveNextItem = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        let curNo = this.state.previewData && this.state.previewData.currentRecord ? this.state.previewData.currentRecord : 1;

        var previewData = this.state.previewData;
        previewData.currentRecord = curNo + 1;
        this.setState({ previewData: previewData, previewed: false });

        var _this = this;
        setTimeout(function () {
            _this.previewFuncHandler(curNo + 1);
        }, 1000);
        return false;
    }
    moveLastItem = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        let totalRecords = this.state.crstarImportForm.totalRecords ? this.state.crstarImportForm.totalRecords : this.state.crstarImportForm.previewRecord;

        var previewData = this.state.previewData;
        previewData.currentRecord = totalRecords;
        this.setState({ previewData: previewData, previewed: false });

        var _this = this;
        setTimeout(function () {
            _this.previewFuncHandler(totalRecords);
        }, 1000);
        return false;
    }

    componentDidMount() {
        this.setState({ reloadPage: true, crstarImportForm: null });
        let inVal = setInterval(function (e) {
            if (document.getElementById("btnAddNewItem0")) {
                document.getElementById("btnAddNewItem0").click();
                clearInterval(inVal);
                return false;
            }
        }, 1);
    }

    componentDidUnmount() {
        this.props.loadImportConfig("");
        this.setState({ reloadPage: true, crstarImportForm: null });
        setTimeout(function (e) {
            document.getElementById("btnAddNewItem0") ? document.getElementById("btnAddNewItem0").click() : null;
        }, 100);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.crstarImportForm && this.props.importConfigInfo)
            || (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.mergeOptionsId !== this.props.importConfigInfo.mergeOptionsId)
            || (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.importLabel !== this.props.importConfigInfo.importLabel)
            || (this.state.reloadData && this.props.importConfigInfo)

            || (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.lastTimeAccess !== this.props.importConfigInfo.lastTimeAccess)
        ) {
            this.setFormData(
                !prevProps.importConfigInfo || !this.props.importConfigInfo
                || !this.props.importConfigInfo.importLabel
                || prevProps.importConfigInfo.mergeOptionsId !== this.props.importConfigInfo.mergeOptionsId
                || this.props.importConfigInfo.importLabel == ""
            );
        }

        if (!this.state.previewed && this.props.importConfigInfo && this.state.fnDisplay != this.props.importConfigInfo.fnDisplay) {
            this.showPreviewData(this.props.importConfigInfo);
        }

        // 1. XMergeOptionsImpoList
        if (this.props.importConfigInfo && this.state.moid != 0 && ((!this.state.xMergeOptionsImpo && !this.state.xMergeOptionsImpoLoading) || this.state.reloadData)) {
            this.setState({ xMergeOptionsImpoLoading: true });
            //axios.get(this.props.clientgroup + "/GetXMergeOptionsImpoList?moid=" + this.state.moid)
            let url = this.props.clientgroup + "/GetXMergeOptionsImpoList";
            let params = { moid: this.state.moid, accessToken: localStorage.getItem('token') };

            axios.post( url, params )
                .then(res => {
                    //update new accessToken again
                    let newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    };

                    if (!res.data.error) {
                        this.setState({ xMergeOptionsImpo: res.data.xMergeOptionsImpoList });

                        if (!this.state.xMergeOptionsImpoItemFields && res.data.xMergeOptionsImpoList) {
                            let objArr = [];
                            res.data.xMergeOptionsImpoList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ xMergeOptionsImpoItemFields: objArr });

                            // get maxlength for XMergeOptionsImpo grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure?table=x_Merge_Options_Import_Columns_Mv")
                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "x_Merge_Options_Import_Columns_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
                                .then(res => {
                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            xMergeOptionsImpoMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);

                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        // 2. XMergeOptionsHospList
        if (this.props.importConfigInfo && this.state.moid && ((!this.state.xMergeOptionsHosp && !this.state.xMergeOptionsHospLoading) || this.state.reloadData)) {
            this.setState({ xMergeOptionsHospLoading: true });
            //axios.get(this.props.clientgroup + "/GetXMergeOptionsHospList?moid=" + this.state.moid)
            //here I use Moid in upper case because in the backend is used in that way
            axios.post(this.props.clientgroup + "/GetXMergeOptionsHospList", { moid: this.state.moid, accessToken: localStorage.getItem('token') })
                .then(res => {
		        //update new accessToken again
		        var newAccessToken = res && res.data ? res.data.accessToken : null;
		        if (newAccessToken) {
		            localStorage.setItem('token', newAccessToken);
		        }

                    if (!res.data.error) {
                        this.setState({ xMergeOptionsHosp: res.data.xMergeOptionsHospList });

                        if (!this.state.xMergeOptionsHospItemFields && res.data.xMergeOptionsHospList) {
                            let objArr = [];
                            res.data.xMergeOptionsHospList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ xMergeOptionsHospItemFields: objArr });

                            // get maxlength for XMergeOptionsHosp grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure", {table: "x_Merge_Options_Hosp_Codes_Mv", accessToken: localStorage.getItem('token') })

                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "x_Merge_Options_Hosp_Codes_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
			                    .then(res => {
			                        //update new accessToken again
			                        var newAccessToken = res && res.data ? res.data.accessToken : null;
			                        if (newAccessToken) {
			                            localStorage.setItem('token', newAccessToken);
			                        }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            xMergeOptionsHospMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
		                        .catch(function (error) {
		                            console.log(error);

		                            var btnLogout = document.getElementById("logoutButtonArea");
		                            if (btnLogout) {
		                                btnLogout.querySelector("a").click();
		                            }
		                            return false;
                                });
                        };
                    }
                })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        }

        // 3. XMergeOptionsMariList
        if (this.props.importConfigInfo && this.state.moid && ((!this.state.xMergeOptionsMari && !this.state.xMergeOptionsMariLoading) || this.state.reloadData)) {
            this.setState({ xMergeOptionsMariLoading: true });
            //axios.get(this.props.clientgroup + "/GetXMergeOptionsMariList?moid=" + this.state.moid)
            axios.post(this.props.clientgroup + "/GetXMergeOptionsMariList", { moid: this.state.moid, accessToken: localStorage.getItem('token') })
                .then(res => {
	                //update new accessToken again
	                var newAccessToken = res && res.data ? res.data.accessToken : null;
	                if (newAccessToken) {
	                    localStorage.setItem('token', newAccessToken);
	                }

                    if (!res.data.error) {
                        this.setState({ xMergeOptionsMari: res.data.xMergeOptionsMariList });

                        if (!this.state.xMergeOptionsMariItemFields && res.data.xMergeOptionsMariList) {
                            let objArr = [];
                            res.data.xMergeOptionsMariList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ xMergeOptionsMariItemFields: objArr });

                            // get maxlength for XMergeOptionsMari grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure", { table: "x_Merge_Options_Marital_Status_Mv", accessToken: localStorage.getItem('token') })

                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "x_Merge_Options_Marital_Status_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
			                .then(res => {
			                    //update new accessToken again
			                    var newAccessToken = res && res.data ? res.data.accessToken : null;
			                    if (newAccessToken) {
			                        localStorage.setItem('token', newAccessToken);
			                    }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;
                                    let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    this.setState({
                                        xMergeOptionsMariMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
		                    .catch(function (error) {
		                        console.log(error);

		                        var btnLogout = document.getElementById("logoutButtonArea");
		                        if (btnLogout) {
		                            btnLogout.querySelector("a").click();
		                        }
		                        return false;
                            });
                    };
                }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        }

        // 4. XMergeOptionsRaceList
        if (this.props.importConfigInfo && this.state.moid && ((!this.state.xMergeOptionsRace && !this.state.xMergeOptionsRaceLoading) || this.state.reloadData)) {
            this.setState({ xMergeOptionsRaceLoading: true });
            //axios.get(this.props.clientgroup + "/GetXMergeOptionsRaceList?moid=" + this.state.moid)
            //here I use Moid in upper case because in the backend is used in that way
            axios.post(this.props.clientgroup + "/GetXMergeOptionsRaceList", { moid: this.state.moid, accessToken: localStorage.getItem('token') })
                .then(res => {
		            //update new accessToken again
		            var newAccessToken = res && res.data ? res.data.accessToken : null;
		            if (newAccessToken) {
		                localStorage.setItem('token', newAccessToken);
		            }

                    if (!res.data.error) {
                        this.setState({ xMergeOptionsRace: res.data.xMergeOptionsRaceList });

                        if (!this.state.xMergeOptionsRaceItemFields && res.data.xMergeOptionsRaceList) {
                            let objArr = [];
                            res.data.xMergeOptionsRaceList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ xMergeOptionsRaceItemFields: objArr });

                            // get maxlength for XMergeOptionsRace grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure", { table: "x_Merge_Options_Race_Mv", accessToken: localStorage.getItem('token') })
                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "x_Merge_Options_Race_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
			                    .then(res => {
			                        //update new accessToken again
			                        var newAccessToken = res && res.data ? res.data.accessToken : null;
			                        if (newAccessToken) {
			                            localStorage.setItem('token', newAccessToken);
			                        }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            xMergeOptionsRaceMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
		                        .catch(function (error) {
		                            console.log(error);

		                            var btnLogout = document.getElementById("logoutButtonArea");
		                            if (btnLogout) {
		                                btnLogout.querySelector("a").click();
		                            }
		                            return false;
                                });
                        };
                    }
                })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        }

        // 5. XMergeOptionsSexList
        if (this.props.importConfigInfo && this.state.moid && ((!this.state.xMergeOptionsSex && !this.state.xMergeOptionsSexLoading) || this.state.reloadData)) {
            this.setState({ xMergeOptionsSexLoading: true });
            //axios.get(this.props.clientgroup + "/GetXMergeOptionsSexList?moid=" + this.state.moid)
            //here I use Moid in upper case because in the backend is used in that way
            axios.post(this.props.clientgroup + "/GetXMergeOptionsSexList", { moid: this.state.moid, accessToken: localStorage.getItem('token') })
                .then(res => {
		            //update new accessToken again
		            var newAccessToken = res && res.data ? res.data.accessToken : null;
		            if (newAccessToken) {
		                localStorage.setItem('token', newAccessToken);
		            }

                    if (!res.data.error) {
                        this.setState({ xMergeOptionsSex: res.data.xMergeOptionsSexList });

                        if (!this.state.xMergeOptionsSexItemFields && res.data.xMergeOptionsSexList) {
                            let objArr = [];
                            res.data.xMergeOptionsSexList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ xMergeOptionsSexItemFields: objArr });

                            // get maxlength for XMergeOptionsSex grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure", { table: "x_Merge_Options_Sex_Mv", accessToken: localStorage.getItem('token') })

                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "x_Merge_Options_Sex_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
			                .then(res => {
			                    //update new accessToken again
			                    var newAccessToken = res && res.data ? res.data.accessToken : null;
			                    if (newAccessToken) {
			                        localStorage.setItem('token', newAccessToken);
			                    }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;
                                    let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    this.setState({
                                        xMergeOptionsSexMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
		                    .catch(function (error) {
		                        console.log(error);

		                        var btnLogout = document.getElementById("logoutButtonArea");
		                        if (btnLogout) {
		                            btnLogout.querySelector("a").click();
		                        }
		                        return false;
                            });
                    };
                }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        }

        // 6. XMergeOptionsIcdList
        if (this.props.importConfigInfo && this.state.moid && ((!this.state.xMergeOptionsIcd && !this.state.xMergeOptionsIcdLoading) || this.state.reloadData)) {
            this.setState({ xMergeOptionsIcdLoading: true });
            //axios.get(this.props.clientgroup + "/GetXMergeOptionsIcdList?moid=" + this.state.moid)
            //here I use Moid in upper case because in the backend is used in that way
            axios.post(this.props.clientgroup + "/GetXMergeOptionsIcdList", { moid: this.state.moid, accessToken: localStorage.getItem('token') })
                .then(res => {
		            //update new accessToken again
		            var newAccessToken = res && res.data ? res.data.accessToken : null;
		            if (newAccessToken) {
		                localStorage.setItem('token', newAccessToken);
		            }

                    if (!res.data.error) {
                        this.setState({ xMergeOptionsIcd: res.data.xMergeOptionsIcdList });

                        if (!this.state.xMergeOptionsIcdItemFields && res.data.xMergeOptionsIcdList) {
                            let objArr = [];
                            res.data.xMergeOptionsIcdList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ xMergeOptionsIcdItemFields: objArr });

                            // get maxlength for XMergeOptionsIcd grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure", { table: "x_Merge_Options_Icd_Mv", accessToken: localStorage.getItem('token') })
                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "x_Merge_Options_Icd_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
			                .then(res => {
			                    //update new accessToken again
			                    var newAccessToken = res && res.data ? res.data.accessToken : null;
			                    if (newAccessToken) {
			                        localStorage.setItem('token', newAccessToken);
			                    }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;
                                    let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    this.setState({
                                        xMergeOptionsIcdMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
		                    .catch(function (error) {
		                        console.log(error);

		                        var btnLogout = document.getElementById("logoutButtonArea");
		                        if (btnLogout) {
		                            btnLogout.querySelector("a").click();
		                        }
		                        return false;
                            });
                    };
                }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        }

        //
        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.crstarImportForm && this.state.dAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    crstarImportForm: { ...this.state.crstarImportForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    crstarImportForm: { ...this.state.crstarImportForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "x_Merge_Options", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
	
        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        /*this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data : []
        });*/

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    // Add event handler for all input fields on grid - START
    // 1. XMergeOptionsImpo grid
    handleXMergeOptionsImpoLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.xMergeOptionsImpoItemFields];
                newItems[index][name] = "";
                return { xMergeOptionsImpoItemFields: newItems };
            });
        }
    }
    handleXMergeOptionsImpoGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsImpo];
            newItems[index][name] = value;
            return { xMergeOptionsImpo: newItems, isFormDirty: true };
        });
    }
    addNewXMergeOptionsImpo = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.xMergeOptionsImpo];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.xMergeOptionsImpoItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { xMergeOptionsImpo: newItems, xMergeOptionsImpoItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }
    deleteSelectedXMergeOptionsImpo = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedXMergeOptionsImpoRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsImpo.length) {
            selectedRow = this.state.xMergeOptionsImpo.length - 1;
        }

        if (this.state.xMergeOptionsImpo[selectedRow] && this.state.xMergeOptionsImpo[selectedRow]["id"] !== undefined && this.state.xMergeOptionsImpo[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "XMergeOptionsImportColumnsMv" });
        } else {
            this.deleteXMergeOptionsImpoGrid();
        }
        return false;
    }
    deleteXMergeOptionsImpoGrid = () => {
        let selectedRow = this.state.selectedXMergeOptionsImpoRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsImpo.length) {
            selectedRow = this.state.xMergeOptionsImpo.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsImpo];
            if (newItems.length === 1) {
                const _newItems = [...this.state.xMergeOptionsImpo];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "mergeOptionsId") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.xMergeOptionsImpoItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.xMergeOptionsImpoItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { xMergeOptionsImpo: newItems, xMergeOptionsImpoItemFields: newFields, selectedXMergeOptionsImpoRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // 2. XMergeOptionsHosp grid
    handleXMergeOptionsHospLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.xMergeOptionsHospItemFields];
                newItems[index][name] = "";
                return { xMergeOptionsHospItemFields: newItems };
            });
        }
    }
    handleXMergeOptionsHospGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsHosp];
            newItems[index][name] = value;
            return { xMergeOptionsHosp: newItems, isFormDirty: true };
        });
    }
    addNewXMergeOptionsHosp = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.xMergeOptionsHosp];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.xMergeOptionsHospItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { xMergeOptionsHosp: newItems, xMergeOptionsHospItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }
    deleteSelectedXMergeOptionsHosp = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedXMergeOptionsHospRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsHosp.length) {
            selectedRow = this.state.xMergeOptionsHosp.length - 1;
        }

        if (this.state.xMergeOptionsHosp[selectedRow] && this.state.xMergeOptionsHosp[selectedRow]["id"] !== undefined && this.state.xMergeOptionsHosp[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "XMergeOptionsHospCodesMv" });
        } else {
            this.deleteXMergeOptionsHospGrid();
        }
        return false;
    }
    deleteXMergeOptionsHospGrid = () => {
        let selectedRow = this.state.selectedXMergeOptionsHospRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsHosp.length) {
            selectedRow = this.state.xMergeOptionsHosp.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsHosp];
            if (newItems.length === 1) {
                const _newItems = [...this.state.xMergeOptionsHosp];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "mergeOptionsId") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.xMergeOptionsHospItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.xMergeOptionsHospItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { xMergeOptionsHosp: newItems, xMergeOptionsHospItemFields: newFields, selectedXMergeOptionsHospRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // 3. XMergeOptionsMari grid
    handleXMergeOptionsMariLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.xMergeOptionsMariItemFields];
                newItems[index][name] = "";
                return { xMergeOptionsMariItemFields: newItems };
            });
        }
    }
    handleXMergeOptionsMariGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsMari];
            newItems[index][name] = value;
            return { xMergeOptionsMari: newItems, isFormDirty: true };
        });
    }
    addNewXMergeOptionsMari = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.xMergeOptionsMari];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.xMergeOptionsMariItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { xMergeOptionsMari: newItems, xMergeOptionsMariItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }
    deleteSelectedXMergeOptionsMari = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedXMergeOptionsMariRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsMari.length) {
            selectedRow = this.state.xMergeOptionsMari.length - 1;
        }

        if (this.state.xMergeOptionsMari[selectedRow] && this.state.xMergeOptionsMari[selectedRow]["id"] !== undefined && this.state.xMergeOptionsMari[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "XMergeOptionsMaritalStatusMv" });
        } else {
            this.deleteXMergeOptionsMariGrid();
        }
        return false;
    }
    deleteXMergeOptionsMariGrid = () => {
        let selectedRow = this.state.selectedXMergeOptionsMariRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsMari.length) {
            selectedRow = this.state.xMergeOptionsMari.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsMari];
            if (newItems.length === 1) {
                const _newItems = [...this.state.xMergeOptionsMari];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "mergeOptionsId") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.xMergeOptionsMariItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.xMergeOptionsMariItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { xMergeOptionsMari: newItems, xMergeOptionsMariItemFields: newFields, selectedXMergeOptionsMariRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // 4. XMergeOptionsRace grid
    handleXMergeOptionsRaceLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.xMergeOptionsRaceItemFields];
                newItems[index][name] = "";
                return { xMergeOptionsRaceItemFields: newItems };
            });
        }
    }
    handleXMergeOptionsRaceGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsRace];
            newItems[index][name] = value;
            return { xMergeOptionsRace: newItems, isFormDirty: true };
        });
    }
    addNewXMergeOptionsRace = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.xMergeOptionsRace];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.xMergeOptionsRaceItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { xMergeOptionsRace: newItems, xMergeOptionsRaceItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }
    deleteSelectedXMergeOptionsRace = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedXMergeOptionsRaceRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsRace.length) {
            selectedRow = this.state.xMergeOptionsRace.length - 1;
        }

        if (this.state.xMergeOptionsRace[selectedRow] && this.state.xMergeOptionsRace[selectedRow]["id"] !== undefined && this.state.xMergeOptionsRace[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "XMergeOptionsRaceMv" });
        } else {
            this.deleteXMergeOptionsRaceGrid();
        }
        return false;
    }
    deleteXMergeOptionsRaceGrid = () => {
        let selectedRow = this.state.selectedXMergeOptionsRaceRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsRace.length) {
            selectedRow = this.state.xMergeOptionsRace.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsRace];
            if (newItems.length === 1) {
                const _newItems = [...this.state.xMergeOptionsRace];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "mergeOptionsId") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.xMergeOptionsRaceItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.xMergeOptionsRaceItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }
            return { xMergeOptionsRace: newItems, xMergeOptionsRaceItemFields: newFields, selectedXMergeOptionsRaceRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // 5. XMergeOptionsSex grid
    handleXMergeOptionsSexLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.xMergeOptionsSexItemFields];
                newItems[index][name] = "";
                return { xMergeOptionsSexItemFields: newItems };
            });
        }
    }
    handleXMergeOptionsSexGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsSex];
            newItems[index][name] = value;
            return { xMergeOptionsSex: newItems, isFormDirty: true };
        });
    }
    addNewXMergeOptionsSex = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.xMergeOptionsSex];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.xMergeOptionsSexItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { xMergeOptionsSex: newItems, xMergeOptionsSexItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }
    deleteSelectedXMergeOptionsSex = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedXMergeOptionsSexRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsSex.length) {
            selectedRow = this.state.xMergeOptionsSex.length - 1;
        }

        if (this.state.xMergeOptionsSex[selectedRow] && this.state.xMergeOptionsSex[selectedRow]["id"] !== undefined && this.state.xMergeOptionsSex[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "XMergeOptionsSexMv" });
        } else {
            this.deleteXMergeOptionsSexGrid();
        }
        return false;
    }
    deleteXMergeOptionsSexGrid = () => {
        let selectedRow = this.state.selectedXMergeOptionsSexRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsSex.length) {
            selectedRow = this.state.xMergeOptionsSex.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsSex];
            if (newItems.length === 1) {
                const _newItems = [...this.state.xMergeOptionsSex];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "mergeOptionsId") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.xMergeOptionsSexItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.xMergeOptionsSexItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }
            return { xMergeOptionsSex: newItems, xMergeOptionsSexItemFields: newFields, selectedXMergeOptionsSexRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // 6. XMergeOptionsIcd grid
    handleXMergeOptionsIcdLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.xMergeOptionsIcdItemFields];
                newItems[index][name] = "";
                return { xMergeOptionsIcdItemFields: newItems };
            });
        }
    }
    handleXMergeOptionsIcdGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsIcd];
            newItems[index][name] = value;
            return { xMergeOptionsIcd: newItems, isFormDirty: true };
        });
    }
    addNewXMergeOptionsIcd = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.xMergeOptionsIcd];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.xMergeOptionsIcdItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "mergeOptionsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { xMergeOptionsIcd: newItems, xMergeOptionsIcdItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }
    deleteSelectedXMergeOptionsIcd = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedXMergeOptionsIcdRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsIcd.length) {
            selectedRow = this.state.xMergeOptionsIcd.length - 1;
        }

        if (this.state.xMergeOptionsIcd[selectedRow] && this.state.xMergeOptionsIcd[selectedRow]["id"] !== undefined && this.state.xMergeOptionsIcd[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "XMergeOptionsIcdMv" });
        } else {
            this.deleteXMergeOptionsIcdGrid();
        }
        return false;
    }
    deleteXMergeOptionsIcdGrid = () => {
        let selectedRow = this.state.selectedXMergeOptionsIcdRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.xMergeOptionsIcd.length) {
            selectedRow = this.state.xMergeOptionsIcd.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.xMergeOptionsIcd];
            if (newItems.length === 1) {
                const _newItems = [...this.state.xMergeOptionsIcd];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "mergeOptionsId") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.xMergeOptionsIcdItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.xMergeOptionsIcdItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }
            return { xMergeOptionsIcd: newItems, xMergeOptionsIcdItemFields: newFields, selectedXMergeOptionsIcdRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }
    // Add event handler for all input fields on grid - END

    // Set data into form
    setFormData = (resetId) => {
        var importConfigInfo = this.props.importConfigInfo;

        this.setState({
            moid: importConfigInfo.mergeOptionsId && resetId ? importConfigInfo.mergeOptionsId : 0,
            initialImportForm: {
                ...importConfigInfo,
            },
            crstarImportForm: {
                ...importConfigInfo,
                matchOnLastName: importConfigInfo.matchOnLastName ? importConfigInfo.matchOnLastName : "true",
                matchOnFirstName: importConfigInfo.matchOnFirstName ? importConfigInfo.matchOnFirstName : "true",
                matchOnSsn: importConfigInfo.matchOnSsn ? importConfigInfo.matchOnSsn : "true",
                defaultCountry: importConfigInfo.defaultCountry ? importConfigInfo.defaultCountry : "true",
                convertUnkSsn: importConfigInfo.convertUnkSsn ? importConfigInfo.convertUnkSsn : "true",
                defaultSequence: importConfigInfo.defaultSequence == "undefined" || importConfigInfo.defaultSequence == null ? "99" : importConfigInfo.defaultSequence,
                defaultClass: importConfigInfo.defaultClass == "undefined" || importConfigInfo.defaultClass == null ? "99" : importConfigInfo.defaultClass,
                numberOfHeaderRows: importConfigInfo.numberOfHeaderRows ? importConfigInfo.numberOfHeaderRows : "0",
                dateToUpdate: importConfigInfo.dateToUpdate ? importConfigInfo.dateToUpdate : "Discharge",

                fileType: importConfigInfo.fileType && importConfigInfo.fileType != "" ? importConfigInfo.fileType : "Delimited",
                xmlType: importConfigInfo.xmlType && importConfigInfo.xmlType != "" ? importConfigInfo.xmlType : "Attribute",
                importType: importConfigInfo.importType && importConfigInfo.importType != "" ? importConfigInfo.importType : "Casefinding and Follow-Up",
                newPrimaries: importConfigInfo.newPrimaries && importConfigInfo.newPrimaries != "" ? importConfigInfo.newPrimaries : this.newPrimariesOptions[0]["opt0"],
                icdVersion: importConfigInfo.icdVersion && importConfigInfo.icdVersion != "" ? importConfigInfo.icdVersion : "ICD-10",
                updateMatchingAllSites: importConfigInfo.updateMatchingAllSites && importConfigInfo.updateMatchingAllSites != "" ? importConfigInfo.updateMatchingAllSites : "Only matching sites",

                dateConfigLastUpdated: importConfigInfo.dateConfigLastUpdated ? moment(new Date(importConfigInfo.dateConfigLastUpdated)).format("MM/DD/YYYY hh:mm:ss a") : ""//05/22/2017 04:51:48 10 pm
            },

            selectedFileTypeOption: importConfigInfo.fileType && importConfigInfo.fileType != "" ? importConfigInfo.fileType : "Delimited",
            selectedXmlTypeOption: importConfigInfo.xmlType && importConfigInfo.xmlType != "" ? importConfigInfo.xmlType : "Attribute",
            selectedImportTypeOption: importConfigInfo.importType && importConfigInfo.importType != "" ? importConfigInfo.importType : "Casefinding and Follow-Up",
            selectedNewPrimariesOption: importConfigInfo.newPrimaries && importConfigInfo.newPrimaries != "" ? importConfigInfo.newPrimaries : this.newPrimariesOptions[0]["opt0"],
            icdVersionOption: importConfigInfo.icdVersion && importConfigInfo.icdVersion != "" ? importConfigInfo.icdVersion : "ICD-10",
            updateSiteAllOption: importConfigInfo.updateMatchingAllSites && importConfigInfo.updateMatchingAllSites != "" ? importConfigInfo.updateMatchingAllSites : "Only matching sites",

            isFormDirty: this.props.isFormDirty,
            reloadedImportLabel: importConfigInfo && importConfigInfo.mergeOptionsId ? true : false,
            reloadData: importConfigInfo.mergeOptionsId != 0 ? false : this.state.reloadData,
        });

        if (!this.state.dAllItemFields && importConfigInfo) {
            let fields = {};
            Object.keys(this.props.importConfigInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "recurrenceMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.recurrenceMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { recurrenceMvItemFields: newItems };
            });
        } else if (onGrid && onGrid === "userChemoMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.userChemoMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { userChemoMvItemFields: newItems };
            });
        } else if (onGrid && onGrid === "userRadiationMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.userRadiationMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { userRadiationMvItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "recurrenceMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.recurrenceMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { recurrenceMvItemFields: newItems };
                        });
                    } else if (onGrid && onGrid === "userChemoMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.userChemoMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { userChemoMvItemFields: newItems };
                        });
                    } else if (onGrid && onGrid === "userRadiationMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.userRadiationMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { userRadiationMvItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveImportConfig = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        /*
        const formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "posBxDate" || t === "dateConclusive" || t === "dateMultTum" || t === "dateEntered") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        // RecurrenceMv grid
        if (isValid) {
            const pItems = this.state.recurrenceMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "recurDate") {
                        this.clearInvalidOfDateFields(prm, field, "recurrenceMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        // UserChemoMv grid
        if (isValid) {
            const pItems = this.state.userChemoMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "chemoDate") {
                        this.clearInvalidOfDateFields(prm, field, "userChemoMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        // UserRadiationMv grid
        if (isValid) {
            const pItems = this.state.userRadiationMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "radDate") {
                        this.clearInvalidOfDateFields(prm, field, "userRadiationMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }
        */

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let crstarImportFormData = this.state.crstarImportForm;
            let importColumnsMvList = this.state.xMergeOptionsImpo;
            let hospCodesMvList = this.state.xMergeOptionsHosp;
            let maritalStatusMvList = this.state.xMergeOptionsMari;
            let raceMvList = this.state.xMergeOptionsRace;
            let sexMvList = this.state.xMergeOptionsSex;
            let icdMvList = this.state.xMergeOptionsIcd;

            //
            let isValidDateInput = true;

            /*
            Object.keys(crstarImportFormData).map((t, k) => {
                if (t === "posBxDate" || t === "dateConclusive" || t === "dateMultTum" || t === "dateEntered") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(crstarImportFormData, t);
                }
            });

            recurrenceMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "recurDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "recurrenceMvList", index);
                    }
                });
            });

            userChemoMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "chemoDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "userChemoMvList", index);
                    }
                });
            });

            userRadiationMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "radDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "userRadiationMvList", index);
                    }
                });
            });
            */

            if (!isValidDateInput && !allowSaveAnyway) return false;

            crstarImportFormData.xMergeOptionsImportColumnsMv = importColumnsMvList;
            crstarImportFormData.xMergeOptionsHospCodesMv = hospCodesMvList;
            crstarImportFormData.xMergeOptionsMaritalStatusMv = maritalStatusMvList;
            crstarImportFormData.xMergeOptionsRaceMv = raceMvList;
            crstarImportFormData.xMergeOptionsSexMv = sexMvList;
            crstarImportFormData.xMergeOptionsIcdMv = icdMvList;

            crstarImportFormData.action = "Save";
            crstarImportFormData.userId = this.props.userId;

            this.props.saveImportConfig(crstarImportFormData);
            this.setState({ isFormDirty: false });

            return false;
        }
    }

    reloadPage = () => {
        this.setState({
            showCopyDialog: false,
            isSaving: true,
            savedForm: true,
            isFormDirty: false,

            reloadData: true,
            xMergeOptionsImpoItemFields: null, xMergeOptionsImpo: null, xMergeOptionsImpoLoading: false,
            xMergeOptionsHospItemFields: null, xMergeOptionsHosp: null, xMergeOptionsHospLoading: false,
            xMergeOptionsMariItemFields: null, xMergeOptionsMari: null, xMergeOptionsMariLoading: false,
            xMergeOptionsRaceItemFields: null, xMergeOptionsRace: null, xMergeOptionsRaceLoading: false,
            xMergeOptionsSexItemFields: null, xMergeOptionsSex: null, xMergeOptionsSexLoading: false,
            xMergeOptionsIcdItemFields: null, xMergeOptionsIcd: null, xMergeOptionsIcdLoading: false,
            moid: 0
        });
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.dAllItemFields && this.props.importConfigInfo) {
            Object.keys(this.props.importConfigInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            crstarImportForm: { ...this.state.initialImportForm },
            dAllItemFields: fields,
            //pageMaxlengthInput: fields,
            reload: false,
            showCopyDialog: false,
            isSaving: true,
            savedForm: true,
            isFormDirty: false,

            reloadData: true,
            xMergeOptionsImpoItemFields: null, xMergeOptionsImpo: null, xMergeOptionsImpoLoading: false,
            xMergeOptionsHospItemFields: null, xMergeOptionsHosp: null, xMergeOptionsHospLoading: false,
            xMergeOptionsMariItemFields: null, xMergeOptionsMari: null, xMergeOptionsMariLoading: false,
            xMergeOptionsRaceItemFields: null, xMergeOptionsRace: null, xMergeOptionsRaceLoading: false,
            xMergeOptionsSexItemFields: null, xMergeOptionsSex: null, xMergeOptionsSexLoading: false,
            xMergeOptionsIcdItemFields: null, xMergeOptionsIcd: null, xMergeOptionsIcdLoading: false,
            moid: 0
        });

        //
        setTimeout(function () {
            document.getElementById("importLabel0") ? document.getElementById("importLabel0").focus() : "";
        }, 500);
    }

    resetImportForm = () => {
        let fields = {};
        Object.keys(this.state.dAllItemFields).map((t) => {
            fields[t] = "";
        });
        this.setState({
            crstarImportForm: { ...this.state.initialImportForm },
            dAllItemFields: fields,
            isFormDirty: false,
        });
        return false;
    }

    previewFuncHandler = (curNo) => {
        let crstarImportFormData = this.state.crstarImportForm;
        
        this.props.storeToGlobal(null);
        this.setState({ previewing: false, previewed: false });

        crstarImportFormData.action = "Preview_File";
        if (!crstarImportFormData.importName) {
            crstarImportFormData.importName = crstarImportFormData.importLabel;
        }

        crstarImportFormData.userId = this.props.userId;
        crstarImportFormData.clientAlias = this.props.clientgroup;        
        crstarImportFormData.currentRecord = curNo;

        this.props.saveImportConfig(crstarImportFormData);
        return false;
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
            width: rect.width,
            height: rect.height
        }
    }

    copyFuncHandler = () => {
        var target = document.getElementById("btnCopy");
        var divOffset = this.offset(target);

        this.setState({ showCopyDialog: true, xPos: divOffset.left, yPos: divOffset.top - 135 });
        this.props.storeToGlobal(null);
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        return false;
    }

    //
    hideCopyDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showCopyDialog: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnCopy') ? document.getElementById('btnCopy').focus() : "";
            return false;
        }, 100);

        return false;
    }
    async copyImportOptions(event) {
        event.preventDefault();

        let dialogFormData = this.state.crstarImportForm;

        var Import_Name = dialogFormData.importLabel ? dialogFormData.importLabel : "";//{dialog.Object}.getValue('Import_Name');
        var New_Import_Name = dialogFormData.newImportLabel ? dialogFormData.newImportLabel : "";//{dialog.Object}.getValue('New_Import_Name');
        
        if (Import_Name != New_Import_Name && New_Import_Name != "") {
            //{Dialog.Object}.ajaxCallback('', '', 'Copy_Import_Options', '', '');

            let url = this.props.clientgroup + "/CheckImportLabelHasExisted";
            let params = { label: New_Import_Name, accessToken: localStorage.getItem('token') };

            let res = await axios
                .post(url, params)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
            //show error message and stop the copying process
            if (res && res.data && !res.data.error && res.data.xMergeOptions && res.data.xMergeOptions.mergeOptionsId > 0) {
                this.setState({ showCopyDialog: false, showCopyError: true });
                return false;
            }

            //
            dialogFormData.newImportLabel = New_Import_Name;
            dialogFormData.newImportName = New_Import_Name;
            dialogFormData.action = "Copy";

            this.props.saveImportConfig(dialogFormData);
            this.setState({
                showCopyDialog: false,
                isSaving: true,
                savedForm: true,
                isFormDirty: false,

                reloadData: true,
                xMergeOptionsImpoItemFields: null, xMergeOptionsImpo: null, xMergeOptionsImpoLoading: false,
                xMergeOptionsHospItemFields: null, xMergeOptionsHosp: null, xMergeOptionsHospLoading: false,
                xMergeOptionsMariItemFields: null, xMergeOptionsMari: null, xMergeOptionsMariLoading: false,
                xMergeOptionsRaceItemFields: null, xMergeOptionsRace: null, xMergeOptionsRaceLoading: false,
                xMergeOptionsSexItemFields: null, xMergeOptionsSex: null, xMergeOptionsSexLoading: false,
                xMergeOptionsIcdItemFields: null, xMergeOptionsIcd: null, xMergeOptionsIcdLoading: false,
                moid: 0
            });
        }

        this.props.setChildrenOpening(false);
        this.props.storeToGlobal(null);

        return false;
    }

    handleOnFocus = (event, index, alias) => {
        //event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        if (alias) {
            switch (alias) {
                case "selectedXMergeOptionsImpoRowIndex":
                    this.setState({ selectedXMergeOptionsImpoRowIndex: index });
                    break;
                case "selectedXMergeOptionsHospRowIndex":
                    this.setState({ selectedXMergeOptionsHospRowIndex: index });
                    break;
                case "selectedXMergeOptionsMariRowIndex":
                    this.setState({ selectedXMergeOptionsMariRowIndex: index });
                    break;
                case "selectedXMergeOptionsRaceRowIndex":
                    this.setState({ selectedXMergeOptionsRaceRowIndex: index });
                    break;
                case "selectedXMergeOptionsSexRowIndex":
                    this.setState({ selectedXMergeOptionsSexRowIndex: index });
                    break;
                case "selectedXMergeOptionsIcdRowIndex":
                    this.setState({ selectedXMergeOptionsIcdRowIndex: index });
                    break;
            }
        }
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.getAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            if (name === "extnProstPath") {
                key = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            }

            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let cid = this.props.selectedPrimarySummary.cid;

            this.props.checkValidInput(name, value, table, key, -1, flag, cid);

            //#20210911 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ crstarImportForm: { ...this.state.crstarImportForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
            //#20210911 - END
        } else if (event.target.name == "followUpEnteredBy" && event.target.value.length > 20) {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [event.target.name]: "IN_VALID",
                    [event.target.name + "_bubbleMsg"]: "Follow-Up Entered By may contain no more than 20 characters",
                }
            });
        } else if (event.target.name == "importLabel" && event.target.value != "" && !this.state.reloadedImportLabel) {
            this.setState({ reloadData: true });
            var _this = this;
            var val = event.target.value;
            setTimeout(function () {
                _this.props.loadImportConfig(val);
            }, 1000);
        } else {
            this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });

            if ((event.target.name == "udef1Mapping" || event.target.name == "udef2Mapping"
                || event.target.name == "udef3Mapping" || event.target.name == "udef4Mapping"
                || event.target.name == "udef5Mapping" || event.target.name == "udef6Mapping"
                || event.target.name == "udef7Mapping" || event.target.name == "udef8Mapping"
                || event.target.name == "udef9Mapping" || event.target.name == "udef10Mapping")
                && event.target.value != "") {
                var _udIdx = event.target.getAttribute("data-index");
                var _value = event.target.value;
                // get User Defined labels if defined in the Global Dir
                var url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserDefLabel?value=" + _value;
                var _this = this;
                axios.post(url, { value: _value, accessToken: localStorage.getItem('token') })
                    //.then(res => res.json())
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        return res && res.data && res.data.result ? _this.setState({
                            crstarImportForm: {
                                ..._this.state.crstarImportForm,
                                ["userLabel" + _udIdx]: res.data.result
                            }
                        }) : null;
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;
                    });
            }
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                crstarImportForm: {
                    ...this.state.crstarImportForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                }, isFormDirty: true
            });

            if (event.target2.name == "importLabel" && event.target2.value != "") {
                this.setState({
                    reloadData: true,
                    xMergeOptionsImpoItemFields: null, xMergeOptionsImpo: null, xMergeOptionsImpoLoading: false,
                    xMergeOptionsHospItemFields: null, xMergeOptionsHosp: null, xMergeOptionsHospLoading: false,
                    xMergeOptionsMariItemFields: null, xMergeOptionsMari: null, xMergeOptionsMariLoading: false,
                    xMergeOptionsRaceItemFields: null, xMergeOptionsRace: null, xMergeOptionsRaceLoading: false,
                    xMergeOptionsSexItemFields: null, xMergeOptionsSex: null, xMergeOptionsSexLoading: false,
                    xMergeOptionsIcdItemFields: null, xMergeOptionsIcd: null, xMergeOptionsIcdLoading: false,
                    moid: 0
                });
                this.props.loadImportConfig(event.target2.value);
            }
        } else {
            this.setState({
                crstarImportForm: {
                    ...this.state.crstarImportForm,
                    [event.target.name]: event.target.value
                }, isFormDirty: true
            });

            if (event.target.name == "importLabel" && event.target.value != "") {
                // check if whether has existed label?
                var _this = this;
                var iLabel = event.target.value;
                var _importLabelOrg = this.state.initialImportForm["importLabel"];

                if (_importLabelOrg == iLabel || (this.importLabelBk && this.importLabelBk == iLabel)) {
                    return false;
                }
                this.importLabelBk = iLabel;

                //populationLabelBk
                //let url = this.props.clientgroup + "/CheckImportLabelHasExisted?label=" + iLabel;
                let url = this.props.clientgroup + "/CheckImportLabelHasExisted";
                let params = { label: iLabel, accessToken: localStorage.getItem('token') };

                axios.post(url, params)
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        if (!res.data.error && _importLabelOrg != iLabel) {
                            _this.props.loadImportConfig(iLabel);
                            setTimeout(function () {
                                _this.setState({
                                    reloadData: true,
                                    xMergeOptionsImpoItemFields: null, xMergeOptionsImpo: null,
                                    xMergeOptionsHospItemFields: null, xMergeOptionsHosp: null,
                                    xMergeOptionsMariItemFields: null, xMergeOptionsMari: null,
                                    xMergeOptionsRaceItemFields: null, xMergeOptionsRace: null,
                                    xMergeOptionsSexItemFields: null, xMergeOptionsSex: null,
                                    xMergeOptionsIcdItemFields: null, xMergeOptionsIcd: null,
                                });
                            }, 500);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;

                    });
                return false;
            }

            else if ((event.target.name == "udef1Mapping" || event.target.name == "udef2Mapping"
                || event.target.name == "udef3Mapping" || event.target.name == "udef4Mapping"
                || event.target.name == "udef5Mapping" || event.target.name == "udef6Mapping"
                || event.target.name == "udef7Mapping" || event.target.name == "udef8Mapping"
                || event.target.name == "udef9Mapping" || event.target.name == "udef10Mapping")
                && event.target.value != "") {
                // 
            }
        }
    }

    handleFormChange2 = (event) => {
        this.setState({
            previewData: {
                ...this.state.previewData,
                    [event.target.name]: event.target.value
            },
            previewed: false
        });

        if (event.target.value > this.state.previewData.previewRecordNumber || event.target.value <= 0) {
            return false;
        }

        var _this = this;
        setTimeout(function () {
            _this.previewFuncHandler(_this.state.previewData.currentRecord ? _this.state.previewData.currentRecord : "1");
        }, 1000);
        return false;
    }
    enterToLoadDataHandler = (event) => {
        if (event.target.name == "currentRecord") {
            this.previewFuncHandler(this.state.previewData.currentRecord ? this.state.previewData.currentRecord : "1");
        }
    }
    
    toggleChecked = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            crstarImportForm: { ...this.state.crstarImportForm, [name]: (value == "false" ? "true" : "false") },
            isFormDirty: true,
        });
    }

    selectionFileType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var crstarImportForm = this.state.crstarImportForm;
        crstarImportForm[name] = value;
        this.setState({
            selectedFileTypeOption: event.target.value,
            crstarImportForm: crstarImportForm,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }
    selectionImportType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var crstarImportForm = this.state.crstarImportForm;
        crstarImportForm[name] = value;
        this.setState({
            selectedImportTypeOption: event.target.value,
            crstarImportForm: crstarImportForm,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }
    selectionNewPrimaries = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var crstarImportForm = this.state.crstarImportForm;
        crstarImportForm[name] = value;
        this.setState({
            selectedNewPrimariesOption: event.target.value,
            crstarImportForm: crstarImportForm,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }
    selectionXmlType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var crstarImportForm = this.state.crstarImportForm;
        crstarImportForm[name] = value;
        this.setState({
            selectedXmlTypeOption: event.target.value,
            crstarImportForm: crstarImportForm,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }
    setIcdVersionOption = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var crstarImportForm = this.state.crstarImportForm;
        crstarImportForm[name] = value;
        this.setState({
            icdVersionOption: event.target.value,
            crstarImportForm: crstarImportForm,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }
    setUpdateSiteAllOption = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var crstarImportForm = this.state.crstarImportForm;
        crstarImportForm[name] = value;
        this.setState({
            updateSiteAllOption: event.target.value,
            crstarImportForm: crstarImportForm,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: true,
        });
    }

    loadBeakerDefaults = (e) => {
        e.preventDefault();

        //var Specimen_to_Topo = {dialog.Object}.getValue('Specimen_to_Topo_Mapping');
        var Specimen_to_Topo = this.state.crstarImportForm.specimenToTopoMapping ? this.state.crstarImportForm.specimenToTopoMapping : "";

        var Beaker_Defaults = 'Adenoids = C539 \n' +
            'Adrenal, Left = C741\n' +
            'Adrenal, Right = C741\n' +
            'Adrenal, Unspecified = C749\n' +
            'Ampulla of Vater = C241\n' +
            'Anus = C210\n' +
            'Appendix = C181\n' +
            "Bartholin's Gland Cyst = C519\n" +
            'Basophils = C751\n' +
            'Bladder = C679\n' +
            'Blood = C420\n' +
            'Bone Marrow = C421\n' +
            'Bone Marrow aspirate - vertebral process = C421\n' +
            'Bone Marrow Aspirate = C421\n' +
            'Bone Marrow Biopsy = C421\n' +
            'Bone Marrow Left - Aspirate = C421\n' +
            'Bone Marrow Left - Aspirate and Biopsy = C421\n' +
            'Bone Marrow Left - Biopsy = C421\n' +
            'Bone Marrow QC = C421\n' +
            'Bone Marrow Right - Aspirate = C421\n' +
            'Bone Marrow Right - Aspirate and Biopsy = C421\n' +
            'Bone Marrow Right - Biopsy = C421\n' +
            'Bone Marrow Smear = C421\n' +
            'Bone Marrow Touch Prep = C421\n' +
            'Breast, Left = C509\n' +
            'Breast, Left Central = C501\n' +
            'Breast, Left Lower Inner = C503\n' +
            'Breast, Left Lower Outer = C505\n' +
            'Breast, Left Upper Inner = C502\n' +
            'Breast, Left Upper Outer = C504\n' +
            'Breast, NOS = C509\n' +
            'Breast, Right = C509\n' +
            'Breast, Right Central = C501\n' +
            'Breast, Right Lower Inner = C503\n' +
            'Breast, Right Lower Outer = C505\n' +
            'Breast, Right Upper Inner = C502\n' +
            'Breast, Right Upper Outer = C504\n' +
            'Breast, Unspecified = C509\n' +
            'Bronchial = C349\n' +
            'Bronchus = C349\n' +
            'Buccal Cells = C069\n' +
            'Bursa/Synovial Cyst = C499\n' +
            'Cerebrum = C710\n' +
            'Cervical = C539\n' +
            'Cervix = C539\n' +
            'Cervix/Endocervix = C539\n' +
            'Cervix/Vagina = C539\n' +
            'Clavicle, Left = C413\n' +
            'Clavicle, Right = C413\n' +
            'Clavicle, Unspecified = C419\n' +
            'Colon = C189\n' +
            'Conjunctiva = C690\n' +
            'Cornea = C691\n' +
            'Cul-de-sac = C481\n' +
            'Diaphragm  = C493\n' +
            'Diverticulum = C173\n' +
            'Duodenal = C170\n' +
            'Duodenal Fluid = C170\n' +
            'Duodenum = C170\n' +
            'Ear = C442\n' +
            'Ear, Left = C442\n' +
            'Ear, Right = C442\n' +
            'Ear, Unspecified = C442\n' +
            'Endocardium = C380\n' +
            'Endocervical = C530\n' +
            'Endocervix = C530\n' +
            'Endometrium = C541\n' +
            'Endotracheal = C339\n' +
            'Eosinophils = C751\n' +
            'Esophageal = C159\n' +
            'Eye, Left = C699\n' +
            'Eye, Right = C699\n' +
            'Eye, Unspecified = C699\n' +
            'Eyelid, Left = C441\n' +
            'Eyelid, Right = C441\n' +
            'Eyelid, Unspecified = C441\n' +
            'Eye = C699\n' +
            'Face = C760\n' +
            'Fallopian Tube, Bilateral = C570\n' +
            'Fallopian Tube, Left = C570\n' +
            'Fallopian Tube, Right = C570\n' +
            'Foreskin = C600\n' +
            'Gallbladder = C239\n' +
            'Gastroesophageal Junction = C160\n' +
            'Genital Vaginal = C529\n' +
            'Heart = C380\n' +
            'Heart Valve (native) = C380\n' +
            'Heart Valve (non-native) = C380\n' +
            'Heart Valve = C380\n' +
            'Intestine = C260\n' +
            'Jejunum = C171\n' +
            'Joint, Ankle Unspecified = C403\n' +
            'Joint, Elbow = C400\n' +
            'Joint, Elbow Unspecified = C409\n' +
            'Joint, Finger = C401\n' +
            'Joint, Finger Unspecified = C409\n' +
            'Joint, Hip = C414\n' +
            'Joint, Hip Unspecified = C409\n' +
            'Joint, Knee = C402\n' +
            'Joint, Knee Unspecified = C409\n' +
            'Joint, Left Ankle = C413\n' +
            'Joint, Left Elbow = C400\n' +
            'Joint, Left Finger = C401\n' +
            'Joint, Left Hip = C414\n' +
            'Joint, Left Knee = C402\n' +
            'Joint, Left Shoulder = C400\n' +
            'Joint, Left Wrist = C401\n' +
            'Joint, Right Ankle = C413\n' +
            'Joint, Right Elbow = C400\n' +
            'Joint, Right Finger = C401\n' +
            'Joint, Right Hip = C414\n' +
            'Joint, Right Knee = C402\n' +
            'Joint, Right Shoulder = C400\n' +
            'Joint, Right Wrist = C401\n' +
            'Joint, Shoulder = C400\n' +
            'Joint, Shoulder Unspecified = C409\n' +
            'Joint, Unspecified = C409\n' +
            'Joint, Wrist = C401\n' +
            'Joint, Wrist Unspecified = C409\n' +
            'Kidney, Left = C649\n' +
            'Kidney, Right = C649\n' +
            'Kidney, Unspecified = C649\n' +
            'Knee, Left = C765\n' +
            'Knee, Right = C649\n' +
            'Knee, Unspecified = C649\n' +
            'Labia = C510\n' +
            'Large Intestine, Cecum = C189\n' +
            'Large Intestine, Hepatic Flexure = C183\n' +
            'Large Intestine, Left/Descending Colon = C186\n' +
            'Large Intestine, NOS = C260\n' +
            'Large Intestine, Right/Ascending Colon = C182\n' +
            'Large Intestine, Sigmoid Colon = C187\n' +
            'Large Intestine, Splenic Flexure = C187\n' +
            'Large Intestine, Transverse Colon = C184\n' +
            'Larynx = C329\n' +
            'Left Radial = C400\n' +
            'Lip = C009\n' +
            'Liver = C220\n' +
            'Lung, Left = C349\n' +
            'Lung, Left Lower Lobe = C343\n' +
            'Lung, Left Middle Lobe = C342\n' +
            'Lung, Left Upper Lobe = C341\n' +
            'Lung, Right = C349\n' +
            'Lung, Right Lower Lobe = C343\n' +
            'Lung, Right Mainstem = C340\n' +
            'Lung, Right Middle Lobe = C342\n' +
            'Lung, Right Upper Lobe = C341\n' +
            'Lung-lingula = C341\n' +
            'Lymph Node = C779\n' +
            'Lymph Node, Regional Resection = C779\n' +
            'Lymph Node, Sentinel = C779\n' +
            'Mandible = C411\n' +
            'Mandible, Left = C411\n' +
            'Mandible, Right = C411\n' +
            'Mandible, Unspecified = C411\n' +
            'Marrow = C421\n' +
            'Mediastinum = C383\n' +
            'Meninges = C709\n' +
            'Meniscus = C402\n' +
            'Mouth = C069\n' +
            'Muscle = C499\n' +
            'Myocardium = C380\n' +
            'Nail = C446\n' +
            'Nasal = C300\n' +
            'Nasal Mucosa = C300\n' +
            'Nasal Washing  = C300\n' +
            'Nasal/Sinus Polyps = C300\n' +
            'Nasopharyngeal = C119\n' +
            'Nasopharyngeal Aspirate = C119\n' +
            'Nasopharyngeal Swab = C119\n' +
            'Nasopharyngeal Wash = C119\n' +
            'Nasopharynx/Oropharynx = C109\n' +
            'Neck = C760\n' +
            'Neuroma = C724\n' +
            'Nipple Discharge = C500\n' +
            'Nose (nasal passage) = C300\n' +
            'Nose = C760\n' +
            'Odontogenic Tumor = C410\n' +
            'Odontogenic/Dental Cyst = C410\n' +
            'Omentum = C481\n' +
            'Oral Mucosa/Gingiva = C069\n' +
            'Oropharynx = C109\n' +
            'Ovary, Left = C569\n' +
            'Ovary, Right = C569\n' +
            'Ovary, Unspecified = C569\n' +
            'Pancreas = C259\n' +
            'Parathyroid = C750\n' +
            'Parotid = C079\n' +
            'Penis = C609\n' +
            'Pericardium = C380\n' +
            'Perineum = C763\n' +
            'Perirectal = C763\n' +
            'Peritoneal = C482\n' +
            'Peritoneum = C482\n' +
            'Pituitary = C751\n' +
            'Placenta = C589\n' +
            'Placenta, Fetal = C589\n' +
            'Placenta, Maternal = C589\n' +
            'Pleura = C384\n' +
            'Pleural Cavity = C384\n' +
            'Pleural Fluid (thoracentesis fld) = C384\n' +
            'Pleural Fluid = C384\n' +
            'Pleural, Left = C384\n' +
            'Pleural, Right = C384\n' +
            'Pleural-Based Mass = C384\n' +
            'Prostate = C619\n' +
            'Pulmonary = C349\n' +
            'Pylorus = C164\n' +
            'Rectum = C209\n' +
            'Renal Cyst = C649\n' +
            'Renal Pelvis = C659\n' +
            'Retroperitoneum = C480\n' +
            'Salivary Gland = C089\n' +
            'Scalp = C444\n' +
            'Scrotum = C632\n' +
            'Seminal Vesicle = C637\n' +
            'Shin, Left = C765\n' +
            'Shin, Right = C765\n' +
            'Shin, Unspecified = C765\n' +
            'Shoulder, Left = C764\n' +
            'Shoulder, Right = C764\n' +
            'Shoulder, Unspecified = C764\n' +
            'Sinus, Ethmoid = C311\n' +
            'Sinus, Frontal = C312\n' +
            'Sinus, Maxillary = C310\n' +
            'Sinus, Sphenoid = C313\n' +
            'Sinus, Unspecified = C319\n' +
            'Sinus, Washing/Contents = C319\n' +
            'Skeletal Muscle = C499\n' +
            'Skin = C449\n' +
            'Skin, Cyst/Tag/Debridement = C449\n' +
            'Skin, Other = C449\n' +
            'Skin, Plastic Repair = C449\n' +
            'Small Bowel = C179\n' +
            'Soft Tissue, Debridement = C499\n' +
            'Soft Tissue, Lipoma = C499\n' +
            'Soft Tissue, Other = C499\n' +
            'Spinal Cord = C720\n' +
            'Spleen = C422\n' +
            'Stomach = C164\n' +
            'Submandibular = C080\n' +
            'Synovium = C499\n' +
            'Testis, Left = C629\n' +
            'Testis, Right = C629\n' +
            'Testis, Unspecified = C629\n' +
            'Throat = C140\n' +
            'Thymus = C379\n' +
            'Thyroid = C739\n' +
            'Tibia, Left = C402\n' +
            'Tibia, Right = C402\n' +
            'Tissue = C499\n' +
            'Tissue Gall Bladder = C239\n' +
            'Tissue Large Intestine = C189\n' +
            'Tissue Lung = C349\n' +
            'Tissue Placenta = C589\n' +
            'Tissue Small Intestine = C179\n' +
            'Tongue = C029\n' +
            'Tonsil = C099\n' +
            'Tooth = C039\n' +
            'Trachea = C339\n' +
            'Tracheal = C339\n' +
            'Ureter, Left = C669\n' +
            'Ureter, Right = C669\n' +
            'Ureter, Unspecified = C669\n' +
            'Ureteral = C669\n' +
            'Urethra = C680\n' +
            'Uterus = C559\n' +
            'Uvula = C052\n' +
            'Vagina = C529\n' +
            'Vagina/Cervix/Endocervix = C539\n' +
            'Vagina/Endocervix = C530\n' +
            'Vaginal = C529\n' +
            'Vas Deferens, Left = C631\n' +
            'Vas Deferens, Right = C631\n' +
            'Vein = C499\n' +
            'Vulva = C519\n' +
            'Wrist, Left = C764\n' +
            'Wrist, Right = C764\n' +
            'Wrist, Unspecified = C764\n \n' +
            'Abdomen = C809\n' +
            'Abdominal = C809\n' +
            'Abscess = C809\n' +
            'Acute Dysuria = C809\n' +
            'Amniotic Fluid = C809\n' +
            'Amniotic Sac = C809\n' +
            'Anal Pap = C809\n' +
            'Aneurysm = C809\n' +
            'Ankle, Left = C809\n' +
            'Ankle, Right = C809\n' +
            'Ankle, Unspecified = C809\n' +
            'Antrum = C809\n' +
            'Arm, Left = C809\n' +
            'Arm, Right = C809\n' +
            'Arm, Unspecified = C809\n' +
            'Arterial Stick = C809\n' +
            'Artery = C809\n' +
            'Asap = C809\n' +
            'Ascites = C809\n' +
            'Ascitic = C809\n' +
            'Aspirate = C809\n' +
            'Axilla, Left = C809\n' +
            'Axilla, Right = C809\n' +
            'Axilla, Unspecified = C809\n' +
            'Axillary Mass = C809\n' +
            'Back = C809\n' +
            'BAL = C809\n' +
            'Bile = C809\n' +
            'Bile Duct = C809\n' +
            'Bile Fluid = C809\n' +
            'Biopsy = C809\n' +
            'Blister = C809\n' +
            'Blood Arterial = C809\n' +
            'Blood Bag = C809\n' +
            'Blood Capillary = C809\n' +
            'Blood Card = C809\n' +
            'Blood for Acquired Disease = C809\n' +
            'Blood Product Unit = C809\n' +
            'Blood Venous = C809\n' +
            'Body Fluid, Unsp = C809\n' +
            'Bone = C809\n' +
            'Bone Allograft = C809\n' +
            'Bone Aspirate & Core = C809\n' +
            'Bone Core = C809\n' +
            'Brain = C809\n' +
            'Breast Milk = C809\n' +
            'Breast-Ductal Lavage = C809\n' +
            'Breath = C809\n' +
            'Bronchial Brush = C809\n' +
            'Broviac = C809\n' +
            'Buccal Smear = C809\n' +
            'Burn = C809\n' +
            'Buttock, Left = C809\n' +
            'Buttock, Right = C809\n' +
            'Buttock, Unspecified = C809\n' +
            'Calf, Left = C809\n' +
            'Calf, Right = C809\n' +
            'Calf, Unspecified = C809\n' +
            'Cannula = C809\n' +
            'Cardiac Muscle = C809\n' +
            'Carpal Tunnel Tissue = C809\n' +
            'Cartilage = C809\n' +
            'Catheter Tip = C809\n' +
            'Catheterized = C809\n' +
            'Catheterized-In and Out Catheter = C809\n' +
            'Catheterized-Indwelling Catheter = C809\n' +
            'Central Venous Line = C809\n' +
            'Central Venous Line - Blue = C809\n' +
            'Central Venous Line - Purple = C809\n' +
            'Central Venous Line - Red = C809\n' +
            'Central Venous Line - White = C809\n' +
            'Cerebral Spinal Fluid = C809\n' +
            'Cerebrospinal Fluid = C809\n' +
            'Cervical Mucus = C809\n' +
            'Cheek, Left = C809\n' +
            'Cheek, Right = C809\n' +
            'Cheek, Unspecified = C809\n' +
            'Chest = C809\n' +
            'Chest Tube Drainage = C809\n' +
            'Cholesteatoma = C809\n' +
            'Chorionic Villus = C809\n' +
            'Clean Catch = C809\n' +
            'CNS Cyst Fluid = C809\n' +
            'Colostrum = C809\n' +
            'Cord = C809\n' +
            'Cord Blood = C809\n' +
            'Cord Blood Arterial = C809\n' +
            'CSF = C809\n' +
            'Curettage = C809\n' +
            'CVAD Implanted = C809\n' +
            'CVAD Temp = C809\n' +
            'CVAD Tunneled = C809\n' +
            'CVP = C809\n' +
            'Cylinder, Left = C809\n' +
            'Cylinder, Right = C809\n' +
            'Cyst = C809\n' +
            'Cystic Hygroma Fluid = C809\n' +
            'Cytospin Preparation = C809\n' +
            'Deep Pharyngeal = C809\n' +
            'Dendritic Cells = C809\n' +
            'Device Hardware = C809\n' +
            'Dialysate = C809\n' +
            'Dialysis Fluid = C809\n' +
            'Digit = C809\n' +
            'Direct Bladder Aspirate = C809\n' +
            'DNA = C809\n' +
            'Donor, Left Lung = C809\n' +
            'Donor, Right Lung = C809\n' +
            'Dose Med or Substance = C809\n' +
            'Drain = C809\n' +
            'Driveline Site = C809\n' +
            'Dupuytrens = C809\n' +
            "Dupuytren's Contracture Tissue = C809\n" +
            'Ear Wax (cerumen) = C809\n' +
            'ECMO Circ ABG = C809\n' +
            'Elbow, Left = C809\n' +
            'Elbow, Right = C809\n' +
            'Elbow, Unspecified = C809\n' +
            'Electrode = C809\n' +
            'Erythrocytes = C809\n' +
            'Esophagus = C809\n' +
            'EVD Shunt = C809\n' +
            'Exhaled Gas (breath) = C809\n' +
            'Femoral Head = C809\n' +
            'Fetal Blood = C809\n' +
            'Fetal Tissue = C809\n' +
            'Fetus = C809\n' +
            'Fibroblasts = C809\n' +
            'Filter = C809\n' +
            'Fine Needle Aspiration = C809\n' +
            'Finger, Left = C809\n' +
            'Finger, Right = C809\n' +
            'Finger, Unspecified = C809\n' +
            'Fistula = C809\n' +
            'FNA = C809\n' +
            'Foot, Left = C809\n' +
            'Foot, Right = C809\n' +
            'Foot, Unspecified = C809\n' +
            'Forearm = C809\n' +
            'Forearm, Left = C809\n' +
            'Forearm, Right = C809\n' +
            'Forearm, Unspecified = C809\n' +
            'Forehead = C809\n' +
            'Foreign Body = C809\n' +
            'Ganglion Cyst = C809\n' +
            'Gas = C809\n' +
            'Gastric = C809\n' +
            'Gastric Fluid/Contents = C809\n' +
            'Genital = C809\n' +
            'Genital Cervix = C809\n' +
            'Groin = C809\n' +
            'Guthrie Card = C809\n' +
            'Gutter = C809\n' +
            'Gyn, Site Not Specified = C809\n' +
            'Hair = C809\n' +
            'Hand, Left = C809\n' +
            'Hand, Right = C809\n' +
            'Hand, Unspecified = C809\n' +
            'Head = C809\n' +
            'Hematoma = C809\n' +
            'Hemodialysis Catheter = C809\n' +
            'Hemorrhoids = C809\n' +
            'Hernia Sac, Left Inguinal = C809\n' +
            'Hernia Sac, Right Inguinal = C809\n' +
            'Hernia sac, Umbilical = C809\n' +
            'Hickman = C809\n' +
            'Hip, Left = C809\n' +
            'Hip, Prosthetic Left = C809\n' +
            'Hip, Prosthetic Right = C809\n' +
            'Hip, Prosthetic Unspecified = C809\n' +
            'Hip, Right = C809\n' +
            'Hip, Unspecified = C809\n' +
            'Hydatid of Morgagni = C809\n' +
            'Hydrocele Sac = C809\n' +
            'Ileal Conduit = C809\n' +
            'Ileal Conduit Urine = C809\n' +
            'Iliac Crest, Left = C809\n' +
            'Iliac Crest, Right = C809\n' +
            'Illeal Conduit = C809\n' +
            'Inhaled Gas = C809\n' +
            'Intervertebral Disc = C809\n' +
            'Intubation Tube = C809\n' +
            'Isolate = C809\n' +
            'Joint, Loose Body = C809\n' +
            'Joint, Prosthetic Unspecified = C809\n' +
            'Knee, Prosthetic Left = C809\n' +
            'Knee, Prosthetic Right = C809\n' +
            'Knee, Prosthetic Unspecified = C809\n' +
            'Lamella = C809\n' +
            'Leg, Left = C809\n' +
            'Leg, Right = C809\n' +
            'Leg, Unspecified = C809\n' +
            'Lesion = C809\n' +
            'Leukocytes = C809\n' +
            'Line Arterial = C809\n' +
            'Line Venous = C809\n' +
            'Line, Arterial = C809\n' +
            'Line = C809\n' +
            'Lingula, Left = C809\n' +
            'Liquid NOS = C809\n' +
            'Lochia = C809\n' +
            'Loop = C809\n' +
            'Lumbar Puncture = C809\n' +
            'Lung, Combined = C809\n' +
            'Lymphocytes = C809\n' +
            'Macrophages = C809\n' +
            'Meconium = C809\n' +
            'Medical Device = C809\n' +
            'Medical Specimen = C809\n' +
            'Menstrual Blood = C809\n' +
            'Mesh = C809\n' +
            'Midline Catheter = C809\n' +
            'Milk = C809\n' +
            'Miscellaneous = C809\n' +
            'Mucocele, Salivary = C809\n' +
            'Myomectomy W/O Uterus = C809\n' +
            'Nape of the Neck = C809\n' +
            'Native, Left Lung = C809\n' +
            'Native, Right Lung = C809\n' +
            'Nephrostomy = C809\n' +
            'Nephrostomy, Left = C809\n' +
            'Nephrostomy, Right = C809\n' +
            'Nerve = C809\n' +
            'Newborn Cord Blood = C809\n' +
            'None = C809\n' +
            'Ommaya Reservoir = C809\n' +
            'One Peripheral Draw = C809\n' +
            'Oral/Pharynx = C809\n' +
            'Ostomy = C809\n' +
            'Ostomy Pouch = C809\n' +
            'Other = C809\n' +
            'Ovarian Cyst = C809\n' +
            'PA Catheter = C809\n' +
            'Pacemaker = C809\n' +
            'Pancreatic Fluid = C809\n' +
            'Panniculus = C809\n' +
            'Paracentesis = C809\n' +
            'Paraffin Embedded = C809\n' +
            'Patient = C809\n' +
            'Pelvic Cavity = C809\n' +
            'Penile = C809\n' +
            'Per Rectum = C809\n' +
            'Per Stoma = C809\n' +
            'Pericardial Fluid = C809\n' +
            'Peripheral Blood = C809\n' +
            'Peritoneal Fluid/Ascites = C809\n' +
            'Periumbilical = C809\n' +
            'PICC Line = C809\n' +
            'Pilondial Cyst/Sinus = C809\n' +
            'Pilonidal = C809\n' +
            'Plasma = C809\n' +
            'Plasma Bag = C809\n' +
            'Platelet Poor Plasma = C809\n' +
            'Platelet Product = C809\n' +
            'Platelet Rich Plasma = C809\n' +
            'Polymorphonuclear Neutrophils = C809\n' +
            'Post Implant Valve = C809\n' +
            'Post Prostatic Massage = C809\n' +
            'Post-Soak Valve = C809\n' +
            'Pre-Soak Valve = C809\n' +
            'Products of Conception = C809\n' +
            'PUBS = C809\n' +
            'Pump = C809\n' +
            'Pus = C809\n' +
            'Rectal = C809\n' +
            'Renal Calculi(Stone) = C809\n' +
            'Reservoir = C809\n' +
            'Right Radial = C809\n' +
            'RNA = C809\n' +
            'Rout = C809\n' +
            'Route of Medicine = C809\n' +
            'Rush = C809\n' +
            'Saliva = C809\n' +
            'Segment = C809\n' +
            'Semen = C809\n' +
            'Seminal Fluid = C809\n' +
            'Serum = C809\n' +
            'Shoulder, Prosthetic Left = C809\n' +
            'Shoulder, Prosthetic Right = C809\n' +
            'Shoulder, Prosthetic Unspecified = C809\n' +
            'Smear = C809\n' +
            'Solid Tumor = C809\n' +
            'Spermatocele = C809\n' +
            'Spermatozoa = C809\n' +
            'Sputum = C809\n' +
            'Sputum - Coughed = C809\n' +
            'Sputum - Tracheal Aspirate = C809\n' +
            'Sputum Expectorated = C809\n' +
            'Sputum Induced = C809\n' +
            'Sputum Post-Bronchoscopy = C809\n' +
            'Stoma = C809\n' +
            'Stone (Calculus) = C809\n' +
            'Stool = C809\n' +
            'Suprapubic = C809\n' +
            'Survey Material = C809\n' +
            'SWAB = C809\n' +
            'Swan Ganz = C809\n' +
            'Sweat = C809\n' +
            'Sympathetic Ganglion = C809\n' +
            'Synovial Fluid (Joint Fluid) = C809\n' +
            'Tears = C809\n' +
            'Temple, Left = C809\n' +
            'Temple, Right = C809\n' +
            'Temple, Unspecified = C809\n' +
            'Tenckhoff Catheter = C809\n' +
            'Tendon = C809\n' +
            'Tendon/Tendon Sheath = C809\n' +
            'Testicular Appendage = C809\n' +
            'Thigh, Left = C809\n' +
            'Thigh, Right = C809\n' +
            'Thigh, Unspecified = C809\n' +
            'Thoracentesis = C809\n' +
            'Thrombocyte (Platelet) = C809\n' +
            'Thrombus/Embolus = C809\n' +
            'Thyroglossal Duct/Branchial Cleft Cyst = C809\n' +
            'Tissue Ulcer = C809\n' +
            'Toe, Left = C809\n' +
            'Toe, Right = C809\n' +
            'Toe, Unspecified = C809\n' +
            'Touch Prep = C809\n' +
            'TPN = C809\n' +
            'Tracheal Aspirate = C809\n' +
            'Trans-Abdominal Collection = C809\n' +
            'Trans-Vaginal Collection = C809\n' +
            'Triple Lumen = C809\n' +
            'Tube NOS = C809\n' +
            'Tzanck Prep = C809\n' +
            'Ulcer = C809\n' +
            'Uldall = C809\n' +
            'Umbilical Artery = C809\n' +
            'Umbilical Blood = C809\n' +
            'Umbilical Cord = C809\n' +
            'Umbilical Vein = C809\n' +
            'Unacceptable Specimen = C809\n' +
            'Unidentifiable Tissue = C809\n' +
            'Unknown = C809\n' +
            'Unknown Medicine = C809\n' +
            'Unknown Substance = C809\n' +
            'Unspecified = C809\n' +
            'Upper Arm, Left = C809\n' +
            'Upper Arm, Right = C809\n' +
            'Upper Arm, Unspecified = C809\n' +
            'Urine = C809\n' +
            'Urine (Male) = C809\n' +
            'Urine Catheter = C809\n' +
            'Urine Catheterized = C809\n' +
            'Urine Clean Catch = C809\n' +
            'Urine Sediment = C809\n' +
            'Urine, Bladder Wash = C809\n' +
            'Uterus W/Bilateral Ovaries and Fallopian Tubes = C809\n' +
            'Vaginal Secretions = C809\n' +
            'Vaginal/Rectal = C809\n' +
            'Vagus Nerve = C809\n' +
            'Validation Study = C809\n' +
            'Varicocele = C809\n' +
            'Varicosities = C809\n' +
            'Vas Deferens = C809\n' +
            'Venous = C809\n' +
            'Villi = C809\n' +
            'Villi & Fetal Tissue = C809\n' +
            'Vitreous = C809\n' +
            'Vitreous Fluid = C809\n' +
            'Voided = C809\n' +
            'Vomitus = C809\n' +
            'VP Shunt = C809\n' +
            'Wang Needle = C809\n' +
            'Wash = C809\n' +
            'Water = C809\n' +
            'Whole Blood = C809\n' +
            'Whole Body = C809\n' +
            'Wick = C809\n' +
            'Wound = C809\n' +
            'Wound Abscess = C809\n' +
            'Wound Drainage = C809\n' +
            'Wound Exudate = C809';

        if (Specimen_to_Topo != '') {
            // Confirm: Are you sure you want to overwrite your current mapped list with the Beaker default list?
            this.setState({
                showBeakerDefaultsConfirmation: true
            });
        } else {
            var crstarImportForm = this.state.crstarImportForm;
            crstarImportForm['specimenToTopoMapping'] = Beaker_Defaults;

            this.setState({
                crstarImportForm: crstarImportForm/*{
                    ...this.state.crstarImportForm,
                    specimenToTopoMapping: Beaker_Defaults
                }*/,
                isFormDirty: true
            });
        }

        return false;
    }

    hideBeakerDefaultsConfirmationMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        if (event.target.value === "yes") {
            this.setState({
                crstarImportForm: {
                    ...this.state.crstarImportForm,
                    specimenToTopoMapping: ""
                },
                showBeakerDefaultsConfirmation: false
            });

            setTimeout(function () {
                document.getElementById("btnLoadBeakerDefaults").click();
            }, 0);
        } else {
            this.setState({ showBeakerDefaultsConfirmation: false });
        }
        return false;
    }

    render() {
        let showUploadSampleFile;
        {
            showUploadSampleFile = this.state.showUploadSampleFile ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Select File</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.showUploadFile(e, false) }}></span>
                                    </a>
                                </div>
                            </div>

                            <div className="emptyLine"></div>

                            <form id="uploadTemplateSampleFile" encType="multipart/form-data" method="post">
                                <div className="container">
                                    <Input type="file"
                                        field="fileName" changed={this.onFileChange}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        labelText="" labelWidth="0px" width="150px" />
                                </div>
                                <div className="container" id="waitingForUploadFile" style={{ display: this.state.selectedFile ? "" : "none" }}>
                                    <span>Uploading Files...</span>
                                </div>
                                <div className="emptyLine"></div>

                                <div className="container center">
                                    <button onClick={(e) => { this.showUploadFile(e, false) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                                <div className="emptyLine"></div>
                            </form>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showBeakerDefaultsConfirmation;
        {
            showBeakerDefaultsConfirmation = this.state.showBeakerDefaultsConfirmation ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideBeakerDefaultsConfirmationMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to overwrite your current mapped list with the Beaker default list?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideBeakerDefaultsConfirmationMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideBeakerDefaultsConfirmationMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let copyDialog = (
            this.state.showCopyDialog ?
                <div className="CRStar_Window" id="copyImportConfigForm">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" style={{ left: this.state.xPos + "px", top: this.state.yPos + "px" }}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header hidden">
                                <div className="k-window-title k-dialog-title hidden"></div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close hidden">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideCopyDialog(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="_row_">
                                <div className="col-sm-12">
                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="New Import Name" field="newImportLabel" lineStyle="oneLine"
                                        value={this.state.crstarImportForm.newImportLabel} _labelWidth="90px" width="190px"
                                        inValid={this.state.dAllItemFields["newImportLabel"]}
                                        lostfocused={this.handleLostFocus} autoFocus
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["importLabel"] : ""}
                                    />
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <button onClick={(e) => { this.copyImportOptions(e) }} value="yes">OK</button>
                                <button onClick={(e) => { this.hideCopyDialog(e) }} value="no">Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        let copyErrorMsg;
        {
            copyErrorMsg = this.state.showCopyError ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Validation Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideCopyErrorMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Import name already exists.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.hideCopyErrorMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        //showingPreviewData
        let showingPreviewData = (
            this.state.showingPreviewData ?
                <div className="CRStar_Window" id="previewImportDataForm">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Preview Data</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hidePreviewData(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            {/* This html content should come from the SQL function: Import_Record_Preview */}
                            <div className="_container" id="Import_Record_Preview_Html">
                                <iframe id="previewHtmlIframe" />
                            </div>

                            <div className="emptyLine"></div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-3" style={{display: "flex"}}>
                                        <button type="button" id="HYBRIDSYSA_CRSTARIMPO_BUTTON_FIRST_RECORD"
                                            onClick={this.moveFirstItem} style={{ width: "26px" }}
                                            className="ScreenButton" title="Go to the First Record"
                                            disabled={this.state.previewData.currentRecord == "1" ? true : false}>
                                            <img id="HYBRIDSYSA_CRSTARIMPO_BUTTON_FIRST_RECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.move.first.png.a5image"
                                                disabled={this.state.previewData.currentRecord == "1" ? true : false}></img>
                                        </button>
                                        <button type="button" id="HYBRIDSYSA_CRSTARIMPO_BUTTON_PREVIOUS_RECORD"
                                            onClick={this.movePrevItem} style={{ width: "26px" }}
                                            className="ScreenButton" title="Previous Record"
                                            disabled={this.state.previewData.currentRecord == "1" ? true : false}>
                                            <img id="HYBRIDSYSA_CRSTARIMPO_BUTTON_PREVIOUS_RECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.move.prev.png.a5image"
                                                disabled={this.state.previewData.currentRecord == "1" ? true : false}></img>
                                        </button>

                                        <Input type="input" autoFocus
                                            labelText="" field="currentRecord" keyId="currentRecord0"
                                            value={this.state.previewData.currentRecord ? this.state.previewData.currentRecord : "1"}
                                            labelWidth="0px" width="50px"
                                            changed={this.handleFormChange2}
                                        />

                                        <button type="button" id="HYBRIDSYSA_CRSTARIMPO_BUTTON_NEXT_RECORD"
                                            onClick={this.moveNextItem} style={{ width: "26px" }}
                                            className="ScreenButton ScreenButtonHover" title="Next Record"
                                            disabled={this.state.previewData.currentRecord == this.state.previewData.previewRecordNumber ? true : false}>
                                            <img id="HYBRIDSYSA_CRSTARIMPO_BUTTON_NEXT_RECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.move.next.png.a5image"></img>
                                        </button>
                                        <button type="button" id="HYBRIDSYSA_CRSTARIMPO_BUTTON_LAST_RECORD"
                                            onClick={this.moveLastItem} style={{ width: "26px" }}
                                            className="ScreenButton" title="Go to the Last Record"
                                            disabled={this.state.previewData.currentRecord == this.state.previewData.previewRecordNumber ? true : false}>
                                            <img id="HYBRIDSYSA_CRSTARIMPO_BUTTON_LAST_RECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.move.last.png.a5image"></img>
                                        </button>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <button id="btnExitPreviewData_CRStarImpConf" className="ErsGrBlueButton" style={{ width: "50px" }} onClick={this.hidePreviewData}>Exit</button>
                                    </div>

                                    <div className="col-sm-5">
                                        <Input type="label"
                                            labelText="Total Records in this File" field="fileRecCount"
                                            value={this.state.previewData.previewRecordNumber ? this.state.previewData.previewRecordNumber : this.state.crstarImportForm.previewRecord}
                                            labelWidth="45px" width="155px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        const crstarImportOptions = this.state.crstarImportForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    <form onSubmit={this.saveImportConfig} id="crstarImportForm" className="DialogForm TabParentPage">
                        <div className="hidden">
                            <Input type="hidden" field="mergeOptionsId" value={this.state.crstarImportForm.mergeOptionsId} />
                        </div>
                        <div className="emptyLine"></div>
                        <div className="row">
                            <div className="col-sm-6" style={{ /*marginLeft: "-25px"*/ }}>
                                <Input type="codeSelect" focused={this.handleOnFocus}
                                    changed={this.handleFormChange}
                                    labelText="Import Name"
                                    field="importLabel" keyId="importLabel0" rowIndex="0"
                                    value={this.state.crstarImportForm.importLabel}
                                    dataSource="x_Merge_Options" titleDialog="" shownType="dropdown" displayingCodeOnly
                                    labelWidth="125px" width="205px" lineStyle="oneLine"
                                    inValid={this.state.dAllItemFields["importLabel"]}
                                    lostfocused={this.handleLostFocus} autoFocus
                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["importLabel"] : ""}
                                />
                            </div>
                            <div className="col-sm-6">
                                {/*
                                    Beacon
                                    Beaker
                                    E-Path
                                    FL Follow-Up
                                    Meditech
                                    NAACCR XML
                                 */}
                                <div className="row">
                                    <div className="col-sm-7">
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange}
                                            labelText="Import Source"
                                            field="importSource" itemNbr=""
                                            value={this.state.crstarImportForm.importSource}
                                            dataSource="x_Merge_Options_ImportSource" titleDialog=""
                                            labelWidth="110px" width="170px" lineStyle="oneLine"
                                            inValid={this.state.dAllItemFields["importSource"]}
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["importSource"] : ""}
                                            options={["", "Beacon", "Beaker", "E-Path", "FL Follow-Up", "Meditech", "NAACCR XML"]}
                                            keyId="importSource0" focusingField={this.state.focusingField}
                                            shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                            hideFilter={true} limitByWidth={true}
                                        />
                                    </div>
                                    <div className="col-sm-5">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Requires Custom Program"
                                            title="Check only if this Import requires a custom program to run"
                                            field="requiresCustomProgram"
                                            labelWidth="150px" width="30px"
                                            value={this.state.crstarImportForm.requiresCustomProgram && this.state.crstarImportForm.requiresCustomProgram == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.requiresCustomProgram && this.state.crstarImportForm.requiresCustomProgram == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row _secondLines">
                            <div className="col-sm-7">
                                <div id="multiTabShowByVertical" className="multiTabShowByVertical_ImportOptionScreen">
                                    <Nav tabs tabIndex={0} autoFocus name="navTabOnInnerRetired" vertical>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t1" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t1") }} href="#" id="linkTo_ImportFileColumns_Tab">Import File Columns</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t2" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t2") }} href="#" id="linkTo_HospitalCodes_Tab">Hospital Codes</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t3" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t3") }} href="#" id="linkTo_MaritalStatusCodes_Tab">Marital Status Codes</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t4" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t4") }} href="#" id="linkTo_RaceCodes_Tab">Race Codes</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t5" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t5") }} href="#" id="linkTo_SexCodes_Tab">Sex Codes</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t6" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t6") }} href="#" id="linkTo_IcdCodesAndRanges_Tab">ICD Codes & Ranges</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t7" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t7") }} href="#" id="linkTo_SpecimenTypes_Tab">Specimen Types</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t8" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t8") }} href="#" id="linkTo_DateFormats_Tab">Date Formats</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t9" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t9") }} href="#" id="linkTo_Defaults_Tab">Defaults</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t10" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t10") }} href="#" id="linkTo_AdditionalMapping_Tab">Additional Mapping</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t11" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t11") }} href="#" id="linkTo_AdditionalOptions_Tab">Additional Options</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t12" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t12") }} href="#" id="linkTo_UserDefinedMapping_Tab">User-Defined Mapping</NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTabInner === "t13" })}
                                            onClick={(e) => { this.toggleTabInner(e, "t13") }} href="#" id="linkTo_ReportOptions_Tab">Report Options</NavLink>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTabInner}>
                                        <TabPane tabId="t1">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div id="fileTypeLabel" style={{ verticalAlign: "middle", border: "none" }}>File Type:</div>
                                                    <div className="fileTypeRadio">
                                                        <label style={{ width: "196px", height: "0px", cursor: "pointer" }}>
                                                            <input type="radio" onClick={this.selectionFileType} id="fileTypeOpt_Delimited"
                                                                checked={this.state.selectedFileTypeOption === "Delimited"}
                                                                name="fileType" field="fileType" value="Delimited"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                            Delimited</label>
                                                        <label style={{ width: "232px", height: "0px", cursor: "pointer" }}>
                                                            <input type="radio" onClick={this.selectionFileType}
                                                                checked={this.state.selectedFileTypeOption === "Fixed-Width"}
                                                                name="fileType" field="fileType" value="Fixed-Width"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                            Fixed-Width</label>
                                                        <label style={{ width: "121px", height: "0px", cursor: "pointer" }}>
                                                            <input type="radio" onClick={this.selectionFileType}
                                                                checked={this.state.selectedFileTypeOption === "XML"}
                                                                name="fileType" field="fileType" value="XML"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                            XML</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={this.state.selectedFileTypeOption === "Delimited" ? "" : "hidden"}>
                                                        <Input type="input"//show/hide: File_Type = 'Delimited'
                                                            focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Delimiter" field="delimiter"
                                                            value={this.state.crstarImportForm.delimiter}
                                                            labelWidth="100%" width="80px"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["delimiter"] : ""}
                                                        />
                                                    </div>

                                                    <div className={this.state.selectedFileTypeOption === "XML" ? "" : "hidden"}>
                                                        <Input type="input"//show/hide: File_Type = 'XML'
                                                            focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Nodes to Patient" field="xmlNodesToPatient"
                                                            value={this.state.crstarImportForm.xmlNodesToPatient}
                                                            placeholder="Example:/NaaccrData"
                                                            labelWidth="120px" width="120px"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["xmlNodesToPatient"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"col-md-3" + (this.state.selectedFileTypeOption === "XML" ? "" : " hiddenByInvisible")}>
                                                    <Input type="input"//show/hide: File_Type = 'XML'
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Patient Node New Root" field="xmlPatientNodeNewRoot"
                                                        value={this.state.crstarImportForm.xmlPatientNodeNewRoot}
                                                        placeholder={this.state.selectedXmlTypeOption === "Attribute" ? "ns:NaaccrData/ns:Patient" : "/NaaccrData/Patient"}
                                                        labelWidth="155px" width="155px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["xmlPatientNodeNewRoot"] : ""}
                                                    />
                                                </div>
                                            </div>

                                            {/* show/hide: File_Type = 'XML' */}
                                            <div className={"row" + (this.state.selectedFileTypeOption === "XML" ? "" : " hiddenByInvisible")}>
                                                <div className="col-md-5">
                                                    <div id="xmlTypeLabel" style={{ verticalAlign: "middle", border: "none" }}>XML Type:</div>
                                                    <div className="xmlTypeRadio">
                                                        <label style={{ width: "118px", height: "0px", cursor: "pointer" }}>
                                                            <input type="radio" onClick={this.selectionXmlType}
                                                                checked={this.state.selectedXmlTypeOption === "Attribute"}
                                                                name="xmlType" field="xmlType" value="Attribute"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                            Attribute</label>
                                                        <label style={{ width: "220px", height: "0px", cursor: "pointer" }}>
                                                            <input type="radio" onClick={this.selectionXmlType}
                                                                checked={this.state.selectedXmlTypeOption === "Element"}
                                                                name="xmlType" field="xmlType" value="Element"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                            Element</label>
                                                    </div>
                                                </div>
                                                <div className={"col-md-3" + (this.state.selectedXmlTypeOption === "Attribute" ? "" : " hiddenByInvisible")}>
                                                    <Input type="input"//show/hide: [[Fade,Fast|Fade,Fast]]File_Type = 'XML' And XML_Type = 'Attribute'
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Attribute ID" field="xmlAttributeId"
                                                        value={this.state.crstarImportForm.xmlAttributeId}
                                                        placeholder="Example:@naaccrId"
                                                        labelWidth="100%" width="120px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["xmlAttributeId"] : ""}
                                                    />
                                                </div>
                                                <div className={"col-md-3" + (this.state.selectedXmlTypeOption === "Attribute" ? "" : " hiddenByInvisible")}>
                                                    <Input type="input"//[[Fade,Fast|Fade,Fast]]File_Type = 'XML' And XML_Type = 'Attribute'
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Namespace" field="xmlNamespace"
                                                        value={this.state.crstarImportForm.xmlNamespace}
                                                        placeholder="Example:http://naaccr.org/naaccrxml"//Alpha is Watermark text attribute
                                                        labelWidth="100%" width="155px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["xmlNamespace"] : ""}
                                                    />
                                                </div>
                                            </div>

                                            <br/>
                                            <div className="importFileColumnsOnTab1">
                                                <div className="row">
                                                    <div className="col-sm-12 xMergeOptionsImpoListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "18px" }}>&nbsp;</th>
                                                                    <th style={{ width: "172px" }}>
                                                                        <Input type="gridHeader" labelWidth="170px"
                                                                            labelText="Import Column" field="importColumn"
                                                                        /></th>
                                                                    <th style={{ width: "50px" }}>
                                                                        <Input type="gridHeader" labelWidth="50px"
                                                                            labelText="Position" field="position"
                                                                        /></th>
                                                                    <th style={{ width: "45px", display: this.state.selectedFileTypeOption === "Fixed-Width" ? "" : "none" }}>
                                                                        <Input type="gridHeader" labelWidth="45px"
                                                                            labelText="Length" field="length"
                                                                        /></th>
                                                                    <th style={{ width: "37px" }}>
                                                                        <Input type="gridHeader" labelWidth="37px"
                                                                            labelText="Rpt Col" field="reportDisplayOrder"
                                                                        /></th>
                                                                    <th style={{ width: "95px", display: this.state.selectedFileTypeOption === "XML" ? "" : "none" }}>
                                                                        <Input type="gridHeader" labelWidth="93px"
                                                                            labelText="XML Path" field="xmlPath"
                                                                        /></th>
                                                                    <th style={{ width: "80px", display: this.state.selectedFileTypeOption === "XML" ? "" : "none" }}>
                                                                        <Input type="gridHeader" labelWidth="80px"
                                                                            labelText="XML Node Name" field="xmlNodeName"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th colSpan={this.state.selectedFileTypeOption === "Fixed-Width" ? "5" : (this.state.selectedFileTypeOption === "XML" ? "6" : "4")}>&nbsp;</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "172px" }}></th>
                                                                        <th style={{ width: "50px" }}></th>
                                                                        <th style={{ width: "45px", display: this.state.selectedFileTypeOption === "Fixed-Width" ? "" : "none" }}></th>
                                                                        <th style={{ width: "37px" }}></th>
                                                                        <th style={{ width: "95px", display: this.state.selectedFileTypeOption === "XML" ? "" : "none" }}></th>
                                                                        <th style={{ width: "80px", display: this.state.selectedFileTypeOption === "XML" ? "" : "none" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.xMergeOptionsImpoItemFields
                                                                        && this.state.xMergeOptionsImpo !== null && this.state.xMergeOptionsImpo.length > 0
                                                                        ? this.state.xMergeOptionsImpo.map((itm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionXMergeOptionsImpoRow(e, index) }} className={this.state.selectedXMergeOptionsImpoRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "18px", textAlign: "right" }}>
                                                                                    {index + 1}&nbsp;
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect"//codeSelect
                                                                                        options={this.importNameOptions} className="crstarImportNameOptCls"
                                                                                        focused={(e) => { this.handleOnFocus(e, index, "selectedXMergeOptionsImpoRowIndex") }}
                                                                                        changed={(e) => { this.handleXMergeOptionsImpoGridChange(e, index) }}
                                                                                        field="importColumn" value={itm.importColumn}
                                                                                        labelText="" labelWidth="0px" width="150px" lineStyle="oneLine"
                                                                                        dataSource="Import_Column_Option" titleDialog=""
                                                                                        inValid={this.state.xMergeOptionsImpoItemFields[index]["importColumn"]}
                                                                                        keyId={"importColumn" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsImpoMaxlengthInput ? this.state.xMergeOptionsImpoMaxlengthInput["importColumn"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsImpoLostfocus(e, index) }}
                                                                                        shownType="dropdown" focusingField={this.state.focusingField}
                                                                                        displayingCodeOnly={true}
                                                                                        hideFilter={true} limitByWidth={true}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsImpoGridChange(e, index) }}
                                                                                        field="position" value={itm.position}
                                                                                        labelText="" labelWidth="0px" width="50px"
                                                                                        inValid={this.state.xMergeOptionsImpoItemFields[index]["position"]}
                                                                                        keyId={"position" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsImpoMaxlengthInput ? this.state.xMergeOptionsImpoMaxlengthInput["position"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsImpoLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td className={this.state.selectedFileTypeOption === "Fixed-Width" ? "" : "hidden"}>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsImpoGridChange(e, index) }}
                                                                                        field="length" value={itm.length}
                                                                                        labelText="" labelWidth="0px" width="45px"
                                                                                        inValid={this.state.xMergeOptionsImpoItemFields[index]["length"]}
                                                                                        keyId={"length" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsImpoMaxlengthInput ? this.state.xMergeOptionsImpoMaxlengthInput["length"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsImpoLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsImpoGridChange(e, index) }}
                                                                                        field="reportDisplayOrder" value={itm.reportDisplayOrder}
                                                                                        labelText="" labelWidth="0px" width="35px"
                                                                                        inValid={this.state.xMergeOptionsImpoItemFields[index]["reportDisplayOrder"]}
                                                                                        keyId={"reportDisplayOrder" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsImpoMaxlengthInput ? this.state.xMergeOptionsImpoMaxlengthInput["reportDisplayOrder"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsImpoLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td className={this.state.selectedFileTypeOption === "XML" ? "" : "hidden"}>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsImpoGridChange(e, index) }}
                                                                                        field="xmlPath" value={itm.xmlPath}
                                                                                        labelText="" labelWidth="0px" width="95px"
                                                                                        inValid={this.state.xMergeOptionsImpoItemFields[index]["xmlPath"]}
                                                                                        keyId={"xmlPath" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsImpoMaxlengthInput ? this.state.xMergeOptionsImpoMaxlengthInput["xmlPath"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsImpoLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td className={this.state.selectedFileTypeOption === "XML" ? "" : "hidden"}>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsImpoGridChange(e, index) }}
                                                                                        field="xmlNodeName" value={itm.xmlNodeName}
                                                                                        labelText="" labelWidth="0px" width="70px"
                                                                                        inValid={this.state.xMergeOptionsImpoItemFields[index]["xmlNodeName"]}
                                                                                        keyId={"xmlNodeName" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsImpoMaxlengthInput ? this.state.xMergeOptionsImpoMaxlengthInput["xmlNodeName"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsImpoLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan={this.state.selectedFileTypeOption === "Fixed-Width" ? "5" : (this.state.selectedFileTypeOption === "XML" ? "6" : "4")}>No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewXMergeOptionsImpo}>Add</button>
                                                            <button onClick={this.deleteSelectedXMergeOptionsImpo} id="btnSelectedXMergeOptionsHosp" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t2">
                                            <div className="hospitalCodesOnTab2">
                                                <div className="row">
                                                    <div className="col-sm-12 xMergeOptionsHospListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="Import File" field="importValue"
                                                                        /></th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="CRStar" field="ersValue"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th colSpan="3">&nbsp;</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.xMergeOptionsHospItemFields
                                                                        && this.state.xMergeOptionsHosp !== null && this.state.xMergeOptionsHosp.length > 0
                                                                        ? this.state.xMergeOptionsHosp.map((itm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionXMergeOptionsHospRow(e, index) }} className={this.state.selectedXMergeOptionsHospRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsHospGridChange(e, index) }}
                                                                                        field="importValue" value={itm.importValue}
                                                                                        labelText="" labelWidth="0px" width="100px"
                                                                                        inValid={this.state.xMergeOptionsHospItemFields[index]["importValue"]}
                                                                                        keyId={"hosp_importValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsHospMaxlengthInput ? this.state.xMergeOptionsHospMaxlengthInput["importValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsHospLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect"
                                                                                        focused={(e) => { this.handleOnFocus(e, index, "selectedXMergeOptionsHospRowIndex") }}
                                                                                        changed={(e) => { this.handleXMergeOptionsHospGridChange(e, index) }}
                                                                                        field="ersValue" value={itm.ersValue}
                                                                                        labelText="" labelWidth="0px" width="100px" lineStyle="oneLine"
                                                                                        dataSource="Hospital" titleDialog="Hospital Lookup" codeLength="2" flag="2"
                                                                                        inValid={this.state.xMergeOptionsHospItemFields[index]["ersValue"]}
                                                                                        keyId={"ersValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsHospMaxlengthInput ? this.state.xMergeOptionsHospMaxlengthInput["ersValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsHospLostfocus(e, index) }}
                                                                                        shownType="dropdown" focusingField={this.state.focusingField}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewXMergeOptionsHosp}>Add</button>
                                                            <button onClick={this.deleteSelectedXMergeOptionsHosp} id="btnSelectedXMergeOptionsHosp" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t3">
                                            <div className="maritalStatusCodesOnTab3">
                                                <div className="row">
                                                    <div className="col-sm-12 xMergeOptionsMariListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="Import File" field="importValue"
                                                                        /></th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="CRStar" field="ersValue"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th colSpan="3">&nbsp;</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.xMergeOptionsMariItemFields
                                                                        && this.state.xMergeOptionsMari !== null && this.state.xMergeOptionsMari.length > 0
                                                                        ? this.state.xMergeOptionsMari.map((itm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionXMergeOptionsMariRow(e, index) }} className={this.state.selectedXMergeOptionsMariRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsMariGridChange(e, index) }}
                                                                                        field="importValue" value={itm.importValue}
                                                                                        labelText="" labelWidth="0px" width="100px"
                                                                                        inValid={this.state.xMergeOptionsMariItemFields[index]["importValue"]}
                                                                                        keyId={"mari_importValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsMariMaxlengthInput ? this.state.xMergeOptionsMariMaxlengthInput["importValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsMariLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect"
                                                                                        focused={(e) => { this.handleOnFocus(e, index, "selectedXMergeOptionsMariRowIndex") }}
                                                                                        changed={(e) => { this.handleXMergeOptionsMariGridChange(e, index) }}
                                                                                        field="ersValue" value={itm.ersValue}
                                                                                        labelText="" labelWidth="0px" width="100px" lineStyle="oneLine"
                                                                                        dataSource="Global_Dir_Mv" titleDialog="" itemNbr="22" aliasNo="22"
                                                                                        inValid={this.state.xMergeOptionsMariItemFields[index]["ersValue"]}
                                                                                        keyId={"ersValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsMariMaxlengthInput ? this.state.xMergeOptionsMariMaxlengthInput["ersValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsMariLostfocus(e, index) }}
                                                                                        shownType="dropdown" focusingField={this.state.focusingField}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewXMergeOptionsMari}>Add</button>
                                                            <button onClick={this.deleteSelectedXMergeOptionsMari} id="btnSelectedXMergeOptionsMari" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t4">
                                            <div className="raceCodesOnTab4">
                                                <div className="row">
                                                    <div className="col-sm-12 xMergeOptionsRaceListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "120px" }}>
                                                                        <Input type="gridHeader" labelWidth="120px"
                                                                            labelText="Import File" field="importValue"
                                                                        /></th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="CRStar" field="ersValue"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th colSpan="3">&nbsp;</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "120px" }}></th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.xMergeOptionsRaceItemFields
                                                                        && this.state.xMergeOptionsRace !== null && this.state.xMergeOptionsRace.length > 0
                                                                        ? this.state.xMergeOptionsRace.map((itm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionXMergeOptionsRaceRow(e, index) }} className={this.state.selectedXMergeOptionsRaceRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsRaceGridChange(e, index) }}
                                                                                        field="importValue" value={itm.importValue}
                                                                                        labelText="" labelWidth="0px" width="120px"
                                                                                        inValid={this.state.xMergeOptionsRaceItemFields[index]["importValue"]}
                                                                                        keyId={"race_importValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsRaceMaxlengthInput ? this.state.xMergeOptionsRaceMaxlengthInput["importValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsRaceLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect"
                                                                                        focused={(e) => { this.handleOnFocus(e, index, "selectedXMergeOptionsRaceRowIndex") }}
                                                                                        changed={(e) => { this.handleXMergeOptionsRaceGridChange(e, index) }}
                                                                                        field="ersValue" value={itm.ersValue}
                                                                                        labelText="" labelWidth="0px" width="100px" lineStyle="oneLine"
                                                                                        dataSource="Global_Dir_Mv" titleDialog="" itemNbr="18" aliasNo="18"
                                                                                        inValid={this.state.xMergeOptionsRaceItemFields[index]["ersValue"]}
                                                                                        keyId={"ersValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsRaceMaxlengthInput ? this.state.xMergeOptionsRaceMaxlengthInput["ersValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsRaceLostfocus(e, index) }}
                                                                                        shownType="dropdown" focusingField={this.state.focusingField}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewXMergeOptionsRace}>Add</button>
                                                            <button onClick={this.deleteSelectedXMergeOptionsRace} id="btnSelectedXMergeOptionsRace" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t5">
                                            <div className="sexCodesOnTab5">
                                                <div className="row">
                                                    <div className="col-sm-12 xMergeOptionsSexListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="Import File" field="importValue"
                                                                        /></th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="CRStar" field="ersValue"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th colSpan="3">&nbsp;</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.xMergeOptionsSexItemFields
                                                                        && this.state.xMergeOptionsSex !== null && this.state.xMergeOptionsSex.length > 0
                                                                        ? this.state.xMergeOptionsSex.map((itm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionXMergeOptionsSexRow(e, index) }} className={this.state.selectedXMergeOptionsSexRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsSexGridChange(e, index) }}
                                                                                        field="importValue" value={itm.importValue}
                                                                                        labelText="" labelWidth="0px" width="100px"
                                                                                        inValid={this.state.xMergeOptionsSexItemFields[index]["importValue"]}
                                                                                        keyId={"importValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsSexMaxlengthInput ? this.state.xMergeOptionsSexMaxlengthInput["importValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsSexLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect"
                                                                                        focused={(e) => { this.handleOnFocus(e, index, "selectedXMergeOptionsSexRowIndex") }}
                                                                                        changed={(e) => { this.handleXMergeOptionsSexGridChange(e, index) }}
                                                                                        field="ersValue" value={itm.ersValue}
                                                                                        labelText="" labelWidth="0px" width="100px" lineStyle="oneLine"
                                                                                        dataSource="Global_Dir_Mv" titleDialog="" itemNbr="9" aliasNo="9"
                                                                                        inValid={this.state.xMergeOptionsSexItemFields[index]["ersValue"]}
                                                                                        keyId={"ersValue" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsSexMaxlengthInput ? this.state.xMergeOptionsSexMaxlengthInput["ersValue"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsSexLostfocus(e, index) }}
                                                                                        shownType="dropdown" focusingField={this.state.focusingField}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewXMergeOptionsSex}>Add</button>
                                                            <button onClick={this.deleteSelectedXMergeOptionsSex} id="btnSelectedXMergeOptionsSex" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label style={{width: "80px"}}>ICD Version</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <div style={{ textAlign: "right" }}>
                                                        <label>
                                                            <input type="radio" onClick={this.setIcdVersionOption} onChange={this.setIcdVersionOption}
                                                                checked={this.state.icdVersionOption === "ICD-9"} id="icdOpt_9"
                                                                name="icdVersion" field="icdVersion" value="ICD-9" style={{ marginRight: "5px" }} />
                                                                ICD-9
                                                        </label>
                                                        <label style={{ width: "40px" }}> </label>
                                                        <label>
                                                            <input type="radio" onClick={this.setIcdVersionOption} onChange={this.setIcdVersionOption}
                                                                checked={this.state.icdVersionOption === "ICD-10"} id="icdOpt_10"
                                                                name="icdVersion" field="icdVersion" value="ICD-10" style={{ marginRight: "5px" }} />
                                                                ICD-10
                                                        </label>
                                                        <label style={{ width: "40px" }}> </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icdCodesAndRangesOnTab6">
                                                <div className="row">
                                                    <div className="col-sm-12 xMergeOptionsIcdListArea">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="Start Code" field="startCode"
                                                                        /></th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="100px"
                                                                            labelText="End Code" field="endCode"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th colSpan="3">&nbsp;</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="innerBody">
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                        <th style={{ width: "100px" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.xMergeOptionsIcdItemFields
                                                                        && this.state.xMergeOptionsIcd !== null && this.state.xMergeOptionsIcd.length > 0
                                                                        ? this.state.xMergeOptionsIcd.map((itm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionXMergeOptionsIcdRow(e, index) }} className={this.state.selectedXMergeOptionsIcdRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsIcdGridChange(e, index) }}
                                                                                        field="startCode" value={itm.startCode}
                                                                                        labelText="" labelWidth="0px" width="100px"
                                                                                        inValid={this.state.xMergeOptionsIcdItemFields[index]["startCode"]}
                                                                                        keyId={"startCode" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsIcdMaxlengthInput ? this.state.xMergeOptionsIcdMaxlengthInput["startCode"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsIcdLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        changed={(e) => { this.handleXMergeOptionsIcdGridChange(e, index) }}
                                                                                        field="endCode" value={itm.endCode}
                                                                                        labelText="" labelWidth="0px" width="100px"
                                                                                        inValid={this.state.xMergeOptionsIcdItemFields[index]["endCode"]}
                                                                                        keyId={"endCode" + index} rowIndex={index}
                                                                                        maxLength={this.state.xMergeOptionsIcdMaxlengthInput ? this.state.xMergeOptionsIcdMaxlengthInput["endCode"] : ""}
                                                                                        lostfocused={(e) => { this.handleXMergeOptionsIcdLostfocus(e, index) }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button onClick={this.addNewXMergeOptionsIcd}>Add</button>
                                                            <button onClick={this.deleteSelectedXMergeOptionsIcd} id="btnSelectedXMergeOptionsIcd" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t7">
                                            <div className="row">
                                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                                    <button id="btnLoadBeakerDefaults" onClick={(e) => { this.loadBeakerDefaults(e) }}>Load Beaker Defaults</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange}
                                                        inValid={this.state.dAllItemFields["specimenToTopoMapping"]}
                                                        labelText="Incoming Text = CRStar Topo Code" field="specimenToTopoMapping"
                                                        value={this.state.crstarImportForm.specimenToTopoMapping}
                                                        labelWidth="auto" width="350px" rows="14" //height="405px"
                                                        normalTaField={true}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t8">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Date of Birth Format" field="dateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.dateFormat}
                                                        dataSource="static" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["dateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="dateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="First Contact Date Format" field="firstContactDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.firstContactDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["firstContactDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstContactDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="firstContactDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Diagnosis Date Format" field="diagnosisDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.diagnosisDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["diagnosisDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["diagnosisDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="diagnosisDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Admit Date Format" field="admitDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.admitDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["admitDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["admitDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="admitDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Disch Date Format" field="dischDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.dischDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["dischDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dischDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="dischDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Chemo Start Date Format" field="chemoStartDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.chemoStartDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["chemoStartDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoStartDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="chemoStartDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Chemo End Date Format" field="chemoEndDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.chemoEndDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["chemoEndDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoEndDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="chemoEndDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row hidden">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Rad Start Date Format" field="radStartDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.radStartDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["radStartDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radStartDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="radStartDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row hidden">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Rad End Date Format" field="radEndDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.radEndDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["radEndDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radEndDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="radEndDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="User Defined Date Format" field="userDefinedDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.userDefinedDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["userDefinedDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userDefinedDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="userDefinedDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Death Date Format" field="deathDateFormat" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.deathDateFormat}
                                                        dataSource="" titleDialog="" labelWidth="180px" width="100px"
                                                        inValid={this.state.dAllItemFields["deathDateFormat"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["deathDateFormat"] : ""}
                                                        options={this.dateTemplateFormatting} keyId="deathDateFormat0" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="t9">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"//default value: 99
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Default Sequence" field="defaultSequence"
                                                        value={this.state.crstarImportForm.defaultSequence}
                                                        labelWidth="180px" width="40px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["defaultSequence"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"//default value: 99
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Default Class" field="defaultClass"
                                                        value={this.state.crstarImportForm.defaultClass}
                                                        labelWidth="180px" width="40px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["defaultClass"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Default Casefinding Source" field="defaultCasefindingSource" lineStyle="oneLine"
                                                        value={this.state.crstarImportForm.defaultCasefindingSource}
                                                        dataSource="Global_Dir_Mv" aliasNo="509"
                                                        titleDialog="" labelWidth="180px" width="40px"
                                                        inValid={this.state.dAllItemFields["defaultCasefindingSource"]} itemNbr="509"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["defaultCasefindingSource"] : ""}
                                                        shownType="dropdown" keyId="defaultCasefindingSource0" rowIndex="0"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Input type="checkbox"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Default Country"
                                                        title="Defaults Country to USA where the State is a US state"
                                                        field="defaultCountry"
                                                        labelWidth="270px" width="30px"
                                                        value={this.state.crstarImportForm.defaultCountry && this.state.crstarImportForm.defaultCountry == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.defaultCountry && this.state.crstarImportForm.defaultCountry == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row hidden">
                                                <div className="col-sm-12">
                                                    <Input type="checkbox"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Default Birth Country and State"
                                                        title="Default the Birth Country and Birth State using values from the Current Country and Current State"
                                                        field="defaultBirthCountryState"
                                                        labelWidth="270px" width="30px"
                                                        value={this.state.crstarImportForm.defaultBirthCountryState && this.state.crstarImportForm.defaultBirthCountryState == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.defaultBirthCountryState && this.state.crstarImportForm.defaultBirthCountryState == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t10">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="If you are importing a Patient Type, List the Patient Types, separated by commas, that should be accepted"
                                                        field="patientTypeCodes"
                                                        value={this.state.crstarImportForm.patientTypeCodes}
                                                        labelWidth="320px" width="320px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["patientTypeCodes"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="List the Discharge Disposition Codes or other Patient Status Codes, separated by commas, that indicate a deceased patient"
                                                        field="dischDisCodes" placeholder="Example: 40,41,42"
                                                        value={this.state.crstarImportForm.dischDisCodes}
                                                        labelWidth="320px" width="320px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dischDisCodes"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="If you are importing an Autopsy code, please enter the code that indicates that an Autopsy was performed, followed by a comma and the code that indicates that an Autopsy was not performed"
                                                        field="autopsyCodes" placeholder="Example: Yes,No"
                                                        value={this.state.crstarImportForm.autopsyCodes}
                                                        labelWidth="320px" width="320px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["autopsyCodes"] : ""}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t11">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"//default value: 0
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Number of Header Rows in Data File" field="numberOfHeaderRows"
                                                        value={this.state.crstarImportForm.numberOfHeaderRows}
                                                        labelWidth="230px" width="30px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["numberOfHeaderRows"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Number of digits to Pad MRN up to" field="padMrn"
                                                        title="The Medical Record Number will be padded with leading zeros up to this number.  If left blank, the MRN will be imported as it exists in the data file."
                                                        value={this.state.crstarImportForm.padMrn}
                                                        labelWidth="230px" width="35px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["padMrn"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Number of digits to Trim MRN down to" field="trimMrn"
                                                        title="The length of the Medical Record Number will be trimmed down to this number by removing leading digits.  If left blank, the MRN will be imported as it exists in the data file."
                                                        value={this.state.crstarImportForm.trimMrn}
                                                        labelWidth="230px" width="35px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["trimMrn"] : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Input type="checkbox"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Import patient name in all CAPS"
                                                        title="Import the patient name in all upper-case letters"
                                                        field="importNameAllCaps"
                                                        labelWidth="270px" width="30px"
                                                        value={this.state.crstarImportForm.importNameAllCaps && this.state.crstarImportForm.importNameAllCaps == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.importNameAllCaps && this.state.crstarImportForm.importNameAllCaps == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Input type="checkbox"//show/hide: Import_Type <> "Follow-Up"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Use Admit Dt as Disch Dt where Disch is blank"
                                                        field="useAdmitAsDisch"
                                                        labelWidth="270px" width="30px"
                                                        value={this.state.crstarImportForm.useAdmitAsDisch && this.state.crstarImportForm.useAdmitAsDisch == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.useAdmitAsDisch && this.state.crstarImportForm.useAdmitAsDisch == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Input type="checkbox"//show/hide: Import_Type <> "Follow-Up"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Use topo C809 when no ICD Code is available"
                                                        title="Check only if using a pre-filtered file containing only cases for the Cancer Registry"
                                                        field="useC809"
                                                        labelWidth="270px" width="30px"
                                                        value={this.state.crstarImportForm.useC809 && this.state.crstarImportForm.useC809 == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.useC809 && this.state.crstarImportForm.useC809 == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Input type="checkbox"//default value: true
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Use all 9's for unknown SSN's"
                                                        title="Use 999999999 when the Social Security Number in the file is blank or all 0's or 8's etc. Using all 9's will improve the matches found where the SSN in CRStar is also unknown/all 9's."
                                                        field="convertUnkSsn"
                                                        labelWidth="270px" width="30px"
                                                        value={this.state.crstarImportForm.convertUnkSsn && this.state.crstarImportForm.convertUnkSsn == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.convertUnkSsn && this.state.crstarImportForm.convertUnkSsn == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>By default user defined fields will be combined into the Imported Text field.  If you wish to map user defined fields in the data file to CRStar user-defined fields, enter any text you would like to precede the user-defined value, and then enter the number of the CRStar user-defined field that the data should be put into.</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText1"
                                                        value={this.state.crstarImportForm.userText1}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText1"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_1 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef1Mapping"
                                                        value={this.state.crstarImportForm.udef1Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="1"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef1Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef1Mapping"
                                                        value={this.state.crstarImportForm.udef1Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef1Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef1Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef1Mapping1" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="1" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel1" className="autoGeneralFromMapping udef1Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel1 ? this.state.crstarImportForm.userLabel1 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText2"
                                                        value={this.state.crstarImportForm.userText2}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText2"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_2 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef2Mapping"
                                                        value={this.state.crstarImportForm.udef2Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="2"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef2Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef2Mapping"
                                                        value={this.state.crstarImportForm.udef2Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef2Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef2Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef2Mapping2" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="2" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel2" className="autoGeneralFromMapping udef2Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel2 ? this.state.crstarImportForm.userLabel2 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText3"
                                                        value={this.state.crstarImportForm.userText3}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText3"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_3 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef3Mapping"
                                                        value={this.state.crstarImportForm.udef3Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="3"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef3Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef3Mapping"
                                                        value={this.state.crstarImportForm.udef3Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef3Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef3Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef3Mapping3" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="3" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel3" className="autoGeneralFromMapping udef3Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel3 ? this.state.crstarImportForm.userLabel3 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText4"
                                                        value={this.state.crstarImportForm.userText4}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText4"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_4 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef4Mapping"
                                                        value={this.state.crstarImportForm.udef4Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="4"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef4Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef4Mapping"
                                                        value={this.state.crstarImportForm.udef4Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef4Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef4Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef4Mapping4" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="4" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel4" className="autoGeneralFromMapping udef4Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel4 ? this.state.crstarImportForm.userLabel4 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText5"
                                                        value={this.state.crstarImportForm.userText5}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText5"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_5 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef5Mapping"
                                                        value={this.state.crstarImportForm.udef5Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="5"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef5Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef5Mapping"
                                                        value={this.state.crstarImportForm.udef5Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef5Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef5Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef5Mapping5" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="5" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel5" className="autoGeneralFromMapping udef5Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel5 ? this.state.crstarImportForm.userLabel5 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText6"
                                                        value={this.state.crstarImportForm.userText6}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText6"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_6 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef6Mapping"
                                                        value={this.state.crstarImportForm.udef6Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="6"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef6Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef6Mapping"
                                                        value={this.state.crstarImportForm.udef6Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef6Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef6Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef6Mapping6" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="6" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel6" className="autoGeneralFromMapping udef6Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel6 ? this.state.crstarImportForm.userLabel6 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText7"
                                                        value={this.state.crstarImportForm.userText7}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText7"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_7 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef7Mapping"
                                                        value={this.state.crstarImportForm.udef7Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="7"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef7Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef7Mapping"
                                                        value={this.state.crstarImportForm.udef7Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef7Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef7Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef7Mapping7" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="7" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel7" className="autoGeneralFromMapping udef7Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel7 ? this.state.crstarImportForm.userLabel7 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText8"
                                                        value={this.state.crstarImportForm.userText8}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText8"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_8 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef8Mapping"
                                                        value={this.state.crstarImportForm.udef8Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="8"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef8Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef8Mapping"
                                                        value={this.state.crstarImportForm.udef8Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef8Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef8Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef8Mapping8" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="8" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel8" className="autoGeneralFromMapping udef8Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel8 ? this.state.crstarImportForm.userLabel8 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText9"
                                                        value={this.state.crstarImportForm.userText9}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText9"] : ""}
                                                    />
                                                    <label>&nbsp; + User_Defined_9 &nbsp;&nbsp;<img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef9Mapping"
                                                        value={this.state.crstarImportForm.udef9Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="9"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef9Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef9Mapping"
                                                        value={this.state.crstarImportForm.udef9Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef9Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef9Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef9Mapping9" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="9" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel9" className="autoGeneralFromMapping udef9Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel9 ? this.state.crstarImportForm.userLabel9 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 oneLine">
                                                    <Input type="input"
                                                        focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="userText10"
                                                        value={this.state.crstarImportForm.userText10}
                                                        labelWidth="0px" width="132px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["userText10"] : ""}
                                                    />
                                                    <label>&nbsp;+ User_Defined_10 <img src="https://crstar.ers-can.com/images/$$navigate.arrow.right.png.a5image"></img> &nbsp;Udef&nbsp;</label>
                                                    {/*
                                                    <Input type="input" autocompleted
                                                        focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                                        labelText="" field="udef10Mapping"
                                                        value={this.state.crstarImportForm.udef10Mapping}
                                                        labelWidth="0px" width="35px" rowIndex="10"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef10Mapping"] : ""}
                                                    />
                                                    */}
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="" field="udef10Mapping"
                                                        value={this.state.crstarImportForm.udef10Mapping}
                                                        dataSource="" titleDialog="" labelWidth="0px" width="35px"
                                                        inValid={this.state.dAllItemFields["udef10Mapping"]}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef10Mapping"] : ""}
                                                        options={this.udefValuesMapping} keyId="udef10Mapping10" focusingField={this.state.focusingField}
                                                        shownType="dropdown" rowIndex="10" displayingCodeOnly={true}
                                                        hideFilter={true} limitByWidth={true} autocompleted={true}
                                                    />
                                                    &nbsp;<label id="userLabel10" className="autoGeneralFromMapping udef10Mapping">
                                                        {this.state.crstarImportForm && this.state.crstarImportForm.userLabel10 ? this.state.crstarImportForm.userLabel10 : ""}
                                                    </label>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="t13">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>Additional Report Options:</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="checkbox"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Double Space Report"
                                                        field="doubleSpace"
                                                        labelWidth="260px" width="30px"
                                                        value={this.state.crstarImportForm.doubleSpace && this.state.crstarImportForm.doubleSpace == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.doubleSpace && this.state.crstarImportForm.doubleSpace == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="checkbox"//show/hide: Import_Type <> 'Follow-Up'
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Show New Topo Codes on the Report"
                                                        field="includeTopo"
                                                        labelWidth="260px" width="30px"
                                                        value={this.state.crstarImportForm.includeTopo && this.state.crstarImportForm.includeTopo == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.includeTopo && this.state.crstarImportForm.includeTopo == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="checkbox"//show/hide: Import_Type <> 'Follow-Up'
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Show New Dx Comments on the Report"
                                                        field="includeDxComments"
                                                        labelWidth="260px" width="30px"
                                                        value={this.state.crstarImportForm.includeDxComments && this.state.crstarImportForm.includeDxComments == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.includeDxComments && this.state.crstarImportForm.includeDxComments == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="checkbox"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Show New Patient Status on the Report"
                                                        field="includeNewPatientStatus"
                                                        labelWidth="260px" width="30px"
                                                        value={this.state.crstarImportForm.includeNewPatientStatus && this.state.crstarImportForm.includeNewPatientStatus == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.includeNewPatientStatus && this.state.crstarImportForm.includeNewPatientStatus == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="checkbox"
                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        labelText="Show Already Existing Cases on the Report"
                                                        field="includeAlreadyAbstracted"
                                                        labelWidth="260px" width="30px"
                                                        value={this.state.crstarImportForm.includeAlreadyAbstracted && this.state.crstarImportForm.includeAlreadyAbstracted == "true" ? "true" : "false"}
                                                        checked={this.state.crstarImportForm.includeAlreadyAbstracted && this.state.crstarImportForm.includeAlreadyAbstracted == "true" ? true : false}
                                                        clicked={this.toggleChecked} />
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="importTypeRadio">
                                            <div id="importTypeLabel" style={{ verticalAlign: "middle", border: "none" }}>Import Type:</div>
                                            <label style={{ width: "240px", height: "0px", cursor: "pointer" }}>
                                                <input type="radio" onClick={this.selectionImportType}
                                                    checked={this.state.selectedImportTypeOption === "Casefinding and Follow-Up"}
                                                    name="importType" field="importType" value="Casefinding and Follow-Up"
                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                Casefinding and Follow-Up</label>
                                            <br />
                                            <label style={{ width: "240px", height: "0px", cursor: "pointer" }}>
                                                <input type="radio" onClick={this.selectionImportType}
                                                    checked={this.state.selectedImportTypeOption === "Follow-Up"}
                                                    name="importType" field="importType" value="Follow-Up"
                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                Follow-Up</label>
                                            <br />
                                            <label style={{ width: "240px", height: "0px", cursor: "pointer" }}>
                                                <input type="radio" onClick={this.selectionImportType}
                                                    checked={this.state.selectedImportTypeOption === "Casefinding"}
                                                    name="importType" field="importType" value="Casefinding"
                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                Casefinding</label>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            lostfocused={this.handleLostFocus}
                                            labelText="Follow-Up Entered By" field="followUpEnteredBy"
                                            value={this.state.crstarImportForm.followUpEnteredBy}
                                            labelWidth="100%" width="175px"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpEnteredBy"] : ""}
                                            inValid={this.state.dAllItemFields["followUpEnteredBy"]}
                                            bubbleMsg={this.state.dAllItemFields["followUpEnteredBy_bubbleMsg"]}
                                        />
                                    </div>
                                </div>

                                <div className="emptyLine"></div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <label style={{ width: "420px" }}>In addition to Date of Birth, which fields should be used to match patients in the data file with patients in CRStar?</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Last Name"
                                            field="matchOnLastName"
                                            labelWidth="80px" width="30px"
                                            value={this.state.crstarImportForm.matchOnLastName && this.state.crstarImportForm.matchOnLastName == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.matchOnLastName && this.state.crstarImportForm.matchOnLastName == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                    <div className="col-md-2">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="First Name"
                                            field="matchOnFirstName"
                                            labelWidth="80px" width="30px"
                                            value={this.state.crstarImportForm.matchOnFirstName && this.state.crstarImportForm.matchOnFirstName == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.matchOnFirstName && this.state.crstarImportForm.matchOnFirstName == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                    <div className="col-md-2">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Sex"
                                            field="matchOnSex"
                                            labelWidth="80px" width="30px"
                                            value={this.state.crstarImportForm.matchOnSex && this.state.crstarImportForm.matchOnSex == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.matchOnSex && this.state.crstarImportForm.matchOnSex == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                    <div className="col-md-2">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="SSN"
                                            field="matchOnSsn"
                                            labelWidth="80px" width="30px"
                                            value={this.state.crstarImportForm.matchOnSsn && this.state.crstarImportForm.matchOnSsn == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.matchOnSsn && this.state.crstarImportForm.matchOnSsn == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                    <div className="col-md-2">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="MRN"
                                            field="matchOnMrn"
                                            labelWidth="80px" width="30px"
                                            value={this.state.crstarImportForm.matchOnMrn && this.state.crstarImportForm.matchOnMrn == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.matchOnMrn && this.state.crstarImportForm.matchOnMrn == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                    <div className="col-md-2" className="hidden">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Date of Birth"
                                            field="matchOnDob"
                                            labelWidth="80px" width="30px"
                                            value={this.state.crstarImportForm.matchOnDob && this.state.crstarImportForm.matchOnDob == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.matchOnDob && this.state.crstarImportForm.matchOnDob == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                </div>

                                <div className="emptyLine"></div>

                                <div className={this.state.selectedImportTypeOption != "Follow-Up" ? "row" : "hiddenByInvisible"}>
                                    <div className="col-md-12">
                                        <div className="Additional_Primary_Filtering_Options_Radio">
                                            <div id="Additional_Primary_Filtering_Options_Label" style={{ verticalAlign: "middle", border: "none" }}>Additional Primary Filtering Options:</div>
                                            {
                                                /**
                                                 * show/hide: Import_Type <> "Follow-Up"
                                                 * default value: Do not create new primary if 2 digit Site Code and Hospital Code match.
                                                 **/
                                                this.newPrimariesOptions.map((element, index) => (
                                                    <div key={index}>
                                                        <label style={{ height: "0px", cursor: "pointer" }}>
                                                            <input type="radio" onClick={this.selectionNewPrimaries}
                                                                checked={this.state.selectedNewPrimariesOption === element["opt" + index]}
                                                                name="newPrimaries" field="newPrimaries" value={element["opt" + index]}
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                                            {element["opt" + index]}</label>
                                                        <br />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.selectedImportTypeOption != "Follow-Up" ? "emptyLine" : "hiddenByInvisible"}></div>

                                <div className={this.state.selectedImportTypeOption != "Follow-Up" ? "row" : "hiddenByInvisible"}>
                                    <div className="col-md-12">
                                        <Input type="checkbox" //show/hide: Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Update Patient Suspense Remarks"
                                            title="Update the Patient Suspense Remarks when new cancer data is found. Note: Choosing to update the Patient Remarks will slow down the import process."
                                            field="updatePatientSuspenseRemarks"
                                            labelWidth="310px" width="30px"
                                            value={this.state.crstarImportForm.updatePatientSuspenseRemarks && this.state.crstarImportForm.updatePatientSuspenseRemarks == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.updatePatientSuspenseRemarks && this.state.crstarImportForm.updatePatientSuspenseRemarks == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Input type="checkbox"
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Update Current Address if newer Last Contact Found"
                                            field="updateAddress"
                                            labelWidth="310px" width="30px"
                                            value={this.state.crstarImportForm.updateAddress && this.state.crstarImportForm.updateAddress == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.updateAddress && this.state.crstarImportForm.updateAddress == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                </div>
                                <div className={this.state.selectedImportTypeOption != "Follow-Up" ? "row" : "hiddenByInvisible"}>
                                    <div className="col-md-12">
                                        <Input type="checkbox"//show/hide: Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Update Existing Cases when Additional Data is Found"
                                            title="Update the blank fields of existing cases when additional cancer data is found. Note: Choosing this update option will slow down the import process."
                                            field="updateExistingCases"
                                            labelWidth="310px" width="30px"
                                            value={this.state.crstarImportForm.updateExistingCases && this.state.crstarImportForm.updateExistingCases == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.updateExistingCases && this.state.crstarImportForm.updateExistingCases == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                </div>
                                <div className={this.state.selectedImportTypeOption != "Follow-Up" ? "row" : "hiddenByInvisible"}>
                                    <div className="col-md-12">
                                        <Input type="checkbox"//show/hide: Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Update Dx Comments of existing suspense primaries"
                                            title="Update the Diagnosis Comments of an existing suspense primary in CRStar when a matching case is found in your data file. Note: Choosing to update the Dx Comments of existing cases in CRStar will slow down the import process."
                                            field="updateDxComments"
                                            labelWidth="310px" width="30px"
                                            value={this.state.crstarImportForm.updateDxComments && this.state.crstarImportForm.updateDxComments == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.updateDxComments && this.state.crstarImportForm.updateDxComments == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                    </div>
                                </div>
                                <div className={this.state.selectedImportTypeOption != "Follow-Up" ? "row" : "hiddenByInvisible"}>
                                    <div className="col-md-12" id="Date_of_existing_suspense">
                                        <Input type="checkbox"//show/hide: Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."
                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                            labelText="Update"
                                            title="Update the a date of existing suspense cases when the date is blank in CRStar. Note: Choosing to update a date of existing cases in CRStar will slow down the import process."
                                            field="updateDischDt"
                                            labelWidth="50px" width="30px"
                                            value={this.state.crstarImportForm.updateDischDt && this.state.crstarImportForm.updateDischDt == "true" ? "true" : "false"}
                                            checked={this.state.crstarImportForm.updateDischDt && this.state.crstarImportForm.updateDischDt == "true" ? true : false}
                                            clicked={this.toggleChecked} />
                                        <Input type="codeSelect"//show/hide: Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."
                                            focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="" field="dateToUpdate"//Admit|Discharge|First Contact
                                            value={this.state.crstarImportForm.dateToUpdate}
                                            dataSource="Global_Dir_Mv"
                                            labelWidth="0px" width="100px" lineStyle="oneLine"
                                            inValid={this.state.dAllItemFields["dateToUpdate"]}
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dateToUpdate"] : ""}
                                            options={["Admit", "Discharge", "First Contact"]}
                                            keyId="dateToUpdate0" focusingField={this.state.focusingField}
                                            shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                            hideFilter={true} limitByWidth={true}
                                        />

                                        {/*show/hide: Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."*/}
                                        <div>&nbsp;&nbsp;Date of existing suspense primaries</div>
                                    </div>
                                </div>

                                {/*show/hide: Update_Disch_Date = true .And. Import_Type <> "Follow-Up" .And. New_Primaries <> "Do not filter the file. Import all records into CRStar as new primaries."*/}
                                <div className={this.state.selectedImportTypeOption != "Follow-Up" && this.state.crstarImportForm.updateDischDt == "true" ? "row" : "hiddenByInvisible"}>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <div style={{ textAlign: "right" }}>
                                            <label>
                                                <input type="radio" onClick={this.setUpdateSiteAllOption} onChange={this.setUpdateSiteAllOption}
                                                    checked={this.state.updateSiteAllOption === "Only matching sites"} id="updateOnlyMatchingSites"
                                                    name="updateMatchingAllSites" field="updateMatchingAllSites" value="Only matching sites" style={{ marginRight: "5px" }} />
                                                    Only matching sites
                                            </label>
                                            <label style={{ width: "20px" }}> </label>
                                            <label>
                                                <input type="radio" onClick={this.setUpdateSiteAllOption} onChange={this.setUpdateSiteAllOption}
                                                    checked={this.state.updateSiteAllOption === "All sites"} id="updateMatchingAllSites"
                                                    name="updateMatchingAllSites" field="updateMatchingAllSites" value="All sites" style={{ marginRight: "5px" }} />
                                                    All sites
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">
                                <Input type="input" lineStyle="oneLine"
                                    focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="File" field="fileName"
                                    value={this.state.crstarImportForm.fileName}
                                    labelWidth="40px" width="200px" disabled={true}
                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fileName"] : ""}
                                />
                            </div>
                            <div className="col-sm-2">
                                <Input type="input"
                                    focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="Preview Record" field="previewRecord"
                                    value={this.state.crstarImportForm.previewRecord}
                                    labelWidth="105px" width="40px"
                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["previewRecord"] : ""}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Input type="label"
                                    labelText="Last Updated" field="dateConfigLastUpdated"
                                    value={this.state.crstarImportForm.dateConfigLastUpdated}
                                    labelWidth="90px" width="175px"
                                />
                            </div>
                            <div className="col-sm-3">
                                <Input type="label"
                                    labelText="By" field="configLastUpdatedBy"
                                    value={this.state.crstarImportForm.configLastUpdatedBy}
                                    labelWidth="25px" width="125px"
                                />
                            </div>
                        </div>
                    </form>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.refreshFormFnc} formId="crstarImportForm"
                        isFormDirty={this.state.isFormDirty && (this.state.crstarImportForm.importLabel && this.state.crstarImportForm.importLabel != "")}//Save enable: dialog.isDataDirty = true And Import_Name <> ""
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                        showBtnNew="CRStarImportOption" enabledNewBtn={this.state.isFormDirty || (this.state.crstarImportForm.importLabel && this.state.crstarImportForm.importLabel != "")}//New enable: dialog.isDataDirty = true or Import_Name <> ""
                        ignoreExit={true} enabledAlways={false}
                        btnCopyText="CopyCRStarImportOption" showBtnCopy="CRStarImportOption" enabledCopyBtn={this.state.crstarImportForm.importId && this.state.crstarImportForm.importId != ""}//Copy enable: Import_Name <> "" And Import_ID <> ""
                        copyFnc={this.copyFuncHandler}
                        showBtnDelete="CRStarImportOption" enabledDeletedBtn={this.state.crstarImportForm.importId && this.state.crstarImportForm.importId != ""}//Delete enable: Import_Name <> "" And Import_ID <> ""
                        showExtra={[
                            { name: "Reset", action: "resetImportConfiguration", enabled: this.state.crstarImportForm && this.state.crstarImportForm.importLabel && this.enableResetBtnCases.includes(this.state.crstarImportForm.importLabel), title: "Reset Import Configuration back to preset option settings" },//enable: Import_Name='Redsson' or Import_Name='FL Deathlist' or Import_Name='IN Deathlist' or Import_Name='MN Deathlist' or Import_Name='MS Deathlist' or Import_Name='NC Deathlist' or Import_Name='OH Deathlist' or Import_Name='TN Deathlist' or Import_Name='TX Deathlist' or Import_Name='VA Deathlist' or Import_Name='WI Deathlist' or Import_Name='CA Follow-Up' or Import_Name='Import FL Follow Up' or Import_Name='GA Follow-Up'
                            { name: "Preview Data", action: "previewDataImportConfig", enabled: this.state.crstarImportForm.fileName ? true : false },//enable: File_Name <> ""
                            { name: "Upload Sample File", action: "uploadSampleFile", enabled: this.state.crstarImportForm && this.state.crstarImportForm.importId && this.state.crstarImportForm.importId > 0 ? true : false }//enable: Import_Name <> "" And Import_ID <> ""
                        ]}
                        previewFnc={e => { this.previewFuncHandler(1) }}
                        uploadSampleFileFnc={e => { this.showUploadFile(e, true) }}
                        importOptionDatas={this.state.crstarImportForm}
                        className="Footer crstarImportOptionsFooter"
                        reloadPageFnc={this.reloadPage}
                    />

                </div>
            )
            : <p className="data_loading_page">Import Configuration data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {showBeakerDefaultsConfirmation}
                </React.Fragment>

                <React.Fragment>
                    {showUploadSampleFile}
                </React.Fragment>

                <React.Fragment>{copyDialog}</React.Fragment>

                <React.Fragment>{showingPreviewData}</React.Fragment>

                <React.Fragment>{copyErrorMsg}</React.Fragment>

                {crstarImportOptions}
            </React.Fragment>
        );
    }
}

CRStarImportOptions.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        importConfigInfo: state.patientAbstract.importConfigInfo,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        userId: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveImportConfig: (formData) => dispatch(actionCreators.saveImportConfig(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag, cid) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag, cid)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        loadImportConfig: (label) => dispatch(actionCreators.loadImportConfigInfo(label)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRStarImportOptions);