/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import TextForm from "../DiagnosisMaintenance/TextForm";
import "../TreatmentMaintenance/TreatmentMaintenance.css";
import "./OutcomesMaintenance.css";
import moment from "moment";
import Help from "../../../UI/Help";
import Draggable from 'react-draggable';
import PhysicianForm from "../../../UI/Lookup/AddNew/PhysicianForm";
import * as TextPopup from "../TitleOfTextPopup";

class OutcomesMaintenance extends Component {

    state = {
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'outcomesForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty outcomesForm back to what it was on load
        initialOutcomesForm: null,
        outcomesForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "dtLstCont",
        isHelpVisible: false,

        // Outcomes_Mv
        outcomesMv: null,
        outcomesMvItemFields: null,
        outcomesMvLoading: false,
        selectedOutMvRowIndex: 0,//#PR00237 - because when initial page, it's focusing first row
        outcomesMvGridFocusing: false,
        setEventHandlerForGrid: false,

        // Letters_Mv
        letters: null,
        lettersMvItemFields: null,
        lettersLoading: false,
        selectedLettersMvRowIndex: 0,

        // Letters_Log_Mv
        lettersLog: null,
        lettersLogItemFields: null,
        lettersLogLoading: false,

        // Recrrence_Mv
        recurrences: null,
        recurrenceItemFields: null,
        recurrencesLoading: false,
        selectedRecurrencesRowIndex: -1,

        // Labs_Mv
        labs: null,
        labsItemFields: null,
        labsLoading: false,
        selectedLabsRowIndex: -1,

        primTopo: null,
        primSite: null,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        showConfirmationToAddNew: false,
        newPhysVal: "",

        //Registrars -> Initials
        userInitials: "",

        outMvMaxlengthInput: null,
        lettersMvMaxlengthInput: null,
        recurrenceMaxlengthInput: null,
        labsMaxlengthInput: null,
        focusingField: null,

        tryToReloadingTimes: 0,
        reloadedDefaultTemplate: false,
    }

    setSelectionOutMvRow = (event, index) => {
        this.setState({ selectedOutMvRowIndex: index });
    }

    setSelectionLettersMvRow = (event, index) => {
        this.setState({ selectedLettersMvRowIndex: index });
    }

    setSelectionRecurrencesRow = (event, index) => {
        this.setState({ selectedRecurrencesRowIndex: index });
    }

    setSelectionLabsRow = (event, index) => {
        this.setState({ selectedLabsRowIndex: index });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    moveUp = () => {
        var thisRow = this.state.selectedLettersMvRowIndex;
        var nextRow = thisRow - 1;
        if (thisRow > 0) {
            var _this = this;
            setTimeout(function () {//#PR00418
                _this.setState(prevState => {
                    const newItems = [...prevState.letters];

                    var thisFUPhys = newItems[thisRow]["fuPhys"];
                    var thisPhysName = newItems[thisRow]["fuPhysName"];
                    var thisLtrSent = newItems[thisRow]["ltrSent"];
                    var thisLtrName = newItems[thisRow]["letterName"];

                    var nextFUPhys = newItems[nextRow]["fuPhys"];
                    var nextPhysName = newItems[nextRow]["fuPhysName"];
                    var nextLtrSent = newItems[nextRow]["ltrSent"];
                    var nextLtrName = newItems[nextRow]["letterName"];

                    newItems[thisRow]["fuPhys"] = nextFUPhys;
                    newItems[thisRow]["fuPhysName"] = nextPhysName;
                    newItems[thisRow]["ltrSent"] = nextLtrSent;
                    newItems[thisRow]["letterName"] = nextLtrName;

                    newItems[nextRow]["fuPhys"] = thisFUPhys;
                    newItems[nextRow]["fuPhysName"] = thisPhysName;
                    newItems[nextRow]["ltrSent"] = thisLtrSent;
                    newItems[nextRow]["letterName"] = thisLtrName;

                    return { letters: newItems, selectedLettersMvRowIndex: nextRow, isFormDirty: true };
                });
            }, 500);
        }
    }
    moveDown = () => {
        var thisRow = this.state.selectedLettersMvRowIndex;
        var nextRow = thisRow + 1;

        var totalRows = this.state.letters ? this.state.letters.length : 0;
        if (nextRow < totalRows) {
            var _this = this;
            setTimeout(function () {//#PR00418
                _this.setState(prevState => {
                    const newItems = [...prevState.letters];

                    var thisFUPhys = newItems[thisRow]["fuPhys"];
                    var thisPhysName = newItems[thisRow]["fuPhysName"];
                    var thisLtrSent = newItems[thisRow]["ltrSent"];
                    var thisLtrName = newItems[thisRow]["letterName"];

                    var nextFUPhys = newItems[nextRow]["fuPhys"];
                    var nextPhysName = newItems[nextRow]["fuPhysName"];
                    var nextLtrSent = newItems[nextRow]["ltrSent"];
                    var nextLtrName = newItems[nextRow]["letterName"];

                    newItems[thisRow]["fuPhys"] = nextFUPhys;
                    newItems[thisRow]["fuPhysName"] = nextPhysName;
                    newItems[thisRow]["ltrSent"] = nextLtrSent;
                    newItems[thisRow]["letterName"] = nextLtrName;

                    newItems[nextRow]["fuPhys"] = thisFUPhys;
                    newItems[nextRow]["fuPhysName"] = thisPhysName;
                    newItems[nextRow]["ltrSent"] = thisLtrSent;
                    newItems[nextRow]["letterName"] = thisLtrName;

                    return { letters: newItems, selectedLettersMvRowIndex: nextRow, isFormDirty: true };
                });
            }, 500);
        }
    }

    setOutcomesMvGridFocusing = (flag) => {
        this.setState({ outcomesMvGridFocusing: flag });
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    deleteOutcomesMvGrid = (flg) => {
        let selectedRow = this.state.selectedOutMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.outcomesMv.length) {
            selectedRow = this.state.outcomesMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.outcomesMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.outcomesMv];
                const firstOutcomes = _newItems[0];

                const newOutcomes = {};
                Object.keys(firstOutcomes).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newOutcomes[t] = 0;
                    } else {
                        newOutcomes[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newOutcomes);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.outcomesMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.outcomesMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { outcomesMv: newItems, outcomesMvItemFields: newFields, selectedOutMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    deleteLettersGrid = (flg) => {
        let selectedRow = this.state.selectedLettersMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.letters.length) {
            selectedRow = this.state.letters.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.letters];
            if (newItems.length === 1) {
                const _newItems = [...this.state.letters];
                const firstObject = _newItems[0];

                const newObject = {};
                Object.keys(firstObject).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObject[t] = 0;
                    } else {
                        newObject[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObject);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.lettersMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.lettersMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }
            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { letters: newItems, lettersMvItemFields: newFields, selectedLettersMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    deleteRecurrencesGrid = (flg) => {
        let selectedRow = this.state.selectedRecurrencesRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.recurrences.length) {
            selectedRow = this.state.recurrences.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.recurrences];
            if (newItems.length === 1) {
                const _newItems = [...this.state.recurrences];
                const firstObject = _newItems[0];

                const newObject = {};
                Object.keys(firstObject).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObject[t] = 0;
                    } else {
                        newObject[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObject);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.recurrenceItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.recurrenceItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            return { recurrences: newItems, recurrenceItemFields: newFields, selectedRecurrencesRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    deleteLabsGrid = (flg) => {
        let selectedRow = this.state.selectedLabsRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labs.length) {
            selectedRow = this.state.labs.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labs];
            if (newItems.length === 1) {
                const _newItems = [...this.state.labs];
                const firstObject = _newItems[0];

                const newObject = {};
                Object.keys(firstObject).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObject[t] = 0;
                    } else {
                        newObject[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObject);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.labsItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.labsItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            return { labs: newItems, labsItemFields: newFields, selectedLabsRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    hideConfirmationToAddNew = (event, newPhysVal) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        if (event.target.value === "yes") {
            this.props.addNewItemIsNotExist(newPhysVal);
        } else {
            var index = this.state.selectedLettersMvRowIndex;
            this.setState(prevState => {
                const newItems = [...prevState.letters];
                newItems[index]["fuPhys"] = "";
                newItems[index]["fuPhysName"] = "";
                return { letters: newItems };
            });

            setTimeout(function () {
                document.getElementById("fuPhys" + index) ? document.getElementById("fuPhys" + index).focus() : '';
            }, 1000);
        }
        this.setState({ showConfirmationToAddNew: false, newPhysVal: "" });
        return false;
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "outcomesMv":
                    this.deleteOutcomesMvGrid("1");
                    break;
                case "letters":
                    this.deleteLettersGrid("1");
                    break;
                case "recurrences":
                    this.deleteRecurrencesGrid("1");
                    break;
                case "labs":
                    this.deleteLabsGrid("1");
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    insertNewOutcomesMvGrid = (e, _insertedRow) => {
        var insertedRow = this.state.selectedOutMvRowIndex;
        this.setState(prevState => {
            const newItems = [...this.state.outcomesMv];
            const firstOutcomes = newItems[0];

            const newOutcomes = {};
            Object.keys(firstOutcomes).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newOutcomes[t] = 0;
                } else {
                    newOutcomes[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newOutcomes);

            // 
            const newFields = [...this.state.outcomesMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            setTimeout(function () {
                var selectedIndex = e.target.getAttribute("data-index");
                document.getElementById('dtLstCont' + selectedIndex) ? document.getElementById('dtLstCont' + selectedIndex).focus() : "";
                return false;
            }, 100);

            return {
                outcomesMv: newItems,
                outcomesMvItemFields: newFields,
                selectedOutMvRowIndex: insertedRow,
            };
        });

        return false;
    }

    // Add new function for #PR00415 - START
    addEventListenerFnc = (om) => {
        //var outcomesMvGrid = document.getElementById('outcomesMvListArea');
        document.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedOutMvRowIndex;
                om.insertNewOutcomesMvGrid(e, insertedRowIdx);
                return false;
            }
            return false;
        });

        setTimeout(function () {
            var selectedIndex = 0;
            document.getElementById('dtLstCont' + selectedIndex) ? document.getElementById('dtLstCont' + selectedIndex).focus() : "";
            return false;
        }, 1000);

        return false;
    }
    //END

    componentDidMount() {
        if (!this.state.outcomesForm && this.props.selectedOutcomesRecord) {
            this.setFormData();
        }

        this.setState({ tryToReloadingTimes: 0, setEventHandlerForGrid: false });

        // Make sure close popup when initial page
        this.props.closeAddNewDialog();
    }

    /*
    //#PR00415
    componentWillMount() {
        this.setState({ setEventHandlerForGrid: false });
    }
    componentWillUnmount() {
        this.setState({ setEventHandlerForGrid: false });
    }
    */

    componentDidUpdate(prevProps) {
        if (!this.state.setEventHandlerForGrid) {
            if (document.getElementById('outcomesMvListArea')) {
                this.setState({ setEventHandlerForGrid: true });
                this.addEventListenerFnc(this);
                setTimeout(function () {
                    var selectedIndex = 0;
                    document.getElementById('dtLstCont' + selectedIndex) ? document.getElementById('dtLstCont' + selectedIndex).focus() : "";
                    return false;
                }, 500);
                return false;
            }
        }

        if ((!this.state.outcomesForm && this.props.selectedOutcomesRecord)
            || (prevProps.selectedOutcomesRecord && prevProps.selectedOutcomesRecord !== this.props.selectedOutcomesRecord)
            || (this.props.initialAbstractPages && this.props.selectedOutcomesRecord)
        ) {
            this.setFormData();
        }

        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            axios.post(this.props.clientgroup + "/GetUserInitials", { uid: logedOnUser, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                        } else {
                            userInitials = logedOnUser.substr(0, 20);
                        }
                        this.setState({ userInitials: userInitials });
                    } else {
                        let userInitials = logedOnUser.substr(0, 20);
                        this.setState({ userInitials: userInitials });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        // OutcomesMv List
        if (this.props.selectedPrimarySummary && !this.state.outcomesMv && !this.state.outcomesMvLoading) {
            this.setState({ outcomesMvLoading: true });
            var ocm = this;
            axios.post(this.props.clientgroup + "/GetOutcomesMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let outcomesArr = [];
                        res.data.outcomesMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                let value = obj[t];
                                if (t === "dtLstCont" && value !== null) {
                                    let thisDate = ocm.Outcomes_Date_Format(value.replace(/[^0-9-/]/gi, ''));
                                    if (thisDate != value) {
                                        fields[t] = moment(new Date(thisDate)).format("MM/DD/YYYY");
                                    } else {
                                        fields[t] = value;
                                    }
                                } else {
                                    fields[t] = value;
                                }
                            });
                            outcomesArr.push(fields);
                        });
                        this.setState({ outcomesMv: outcomesArr });

                        //
                        let maxLengthItem = [];//#1
                        if (!this.state.outcomesMvItemFields && res.data.outcomesMvList) {
                            let _outcomesArr = [];
                            res.data.outcomesMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                _outcomesArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            this.setState({ outcomesMvItemFields: _outcomesArr });
                        };

                        // get maxlength for outcomeMv grid
                        axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Outcomes_Mv", accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;
                                    //let maxLengthItem = outcomesArr && outcomesArr[0] ? outcomesArr[0] : [];//#1

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    this.setState({
                                        outMvMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                                return false;
                            })
                            .catch(function (error) {
                                console.log(error);
                                /*
                                //redirect to Login page by clicking the Logout button
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                //@TODO:...

                                return false;
                            });

                        //
                        setTimeout(function () {
                            document.getElementById("dtLstCont0") ? document.getElementById("dtLstCont0").focus() : '';

                            ocm.setState({
                                selectedOutMvRowIndex: 0,
                                //setEventHandlerForGrid: false
                            });//#PR00415

                            return false;
                        }, 100);
                    }
                    return false;
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
            return false;
        }
        // LettersMv List
        if (this.props.selectedPrimarySummary && !this.state.letters && (!this.state.lettersLoading || this.state.tryToReloadingTimes <= 3)) {
            this.setState({ lettersLoading: true, tryToReloadingTimes: this.state.tryToReloadingTimes + 1 });
            var _this = this;
            axios.post(this.props.clientgroup + "/GetLettersMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        var lettersMvList = res.data.lettersMvList;
                        let lettersMvArr = [];
                        //
                        let maxLengthItem = [];//#2
                        lettersMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                fields[t] = "";
                            });
                            lettersMvArr.push(fields);
                            maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#2
                        });

                        // get maxlength for lettersMv grid
                        axios.post(_this.props.clientgroup + "/GetTableStructure", { table: "Letters_Mv", accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                                /*
                                //redirect to Login page by clicking the Logout button
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                //@TODO:...

                                return false;
                            });

                        this.setState({
                            letters: lettersMvList,
                            lettersMvItemFields: lettersMvArr,
                            lettersMvMaxlengthInput: { ...maxLengthItem },
                            tryToReloadingTimes: 4
                        });
                    }
                });
        }
        // LettersLogMv List
        if (this.props.selectedPrimarySummary && !this.state.lettersLog && !this.state.lettersLogLoading) {
            this.setState({ lettersLogLoading: true });
            axios.post(this.props.clientgroup + "/GetLettersLogMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ lettersLog: res.data.lettersLogMvList });

                        //
                        //let maxLengthItem = [];//#3
                        if (!this.state.lettersLogItemFields && res.data.lettersLogMvList) {
                            let lettersLogArr = [];
                            res.data.lettersLogMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                lettersLogArr.push(fields);
                                //maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#3
                            });

                            this.setState({ lettersLogItemFields: lettersLogArr });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
        // RecurrenceMv List
        if (this.props.selectedPrimarySummary && !this.state.recurrences && !this.state.recurrencesLoading) {
            this.setState({ recurrencesLoading: true });
            axios.post(this.props.clientgroup + "/GetRecurrenceMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    if (!res.data.error) {
                        this.setState({ recurrences: res.data.recurrenceMvList });

                        //
                        let maxLengthItem = [];//#4
                        if (!this.state.recurrenceItemFields && res.data.recurrenceMvList) {
                            let recurrencesArr = [];
                            res.data.recurrenceMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                recurrencesArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#4
                            });

                            this.setState({ recurrenceItemFields: recurrencesArr });

                            // get maxlength for recurrenceMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Recurrence_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        //let maxLengthItem = outcomesArr && outcomesArr[0] ? outcomesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            recurrenceMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    //redirect to Login page by clicking the Logout button
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                });
        }
        // LabsMv List
        if (this.props.selectedPrimarySummary && !this.state.labs && !this.state.labsLoading) {
            this.setState({ labsLoading: true });
            axios.post(this.props.clientgroup + "/GetLabsMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ labs: res.data.labsMvList });

                        //
                        let maxLengthItem = [];//#5
                        if (!this.state.labsItemFields && res.data.labsMvList) {
                            let labsArr = [];
                            res.data.labsMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                labsArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#5
                            });

                            this.setState({ labsItemFields: labsArr });

                            // get maxlength for LabsMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Labs_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        //let maxLengthItem = outcomesArr && outcomesArr[0] ? outcomesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            labsMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    //redirect to Login page by clicking the Logout button
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField !== null && this.props.commonLookupItem
            && this.state.outcomesForm && this.state.dAllItemFields
        ) {
            console.log("[OutcomesMv] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // OutcomesMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.outcomesMvItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { outcomesMvItemFields: newItems };
                    });

                    // LettersMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.lettersMvItemFields];
                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }

                        const lettersMv = [...prevState.letters];
                        if (lettersMv[this.props.currentSelectedIndex]) {
                            lettersMv[this.props.currentSelectedIndex][this.props.currentSelectedField + "Name"] = this.props.commonLookupItem.name;
                        }

                        return { lettersMvItemFields: newItems, letters: lettersMv };
                    });

                    // LettersLogMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.lettersLogItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { lettersLogItemFields: newItems };
                    });
                    // RecurrenceMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.recurrenceItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { recurrenceItemFields: newItems };
                    });
                    // LabsMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labsItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { labsItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        outcomesForm: { ...this.state.outcomesForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex != -1) {
                    // OutcomesMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.outcomesMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { outcomesMvItemFields: newItems };
                    });
                    // LettersMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.lettersMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { lettersMvItemFields: newItems };
                    });

                    //--------------------------------------------------------------------------------------------------
                    // LettersLogMv grid
                    if (this.props.currentSelectedField === "fuPhys"
                        && this.state.newPhysVal && this.state.newPhysVal.toUpperCase() !== "PATIENT"
                        && this.state.newPhysVal.toUpperCase() !== "CONTACT") {
                        /*
                        var result = alert("Physician does not exist. Choose OK to add a new physician.");
                        if (!result) return false;
                        //
                        this.props.addNewItem(this.props.commonLookupItem.code);
                        */
                        let value = this.props.commonLookupItem.code;
                        let index = this.props.currentSelectedIndex;
                        this.setState({ showConfirmationToAddNew: true, /*newPhysVal: value,*/ selectedLettersMvRowIndex: index });
                    } else {
                        this.setState(prevState => {
                            const newItems = [...prevState.lettersLogItemFields];

                            if (newItems && newItems[this.props.currentSelectedIndex]) {
                                newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                            }
                            return { lettersLogItemFields: newItems };
                        });
                    }
                    //--------------------------------------------------------------------------------------------------

                    // RecurrenceMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.recurrenceItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { recurrenceItemFields: newItems };
                    });
                    // LabsMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labsItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { labsItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        outcomesForm: { ...this.state.outcomesForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Outcomes", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    setFormData = () => {
        var outcomesForm = this.props.selectedOutcomesRecord;
        this.setState({
            outcomesForm: {
                ...outcomesForm,
                ["dateLastCancerStatus"]: (this.props.selectedOutcomesRecord != null && this.props.selectedOutcomesRecord.dateLastCancerStatus !== null && this.props.selectedOutcomesRecord.dateLastCancerStatus !== "" ? moment(new Date(this.props.selectedOutcomesRecord.dateLastCancerStatus)).format("MM/DD/YYYY") : "")
            },
            initialOutcomesForm: {
                ...outcomesForm,
            },
            isFormDirty: this.props.isFormDirty,

            primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : ""
        });

        if (this.props.selectedPrimarySummary) {
            this.setState({
                primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "",
                primSite: this.props.selectedMedicalRecord.topoCode ? this.props.selectedMedicalRecord.topoCode.substring(2) : "",
            });
        }

        if (!this.state.dAllItemFields && this.props.selectedOutcomesRecord) {//selectedPatient
            let fields = {};
            Object.keys(this.props.selectedOutcomesRecord).map((t) => {//selectedPatient
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "recurrenceItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.recurrenceItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { recurrenceItemFields: newItems };
            });
        } else if (onGrid && onGrid === "labsItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.labsItemFields];
                newItems[index]["labsDateYear"] = "";
                newItems[index]["labsDateMonth"] = "";
                newItems[index]["labsDateDay"] = "";
                return { labsItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "recurrenceItemList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.recurrenceItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { recurrenceItemFields: newItems };
                        });
                    } else if (onGrid && onGrid === "labsItemList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.labsItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { labsItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveOutcomes = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        /*
        const formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        /*
        // OutcomesMv grid
        if (isValid) {
            const pItems = this.state.outcomesMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // must check letterName field by duplicated in letters grid
                    if (field !== "letterName" && prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }
        * /

        // And more grids...
        if (isValid) {
            const pItems = this.state.lettersMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }
        if (isValid) {
            const pItems = this.state.recurrenceItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "recurDate" || field === "RecurDate") {
                        this.clearInvalidOfDateFields(prm, field, "recurrenceItemList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }
        if (isValid) {
            let pItems = this.state.labsItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "labsDate" || field === "LabsDate") {
                        this.clearInvalidOfDateFields(prm, field, "labsItemList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }
        */

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let isValidDateInput = true;

            let outcomesFormData = this.state.outcomesForm;

            let outcomesList = this.state.outcomesMv;
            let lettersList = this.state.letters;
            //let lettersLogList = this.state.lettersLog;
            let recurrencesList = this.state.recurrences;
            let labsList = this.state.labs;

            /*
            //#20210125 - START
            // append date fields from m/d/y
            Object.keys(outcomesFormData).map((t, k) => {
                if (t === "dateLastCancerStatus" || t === "DateLastCancerStatus") {
                    //outcomesFormData[t] = new Date(outcomesFormData["dateLastCancerStatusYear"] + "-" + outcomesFormData["dateLastCancerStatusMonth"] + "-" + outcomesFormData["dateLastCancerStatusDay"]);
                }
            });

            outcomesList.map((obj) => {
                Object.keys(obj).map((t) => {
                    if (t === "dtLstCont" || t === "DtLstCont") {
                        obj["dtLstCont"] = new Date(obj["dtLstContYear"] + "-" + obj["dtLstContMonth"] + "-" + obj["dtLstContDay"]);
                    } /*else if (t == "fuEntered" || t == "FuEntered") {
                        obj["fuEntered"] = new Date(obj["fuEnteredDay"] + "-" + obj["fuEnteredMonth"] + "-" + obj["fuEnteredYear"]);
                    }* /
                });
            });
            * /

            recurrencesList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t == "recurDate" || t == "RecurDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "recurrenceItemList", index);
                    }
                });
            });
            labsList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t == "labsDate" || t == "LabsDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "labsItemList", index);
                    }
                });
            });
            //#20210125 - END
            */

            if (!isValidDateInput && !allowSaveAnyway) return false;

            outcomesFormData.outcomesMvList = outcomesList;
            outcomesFormData.lettersMvList = lettersList;
            //outcomesFormData.lettersLogMvList = lettersLogList;
            outcomesFormData.recurrenceMvList = recurrencesList;
            outcomesFormData.labsMvList = labsList;

            if (this.props.selectedPrimarySummary && (!outcomesFormData.cid || outcomesFormData.cid == 0)) {
                outcomesFormData.cid = this.props.selectedPrimarySummary.cid;
            }

            if (!this.state.reloadedDefaultTemplate) {
                outcomesFormData.reloadLettersMvList = "1";
            } else {
                outcomesFormData.reloadLettersMvList = "0";
            }

            this.props.saveOutcomes(outcomesFormData);
            this.setState({ isFormDirty: false });

            return false;
        }
    }

    resetOutcomes = () => {
        this.setState({
            outcomesForm: { ...this.state.initialOutcomesForm },
            isFormDirty: false,

            outcomesMv: null,
            outcomesMvItemFields: null,
            outcomesMvLoading: false,
            selectedOutMvRowIndex: -1,

            letters: null,
            lettersMvItemFields: null,
            lettersLoading: false,
            selectedLettersMvRowIndex: -1,

            lettersLog: null,
            lettersLogItemFields: null,
            lettersLogLoading: false,

            recurrences: null,
            recurrenceItemFields: null,
            recurrencesLoading: false,
            selectedRecurrencesRowIndex: -1,

            labs: null,
            labsItemFields: null,
            labsLoading: false,
            selectedLabsRowIndex: -1,
        });
        return false;
    }

    handleOnFocus = (event, index, alias) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        if (alias) {
            switch (alias) {
                case "selectedOutMvRowIndex":
                    this.setState({ selectedOutMvRowIndex: index });
                    break;
                case "selectedLettersMvRowIndex":
                    this.setState({ selectedLettersMvRowIndex: index });
                    break;
                case "selectedRecurrencesRowIndex":
                    this.setState({ selectedRecurrencesRowIndex: index });
                    break;
                case "selectedLabsRowIndex":
                    this.setState({ selectedLabsRowIndex: index });
                    break;
            }
        }
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        var fName = event.target.name;
        var fVal = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ outcomesForm: { ...this.state.outcomesForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            if (event.target._element && event.target._element.className === "k-widget k-datepicker" && event.target.dateInput._element.value !== "month/day/year") {
                if (event.target.dateInput._element.validity.valid) {
                    //this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
                }
            } else {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
        }

        if (fName === "followUpTemplate" && fVal !== null && fVal !== "") {
            var parentOfIt = event.target.parentNode;
            var bkChangedVal = parentOfIt.getAttribute("data-bkval");

            var reloadTmpl = false;
            if (bkChangedVal === null || bkChangedVal !== fVal) {
                reloadTmpl = true;
            }

            if (!reloadTmpl) return false;
            else parentOfIt.setAttribute("data-bkval", fVal);

            let tmplt = event.target.value;
            // Set_FollowUp_Order
            axios.post(this.props.clientgroup + "/SetFollowUpOrder", { cId: this.props.selectedPrimarySummary.cid, template: tmplt, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        var lettersMvList = res.data.lettersMvList;
                        let lettersMvArr = [];
                        lettersMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                fields[t] = "";
                            });
                            lettersMvArr.push(fields);
                        });
                        this.setState({
                            letters: lettersMvList,
                            lettersMvItemFields: lettersMvArr,
                            reloadedDefaultTemplate: true,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        } else if (fName === "dateLastCancerStatus") {
            let thisValue = event.target.value;
            let thisDate = this.Outcomes_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    outcomesForm: {
                        ...this.state.outcomesForm,
                        [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }

        return false;
    }

    handleOutcomesLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.outcomesMv];
                        newItems[index][name] = value;
                        return { outcomesMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.outcomesMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { outcomesMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.outcomesMvItemFields];
                    newItems[index][name] = "";
                    return { outcomesMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.outcomesMvItemFields];
                newItems[index][name] = "";
                return { outcomesMvItemFields: newItems };
            });
        }

        //
        if (name === "dtLstCont") {
            var thisDate = this.Outcomes_Date_Format(value.replace(/[^0-9-/]/gi, ''));
            if (thisDate != value) {
                //{ dialog.Object }.setValue('Dt_Lst_Cont:' + thisRow, thisDate);
                this.setState(prevState => {
                    const newItems = [...prevState.outcomesMv];
                    newItems[index][name] = thisDate;
                    return { outcomesMv: newItems };
                });
            }
        }

        return false;
    }

    handleLettersLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            var thisContact = event.target.value;
            if (name === "fuPhys") {
                if (thisContact.toUpperCase() === "9999PAT" || thisContact.toUpperCase() === "PATIENT" || thisContact.substr(0, 3).toUpperCase() === "PAT") {
                    //{ dialog.Object }.setValue('Fu_Phys_Name:' + thisRow, 'Patient');
                    this.setState(prevState => {
                        const newItems = [...prevState.letters];
                        newItems[index][name] = thisContact.toUpperCase() === "9999PAT" ? "Patient" : thisContact;
                        newItems[index]["fuPhysName"] = "Patient";
                        return { letters: newItems };
                    });
                } else if (thisContact.substr(0, 7).toUpperCase() === "9999CON" || thisContact.toUpperCase() === "CONTACT" || thisContact.substr(0, 3).toUpperCase() === "CON") {
                    if (thisContact.substr(-1, 1) === '2' || thisContact.substr(-1, 1) === '3' || thisContact.substr(-1, 1) === '4') {
                        //{ dialog.Object }.setValue('Fu_Phys_Name:' + thisRow, 'Secondary Contact ' + thisContact.substr(-1, 1));
                        this.setState(prevState => {
                            const newItems = [...prevState.letters];
                            newItems[index][name] = thisContact.substr(0, 7).toUpperCase() === "9999CON" ? "Contact" : thisContact;
                            newItems[index]["fuPhysName"] = "Secondary Contact " + thisContact.substr(-1, 1);
                            return { letters: newItems };
                        });
                    } else {
                        //{ dialog.Object }.setValue('Fu_Phys_Name:' + thisRow, 'Secondary Contact 1');
                        this.setState(prevState => {
                            const newItems = [...prevState.letters];
                            newItems[index][name] = thisContact.substr(0, 7).toUpperCase() === "9999CON" ? "Contact" : thisContact;
                            newItems[index]["fuPhysName"] = "Secondary Contact 1";
                            return { letters: newItems };
                        });
                    }
                } else {
                    //{ Dialog.Object }.ajaxCallback('', '', 'Lookup_Phys_Name', '', ''); //Only look up physician name if it's an actual physician and not a Patient or Contact
                    //#PR00418
                    const lettersMv = [...this.state.letters];
                    if (lettersMv[index] && lettersMv[index][name + "Name"] && lettersMv[index][name + "Name"] != "") {
                        return false;
                    }
                    this.props.checkValidInput(name, value, table, key, index);
                }

                this.setState({ newPhysVal: value });
            } else {
                this.props.checkValidInput(name, value, table, key, index);
            }
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.lettersMvItemFields];
                newItems[index][name] = "";
                return { lettersMvItemFields: newItems };
            });
        }

        /*
        var thisContact = event.target.value;
        if (name === "fuPhys") {
            if (thisContact.toUpperCase() === "9999PAT" || thisContact.substr(0, 3).toUpperCase() === "PAT") {
                //{ dialog.Object }.setValue('Fu_Phys_Name:' + thisRow, 'Patient');
                this.setState(prevState => {
                    const newItems = [...prevState.lettersMvItemFields];
                    newItems[index][name] = thisContact.toUpperCase() === "9999PAT" ? "Patient" : thisContact;
                    newItems[index]["fuPhysName"] = "Patient";
                    return { lettersMvItemFields: newItems };
                });
            } else if (thisContact.substr(0, 7).toUpperCase() === "9999CON" || thisContact.substr(0, 3).toUpperCase() === "CON") {
                if (thisContact.substr(-1, 1) === '2' || thisContact.substr(-1, 1) === '3' || thisContact.substr(-1, 1) === '4') {
                    //{ dialog.Object }.setValue('Fu_Phys_Name:' + thisRow, 'Secondary Contact ' + thisContact.substr(-1, 1));
                    this.setState(prevState => {
                        const newItems = [...prevState.lettersMvItemFields];
                        newItems[index][name] = thisContact.substr(0, 7).toUpperCase() === "9999CON" ? "Contact" : thisContact;
                        newItems[index]["fuPhysName"] = "Secondary Contact " + thisContact.substr(-1, 1);
                        return { lettersMvItemFields: newItems };
                    });
                } else {
                    //{ dialog.Object }.setValue('Fu_Phys_Name:' + thisRow, 'Secondary Contact 1');
                    this.setState(prevState => {
                        const newItems = [...prevState.lettersMvItemFields];
                        newItems[index][name] = thisContact.substr(0, 7).toUpperCase() === "9999CON" ? "Contact" : thisContact;
                        newItems[index]["fuPhysName"] = "Secondary Contact 1";
                        return { lettersMvItemFields: newItems };
                    });
                }
            } else {
                //{ Dialog.Object }.ajaxCallback('', '', 'Lookup_Phys_Name', '', ''); //Only look up physician name if it's an actual physician and not a Patient or Contact
            }
        }
        */

        return false;
    }
    // --END

    Outcomes_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ outcomesForm: { ...this.state.outcomesForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({
                outcomesForm: { ...this.state.outcomesForm, [event.target.name]: event.target.value },
                isFormDirty: true,
                reloadedDefaultTemplate: event.target.name == "followUpTemplate" ? false : this.state.reloadedDefaultTemplate,
            });
        }

        return false;
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });

        return false;
    }

    // Outcomes_Mv grid
    handleOutcomesGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        // Fixing #PR00287
        if (name === "dtLstCont" && value !== ""
            //&& (this.state.outcomesMv[index]["fuEnteredBy"] === null || this.state.outcomesMv[index]["fuEnteredBy"] === "")
        ) {
            const enteredBy = this.state.userInitials;

            this.setState(prevState => {
                const newItems = [...prevState.outcomesMv];
                newItems[index][name] = value;
                newItems[index]["fuEntered"] = moment(new Date()).format("MM/DD/YYYY");
                newItems[index]["fuEnteredBy"] = enteredBy;

                return { outcomesMv: newItems, isFormDirty: true };
            });

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.outcomesMv];
                newItems[index][name] = value;
                return { outcomesMv: newItems, isFormDirty: true };
            });
        }

        return false;
    }

    addNewOutcomesMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.outcomesMv];
            const firstOutcomes = newItems[0];

            const newOutcomes = {};
            Object.keys(firstOutcomes).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newOutcomes[t] = 0;
                } else {
                    newOutcomes[t] = "";
                }
            });
            newItems.push(newOutcomes);

            // 
            const newFields = [...this.state.outcomesMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { outcomesMv: newItems, outcomesMvItemFields: newFields };
        });
    }

    deleteSelectedOutcomesMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedOutMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.outcomesMv.length) {
            selectedRow = this.state.outcomesMv.length - 1;
        }

        if (this.state.outcomesMv[selectedRow] && this.state.outcomesMv[selectedRow]["id"] !== undefined && this.state.outcomesMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "outcomesMv" });
        } else {
            this.deleteOutcomesMvGrid("0");
        }
        return false;
    }

    //--
    // Letters grid
    handleLettersGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        let name2 = null;
        let value2 = null;

        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.letters];

            newItems[index][name] = value;
            if (name2 && value2) {
                newItems[index][name2] = value2;
            }

            return { letters: newItems, isFormDirty: true };
        });
        return false;
    }

    addNewLetters = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.letters];
            const firstObject = newItems[0];

            const newObject = {};
            Object.keys(firstObject).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObject[t] = 0;
                } else {
                    newObject[t] = "";
                }
            });
            newItems.push(newObject);

            // 
            const newFields = [...this.state.lettersMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { letters: newItems, lettersMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedLetters = (event) => {
        event.preventDefault();
        let selectedRow = this.state.selectedLettersMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.letters.length) {
            selectedRow = this.state.letters.length - 1;
        }

        if (this.state.letters[selectedRow] && this.state.letters[selectedRow]["id"] !== undefined && this.state.letters[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "letters" });
        } else {
            this.deleteLettersGrid("0");
        }
        return false;
    }

    // Recurrences grid
    handleRecurrencesGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.recurrences];
            newItems[index][name] = value;
            return { recurrences: newItems, isFormDirty: true };
        });
        return false;
    }

    handleRecurrencesLostFocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.recurrences];
                        newItems[index][name] = value;
                        return { recurrences: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.recurrenceItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { recurrenceItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.recurrenceItemFields];
                    newItems[index][name] = "";
                    return { recurrenceItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.recurrenceItemFields];
                newItems[index][name] = "";
                return { recurrenceItemFields: newItems };
            });
        }
        return false;
    }

    addNewRecurrences = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.recurrences];
            const firstObject = newItems[0];

            const newObject = {};
            Object.keys(firstObject).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObject[t] = 0;
                } else {
                    newObject[t] = "";
                }
            });
            newItems.push(newObject);

            // 
            const newFields = [...this.state.recurrenceItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { recurrences: newItems, recurrenceItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedRecurrences = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedRecurrencesRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.recurrences.length) {
            selectedRow = this.state.recurrences.length - 1;
        }

        if (this.state.recurrences[selectedRow] && this.state.recurrences[selectedRow]["id"] !== undefined && this.state.recurrences[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "recurrences" });
        } else {
            this.deleteRecurrencesGrid("0");
        }
        return false;
    }

    // Labs grid
    handleLabsGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.labs];
            newItems[index][name] = value;
            return { labs: newItems, isFormDirty: true };
        });
        return false;
    }

    handleLabsLostFocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.labs];
                        newItems[index][name] = value;
                        return { labs: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.labsItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { labsItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.labsItemFields];
                    newItems[index][name] = "";
                    return { labsItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.labsItemFields];
                newItems[index][name] = "";
                return { labsItemFields: newItems };
            });
        }
        return false;
    }

    addNewLabs = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.labs];
            const firstObject = newItems[0];

            const newObject = {};
            Object.keys(firstObject).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObject[t] = 0;
                } else {
                    newObject[t] = "";
                }
            });
            newItems.push(newObject);

            // 
            const newFields = [...this.state.labsItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { labs: newItems, labsItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedLabs = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedLabsRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labs.length) {
            selectedRow = this.state.labs.length - 1;
        }

        if (this.state.labs[selectedRow] && this.state.labs[selectedRow]["id"] !== undefined && this.state.labs[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "labs" });
        } else {
            this.deleteLabsGrid("0");
        }
        return false;
    }
    //--

    closeAddNewDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeAddNewDialog();

        return false;
    }

    render() {
        let showConfirmationToAddNew;
        {
            showConfirmationToAddNew = this.state.showConfirmationToAddNew ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".dragableSmallWindowCls">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationToAddNew(e, this.state.newPhysVal) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Physician does not exist. Choose OK to add a new physician.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.hideConfirmationToAddNew(e, this.state.newPhysVal) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.hideConfirmationToAddNew(e, this.state.newPhysVal) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".dragableSmallWindowCls">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const OutcomesMaintenance = this.state.outcomesForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveOutcomes} id="outcomesForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp} name="btnHelp">
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div style={{ display: "flex" }}>
                            <div className="A5CWLayout TreatmentScreen">
                                <div className="frameInner" style={{ width: "790px" }}>
                                    <div className="header">&nbsp;</div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <fieldset>
                                                <legend>Outcomes</legend>
                                                <div id="outcomesMvListArea" className="outcomesMvListArea" onFocus={(e) => { this.setOutcomesMvGridFocusing(true) }}>
                                                    <div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "115px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Date Last Contact" field="dtLstCont"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Pt St" field="ptStatus"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "50px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Ca st" field="caStatus"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "50px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="QL" field="ql"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "195px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Fu Notes" field="fuNotes"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "50px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Source" field="source"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "95px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Entered" field="fuEntered"
                                                                        />
                                                                    </th>
                                                                    <th style={{ width: "140px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Entered By" field="fuEnteredBy"
                                                                        />
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody></tbody>
                                                        </table>
                                                    </div>
                                                    <div className="innerBody" style={{ width: "750px", height: "150px", overflow: "auto" }}>
                                                        <table onFocus={(e) => { this.setOutcomesMvGridFocusing(true) }} onClick={(e) => { this.setOutcomesMvGridFocusing(true) }}>
                                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th style={{ width: "18px" }}>&nbsp;</th>
                                                                    <th style={{ width: "115px" }}>Date Last Contact</th>
                                                                    <th style={{ width: "55px" }}>Pt St</th>
                                                                    <th style={{ width: "50px" }}>Ca st</th>
                                                                    <th style={{ width: "50px" }}>QL</th>
                                                                    <th style={{ width: "195px" }}>Fu Notes</th>
                                                                    <th style={{ width: "50px" }}>Source</th>
                                                                    <th style={{ width: "95px" }}>Entered</th>
                                                                    <th style={{ width: "140px" }}>Entered By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.outcomesMvItemFields
                                                                    && this.state.outcomesMv !== null && this.state.outcomesMv.length > 0
                                                                    ? this.state.outcomesMv.map((prm, index) => (
                                                                        <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionOutMvRow(e, index) }} className={this.state.selectedOutMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                            <td style={{ width: "18px" }}>
                                                                                {index + 1}
                                                                            </td>
                                                                            <td>
                                                                                <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedOutMvRowIndex") }}
                                                                                    changed={(e) => { this.handleOutcomesGridChange(e, index) }}
                                                                                    labelText="" field="dtLstCont" keyId={"dtLstCont" + index}
                                                                                    rowIndex={index} lineStyle="oneLine"

                                                                                    //value={prm.dtLstCont != null && prm.dtLstCont !== "" ? moment(new Date(prm.dtLstCont)).format("MM/DD/YYYY") : ""}
                                                                                    value={prm.dtLstCont} //autoFocus
                                                                                    placeholder="mm/dd/yyyy"

                                                                                    labelWidth="0px" width="110px"

                                                                                    maxLength="10"
                                                                                    //maxLength={this.state.outMvMaxlengthInput ? this.state.outMvMaxlengthInput["ptStatus"] : ""}

                                                                                    lostfocused={(e) => { this.handleOutcomesLostfocus(e, index) }} />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedOutMvRowIndex") }} labelWidth="0px"
                                                                                    changed={(e) => { this.handleOutcomesGridChange(e, index) }}
                                                                                    labelText="" field="ptStatus" value={prm.ptStatus}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Pt Status" aliasNo="30"
                                                                                    titleDialog="Pt Status" width="30px" lineStyle="oneLine"
                                                                                    inValid={this.state.outcomesMvItemFields[index]["ptStatus"]}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.outMvMaxlengthInput ? this.state.outMvMaxlengthInput["ptStatus"] : ""}
                                                                                    lostfocused={(e) => { this.handleOutcomesLostfocus(e, index) }}
                                                                                    keyId={"ptStatus" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedOutMvRowIndex") }} labelWidth="0px"
                                                                                    changed={(e) => { this.handleOutcomesGridChange(e, index) }}
                                                                                    labelText="" field="caStatus" value={prm.caStatus} lineStyle="oneLine"
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Ca Status" aliasNo="34" width="30px"
                                                                                    inValid={this.state.outcomesMvItemFields[index]["caStatus"]}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.outMvMaxlengthInput ? this.state.outMvMaxlengthInput["caStatus"] : ""}
                                                                                    lostfocused={(e) => { this.handleOutcomesLostfocus(e, index) }}
                                                                                    keyId={"caStatus" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedOutMvRowIndex") }} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handleOutcomesGridChange(e, index) }}
                                                                                    labelText="" field="ql" value={prm.ql} labelWidth="0px"
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Ql" aliasNo="29" width="30px"
                                                                                    inValid={this.state.outcomesMvItemFields[index]["ql"]}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.outMvMaxlengthInput ? this.state.outMvMaxlengthInput["ql"] : ""}
                                                                                    lostfocused={(e) => { this.handleOutcomesLostfocus(e, index) }}
                                                                                    keyId={"ql" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedOutMvRowIndex") }} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handleOutcomesGridChange(e, index) }} labelWidth="0px"
                                                                                    labelText="" field="fuNotes" value={prm.fuNotes} width="190px"
                                                                                    //maxLength=""
                                                                                    maxLength={this.state.outMvMaxlengthInput ? this.state.outMvMaxlengthInput["fuNotes"] : ""}
                                                                                    rowIndex={index}
                                                                                    lostfocused={(e) => { this.handleOutcomesLostfocus(e, index) }} />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedOutMvRowIndex") }} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handleOutcomesGridChange(e, index) }}
                                                                                    labelText="" field="source" value={prm.source} labelWidth="0px"
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Source" aliasNo="36" width="30px"
                                                                                    inValid={this.state.outcomesMvItemFields[index]["source"]}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.outMvMaxlengthInput ? this.state.outMvMaxlengthInput["source"] : ""}
                                                                                    lostfocused={(e) => { this.handleOutcomesLostfocus(e, index) }}
                                                                                    keyId={"source" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelText="" field="fuEntered" value={prm.fuEntered != null && prm.fuEntered !== "" ? moment(new Date(prm.fuEntered)).format("MM/DD/YYYY") : ""}
                                                                                    width="80px" labelWidth="0px" />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelText="" field="fuEnteredBy" value={prm.fuEnteredBy}
                                                                                    width="105px" labelWidth="0px" />
                                                                            </td>
                                                                        </tr>
                                                                    )) : <tr><td colSpan="9">No records available</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="contactBtnArea right">
                                                        <button onClick={this.addNewOutcomesMv}>Add</button>
                                                        <button onClick={this.deleteSelectedOutcomesMv}>Delete</button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div className="emptyLine"></div>

                                    <div style={{ display: "flex" }}>
                                        <div className="">
                                            <fieldset style={{ /*height: "178px",*/ width: "540px" }}>
                                                <legend>Follow-Up Physicians and Enhanced Follow-Up Order</legend>
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "18px" }}>&nbsp;</th>
                                                                <th style={{ width: "100px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Fu Physician" field="fuPhys"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "120px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Fu Physician Name" field="fuPhysName"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "90px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Letter Sent" field="ltrSent"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "130px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Letter Type" field="letterName"
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody></tbody>
                                                    </table>
                                                </div>
                                                <div id="lettersListArea" className="lettersListArea">
                                                    <div id="pFollowUpOrder" style={{ display: "flex" }}>
                                                        <div className="lettersChildrenArea innerBody" style={{ height: "93px", overflow: "auto" }}>
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "110px" }}>Fu Physician</th>
                                                                        <th style={{ width: "120px" }}>Fu Physician Name</th>
                                                                        <th style={{ width: "110px" }}>Letter Sent</th>
                                                                        <th style={{ width: "125px" }}>Letter Type</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.lettersMvItemFields
                                                                        && this.state.letters !== null && this.state.letters.length > 0
                                                                        ? this.state.letters.map((prm, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionLettersMvRow(e, index) }} className={this.state.selectedLettersMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "18px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="Physician" focused={(e) => { this.handleOnFocus(e, index, "selectedLettersMvRowIndex") }}
                                                                                        changed={(e) => { this.handleLettersGridChange(e, index) }}
                                                                                        labelText="" field="fuPhys" value={prm.fuPhys} lineStyle="oneLine"
                                                                                        dataSource="Physicians" titleDialog="F/U Physicians"
                                                                                        width="90px" labelWidth="0px"
                                                                                        inValid={this.state.lettersMvItemFields[index]["fuPhys"]}
                                                                                        //maxLength="15"
                                                                                        maxLength={this.state.lettersMvMaxlengthInput ? this.state.lettersMvMaxlengthInput["fuPhys"] : ""}
                                                                                        lostfocused={(e) => { this.handleLettersLostfocus(e, index) }}
                                                                                        keyId={"fuPhys" + index}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        labelText="" field="fuPhysName" value={prm.fuPhysName} labelMainStyle="cellOnGrid"
                                                                                        width="125px" labelWidth="0px" />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                        labelText="" field="ltrSent"
                                                                                        value={prm.ltrSent != null && prm.ltrSent !== "" ? moment(new Date(prm.ltrSent)).format("MM/DD/YYYY") : ""}
                                                                                        width="80px" labelWidth="0px" />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLettersMvRowIndex") }}
                                                                                        changed={(e) => { this.handleLettersGridChange(e, index) }}
                                                                                        labelText="" field="letterName" value={prm.letterName}
                                                                                        dataSource="Letters" titleDialog="Choose Follow-Up Letter"
                                                                                        width="105px" labelWidth="0px"
                                                                                        inValid={this.state.lettersMvItemFields[index]["letterName"]}
                                                                                        //maxLength="30"
                                                                                        maxLength={this.state.lettersMvMaxlengthInput ? this.state.lettersMvMaxlengthInput["letterName"] : ""}
                                                                                        aliasNo="1031" itemNbr=""
                                                                                        //lostfocused={(e) => { this.handleLettersLostfocus(e, index) }}
                                                                                        keyId={"letterName" + index}
                                                                                        rowIndex={index} lineStyle="oneLine"
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="5">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="resorterCls">
                                                            <img src="https://crstar.ers-can.com/images/$$navigate.move.up.png.a5image" onClick={this.moveUp} id="btnMoveUpLetter" /><br />
                                                            <img src="https://crstar.ers-can.com/images/$$navigate.move.down.png.a5image" onClick={this.moveDown} id="btnMoveDownLetter" />
                                                        </div>
                                                    </div>
                                                    <div className="contactBtnArea right">
                                                        <button onClick={this.addNewLetters}>Add</button>
                                                        <button onClick={this.deleteSelectedLetters}>Delete</button>
                                                    </div>

                                                    <div style={{ marginTop: "-15px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Default Letter Types Based On" field="followUpTemplate" lineStyle="oneLine"
                                                            value={this.state.outcomesForm.followUpTemplate ? this.state.outcomesForm.followUpTemplate : this.state.outcomesForm.FollowUpTemplate}
                                                            dataSource="Templates_FollowUp_Order" titleDialog="Follow-Up Order" labelStyle="normalStyleCls"
                                                            inValid={this.state.dAllItemFields["followUpTemplate"]}
                                                            labelWidth="180px" width="165px" itemNbr=""
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpTemplate"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                            keyId="followUpTemplate" //ignoreRapid: FOLLOWUP_TEMPLATE
                                                        />
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Date Cancer Status Last Changed" field="dateLastCancerStatus"

                                                        //value={this.state.outcomesForm.dateLastCancerStatus != null && this.state.outcomesForm.dateLastCancerStatus !== "" ? moment(new Date(this.state.outcomesForm.dateLastCancerStatus)).format("MM/DD/YYYY") : ""}
                                                        value={this.state.outcomesForm != null ? this.state.outcomesForm.dateLastCancerStatus : ""}
                                                        maxLength="10"

                                                        aliasNo="4346" labelWidth="140px" width="85px" lineStyle="oneLine" itemNbr="4346"
                                                        lostfocused={this.handleLostFocus}
                                                        placeholder="mm/dd/yyyy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="emptyLine"></div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <fieldset style={{ height: "158px" }}>
                                                        <legend>Letters</legend>
                                                        <div className="lettersChildrenArea innerBody">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                                        <th style={{ width: "80px" }}>
                                                                            <Input type="gridHeader" labelWidth="auto"
                                                                                labelText="Date Sent" field="dateSent"
                                                                            />
                                                                        </th>
                                                                        <th style={{ width: "90px" }}>
                                                                            <Input type="gridHeader" labelWidth="auto"
                                                                                labelText="Letter Type" field="letterType"
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.lettersLog !== null && this.state.lettersLog.length > 0
                                                                        ? this.state.lettersLog.map((prm, index) => (
                                                                            <tr key={"data-key-" + index}>
                                                                                <td style={{ width: "15px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="label" lineStyle="oneLine"
                                                                                        labelText="" field="dateSent"
                                                                                        value={prm.dateSent != null && prm.dateSent !== "" ? moment(new Date(prm.dateSent)).format("MM/DD/YYYY") : ""}
                                                                                        width="80px" labelWidth="0px"
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="label" lineStyle="oneLine"
                                                                                        labelText="" field="letterType" value={prm.letterType}
                                                                                        width="90px" labelWidth="0px" />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="A5CWLayout TreatmentScreen">
                                <div className="frameInner" style={{ width: "445px", height: "470px" }}>
                                    <div className="header">&nbsp;</div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine onTopFrame"
                                                labelWidth="95px" width="45px"
                                                labelText="Surv Months" field="survMths" value={this.state.outcomesForm.survMths ? this.state.outcomesForm.survMths : "          "} />
                                        </div>
                                        <div className="col-md-4">
                                            <Input type="input" focused={this.handleOnFocus}
                                                labelWidth="65px" width="55px"
                                                changed={this.handleFormChange} lineStyle="oneLine onTopFrame"
                                                labelText="Icd Cause" field="icdCause"
                                                value={this.state.outcomesForm.icdCause ? this.state.outcomesForm.icdCause : this.state.outcomesForm.IcdCause}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["icdCause"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelWidth="55px" width="35px" lineStyle="oneLine onTopFrame"
                                                labelText="Icd Rev" field="icdRev"
                                                value={this.state.outcomesForm.icdRev ? this.state.outcomesForm.icdRev : this.state.outcomesForm.IcdRev}
                                                dataSource="Global_Dir_Mv" titleDialog="Icd Rev" aliasNo="204"
                                                inValid={this.state.dAllItemFields["icdRev"]}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["icdRev"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                        </div>
                                    </div>
                                    <div className="" style={{ display: "flex" }}>
                                        <div className="">
                                            <fieldset style={{ width: "165px", height: "210px" }}>
                                                <legend>Outcomes</legend>
                                                <div className="outcomesListArea">
                                                    <div style={{ marginLeft: "-2px" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Method" field="method"
                                                            labelWidth="78px" width="40px"
                                                            value={this.state.outcomesForm.Method ? this.state.outcomesForm.Method : this.state.outcomesForm.method}
                                                            dataSource="Global_Dir_Mv" titleDialog="Method" aliasNo="31"
                                                            inValid={this.state.dAllItemFields["method"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["method"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelWidth="78px" width="40px"
                                                            labelText="Death Cause" field="deathCause"
                                                            value={this.state.outcomesForm.DeathCause ? this.state.outcomesForm.DeathCause : this.state.outcomesForm.deathCause}
                                                            dataSource="Global_Dir_Mv" titleDialog="Death Cause" aliasNo="32"
                                                            inValid={this.state.dAllItemFields["deathCause"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["deathCause"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelWidth="78px" width="40px"
                                                            labelText="Autopsy" field="autopsy"
                                                            value={this.state.outcomesForm.Autopsy ? this.state.outcomesForm.Autopsy : this.state.outcomesForm.autopsy}
                                                            dataSource="Global_Dir_Mv" titleDialog="Autopsy" aliasNo="375"
                                                            inValid={this.state.dAllItemFields["autopsy"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["autopsy"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelWidth="78px" width="40px"
                                                            labelText="Pod" field="pod"
                                                            value={this.state.outcomesForm.Pod ? this.state.outcomesForm.Pod : this.state.outcomesForm.pod}
                                                            dataSource="Global_Dir_Mv" titleDialog="Pod" aliasNo="376"
                                                            inValid={this.state.dAllItemFields["pod"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pod"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelWidth="78px" width="40px"
                                                            labelText="Pod-State" field="deathState"
                                                            value={this.state.outcomesForm.DeathState ? this.state.outcomesForm.DeathState : this.state.outcomesForm.deathState}
                                                            dataSource="State_Codes" titleDialog="Place of Death: State" aliasNo="4015"
                                                            inValid={this.state.dAllItemFields["deathState"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["deathState"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelWidth="78px" width="40px"
                                                            labelText="Pod-Country" field="deathCountry"
                                                            value={this.state.outcomesForm.DeathCountry ? this.state.outcomesForm.DeathCountry : this.state.outcomesForm.deathCountry}
                                                            dataSource="Country_Codes" titleDialog="Place of Death: Country" aliasNo="4031"
                                                            inValid={this.state.dAllItemFields["deathCountry"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["deathCountry"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div className="">
                                            <fieldset style={{ width: "265px", height: "210px" }}>
                                                <legend>Recurrence</legend>
                                                <div className="">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                <th style={{ width: "90px"/*"128px"*/ }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Recur Date" field="recurDate"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Type" field="recurType"
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody></tbody>
                                                    </table>
                                                </div>
                                                <div className="recurrencesListArea innerBody" style={{ maxHeight: "100px", overflow: "auto" }}>
                                                    <table>
                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                            <tr>
                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                <th style={{ width: "90px"/*"128px"*/ }}>Recur Date</th>
                                                                <th style={{ width: "60px" }}>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.recurrenceItemFields
                                                                && this.state.recurrences !== null && this.state.recurrences.length > 0
                                                                ? this.state.recurrences.map((prm, index) => (
                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionRecurrencesRow(e, index) }} className={this.state.selectedRecurrencesRowIndex === index ? "selectedRowCls" : ""}>
                                                                        <td style={{ width: "15px" }}>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td>
                                                                            <Input type="date" focused={(e) => { this.handleOnFocus(e, index, "selectedRecurrencesRowIndex") }}
                                                                                changed={(e) => { this.handleRecurrencesGridChange(e, index) }}
                                                                                labelText="" field="recurDate" lineStyle="oneLine"
                                                                                value={prm.recurDate != null && prm.recurDate !== "" ? moment(new Date(prm.recurDate)).format("MM/DD/YYYY") : ""}
                                                                                rowIndex={index}//
                                                                                //#20210125 - START
                                                                                // Add day/month/year values
                                                                                inValidDay={this.state.recurrenceItemFields[index]["recurDateDay"]}
                                                                                inValidMonth={this.state.recurrenceItemFields[index]["recurDateMonth"]}
                                                                                inValidYear={this.state.recurrenceItemFields[index]["recurDateYear"]}
                                                                                dayVal={prm.recurDateDay}
                                                                                monthVal={prm.recurDateMonth}
                                                                                yearVal={prm.recurDateYear}
                                                                                //#20210125 - END
                                                                                lostfocused={(e) => { this.handleRecurrencesLostFocus(e, index) }}
                                                                                monthWidth="33px" dayWidth="40px" yearWidth="50px" labelWidth="0px"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedRecurrencesRowIndex") }}
                                                                                changed={(e) => { this.handleRecurrencesGridChange(e, index) }}
                                                                                labelText="" field="recurType" value={prm.recurType}
                                                                                labelWidth="0px" width="30px"
                                                                                dataSource="Global_Dir_Mv" titleDialog="Recur Type" aliasNo="163"
                                                                                inValid={this.state.recurrenceItemFields[index]["recurType"]}
                                                                                //maxLength="2"
                                                                                maxLength={this.state.recurrenceMvMaxlengthInput ? this.state.recurrenceMvMaxlengthInput["recurType"] : ""}
                                                                                lostfocused={(e) => { this.handleRecurrencesLostFocus(e, index) }}
                                                                                keyId={"recurType" + index}
                                                                                rowIndex={index} lineStyle="oneLine"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan="3">No records available</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="contactBtnArea right">
                                                    <button onClick={this.addNewRecurrences}>Add</button>
                                                    <button onClick={this.deleteSelectedRecurrences}>Delete</button>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="emptyLine"></div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "15px" }}>&nbsp;</th>
                                                            <th style={{ width: "130px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Labs Date" field="labsDate"
                                                                />
                                                            </th>
                                                            <th style={{ width: "95px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Collected" field="labsCollect"
                                                                />
                                                            </th>
                                                            <th style={{ width: "75px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Value" field="labsValue"
                                                                />
                                                            </th>
                                                            <th style={{ width: "100px" }}>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody></tbody>
                                                </table>
                                            </div>

                                            <div className="labsListArea innerBody" style={{ maxHeight: "128px", overflow: "auto" }}>
                                                <table>
                                                    <thead>
                                                        <tr style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                            <th style={{ width: "15px" }}>&nbsp;</th>
                                                            <th style={{ width: "130px" }}>Labs Date</th>
                                                            <th style={{ width: "95px" }}>Collected</th>
                                                            <th style={{ width: "75px" }}>Value</th>
                                                            <th style={{ width: "100px" }}>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.labsItemFields
                                                            && this.state.labs !== null && this.state.labs.length > 0
                                                            ? this.state.labs.map((prm, index) => (
                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionLabsRow(e, index) }} className={this.state.selectedLabsRowIndex === index ? "selectedRowCls" : ""}>
                                                                    <td style={{ width: "15px" }}>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td>
                                                                        <Input type="date" focused={(e) => { this.handleOnFocus(e, index, "selectedLabsRowIndex") }}
                                                                            changed={(e) => { this.handleLabsGridChange(e, index) }}
                                                                            labelText="" field="labsDate" lineStyle="oneLine"
                                                                            value={prm.labsDate != null && prm.labsDate !== "" ? moment(new Date(prm.labsDate)).format("MM/DD/YYYY") : ""}
                                                                            rowIndex={index}//keyId={"labsDate" + index}
                                                                            //#20210125 - START
                                                                            // Add day/month/year values
                                                                            inValidDay={this.state.labsItemFields[index]["labsDateDay"]}
                                                                            inValidMonth={this.state.labsItemFields[index]["labsDateMonth"]}
                                                                            inValidYear={this.state.labsItemFields[index]["labsDateYear"]}
                                                                            dayVal={prm.labsDateDay}
                                                                            monthVal={prm.labsDateMonth}
                                                                            yearVal={prm.labsDateYear}
                                                                            //#20210125 - END
                                                                            lostfocused={(e) => { this.handleLabsLostFocus(e, index) }}
                                                                            labelWidth="0px" monthWidth="33px" dayWidth="40px" yearWidth="50px"//width="100%"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedLabsRowIndex") }}
                                                                            width="95px" labelWidth="0px"
                                                                            changed={(e) => { this.handleLabsGridChange(e, index) }}
                                                                            labelText="" field="labsCollect" value={prm.labsCollect}
                                                                            //maxLength="100"
                                                                            maxLength={this.state.labsMvMaxlengthInput ? this.state.labsMvMaxlengthInput["labsCollect"] : ""}
                                                                            //lostfocused={(e) => { this.handleLabsLostFocus(e, index) }}
                                                                            rowIndex={index} lineStyle="oneLine"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedLabsRowIndex") }}
                                                                            width="75px" labelWidth="0px"
                                                                            changed={(e) => { this.handleLabsGridChange(e, index) }}
                                                                            labelText="" field="labsValue" value={prm.labsValue}
                                                                            //maxLength="100"
                                                                            maxLength={this.state.labsMvMaxlengthInput ? this.state.labsMvMaxlengthInput["labsValue"] : ""}
                                                                            //lostfocused={(e) => { this.handleLabsLostFocus(e, index) }}
                                                                            rowIndex={index} lineStyle="oneLine"
                                                                        />
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                            )) : <tr><td colSpan="5">No records available</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="contactBtnArea right">
                                                <button onClick={this.addNewLabs}>Add</button>
                                                <button onClick={this.deleteSelectedLabs}>Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetOutcomes} formId="outcomesForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        extraAction={this.extraAction}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Outcomes data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {showConfirmationToAddNew}
                </React.Fragment>

                <React.Fragment>
                    {OutcomesMaintenance}

                    <div id="textareaEditorForItemsCls">
                        {
                            this.props.isShowTextDialogVisible ?
                                /*
                                <Dialog
                                    title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                    onClose={this.props.closeTextDialog}
                                    width={950} height={"auto"}
                                    className={classes.title}>*/
                                <div className="CRStar_Window">
                                    <div className="fake_popup_cls"></div>
                                    <Draggable handle=".k-window-titlebar">
                                        <div className="dragableWindowCls">
                                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                                <div className="k-window-title k-dialog-title">
                                                    <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                                </div>
                                                <div className="k-window-actions k-dialog-actions">
                                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                    </a>
                                                </div>
                                            </div>
                                            <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                        </div>
                                        {/*</Dialog>*/}
                                    </Draggable>
                                </div>
                                : null
                        }
                    </div>

                    <div>
                        {
                            this.props.isAddNewDialogVisible2 ?
                                <div className="CRStar_Window">
                                    <div className="fake_popup_cls"></div>
                                    <Draggable handle=".k-window-titlebar">
                                        <div className="dragableLargerWindowCls">
                                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                                <div className="k-window-title k-dialog-title">New Physician</div>
                                                <div className="k-window-actions k-dialog-actions">
                                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeAddNewDialog(e) }}></span>
                                                    </a>
                                                </div>
                                            </div>
                                            <PhysicianForm closeWindow={(e) => { this.closeAddNewDialog(e) }} isNotExist={true} />
                                        </div>
                                    </Draggable>
                                </div>
                                : null
                        }
                    </div>

                </React.Fragment>
            </React.Fragment>
        );
    }
}

OutcomesMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedOutcomesRecord: state.patientAbstract.selectedOutcomesRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem2,//#2
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        userId: state.auth.userId,
        isAddNewDialogVisible: state.patientAbstract.isAddNewDialogVisible,
        isAddNewDialogVisible2: state.patientAbstract.isAddNewDialogVisible2,//Case::Not Exist

        savingDiagnosisCompleted: state.patientAbstract.savingDiagnosisCompleted,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveOutcomes: (outcomesData) => dispatch(actionCreators.saveOutcomes(outcomesData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem2()),//#2
        // show add new Physician popup
        addNewItem: (number) => dispatch(actionCreators.selectPhysicianRecord(number)),
        addNewItemIsNotExist: (number) => dispatch(actionCreators.selectPhysicianRecord(number, true)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutcomesMaintenance);