/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import "./UserMaintenance.css";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "../../../components/AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance.css";
import Help from "../../UI/Help";
//import { Dialog } from "@progress/kendo-react-dialogs";
import Draggable from 'react-draggable';
import ConfigData from "../../../store/config.json";
import axios from "axios";

class UserMaintenance extends Component {

    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "Userid",
        isHelpVisible: false,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,
        isSaving: false,

        showingTestResult: false,
        testMessage: null,

        userIdDefaultVal: "",//"@" + this.props.clientgroup + ".COM",
        changed: false,// this flag to using for Userid item
        loaded: false,

        //passValidation: null,
        //passValidationMsg: null,
        globalMsg: "New Password and Confirm Password do not match.",
        userClientGroup: null
    }

    hideTestResult = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingTestResult: false, testMessage: null });
        this.props.clearErrorMessage();
        this.props.setChildrenOpening(false);

        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
            // To make sure set back focus to password fields only
            if (focusingField && focusingField.indexOf("passwordNet") == -1) {
                focusingField = "passwordNet";
            }
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    componentDidMount() {
        this.props.setChildrenOpening(false);
        if (!this.state.dialogForm && this.props.selectedUserInfo) {
            this.setFormData();
        }
        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }
    }

    componentDidUnmount() {
        this.props.setChildrenOpening(false);
    }

    async getUserClientGroup() {
        let url = ConfigData.CRStarAPI_URL + "ers/GetUserClientGroup";
        let params = { email: this.props.userId, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            userClientGroup: res.data ? res.data.result : ""
        });
    }

    componentDidUpdate(prevProps) {
        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }

        if (this.state.isFormDirty && this.state.isSaving && this.state.isSaving != this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }

        if (this.props.error && !this.state.showingTestResult && this.state.isSaving) {
            this.setState({
                showingTestResult: true,
                testMessage: this.props.errorMessage,
                isSaving: false,
            });

            this.props.setChildrenOpening(true);
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            //console.log("[User Maintenance] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        if (this.state.changed && !this.props.isAddNewUser) {
            return false;
        }
        if ((!this.state.dialogForm && this.props.selectedUserInfo)
            /**/
            || (prevProps.selectedUserInfo && this.props.selectedUserInfo && prevProps.selectedUserInfo.Userid !== this.props.selectedUserInfo.Userid)
            || (this.props.selectedUserInfo && this.props.selectedUserInfo.Userid !== this.state.dialogForm.Userid)
            || (!prevProps.selectedUserInfo && this.props.selectedUserInfo && this.props.selectedUserInfo.Userid === this.state.dialogForm.Userid)

            || (prevProps.selectedUserInfo && this.props.selectedUserInfo && prevProps.selectedUserInfo.userid !== this.props.selectedUserInfo.userid)
            || (this.props.selectedUserInfo && this.props.selectedUserInfo.userid !== this.state.dialogForm.userid)
            || (!prevProps.selectedUserInfo && this.props.selectedUserInfo && this.props.selectedUserInfo.userid === this.state.dialogForm.userid)
            /**/
        ) {
            this.setFormData(true);
            this.props.clearAddNewUserFlag();
        }

        //
        if (this.props.selectedUserInfo && this.props.selectedUserInfo.userid) {
            var newWidth = this.props.selectedUserInfo.userid.length * 10;
            setTimeout(function () {
                var usrIdField = document.getElementById("Userid");
                if (usrIdField && usrIdField.style) {
                    usrIdField.style.width = newWidth + "px !important";
                }

                var pwdField = document.getElementById("passwordNet");
                pwdField ? pwdField.focus() : "";
                return false;
            }, 1500);
        } else {
            setTimeout(function () {
                var usrIdField = document.getElementById("Userid");
                usrIdField ? usrIdField.focus() : "";
                return false;
            }, 1500);
        }
    }

    /*
    componentWillUnmount() {
        console.log("componentWillUnmount");
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("shouldComponentUpdate", nextProps, nextState);
    }
    */

    showUserId = () => {
        let uid = this.props.selectedUserInfo.Userid;
        if (uid && uid.split('@').length > 1) {
            return uid.split('@')[0];
        } else {
            return uid;
        }
    }
    showUserId2 = () => {
        let uid = this.props.selectedUserInfo.userid;
        if (uid && uid.split('@').length > 1) {
            return uid.split('@')[0];
        } else {
            return uid;
        }
    }    

    setFormData = (initial) => {
        const defaultVal = this.state.userIdDefaultVal.toLowerCase();
        this.setState({
            initialDialogForm: { ...this.props.selectedUserInfo },
            dialogForm: {
                ...this.props.selectedUserInfo,
                ["Userid"]: (this.props.selectedUserInfo.Userid === null ? defaultVal : this.showUserId()),
                ["userid"]: (this.props.selectedUserInfo.userid === null ? defaultVal : this.showUserId2()),
            },
            isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,
        });

        if ((!this.state.uAllItemFields && this.props.selectedUserInfo) || initial) {
            let fields = {};
            Object.keys(this.props.selectedUserInfo).map((t) => {
                fields[t] = "";
            });

            // 
            if (fields["Userid"] == undefined) {
                fields["Userid"] = "";
            }

            this.setState({ uAllItemFields: fields });
        };

        return false;
    }

    saveUserInfo = (event) => {
        event.preventDefault();

        this.setState({ showingTestResult: false, testMessage: null });
        this.props.setChildrenOpening(false);

        let dialogFormData = this.state.dialogForm;

        // TODO - check form validation
        let isValid = true;

        const defaultVal = this.state.userIdDefaultVal.toLowerCase();
        const formItems = this.state.uAllItemFields;
        Object.keys(dialogFormData).map((t, k) => {
            if (t === "Userid"
                && (dialogFormData[t] === null || dialogFormData[t] === "" || dialogFormData[t] === defaultVal)) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                formItems[t] = "IN_VALID";
                isValid = false;
            }
            if ((t === "passwordNet" || t === "PasswordNet" || t === "passwordnet")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                formItems[t] = "IN_VALID";
                isValid = false;
            }
            if ((t === "passwordNetConfirm" || t === "PasswordNetConfirm" || t === "passwordnetconfirm")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                formItems[t] = "IN_VALID";
                isValid = false;
            }
            if ((t === "userName" || t === "UserName" || t === "username")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                formItems[t] = "IN_VALID";
                isValid = false;
            }
            if ((t === "clientGroup" || t === "ClientGroup" || t === "clientgroup")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                formItems[t] = "IN_VALID";
                isValid = false;
            }

            //
            if (dialogFormData["passwordNetConfirm"] !== dialogFormData["passwordNet"]) {
                const _t = "passwordNetConfirm";
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_t]: "IN_VALID" },
                });
                formItems[_t] = "IN_VALID";
                isValid = false;
            } else {
                this.setState({
                    uAllItemFields: {
                        ...this.state.uAllItemFields,
                        ["passwordNet"]: "",
                        ["passwordNetConfirm"]: "",
                    },
                });

                formItems["passwordNet"] = "";
                formItems["passwordNetConfirm"] = "";
            }
        });

        this.setState({ uAllItemFields: formItems });

        //const formItems = this.state.uAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid) {
            return false;
        } else {
            var isValidPwdByPattern = true;
            // Finally, check by password validation pattern with message
            const patternTest = dialogFormData.passwordNet;
            var confirmMsg = "";

            var passValidation = dialogFormData.passValidation;
            var passValidationMsg = dialogFormData.passValidationMsg;

            // IGNORE check validate if case can't get any from system setting --START
            if (passValidation === null || passValidationMsg === null) {
                dialogFormData.FromPage = "User";

                //dialogFormData.Userid = dialogFormData.Userid + "@" + this.props.clientgroup.toLowerCase() + ".com";

                dialogFormData.email = dialogFormData.Userid;
                dialogFormData.password = dialogFormData.passwordNetDb;

                this.props.saveUserInfo(dialogFormData);

                this.setState({
                    isSaving: true,
                    //changed: false,
                    //showingTestResult: true,//set this to avoid show error message more again!!!
                });
                return false;
            }
            // --END

            const regex = new RegExp(passValidation, 'g');
            //var combineRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            let m;

            if ((m = regex.exec(patternTest)) !== null) {
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    console.log(`Found match, group ${groupIndex}: ${match}`);
                });
                isValidPwdByPattern = true;
            } else {
                isValidPwdByPattern = false;
                confirmMsg = passValidationMsg;
            }

            if (!isValidPwdByPattern) {
                this.setState({
                    showingTestResult: true,
                    testMessage: confirmMsg
                });

                this.props.setChildrenOpening(true);
                return false;
            } else {
                dialogFormData.FromPage = "User";

                //dialogFormData.Userid = dialogFormData.Userid + "@" + this.props.clientgroup.toLowerCase() + ".com";

                dialogFormData.email = dialogFormData.Userid;
                dialogFormData.password = dialogFormData.passwordNetDb;

                this.props.saveUserInfo(dialogFormData);

                this.setState({
                    isSaving: true,
                    //changed: false,
                    //showingTestResult: true,//set this to avoid show error message more again!!!
                });
            }
        }
    }

    resetUserInfo = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false,
            changed: false,
        });
    }

    handleOnBlur = (event) => {
        /*
        // load if whether this value is existing
        if (event.target.name === "userId" || event.target.name === "Userid" || event.target.name === "userid") {
            this.props.addNewItem(event.target.value);
            this.setState({ changed: false });
        }
        */
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name });

        /*
        if (event.target.name === "Userid") {
            const userIdDefaultVal = this.state.userIdDefaultVal.toLowerCase();
            const input = document.getElementById('Userid');
            let value = event.target.value;
            
            setTimeout(function () {
                input.focus();
                value = value.toLowerCase();
                const posCursor = value.indexOf(userIdDefaultVal);
                input.setSelectionRange(posCursor, posCursor);
            }, 100);
        }
        */
    }

    handleFormChange = (event) => {
        /*
        // load if whether this value is existing
        if (event.target.name === "Userid") {
            let val = event.target.value.toLowerCase();
            const userIdDefaultVal = this.state.userIdDefaultVal.toLowerCase();
            if (val.length == 0) {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm, [event.target.name]: userIdDefaultVal
                    },
                    isFormDirty: true,
                    changed: true,
                });
            } else {
                if (val.indexOf(userIdDefaultVal) >= 0) {
                    val = val.replace(userIdDefaultVal, "");

                    this.setState({
                        dialogForm: {
                            ...this.state.dialogForm, [event.target.name]: val + userIdDefaultVal
                        },
                        isFormDirty: true,
                        changed: true,
                    });
                } else {
                    if (val.indexOf('@') >= 0) {
                        val = val.split('@')[0];
                    } else {
                        val = val.split(this.props.clientgroup.toLowerCase())[0];
                    }
                    
                    this.setState({
                        dialogForm: {
                            ...this.state.dialogForm, [event.target.name]: val + userIdDefaultVal
                        },
                        //isFormDirty: true,
                        changed: true,
                    });
                }
            }
        } else {
        */
            if (event.target2) {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value
                    },
                    isFormDirty: true,
                    //changed: false
                });
            } else {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value
                    },
                    isFormDirty: true,
                    //changed: fieldNeedChanged ? false : changed
                });
            }
        /*}*/
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        // Fixing #PR00193: Validation of password should occur as you leave the password field, rather than at the time of saving.
        if ((event.target.name === "passwordNet" || event.target.name === "passwordNetConfirm")
            && event.target.value !== "") {
            let dialogFormData = this.state.dialogForm;

            let passwordNet = dialogFormData.passwordNet ? dialogFormData.passwordNet : "";
            let passwordNetConfirm = dialogFormData.passwordNetConfirm ? dialogFormData.passwordNetConfirm : "";

            let isValidPwdByPattern = true;
            // Finally, check by password validation pattern with message
            const patternTest = event.target.value;
            let confirmMsg = "";

            let passValidation = dialogFormData.passValidation;
            let passValidationMsg = dialogFormData.passValidationMsg;

            if (passValidation !== null && passValidationMsg !== null
                && passValidation !== "" && passValidationMsg !== "") {
                const regex = new RegExp(passValidation, 'g');
                //var combineRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
                let m;

                if ((m = regex.exec(patternTest)) !== null) {
                    // The result can be accessed through the `m`-variable.
                    m.forEach((match, groupIndex) => {
                        console.log(`Found match, group ${groupIndex}: ${match}`);
                    });
                    isValidPwdByPattern = true;
                } else {
                    isValidPwdByPattern = false;
                    confirmMsg = passValidationMsg;
                }

                if (!isValidPwdByPattern) {
                    this.setState({
                        showingTestResult: true,
                        testMessage: confirmMsg
                    });
                    this.props.setChildrenOpening(true);
                    //this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "IN_VALID" } });
                    return false;
                } else if (passwordNet !== "" && passwordNetConfirm !== "" && passwordNet !== passwordNetConfirm) {
                    this.props.setChildrenOpening(true);
                    this.setState({ uAllItemFields: { ...this.state.uAllItemFields, ["passwordNetConfirm"]: "IN_VALID" } });
                    return false;
                }
            } else if (passwordNet !== "" && passwordNetConfirm !== "" && passwordNet !== passwordNetConfirm) {
                this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
            }                        
        }
    }
    // --END

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "user":
                    this.deleteItemOnGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete User</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete this user?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let resultTest;
        {
            resultTest = this.state.showingTestResult && this.state.testMessage !== null ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTestResult(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="container">
                                    <p>{this.state.testMessage}</p>
                                </div>
                                <div className="container center">
                                    <p>
                                        <button onClick={this.hideTestResult} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Draggable >
                </div >
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded ?
            (
                <div className="container-fluid2">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveUserInfo} id="securityUserForm" className="DialogForm2">
                        <div className="emptyLine"></div>

                        <div style={{ display: "none" }}>
                            <input id="username" name="username" className="input_text" type="text" placeholder="Username" />
                            <input id="password" name="password" className="input_text" type="password" placeholder="Password" />
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="frameInner" style={{ marginRight: "15px" }}>
                                    <div className="header">&nbsp;</div>
                                    <fieldset>
                                        <legend>Security Info</legend>
                                        <div className="row">
                                            <div className="col-sm-12 maxLengthInput autoFilterById">
                                                <Input type="input" changed={this.handleFormChange}
                                                    focused={this.handleOnFocus}
                                                    lostfocused={this.handleLostFocus}//lostfocused={this.handleOnBlur}
                                                    labelText="User ID" field="Userid"
                                                    value={this.state.dialogForm.Userid ? this.state.dialogForm.Userid : this.state.dialogForm.userid}
                                                    isRequired autoFocus
                                                    inValid={this.state.uAllItemFields["Userid"]}
                                                    maxLength="100"
                                                    suffixText={"@" + (this.state.userClientGroup ? this.state.userClientGroup.toLowerCase() : "ers") + ".com"}
                                                    disabled={this.props.selectedUserInfo && this.props.selectedUserInfo.userid ? true : false}
                                                />
                                                {/*<label>{"@" + this.props.clientgroup + ".COM"}</label>*/}
                                            </div>
                                            <div className="col-sm-12">
                                                <Input type="password" changed={this.handleFormChange}
                                                    focused={this.handleOnFocus}
                                                    labelText="User Password" field="passwordNet"
                                                    value={this.state.dialogForm.passwordNet}
                                                    isRequired
                                                    inValid={this.state.uAllItemFields["passwordNet"]}
                                                    maxLength="60"
                                                    lostfocused={this.handleLostFocus}
                                                    autoFocus={this.props.selectedUserInfo && this.props.selectedUserInfo.userid ? true : false}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <Input type="password" changed={this.handleFormChange}
                                                    focused={this.handleOnFocus}
                                                    labelText="User Confirm Password" field="passwordNetConfirm"
                                                    value={this.state.dialogForm.passwordNetConfirm}
                                                    isRequired
                                                    inValid={this.state.uAllItemFields["passwordNetConfirm"]}
                                                    maxLength="60"
                                                    lostfocused={this.handleLostFocus}
                                                    globalMsg={this.state.globalMsg}
                                                    //replacementVal={this.state.dialogForm.passwordNetConfirm}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="200px"
                                                    labelText="Security Group"
                                                    field="securityGroupId" lineStyle="oneLine"
                                                    value={this.state.dialogForm.securityGroupId}
                                                    dataSource="Web_Security_Groups"
                                                    titleDialog="Security Group" labelWidth="150px"
                                                    inValid={this.state.uAllItemFields["securityGroupId"]}
                                                    aliasNo="0" itemNbr="0"
                                                    maxLength="50"
                                                    lostfocused={this.handleLostFocus}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <span style={{ color: "red" }}>&nbsp;*</span> Required Fields
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="emptyLine hidden"></div>
                                    <fieldset>
                                        <legend>User Info</legend>
                                        <div className="row">
                                            <div className="col-sm-12 maxLengthInput">
                                                <Input type="input" changed={this.handleFormChange}
                                                    focused={this.handleOnFocus}
                                                    labelText="Name" field="userName"
                                                    value={this.state.dialogForm.userName}
                                                    isRequired
                                                    inValid={this.state.uAllItemFields["userName"]}
                                                    maxLength="50"
                                                    lostfocused={this.handleLostFocus}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="200px"
                                                    labelText="Client Group"
                                                    field="clientGroup" lineStyle="oneLine"
                                                    value={this.state.dialogForm.clientGroup}
                                                    dataSource="Client_Group"
                                                    titleDialog="Client Group" labelWidth="150px"
                                                    inValid={this.state.uAllItemFields["clientGroup"]}
                                                    flag={this.state.userClientGroup}
                                                    isRequired
                                                    maxLength="50"
                                                    lostfocused={this.handleLostFocus}
                                                />
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetUserInfo}
                        formId="securityUserForm"
                        isFormDirty={this.state.isFormDirty}
                        showBtnNew="UserInfo"
                        showBtnDelete="UserInfo"
                        className="Footer2"
                        /*isLookup={true}*/
                        isDisabledOnChildren={true}
                        isChildren={true}
                        enabledAlways={true}
                        userInfo={this.state.dialogForm}
                    />
                </div>
            )
            : <p>User Maintenance data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>
                <React.Fragment>
                    {resultTest}
                </React.Fragment>
                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

UserMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
    //email: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedUserInfo: state.patientAbstract.selectedUserInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        //passValidation: state.auth.passValidation,
        //passValidationMsg: state.auth.passValidationMsg,
        //
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveUserInfo: (dialogForm) => dispatch(actionCreators.saveUserInfo(dialogForm)),
        //addNewItem: (email) => dispatch(actionCreators.selectUserInfoRecord(email)),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMaintenance);