/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Draggable from 'react-draggable';
import axios from "../../../../axios-instance";
import ConfigData from '../../../../store/config.json';

class PrintAndDownloadSE extends Component {

    state = {
        //selectedStateExport: "State Export",
        publicFolder: null, outputFileNotFound: false, fileName: null
    };

    drawingHtmlTable(selectedReportLabelsInfo) {
        this.setState({
            dialogForm: {
                ...selectedReportLabelsInfo,
                //populationLabel: populationLabel,
                filename: !selectedReportLabelsInfo["filename"] || selectedReportLabelsInfo["filename"] == "" ? "State Export" : selectedReportLabelsInfo["filename"],
            },
            showGeneditPopup: selectedReportLabelsInfo.runGenEdits == "1" && selectedReportLabelsInfo.fnGenEditsStartFile ? true : false,
            fnGenEditsStartFile: selectedReportLabelsInfo.fnGenEditsStartFile && selectedReportLabelsInfo.fnGenEditsStartFile != "" ? selectedReportLabelsInfo.fnGenEditsStartFile : null,
            MetaFile: selectedReportLabelsInfo.metaFile,
            IIS_Path: selectedReportLabelsInfo.iisPath,
            radioOptions: selectedReportLabelsInfo.radioOptions,
            //
            isDrawingHtmlTable: selectedReportLabelsInfo.fnDisplayURL1 && selectedReportLabelsInfo.fnDisplayURL1 != "",
            selectedStateExport: "State Export",//selectedRadioOptions: "State Export",
            fnDisplayURL1: selectedReportLabelsInfo.fnDisplayURL1,
            fnDisplayURL2: selectedReportLabelsInfo.fnDisplayURL2,
            fnDisplayURL3: selectedReportLabelsInfo.fnDisplayURL3,
            fnDisplayURL4: selectedReportLabelsInfo.fnDisplayURL4,
            fnDownloadFile1: selectedReportLabelsInfo.fnDownloadFile1,
            fnDownloadFile2: selectedReportLabelsInfo.fnDownloadFile2,
            fnDownloadFile3: selectedReportLabelsInfo.fnDownloadFile3,
            fnDownloadFile4: selectedReportLabelsInfo.fnDownloadFile4,

            //
            fnHtmlDisplayURL1: selectedReportLabelsInfo.fnHtmlDisplayURL1,
            fnHtmlDisplayURL2: selectedReportLabelsInfo.fnHtmlDisplayURL2,
            fnHtmlDisplayURL3: selectedReportLabelsInfo.fnHtmlDisplayURL3,
            fnHtmlDisplayURL4: selectedReportLabelsInfo.fnHtmlDisplayURL4,

            //Fn_Html_Display_URL: selectedReportLabelsInfo.htmlDisplay,
            //publicFolder: selectedReportLabelsInfo.publicFolder ? selectedReportLabelsInfo.publicFolder : ("/" + this.props.clientgroup),
        });

        setTimeout(function () {
            document.getElementById("stateExportOption_Id") ? document.getElementById("stateExportOption_Id").click() : null;
        }, 100);

        //
        if (!selectedReportLabelsInfo.fnHtmlDisplayURL1) {
            let _url2 = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/LoadExportedData";
            let _params2 = { fileName: selectedReportLabelsInfo.fnDisplayURL1, accessToken: localStorage.getItem('token') };
            var _this = this;
            axios.post(_url2, _params2)
                .then(function (response) {
                    _this.setState({
                        fnHtmlDisplayURL1: response.data ? response.data.output : "",
                    });

                    // Also add process to download output file
                    if (!_this.downloadFile) {
                        setTimeout(function () {
                            selectedReportLabelsInfo.endTime = null;
                            if (document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE")) {
                                document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").removeAttribute('disabled');
                                document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").click();
                                _this.downloadFile = true;
                            }
                            return false;
                        }, 1000);
                        return false;
                    }
                    return false;
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
            return false;
        } else {
            if (!this.downloadFile) {
                var _this = this;
                setTimeout(function () {
                    selectedReportLabelsInfo.endTime = null;
                    if (document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE")) {
                        document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").removeAttribute('disabled');
                        document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").click();
                        _this.downloadFile = true;
                    }
                    return false;
                }, 1000);
                return false;
            }
        }

        if (document.querySelector('.statusBarOnFooterCls')) {
            var _startTime = "Start Time: " + selectedReportLabelsInfo.startTime;
            var space = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: ";
            document.querySelector('.statusBarOnFooterCls').innerHTML = _startTime + space + selectedReportLabelsInfo.endTime;
        }
        return false;
    };

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.props.closeWindow();
        return false;
    }

    componentDidMount() {
        if (this.props.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo.endTime) {
            let dataForm = this.props.selectedReportLabelsInfo;
            if (dataForm.endTime) {
                this.drawingHtmlTable(dataForm);
            }
        }
    }

    printTable = () => {
        var printStyle = document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : this.state.printStyle;

        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>' + (/*this.state.dialogForm ? this.state.dialogForm.filename :*/ "NAACCR Export") + '</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
        return;
    }

    selectionStateExport = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedStateExport: event.target.value,
            dialogForm: dialogFormData
        });

        setTimeout(function () {
            document.getElementById("option1").click();
            return false;
        }, 0);
    }

    mouseOverFnc(event, obj) {
        event.preventDefault();
        obj.downloadFile = false;
        return false;
    }

    //
    async downloadFileFnc(event, obj) {
        event.preventDefault();

        var dialogFormData = obj.state.dialogForm;
        var radioStateExport = obj.state.selectedStateExport ? obj.state.selectedStateExport : "State Export";
        if (dialogFormData.zipFile != "1") {
            dialogFormData["fileExtension"] = "xml";
        } else if (dialogFormData.urlOutputFileZip && dialogFormData.urlOutputFileZip != "" && !obj.downloadFile && radioStateExport == "State Export") {
            //this.downloadZipFile(dialogFormData);

            // get file name from url
            //var filename = dialogFormData.csFilename ? dialogFormData.csFilename.substring(0, dialogFormData.csFilename.lastIndexOf(".")) + ".zip" : dialogFormData.filename + ".zip";
            var filename = dialogFormData.urlOutputFileZip.substring(dialogFormData.urlOutputFileZip.lastIndexOf("\\") + 1);

            //display error message "Could not find the exported file: ..." and stop the process to prevent Excel grab the index file for downloading
            let userId = obj.props.userId;
            var url = ConfigData.CRStarAPI_URL + obj.props.clientgroup + "/CheckExistedDownloadFile";
            var _params = { fileName: filename, clientAlias: userId, accessToken: localStorage.getItem('token') };
            var result = await axios.post(url, _params)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                    /*_thjs.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });

            if (result && result.data && result.data.error && (result.data.errorCode == "204" || result.data.errorCode == "404")) {
                let path = dialogFormData.urlOutputFileZip ? dialogFormData.urlOutputFileZip : "";
                path = path.substr(0, path.lastIndexOf("\\") + 1);

                obj.setState({ outputFileNotFound: true, fileName: path + filename });
                return false;
            } else {
                const link = document.createElement('a');
                link.href = obj.props.clientgroup + "/" + filename;//dialogFormData.urlOutputFileZip;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                //obj.downloadFile = true;
            }
            return false;
        }

        if (this.downloadFile) {
            this.downloadFile = false;
            return false;
        }

        // get file name from url
        //var filename = dialogFormData.filename;//url.substring(url.lastIndexOf('\\') + 1);
        var filename = dialogFormData.urlOutputFileZip ? dialogFormData.urlOutputFileZip.substring(dialogFormData.urlOutputFileZip.lastIndexOf("/") + 1) : "";
        if (dialogFormData.csFilename && dialogFormData.radioStateExport && dialogFormData.radioStateExport == "State Export") {
            filename = dialogFormData.csFilename;
        }

        if (dialogFormData.zipFile == "1") {
            //filename = filename + ".zip";
        } else {
            if (radioStateExport == "File 2") {
                filename = dialogFormData.fnDownloadFile2;
                filename = filename.substring(filename.lastIndexOf('\\') + 1);
            } else if (radioStateExport == "File 3") {
                filename = dialogFormData.fnDownloadFile3;
                filename = filename.substring(filename.lastIndexOf('\\') + 1);
            } else if (radioStateExport == "File 4") {
                filename = dialogFormData.fnDownloadFile4;
                filename = filename.substring(filename.lastIndexOf('\\') + 1);
            } else if (dialogFormData.hospState == "FL") {
                filename = dialogFormData.csFilename ? dialogFormData.csFilename : filename;
            }
        }

        if (filename.indexOf(".XDL") != -1) {
            filename += ".txt";
        }

        //display error message "Could not find the exported file: ..." and stop the process to prevent Excel grab the index file for downloading
        let userId = obj.props.userId;
        var url = ConfigData.CRStarAPI_URL + obj.props.clientgroup + "/CheckExistedDownloadFile";
        var _params = { fileName: filename, clientAlias: userId, accessToken: localStorage.getItem('token') };
        var result = await axios.post(url, _params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*obj.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });

        if (result && result.data && result.data.error && (result.data.errorCode == "204" || result.data.errorCode == "404")) {
            let path = dialogFormData.urlOutputFileZip ? dialogFormData.urlOutputFileZip : "";
            path = path.substr(0, path.lastIndexOf("\\") + 1);

            obj.setState({ outputFileNotFound: true, fileName: path + filename });
            return false;
        }

        dialogFormData.radioStateExport = radioStateExport;

        //https://bobbyhadz.com/blog/react-download-file
        var url = obj.props.clientgroup + "/DownloadStateExport";
        axios.post(url, {
            printForm: dialogFormData,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            //update new accessToken again
            var newAccessToken = response && response.data ? response.data.accessToken : null;
            if (newAccessToken) {
                localStorage.setItem('token', newAccessToken);
            }

            try {
                if (response.data && response.data.indexOf(".zip") != -1) {
                    var filename = response.data;
                    const link = document.createElement('a');
                    link.href = obj.props.clientgroup + "/" + filename;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return false;
                }
            } catch (ex) {
                if (response.data && response.data.result && response.data.result.indexOf(".zip") != -1) {
                    var filename = response.data.result;
                    const link = document.createElement('a');
                    link.href = obj.props.clientgroup + "/" + filename;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return false;
                }
            }

            //const _url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement('a');
            //link.href = _url;

            // get file name from url
            //var filename = dialogFormData.filename;//url.substring(url.lastIndexOf('\\') + 1);
            var _filename = dialogFormData.urlOutputFileZip ? dialogFormData.urlOutputFileZip.substring(dialogFormData.urlOutputFileZip.lastIndexOf("/") + 1) : "";
            if (dialogFormData.csFilename && dialogFormData.radioStateExport && dialogFormData.radioStateExport == "State Export") {
                _filename = dialogFormData.csFilename;
            }

            if (dialogFormData.zipFile == "1") {
                //_filename = _filename + ".zip";
            } else {
                if (radioStateExport == "File 2") {
                    _filename = dialogFormData.fnDownloadFile2;
                    _filename = _filename.substring(_filename.lastIndexOf('\\') + 1);
                } else if (radioStateExport == "File 3") {
                    _filename = dialogFormData.fnDownloadFile3;
                    _filename = _filename.substring(_filename.lastIndexOf('\\') + 1);
                } else if (radioStateExport == "File 4") {
                    _filename = dialogFormData.fnDownloadFile4;
                    _filename = _filename.substring(_filename.lastIndexOf('\\') + 1);
                } else if (dialogFormData.hospState == "FL") {
                    _filename = dialogFormData.csFilename ? dialogFormData.csFilename : _filename;
                }
            }

            if (_filename.indexOf(".XDL") != -1) {
                _filename += ".txt";
            }
            link.href = obj.props.clientgroup + "/" + _filename;
            link.download = _filename;
            link.target = '_blank';
            link.rel = 'noreferrer';

            console.log("Downloading a filename=" + obj.props.clientgroup + "/" + _filename);

            //link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            obj.downloadFile = true;
            return false;
        }).catch(function (error) {
            console.log(error);
            /*
            var btnLogout = document.getElementById("logoutButtonArea");
            if (btnLogout) {
                btnLogout.querySelector("a").click();
            }
            */
            //@TODO:...

            return false;
        });
        return false;
    }

    hideValidationError = (event) => {
        try {
            event.preventDefault();
        } catch (e) { }
        this.setState({ outputFileNotFound: false, fileName: null });
        return false;
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
        return;
    }

    render() {
        let validationError;
        {
            validationError = this.state.outputFileNotFound ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" id="PrintAndDownloadSePopup">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Validation Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideValidationError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">
                                    {"Could not find the exported file: " + this.state.fileName}
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideValidationError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var Report_Url = this.state.fnHtmlDisplayURL1 ? this.state.fnHtmlDisplayURL1 : "Loading... Please wait!";
        var reportFile = this.state.selectedStateExport;
        if (reportFile == "File 2") {
            Report_Url = this.state.fnHtmlDisplayURL2;
        } else if (reportFile == "File 3") {
            Report_Url = this.state.fnHtmlDisplayURL3;
        } else if (reportFile == "File 4") {
            Report_Url = this.state.fnHtmlDisplayURL4;
        } else {
            Report_Url = this.state.fnHtmlDisplayURL1;
        }

        const _selectedStateExport = this.state.selectedStateExport ? this.state.selectedStateExport : "State Export";

        let pdSE;
        {
            pdSE = this.state.isDrawingHtmlTable && this.state.fnDisplayURL1 ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls" id="dataExportMainPopup">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Data Exported</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable_GenEdits"
                                style={{ overflowY: this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <div id="useForPrintHtml" className={this.state.publicFolder && this.state.publicFolder != "" ? "_hidden" : ""}>
                                    <div dangerouslySetInnerHTML={{ __html: Report_Url }} className="state_export_popup" />
                                </div>

                                <iframe src={this.state.publicFolder ? (this.state.publicFolder + "?alias=" + this.props.clientgroup) : this.state.Fn_Html_Display_URL}
                                    //id="displayReportIframe"
                                    className={this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "hidden"} />
                            </div>

                            <div className="emptyLine"></div>

                            <div className="emptyLine hidden" style={{ display: "none" }}>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option1" id="option1"
                                            checked={this.state.selectedOption === 'option1'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 1
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option2" id="option2"
                                            checked={this.state.selectedOption === 'option2'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 2
                                        </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option3" id="option3"
                                            checked={this.state.selectedOption === 'option3'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 3
                                    </label>
                                </div>
                            </div>

                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-3" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="REPORTDATA_STATEEXPOR_XDRU_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable}>
                                            <img id="REPORTDATA_STATEEXPOR_XDRU_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE" className="ScreenButton ScreenButtonHover" title="Download the Exported Data File"
                                            onClick={(e) => { this.downloadFileFnc(e, this) }}
                                            onMouseOver={(e) => { this.mouseOverFnc(e, this) }}
                                        >
                                            <img id="REPORTDATA_STATEEXPOR_XDRU_ICON_DOWNLOAD_FILE" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" className="" />
                                            Download</button>
                                        <button type="button" id="REPORTDATA_STATEEXPOR_XDRU_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideDrawingHtmlTable} value="exit" autoFocus>
                                            <img id="REPORTDATA_STATEEXPOR_XDRU_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                    <div className="col-sm-7" style={{ display: "flex", margin: "15px" }} id="stateExportRadioOptions">
                                        <label style={{ width: "240px", height: "0px" }}>
                                            <input type="radio" onChange={(e) => { this.selectionStateExport(e) }}
                                                checked={_selectedStateExport == "State Export" ? true : false}
                                                name="radioStateExport" field="radioStateExport" id="stateExportOption_Id" value="State Export"
                                                style={{ cursor: "pointer", fontWeight: "bold", /*width: "50px",*/ marginRight: "-35px" }} />
                                            {this.state.radioOptions && this.state.radioOptions != "" && this.state.radioOptions.split("|").length >= 0 ? this.state.radioOptions.split("|")[0] : "State Export"}
                                        </label>

                                        <label style={{ width: "240px", height: "0px", display: this.state.fnDisplayURL2 && this.state.fnDisplayURL2 != "" ? "" : "none" }}>
                                            <input type="radio" onChange={(e) => { this.selectionStateExport(e) }}
                                                checked={_selectedStateExport == "File 2" ? true : false}
                                                name="radioStateExport" field="radioStateExport" value="File 2" width="240px"
                                                style={{ cursor: "pointer", fontWeight: "bold", /*width: "50px",*/ marginRight: "-35px", display: this.state.fnDisplayURL2 && this.state.fnDisplayURL2 != "" ? "" : "none" }} />
                                            {this.state.radioOptions && this.state.radioOptions != "" && this.state.radioOptions.split("|").length >= 1 ? this.state.radioOptions.split("|")[1] : "File 2"}
                                        </label>

                                        <label style={{ width: "240px", height: "0px", display: this.state.fnDisplayURL3 && this.state.fnDisplayURL3 != "" ? "" : "none" }}>
                                            <input type="radio" onChange={(e) => { this.selectionStateExport(e) }}
                                                checked={_selectedStateExport == "File 3" ? true : false}
                                                name="radioStateExport" field="radioStateExport" value="File 3" width="240px"
                                                style={{ cursor: "pointer", fontWeight: "bold", /*width: "50px",*/ marginRight: "-35px", display: this.state.fnDisplayURL3 && this.state.fnDisplayURL3 != "" ? "" : "none" }} />
                                            {this.state.radioOptions && this.state.radioOptions != "" && this.state.radioOptions.split("|").length >= 2 ? this.state.radioOptions.split("|")[2] : "File 3"}
                                        </label>

                                        <label style={{ width: "240px", height: "0px", display: this.state.fnDisplayURL4 && this.state.fnDisplayURL4 != "" ? "" : "none" }}>
                                            <input type="radio" onChange={(e) => { this.selectionStateExport(e) }}
                                                checked={_selectedStateExport == "File 4" ? true : false}
                                                name="radioStateExport" field="radioStateExport" value="File 4" width="240px"
                                                style={{ cursor: "pointer", fontWeight: "bold", /*width: "50px",*/ marginRight: "-35px", display: this.state.fnDisplayURL4 && this.state.fnDisplayURL4 != "" ? "" : "none" }} />
                                            {this.state.radioOptions && this.state.radioOptions != "" && this.state.radioOptions.split("|").length >= 3 ? this.state.radioOptions.split("|")[3] : "File 4"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null;
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    {validationError}
                </React.Fragment>
                <React.Fragment>
                    {pdSE}
                </React.Fragment>
            </React.Fragment>
        );

    }
}

PrintAndDownloadSE.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        userId: state.auth.userId,
        selectedHospital: state.patientAbstract.selectedHospital,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintAndDownloadSE);