/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import "../SelectAPop/SelectAPop.css";
import Draggable from 'react-draggable';
import PopLabel from "../PopLabel/PopulationLabel";

// Step 1 - Include react
//import React from "react";

// Step 2 - Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Step 3 - Include the fusioncharts library
import FusionCharts from "fusioncharts";
// Step 4 - Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Step 5 - Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Step 6 - Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

class SurvivalByStage extends Component {
    downloadFile = false;
    showingByGraphTypeIndex = 0;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "populationLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,

        //Chart configs
        isDrawingCharts: false,
        showingByGraphType: -1,

        listOfItems: null,
        chartDatas: null,
        trHtml: null,
        activeTab: "0",
        printStyle: null,

        Radio_Options: null,
        Surv_URL_Data_Table: null,
        Surv_URL1: null,
        Surv_URL2: null,
        Surv_URL3: null,
        Surv_URL4: null,
        Surv_URL5: null,
        Surv_URL6: null,
        Report_Url: null,
        Fn_Display: null,
        Fn_Display_URL: null,

        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        chartDatas: null,
        showMessageError: false,
    }
    initialPage = true;

    //
    changeGraphType = (event, index) => {
        var reportUrl = null;
        switch (index) {
            case 1:
            case "1":
                reportUrl = this.state.Surv_URL1;
                break;
            case 2:
            case "2":
                reportUrl = this.state.Surv_URL2;
                break;
            case 3:
            case "3":
                reportUrl = this.state.Surv_URL3;
                break;
            case 4:
            case "4":
                reportUrl = this.state.Surv_URL4;
                break;
            case 5:
            case "5":
                reportUrl = this.state.Surv_URL5;
                break;
            case 6:
            case "6":
                reportUrl = this.state.Surv_URL6;
                break;
            default:
                reportUrl = this.state.Surv_URL_Data_Table;
                break;
        }

        this.setState({
            showingByGraphType: index,
            Report_Url: reportUrl,//Report_Url
            showingByGraphTypeIndex: index,
        });
    }

    //--
    selectionSurvivalMethod = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedSurvivalMethodOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedSurvivalMethodOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }
    selectionStageType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedStageTypeOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedStageTypeOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }
    selectionStartDate = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedStartDateOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedStartDateOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }
    selectionEndDate = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedEndDateOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedEndDateOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }

    selectionOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedOutputTypeOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedOutputTypeOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }

    addEventListenerFnc = (om) => {
        var labelsMvGrid = document.getElementById('labelsMvListArea');
        labelsMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedLabelsMvRowIndex;
                om.insertNewLabelsMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('globalItemNumber' + selectedIndex) ? document.getElementById('globalItemNumber' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedLabelsMv();//btnDeleteSelectedLabelsMv
                return false;
            }
            return false;
        });

        var labelsSiteMvSapGrid = document.getElementById('labelsSiteMvSapListArea');
        labelsSiteMvSapGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedLabelsSiteMvSapRowIndex;
                om.insertNewLabelsSiteMvSapGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('siteCode' + selectedIndex) ? document.getElementById('siteCode' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedLabelsSiteMvSap();//btnDeleteSelectedLabelsSiteMvSap
                return false;
            }
            return false;
        });

        return false;
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        //axios.get(url)
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ availableHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ availableHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_RUN") ? document.querySelector("#BUTTON_RUN").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedLabelPopInfo) {
            this.setFormData(this.props.selectedLabelPopInfo);
        }
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null });

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        //
        if (!this.props.selectedLabelPopInfo) {
            this.initialPage = true;
            return false;
        }

        if (this.props.error && this.props.errorMessage) {
            this.setState({ showClockTimer: false, showMessageError: true, errorMessage: this.props.errorMessage });

            document.querySelector('#BUTTON_RUN') ? document.querySelector('#BUTTON_RUN').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        /*---*/
        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }
        /*---*/

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {                    
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex != -1) {                    
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedLabelPopInfo)
            || (prevProps.selectedLabelPopInfo && this.props.selectedLabelPopInfo && (prevProps.selectedLabelPopInfo.labelId !== this.props.selectedLabelPopInfo.labelId || prevProps.selectedLabelPopInfo.populationLabel !== this.props.selectedLabelPopInfo.populationLabel))
            || (prevProps.selectedLabelPopInfo && this.props.selectedLabelPopInfo && (prevProps.selectedLabelPopInfo.startTime !== this.props.selectedLabelPopInfo.startTime || prevProps.selectedLabelPopInfo.endTime !== this.props.selectedLabelPopInfo.endTime))
            || (this.state.reload && this.props.selectedLabelPopInfo)
            || this.initialPage
        ) {
            this.setFormData(this.props.selectedLabelPopInfo);
            this.initialPage = false;
        }

        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetUserInitials?uid=" + logedOnUser)
            let url = this.props.clientgroup + "/GetUserInitials";
            let params = { uid: logedOnUser, accessToken: localStorage.getItem('token') };

            axios.post(url, params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                        } else {
                            userInitials = logedOnUser.substr(0, 20);
                        }
                        _this.setState({ userInitials: userInitials });
                    } else {
                        let userInitials = logedOnUser.substr(0, 20);
                        _this.setState({ userInitials: userInitials });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Labels";
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = (selectedLabelPopInfo) => {
        this.initialPage = false;
        this.setState({
            initialDialogForm: {
                ...selectedLabelPopInfo,
                ["numberOfYears"]: selectedLabelPopInfo.numberOfYears ? selectedLabelPopInfo.numberOfYears : "5",
                ["stdYear"]: selectedLabelPopInfo.stdYear ? selectedLabelPopInfo.stdYear : "2004",
            },
            dialogForm: {
                ...selectedLabelPopInfo,
                ["numberOfYears"]: selectedLabelPopInfo.numberOfYears ? selectedLabelPopInfo.numberOfYears : "5",
                ["stdYear"]: selectedLabelPopInfo.stdYear ? selectedLabelPopInfo.stdYear : "2004",
            },
            reload: false,
            changed: true,
            loaded: true,
            activeTab: 0,

            ["selectedSurvivalMethodOption"]: selectedLabelPopInfo.survivalMethod ? selectedLabelPopInfo.survivalMethod : "Observed Survival",
            ["selectedStageTypeOption"]: selectedLabelPopInfo.stageType ? selectedLabelPopInfo.stageType : "General",
            ["selectedStartDateOption"]: selectedLabelPopInfo.startDate ? selectedLabelPopInfo.startDate : "Diagnosis",
            ["selectedEndDateOption"]: selectedLabelPopInfo.endDate ? selectedLabelPopInfo.endDate : "Last Contact",
            ["selectedOutputTypeOption"]: selectedLabelPopInfo.sendReportTo ? selectedLabelPopInfo.sendReportTo : "Excel",

            showClockTimer: false,
            titleDialog: null,
        });

        //this.showingByGraphTypeIndex = 0;

        if ((!this.state.uAllItemFields && selectedLabelPopInfo)) {
            let fields = {};
            Object.keys(selectedLabelPopInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };

        if (selectedLabelPopInfo.sendReportTo == "Screen" && selectedLabelPopInfo.endTime)
            //@TODO...
            this.drawingCharts(selectedLabelPopInfo);
        else if (selectedLabelPopInfo.sendReportTo == "Excel" && selectedLabelPopInfo.urlOutputFile && !this.downloadFile) {
            var _this = this;
            var url = this.props.clientgroup + "/DownloadFile";
            axios.post(url, {
                urlOutputFile: selectedLabelPopInfo.urlOutputFile,
                method: 'POST',
                responseType: 'blob',//important
                accessToken: localStorage.getItem('token')
            }).then((response) => {
                // get file name from url
                var filename = selectedLabelPopInfo.urlOutputFile.substring(selectedLabelPopInfo.urlOutputFile.lastIndexOf('\\') + 1);
                console.log("filename=" + filename);

                const link = document.createElement('a');

                let excel;
                if (selectedLabelPopInfo.urlOutputFile.indexOf('/') != -1) {
                    excel = selectedLabelPopInfo.urlOutputFile.substring(selectedLabelPopInfo.urlOutputFile.lastIndexOf('/') + 1);
                } else {
                    excel = selectedLabelPopInfo.urlOutputFile.substring(selectedLabelPopInfo.urlOutputFile.lastIndexOf('\\') + 1);
                }
                link.href = this.props.clientgroup + "/" + excel;

                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();

                _this.downloadFile = true;
                return false;
            })
            .catch(function (error) {
                console.log(error);
            });

            return false;
        }

        //this.downloadFile = false;
        return false;
    }

    savePopLabelInfo = (event) => {
        event.preventDefault();
        return false;
    }

    handleOnFocus = (event, index, alias) => {
        this.props.storeToGlobal(null);
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    async Get_Data(pLabel) {
        var _this = this;
        //Labels
        await _this.props.selectAPopLabel(pLabel);

        this.setState({
            reload: true,
            isFormDirty: false,
        });

        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        this.setState({
            uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }
        });
    }
    // --END

    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;
            let textareaEditSelect = plForm.textareaEditSelect;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
            reportingForm.textareaEditSelect = textareaEditSelect;
        }

        this.setState({
            dialogForm: reportingForm,
            /*
            dialogForm: {
                ...this.state.dialogForm,
                reportingForm
            },
            */
        });
        return false;
    }

    Survival_By_Stage_Report = () => {
        var dialogFormData = this.state.dialogForm;

        var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
        dialogFormData.populationLabel = populationLabel;
        dialogFormData.labelSAP = populationLabel;

        if (dialogFormData.populationLabel == "") {
            return false;
        }

        dialogFormData.survivalMethod = this.state.selectedSurvivalMethodOption;
        dialogFormData.stageType = this.state.selectedStageTypeOption;
        dialogFormData.startDate = this.state.selectedStartDateOption;
        dialogFormData.sendReportTo = this.state.selectedOutputTypeOption;
        dialogFormData.endDate = this.state.selectedEndDateOption;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        let availableHosps = this.state.availableHosps;
        dialogFormData.availableHospsRpt = availableHosps;
        console.log("runGraphForPopulation::availableHospsRpt=" + availableHosps);

        var createPopulationLabel = document.getElementById("createPopulationLabel") ? document.getElementById("createPopulationLabel").value : "0";
        dialogFormData.createPopulationLabel = createPopulationLabel;

        //
        this.props.runSurvivalByStageReport(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }
    //--

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        var _this = this;
        setTimeout(function () {
            var dialogFormData = _this.state.dialogForm;

            var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
            dialogFormData.populationLabel = populationLabel;
            if (dialogFormData.populationLabel == "") {
                return false;
            }
            console.log("populationLabel = " + populationLabel, dialogFormData.populationLabel);

            _this.Survival_By_Stage_Report();

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });

            return false;
        }, 1000);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false, currentTime: null });
            return false;
        }, 0);

        return false;
    }

    //
    drawingCharts = (data) => {
        //
        this.setState({
            isDrawingCharts: true,
            Radio_Options: data.radioOptions,
            Surv_URL_Data_Table: data.survUrlDataTable,
            Surv_URL1: data.survUrl1,
            Surv_URL2: data.survUrl2,
            Surv_URL3: data.survUrl3,
            Surv_URL4: data.survUrl4,
            Surv_URL5: data.survUrl5,
            Surv_URL6: data.survUrl6,
            Fn_Display: data.fnDisplay,
            Fn_Display_URL: data.fnDisplayUrl,

            showClockTimer: false,//hide clock timer

            chartDatas: data.chartDatas,
            survivalChartsInPublicFolder: data.survivalChartsInPublicFolder,
        });

        console.clear();
        console.log(data.chartDatas);

        setTimeout(function () {
            document.getElementById("graphType_0") ? document.getElementById("graphType_0").click() : null;
        }, 500);

        return false;
    }

    hideDrawingCharts = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.Get_Data("");
        return false;
    }

    exitScreen(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.props.setInitialPageVisible(false);

        this.setState({
            uAllItemFields: null,
            initialDialogForm: null,
            dialogForm: null,
            isFormDirty: false,
            reload: false,
            fieldInFocus: "populationLabel",
            focusingField: null,
            isHelpVisible: false,
            loaded: false,

            // set max-length for input fields
            tableStructureList: null,
            pageMaxlengthInput: null,
            hasSetMaxLength: false,

            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,

            //Registrars -> Initials
            userInitials: "",
            labelsSiteMvMaxlengthInput: null,
            labelsMvMaxlengthInput: null,
            labelsSiteMvSapMaxlengthInput: null,
            allowSaveAnyway: true,
            availableHosps: null,
            getAvailableHosps: false,
            hospitalCodeMsgErr: null,

            //Chart configs
            isDrawingCharts: false,
            showingByGraphType: -1,

            listOfItems: null,
            chartDatas: null,
            trHtml: null,
            activeTab: "0",
            printStyle: null,

            Radio_Options: null,
            Surv_URL_Data_Table: null,
            Surv_URL1: null,
            Surv_URL2: null,
            Surv_URL3: null,
            Surv_URL4: null,
            Surv_URL5: null,
            Surv_URL6: null,
            Report_Url: null,
            Fn_Display: null,
            Fn_Display_URL: null,

            titleDialog: null,
            showClockTimer: false,
            startTimeFrom: null,
            currentTime: null,
            chartDatas: null,
        });

        return false;
    }

    printTable = () => {
        var printStyle = document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : (document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : this.state.printStyle);

        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Survival By Stage</title><style type="text/css">';
        //html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        var radioOptionList = this.state.Radio_Options && this.state.Radio_Options != "" ? this.state.Radio_Options.split("|") : [];
        radioOptionList = radioOptionList.filter((item, idx) => {
            return item != "";
        });

        let radioOptionItems;
        {
            radioOptionItems = radioOptionList.map((item, index) => {
                return (
                    <label style={{ marginTop: "5px", height: "15px" }} key={index}>
                        <input type="radio" onClick={(e) => { this.changeGraphType(e, index) }}
                            checked={this.state.showingByGraphType == index /*|| this.showingByGraphTypeIndex == "" + index*/ ? true : false}
                            name="graphType" value={item} id={"graphType_" + index}
                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                        {item}
                    </label>
                );
            });
        }

        //const chartDatas = this.state.chartDatas ? [...this.state.chartDatas] : [];

        var categories = [];
        this.state.chartDatas && this.state.chartDatas.categories ? this.state.chartDatas.categories.map((c, index) => (
            index == 0 ? categories[index] = " " : categories[index] = c
        )) : [];

        var dataset = [];

        var data;

        this.state.chartDatas && this.state.chartDatas.dataset ? this.state.chartDatas.dataset.map((d, index) => (
            dataset[index] = d,

            data = Array.from({ length: 6 }),

            d.data ? d.data.map((v, idx) => (
                data[idx] = { "value": v }
            )) : [],

            dataset[index] = { ...d, data }
        )) : [];

        //console.log(JSON.stringify(dataset));

        // Create a JSON object to store the chart configurations
        const chartConfigs = {
            type: "MSLine",// The chart type
            width: "850",// Width of the chart
            height: "320",// Height of the chart
            dataFormat: "json", // Data type

            dataSource: {
                // Chart Configuration
                "chart": {
                    "caption": "Graph for " + (this.state.chartDatas && this.state.chartDatas.chart ? this.state.chartDatas.chart.caption : ""),//Set the chart caption
                    showPrintMenuItem: '1',
                },
                // Chart Data - from step 2
                "categories": [{
                    "category": [...categories],//eval(JSON.stringify(categories))
                }],
                "dataset": [...dataset], //eval(JSON.stringify(dataset)),
            },
        };

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts && this.state.Radio_Options ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.state.dialogForm.survivalMethod}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" id="survivalByStageShowingCharts">
                                {/*Drawing table here*/}
                                <div dangerouslySetInnerHTML={{ __html: this.state.Report_Url ? this.state.Report_Url : this.state.Surv_URL_Data_Table }} className="" />
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print Table
                                            </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideDrawingCharts} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                            </button>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.showingByGraphType === 0 ? "" : "hidden"} style={{ marginLeft: "30px", marginRight: "30px" }}>
                                <div className="row" style={{ display: "inline-block", width: "100%" }}>
                                    <div className="col-sm-12">
                                        { /* Drawing chart here * / }
                                        <ReactFC {...chartConfigs} />
                                        { */ }
                                        <iframe src={this.state.survivalChartsInPublicFolder ? (this.state.survivalChartsInPublicFolder + "?alias=" + this.props.clientgroup) : ""} className={this.state.survivalChartsInPublicFolder && this.state.survivalChartsInPublicFolder != "" ? "" : "hidden"} />
                                    </div>
                                </div>
                            </div>

                            <div id="Radio_Survival_Report">
                                <div className="row">
                                    <div className="col-sm-11" style={{ display: "flex" }}>
                                        {radioOptionItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded ?
            (
                <div className="container-fluid2">

                    {/* Adding "Population Label" component on top this screen */}
                    <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />

                    <form onSubmit={this.savePopLabelInfo} id="survivalByStageForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset>
                                        <legend></legend>

                                        {/* PR00495: Remove the "extra" pop label drop-down selection * / }
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="320px"
                                                    labelText="Population Label"
                                                    field="populationLabel" lineStyle="oneLine"
                                                    value={this.state.dialogForm.populationLabel}
                                                    dataSource="Labels" autoFocus
                                                    titleDialog="Population Labels" labelWidth="110px"
                                                    inValid={this.state.uAllItemFields["populationLabel"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["label"] : ""}
                                                    lostfocused={this.handleLostFocus}
                                                />
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                        {*/}

                                        <div className="row">
                                            <div className="col-sm-3">Survival Method</div>
                                            <div className="col-sm-3">Stage Type</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label style={{ marginTop: "5px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionSurvivalMethod(e) }} onChange={(e) => { this.selectionSurvivalMethod(e) }}
                                                        checked={this.state.selectedSurvivalMethodOption === "Observed Survival"}
                                                        name="survivalMethod" value="Observed Survival" id="survivalMethod_ObservedSurvival"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Observed Survival
                                                                                                </label>
                                                <br />
                                                <label style={{ width: "240px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionSurvivalMethod(e) }} onChange={(e) => { this.selectionSurvivalMethod(e) }}
                                                        checked={this.state.selectedSurvivalMethodOption === "Observed (Kaplan Meier Method)"}
                                                        name="survivalMethod" value="Observed (Kaplan Meier Method)" id="survivalMethod_ObservedKaplanMeierMethod"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Observed (Kaplan Meier Method)
                                                                                                </label>
                                                <br />
                                                <label style={{ width: "240px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionSurvivalMethod(e) }} onChange={(e) => { this.selectionSurvivalMethod(e) }}
                                                        checked={this.state.selectedSurvivalMethodOption === "Adjusted Survival"}
                                                        name="survivalMethod" value="Adjusted Survival" id="survivalMethod_AdjustedSurvival"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Adjusted Survival
                                                                                                </label>
                                                <br />
                                                <label style={{ width: "240px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionSurvivalMethod(e) }} onChange={(e) => { this.selectionSurvivalMethod(e) }}
                                                        checked={this.state.selectedSurvivalMethodOption === "Relative Survival"}
                                                        name="survivalMethod" value="Relative Survival" id="survivalMethod_RelativeSurvival"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Relative Survival
                                                                                                </label>
                                            </div>

                                            <div className="col-sm-3">
                                                <label style={{ marginTop: "5px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionStageType(e) }} onChange={(e) => { this.selectionStageType(e) }}
                                                        checked={this.state.selectedStageTypeOption === "General"}
                                                        name="stageType" value="General" id="stageType_General"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        General
                                                                                                </label>
                                                <br />
                                                <label style={{ width: "240px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionStageType(e) }} onChange={(e) => { this.selectionStageType(e) }}
                                                        checked={this.state.selectedStageTypeOption === "AJCC"}
                                                        name="stageType" value="AJCC" id="stageType_AJCC"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        AJCC
                                                                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">Start Date</div>
                                            <div className="col-sm-3">Send Report To</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label style={{ marginTop: "5px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionStartDate(e) }} onChange={(e) => { this.selectionStartDate(e) }}
                                                        checked={this.state.selectedStartDateOption === "Diagnosis"}
                                                        name="startDate" value="Diagnosis" id="startDate_Diagnosis"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Diagnosis
                                                                                                </label>
                                                <br />
                                                <label style={{ width: "240px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionStartDate(e) }} onChange={(e) => { this.selectionStartDate(e) }}
                                                        checked={this.state.selectedStartDateOption === "Treatment"}
                                                        name="startDate" value="Treatment" id="startDate_Treatment"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Treatment
                                                                                                </label>
                                            </div>

                                            <div className="col-sm-3">
                                                <label style={{ width: "240px", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionOutputType} onChange={(e) => { this.selectionOutputType(e) }}
                                                        checked={this.state.selectedOutputTypeOption === "Excel"}
                                                        name="sendReportTo" field="sendReportTo" value="Excel"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Excel
                                                                        </label>
                                                <br />
                                                <label style={{ width: "240px", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionOutputType} onChange={(e) => { this.selectionOutputType(e) }}
                                                        checked={this.state.selectedOutputTypeOption === "Screen"}
                                                        name="sendReportTo" field="sendReportTo" value="Screen"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Screen
                                                                        </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">End Date</div>
                                            <div className="col-sm-3"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label style={{ marginTop: "5px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionEndDate(e) }} onChange={(e) => { this.selectionEndDate(e) }}
                                                        checked={this.state.selectedEndDateOption === "Last Contact"}
                                                        name="endDate" value="Last Contact" id="endDate_LastContact"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Last Contact
                                                                                                </label>
                                                <br />
                                                <label style={{ width: "240px", height: "15px" }}>
                                                    <input type="radio" onClick={(e) => { this.selectionEndDate(e) }} onChange={(e) => { this.selectionEndDate(e) }}
                                                        checked={this.state.selectedEndDateOption === "Recurrence"}
                                                        name="endDate" value="Recurrence" id="endDate_Recurrence"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                        Recurrence
                                                                                                </label>
                                            </div>

                                            <div className="col-sm-3">
                                                <Input type="input" focused={this.handleOnFocus}
                                                    labelWidth="110px" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                    labelText="Number of Years" field="numberOfYears" width="40px"
                                                    value={this.state.dialogForm.numberOfYears ? this.state.dialogForm.numberOfYears : "0"}
                                                    changed={this.handleFormChange}
                                                    lostfocused={this.handleLostFocus}
                                                />

                                                <label style={{ display: this.state.selectedSurvivalMethodOption === "Relative Survival" ? "" : "none" }}>
                                                    <select type="radio" onChange={this.handleFormChange} width="100px"
                                                        value={this.state.dialogForm.stdYear}
                                                        name="stdYear" field="stdYear"
                                                        className="stdYearSelection">
                                                        <option value="1970">1970</option>
                                                        <option value="1980">1980</option>
                                                        <option value="2004">2004</option>
                                                    </select>
                                                    Std Year:
                                                </label>
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>

                                    </fieldset>

                                    <div className="emptyLine"></div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3 _hidden">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Selected" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>

                                    <div id="CONTAINER_JS_CLOCK" className="hidden">
                                        <label>Selecting Cases</label>
                                        <div id="IMAGE_WAIT" style={{ color: "#05004c", fontWeight: "bold" }}>
                                            Selecting Cases - Please Wait ...
                                        </div>
                                    </div>

                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_RUN_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={this.state.isFormDirty || (this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "") ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.newRecord(e) }}
                                                        disabled={/*!this.state.isFormDirty ||*/ !this.state.dialogForm.populationLabel || this.state.dialogForm.populationLabel == ""}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_EXIT" className="ErsGrBlueButton"
                                                        style={{ display: this.state.onlyShowOnModal ? "" : "" }}
                                                        onClick={(e) => { this.exitScreen(e) }}
                                                    >
                                                        <img id="BUTTON_EXIT_ICON" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                            Exit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>
                </div>
            )
            : <p>Survival By Stage data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

SurvivalByStage.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        showToggleBtn: true,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePopLabelInfo: (dialogForm) => dispatch(actionCreators.saveAPopLabel(dialogForm)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        deleteAPopLabel: (dialogForm) => dispatch(actionCreators.deleteAPopLabel(dialogForm)),
        runSurvivalByStageReport: (dialogForm) => dispatch(actionCreators.runSurvivalByStageReport(dialogForm)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurvivalByStage);