/* eslint-disable */
import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { connect } from 'react-redux';
import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import axios from "../../axios-instance";
import * as actions from '../../store/actions/index';
import Draggable from 'react-draggable';

//export default function OktaLoginCallback(props) {
const OkCallback = () => {

    const { authState, oktaAuth } = useOktaAuth();

    const [showMultiGroup, setShowMultiGroup] = useState(false);
    const [userList, setUserList] = useState([]);

    const [email, setEmail] = useState('');
    const [action, setAction] = useState('callback');

    const [submitData, setSubmitData] = useState(false);

    useEffect(() => {
        //console.log(authState, oktaAuth);
        if (authState && authState.isAuthenticated) {

            oktaAuth.token.getUserInfo().then(info => {

                if (info && info.email && action == "callback") {
                    var _email = info.email;

                    let url = "/ers/OktaLoginCallback";
                    let params = { email: _email, action: "callback" };

                    axios.post(url, params)
                        .then(response => {
                            if (!response.data.error) {
                                if (response.data.userList) {
                                    setShowMultiGroup(true);
                                    setSubmitData(false);
                                    setUserList(response.data.userList);
                                    setEmail(response.data.userList[0] ? response.data.userList[0].email : response.data.email);
                                    return false;
                                } else {
                                    setAction("recall");//set this to show loading effect...
                                }

                                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 60 * 1000);
                                localStorage.setItem('token', response.data.acessToken);
                                localStorage.setItem('expirationDate', expirationDate);
                                localStorage.setItem('userId', response.data.username);
                                localStorage.setItem('clientAlias', response.data.clientgroup);

                                //
                                localStorage.setItem('crstar_user', JSON.stringify(response.data));
                                localStorage.setItem('crstar_user_by_okta', JSON.stringify(response.data));

                                sessionStorage.setItem('crstar_user', JSON.stringify(response.data));
                                sessionStorage.setItem('crstar_user_by_okta', JSON.stringify(response.data));

                                sessionStorage.removeItem('oktaAuthFailed');
                                sessionStorage.removeItem('CrstarLogout');

                                window.location.href = window.location.origin;
                                return true;
                            } else if (action == "callback") {
                                alert(response.data.message);
                                //oktaAuth.signOut();

                                sessionStorage.setItem('oktaAuthFailed', "1");
                                sessionStorage.setItem('oktaLoggedOnUser', _email);

                                window.location.href = window.location.origin;
                                return false;
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            return false;
                        });
                }
            });
        }

        if (submitData) {
            setSubmitData(false);
            setAction("recall");

            let _url = "/ers/OktaLoginCallback";
            let _params = { email: email, action: "recall" };

            axios.post(_url, _params)
                .then(response => {
                    if (!response.data.error) {
                        const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 60 * 1000);
                        localStorage.setItem('token', response.data.acessToken);
                        localStorage.setItem('expirationDate', expirationDate);
                        localStorage.setItem('userId', response.data.username);
                        localStorage.setItem('clientAlias', response.data.clientgroup);

                        //
                        localStorage.setItem('crstar_user', JSON.stringify(response.data));
                        localStorage.setItem('crstar_user_by_okta', JSON.stringify(response.data));

                        sessionStorage.setItem('crstar_user', JSON.stringify(response.data));
                        sessionStorage.setItem('crstar_user_by_okta', JSON.stringify(response.data));

                        sessionStorage.removeItem('oktaAuthFailed');
                        sessionStorage.removeItem('CrstarLogout');

                        window.location.href = window.location.origin;
                        return true;
                    }
                });
        }
    }, [authState, oktaAuth, submitData, action]);// Update if authState changes

    return (
        <>
            <div>
                {action == "recall" || !showMultiGroup ? <div className="loader">Processing... Please wait!</div> : null}
            </div>

            <div>
                {
                    showMultiGroup ?
                        <div className="CRStar_Window">
                            <div className="fake_popup_cls"></div>
                            <Draggable handle=".k-window-titlebar">
                                <div className="dragableWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title k-dialog-title">Selection Client Group</div>
                                        <div className="k-window-actions k-dialog-actions hidden">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x" onClick={e => { setShowMultiGroup(false) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="container center">
                                        <div className="row">
                                            <div className="col-sm-12" id="oktaRedirectWithSelectedClientGroup">
                                                <Grid
                                                    data={userList}
                                                    filterable="false"
                                                    onRowClick={(e) => { setEmail(e.dataItem.email); setSubmitData(true); }}
                                                    scrollable="scrollable"
                                                    selectedField="selected">
                                                    <GridColumn field="email" filter="text" title="Email" />
                                                    <GridColumn field="clientGroup" filter="text" title="Client Group" />
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Draggable>
                        </div>
                        : null
                }
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        oktaLoginSuccess: (userInfo) => dispatch(actions.oktaLoginSuccess(userInfo)),
    };
};

const CallbackWithRouterAccess = withRouter(connect(null, mapDispatchToProps)(OkCallback));

class OktaLoginCallback extends Component {

    state = {

    };

    constructor(props) {
        super(props);

        this.oktaLoginSuccess = this.oktaLoginSuccess.bind(this);
    }

    oktaLoginSuccess() {
        const user = JSON.parse(localStorage.getItem('crstar_user_by_okta'));
        if (user) {
            this.props.oktaLoginSuccess(user);
        }
    }

    componentDidMount = () => {
        /*
        const user = JSON.parse(localStorage.getItem('crstar_user_by_okta'));
        if (user) {
            OkCallback.props.oktaLoginSuccess(user);
        }
        */
    }

    componentDidUpdate() {
        /*
        const user = JSON.parse(localStorage.getItem('crstar_user_by_okta'));
        if (user) {
            OkCallback.props.oktaLoginSuccess(user);
        }
        */
    }


    hideSelectionModal = (event, obj) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        obj.setState({ showSelectionModal: false });

        return false;
    }

    render() {
        let showSelectionModal = null;
        {
            showSelectionModal = this.state.showSelectionModal ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selection client group</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideSelectionModal(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div className="col-sm-3">
                                    <label className="">Client Group: </label>
                                </div>
                                <div className="col-sm-9">
                                    <select className="">
                                        {
                                            this.state.userList !== null && this.state.userList.length > 0
                                                ? this.state.userList.map((usr, index) => (
                                                    <option value={usr.clientGroup ? usr.clientGroup : "ERS"} key={"userItem" + index}
                                                    //selected={userHosp == prm.hospNbr}
                                                    >
                                                        {usr.clientGroup + " - " + usr.email}
                                                    </option>
                                                ))
                                                : <option value="">Loading...</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideSelectionModal(e, this) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        //return (<Router><CallbackWithRouterAccess /></Router>);
        return <CallbackWithRouterAccess />;
        { /*}
        <>
            <>
                {showSelectionModal}
            </>
        </>
        {*/ }
    }
}

export default withOktaAuth(OktaLoginCallback);
