/* eslint-disable */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from './components/Layout';
import Login from './components/UI/Login/Login';
import ForgotPassword from './components/UI/ForgotPassword/ForgotPassword';
import ChangePassword from './components/UI/ChangePassword/ChangePassword';
import PatientAbstract from './components/AbstractManagement/PatientAbstract/PatientAbstract';
import AbstractUtils from './components/AbstractManagement/AbstractUtils/AbstractUtils';
import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import Aframe from './components/Aframe';
import UserSecurityMaintLookup from './components/SystemAdministration/UserSecurityMaintLookup';
import ChangePwdDialog from "./components/SystemAdministration/SecurityMaintenance/ChangePwdDialog";
import TwoFactorAuthDialog from "./components/SystemAdministration/SecurityMaintenance/TwoFactorAuthDialog";
import * as actions from './store/actions/index';
import { actionCreators } from "./store/PatientAbstract";
import './assets/css/site.css';
//import './assets/css/all.css';
import './assets/css/alpha_style_v1.0.css';
import UtilityPage from './components/SystemAdministration/UtilityPage';
import SelectAPop from './components/Reporting/SelectAPop/SelectAPop';
import GlobalChange from './components/SystemAdministration/DataManagment/GlobalDataChange/GlobalChange';
import UtilMainTabs from './components/SystemAdministration/Utilities/GlobalDir';

import SiteDistIncidence from './components/Reporting/ReaCannedReports/Tabs/SiteDistIncidence';
import Suspense from './components/Reporting/ReaRegistryMgmtReports/Tabs/Suspense';
import AccRosterMpi from './components/Reporting/ReaAdminReports/Tabs/AccRosterMpi';
import Coc from './components/Reporting/ReaAccreditationReports/Tabs/Coc';
import HospitalsMaintLookup from './components/SystemManagement/HospitalsMaintLookup/HospitalsMaintLookup';
import PrintAbstractsMaint from './components/AbstractManagement/PrintForms/PrintAbstractsMaint';

import EditsSettingsMaint from './components/SystemConfiguration/GenEdits/EditsSettingsMaint';
import DeletePatientAbstactMaint from './components/AbstractManagement/AbstractUtils/DeleteAbstract/DeletePatientAbstactMaint';
import StageComparisons from './components/Reporting/ReaComparisonReports/Tabs/StageComparisons';
import ListPop from './components/Reporting/ReaAdhocReports/Tabs/ListPop';
import AgeBySex from './components/Reporting/ReaMiscellaneousReports/Tabs/AgeBySex';
import StateExport from './components/Reporting/ReaDataExports/Tabs/StateExport';
import ClearPopulations from './components/Reporting/ReportUtility/Tabs/ClearPopulations';

import NlpWorkQueue from './components/AbstractManagement/NlpWorkQueue/NlpWorkQueue';

import GlobalDirMaintLookup from './components/SystemAdministration/GlobalDirMaintLookup/GlobalDirMaintLookup';
import ZipCodesMaintLookup from './components/SystemManagement/ZipCodesMaintenance/ZipCodeMaintLookup';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import Profile from './containers/Auth/Profile';
import OktaLoginCallback from './containers/Auth/OktaLoginCallback';
//import { withOktaAuth } from '@okta/okta-react';

// Adding the license of Fusion Charts
import FusionCharts from "fusioncharts";
FusionCharts.options.license({
    key: '0oI2cB-21A-16E2D6E2A4H4A2G3B5A3C7A3B4wjjH3B5D7jF4D5D3D-8buB6A6E3sudC1G2B1wikB1A4B4B2A21A14B12A4D8D5B4lffB2A5UA5vraA2A1A1pcB2DB2G2yyjC2B2C8D4E3D2F2F3A1A2D8B2D2F4p==',
    creditLabel: false,
});

// OKTA 
const oktaAuth = new OktaAuth({
    issuer: 'https://sso.healthcatalyst.cloud/oauth2/default',
    clientId: '0oaa3q0tdnAWttfIn697',
    //issuer: 'https://dev-42708933.okta.com/oauth2/default',
    //clientId: '0oaghsrmcekKxVGWl5d7',
    redirectUri: window.location.origin + '/login/callback'
});

class App extends Component {

    constructor(props) {
        super(props);

        //TODO: Move to mapStateToProps?
        this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
            props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
        };
    }

    state = {
        userid: null,
        redirectTo: null,
    }

    addEventListener = () => {
        var app = this;
        window.addEventListener('click', function (e) {
            if (e.target && e.target.nodeName == "INPUT" || (e.target.nodeName == "BUTTON" && e.target.getAttribute("class") && e.target.getAttribute("class").indexOf("codeSelectIcon") != -1)) {
            } else {
                app.props.storeToGlobal(null);//clear stored everything
            }
        });
        window.addEventListener('keydown', function (e) {
            if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.target.form && e.target.form.id === "selectAPopForm") {
                    app.props.storeToGlobal(null);//clear stored everything
                    return false;
                }

                //
                if (e.target.nodeName === "BUTTON" && e.target.id === "btnExitForm_Fake") {
                    var extra_btn_0 = document.getElementById("extra_btn_0");
                    if (e.key === "Tab" && !e.shiftKey && !extra_btn_0) {
                        var currentMainForm = document.querySelector('.DialogForm.TabParentPage');
                        e.preventDefault();
                        setTimeout(function () {
                            if (currentMainForm) {
                                currentMainForm.querySelectorAll('input[type="text"]')[0] ? currentMainForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                            } else if (document.querySelector('.DialogForm.PopupChildrenPage')) {
                                currentMainForm = document.querySelector('.DialogForm.PopupChildrenPage');
                                currentMainForm.querySelectorAll('input[type="text"]')[0] ? currentMainForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                            }
                        }, 0);
                        return false;
                    } else if (e.key === "Tab" && !e.shiftKey && document.querySelector('.DialogForm.PopupChildrenPage')) {
                        var currentMainForm = document.querySelector('.DialogForm.PopupChildrenPage');
                        e.preventDefault();
                        var surgeryForm = document.getElementById("surgeryForm");
                        setTimeout(function () {
                            if (surgeryForm) {
                                var linkedTab = document.getElementById('linkOfCommonTab');
                                if (linkedTab && linkedTab.getAttribute("class") && linkedTab.getAttribute("class").indexOf("active") == -1) {
                                    document.getElementById('linkOfCommonTab').click();
                                } else {
                                    if (surgeryForm.querySelector("#hemaText0")) {
                                        surgeryForm.querySelector("#hemaText0").focus();
                                    } else {
                                        surgeryForm.querySelectorAll('input[type="text"]')[0] ? surgeryForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                                    }
                                }
                            } else {

                            }
                        }, 0);
                        return false;
                    }
                } else if (e.target.nodeName === "BUTTON" && e.target.id === "extra_btn_0") {
                    if (e.key === "Tab" && !e.shiftKey) {
                        if (!document.getElementById("extra_btn_1")) {
                            var currentMainForm = document.querySelector('.DialogForm.TabParentPage');
                            e.preventDefault();
                            var treatmentForm = document.getElementById("treatmentForm");
                            var cocForm = document.getElementById("cocForm");
                            var userDefinedForm = document.getElementById("userDefinedForm");
                            var retiredForm = document.getElementById("retiredForm");
                            setTimeout(function () {
                                if (treatmentForm) {
                                    if (treatmentForm.querySelector("#initialRxDateMonth0") && treatmentForm.querySelector("#initialRxDateMonth0").parentNode.parentNode.style.display !== "none") {
                                        treatmentForm.querySelector("#initialRxDateMonth0").focus();
                                    } else if (treatmentForm.querySelector("#rxSummRxStatus")) {
                                        treatmentForm.querySelector("#rxSummRxStatus").focus();
                                    }
                                    return false;
                                } else if (cocForm) {
                                    var linkedTab = document.getElementById('linkOfCCServicesTab');
                                    if (linkedTab && linkedTab.getAttribute("class") && linkedTab.getAttribute("class").indexOf("active") == -1) {
                                        document.getElementById('linkOfCCServicesTab').click();
                                    } else {
                                        cocForm.querySelectorAll('input[type="text"]')[0] ? cocForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                                    }
                                    return false;
                                } else if (userDefinedForm) {
                                    var linkedTab = document.getElementById('linkOfUserDefined1Tab');
                                    if (linkedTab && linkedTab.getAttribute("class") && linkedTab.getAttribute("class").indexOf("active") == -1) {
                                        document.getElementById('linkOfUserDefined1Tab').click();
                                    } else {
                                        userDefinedForm.querySelectorAll('input[type="text"]')[0] ? userDefinedForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                                    }
                                    return false;
                                } else if (retiredForm) {
                                    var linkedTab = document.getElementById('linkOfRetiredTab1');
                                    if (linkedTab && linkedTab.getAttribute("class") && linkedTab.getAttribute("class").indexOf("active") == -1) {
                                        document.getElementById('linkOfRetiredTab1').click();
                                    } else {
                                        retiredForm.querySelectorAll('input[type="text"]')[0] ? retiredForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                                    }
                                    return false;
                                }
                                currentMainForm.querySelectorAll('input[type="text"]')[0] ? currentMainForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                            }, 0);
                            return false;
                        }
                    }
                } else if (e.target.nodeName === "BUTTON" && e.target.id === "extra_btn_1") {
                    if (e.key === "Tab" && !e.shiftKey) {
                        var currentMainForm = document.querySelector('.DialogForm.TabParentPage');
                        var stagingForm = document.getElementById("stagingForm");

                        e.preventDefault();
                        setTimeout(function () {
                            if (stagingForm) {
                                var linkedTab = document.getElementById('linkOfStagingTab');
                                if (linkedTab && linkedTab.getAttribute("class") && linkedTab.getAttribute("class").indexOf("active") == -1) {
                                    document.getElementById('linkOfStagingTab').click();
                                } else {
                                    stagingForm.querySelectorAll('input[type="text"]')[0] ? stagingForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                                }
                                stagingForm.querySelector("#ndPos") ? stagingForm.querySelector("#ndPos").focus() : null;
                                return false;
                            }
                            currentMainForm.querySelectorAll('input[type="text"]')[0] ? currentMainForm.querySelectorAll('input[type="text"]')[0].focus() : "";
                        }, 0);
                        return false;
                    }
                } else if (e.target.nodeName === "BUTTON" && e.target.innerHTML == "Delete" && e.target.form && e.target.form.id === "surgeryForm") {
                    if (e.key === "Tab" && !e.shiftKey) {
                        e.preventDefault();
                        setTimeout(function () {
                            var linkedTab = document.getElementById('linkofBreast22Tab');
                            if (linkedTab && linkedTab.getAttribute("class") && linkedTab.getAttribute("class").indexOf("active") == -1) {
                                document.getElementById('linkofBreast22Tab').click();
                            } else {
                                var currentRxSummaryModal = document.querySelector('.CRStar_Window');
                                currentRxSummaryModal.querySelector("#btnSubmitForm_Fake") && !currentRxSummaryModal.querySelector("#btnSubmitForm_Fake").disabled ? currentRxSummaryModal.querySelector("#btnSubmitForm_Fake").focus() : (currentRxSummaryModal.querySelector("#btnExitForm_Fake") ? currentRxSummaryModal.querySelector("#btnExitForm_Fake").focus() : "");
                            }
                        }, 0);
                        return false;
                    }
                }

                // Diagnosis Text / Treatment & Other Text
                var event = e;
                if (event.target.form && event.target.form.id === "diagnosisTextForm") {
                    var currentInput = e.target.name;
                    if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                        if (event.target.nodeName === "BUTTON") {
                            if (event.target.nextSibling) {
                                document.getElementById('importedText_TextPadLbl') ? document.getElementById('importedText_TextPadLbl').focus() : "";
                                setTimeout(function () {
                                    var textPadForm = document.getElementById("diagnosisTextForm");
                                    if (textPadForm) {
                                        textPadForm.querySelector("#LBL-importedText") ? textPadForm.querySelector("#LBL-importedText").scrollIntoView() : document.getElementById("#LBL-importedText").scrollIntoView();
                                    }
                                    return false;
                                }, 100);
                                event.preventDefault();
                                return false;
                            }
                            return false;
                        } else if (currentInput === "primSiteText") {
                            document.getElementById('importedText_TextPadLbl') ? document.getElementById('importedText_TextPadLbl').focus() : "";
                            setTimeout(function () {
                                var textPadForm = document.getElementById("diagnosisTextForm");
                                if (textPadForm) {
                                    textPadForm.querySelector("#LBL-importedText") ? textPadForm.querySelector("#LBL-importedText").scrollIntoView() : document.getElementById("#LBL-importedText").scrollIntoView();
                                }
                                return false;
                            }, 100);
                            event.preventDefault();
                            return false;
                        }
                    } else if (event.key === "Tab" && event.keyCode == 9) {
                        if (event.target.nodeName === "BUTTON") {
                            if (!event.target.nextSibling || (event.target.nextSibling && event.target.nextSibling.disabled)) {
                                document.getElementById('primSiteText_TextPadLbl') ? document.getElementById('primSiteText_TextPadLbl').focus() : "";
                                setTimeout(function () {
                                    var textPadForm = document.getElementById("diagnosisTextForm");
                                    if (textPadForm) {
                                        textPadForm.querySelector("#LBL-primSiteText") ? textPadForm.querySelector("#LBL-primSiteText").scrollIntoView() : document.getElementById("#LBL-primSiteText").scrollIntoView();
                                    }
                                    return false;
                                }, 100);
                                event.preventDefault();
                                return false;
                            }
                            return false;
                        }
                    }
                }

                // Draggable modal
                var currentDialogModal = document.querySelector('.CRStar_Window');
                if (e.target.nodeName === "BUTTON" && e.target.getAttribute('class') === "validationErr_btnOk_Cls") {
                    e.preventDefault();
                    return false;
                } else if (app.props.showingHelpInfo) {
                    e.preventDefault();
                    return false;

                } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnExitForm_Fake"
                    && currentDialogModal && currentDialogModal.querySelector('.DialogForm.PopupChildrenPage') !== null) {
                    if (e.key === "Tab" && !e.shiftKey) {
                        e.preventDefault();
                        setTimeout(function () {
                            currentDialogModal.querySelectorAll('input')[0] ? currentDialogModal.querySelectorAll('input')[0].focus() : "";
                        }, 500);
                        return false;
                    }

                } else if (e.target.nodeName === "BUTTON"
                    && (e.target.id === "btnExitLookupHospital" || e.target.id === "btnExitLookupRegistrar" || e.target.id === "btnExitLookupPhysician" || e.target.id === "btnExitLookupGlobalDir")) {
                    if (e.key === "Tab" && !e.shiftKey) {
                        e.preventDefault();
                        setTimeout(function () {
                            currentDialogModal.querySelectorAll('input')[0] ? currentDialogModal.querySelectorAll('input')[0].focus() : "";
                        }, 500);
                        return false;
                    }

                    // Physicians/Hospital/Registrars/Global Dir Form
                } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnExitForm_Fake") {
                    // Not opened from Physicians Lookup grid -> Add New
                    if (currentDialogModal && currentDialogModal.querySelector('.DialogForm2.physicians_maint_form') !== null) {
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                currentDialogModal.querySelector("#btnDeleteItem_Fake") && !currentDialogModal.querySelector("#btnDeleteItem_Fake").disabled ? currentDialogModal.querySelector("#btnDeleteItem_Fake").focus() : (currentDialogModal.querySelectorAll('input')[0] ? currentDialogModal.querySelectorAll('input')[0].focus() : "");
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.physicians_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.physicians_maint_form').parentNode;
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelector("#btnDeleteItem_Fake") && !childDialogForm.querySelector("#btnDeleteItem_Fake").disabled ? childDialogForm.querySelector("#btnDeleteItem_Fake").focus() : (childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "");
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.hospital_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.hospital_maint_form').parentNode;
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelector("#btnDeleteItem_Fake") && !childDialogForm.querySelector("#btnDeleteItem_Fake").disabled ? childDialogForm.querySelector("#btnDeleteItem_Fake").focus() : (childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "");
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.registrars_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.registrars_maint_form').parentNode;
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelector("#btnDeleteItem_Fake") && !childDialogForm.querySelector("#btnDeleteItem_Fake").disabled ? childDialogForm.querySelector("#btnDeleteItem_Fake").focus() : (childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "");
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.followUpOrderMaintForm')) {
                        var childDialogForm = document.querySelector('.DialogForm2.followUpOrderMaintForm').parentNode;
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelector("#btnDeleteItem_Fake") && !childDialogForm.querySelector("#btnDeleteItem_Fake").disabled ? childDialogForm.querySelector("#btnDeleteItem_Fake").focus() : (childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "");
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.globaldir_maint_form')) {
                        var childDialogForm = this.document.querySelector('.DialogForm2.globaldir_maint_form').parentNode;
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelector("#btnDeleteItem_Fake") && !childDialogForm.querySelector("#btnDeleteItem_Fake").disabled ? childDialogForm.querySelector("#btnDeleteItem_Fake").focus() : (childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "");
                            }, 500);
                            return false;
                        }
                    }
                } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnDeleteItem_Fake") {
                    // Not opened from Physicians Lookup grid -> Add New
                    if (currentDialogModal && currentDialogModal.querySelector('.DialogForm2.physicians_maint_form') !== null) {
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                currentDialogModal.querySelectorAll('input')[0] ? currentDialogModal.querySelectorAll('input')[0].focus() : "";
                            }, 500);
                            return false;
                        }

                    } else if (document.querySelector('.DialogForm2.physicians_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.physicians_maint_form');
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "";
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.hospital_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.hospital_maint_form');
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "";
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.registrars_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.registrars_maint_form');
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "";
                            }, 500);
                            return false;
                        }
                    } else if (document.querySelector('.DialogForm2.registrars_maint_form')) {
                        var childDialogForm = document.querySelector('.DialogForm2.globaldir_maint_form');
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                childDialogForm.querySelectorAll('input')[0] ? childDialogForm.querySelectorAll('input')[0].focus() : "";
                            }, 500);
                            return false;
                        }
                    }

                    // Hospital Form
                } else if (e.target.nodeName === "BUTTON" && e.target.id === "deleteSelectedAliasBtn") {
                    if (e.key === "Tab" && !e.shiftKey) {
                        e.preventDefault();
                        setTimeout(function () {
                            document.getElementById("linkOfCountiesTab") ? document.getElementById("linkOfCountiesTab").click() : "";
                        }, 500);
                        return false;
                    }

                    //Patient Lookup grid
                } else if (e.target.nodeName == "BUTTON" && e.target.innerHTML == "Search" && e.target.parentNode.parentNode.id == "patientAbstractGridView") {
                    var patientAbstract = document.getElementById("patientAbstractGridView");
                    if (patientAbstract && patientAbstract.querySelectorAll("input.k-input-inner")) {
                        var inputs = patientAbstract.querySelectorAll("input.k-input-inner");
                        if (e.key === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                inputs[0] ? inputs[0].focus() : null;
                            }, 0);
                            return false;
                        }
                    }
                } else if (e.target.nodeName == "INPUT" && e.target.getAttribute("class") == "k-input-inner" && e.target.parentNode.parentNode.parentNode.parentNode.getAttribute("aria-colindex") == "2") {
                    var patientAbstract = document.getElementById("patientAbstractGridView");
                    if (patientAbstract && patientAbstract.querySelector("#btnSearchPA")) {
                        if (e.key === "Tab" && e.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                patientAbstract.querySelector("#btnSearchPA").focus();
                            }, 0);
                            return false;
                        }
                    }

                } else {
                    const val = e.target.getAttribute('value');
                    if (e.target.nodeName === "BUTTON" && val === "yes") {
                        currentDialogModal.setAttribute("tabindex", "0");
                        setTimeout(function () {
                            event.target.nextSibling && !event.target.nextSibling.disabled ? event.target.nextSibling.setAttribute("tabindex", "0") : "";
                            event.target.nextSibling && !event.target.nextSibling.disabled ? event.target.nextSibling.focus() : "";
                            return false;
                        }, 0);
                    } else if (e.target.nodeName === "BUTTON" && val === "no" &&
                        (e.target.form === null || e.target.form.id !== "securityTwoFactorAuthForm")) {
                        currentDialogModal.setAttribute("tabindex", "0");
                        setTimeout(function () {
                            event.target.previousSibling ? event.target.previousSibling.setAttribute("tabindex", "0") : "";
                            event.target.previousSibling ? event.target.previousSibling.focus() : "";
                            return false;
                        }, 0);

                        // 
                        if (event.key === "Tab" && event.keyCode == 9 && !event.shiftKey && !event.target.nextSibling) {
                            e.preventDefault();
                            return false;
                        }

                        //Web Links - tab on Exit button
                    } else if (e.target.id === "btnWebLinksExit") {
                        var allScreenButton = document.querySelectorAll(".ScreenButton");
                        if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                            allScreenButton && allScreenButton[allScreenButton.length - 1] ? allScreenButton[allScreenButton.length - 1].focus() : "";
                        } else if (event.key === "Tab" && event.keyCode == 9) {
                            allScreenButton && allScreenButton[0] ? allScreenButton[0].focus() : "";
                        }
                        e.preventDefault();
                        return false;

                        //Web Links - tab on link
                    } else if (e.target.nodeName === "BUTTON" && e.target.getAttribute("class") === "ScreenButton") {
                        if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                            if (!e.target.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.previousSibling === null) {
                                e.preventDefault();
                                setTimeout(function () {
                                    document.getElementById("btnWebLinksExit") ? document.getElementById("btnWebLinksExit").focus() : "";
                                }, 0);
                                return false;
                            }
                        }

                        //User Maintenance
                    } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnDeleteItem_Fake" && document.getElementById("securityUserForm") && document.getElementById("securityUserForm").length > 0) {
                        if (event.key === "Tab" && !event.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                document.getElementById("Userid") ? document.getElementById("Userid").focus() : "";
                            }, 0);
                            return false;
                        }
                    } else if (document.getElementById("securityUserForm") && document.getElementById("securityUserForm").length > 0 && e.target.id === "Userid") {
                        if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                            e.preventDefault();
                            setTimeout(function () {
                                document.getElementById("btnDeleteItem_Fake") ? document.getElementById("btnDeleteItem_Fake").focus() : "";
                            }, 0);
                            return false;
                        }

                        //Pattern Builder
                    } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnHidePatternBuilder" && document.getElementById("patternBuilderEditForm")/* && document.getElementById("patternBuilderEditForm").length > 0*/) {
                        if (event.key === "Tab" && !event.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                document.getElementById("minCharTextInput") ? document.getElementById("minCharTextInput").focus() : "";
                            }, 0);
                            return false;
                        }
                    } else if (document.getElementById("patternBuilderEditForm") && e.target.id === "minCharTextInput") {
                        if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                            e.preventDefault();
                            setTimeout(function () {
                                document.getElementById("btnHidePatternBuilder") ? document.getElementById("btnHidePatternBuilder").focus() : "";
                            }, 0);
                            return false;
                        }

                        //Test Password Validation Pattern
                    } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnCloseTestBuilder" && document.getElementById("testPasswordValidationPatternForm")) {
                        if (event.key === "Tab" && !event.shiftKey) {
                            e.preventDefault();
                            setTimeout(function () {
                                document.getElementById("patternTestTextInput") ? document.getElementById("patternTestTextInput").focus() : "";
                            }, 0);
                            return false;
                        }
                    } else if (document.getElementById("testPasswordValidationPatternForm") && e.target.id === "patternTestTextInput") {
                        if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                            e.preventDefault();
                            setTimeout(function () {
                                document.getElementById("btnCloseTestBuilder") ? document.getElementById("btnCloseTestBuilder").focus() : "";
                            }, 0);
                            return false;
                        }

                        //Change Password dialog
                    } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnClearChangePwdForm" && e.target.form !== null && e.target.form.id === "securityChangePwdForm") {
                        if (event.key === "Tab" && !event.shiftKey) {
                            if (!app.props.passExpired) {
                                document.getElementById("passwordNet") ? document.getElementById("passwordNet").focus() : "";
                                setTimeout(function () {
                                    document.getElementById("passwordNet") ? document.getElementById("passwordNet").focus() : "";
                                }, 0);
                            } else {
                                document.getElementById("passwordNetDb") ? document.getElementById("passwordNetDb").focus() : "";
                                setTimeout(function () {
                                    document.getElementById("passwordNetDb") ? document.getElementById("passwordNetDb").focus() : "";
                                }, 0);
                            }
                            return false;
                        }

                        //Configure Two Factor Authentication (No input field)
                    } else if (e.target.nodeName === "BUTTON" && e.target.id === "btnIgnoreTfaConfig" && document.getElementById("securityCodeInputForm")) {
                        if (event.key === "Tab" && !event.shiftKey) {
                            document.getElementById("securityCode") ? document.getElementById("securityCode").focus() : "";
                            setTimeout(function () {
                                document.getElementById("securityCode") ? document.getElementById("securityCode").focus() : "";
                            }, 100);
                            e.preventDefault();
                            return false;
                        }
                    } else if (document.getElementById("securityCodeInputForm") && e.target.id === "securityCode") {
                        if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                            document.getElementById("btnIgnoreTfaConfig") ? document.getElementById("btnIgnoreTfaConfig").focus() : "";
                            setTimeout(function () {
                                document.getElementById("btnIgnoreTfaConfig") ? document.getElementById("btnIgnoreTfaConfig").focus() : "";
                            }, 100);
                            e.preventDefault();
                            return false;
                        }
                    } else if (event.target.id === "btnIgnoreTfaConfig" && event.target.form.id === "securityTwoFactorAuthForm") {
                        if ((event.key === "Tab" && !event.shiftKey)) {
                            currentDialogModal.setAttribute("tabindex", "0");
                            setTimeout(function () {
                                event.target.previousSibling ? event.target.previousSibling.setAttribute("tabindex", "0") : "";
                                event.target.previousSibling ? event.target.previousSibling.focus() : "";
                                return false;
                            }, 0);
                            e.preventDefault();
                            return false;
                        }

                    } else if (event.target.id === "btnHideTextEditorPopup") {
                        setTimeout(function () {
                            currentDialogModal.querySelector(".textEditorOnPopupCls") ? currentDialogModal.querySelector(".textEditorOnPopupCls").focus() : "";
                        }, 0);
                        return false;
                    }

                    return false;
                }
            } else if (app.props.showingHelpInfo) {
                if (e.keyCode !== 27) {
                    e.preventDefault();
                    return false;
                }

            } else if (e.keyCode == 13) {
                // @TODO: fixing #PR00483
                app.props.storeToGlobal(null);//clear stored everything
            }
            return false;
        });

        return false;
    }

    /**/
    componentDidMount() {
        this.addEventListener();
        this.props.onTryAutoSignup();
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.userid === null && this.props.userId !== null) {
            this.setState({ userid: this.props.userId });
        }

        if ((this.state.redirectTo === null
            || (this.state.redirectTo === "/" && this.props.authRedirectPath !== "/login")
            || (this.state.redirectTo === "/login" && this.props.authRedirectPath !== "/login")
        )
            && this.props.authRedirectPath !== null) {
            this.setState({ redirectTo: this.props.authRedirectPath });
        }
    }

    /**/
    render() {
        let routes = (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                <Login>
                    <Switch>
                        <Route path="/login" exact={true} component={Auth} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/change-password" component={ChangePassword} />

                        <Route path="/login/callback" component={LoginCallback} />
                        <Route path="/ok2callback" component={OktaLoginCallback} />

                        <Route path="/profile" component={Profile} />
                        {/*
                        <Route path="/login" exact={true} component={OktaAuthHome}/>
                        */}

                        <Redirect to="/login" />
                    </Switch>
                </Login>
            </Security>
        );

        var userId = this.props.userId;
        var crstarToken = this.props.crstarToken;
        var expiresIn = this.props.expiresIn;
        var hasChangePass = this.props.hasChangePass;
        var enable2fa = this.props.enable2fa;
        var passExpired = this.props.passExpired;
        var lastAuth = this.props.lastAuth;
        var lastResult = this.props.lastResult;
        var key2FA = this.props.key2FA;
        var clientgroup = this.props.clientgroup;

        if (sessionStorage.user !== null && sessionStorage.user !== undefined) {
            var user = JSON.parse(sessionStorage.user);

            userId = user.userId;
            crstarToken = user.token;
            expiresIn = user.expires_in;
            hasChangePass = user.hasChangePass;
            enable2fa = user.enable2fa;
            passExpired = user.passExpired;
            lastAuth = user.lastAuth;
            lastResult = user.lastResult;
            key2FA = user.key2FA;
        }

        var isAuthenticated = this.props.isAuthenticated;
        if (isAuthenticated) {
            sessionStorage.clear();
            var user = {
                // check logged on
                'userId': this.props.userId ? this.props.userId : userId,
                'token': this.props.crstarToken ? this.props.crstarToken : crstarToken,
                'expires_in': this.props.expiresIn !== 0 ? this.props.expiresIn : expiresIn,
                'created_at': new Date(),
                // check more than others
                'hasChangePass': this.props.hasChangePass,
                'enable2fa': this.props.enable2fa !== null ? this.props.enable2fa : enable2fa,
                'passExpired': this.props.passExpired ? this.props.passExpired : passExpired,
                'lastAuth': this.props.lastAuth ? this.props.lastAuth : lastAuth,
                'lastResult': this.props.lastResult ? this.props.lastResult : lastResult,
                'key2FA': this.props.key2FA ? this.props.key2FA : key2FA
            };
            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('clientgroup', clientgroup);

            //
            routes = (
                <Layout userId={this.props.userId !== null ? this.props.userId : this.state.userid}
                    changedPwd={this.props.hasChangePass == null && !this.props.isChangePwdDialogVisible}
                    enable2fa={this.props.enable2fa}
                    passExpired={this.props.passExpired}
                    lastAuth={this.props.lastAuth}
                    lastResult={this.props.lastResult}
                    key2FA={this.props.key2FA}>
                    <Switch>
                        <Route path='/patient-abstract' component={PatientAbstract} />
                        <Route path='/rapid-abstract' component={PatientAbstract} />
                        <Route path='/abstract-utils' component={AbstractUtils} />

                        <Route path='/ReaCannedReports' component={SiteDistIncidence} />
                        <Route path='/SiteDistIncidence' render={(props) => (
                            <Aframe {...props} url="SiteDistIncidence" />
                        )} />

                        <Route path='/ReaRegistryMgmtReports' component={Suspense} />
                        <Route path='/Suspense' render={(props) => (
                            <Aframe {...props} url="Suspense" />
                        )} />

                        <Route path='/ReaAdminReports' component={AccRosterMpi} />
                        <Route path='/AccRosterMpi' render={(props) => (
                            <Aframe {...props} url="AccRosterMpi" />
                        )} />

                        <Route path='/ReaAccreditationReports' component={Coc} />
                        <Route path='/Coc' render={(props) => (
                            <Aframe {...props} url="Coc" />
                        )} />

                        <Route path='/ReaComparisonReports' component={StageComparisons} />
                        <Route path='/StageComparisons' render={(props) => (
                            <Aframe {...props} url="StageComparisons" />
                        )} />

                        <Route path='/ReaAdhocReports' component={ListPop} />
                        <Route path='/ListPop' render={(props) => (
                            <Aframe {...props} url="ListPop" />
                        )} />

                        <Route path='/ReaMiscellaneousReports' component={AgeBySex} />
                        <Route path='/AgeBySex' render={(props) => (
                            <Aframe {...props} url="AgeBySex" />
                        )} />

                        <Route path='/ReaDataExports' component={StateExport} />
                        <Route path='/StateExport' render={(props) => (
                            <Aframe {...props} url="StateExport" />
                        )} />

                        <Route path='/ReportToSelectAPop' component={SelectAPop} />

                        <Route path='/ReportUtility' component={ClearPopulations} />
                        <Route path='/ClearPopulations' render={(props) => (
                            <Aframe {...props} url="ClearPopulations" />
                        )} />

                        <Route path='/SysAdmSecurityMaint' component={UserSecurityMaintLookup} />
                        <Route path='/UserSecurityMaintLookup' render={(props) => (
                            <Aframe {...props} url="UserSecurityMaintLookup" />
                        )} />

                        <Route path='/alpha-dashboard' exact render={(props) => (
                            <Aframe {...props} url="/alpha-dashboard" alphaUrl="/alpha-dashboard" />
                        )} />

                        <Route path='/AbstractNavLaunch' render={(props) => (
                            <Aframe {...props} url="AbstractNavLaunch" />
                        )} />
                        <Route path='/AbstractPrintNav' render={(props) => (
                            <Aframe {...props} url="AbstractPrintNav" />
                        )} />
                        <Route path='/AbstractUtilityNavLaunch' render={(props) => (
                            <Aframe {...props} url="AbstractUtilityNavLaunch" />
                        )} />
                        <Route path='/FollowupNavLaunch' render={(props) => (
                            <Aframe {...props} url="FollowupNavLaunch" />
                        )} />

                        <Route path='/ReportCannedNavLaunch' render={(props) => (
                            <Aframe {...props} url="ReportCannedNavLaunch" />
                        )} />
                        <Route path='/ReportAcosNavLaunch' render={(props) => (
                            <Aframe {...props} url="ReportAcosNavLaunch" />
                        )} />
                        <Route path='/ReportAdhocNavLaunch' render={(props) => (
                            <Aframe {...props} url="ReportAdhocNavLaunch" />
                        )} />
                        <Route path='/ReportMiscNavLaunch' render={(props) => (
                            <Aframe {...props} url="ReportMiscNavLaunch" />
                        )} />
                        <Route path='/ReportDataNavLaunch' render={(props) => (
                            <Aframe {...props} url="ReportDataNavLaunch" />
                        )} />
                        <Route path='/ReportUtilityNavLaunch' render={(props) => (
                            <Aframe {...props} url="ReportUtilityNavLaunch" />
                        )} />

                        <Route path='/SysAdmSecurityNavLaunch' render={(props) => (
                            <Aframe {...props} url="SysAdmSecurityNavLaunch" />
                        )} />

                        <Route path='/DataManagment' component={GlobalChange} />
                        <Route path='/GlobalChange' render={(props) => (
                            <Aframe {...props} url="GlobalChange" />
                        )} />

                        <Route path='/SysAdmUtility' component={UtilMainTabs} />
                        <Route path='/UtilMainTabs' render={(props) => (
                            <Aframe {...props} url="UtilMainTabs" />
                        )} />

                        <Route path='/HybridSysManNavLaunch' render={(props) => (
                            <Aframe {...props} url="HybridSysManNavLaunch" />
                        )} />
                        <Route path='/HybridSysManCodesNavLaunch' render={(props) => (
                            <Aframe {...props} url="HybridSysManCodesNavLaunch" />
                        )} />
                        <Route path='/SysConNavLaunch' render={(props) => (
                            <Aframe {...props} url="SysConNavLaunch" />
                        )} />
                        <Route path='/alpha-support-portal' render={(props) => (
                            <Aframe {...props} url="" />
                        )} />

                        <Route path="/logout" component={Logout} />
                        <Route path='/logout_byid' render={(props) => (
                            <Logout {...props} userId={this.props.userId} />
                        )} />

                        <Route path='/change-pwd' render={(props) => (
                            <ChangePwdDialog {...props} userId={this.props.userId} />
                        )} />
                        <Route path='/two-factor-auth' render={(props) => (
                            <TwoFactorAuthDialog {...props}
                                userId={this.props.userId !== null ? this.props.userId : this.state.userid}
                                key2FA={this.props.key2FA}
                                lastAuth={this.props.lastAuth}
                            />
                        )} />

                        <Route path='/utilityP' component={UtilityPage} />

                        <Route path='/ReaSystemMgmt' component={HospitalsMaintLookup} />
                        <Route path='/HospitalsMaintLookup' render={(props) => (
                            <Aframe {...props} url="HospitalsMaintLookup" />
                        )} />

                        <Route path='/GenEditsSettings' component={EditsSettingsMaint} />
                        <Route path='/EditsSettingsMaint' render={(props) => (
                            <Aframe {...props} url="EditsSettingsMaint" />
                        )} />

                        <Route path='/AbstractUtils' component={DeletePatientAbstactMaint} />
                        <Route path='/AbstractUtils' render={(props) => (
                            <Aframe {...props} url="AbstractUtils" />
                        )} />

                        <Route path='/PrintForms' component={PrintAbstractsMaint} />
                        <Route path='/PrintForms' render={(props) => (
                            <Aframe {...props} url="PrintForms" />
                        )} />

                        <Route path='/PrintAbstractsMaint' component={PrintAbstractsMaint} />
                        <Route path='/PrintAbstractsMaint' render={(props) => (
                            <Aframe {...props} url="PrintAbstractsMaint" />
                        )} />

                        <Route path='SysAdmUtilitiesMaint' component={GlobalDirMaintLookup} />
                        <Route path='/GlobalDirMaintLookup' render={(props) => (
                            <Aframe {...props} url="GlobalDirMaintLookup" />
                        )} />

                        <Route path='/NlpWorkQueue' component={NlpWorkQueue} />
                        <Route path='/NlpWorkQueue' render={(props) => (
                            <Aframe {...props} url="NlpWorkQueue" />
                        )} />

                        <Route path='/ZipCodesMaint' component={ZipCodesMaintLookup} />
                        <Route path='/ZipCodesMaintLookup' render={(props) => (
                            <Aframe {...props} url="ZipCodesMaintLookup" />
                        )} />

                        {
                            <Redirect to="/alpha-dashboard" />
                        }
                    </Switch>
                </Layout>
            );
        }

        return (
            <div className="app_root">
                {routes}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        crstarToken: state.auth.token,
        authRedirectPath: state.auth.authRedirectPath,
        userId: state.auth.userId,
        alphaToken: state.patientAbstract.tokenOnSession,
        hasChangePass: state.auth.hasChangePass,
        passExpired: state.auth.passExpired,//
        expiresIn: state.auth.expiresIn,
        enable2fa: state.auth.enable2fa,
        lastAuth: state.auth.lastAuth,
        lastResult: state.auth.lastResult,
        key2FA: state.auth.key2FA,
        isChangePwdDialogVisible: state.patientAbstract.isChangePwdDialogVisible,
        isAuth2FaDialogVisible: state.patientAbstract.isAuth2FaDialogVisible,
        showingHelpInfo: state.patientAbstract.showingHelpInfo,
        clientgroup: state.auth.clientgroup,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        getTokenOnSession: (uid) => dispatch(actionCreators.getTokenOnSession(uid)),
        onLogout: (uid, cg, sval) => dispatch(actions.logout(uid, cg, sval)),
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
    };
};
/*
const AppWithRouterAccess = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

class RouterApp extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<Router><AppWithRouterAccess /></Router>);
    }
}

export default withOktaAuth(RouterApp);
*/

const AppWithRouterAccess = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

class RouterApp extends Component {
    render() {
        return (<Router><AppWithRouterAccess /></Router>);
    }
}

export default RouterApp;