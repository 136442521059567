/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../../PatientMaintenance/PatientMaintenance.css";
import "../StateSpecificMaintenance.css";
import "../../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../../DiagnosisMaintenance/TextForm";
import Help from "../../../../UI/Help";
import Draggable from 'react-draggable';
import * as TextPopup from "../../TitleOfTextPopup";

class StateFieldsFLMaint extends Component {
    state = {
        //activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stateFieldsForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stateFieldsForm back to what it was on load
        initialStateFieldsForm: null,
        stateFieldsForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "flAltAccNbr",
        isHelpVisible: false,
        isDialogVisible: true,

        stateFieldsFlHistory: null,
        stateFieldsFlHistoryItemFields: null,
        stateFieldsFlHistoryLoading: false,
        selectedStateFieldsFLHistRowIndex: -1,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,

        stateFieldsFlHistoryMaxlengthInput: null,
    }

    setSelectedStateFieldsFLHistRow = (event, index) => {
        this.setState({ selectedStateFieldsFLHistRowIndex: index });
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: flag });
    }
    
    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "stateFieldsFlHistory":
                    this.deleteStateFieldsFlHistoryGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.stateFieldsForm && this.props.selectedStateFieldsRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.stateFieldsForm && this.props.selectedStateFieldsRecord)
            || (prevProps.selectedStateFieldsRecord && prevProps.selectedStateFieldsRecord !== this.props.selectedStateFieldsRecord)
            || (this.props.initialAbstractPages && this.props.selectedStateFieldsRecord)) {
            this.setFormData();
        }

        // stateFieldsFlHistory List
        if (this.props.selectedStateFieldsRecord && !this.state.stateFieldsFlHistory && !this.state.stateFieldsFlHistoryLoading) {
            this.setState({ stateFieldsFlHistoryLoading: true });
            axios.post(this.props.clientgroup + "/GetStateFieldsFlHistoryList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ stateFieldsFlHistory: res.data.stateFieldsFlHistoryList });

                        //
                        let maxLengthItem = [];//#1
                        if (!this.state.stateFieldsFlHistoryItemFields && res.data.stateFieldsFlHistoryList) {
                            let objArr = [];
                            res.data.stateFieldsFlHistoryList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            this.setState({ stateFieldsFlHistoryItemFields: objArr });
                        };

                        // get maxlength for stateFieldsFlHistory grid
                        axios.post(this.props.clientgroup + "/GetTableStructure", { table: "State_Fields_FL_History", accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res.data !== null && res.data.result !== "") {
                                let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                this.setState({
                                    stateFieldsFlHistoryMaxlengthInput: { ...maxLengthItem }
                                });
                            }
                        }).catch(function (error) {
                            console.log(error);
                            /*
                            let btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                    }
                    return false; 
                }).catch(function (error) {
                    console.log(error);
                    /*
                    let btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
            return false;
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.stateFieldsForm && this.state.sAllItemFields) {
            console.log("[StateFields] = componentDidUpdate 3");

            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // History Only grid
                    this.setState(prevState => {
                        const newItems = [...prevState.stateFieldsFlHistoryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { stateFieldsFlHistoryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        stateFieldsForm: { ...this.state.stateFieldsForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name }
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // contact grid
                    this.setState(prevState => {
                        const newItems = [...prevState.stateFieldsFlHistoryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { stateFieldsFlHistoryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        stateFieldsForm: { ...this.state.stateFieldsForm, [this.props.currentSelectedField + "Name"]: "" }
                    });
                }
            }

            this.props.clearCommonLookupItem();
        }

        // set maxlength for all fields
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "State_Fields", accessToken: localStorage.getItem('token') };
        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });
    }

    // stateFieldsFlHistory grid - lostfocus
    handlestateFieldsFlHistoryGridLostFocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, index, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.stateFieldsFlHistory];
                        newItems[index][name] = value;
                        return { stateFieldsFlHistory: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.stateFieldsFlHistoryItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { stateFieldsFlHistoryItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.stateFieldsFlHistoryItemFields];
                    newItems[index][name] = "";
                    return { stateFieldsFlHistoryItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.stateFieldsFlHistoryItemFields];
                newItems[index][name] = "";
                return { stateFieldsFlHistoryItemFields: newItems };
            });
        }

        //-- Fix issue of #PR00044 --START
        // Determine_Disc_Schema_ID & Get_SSF_25_Schema_Filter
        if ((name === "flHistTopography" || name === "flHistMorph" || name === "flHistDiscriminator1") && value !== "") {
            var sfArr = this.state.stateFieldsFlHistory;
            var cid = sfArr[index]["cid"] ? sfArr[index]["cid"] : "0";
            var topo = sfArr[index]["flHistTopography"] ? sfArr[index]["flHistTopography"] : "";
            var hist = sfArr[index]["flHistMorph"] ? sfArr[index]["flHistMorph"] : "";
            var disc = sfArr[index]["flHistDisc"] ? sfArr[index]["flHistDisc"] : "";
            var disc_1 = sfArr[index]["flHistDiscriminator1"] ? sfArr[index]["flHistDiscriminator1"] : "";
            var sex = this.props.selectedPatient && this.props.selectedPatient.sex ? this.props.selectedPatient.sex : "";

            if (topo === "" || hist === "") return false;

            if (name === "flHistDiscriminator1") {
                disc_1 = value;
            }

            //Determine_Disc_Schema_ID
            var params = {
                cid: cid, topo: topo, hist: hist, disc: disc, disc1: disc_1, sex: sex, accessToken: localStorage.getItem('token')
            };
            axios.post(this.props.clientgroup + "/DetermineDiscSchemaId", params)
                .then(res => {
                    let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && res.data.message !== null && res.data.message !== "") {
                        const fnDiscSchemaId = res.data.message;
                        this.setState(prevState => {
                            const newItems = [...prevState.stateFieldsFlHistory];
                            newItems[index][name === "flHistDiscriminator1" ? "fnDiscSchemaId2" : "fnDiscSchemaId"] = fnDiscSchemaId;
                            return {
                                stateFieldsFlHistory: newItems,
                            };
                        });
                        return false;
                    }
                }).catch (function (error) {
                    console.log(error);
                    /*
                    let btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });

            //Get_SSF_25_Schema_Filter
            var params2 = {
                cid: cid, topo: topo, hist: hist, disc: disc, disc1: disc_1, sex: sex, accessToken: localStorage.getItem('token')
            };
            axios.post(this.props.clientgroup + "/GetSsf25SchemaFilter", params2)
                .then(res => {
                    let newAccessToken2 = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken2) {
                        localStorage.setItem('token', newAccessToken2);
                    }
                    if (!res.data.error && res.data.message !== null && res.data.message !== "") {
                        const fnSsf25Lookup = res.data.message;
                        this.setState(prevState => {
                            const newItems = [...prevState.stateFieldsFlHistory];
                            newItems[index]["fnSsf25Lookup"] = fnSsf25Lookup;
                            return {
                                stateFieldsFlHistory: newItems,
                            };
                        });
                        return false;
                    }
                }).catch(function (error) {
                    console.log(error);
                    /*
                    let btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
        //-- Fix issue of #PR00044 --END
    }
    //onchange
    handlestateFieldsFlHistoryGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.stateFieldsFlHistory];
            newItems[index][name] = value;
            return { stateFieldsFlHistory: newItems, isFormDirty: true };
        });
    }

    addNewstateFieldsFlHistory = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.stateFieldsFlHistory];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.stateFieldsFlHistoryItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { stateFieldsFlHistory: newItems, stateFieldsFlHistoryItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedStateFieldsFlHistory = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedStateFieldsFLHistRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.stateFieldsFlHistory.length) {
            selectedRow = this.state.stateFieldsFlHistory.length - 1;
        }

        //if (this.state.stateFieldsFlHistory[this.state.stateFieldsFlHistory.length - 1]["id"] !== 0) {
        if (this.state.stateFieldsFlHistory[selectedRow] && this.state.stateFieldsFlHistory[selectedRow]["id"] !== undefined && this.state.stateFieldsFlHistory[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "stateFieldsFlHistory" });
        } else {
            this.deleteStateFieldsFlHistoryGrid();
        }
        return false;
    }
    
    deleteStateFieldsFlHistoryGrid = () => {
        let selectedRow = this.state.selectedStateFieldsFLHistRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.stateFieldsFlHistory.length) {
            selectedRow = this.state.stateFieldsFlHistory.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.stateFieldsFlHistory];
            if (newItems.length === 1) {
                const _newItems = [...this.state.stateFieldsFlHistory];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.stateFieldsFlHistoryItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.stateFieldsFlHistoryItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { stateFieldsFlHistory: newItems, stateFieldsFlHistoryItemFields: newFields, selectedStateFieldsFLHistRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // Getting a random integer between two values
    getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    // Declare this function is only temporality
    ERSCs_get_schema_number = (/*site, hist, discriminator*/) => {
        return this.getRandomInt(1, 153);
    }

    fnEnableAllSsf = (event) => {
        this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: "1" } });
    }

    setFormData = () => {
        this.setState({
            stateFieldsForm: { ...this.props.selectedStateFieldsRecord },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedStateFieldsRecord) {
            let fields = {};
            Object.keys(this.props.selectedStateFieldsRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ sAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    saveStateFields = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        // stateFieldsFlHistory grid
        if (isValid) {
            const pItems = this.state.stateFieldsFlHistoryItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "flHistDxDate") {
                        this.setState(prevState => {
                            let newItems = [...prevState.stateFieldsFlHistoryItemFields];
                            newItems[index][field + "Year"] = "";
                            newItems[index][field + "Month"] = "";
                            newItems[index][field + "Day"] = "";
                            return { stateFieldsFlHistoryItemFields: newItems };
                        });
                        prm[field + "Year"] = "";
                        prm[field + "Month"] = "";
                        prm[field + "Day"] = "";
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let stateFieldsFormData = this.state.stateFieldsForm;
            let stateFieldsFlHistoryList = this.state.stateFieldsFlHistory;
            //stateFieldsFormData.cid = this.props.selectedMedicalRecord.cid;

            let isValidDateInput = true;

            //#20210125 - START
            stateFieldsFlHistoryList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "flHistDxDate") {
                        // Check valid of date in February
                        var year = obj[t + "Year"] ? obj[t + "Year"] : "";
                        var month = obj[t + "Month"] ? obj[t + "Month"] : "";
                        var day = obj[t + "Day"] ? obj[t + "Day"] : "";

                        obj[t] = new Date(year + "-" + month + "-" + day);

                        if (year !== "" && year !== null && month !== "" && month !== null && day !== "" && day !== null) {
                            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
                            try {
                                A[0] -= 1;
                                _day = new Date(+A[2], A[0], +A[1]);
                                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                    /*|| year == '9999'*/ || month == '99' || day == '99') {
                                    return;
                                } else {
                                    isValidDateInput = false;
                                    this.setState(prevState => {
                                        const newItems = [...prevState.stateFieldsFlHistoryItemFields];
                                        newItems[index][t + "Year"] = "IN_VALID";
                                        newItems[index][t + "Month"] = "IN_VALID";
                                        newItems[index][t + "Day"] = "IN_VALID";
                                        return { stateFieldsFlHistoryItemFields: newItems };
                                    });
                                    return;
                                }
                            } catch (er) {
                                isValidDateInput = false;
                                return;
                            }
                        }
                    }
                });
            });

            if (!isValidDateInput && !allowSaveAnyway) return false;

            stateFieldsFormData.stateFieldsFlHistoryList = stateFieldsFlHistoryList;

            this.props.saveStateFields(stateFieldsFormData);
            return false;
        }
    }

    resetStateFields = () => {
        this.setState({
            stateFieldsForm: { ...this.state.initialStateFieldsForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: value } });
                } else {
                    this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".dragableSmallWindowCls">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const stateSpecificMaintenance = this.state.stateFieldsForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveStateFields} id="stateFieldsForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-3 FLStateMaint1">
                                    <div className="frameInner" style={{ height: "150px", marginBottom: "11px"}}>
                                        <div className="header">&nbsp;</div>
                                        <Input type="input" focused={this.handleOnFocus} autoFocus
                                            changed={this.handleFormChange} labelWidth="120px" width="120px"
                                            labelText="Alt Acc Nbr" field="flAltAccNbr"
                                            value={this.state.stateFieldsForm.flAltAccNbr} itemNbr="484"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["flAltAccNbr"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="input" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} labelWidth="190px" width="50px"
                                            labelText="Alt Seq" field="flAltSeq"
                                            value={this.state.stateFieldsForm.flAltSeq} itemNbr="854"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["flAltSeq"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="input" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} labelWidth="190px" width="50px"
                                            labelText="Height (inch)" field="height"
                                            value={this.state.stateFieldsForm.height} itemNbr="3623"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["height"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="input" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} labelWidth="190px" width="50px"
                                            labelText="Weight (lbs)" field="weight"
                                            value={this.state.stateFieldsForm.weight} itemNbr="3624"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["weight"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                    </div>
                                    <div className="frameInner" style={{ height: "310px" }}>
                                        <div className="header">Retired Fields</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="30px"
                                            labelText="Tobacco Use Cigarette" field="tobUseCigarette" lineStyle="oneLine"
                                            value={this.state.stateFieldsForm.tobUseCigarette ? this.state.stateFieldsForm.tobUseCigarette : this.state.stateFieldsForm.TobUseCigarette}
                                            dataSource="Global_Dir_Mv" aliasNo="3634" titleDialog="Tob Use Cigarette" labelWidth="190px"
                                            inValid={this.state.sAllItemFields["tobUseCigarette"]} itemNbr="3634"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseCigarette"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="30px"
                                            labelText="Tobacco Use Other" field="tobUseOther" lineStyle="oneLine"
                                            value={this.state.stateFieldsForm.tobUseOther ? this.state.stateFieldsForm.tobUseOther : this.state.stateFieldsForm.TobUseOther}
                                            dataSource="Global_Dir_Mv" aliasNo="3635" titleDialog="Tob Use Other" labelWidth="190px"
                                            inValid={this.state.sAllItemFields["tobUseOther"]} itemNbr="3635"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseOther"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="30px"
                                            labelText="Tobacco Use Smokeless" field="tobUseSmokeless" lineStyle="oneLine"
                                            value={this.state.stateFieldsForm.tobUseSmokeless ? this.state.stateFieldsForm.tobUseSmokeless : this.state.stateFieldsForm.TobUseSmokeless}
                                            dataSource="Global_Dir_Mv" aliasNo="3636" titleDialog="Tob Use Smokeless" labelWidth="190px"
                                            inValid={this.state.sAllItemFields["tobUseSmokeless"]} itemNbr="3636"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseSmokeless"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                            changed={this.handleFormChange} width="30px"
                                            labelText="Tobacco Nos" field="tobUseNos" lineStyle="oneLine"
                                            value={this.state.stateFieldsForm.tobUseNos ? this.state.stateFieldsForm.tobUseNos : this.state.stateFieldsForm.TobUseNos}
                                            dataSource="Global_Dir_Mv" aliasNo="3637" titleDialog="Tob Use Nos" labelWidth="190px"
                                            inValid={this.state.sAllItemFields["tobUseNos"]} itemNbr="3637"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobUseNos"] : ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-9 FlStateMaint2">
                                    <div className="frameInner">
                                        <div className="header">History Only</div>

                                        <div className="ssmTab1Col3">
                                            <div className="row">
                                                <div className="col-sm-12 stateFieldsFlHistoryListArea">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                <th style={{ width: "150px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Dx Date" field="flHistDxDate"
                                                                    /></th>
                                                                <th style={{ width: "50px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Site" field="flHistSite"
                                                                    /></th>
                                                                <th style={{ width: "45px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Seq" field="flHistSeq"
                                                                    /></th>
                                                                <th style={{ width: "70px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Topo" field="flHistTopography"
                                                                    /></th>
                                                                <th style={{ width: "80px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Histo" field="flHistMorph"
                                                                    /></th>
                                                                <th style={{ width: "65px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Laterality" field="flHistLaterality"
                                                                    /></th>
                                                                <th style={{ width: "50px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="State" field="flHistState"
                                                                    /></th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="County" field="flHistCounty"
                                                                    /></th>
                                                                <th style={{ width: "140px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Discrim 2017 & Prior" field="flHistDiscr"
                                                                    /></th>
                                                                <th style={{ width: "80px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Discrim 1" field="flHistDiscriminator1"
                                                                    /></th>
                                                                <th style={{ width: "80px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Discrim 2" field="flHistDiscriminator2"
                                                                    /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                            <tr>
                                                                <th style={{ width: "15px" }} colSpan="5">&nbsp;</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="innerBody">
                                                        <table>
                                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "150px" }}>Dx Date</th>
                                                                    <th style={{ width: "50px" }}>Site</th>
                                                                    <th style={{ width: "45px" }}>Seq</th>
                                                                    <th style={{ width: "70px" }}>Topo</th>
                                                                    <th style={{ width: "80px" }}>Histology</th>
                                                                    <th style={{ width: "65px" }}>Laterality</th>
                                                                    <th style={{ width: "50px" }}>State</th>
                                                                    <th style={{ width: "60px" }}>County</th>
                                                                    <th style={{ width: "140px" }}>Discrim 2017 & Prior</th>
                                                                    <th style={{ width: "80px" }}>Discrim 1</th>
                                                                    <th style={{ width: "80px" }}>Discrim 2</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.stateFieldsFlHistoryItemFields
                                                                    && this.state.stateFieldsFlHistory !== null && this.state.stateFieldsFlHistory.length > 0
                                                                    ? this.state.stateFieldsFlHistory.map((prm, index) => (
                                                                        <tr key={"data-key-" + index} onClick={(e) => { this.setSelectedStateFieldsFLHistRow(e, index) }} className={this.state.selectedStateFieldsFLHistRowIndex === index ? "selectedRowCls" : ""}>
                                                                            <td style={{ width: "15px" }}>
                                                                                {index + 1}
                                                                            </td>
                                                                            <td>
                                                                                <Input type="date" focused={this.handleOnFocus} labelWidth="0px"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistDxDate" width="150px" lineStyle="oneLine"
                                                                                    value={prm.flHistDxDate != null && prm.flHistDxDate !== "" ? moment(new Date(prm.flHistDxDate)).format("MM/DD/YYYY") : ""}
                                                                                    rowIndex={index}//keyId={"flHistDxDate" + index}
                                                                                    // Add day/month/year values
                                                                                    inValidDay={this.state.stateFieldsFlHistoryItemFields[index]["flHistDxDateDay"]}
                                                                                    inValidMonth={this.state.stateFieldsFlHistoryItemFields[index]["flHistDxDateMonth"]}
                                                                                    inValidYear={this.state.stateFieldsFlHistoryItemFields[index]["flHistDxDateYear"]}
                                                                                    dayVal={prm.flHistDxDateDay}
                                                                                    monthVal={prm.flHistDxDateMonth}
                                                                                    yearVal={prm.flHistDxDateYear}

                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistSite" value={prm.flHistSite} labelWidth="0px"
                                                                                    dataSource="Site_Codes" titleDialog="Fl History Only Site"
                                                                                    aliasNo="3044" width="30px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistSite"]}

                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="2"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistSite"] : ""}
                                                                                    keyId={"flHistSite" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }} labelWidth="0px"
                                                                                    labelText="" field="flHistSeq" value={prm.flHistSeq} width="45px"
                                                                                    //maxLength="2"
                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistSeq"] : ""}
                                                                                    keyId={"flHistSeq" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistTopography" value={prm.flHistTopography} labelWidth="0px"
                                                                                    dataSource="Topo_Codes" titleDialog="Topo Codes" aliasNo="0" width="50px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistTopography"]}
                                                                                    ///////////////////////////////////////////////////////////////////////////////
                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="10"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistTopography"] : ""}
                                                                                    keyId={"flHistTopography" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistMorph" value={prm.flHistMorph} labelWidth="0px"
                                                                                    dataSource="Morpho_Codes" titleDialog="Histology" aliasNo="0" itemNbr="" width="60px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistMorph"]}
                                                                                    ///////////////////////////////////////////////////////////////////////////////
                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="10"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistMorph"] : ""}
                                                                                    keyId={"flHistMorph" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistLaterality" value={prm.flHistLaterality} labelWidth="0px"
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Laterality" aliasNo="3046" itemNbr="3046" width="45px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistLaterality"]}

                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="10"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistLaterality"] : ""}
                                                                                    keyId={"flHistLaterality" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistState" value={prm.flHistState} labelWidth="0px"
                                                                                    dataSource="State_Codes" titleDialog="State" aliasNo="0" itemNbr="3047" width="30px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistState"]}

                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="2"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistState"] : ""}
                                                                                    keyId={"flHistState" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistCounty" value={prm.flHistCounty} labelWidth="0px"
                                                                                    dataSource="County_Codes" titleDialog="County" aliasNo="0" itemNbr="3048" width="40px"
                                                                                    flag={prm.flHistState}
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistCounty"]}

                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="50"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistCounty"] : ""}
                                                                                    keyId={"flHistCounty" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistDiscr" value={prm.flHistDiscr} labelWidth="0px"
                                                                                    dataSource="CstageLookupViewSfFl_Maint"
                                                                                    titleDialog="Fl History Only Discriminator" aliasNo="3385"
                                                                                    
                                                                                    //flag={this.state.Fn_ssf_25_lookup}//Lookup_ID (N)|Equals|Fn_ssf_25_lookup
                                                                                    flag={prm.fnSsf25Lookup}

                                                                                    itemNbr="3385" width="120px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistDiscr"]}
                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistDiscr"] : ""}
                                                                                    keyId={"flHistDiscr" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistDiscriminator1" value={prm.flHistDiscriminator1} labelWidth="0px"
                                                                                    dataSource="Schema_SSDI_Codes" titleDialog="Schema Discriminator 1" aliasNo="4167"//4347

                                                                                    //flag={this.state.Fn_disc_schema_id}//Schema_id (C)|Equals|Fn_disc_schema_id
                                                                                    flag={prm.fnDiscSchemaId}

                                                                                    itemNbr="4347" width="60px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistDiscriminator1"]}
                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistDiscriminator1"] : ""}
                                                                                    keyId={"flHistDiscriminator1" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handlestateFieldsFlHistoryGridChange(e, index) }}
                                                                                    labelText="" field="flHistDiscriminator2" value={prm.flHistDiscriminator2} labelWidth="0px"
                                                                                    dataSource="Schema_SSDI_Codes"
                                                                                    titleDialog="Schema Discriminator 2 (Starting 2018)"
                                                                                    aliasNo="4168"

                                                                                    //flag={this.state.Fn_disc_schema_id}//Schema_id (C)|Equals|Fn_disc_schema_id
                                                                                    flag={prm.fnDiscSchemaId2 ? prm.fnDiscSchemaId2 : prm.fnDiscSchemaId}

                                                                                    itemNbr="4348" width="60px"
                                                                                    inValid={this.state.stateFieldsFlHistoryItemFields[index]["flHistDiscriminator2"]}
                                                                                    lostfocused={(e) => { this.handlestateFieldsFlHistoryGridLostFocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.stateFieldsFlHistoryMaxlengthInput ? this.state.stateFieldsFlHistoryMaxlengthInput["flHistDiscriminator2"] : ""}
                                                                                    keyId={"flHistDiscriminator2" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )) : <tr><td colSpan="17">No records available</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="contactBtnArea right">
                                                        <button onClick={this.addNewstateFieldsFlHistory}>Add</button>
                                                        <button onClick={this.deleteSelectedStateFieldsFlHistory}>Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetStateFields} formId="stateFieldsForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">State Specific data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {stateSpecificMaintenance}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog title="Staging Text"
                                onClose={this.props.closeTextDialog}
                                width={500} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

StateFieldsFLMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedStateFieldsRecord: state.patientAbstract.selectedStateFieldsRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        selectedStagingRecord: state.patientAbstract.selectedStagingRecord,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveStateFields: (formData) => dispatch(actionCreators.saveStateFields(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),

        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateFieldsFLMaint);