export const LOGIN = "Login";

// These consts are used by the setDialog actions to set the 'current dialog' in the store
export const LOOKUP = "Lookup";
export const PATIENT = "Patient";
export const DIAGNOSIS = "Diagnosis";
export const STAGING = "Staging";
export const TREATMENT = "Treatment";
export const OUTCOMES = "Outcomes";

export const STATE_SPECIFIC = "State Specific";
export const CPM = "CPM";
export const BPM = "BPM";
export const RPM = "RPM";
export const USER_DEFINED = "User Defined";
export const CASE_ADMIN = "Case Admin";
export const CLINICAL = "Clinical";
export const RESEARCH = "Research";
export const RETIRED = "Retired";

export const ALPHA_MENU = "ALPHA MENU";
export const ALPHA_URL = "ALPHA URL";

// This array is iterated over when creating the buttons that navigate to each dialog (i.e the tabs along the top of PatientAbstract, as well as the 'Go to' popup)
export const dialogDisplays = [
    "Lookup", "Patient", "Diagnosis", "Staging", "Treatment", "Outcomes",
    "State Specific", "CPM", "BPM", "RPM", "User Defined", "Case Admin",
    "Clinical", "Research", "Retired"
];
export const buttonNamesOnTab = [
    "BTN_PATIENTMAINTLOOKUP",//Lookup
    "BTN_PATIENTMAINT",//Patient
    "BTN_DIAGNOSISMAINT",//Diagnosis
    "BTN_STAGINGMAINT",//Staging
    "BTN_RXSUMMARYMAINT",//Treatment
    "BTN_OUTCOMESMAINT",//Outcomes
    "BTN_STATEFIELDSMAINT",//State Specific
    "BTN_COCMAINT",//CPM
    "BTN_NAPBCMAINT",//BPM
    //"BTN_RPMMAINT",//RPM (Rectal Program)
    "BTN_RECTALMAINT",
    "BTN_USERDEFINEDMAINT",//User Defined
    "BTN_CASEADMINMAINT",//Case Admin
    "BTN_CLINICALMAINT",//Clinical
    "BTN_RESEARCHMAINT",//Research
    "BTN_RETIREDMAINT",//Retired
    /*
    //#PR00625
    "BTN_PRINTABSTRACTSMAINT",//Print Abstracts ~ Abstracts
    "BTN_GENCUSTOMFORMS",//Print Tx Summary ~ Tx Summary/Custom

    "BTN_DELETEPATIENTABSTACTMAINT",//Delete An Abstract ~ Delete Abstract
    "BTN_COPYABSTRACT",//Copy Abstract
    "BTN_MERGEPATIENTS",//Merge Patients ~ Combine Patients

    "BTN_SELECTAPOP",//Select A Population
    "BTN_GENSITEDISTRIBUTION",//Site Distribution
    "BTN_GENRXDISTRIBUTION",//Treatment Distribution
    "BTN_GENSURVIVALBYSTAGE",//Survival
    "BTN_ACCESSIONREGISTER",//Acc Register/MPI
    "BTN_GENREQUESTLOG",//Request Log

    "BTN_ADHOCREPORT",//List Pop
    "BTN_GRAPHANITEM",//Graph Pop
    "BTN_GENREPORTFORAFILE",//Report File
    "BTN_GENCROSSTAB",//Cross Tab
    "BTN_GENPRODUCTIVITY",//Productivity
    "BTN_GENTIMELINESS",//Timeliness
    "BTN_GENAGEBYSEX",//Age by Sex
    "BTN_GENSTAGEBYSEX",//Stage by Sex
    "BTN_GENTNM",//TNM Graph
    "BTN_STATEEXPORT",//
    "BTN_NCDBEXPORT",//NCDB/RCRS Export
    */
];

//#PR00625
export const userSecurityEnable_ControlNames = [
    /*
    "BTN_PRINTABSTRACTSMAINT",//Print Abstracts ~ Abstracts
    "BTN_GENCUSTOMFORMS",//Print Tx Summary ~ Tx Summary/Custom
    "BTN_DELETEPATIENTABSTACTMAINT",//Delete An Abstract ~ Delete Abstract
    "BTN_COPYABSTRACT",//Copy Abstract
    "BTN_MERGEPATIENTS",//Merge Patients ~ Combine Patients
    */
    //React Screen Not Developed
    "BTN_GENFOLLOWUPLETTERS",//
    "BTN_GENDEMANDLETTERS",//
    "BTN_GENFOLLOWUPCALCS",//

    //"BTN_SELECTAPOP",//Select A Population

    //Consolodated to another React Screen
    "BTN_GENINCIDENCEBYSEX",//
    "BTN_GENINCIDENCEBYSTAGE",//
    "BTN_GENINCIDENCEBYRACE",//
    "BTN_GENINCIDENCEBYRX",//

    //"BTN_GENSITEDISTRIBUTION",//Site Distribution
    "BTN_GENRXCOMBINATION",//
    //"BTN_GENRXDISTRIBUTION",//Treatment Distribution
    //"BTN_GENSURVIVALBYSTAGE",//Survival
    //"BTN_ACCESSIONREGISTER",//Acc Register/MPI
    "BTN_MPILIST",//
    //"BTN_GENREQUESTLOG",//Request Log
    "BTN_GENCLINICALSTUDIES",//
    /*
    "BTN_ADHOCREPORT",//List Pop
    "BTN_GRAPHANITEM",//Graph Pop
    "BTN_GENREPORTFORAFILE",//Report File
    "BTN_GENCROSSTAB",//Cross Tab
    "BTN_GENPRODUCTIVITY",//Productivity
    "BTN_GENTIMELINESS",//Timeliness
    "BTN_GENAGEBYSEX",//Age by Sex
    "BTN_GENSTAGEBYSEX",//Stage by Sex
    "BTN_GENTNM",//TNM Graph
    "BTN_STATEEXPORT",//
    "BTN_NCDBEXPORT",//NCDB/RCRS Export
    */
    "BTN_NAACCRVIEWER",//
    "BTN_JOURNEYFORWARDEXPORT",//
    "BTN_POPLABELDELETE",//Clear Populations
    "BTN_USERMAINTLOOKUP",//User Logins
    "BTN_PASSWORDMAINT",//Password Policy
    "BTN_DISPLAYLOCKEDUSERS",//Locked Users
    "BTN_USERSECURITYMAINTLOOKUP",//User Security
    "BTN_GLOBALCHANGE",//Global Change
    "BTN_CRSTARIMPORT",//Import
    "BTN_MASSDELETE",//Mass Delete
    "BTN_TCC_IMPORT",//
    "BTN_TCC_EXPORT",//
    "BTN_GLOBALDIRMAINTLOOKUP",//Global Dir
    "BTN_CRSTARIMPORTOPTIONS",//Import Config
    "BTN_GENDUPLICATECHECK",//DB Validation
    "BTN_HOSPITALSMAINTLOOKUP",//Hospitals
    "BTN_REGISTRARMAINTLOOKUP",//Registrars
    "BTN_PHYSICIANSMAINTLOOKUP",//Physicians
    "BTN_GENPHYSICIANROSTER",//Phys Roster
    "BTN_ACCNBRSMAINT",//Initialize Acc
    "BTN_FOLLOWUPORDER",//Follow-up Order
    "BTN_LETTERSMAINT",//Follow-up/QA Letters
    "BTN_CUSTOMFORMSMAINT",//Custom Forms
    "BTN_SITECODEMAINTLOOKUP",//
    "BTN_TOPOCODESMAINTLOOKUP",//
    "BTN_MORPHOCODESMAINTLOOKUP",//
    "BTN_COUNTYCODESMAINTLOOKUP",//
    "BTN_ZIPCODEMAINTLOOKUP",//
    "BTN_SITECODESSURGMAINTLOOKUP",//
    "BTN_TNMLOOKUPMAINTLOOKUP",//
    "BTN_EDITSSETTINGSMAINT",//Genedits
    "BTN_DASHBOARDSETTINGS",//Dashboard
    "BTN_CLR_STATEEXPORT",//State Export
    "BTN_PRINTQAFORMMAINT",//QA
    "BTN_RECTALMAINT",//RPM
    "BTN_SCP",//SCP
];

// Child windows has opened from Treatment Summary page
export const SURGERY = "Surgery";
export const RADIATION = "Radiation";
export const CHEMO = "Chemo";
export const HORMONE = "Hormone";
export const IMMUNOTHERAPY = "Immunotherapy";
export const HEMATOPOIETIC = "Hematopoietic";
export const PALLIATIVE = "Palliative";
export const OTHER = "Other";

// REPORTING menu
export const REPORTING_SELECT_A_POPULATION = " Select a Population ";
export const reportingSelectAPopulationTabsDisplay = [
    { name: " Select a Population ", id: "BTN_SELECTAPOP" }
];

export const REPORTING_GRAPH_AN_ITEM = "Graph An Item";
export const REPORTING_SURVIVAL_BY_STAGE = "Survival By Stage";

export const REPORTING_REA_CANNED_REPORTS = "Canned Reports";
export const REPORTING_REA_REGISTRY_MGMT_REPORTS = "Registry Mgmt Reports";
export const REPORTING_REA_ADMIN_REPORTS = "Admin";
export const REPORTING_REA_ACCREDITATION_REPORTS = "Accreditation Reports";
export const REPORTING_REA_COMPARISON_REPORTS = "Comparison Reports";
export const REPORTING_REA_ADHOC_REPORTS = "Adhoc Reports";
export const REPORTING_REA_MISCELLANEOUS_REPORTS = "Miscellaneous Reports";
export const REPORTING_REA_DATA_EXPORTS = "Data Exports";

// SYSTEM ADMINISTRATION menu
export const USER_MAINTENANCE = "User Maintenance";
export const PASSWORD_POLICY_MAINTENANCE = "Password Policy Maintenance";

// SYSTEM MANAGEMENT menu
export const SYSTEM_MANAGEMENT_MAINT = "System Management";
export const HOSPITALS_MAINT_LOOKUP = "Hospitals";
export const REGISTRAR_MAINT_LOOKUP = "Registrars";
export const PHYSICIANS_MAINT_LOOKUP = "Physicians";
export const PHYSICIAN_ROSTER = "Phys Roster";
export const INITIALIZE_ACC_NBR = "Initialize Acc";
export const FOLLOW_UP_ORDER = "Follow-up Order";
export const FOLLOW_UP_QA_LETTERS = "Follow-up/QA Letters";
export const CUSTOM_FORMS = "Custom Forms";
export const GLOBAL_DIR_LOOKUP = "Global Dir"

// 
export const USER_LOGINS = "User Logins";
export const PASSWORD_POLICY = "Password Policy";
export const LOCKED_USER = "Locked Users";
export const USER_SECURITY = "User Security";

export const sysAdminTabsDisplay = [
    { name: "User Logins", id: "BTN_USERMAINTLOOKUP" },
    //"User Maintenance", //this screen should be shown as popup or page on tab???
    { name: "Password Policy", id: "BTN_PASSWORDMAINT" },
    { name: "Locked Users", id: "BTN_DISPLAYLOCKEDUSERS" },
    { name: "User Security", id: "BTN_USERSECURITYMAINTLOOKUP" }
];

export const sysAdmUtilitiesTabsDisplay = [
    { name: "Global Dir", id: "BTN_GLOBALDIRMAINTLOOKUP" },
    { name: "Import Config", id: "BTN_CRSTARIMPORTOPTIONS" },
    { name: "DB Validation", id: "BTN_GENDUPLICATECHECK" }
];

export const UTILITY_PAGE = "Utility Page";

export const menuNamesOnLeftPanel = [
    "MENU_DASHBOARD",//Dashboard
    "MENU_ABSTRACT_MANAGEMENT",//Abstract Management
    "MENU_FOLLOW_UP_MANAGEMENT",//Follow Up Management
    "MENU_REPORTING",//Reporting
    "MENU_SYSTEM_ADMINISTRATION",//System Administration
    "MENU_SYSTEM_MANAGEMENT",//System Management
    "MENU_PREFERENCES",//Preferences
    "MENU_HELP",//Help
    "MENU_CRSTAR_REPORTING",//Crstar Reporting
    "MENU_CRSTAR_SHORTCUTS",//CRStar Shortcuts
    "MENU_NLP_WORK_QUEUE",//NLP Work Queue
];

export const systemMgmtTabsDisplay = [
    { name: "Hospitals", id: "BTN_HOSPITALSMAINTLOOKUP" },
    { name: "Registrars", id: "BTN_REGISTRARMAINTLOOKUP" },
    { name: "Physicians", id: "BTN_PHYSICIANSMAINTLOOKUP" },
    { name: "Phys Roster", id: "BTN_GENPHYSICIANROSTER" },
    { name: "Initialize Acc", id: "BTN_ACCNBRSMAINT" },
    { name: "Follow-up Order", id: "BTN_FOLLOWUPORDER" },
    { name: "Follow-up/QA Letters", id: "BTN_LETTERSMAINT" },
    { name: "Custom Forms", id: "BTN_CUSTOMFORMSMAINT" },
    { name: "Zip Codes", id: "BTN_ZIP_CODES" }
];

// SYSTEM CONFIGURATION menu
export const SYSTEM_CONFIGURATION_GENEDITS = "Genedits";//Genedits Settings
export const SYSTEM_CONFIGURATION_DASHBOARD = "Dashboard ";
export const systemConfigTabsDisplay = [
    { name: "Genedits", id: "BTN_EDITSSETTINGSMAINT" },
    { name: "Dashboard ", id: "BTN_DASHBOARDSETTINGS" }
];

// Abstract Utilities menu
export const ABSTRACT_UTILITIES_DELETE_ABSTRACT = "Delete Abstract";
export const ABSTRACT_UTILITIES_COPY_ABSTRACT = "Copy Abstract";
export const ABSTRACT_UTILITIES_COMBINE_PATIENTS = "Combine Patients";
export const absUtilsTabsDisplay = [
    { name: "Delete Abstract", id: "BTN_DELETEPATIENTABSTACTMAINT" },
    { name: "Copy Abstract", id: "BTN_COPYABSTRACT" },
    { name: "Combine Patients", id: "BTN_MERGEPATIENTS" },
];


// CRStar Reporting
//#1
export const cannedReportsTabsDisplay = [
    { name: "Site Distribution", id: "BTN_GENSITEDISTRIBUTION" },
    { name: "Treatment Distribution", id: "BTN_GENRXDISTRIBUTION" },//Add space to avoid confused with Treatment Summary page
    { name: "Survival", id: "BTN_GENSURVIVALBYSTAGE" },
];
export const REPORTING_REA_CANNED_REPORTS_TAB1 = "Site Distribution";
export const REPORTING_REA_CANNED_REPORTS_TAB2 = "Treatment Distribution";
export const REPORTING_REA_CANNED_REPORTS_TAB3 = "Survival";

//#2
export const registryMgmtReportsTabsDisplay = [
    { name: "Suspense", id: "BTN_GENSUSPENSE" },
    { name: "Incomplete", id: "BTN_GENINCOMPLETE" },
    { name: "Productivity", id: "BTN_GENPRODUCTIVITY" },//
    { name: "Timeliness", id: "BTN_GENTIMELINESS" },//
];
export const REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB1 = "Suspense";
export const REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB2 = "Incomplete";
export const REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB3 = "Productivity";
export const REPORTING_REA_REGISTRY_MGMT_REPORTS_TAB4 = "Timeliness";

//#3
export const adminReportsTabsDisplay = [
    { name: "Acc Register/MPI", id: "BTN_ACCESSIONREGISTER" },
    { name: "Request Log", id: "BTN_GENREQUESTLOG" },
    { name: "Report File", id: "BTN_GENREPORTFORAFILE" },
];
export const REPORTING_REA_ADMIN_REPORTS_TAB1 = "Acc Register/MPI";
export const REPORTING_REA_ADMIN_REPORTS_TAB2 = "Request Log";
export const REPORTING_REA_ADMIN_REPORTS_TAB3 = "Report File";

//#4
export const accreditationReportsTabsDisplay = [
    //"CoC",
    //"NAPBC",
    //"NAPRC",
    { name: "Quality Measures", id: "BTN_GENCLINICALSTUDIES" }//
];
export const REPORTING_REA_ACCREDITATION_REPORTS_TAB1 = "Quality Measures";
export const REPORTING_REA_ACCREDITATION_REPORTS_TAB2 = "CoC";
export const REPORTING_REA_ACCREDITATION_REPORTS_TAB3 = "NAPBC";
export const REPORTING_REA_ACCREDITATION_REPORTS_TAB4 = "NAPRC";


//#5
export const comparisonReportsTabsDisplay = [
    //"Stage Comparisons",
    { name: "Cross Tab", id: "BTN_GENCROSSTAB" }//
];
export const REPORTING_REA_COMPARISON_REPORTS_TAB1 = "Comparison Reports";
export const REPORTING_REA_COMPARISON_REPORTS_TAB2 = "Cross Tab";



//#6
export const adhocReportsTabsDisplay = [
    { name: "List Pop", id: "BTN_ADHOCREPORT" },
    { name: "Graph Pop", id: "BTN_GRAPHANITEM" }
];
export const REPORTING_REA_ADHOC_REPORTS_TAB1 = "List Pop";
export const REPORTING_REA_ADHOC_REPORTS_TAB2 = "Graph Pop";

//#7
export const miscellaneousReportsTabsDisplay = [
    //{ name: "Age by Sex", id: "BTN_GENAGEBYSEX" },
    //{ name: "Stage by Sex", id: "BTN_GENSTAGEBYSEX" },
    { name: "TNM Graph", id: "BTN_GENTNM" },//
    //"Days to Treatment"
];
//export const REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1 = "Age by Sex";
//export const REPORTING_REA_MISCELLANEOUS_REPORTS_TAB2 = "Stage by Sex";
export const REPORTING_REA_MISCELLANEOUS_REPORTS_TAB1 = "TNM Graph";
export const REPORTING_REA_MISCELLANEOUS_REPORTS_TAB2 = "Days to Treatment";

//#8
export const dataExportsTabsDisplay = [
    { name: "State Export", id: "BTN_CLR_STATEEXPORT" },
    { name: "NCDB/RCRS Export", id: "BTN_NCDBEXPORT" },
    //{ name: "Export Viewer", id: "BTN_NAACCRVIEWER" },//NAACR File Viewer???
];
export const REPORTING_REA_DATA_EXPORTS_TAB1 = "State Export";
export const REPORTING_REA_DATA_EXPORTS_TAB2 = "NCDB/RCRS Export";
export const REPORTING_REA_DATA_EXPORTS_TAB3 = "Export Viewer";

// Print Forms menu
export const PF_PRINT_ABSTRACTS = "Abstract";
export const PF_PRINT_TX_SUMMARY = "Tx Summary/Custom";
export const PF_PRINT_QA_FORM = "QA";
export const PF_PRINT_SCP_FORM = "SCP";
export const printFormsTabsDisplay = [
    { name: "Abstract", id: "BTN_PRINTABSTRACTSMAINT" },//Print Abstracts
    { name: "Tx Summary/Custom", id: "BTN_GENCUSTOMFORMS" },//Print Tx Summary
    { name: "QA", id: "BTN_PRINTQAFORMMAINT" },//Print QA Form
    { name: "SCP", id: "BTN_SCP" }//Print Survivorship Care Plan
];

export const reportingUtilityTabsDisplay = [
    { name: "Clear Populations", id: "BTN_POPLABELDELETE" }
];
export const REPORTING_UTILITY_TAB1 = "Clear Populations";

//NLP Work Queue
export const nlpWorkQueueTabsDisplay = [
    "Work Queue"
];
export const NLP_WORK_QUEUE_TAB = "Work Queue";
//

export const dataManagmentTabDisplay = [
    { name: "Global Change", id: "BTN_GLOBALCHANGE" },
    { name: "Import", id: "BTN_CRSTARIMPORT" },
    { name: "Mass Delete", id: "BTN_MASSDELETE" }
];
export const DATA_MANAGMENT_TAB1 = "Global Change";//
export const DATA_MANAGMENT_TAB2 = "Import";
export const DATA_MANAGMENT_TAB3 = "Mass Delete";//

export const sysAdminUtilitiesTabsDisplay = [
    { name: "Global Dir", id: "BTN_GLOBALDIRMAINTLOOKUP" },
    { name: "Import Config", id: "BTN_CRSTARIMPORTOPTIONS" },
    { name: "DB Validation", id: "BTN_GENDUPLICATECHECK" }
];
export const UTILITIES_GLOBAL_DIR = "Global Dir";//
export const UTILITIES_IMPORT_CONFIG = "Import Config";
export const UTILITIES_DB = "DB Validation";//

//Zip Codes menu
export const SYSMAN_ZIP_CODES_MAINT = "Zip Codes";
export const zipCodesTabsDisplay = [
    { name: "Zip Codes Maintenance", id: "BTN_ZIP_CODES" }
];

