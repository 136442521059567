/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./StateSpecificMaintenance.css";

import StateFieldsAZMaint from "./AZ/StateFieldsAZMaint";
import StateFieldsCAMaint from "./CA/StateFieldsCAMaint";
import StateFieldsCOMaint from "./CO/StateFieldsCOMaint";
import StateFieldsFLMaint from "./FL/StateFieldsFLMaint";
import StateFieldsGAMiant from "./GA/StateFieldsGAMiant";
import StateFieldsIAMaint from "./IA/StateFieldsIAMaint";
import StateFieldsIDMaint from "./ID/StateFieldsIDMaint";
import StateFieldsILMaint from "./IL/StateFieldsILMiant";
import StateFieldsKSMaint from "./KS/StateFieldsKSMaint";
import StateFieldsLAMaint from "./LA/StateFieldsLAMaint";
import StateFieldsMDMaint from "./MD/StateFieldsMDMaint";
import StateFieldsMIMaint from "./MI/StateFieldsMIMaint";
import StateFieldsMNMaint from "./MN/StateFieldsMNMaint";
import StateFieldsMOMaint from "./MO/StateFieldsMOMaint";
import StateFieldsNCMaint from "./NC/StateFieldsNCMaint";
import StateFieldsNMMaint from "./NM/StateFieldsNMMaint";
import StateFieldsNYMaint from "./NY/StateFieldsNYMaint";
import StateFieldsOHMaint from "./OH/StateFieldsOHMaint";
import StateFieldsRIMaint from "./RI/StateFieldsRIMaint";
import StateFieldsTNMaint from "./TN/StateFieldsTNMaint";
import StateFieldsTXMaint from "./TX/StateFieldsTXMaint";
import StateFieldsVAMaint from "./VA/StateFieldsVAMaint";
import StateFieldsWIMaint from "./WI/StateFieldsWIMaint";
import StateFieldsMtMaint from "./MT/StateFieldsMtMaint";
import StateFieldsNdMaint from "./ND/StateFieldsNdMaint";
import StateFieldsScMaint from "./SC/StateFieldsScMaint";
import StateFieldsNhMaint from "./NH/StateFieldsNhMaint";

class StateSpecificMaintenance extends Component {

    setScreenTitle = (title) => {
        var st = document.getElementById("stateSpecificScreenTitle");
        setTimeout(function () {
            st ? st.innerHTML = title + " Specific Fields Maintenance" : null;
        }, 0);
        return false;
    }

    componentDidMount() {
        this.setScreenTitle(this.props.currentShowingState);
    }

    render() {
        let stateFieldsMaint = null;

        const state = this.props.currentShowingState;
        //console.log('currentShowingState=' + this.props.currentShowingState);
        switch (state) {
            case "AZ":
                stateFieldsMaint = <StateFieldsAZMaint />;
                break;
            case "CA":
                stateFieldsMaint = <StateFieldsCAMaint />;
                break;
            case "CO":
                stateFieldsMaint = <StateFieldsCOMaint />;
                break;
            case "FL":
                stateFieldsMaint = <StateFieldsFLMaint />;
                break;
            case "GA":
                stateFieldsMaint = <StateFieldsGAMiant />;
                break;
            case "IA":
                stateFieldsMaint = <StateFieldsIAMaint />;
                break;
            case "ID":
                stateFieldsMaint = <StateFieldsIDMaint />;
                break;
            case "IL":
                stateFieldsMaint = <StateFieldsILMaint />;
                break;
            case "KS":
                stateFieldsMaint = <StateFieldsKSMaint />;
                break;
            case "LA":
                stateFieldsMaint = <StateFieldsLAMaint />;
                break;
            case "MD":
                stateFieldsMaint = <StateFieldsMDMaint />;
                break;
            case "MI":
                stateFieldsMaint = <StateFieldsMIMaint />;
                break;
            case "MN":
                stateFieldsMaint = <StateFieldsMNMaint />;
                break;
            case "MO":
                stateFieldsMaint = <StateFieldsMOMaint />;
                break;
            case "NC":
                stateFieldsMaint = <StateFieldsNCMaint />;
                break;
            case "NM"://#Special case!!!
                stateFieldsMaint = <StateFieldsNMMaint />;
                break;
            case "NY":
                stateFieldsMaint = <StateFieldsNYMaint />;
                break;
            case "OH":
                stateFieldsMaint = <StateFieldsOHMaint />;
                break;
            case "RI":
                stateFieldsMaint = <StateFieldsRIMaint />;
                break;
            case "TN":
                stateFieldsMaint = <StateFieldsTNMaint />;
                break;
            case "TX":
                stateFieldsMaint = <StateFieldsTXMaint />;
                break;
            case "VA":
                stateFieldsMaint = <StateFieldsVAMaint />;
                break;
            case "WI":
                stateFieldsMaint = <StateFieldsWIMaint />;
                break;

            case "MT":
                stateFieldsMaint = <StateFieldsMtMaint />;
                break;
            case "ND":
                stateFieldsMaint = <StateFieldsNdMaint />;
                break;
            case "SC":
                stateFieldsMaint = <StateFieldsScMaint />;
                break;
            case "NH":
                stateFieldsMaint = <StateFieldsNhMaint />;
                break;

            default:
                // the button/tab should be disabled
                // @TODO...
                break;
        }

        return (
            <React.Fragment>
                <div className="specificFieldsTitleCls" id="stateSpecificScreenTitle"></div>

                <React.Fragment>
                    {stateFieldsMaint}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

StateSpecificMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        currentShowingState: state.patientAbstract.currentShowingState,
    }
}

export default connect(mapStateToProps, null)(StateSpecificMaintenance);