/* eslint-disable */
import React, { Component } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { actionCreators } from "../store/PatientAbstract";
import * as dialogTypes from "./AbstractManagement/maintDialogs";

class Tab extends Component {

    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };
    labelDisplayingOnTab = null;

    onClick = (e, obj) => {

        //#region:: Abstract Date that need validation adjustments.
        /*
        Diagnosis - CoV-2 Pos Date
        Outcomes - Date Last Contact + Date Cancer Status Last Changed
        CPM - Date SCP Generated/Delivered + Date QA Performed + Screening Date
        BPM - Mammogram Date + Ultrasound Date + Mri Date + Plastic Surg Referral Date + Screening Date + Date SCP Generated/Delivered
        RPM - Date of Pretreatment MRI + Date of PET/CT + Date of Initial Eval at RCP + Date Post Surg Tx Outcome Discussed + Date Post Surg Tx Outcome Provided
        User Defined - Udef 41 -> Udef 50
        Case Admin - Date Abstract
        */
        var badDateId = null;
        if (document.getElementById("diagnosisForm")) {
            console.log('==diagnosisForm==');
            let dId = "ncdbSarsCov2PositiveDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("outcomesForm")) {
            console.log('==outcomesForm==');
            let dId = "dateLastCancerStatus";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            let gLen = document.getElementById("outcomesMvListArea").querySelectorAll("table>tbody>tr").length;
            for (var gi = 0; gi < gLen; gi++) {
                let gId = "dtLstCont" + gi;
                let gVl = document.getElementById(gId) ? document.getElementById(gId).value : null;
                if (gVl && !moment(gVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = gId;
                    break;
                }
            }

        } else if (document.getElementById("cocForm")) {
            console.log('==cocForm==');
            let dId = "dateScpGenerated";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "dateQaPerformed";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "screenDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (badDateId) {
                document.getElementById("linkOfCCServicesTab") ? document.getElementById("linkOfCCServicesTab").click() : null;
            }

        } else if (document.getElementById("napbcForm")) {
            console.log('==napbcForm==');
            let dId = "mammoDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "ultrasoundDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "mriDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "plasticSurgRefDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "screeningDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "dateScpGeneratedBpm";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

        } else if (document.getElementById("rectalProgramForm")) {
            console.log('==rectalProgramForm==');
            let dId = "rpmMriDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "rpmPetCtDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateInitEval";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateTxOutcomeDiscussed";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateTxOutcomeProvided";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

        } else if (document.getElementById("userDefinedForm")) {
            console.log('==userDefinedForm==');
            let dId = "udef";
            let iBadNo = 0;
            for (var si = 41; si <= 50; si++) {
                let gId = dId + si;
                let gVl = document.getElementById(gId) ? document.getElementById(gId).value : null;
                if (gVl && !moment(gVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = gId;
                    iBadNo = si;
                    break;
                }
            }

            if (iBadNo != 0 && iBadNo <= 45) {
                document.getElementById("linkOfUserDefined1Tab") ? document.getElementById("linkOfUserDefined1Tab").click() : null;
            } else if (iBadNo != 0 && iBadNo > 45) {
                document.getElementById("linkOfUserDefined2Tab") ? document.getElementById("linkOfUserDefined2Tab").click() : null;
            }

        } else if (document.getElementById("caseAdminForm")) {
            console.log('==caseAdminForm==');
            let dId = "dateAbst";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("dashboardSettingsForm")) {
            console.log('==dashboardSettingsForm==');
            let dId = "casesCompletedBeginDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "casesCompletedEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "casesAbstractedBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "casesAbstractedEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpEnteredBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpEnteredEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "lastFollowUpEnteredBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "lastFollowUpEnteredEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            /*
             * Disabled input fields so don't need to check valid input
            if (!badDateId) {
                dId = "followUpCalcsBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpCalcsEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            */
            if (!badDateId) {
                dId = "rqrsBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "rqrsEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "stagedByBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "stagedByEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "suspenseBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "suspenseBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "timelinessBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "timelinessEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "top5SitesBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "top5SitesEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredFromBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredFromEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredToBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredToEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "classBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "classBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "countyBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "countyBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "inOutMigrationBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "inOutMigrationEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            var tabId = e.target.id;
            if (badDateId && tabId != "Tabid_Dashboard_") {
                document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
                alert('Please input a valid date');
                document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
                setTimeout(function () {
                    document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
                    return false;
                }, 1000);
                return false;
            }
        }

        if (!this.props.inquiringMode && badDateId) {
            document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
            alert('Please input a valid date');
            document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
            setTimeout(function () {
                document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
                return false;
            }, 1000);
            return false;
        }
        // --End #region


        // Fixing #PR00555 - clear all checking common before move to other tabs
        this.props.clearCommonLookupItem();
        this.props.clearCommonLookupItem2();

        if (e.target.getAttribute("class").indexOf("tab-list-active") != -1) {
            return false;
        } else {
            this.props.showPageLoading(true);
        }

        document.querySelector('#showLoadingOnTopPage') ? document.querySelector('#showLoadingOnTopPage').setAttribute('class', '') : null;

        try {
            e ? e.preventDefault() : "";
        } catch (ex) { }

        const { label, onClick, disabled, showOrHide } = this.props;

        if (showOrHide === "hide" || disabled
            || (this.props.activeTab === label && label != "Hospitals" && label != "Phys Roster" && label != "Initialize Acc" && label != "Follow-up Order"
            && label != "Site Distribution" && label != "Treatment Distribution" && label != "Survival"
            && label != "Suspense" && label != "Incomplete" && label != "Productivity" && label != "Timeliness"
            && label != "Acc Register/MPI" && label != "Request Log" && label != "Report File"
            && label != "CoC" && label != "NAPBC" && label != "NAPRC" && label != "Quality Measures"
            && label != "Comparison Reports" && label != "Cross Tab"
            && label != "List Pop" && label != "Graph Pop"
            && label != "Age by Sex" && label != "Stage by Sex" && label != "TNM Graph" && label != "Days to Treatment"
            && label != "TNM Graph" && label != "Days to Treatment"
            && label != "State Export" && label != "NCDB/RCRS Export" && label != "Export Viewer"
	        && label != "Genedits" && label != "Dashboard "
            && label != "Delete Abstract" && label != "Copy Abstract" && label != "Combine Patients"
            && label != "Abstract" && label != "Tx Summary/Custom" && label != "QA" && label != "SCP"
            && label != "Global Change" && label != "Import" //&& label != "User Logins"
        )
            || this.props.currentDialog === dialogTypes.ALPHA_MENU) return false;

        onClick(e, label);

        var _this = this;
        setTimeout(function () {
            document.querySelector('#showLoadingOnTopPage') ? document.querySelector('#showLoadingOnTopPage').setAttribute('class', 'hidden') : null;
            document.querySelector('.loader') ? document.querySelector('.loader').setAttribute('class', 'hidden') : null;

            if (_this.props.inquiringMode) {
                if ((_this.props.alphaUrl === '/patient-abstract' || _this.props.alphaUrl === '/rapid-abstract') && label != 'Lookup') {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
                } else {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
                }
            } else if ((!_this.props.inquiringMode && label == "Patient")
                || (!_this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") !== -1)
                || (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") != -1)
            ) {
                document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
            } else if ((!_this.props.inquiringMode && label != "Patient" &&
                (document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1) &&
                (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") == -1))
            ) {
                document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
            } else {
                document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
            }
        }, 1500);

        return false;
    }

    isDisabled = () => {
        return this.props.disabled;
    }

    componentDidMount() {
        setTimeout(function () {
            document.querySelector('#showLoadingOnTopPage') ? document.querySelector('#showLoadingOnTopPage').setAttribute('class', 'hidden') : null;
        }, 3000);
    }

    _componentDidUpdate(prevProps) {
        if (this.props.label != "ALPHA MENU" && this.labelDisplayingOnTab != this.props.label) {
            this.labelDisplayingOnTab = this.props.label;
        }
    }

    render() {
        console.log('Active Tab = ' + this.props.currentDialog, this.props.showingTabName);
        //var _labelDisplayingOnTab = this.labelDisplayingOnTab ? this.labelDisplayingOnTab : label;

        const {
            onClick,
            props: {
                activeTab,
                label,
                disabled,
                showOrHide
            },
        } = this;

        let className = 'tab-list-item';

        if (activeTab === label && (
            (label != "Hospitals" && label != "Phys Roster" && label != "Initialize Acc" && label != "Follow-up Order"
                && label != "Site Distribution" && label != "Treatment Distribution" && label != "Survival"
                && label != "Suspense" && label != "Incomplete" && label != "Productivity" && label != "Timeliness"
                && label != "Acc Register/MPI" && label != "Request Log" && label != "Report File"
                && label != "CoC" && label != "NAPBC" && label != "NAPRC" && label != "Quality Measures"
                && label != "Comparison Reports" && label != "Cross Tab"
                && label != "List Pop" && label != "Graph Pop"
                && label != "Age by Sex" && label != "Stage by Sex" && label != "TNM Graph" && label != "Days to Treatment"
                && label != "State Export" && label != "NCDB/RCRS Export" && label != "Export Viewer"
		        && label != "Genedits" && label != "Dashboard "
                && label != "Delete Abstract" && label != "Copy Abstract" && label != "Combine Patients"
                && label != "Abstract" && label != "Tx Summary/Custom" && label != "QA" && label != "SCP"
                && label != "Global Change" && label != "Import" //&& label != "User Logins"
            )
            || this.props.initialPageVisible)
        ) {
            className += ' tab-list-active';
        }

        if (disabled) {
            className += ' disabledClsTab';
        }
        if (showOrHide === "hide") {
            className += ' hiddenClsTab';
        }

        return (
            <li className={className}
                onClick={(e) => { this.onClick(e) }}
                disabled={disabled}
                data-show-hide={showOrHide}
                id={"Tabid_" + label.replace(" ", "_")}>{label}</li>
        );
    }
}

const mapStateToProps = state => {
    return {
        routingPage: state.patientAbstract.routingPage,
        currentDialog: state.patientAbstract.currentDialog,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        availableItems: state.patientAbstract.availableItems,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        inquiringMode: state.auth.inquiringMode,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        clientgroup: state.auth.clientgroup,
        rapidAbstract: state.patientAbstract.rapidAbstract,
        availableHospsData: state.auth.availableHospsData,
        alphaUrl: window.location ? window.location.pathname : "",
        showingTabName: state.patientAbstract.showingTabName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        showPageLoading: (value) => dispatch(actionCreators.showPageLoading(value)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearCommonLookupItem2: () => dispatch(actionCreators.clearCommonLookupItem2()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab);