/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./StagingMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";
import * as Global from "../../../../store/Global";

class StagingPre2018Maintenance extends Component {

    // At Staging page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        focusTemp: false,
        focusedFirstItem: false,
        activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stagingPre2018Form'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stagingPre2018Form back to what it was on load
        initialStagingPre2018Form: null,
        stagingPre2018Form: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "genStage",
        isHelpVisible: false,
        isDialogVisible: true,

        // set max-length for input fields
        getTableStructure: false,
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,

        //
        setPrimValue: false,
        primTopo: null,
        primHist: null,
        primSite: null,
        primKey: null,
        schemaNbr: "",//
        fnSchemaNbr: "",//
        ssfNbrArr: [], //25 items
        setCstageColorCodeSsf: false,
        setCstageCalculateScreen: false,
        derivedCollaborativeStageError: null,//~alpha:Calculation_Errors
        loadPageType: "",
        loadedSsfNbr: false,
        showConfirmationMsgOfCsSsf25: false,
        ensureSetColorCompleted: false,
        stepNumberToExecuteScript: 0,

        ssf: [],
        color: [],
        setSsfColor: false,

        focusingField: null,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isDialogVisible: false });
        return false;
    }

    hideConfirmationMsgOfCsSsf25 = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ showConfirmationMsgOfCsSsf25: false });

        setTimeout(function () {
            var csSsf25Val = document.getElementById("CS_SSF_25").value;
            document.getElementById("CS_SSF_25").value = "";
            document.getElementById("CS_SSF_25").value = csSsf25Val;
            document.getElementById("CS_SSF_25").focus();
        }, 0);

        return false;
    }

    // #20210125 - START
    showConfirmationMsgOrContinue = (event) => {
        //
        if (event.target.value === "ignore_msg_0") {
            this.setState({ isDialogVisible: false });
            return false;
        }

        if (this.props.isFormDirty) {
            if (event.target.value === "yes") {
                if (this.props.isChildren) {
                    this.props.setChildrenWindowVisible(false);
                } else {
                    this.props.setParentWindowVisible(false);
                    this.setState({ isDialogVisible: false });

                    // set this tab to disabled
                    this.props.setEnabledStatus(this.props.currentDialog, false);
                    // move to first tab is LOOKUP
                    this.props.removeTabItems(this.props.currentDialog);
                }
                this.setState({ isShowConfirmationMsg: false });
            } else {
                this.setState({ isShowConfirmationMsg: true });
            }
        } else {
            if (this.props.isChildren) {
                this.props.setChildrenWindowVisible(false);
            } else if (this.props.isLookup) {
                this.props.closeAddNewDialog(false);
            } else {
                this.props.setParentWindowVisible(false);
                this.setState({ isDialogVisible: false });

                // set this tab to disabled
                this.props.setEnabledStatus(this.props.currentDialog, false);
                // move to first tab is LOOKUP
                this.props.removeTabItems(this.props.currentDialog);
            }
            return false;
        }
    }
    // #20210125 - END

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    async reloadPrimInfo(cid) {
        let url = this.props.clientgroup + "/ReloadPrimInfo";
        let result = await axios
            .post(url, { key: cid, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = result && result.data ? result.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            setPrimValue: true,
            primSite: result.data.split('|')[0],
            primTopo: result.data.split('|')[1],
            primHist: result.data.split('|')[2],
            primKey: cid
        });

        //
        this.getSchemaNbr();
    }

    async getSchemaNbr() {
        let schemaNbrInState = this.state.schemaNbr;
        if (this.state.primTopo !== null && this.state.primTopo !== ""
            && this.state.primHist !== null && this.state.primHist !== ""
            && this.state.schemaNbr === "") {

            let primTopo = this.state.primTopo;
            let primHist = this.state.primHist;

            let disc = this.props.selectedStagingRecord ? this.props.selectedStagingRecord.csSsf25 : "";

            let schemaNbr = "";
            this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), "").then((_schemaNbr) => {
                schemaNbr = _schemaNbr;
                if (schemaNbr > 153) {
                    let discTableNbr = schemaNbr % 65536;
                    let dischSchema = (schemaNbr - discTableNbr) / 65536;
                    this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), disc).then((_schemaNbr) => {
                        schemaNbr = _schemaNbr;
                        if (schemaNbr > 153) {
                            schemaNbr = dischSchema;
                        }

                        // #set schemaNbr
                        if (schemaNbr !== "" && schemaNbrInState === "") {
                            this.setState({ schemaNbr: schemaNbr, fnSchemaNbr: schemaNbr, loadedSsfNbr: true });
                        }
                    });
                } else {
                    // #set schemaNbr
                    if (schemaNbr !== "" && schemaNbrInState === "") {
                        this.setState({ schemaNbr: schemaNbr, fnSchemaNbr: schemaNbr, loadedSsfNbr: true });
                    }
                }
            });

            //
            if (this.state.schemaNbr !== null && this.state.schemaNbr !== ""
                && !this.state.loadedSsfNbr) {
                this.loadSsfNbrArr();
            }

            // 
            if ((this.state.loadedSsfNbr || this.state.ssfNbrArr.length > 0) && !this.state.setCstageColorCodeSsf) {
                this.Cstage_Color_Code_Ssf();
                this.setState({ setCstageColorCodeSsf: true });
            }
        } else {
            //
            if (this.state.schemaNbr !== null && this.state.schemaNbr !== ""
                && !this.state.loadedSsfNbr) {
                this.loadSsfNbrArr();
            }

            // 
            if ((this.state.loadedSsfNbr || this.state.ssfNbrArr.length > 0) && !this.state.setCstageColorCodeSsf) {
                this.Cstage_Color_Code_Ssf();
                this.setState({ setCstageColorCodeSsf: true });
            }
        }
    }

    componentDidMount() {
        if (this.props.selectedMedicalRecord && this.props.selectedPrimarySummary) {
            let siteCode = this.props.selectedPrimarySummary.siteCode;
            let topoCode = this.props.selectedMedicalRecord.topoCode
            let histCode = this.props.selectedMedicalRecord.histCode;

            this.setState({
                setPrimValue: true,
                primSite: siteCode,
                primTopo: topoCode,
                primHist: histCode
            });

            this.getSchemaNbr();
        } else {
            let cid = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : "0";
            this.reloadPrimInfo(cid);
        }

        this.setState({ focusTemp: false, focusedFirstItem: false });

        /*
        let schemaNbrInState = this.state.schemaNbr;
        if (this.state.primTopo !== null && this.state.primTopo !== ""
            && this.state.primHist !== null && this.state.primHist !== ""
            && this.state.schemaNbr === "") {

            let primTopo = this.state.primTopo;
            let primHist = this.state.primHist;

            let disc = this.props.selectedStagingRecord ? this.props.selectedStagingRecord.csSsf25 : "";

            let schemaNbr = "";
            this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), "").then((_schemaNbr) => {
                schemaNbr = _schemaNbr;
                if (schemaNbr > 153) {
                    let discTableNbr = schemaNbr % 65536;
                    let dischSchema = (schemaNbr - discTableNbr) / 65536;
                    this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), disc).then((_schemaNbr) => {
                        schemaNbr = _schemaNbr;
                        if (schemaNbr > 153) {
                            schemaNbr = dischSchema;
                        }

                        // #set schemaNbr
                        if (schemaNbr !== "" && schemaNbrInState === "") {
                            this.setState({ schemaNbr: schemaNbr, fnSchemaNbr: schemaNbr, loadedSsfNbr: true });
                        }
                    });
                } else {
                    // #set schemaNbr
                    if (schemaNbr !== "" && schemaNbrInState === "") {
                        this.setState({ schemaNbr: schemaNbr, fnSchemaNbr: schemaNbr, loadedSsfNbr: true });
                    }
                }
            });
        }
        */

        if (!this.state.stagingPre2018Form && this.props.selectedStagingRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        //
        var schemaNbrDbHidden = document.getElementById('schemaNbrDb');
        var genStage = document.getElementById('genStage');
        var focusTemp = this.state.focusTemp;
        var focusedFirstItem = this.state.focusedFirstItem;
        if (schemaNbrDbHidden && !focusTemp) {
            var stgpre = this;
            setTimeout(function () {
                schemaNbrDbHidden.focus();
                stgpre.setState({ focusTemp: true });
            }, 100);
        } else if (genStage && focusTemp && !focusedFirstItem) {
            var stgpre = this;
            setTimeout(function () {
                genStage.focus();
                stgpre.setState({ focusedFirstItem: true });
            }, 100);
        } else if (genStage && !focusedFirstItem) {
            var stgpre = this;
            setTimeout(function () {
                genStage.focus();
                stgpre.setState({ focusedFirstItem: true });
            }, 100);
        }

        /*
        if (this.props.selectedMedicalRecord && this.props.selectedPrimarySummary && !this.state.setPrimValue) {
            let siteCode = this.props.selectedPrimarySummary.siteCode;
            let topoCode = this.props.selectedMedicalRecord.topoCode
            let histCode = this.props.selectedMedicalRecord.histCode;

            this.setState({
                setPrimValue: true,
                primSite: siteCode,
                primTopo: topoCode,
                primHist: histCode
            });

            this.getSchemaNbr();
        } else if (!this.state.setPrimValue) {
            let cid = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : "0";
            this.reloadPrimInfo(cid);
        }
        */

        if (this.state.schemaNbr && this.state.schemaNbr !== "" && !this.state.loadedSsfNbr) {
            this.loadSsfNbrArr();
            this.setState({ loadedSsfNbr: true });
        } else {
            this.getSchemaNbr();
        }

        // 
        if ((this.state.loadedSsfNbr || this.state.ssfNbrArr.length > 0) && !this.state.setCstageColorCodeSsf) {
            this.Cstage_Color_Code_Ssf();
            this.setState({ setCstageColorCodeSsf: true });
        }

        if ((!this.state.stagingPre2018Form && this.props.selectedStagingRecord)
            || (prevProps.selectedStagingRecord && this.props.selectedStagingRecord && prevProps.selectedStagingRecord !== this.props.selectedStagingRecord)
            || (this.props.initialAbstractPages && this.props.selectedStagingRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.stagingPre2018Form && this.state.sAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    stagingPre2018Form: { ...this.state.stagingPre2018Form, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                var isValid = "IN_VALID";
                if (this.props.currentSelectedField.indexOf("csSsf") != -1
                    && this.props.commonLookupItem.code === null
                    && this.state.stagingPre2018Form[this.props.currentSelectedField] === "988") {
                    isValid = "";
                }

                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: isValid },
                    // And also update name again
                    stagingPre2018Form: { ...this.state.stagingPre2018Form, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        // set maxlength for all fields
        if (this.state.tableStructureList === null && !this.state.getTableStructure) {
            this.getTableStructure();
            this.setState({ getTableStructure: true });
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    //maxLengthItem && maxLengthItem[obj[0]] !== undefined ? (maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1])) : null;
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        //
        if (!this.state.setSsfColor && this.state.color.length > 0 && this.state.ssf.length > 0) {
            this.colorCodeSsf(this.state.ssf, this.state.color, true);
        }

        //
        if (!this.state.setPrimValue && this.props.selectedMedicalRecord && this.props.selectedMedicalRecord.cid != null) {
            this.reloadPrimInfo(this.props.selectedMedicalRecord.cid);
            this.setState({ setPrimValue: true, ensureSetColorCompleted: false });
        }

        // --- Add all missing processes from alpha system
        // screenReload
        if (this.state.loadPageType === "screenReload" && this.state.initialStagingPre2018Form !== null) {
            var initDataForm = this.state.initialStagingPre2018Form;
            var stagingPre2018FormData = this.state.stagingPre2018Form;

            Object.keys(stagingPre2018FormData).map((t) => {
                if (t.indexOf("csSsf") != -1 && stagingPre2018FormData[t] === "988" && initDataForm[t] === "") {
                    stagingPre2018FormData[t] = "";
                }
            });

            this.setState({
                stagingPre2018Form: { ...this.state.stagingPre2018Form, stagingPre2018FormData },
                loadPageType: null,
            });
        }

        /*
        let schemaNbrInState = this.state.schemaNbr;
        if (this.state.primTopo !== null && this.state.primTopo !== ""
            && this.state.primHist !== null && this.state.primHist !== ""
            && this.state.schemaNbr === "") {

            let primTopo = this.state.primTopo;
            let primHist = this.state.primHist;

            let disc = this.props.selectedStagingRecord ? this.props.selectedStagingRecord.csSsf25 : "";

            let schemaNbr = "";
            this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), "").then((_schemaNbr) => {
                schemaNbr = _schemaNbr;
                if (schemaNbr > 153) {
                    let discTableNbr = schemaNbr % 65536;
                    let dischSchema = (schemaNbr - discTableNbr) / 65536;
                    this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), disc).then((_schemaNbr) => {
                        schemaNbr = _schemaNbr;
                        if (schemaNbr > 153) {
                            schemaNbr = dischSchema;
                        }

                        // #set schemaNbr
                        if (schemaNbr !== "" && schemaNbrInState === "") {
                            this.setState({ schemaNbr: schemaNbr, fnSchemaNbr: schemaNbr, loadedSsfNbr: true });
                        }
                    });
                } else {
                    // #set schemaNbr
                    if (schemaNbr !== "" && schemaNbrInState === "") {
                        this.setState({ schemaNbr: schemaNbr, fnSchemaNbr: schemaNbr, loadedSsfNbr: true });
                    }
                }
            });
        }
        */

        var setCstageColorCodeSsf = false;
        if (this.state.schemaNbr !== null && this.state.schemaNbr !== "" && !this.state.setCstageColorCodeSsf) {
            this.Cstage_Color_Code_Ssf();
            this.setState({ setCstageColorCodeSsf: true });
            setCstageColorCodeSsf = true;
        }

        if (this.state.fnSchemaNbr !== null && this.state.fnSchemaNbr !== "" && !this.state.setCstageCalculateScreen) {
            this.CstageCalculateScreen();
            this.setState({ setCstageCalculateScreen: true, loadedSsfNbr: false });
        }

        if ((this.state.loadedSsfNbr || this.state.ssfNbrArr.length > 0) && !this.state.setCstageColorCodeSsf && !setCstageColorCodeSsf) {
            this.Cstage_Color_Code_Ssf();
            this.setState({ setCstageColorCodeSsf: true });
        }
    }

    async loadSsfNbrArr() {
        let schemaNbr = this.state.schemaNbr;
        if (schemaNbr === null || schemaNbr === "") {
            return false;
        }

        let url = this.props.clientgroup + "/LoadSsfNbrArr";
        let res = await axios
            .post(url, { schemaNumber: this.state.schemaNbr, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        this.setState({
            ssfNbrArr: (res && res.data && res.data.csSsfLookupList) ? res.data.csSsfLookupList : [],
            loadedSsfNbr: true,
            focusTemp: false,
            focusedFirstItem: false,
            setCstageColorCodeSsf: false,
        });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Staging", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });
        
        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    // CSLibraryDLL
    async ERSCs_get_schema_number(site, hist, discriminator) {
        let url = this.props.clientgroup + "/ErsCsGetSchemaNumber";
        let params = {
            site: site,
            histology: hist,
            discriminiator: discriminator,
            accessToken: localStorage.getItem('token')
        };
        var res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        return res && res.data ? res.data.result : "";
    }

    fnEnableAllSsf = (event) => {
        var x = '';
        for (var i = 1; i < 26; i++) {
            x = document.getElementById('LBL-CS_SSF_' + i);
            if (x.style.color == 'rgb(184, 184, 184)') {
                x.style.backgroundColor = '';
                x.style.color = '';
            }
            x = document.getElementById('CS_SSF_' + i);
            x.style.backgroundColor = '';
            x.style.color = '';
            x.disabled = false;
        }
        this.setState({ stagingPre2018Form: { ...this.state.stagingPre2018Form, [event.target.name]: "1" } });
    }

    setFormData = () => {
        var dataForm = this.props.selectedStagingRecord;

        //
        var topoCode = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "";
        var siteCode = topoCode ? topoCode.substring(1, 3) : "";

        let initialStagingPre2018Form = {};
        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];

            if (siteCode === "42") {
                const defaultValue = "88";
                if ((t === "tnmEdNbr" || t === "pt" || t === "pn" || t === "pm" || t === "pAjccGrp"
                    || t === "ct" || t === "cn" || t === "cm" || t === "cAjccGrp")
                    && (dataVal === null || dataVal === "")) {
                    dataVal = defaultValue;
                }
            }

            if (t === "tnmEdNbr" && (dataVal === null || dataVal === "")) {
                dataVal = "07";
            } else if ((t === "pStgDescriptor" || t === "cStgDescriptor") && (dataVal === null || dataVal === "")) {
                dataVal = "0";
            }

            dataForm[t] = dataVal;

            initialStagingPre2018Form[t] = dataForm[t];
        });

        this.setState({
            stagingPre2018Form: { ...dataForm },
            initialStagingPre2018Form: { ...initialStagingPre2018Form },//dataForm
            //dummystagingPre2018Form: { ...dataForm },
            isFormDirty: this.props.isFormDirty,
        });

        if (!this.state.sAllItemFields && this.props.selectedStagingRecord) {
            let fields = {};
            Object.keys(this.props.selectedStagingRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({
                sAllItemFields: fields,
                pageMaxlengthInput: fields,
                focusTemp: false,
                focusedFirstItem: false,
                setCstageColorCodeSsf: false,
            });
        };

        /*
        // [Copied Abstract] case: fill in CSOriginal when initial screen
        var stgPre18 = this;
        setTimeout(function () {
            stgPre18.CstageCalculateScreen(true);
        }, 1000);
        */

        this.props.setInitialAbstractPages(false);

        return false;
    }

    colorCodeSsf = (ssf, color, ssfColor) => {
        var xlbl = "";
        var xfld = "";
        for (var i = 1; i <= 25; i++) {
            xlbl = document.getElementById("LBL-CS_SSF_" + ssf[i]);
            xfld = document.getElementById("CS_SSF_" + ssf[i]);

            if (xlbl === null || xfld === null) {
                return false;
            }

            switch (color[i]) {
                case "'blue'":
                case "blue":
                    xlbl.style.color = '#FF0000';
                    xlbl.style.backgroundColor = '';
                    xfld.style.backgroundColor = '';
                    xfld.style.color = '';
                    xfld.disabled = false;
                    break;
                case "'red'":
                case "red":
                    xlbl.style.backgroundColor = '#FF0000';
                    xlbl.style.color = '#FFFFFF';
                    xfld.style.backgroundColor = '';
                    xfld.style.color = '';
                    xfld.disabled = false;
                    break;
                case "'green'":
                case "green":
                    xlbl.style.color = '#FF0000';
                    xlbl.style.backgroundColor = '';
                    xfld.style.backgroundColor = '';
                    xfld.style.color = '';
                    xfld.disabled = false;
                    break;
                case "'white'":
                case "white":
                    xlbl.style.color = '#000000';
                    xlbl.style.backgroundColor = '';
                    xfld.style.backgroundColor = '';
                    xfld.style.color = '';
                    xfld.disabled = false;
                    break;
                case "'gray'":
                case "gray":
                    xlbl.style.color = '#000000';
                    xlbl.style.backgroundColor = '';
                    xfld.style.backgroundColor = '';
                    xfld.style.color = '';
                    xfld.disabled = false;
                    break;
                case "''":
                case "":
                case '':
                case null:
                case undefined:
                    xlbl.style.color = '#B8B8B8';
                    xfld.disabled = true;
                    xfld.style.backgroundColor = '#C8C8C8';
                    xfld.style.color = '#B8B8B8';
                    break;
                default:
                    xlbl.style.color = '#000000';
                    xlbl.style.backgroundColor = '';
                    xfld.style.backgroundColor = '';
                    xfld.style.color = '';
                    xfld.disabled = false;
                    break;
            }
        }

        this.setState({
            ensureSetColorCompleted: true,
            setSsfColor: !ssfColor ? false : ssfColor
        });
    }

    //
    async Cstage_Color_Code_Ssf() {
        let primKey = this.state.primKey ? this.state.primKey : this.props.selectedPrimarySummary.cid;
        let url = this.props.clientgroup + "/CstageColorCodeSsf";
        let res = await axios
            .post(url, { cId: primKey, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        //
        var site = res && res.data && res.data.result ? res.data.result.split('|')[0] : "";//Topo_Code
        var hist = res && res.data && res.data.result ? res.data.result.split('|')[1] : "";//Hist_Code
        var ssf25 = res && res.data && res.data.result ? res.data.result.split('|')[2] : "";

        var schemaNumber = this.state.fnSchemaNbr;
        var primTopo = this.state.primTopo;
        var primHist = this.state.primHist;

        var discNeeded = false;

        if ((primTopo != site && primTopo != "") || (primHist != hist && primHist != "")) {
            this.ERSCs_get_schema_number(primTopo, primHist, "").then((_schemaNumber) => {
                schemaNumber = _schemaNumber;
                if (schemaNumber > 153) {
                    discNeeded = true;
                    let discTableNbr = schemaNumber % 65536;
                    let dischSchema = (schemaNumber - discTableNbr) / 65536;
                    this.ERSCs_get_schema_number(site, hist, ssf25).then((_schemaNumber) => {
                        schemaNumber = _schemaNumber;
                        if (schemaNumber > 153) {
                            schemaNumber = dischSchema;
                        }

                        //
                        //                        if (this.state.ssfNbrArr && this.state.ssfNbrArr.length > 0) {
                        var ssf = Array.from({ length: 26 });
                        var ssf_color = Array.from({ length: 26 });
                        for (var ii = 0; ii < 26; ii++) {
                            ssf[ii] = "";
                            ssf_color[ii] = "";
                        }
                        //
                        var count = 1;
                        var flag = 0;
                        var ssfNbrArr = this.state.ssfNbrArr;
                        while (count < 26) {
                            if (flag < ssfNbrArr.length) {
                                var nbr = ssfNbrArr[flag]["ssfNbr"];
                                while (nbr != count && count != 26) {
                                    ssf[count] = count;
                                    ssf_color[count] = "''";
                                    count = count + 1;
                                }
                                ssf[nbr] = nbr;
                                ssf_color[nbr] = "'" + (ssfNbrArr[flag]["color"] ? ssfNbrArr[flag]["color"] : "") + "'";
                            } else {
                                ssf[count] = count;
                                ssf_color[count] = "''";
                            }
                            flag = flag + 1;
                            count = count + 1;
                        }
                        //--

                        if (discNeeded && ssf25 === "") {
                            ssf[25] = "25";
                            ssf_color[25] = "'red'";
                        }

                        var sg = this;
                        setTimeout(function () {
                            //console.log("Calling colorCodeSsf [1]");
                            sg.colorCodeSsf(ssf, ssf_color, false);
                            sg.setState({ ssf: ssf, color: ssf_color });
                        }, 0);
                        //                        }

                        if ((primTopo != site && primTopo != "") || (primHist != hist && primHist != "")) {
                            this.setState({
                                fnSchemaNbr: schemaNumber
                            });
                        }
                    });
                } else {
                    //
                    //                    if (this.state.ssfNbrArr && this.state.ssfNbrArr.length > 0) {
                    var ssf = Array.from({ length: 26 });
                    var ssf_color = Array.from({ length: 26 });
                    for (var ii = 0; ii < 26; ii++) {
                        ssf[ii] = "";
                        ssf_color[ii] = "";
                    }
                    //
                    var count = 1;
                    var flag = 0;
                    var ssfNbrArr = this.state.ssfNbrArr;
                    while (count < 26) {
                        if (flag < ssfNbrArr.length) {
                            var nbr = ssfNbrArr[flag]["ssfNbr"];
                            while (nbr != count && count != 26) {
                                ssf[count] = count;
                                ssf_color[count] = "''";
                                count = count + 1;
                            }
                            ssf[nbr] = nbr;
                            ssf_color[nbr] = "'" + (ssfNbrArr[flag]["color"] ? ssfNbrArr[flag]["color"] : "") + "'";
                        } else {
                            ssf[count] = count;
                            ssf_color[count] = "''";
                        }
                        flag = flag + 1;
                        count = count + 1;
                    }
                    //--

                    if (discNeeded && ssf25 === "") {
                        ssf[25] = "25";
                        ssf_color[25] = "'red'";
                    }

                    var sg = this;
                    //console.log("Calling colorCodeSsf [2]");
                    setTimeout(function () {
                        sg.colorCodeSsf(ssf, ssf_color, false);
                        sg.setState({ ssf: ssf, color: ssf_color });
                    }, 0);
                    //                    }

                    if ((primTopo != site && primTopo != "") || (primHist != hist && primHist != "")) {
                        this.setState({
                            fnSchemaNbr: schemaNumber
                        });
                    }
                }
            });
        } else {
            //
            //            if (this.state.ssfNbrArr && this.state.ssfNbrArr.length > 0) {
            var ssf = Array.from({ length: 26 });
            var ssf_color = Array.from({ length: 26 });
            for (var ii = 0; ii < 26; ii++) {
                ssf[ii] = "";
                ssf_color[ii] = "";
            }
            //
            var count = 1;
            var flag = 0;
            var ssfNbrArr = this.state.ssfNbrArr;
            while (count < 26) {
                if (flag < ssfNbrArr.length) {
                    var nbr = ssfNbrArr[flag]["ssfNbr"];
                    while (nbr != count && count != 26) {
                        ssf[count] = count;
                        ssf_color[count] = "''";
                        count = count + 1;
                    }
                    ssf[nbr] = nbr;
                    ssf_color[nbr] = "'" + (ssfNbrArr[flag]["color"] ? ssfNbrArr[flag]["color"] : "") + "'";
                } else {
                    ssf[count] = count;
                    ssf_color[count] = "''";
                }
                flag = flag + 1;
                count = count + 1;
            }
            //--

            if (discNeeded && ssf25 === "") {
                ssf[25] = "25";
                ssf_color[25] = "'red'";
            }

            var sg = this;
            //console.log("Calling colorCodeSsf [3]");
            setTimeout(function () {
                sg.colorCodeSsf(ssf, ssf_color, false);
                sg.setState({ ssf: ssf, color: ssf_color });
            }, 0);
            //            }

            if ((primTopo != site && primTopo != "") || (primHist != hist && primHist != "")) {
                this.setState({
                    fnSchemaNbr: schemaNumber
                });
            }
        }
    }

    // supporting functions
    padr = (str, len, rep) => {
        return str !== null && str !== undefined ? str.padEnd(len, rep) : rep.padEnd(len);
    }
    space = (len) => {
        return Array(len + 1).join(" ");
    }
    alltrim = (str) => {
        if (str instanceof Array) return str.join(' ').trim();
        return str !== null && str !== undefined ? str.trim() : "";
    }

    // ...
    async CstageCalculateScreen(copiedAbst) {
        var stagingPre2018FormData = this.state.stagingPre2018Form;

        if (this.state.fnSchemaNbr === null || this.state.fnSchemaNbr === "" || stagingPre2018FormData === null) {
            this.setState({ setCstageCalculateScreen: false });
            return false;
        }

        var isUpdated = false;

        var SSF = Array.from({ length: 26 });
        for (var ii = 0; ii < 26; ii++) {
            SSF[ii] = "";
        }

        //Build array of the ssf values from the screen, which will be used for setting defaults
        var anyBlank = false;

        var updatedItems = {};

        var codeCount = Array.from({ length: 26 }, () => ({ Code: "", Code_Count: 0 }));
        for (var i = 1; i <= 25; i++) {
            codeCount[i].Code = "";
            codeCount[i].Code_Count = 0;

            SSF[i] = stagingPre2018FormData["csSsf" + i] ? stagingPre2018FormData["csSsf" + i] : "";//eval(stagingPre2018FormData["csSsf" + i]);
            if (SSF[i] === null || SSF[i] === undefined || SSF[i] === "") {
                anyBlank = true;
            }
        }

        //Only check for default values if at least one SSF is blank
        if (anyBlank) {
            let url = this.props.clientgroup + "/CstageCalculateScreen";
            let res = await axios
                .post(url, { schemaNumber: this.state.schemaNbr, accessToken: localStorage.getItem('token') })
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });

            //update new accessToken again
            var newAccessToken = res && res.data ? res.data.accessToken : null;
            if (newAccessToken) {
                localStorage.setItem('token', newAccessToken);
            }

            var cstageLookupList = res.data.cstageLookupList ? res.data.cstageLookupList : [];
            var Curr_SSF_Nbr = 1;
            var startIdx = 1;
            while (startIdx < cstageLookupList.length) {
                const code = cstageLookupList[startIdx]["code"];
                Curr_SSF_Nbr = cstageLookupList[startIdx]["ssfNbr"];

                codeCount[Curr_SSF_Nbr].Code_Count = codeCount[Curr_SSF_Nbr].Code_Count + 1;

                if (code === "988") {
                    codeCount[Curr_SSF_Nbr].Code = code;
                }
                startIdx++;
            }

            for (var i = 1; i <= 25; i++) {
                if (codeCount[i].Code_Count === 1 && codeCount[i].Code === "988" && SSF[i] === "") {
                    SSF[i] = "988";
                    //
                    stagingPre2018FormData["csSsf" + i] = "988";

                    updatedItems["csSsf" + i] = "988";

                    isUpdated = true;
                }
            }
        }

        if (isUpdated) {
            this.setState({
                stagingPre2018Form: {
                    ...this.state.stagingPre2018Form,
                    updatedItems
                },
                //isFormDirty: true,
            });
        }

        // List all params
        var csVersCurrent;
        var site = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "";
        var histology = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.histCode : "";
        var diagnosisYear = this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.diagnosisDateYear ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
        var csVers = stagingPre2018FormData["csVersion1st"] ? stagingPre2018FormData["csVersion1st"] : "";

        var behav = histology.substr(4);
        var grade = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.grade : "";

        var lvi = stagingPre2018FormData["lymphVascularInv"];
        var tSize = stagingPre2018FormData["csTumorSize"];

        var age = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.ageDx : "";

        var ext = stagingPre2018FormData["extn"];
        var extEval = stagingPre2018FormData["csSizeExtnEval"];
        var nodes = stagingPre2018FormData["lymphNd"];
        var lNodesEval = stagingPre2018FormData["csNodesEval"];
        var lnPos = stagingPre2018FormData["ndPos"];
        var lnExam = stagingPre2018FormData["ndXam"];
        var mets = stagingPre2018FormData["csMetsDx"];
        var metEval = stagingPre2018FormData["csMetsEval"];
        var ssf1 = SSF[1];
        var ssf2 = SSF[2];
        var ssf3 = SSF[3];
        var ssf4 = SSF[4];
        var ssf5 = SSF[5];
        var ssf6 = SSF[6];
        var ssf7 = SSF[7];
        var ssf8 = SSF[8];
        var ssf9 = SSF[9];
        var ssf10 = SSF[10];
        var ssf11 = SSF[11];
        var ssf12 = SSF[12];
        var ssf13 = SSF[13];
        var ssf14 = SSF[14];
        var ssf15 = SSF[15];
        var ssf16 = SSF[16];
        var ssf17 = SSF[17];
        var ssf18 = SSF[18];
        var ssf19 = SSF[19];
        var ssf20 = SSF[20];
        var ssf21 = SSF[21];
        var ssf22 = SSF[22];
        var ssf23 = SSF[23];
        var ssf24 = SSF[24];
        var ssf25 = SSF[25];
        var cid = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.cid : "0";

        //Do lookup based on cid to get formatted input string
        var params = {
            site: site,
            histology: histology,
            diagnosisYear: diagnosisYear,
            csVers: csVers,
            behav: behav,
            grade: grade,
            lvi: lvi,
            tSize: tSize,
            age: age,
            ext: ext,
            extEval: extEval,
            nodes: nodes,
            lNodesEval: lNodesEval,
            lnPos: lnPos,
            lnExam: lnExam,
            mets: mets,
            metEval: metEval,
            ssf1: ssf1,
            ssf2: ssf2,
            ssf3: ssf3,
            ssf4: ssf4,
            ssf5: ssf5,
            ssf6: ssf6,
            ssf7: ssf7,
            ssf8: ssf8,
            ssf9: ssf9,
            ssf10: ssf10,
            ssf11: ssf11,
            ssf12: ssf12,
            ssf13: ssf13,
            ssf14: ssf14,
            ssf15: ssf15,
            ssf16: ssf16,
            ssf17: ssf17,
            ssf18: ssf18,
            ssf19: ssf19,
            ssf20: ssf20,
            ssf21: ssf21,
            ssf22: ssf22,
            ssf23: ssf23,
            ssf24: ssf24,
            ssf25: ssf25,
            cid: cid, accessToken: localStorage.getItem('token')
        };

        let url = this.props.clientgroup + "/GetFormattedInputString";//And also get message error (if any)
        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var combineStr = res.data ? res.data.result : "";
        var inputStr = combineStr.split('~|~')[0];
        var derivedCollaborativeStageError = combineStr.split('~|~')[1];

        //After the calculation is done, we need to compare the new values with the originals
        //Check what the original version(csVers) is and compare to the 1st.  If no first, the current also becomes the first as well as the last
        csVersCurrent = await this.ERSCs_get_version();
        if (csVers.trim() == "") {
            stagingPre2018FormData["csVersion1st"] = csVersCurrent;

            updatedItems["csVersion1st"] = csVersCurrent;//#
        }

        if (stagingPre2018FormData["csVersionLast"] != csVersCurrent) {
            stagingPre2018FormData["csVersionLast"] = csVersCurrent;

            updatedItems["csVersionLast"] = csVersCurrent;//#
        }

        /*
        url = this.props.clientgroup + "/GetTdataCard";
        let resTcard = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });

        var tdatacard = resTcard.data;
        */
        var tdatacard = JSON.parse(inputStr);

        if (tdatacard !== null && tdatacard !== undefined) {
            /*
            //If the values in the datacard differ from what's on the screen, update the screen's values
            if (this.alltrim(stagingPre2018FormData["csTDisplay"]) != this.alltrim(tdatacard.t))
                stagingPre2018FormData["csTDisplay"] = new String(tdatacard.t, "").replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csTDescriptor"]) != this.alltrim(tdatacard.stor_tdescr))
                stagingPre2018FormData["csTDescriptor"] = new String(tdatacard.stor_tdescr).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csNDisplay"]) != this.alltrim(tdatacard.n))
                stagingPre2018FormData["csNDisplay"] = new String(tdatacard.n).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csNDescriptor"]) != this.alltrim(tdatacard.stor_ndescr))
                stagingPre2018FormData["csNDescriptor"] = new String(tdatacard.stor_ndescr).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csMDisplay"]) != this.alltrim(tdatacard.m))
                stagingPre2018FormData["csMDisplay"] = new String(tdatacard.m).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csMDescriptor"]) != this.alltrim(tdatacard.stor_mdescr))
                stagingPre2018FormData["csMDescriptor"] = new String(tdatacard.stor_mdescr).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csStageGrpDisplay"]) != this.alltrim(tdatacard.ajcc))
                stagingPre2018FormData["csStageGrpDisplay"] = new String(tdatacard.ajcc).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7tDisplay"]) != this.alltrim(tdatacard.ajcc7_t))
                stagingPre2018FormData["cs7tDisplay"] = new String(tdatacard.ajcc7_t).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7tDescriptor"]) != this.alltrim(tdatacard.stor_ajcc7_tdescr))
                stagingPre2018FormData["cs7tDescriptor"] = new String(tdatacard.stor_ajcc7_tdescr).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7nDisplay"]) != this.alltrim(tdatacard.ajcc7_n))
                stagingPre2018FormData["cs7nDisplay"] = new String(tdatacard.ajcc7_n).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7nDescriptor"]) != this.alltrim(tdatacard.stor_ajcc7_ndescr))
                stagingPre2018FormData["cs7nDescriptor"] = new String(tdatacard.stor_ajcc7_ndescr).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7mDisplay"]) != this.alltrim(tdatacard.ajcc7_m))
                stagingPre2018FormData["cs7mDisplay"] = new String(tdatacard.ajcc7_m).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7mDescriptor"]) != this.alltrim(tdatacard.stor_ajcc7_mdescr))
                stagingPre2018FormData["cs7mDescriptor"] = new String(tdatacard.stor_ajcc7_mdescr).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7stageGroupDisplay"]) != this.alltrim(tdatacard.ajcc7_stage))
                stagingPre2018FormData["cs7stageGroupDisplay"] = new String(tdatacard.ajcc7_stage).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs1977TDisplay"]) != this.alltrim(tdatacard.t77))
                stagingPre2018FormData["cs1977TDisplay"] = new String(tdatacard.t77).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs1977NDisplay"]) != this.alltrim(tdatacard.n77))
                stagingPre2018FormData["cs1977NDisplay"] = new String(tdatacard.n77).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs1977MDisplay"]) != this.alltrim(tdatacard.m77))
                stagingPre2018FormData["cs1977MDisplay"] = new String(tdatacard.m77).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs1977StageDisplay"]) != this.alltrim(tdatacard.ss77))
                stagingPre2018FormData["cs1977StageDisplay"] = new String(tdatacard.ss77).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs2000TDisplay"]) != this.alltrim(tdatacard.t2000))
                stagingPre2018FormData["cs2000TDisplay"] = new String(tdatacard.t2000).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs2000NDisplay"]) != this.alltrim(tdatacard.n2000))
                stagingPre2018FormData["cs2000NDisplay"] = new String(tdatacard.n2000).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs2000MDisplay"]) != this.alltrim(tdatacard.m2000))
                stagingPre2018FormData["cs2000MDisplay"] = new String(tdatacard.m2000).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs2000StageDisplay"]) != this.alltrim(tdatacard.ss2000))
                stagingPre2018FormData["cs2000StageDisplay"] = new String(tdatacard.ss2000).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csT"]) != this.alltrim(tdatacard.stor_t))
                stagingPre2018FormData["csT"] = new String(tdatacard.stor_t).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csN"]) != this.alltrim(tdatacard.stor_n))
                stagingPre2018FormData["csN"] = new String(tdatacard.stor_n).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csM"]) != this.alltrim(tdatacard.stor_m))
                stagingPre2018FormData["csM"] = new String(tdatacard.stor_m).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csStageGrp"]) != this.alltrim(tdatacard.stor_ajcc))
                stagingPre2018FormData["csStageGrp"] = new String(tdatacard.stor_ajcc).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7t"]) != this.alltrim(tdatacard.stor_ajcc7_t))
                stagingPre2018FormData["cs7t"] = new String(tdatacard.stor_ajcc7_t).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7n"]) != this.alltrim(tdatacard.stor_ajcc7_n))
                stagingPre2018FormData["cs7n"] = new String(tdatacard.stor_ajcc7_n).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7m"]) != this.alltrim(tdatacard.stor_ajcc7_m))
                stagingPre2018FormData["cs7m"] = new String(tdatacard.stor_ajcc7_m).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["cs7stageGroup"]) != this.alltrim(tdatacard.stor_ajcc7_stage))
                stagingPre2018FormData["cs7stageGroup"] = new String(tdatacard.stor_ajcc7_stage).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csSs1977"]) != this.alltrim(tdatacard.stor_ss77))
                stagingPre2018FormData["csSs1977"] = new String(tdatacard.stor_ss77).replace('\0', '');
            if (this.alltrim(stagingPre2018FormData["csSs2000"]) != this.alltrim(tdatacard.stor_ss2000))
                stagingPre2018FormData["csSs2000"] = new String(tdatacard.stor_ss2000).replace('\0', '');
            */
            stagingPre2018FormData["csTDisplay"] = tdatacard.t.join("");
            stagingPre2018FormData["csTDescriptor"] = tdatacard.stor_tdescr.join("");
            stagingPre2018FormData["csNDisplay"] = tdatacard.n.join("");
            stagingPre2018FormData["csNDescriptor"] = tdatacard.stor_ndescr.join("");
            stagingPre2018FormData["csMDisplay"] = tdatacard.m.join("");
            stagingPre2018FormData["csMDescriptor"] = tdatacard.stor_mdescr.join("");
            stagingPre2018FormData["csStageGrpDisplay"] = tdatacard.ajcc.join("");
            stagingPre2018FormData["cs7tDisplay"] = tdatacard.ajcc7_t.join("");
            stagingPre2018FormData["cs7tDescriptor"] = tdatacard.stor_ajcc7_tdescr.join("");
            stagingPre2018FormData["cs7nDisplay"] = tdatacard.ajcc7_n.join("");
            stagingPre2018FormData["cs7nDescriptor"] = tdatacard.stor_ajcc7_ndescr.join("");
            stagingPre2018FormData["cs7mDisplay"] = tdatacard.ajcc7_m.join("");
            stagingPre2018FormData["cs7mDescriptor"] = tdatacard.stor_ajcc7_mdescr.join("");
            stagingPre2018FormData["cs7stageGroupDisplay"] = tdatacard.ajcc7_stage.join("");
            stagingPre2018FormData["cs1977TDisplay"] = tdatacard.t77.join("");
            stagingPre2018FormData["cs1977NDisplay"] = tdatacard.n77.join("");
            stagingPre2018FormData["cs1977MDisplay"] = tdatacard.m77.join("");
            stagingPre2018FormData["cs1977StageDisplay"] = tdatacard.ss77.join("");
            stagingPre2018FormData["cs2000TDisplay"] = tdatacard.t2000.join("");
            stagingPre2018FormData["cs2000NDisplay"] = tdatacard.n2000.join("");
            stagingPre2018FormData["cs2000MDisplay"] = tdatacard.m2000.join("");
            stagingPre2018FormData["cs2000StageDisplay"] = tdatacard.ss2000.join("");
            stagingPre2018FormData["csT"] = tdatacard.stor_t.join("");
            stagingPre2018FormData["csN"] = tdatacard.stor_n.join("");
            stagingPre2018FormData["csM"] = tdatacard.stor_m.join("");
            stagingPre2018FormData["csStageGrp"] = tdatacard.stor_ajcc.join("");
            stagingPre2018FormData["cs7t"] = tdatacard.stor_ajcc7_t.join("");
            stagingPre2018FormData["cs7n"] = tdatacard.stor_ajcc7_n.join("");
            stagingPre2018FormData["cs7m"] = tdatacard.stor_ajcc7_m.join("");
            stagingPre2018FormData["cs7stageGroup"] = tdatacard.stor_ajcc7_stage.join("");
            stagingPre2018FormData["csSs1977"] = tdatacard.stor_ss77.join("");
            stagingPre2018FormData["csSs2000"] = tdatacard.stor_ss2000.join("");

            //#
            updatedItems["csTDisplay"] = tdatacard.t.join("");
            updatedItems["csTDescriptor"] = tdatacard.stor_tdescr.join("");
            updatedItems["csNDisplay"] = tdatacard.n.join("");
            updatedItems["csNDescriptor"] = tdatacard.stor_ndescr.join("");
            updatedItems["csMDisplay"] = tdatacard.m.join("");
            updatedItems["csMDescriptor"] = tdatacard.stor_mdescr.join("");
            updatedItems["csStageGrpDisplay"] = tdatacard.ajcc.join("");
            updatedItems["cs7tDisplay"] = tdatacard.ajcc7_t.join("");
            updatedItems["cs7tDescriptor"] = tdatacard.stor_ajcc7_tdescr.join("");
            updatedItems["cs7nDisplay"] = tdatacard.ajcc7_n.join("");
            updatedItems["cs7nDescriptor"] = tdatacard.stor_ajcc7_ndescr.join("");
            updatedItems["cs7mDisplay"] = tdatacard.ajcc7_m.join("");
            updatedItems["cs7mDescriptor"] = tdatacard.stor_ajcc7_mdescr.join("");
            updatedItems["cs7stageGroupDisplay"] = tdatacard.ajcc7_stage.join("");
            updatedItems["cs1977TDisplay"] = tdatacard.t77.join("");
            updatedItems["cs1977NDisplay"] = tdatacard.n77.join("");
            updatedItems["cs1977MDisplay"] = tdatacard.m77.join("");
            updatedItems["cs1977StageDisplay"] = tdatacard.ss77.join("");
            updatedItems["cs2000TDisplay"] = tdatacard.t2000.join("");
            updatedItems["cs2000NDisplay"] = tdatacard.n2000.join("");
            updatedItems["cs2000MDisplay"] = tdatacard.m2000.join("");
            updatedItems["cs2000StageDisplay"] = tdatacard.ss2000.join("");
            updatedItems["csT"] = tdatacard.stor_t.join("");
            updatedItems["csN"] = tdatacard.stor_n.join("");
            updatedItems["csM"] = tdatacard.stor_m.join("");
            updatedItems["csStageGrp"] = tdatacard.stor_ajcc.join("");
            updatedItems["cs7t"] = tdatacard.stor_ajcc7_t.join("");
            updatedItems["cs7n"] = tdatacard.stor_ajcc7_n.join("");
            updatedItems["cs7m"] = tdatacard.stor_ajcc7_m.join("");
            updatedItems["cs7stageGroup"] = tdatacard.stor_ajcc7_stage.join("");
            updatedItems["csSs1977"] = tdatacard.stor_ss77.join("");
            updatedItems["csSs2000"] = tdatacard.stor_ss2000.join("");
            //

            if (stagingPre2018FormData["csAjccFlag"] != "1") {
                stagingPre2018FormData["csAjccFlag"] = "1";

                updatedItems["csAjccFlag"] = "1";//#
            }
            if (stagingPre2018FormData["csSs1977Flag"] != "1") {
                stagingPre2018FormData["csSs1977Flag"] = "1";

                updatedItems["csSs1977Flag"] = "1";//#
            }
            if (stagingPre2018FormData["csSs2000Flag"] != "1") {
                stagingPre2018FormData["csSs2000Flag"] = "1";

                updatedItems["csSs2000Flag"] = "1";//#
            }
        }
        //
        this.setState({
            stagingPre2018Form: {
                ...this.state.stagingPre2018Form,
                ...updatedItems
            },
            derivedCollaborativeStageError: derivedCollaborativeStageError,
        });
    }

    async ERSCs_get_version() {
        let url = this.props.clientgroup + "/ErsCsGetVersion";
        let res = await axios
            .post(url, { accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        return res && res.data && res.data.result ? res.data.result : "";
    }

    saveStaging = (event) => {
        event.preventDefault();

        // TODO - check form validation
        var isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.sAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            var stagingPre2018FormData = this.state.stagingPre2018Form;
            stagingPre2018FormData.cid = this.props.selectedMedicalRecord.cid;

            this.props.saveStaging(stagingPre2018FormData);

            return false;
        }
    }

    resetStaging = () => {
        this.setState({
            stagingPre2018Form: { ...this.state.initialStagingPre2018Form },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusTemp: true, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        if (this.state.fieldInFocus) {
            this.setState({ isHelpVisible: true });
        }
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = async (event, obj) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "";

            let cid = this.props.selectedPrimarySummary.cid;

            this.props.checkValidInput(name, value, table, key, -1, flag, cid);
        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }

        // add onchange event on alpha system
        if (event.target.name === "csTumorSize"
            || event.target.name === "extn"
            || event.target.name === "csSizeExtnEval"
            || event.target.name === "lymphNd"
            || event.target.name === "csNodesEval"
            || event.target.name === "ndPos"
            || event.target.name === "ndXam"
            || event.target.name === "lymphVascularInv"
            || event.target.name === "csMetsDx"
            || event.target.name === "csMetsEval"
            || event.target.name === "csMetsDxBone"
            || event.target.name === "csMetsDxBrain"
            || event.target.name === "csMetsDxLiver"
            || event.target.name === "csMetsDxLung"
            || event.target.name === "metsDxDistantLn"
            || event.target.name === "metsDxOther"
            || event.target.name === "schemaNbrDb"
            || event.target.name.indexOf("csSsf") !== -1) {
            await this.CstageCalculateScreen(
                //event.target.name === "csSsf25" || (event.target.value !== "" && event.target.value !== dummyDataForm[event.target.name])
            );

            //this.setState({ dummystagingPre2018Form: { ...this.state.dummystagingPre2018Form, [event.target.name]: event.target.value } });
        }

        var _this = obj ? obj : this;
        var _event = event;
        //if (event.target.name === "csSsf25")
        await setTimeout(function () {
            //
            if (_this.state.primTopo !== null && _this.state.primTopo !== ""
                && _this.state.primHist !== null && _this.state.primHist !== "") {
                var onScreenSchemaNbr = _this.state.fnSchemaNbr;

                var primTopo = _this.state.primTopo;
                var primHist = _this.state.primHist;

                var disc = _event.target && _event.target.value ? _event.target.value : "";

                var schemaNbr = "";
                _this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), "").then((_schemaNbr) => {
                    schemaNbr = _schemaNbr;
                    if (schemaNbr > 153) {
                        let discTableNbr = schemaNbr % 65536;
                        let dischSchema = (schemaNbr - discTableNbr) / 65536;
                        _this.ERSCs_get_schema_number(primTopo, primHist.substr(0, 4), disc).then((_schemaNbr) => {
                            schemaNbr = _schemaNbr;
                            if (schemaNbr > 153) {
                                schemaNbr = dischSchema;
                            }

                            // show confirm message
                            if (schemaNbr !== onScreenSchemaNbr && onScreenSchemaNbr !== "") {
                                _this.setState({ showConfirmationMsgOfCsSsf25: true, fnSchemaNbr: schemaNbr });
                            }
                        });
                    } else {
                        // show confirm message
                        if (schemaNbr !== onScreenSchemaNbr && onScreenSchemaNbr !== "") {
                            _this.setState({ showConfirmationMsgOfCsSsf25: true, fnSchemaNbr: schemaNbr });
                        }
                    }
                });

                // reload and check validation of card then show message if any
                var initDataForm = _this.state.initialstagingPre2018Form;
                if (_event.target && _event.target.value !== initDataForm["csSsf25"]) {
                    _this.Cstage_Color_Code_Ssf();
                    _this.setState({ setCstageColorCodeSsf: true });
                }
            }
        }, 0);
    }
    // -- END

    btnSaveReopen = (event) => {
        try {
            event.preventDefault();
        } catch (ex) { }

        this.setState({ loadPageType: "screenReload", showConfirmationMsgOfCsSsf25: false });
        return false;
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ stagingPre2018Form: { ...this.state.stagingPre2018Form, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ stagingPre2018Form: { ...this.state.stagingPre2018Form, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        const stagingMaintenance = this.state.stagingPre2018Form && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {
                        this.state.isDialogVisible && this.props.selectedMedicalRecord &&
                        (this.props.selectedMedicalRecord.topoCode === null || this.props.selectedMedicalRecord.topoCode === "" || this.props.selectedMedicalRecord.histCode === null || this.props.selectedMedicalRecord.histCode === "") &&
                        /*
                        <Dialog title="Warning"
                            onClose={this.closeWarningDialog}
                            width={500} height={"auto"}
                            className="Help">*/
                        <div className="CRStar_Window">
                            <div className="fake_popup_cls"></div>
                            <Draggable handle=".k-window-titlebar">
                                <div className="dragableWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title k-dialog-title">Warning</div>
                                        <div className="k-window-actions k-dialog-actions">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWarningDialog(e) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <label>A valid topography code and histology code must be entered prior to entering the staging screen.</label>
                                    <div className="center">
                                        <button className="validationErr_btnOk_Cls" onClick={this.showConfirmationMsgOrContinue} value="ignore_msg" autoFocus>OK</button>
                                    </div>
                                </div>
                                {/*</Dialog>*/}
                            </Draggable>
                        </div>
                    }

                    {
                        this.state.showConfirmationMsgOfCsSsf25 &&
                        /*
                        <Dialog title="Warning"
                            onClose={this.hideConfirmationMsgOfCsSsf25}
                            width={500} height={"auto"}
                            className="Help">*/
                        <div className="CRStar_Window">
                            <div className="fake_popup_cls"></div>
                            <Draggable handle=".k-window-titlebar">
                                <div className="dragableWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title k-dialog-title">Warning</div>
                                        <div className="k-window-actions k-dialog-actions">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationMsgOfCsSsf25(e) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <label>
                                        The new discrimator value has caused a different Schema to be identified.<br />
                                        The current values on the staging screen will now be saved<br />
                                        and the staging screen will be reopened to reflect the new Schema.
                                    </label>
                                    <div className="center">
                                        <button onClick={this.btnSaveReopen} value="yes" autoFocus>OK</button>
                                        <button onClick={this.hideConfirmationMsgOfCsSsf25} value="no">Cancel</button>
                                    </div>
                                </div>
                                {/*</Dialog>*/}
                            </Draggable>
                        </div>
                    }

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveStaging} id="stagingPre2018Form" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp} name="btnHelp">
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="displayDataInfo">
                            <div className="row">
                                <div className="col-md-2 extendRightDiv stagCol1">
                                    <div className="frameInner A5CWLayout">
                                        <div className="header">&nbsp;</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="SEER Sum Stage" field="genStage" lineStyle="oneLine onTopFrame"
                                            value={this.state.stagingPre2018Form.GenStage ? this.state.stagingPre2018Form.GenStage : this.state.stagingPre2018Form.genStage}
                                            dataSource="Site_Appr_Codes_Mv" aliasNo="13" titleDialog="General Stage"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["genStage"]} itemNbr="120"
                                            lostfocused={this.handleLostFocus} autoFocus
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["genStage"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Ajcc Basis" field="ajccBasis" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.AjccBasis ? this.state.stagingPre2018Form.AjccBasis : this.state.stagingPre2018Form.ajccBasis}
                                            dataSource="Global_Dir_Mv" aliasNo="121" titleDialog="Ajcc Basis"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["ajccBasis"]} itemNbr="121"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccBasis"] : ""}
                                        />

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Tnm Ed Nbr" field="tnmEdNbr" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.TnmEdNbr ? this.state.stagingPre2018Form.TnmEdNbr : this.state.stagingPre2018Form.tnmEdNbr}
                                            dataSource="Global_Dir_Mv" aliasNo="786" titleDialog="Tnm Ed Nbr"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["tnmEdNbr"]} itemNbr="786"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tnmEdNbr"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="pT" field="pt" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pt ? this.state.stagingPre2018Form.pt : this.state.stagingPre2018Form.Pt}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="pTID"
                                            titleDialog="Path T of Tnm Stage"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pt"]} itemNbr="122"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pt"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="pN" field="pn" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pn ? this.state.stagingPre2018Form.pn : this.state.stagingPre2018Form.Pn}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="pNID"
                                            titleDialog="Path N of Tnm Stage"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pn"]} itemNbr="123"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pn"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="pM" field="pm" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pm ? this.state.stagingPre2018Form.pm : this.state.stagingPre2018Form.Pm}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="pMID"
                                            titleDialog="Path M of Tnm Stage"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pm"]} itemNbr="158"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pm"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="p Ajcc Grp" field="pAjccGrp" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.PAjccGrp ? this.state.stagingPre2018Form.PAjccGrp : this.state.stagingPre2018Form.pAjccGrp}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="GID"
                                            titleDialog="Path Ajcc Stage Group"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pAjccGrp"]} itemNbr="124"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pAjccGrp"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="p Staged By" field="pStagedBy" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pStagedBy ? this.state.stagingPre2018Form.pStagedBy : this.state.stagingPre2018Form.PStagedBy}
                                            dataSource="Global_Dir_Mv" aliasNo="327" titleDialog="P Staged By"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pStagedBy"]} itemNbr="327"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pStagedBy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="p Stg Descriptor" field="pStgDescriptor" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pStgDescriptor ? this.state.stagingPre2018Form.pStgDescriptor : this.state.stagingPre2018Form.PStgDescriptor}
                                            dataSource="Global_Dir_Mv" aliasNo="805" titleDialog="P Stg Descriptor"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pStgDescriptor"]} itemNbr="805"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pStgDescriptor"] : ""}
                                        />

                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Size Path" field="tumorSizePathologic" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.tumorSizePathologic}
                                            labelWidth={Global.LabelWidth} width="70px" itemNbr="4071"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSizePathologic"] : ""} />
                                        <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="User Defined Text" field="ajccStagingText"
                                            value={this.state.stagingPre2018Form.ajccStagingText}
                                            labelWidth="auto" width="200px" height="80px" itemNbr="3975"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccStagingText"] : ""}
                                            hasDoubleClickEvent="warning" maxlen="1000"
                                        />

                                    </div>
                                </div>
                                <div className="col-md-2 stagCol2">
                                    <div className="frameInner A5CWLayout">
                                        <div className="header">&nbsp;</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="cT" field="ct" lineStyle="oneLine onTopFrame"
                                            value={this.state.stagingPre2018Form.ct ? this.state.stagingPre2018Form.ct : this.state.stagingPre2018Form.Ct}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="cTID"
                                            titleDialog="Clinical T of Tnm"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["ct"]} itemNbr="368"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ct"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="cN" field="cn" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.cn ? this.state.stagingPre2018Form.cn : this.state.stagingPre2018Form.Cn}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="cNID"
                                            titleDialog="Clinical N of Tnm"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["cn"]} itemNbr="369"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cn"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="cM" field="cm" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.cm ? this.state.stagingPre2018Form.cm : this.state.stagingPre2018Form.Cm}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="cMID"
                                            titleDialog="Clinical M of Tnm"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["cm"]} itemNbr="370"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cm"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="c Ajcc Grp" field="cAjccGrp" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.cAjccGrp ? this.state.stagingPre2018Form.cAjccGrp : this.state.stagingPre2018Form.CAjccGrp}
                                            dataSource="TNMLOOKUPVIEW" aliasNo={this.props.selectedMedicalRecord.cid} flag="GID"
                                            titleDialog="Clinical Ajcc Stage Group"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["cAjccGrp"]} itemNbr="371"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cAjccGrp"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="c Staged By" field="cStagedBy" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.cStagedBy ? this.state.stagingPre2018Form.cStagedBy : this.state.stagingPre2018Form.CStagedBy}
                                            dataSource="Global_Dir_Mv" aliasNo="372" titleDialog="C Staged By"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["cStagedBy"]} itemNbr="372"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cStagedBy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="c Stg Descriptor" field="cStgDescriptor" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.cStgDescriptor ? this.state.stagingPre2018Form.cStgDescriptor : this.state.stagingPre2018Form.CStgDescriptor}
                                            dataSource="Global_Dir_Mv" aliasNo="806" titleDialog="C Stg Descriptor"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["cStgDescriptor"]} itemNbr="806"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cStgDescriptor"] : ""}
                                        />

                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Size Clin" field="tumorSizeClinical" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.tumorSizeClinical}
                                            labelWidth={Global.LabelWidth} width="70px" itemNbr="4070"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSizeClinical"] : ""} />
                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Size Summ" field="tumorSizeSummary" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.tumorSizeSummary}
                                            labelWidth={Global.LabelWidth} width="70px" itemNbr="4072"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSizeSummary"] : ""} />

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Ped Stg System" field="pedStgSystem" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pedStgSystem ? this.state.stagingPre2018Form.pedStgSystem : this.state.stagingPre2018Form.PedStgSystem}
                                            dataSource="Global_Dir_Mv" aliasNo="791" titleDialog="Ped Stg System"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pedStgSystem"]} itemNbr="791"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pedStgSystem"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Ped Stg" field="pedStg" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.pedStg ? this.state.stagingPre2018Form.pedStg : this.state.stagingPre2018Form.PedStg}
                                            dataSource="Global_Dir_Mv" aliasNo="792" titleDialog="Ped Stg"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pedStg"]} itemNbr="792"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pedStg"] : ""}
                                        />

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Ped Staged By" field="pedStagedBy" lineStyle="oneLine"
                                            value={this.state.stagingPre2018Form.PedStagedBy ? this.state.stagingPre2018Form.PedStagedBy : this.state.stagingPre2018Form.pedStagedBy}
                                            dataSource="Global_Dir_Mv" aliasNo="790" titleDialog="Ped Staged By"
                                            labelWidth={Global.LabelWidth} width="50px"
                                            inValid={this.state.sAllItemFields["pedStagedBy"]} itemNbr="790"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pedStagedBy"] : ""}
                                        />
                                        <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Staging Text" field="stageText"
                                            value={this.state.stagingPre2018Form.stageText ? this.state.stagingPre2018Form.stageText : this.state.stagingPre2018Form.StageText}
                                            labelWidth="auto" width="200px" height="80px" rows="2" itemNbr="824"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stageText"] : ""}
                                            hasDoubleClickEvent="warning" maxlen="1000"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 extendDiv stagCol3">
                                    <div className="frameInner A5CWLayout">
                                        <div className="header">&nbsp;</div>
                                        <fieldset>
                                            <legend>Size/Extension</legend>
                                            <div style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Cs Tumor Size" field="csTumorSize" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.csTumorSize ? this.state.stagingPre2018Form.csTumorSize : this.state.stagingPre2018Form.CsTumorSize}
                                                    dataSource="CstageLookupView" aliasNo="1"
                                                    flag={this.state.fnSchemaNbr}
                                                    titleDialog="Cs Tumor Size"
                                                    labelWidth={Global.LabelWidth} width="70px"
                                                    inValid={this.state.sAllItemFields["csTumorSize"]} itemNbr="3000"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csTumorSize"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Extn" field="extn" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.Extn ? this.state.stagingPre2018Form.Extn : this.state.stagingPre2018Form.extn}
                                                    dataSource="CstageLookupView" aliasNo="2"
                                                    flag={this.state.fnSchemaNbr} titleDialog="Extn"
                                                    labelWidth="30px" width="55px" labelStyle="center"
                                                    inValid={this.state.sAllItemFields["extn"]} itemNbr="135"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["extn"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Eval" field="csSizeExtnEval" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSizeExtnEval ? this.state.stagingPre2018Form.CsSizeExtnEval : this.state.stagingPre2018Form.csSizeExtnEval}
                                                    dataSource="CstageLookupView" aliasNo="3"
                                                    flag={this.state.fnSchemaNbr}
                                                    titleDialog="CS Tumor Size Extension Eval"
                                                    labelWidth="30px" width="35px" labelStyle="center"
                                                    inValid={this.state.sAllItemFields["csSizeExtnEval"]} itemNbr="3001"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSizeExtnEval"] : ""}
                                                />
                                            </div>
                                        </fieldset>
                                        <div>
                                            <div style={{ display: "flex" }}>
                                                <fieldset>
                                                    <legend>Lymph Nodes</legend>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Nodes" field="lymphNd" lineStyle="oneLine"
                                                        value={this.state.stagingPre2018Form.lymphNd ? this.state.stagingPre2018Form.lymphNd : this.state.stagingPre2018Form.LymphNd}
                                                        dataSource="CstageLookupView" aliasNo="4"
                                                        flag={this.state.fnSchemaNbr}
                                                        titleDialog="Lymph Nd"//"Lymph Node Involvement"
                                                        labelWidth={Global.LabelWidth} width="40px"
                                                        inValid={this.state.sAllItemFields["lymphNd"]} itemNbr="136"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lymphNd"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Eval" field="csNodesEval" lineStyle="oneLine"
                                                        value={this.state.stagingPre2018Form.csNodesEval ? this.state.stagingPre2018Form.csNodesEval : this.state.stagingPre2018Form.CsNodesEval}
                                                        dataSource="CstageLookupView" aliasNo="5"
                                                        flag={this.state.fnSchemaNbr}
                                                        titleDialog="Cs Nodes Eval"
                                                        labelWidth={Global.LabelWidth} width="40px"
                                                        inValid={this.state.sAllItemFields["csNodesEval"]} itemNbr="3002"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csNodesEval"] : ""}
                                                    />
                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} lineStyle="oneLine"
                                                        labelText="Positive" field="ndPos" value={this.state.stagingPre2018Form.ndPos}
                                                        labelWidth={Global.LabelWidth} width="60px" itemNbr="155"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ndPos"] : ""} />
                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} lineStyle="oneLine"
                                                        labelText="Examined" field="ndXam" value={this.state.stagingPre2018Form.ndXam}
                                                        labelWidth={Global.LabelWidth} width="60px" itemNbr="156"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ndXam"] : ""} />

                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Lymph Vascular" field="lymphVascularInv" lineStyle="oneLine"
                                                        value={this.state.stagingPre2018Form.lymphVascularInv ? this.state.stagingPre2018Form.lymphVascularInv : this.state.stagingPre2018Form.LymphVascularInv}
                                                        dataSource="Global_Dir_Mv" titleDialog="Lymph Vascular Inv" aliasNo="3159"
                                                        labelWidth={Global.LabelWidth} width="40px"
                                                        inValid={this.state.sAllItemFields["lymphVascularInv"]} itemNbr="3159"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lymphVascularInv"] : "1"}
                                                    />
                                                </fieldset>
                                                <fieldset style={{ height: "155px" }}>
                                                    <legend>Mets</legend>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="At Dx" field="csMetsDx" lineStyle="oneLine"
                                                            value={this.state.stagingPre2018Form.CsMetsDx ? this.state.stagingPre2018Form.CsMetsDx : this.state.stagingPre2018Form.csMetsDx}
                                                            dataSource="CstageLookupView" aliasNo="8"
                                                            flag={this.state.fnSchemaNbr}
                                                            titleDialog="Cs Mets Dx"
                                                            labelWidth="55px" width="35px"
                                                            inValid={this.state.sAllItemFields["csMetsDx"]} itemNbr="3003"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDx"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Eval" field="csMetsEval" lineStyle="oneLine"
                                                            value={this.state.stagingPre2018Form.CsMetsEval ? this.state.stagingPre2018Form.CsMetsEval : this.state.stagingPre2018Form.csMetsEval}
                                                            dataSource="CstageLookupView" aliasNo="9"
                                                            flag={this.state.fnSchemaNbr}
                                                            titleDialog="Cs Mets Eval" labelStyle="center"
                                                            labelWidth="40px" width="25px"
                                                            inValid={this.state.sAllItemFields["csMetsEval"]} itemNbr="3004"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsEval"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Bone" field="csMetsDxBone" lineStyle="oneLine"
                                                            labelWidth="55px" width="35px"
                                                            value={this.state.stagingPre2018Form.CsMetsDxBone ? this.state.stagingPre2018Form.CsMetsDxBone : this.state.stagingPre2018Form.csMetsDxBone}
                                                            dataSource="Global_Dir_Mv" titleDialog="Cs Mets Dx Bone" aliasNo="3161"
                                                            inValid={this.state.sAllItemFields["csMetsDxBone"]} itemNbr="3161"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxBone"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Brain" field="csMetsDxBrain" lineStyle="oneLine"
                                                            labelWidth="40px" width="30px"
                                                            value={this.state.stagingPre2018Form.CsMetsDxBrain ? this.state.stagingPre2018Form.CsMetsDxBrain : this.state.stagingPre2018Form.csMetsDxBrain}
                                                            dataSource="Global_Dir_Mv" titleDialog="Cs Mets Dx Brain" aliasNo="3162" labelStyle="center"
                                                            inValid={this.state.sAllItemFields["csMetsDxBrain"]} itemNbr="3162"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxBrain"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Liver" field="csMetsDxLiver" lineStyle="oneLine"
                                                            labelWidth="55px" width="35px"
                                                            value={this.state.stagingPre2018Form.CsMetsDxLiver ? this.state.stagingPre2018Form.CsMetsDxLiver : this.state.stagingPre2018Form.csMetsDxLiver}
                                                            dataSource="Global_Dir_Mv" titleDialog="Cs Mets Dx Liver" aliasNo="3163"
                                                            inValid={this.state.sAllItemFields["csMetsDxLiver"]} itemNbr="3163"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxLiver"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Lung" field="csMetsDxLung" lineStyle="oneLine"
                                                            labelWidth="40px" width="30px"
                                                            value={this.state.stagingPre2018Form.CsMetsDxLung ? this.state.stagingPre2018Form.CsMetsDxLung : this.state.stagingPre2018Form.csMetsDxLung}
                                                            dataSource="Global_Dir_Mv" titleDialog="Cs Mets Dx Lung" aliasNo="3164" labelStyle="center"
                                                            inValid={this.state.sAllItemFields["csMetsDxLung"]} itemNbr="3164"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxLung"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Dist Ln" field="metsDxDistantLn" lineStyle="oneLine"
                                                            labelWidth="55px" width="35px"
                                                            value={this.state.stagingPre2018Form.MetsDxDistantLn ? this.state.stagingPre2018Form.MetsDxDistantLn : this.state.stagingPre2018Form.metsDxDistantLn}
                                                            dataSource="Global_Dir_Mv" titleDialog="Mets Dx Distant Ln" aliasNo="4073"
                                                            inValid={this.state.sAllItemFields["metsDxDistantLn"]} itemNbr="4073"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["metsDxDistantLn"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Other" field="metsDxOther" lineStyle="oneLine"
                                                            labelWidth="40px" width="30px"
                                                            value={this.state.stagingPre2018Form.MetsDxOther ? this.state.stagingPre2018Form.MetsDxOther : this.state.stagingPre2018Form.metsDxOther}
                                                            dataSource="Global_Dir_Mv" titleDialog="Mets Dx Other" aliasNo="4074" labelStyle="center"
                                                            inValid={this.state.sAllItemFields["metsDxOther"]} itemNbr="4074"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["metsDxOther"] : ""}
                                                        />
                                                    </div>

                                                    <div style={{ display: this.state.stagingPre2018Form.fnEnablessf !== "1" ? "flex" : "none", position: "relative", top: "12px" }} className="enableAllSSF">
                                                        <Input type="checkbox" clicked={this.fnEnableAllSsf}
                                                            labelText="Enable All SSF" lineStyle="oneLine"
                                                            field="fnEnablessf" ignoreRapid
                                                            value={this.state.stagingPre2018Form.fnEnablessf}
                                                            labelWidth="45%" width="50px" />
                                                    </div>

                                                </fieldset>
                                            </div>
                                        </div>

                                        <fieldset className="siteSpecificFactors">
                                            <legend>Site-Specific Factors</legend>
                                            <div style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="1" field="csSsf1" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf1 ? this.state.stagingPre2018Form.CsSsf1 : this.state.stagingPre2018Form.csSsf1}
                                                    dataSource="CstageLookupView" aliasNo="10" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Size of Lymph Nodes"
                                                    labelStyle="center" keyId="CS_SSF_1"
                                                    inValid={this.state.sAllItemFields["csSsf1"]} itemNbr="3005"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf1"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />

                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="6" field="csSsf6" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf6 ? this.state.stagingPre2018Form.CsSsf6 : this.state.stagingPre2018Form.csSsf6}
                                                    dataSource="CstageLookupView" aliasNo="15" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Parapharyngeal, Parotid, and Suboccipital/Retroauricular Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_6"
                                                    inValid={this.state.sAllItemFields["csSsf6"]} itemNbr="3010"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf6"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="11" field="csSsf11" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf11 ? this.state.stagingPre2018Form.CsSsf11 : this.state.stagingPre2018Form.csSsf11}
                                                    dataSource="CstageLookupView" aliasNo="20" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Measured Thickness (Depth)"
                                                    labelStyle="center" keyId="CS_SSF_11"
                                                    inValid={this.state.sAllItemFields["csSsf11"]} itemNbr="3180"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf11"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="16" field="csSsf16" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf16 ? this.state.stagingPre2018Form.CsSsf16 : this.state.stagingPre2018Form.csSsf16}
                                                    dataSource="CstageLookupView" aliasNo="25" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="" 
                                                    labelStyle="center" keyId="CS_SSF_16"
                                                    inValid={this.state.sAllItemFields["csSsf16"]} itemNbr="3185"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf16"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="21" field="csSsf21" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf21 ? this.state.stagingPre2018Form.CsSsf21 : this.state.stagingPre2018Form.csSsf21}
                                                    dataSource="CstageLookupView" aliasNo="30" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_21"
                                                    inValid={this.state.sAllItemFields["csSsf21"]} itemNbr="3190"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf21"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="2" field="csSsf2" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf2 ? this.state.stagingPre2018Form.CsSsf2 : this.state.stagingPre2018Form.csSsf2}
                                                    dataSource="CstageLookupView" aliasNo="11" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="OBSOLETE - Extracapsular Extension, Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_2"
                                                    inValid={this.state.sAllItemFields["csSsf2"]} itemNbr="3006"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf2"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="7" field="csSsf7" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf7 ? this.state.stagingPre2018Form.CsSsf7 : this.state.stagingPre2018Form.csSsf7}
                                                    dataSource="CstageLookupView" aliasNo="16" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Upper and Lower Cervical Node Levels"
                                                    labelStyle="center" keyId="CS_SSF_7"
                                                    inValid={this.state.sAllItemFields["csSsf7"]} itemNbr="3176"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf7"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="12" field="csSsf12" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf12 ? this.state.stagingPre2018Form.CsSsf12 : this.state.stagingPre2018Form.csSsf12}
                                                    dataSource="CstageLookupView" aliasNo="21" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_12"
                                                    inValid={this.state.sAllItemFields["csSsf12"]} itemNbr="3181"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf12"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="17" field="csSsf17" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf17 ? this.state.stagingPre2018Form.CsSsf17 : this.state.stagingPre2018Form.csSsf17}
                                                    dataSource="CstageLookupView" aliasNo="26" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="" 
                                                    labelStyle="center" keyId="CS_SSF_17"
                                                    inValid={this.state.sAllItemFields["csSsf17"]} itemNbr="3186"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf17"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="22" field="csSsf22" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf22 ? this.state.stagingPre2018Form.CsSsf22 : this.state.stagingPre2018Form.csSsf22}
                                                    dataSource="CstageLookupView" aliasNo="31" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_22"
                                                    inValid={this.state.sAllItemFields["csSsf22"]} itemNbr="3191"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf22"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="3" field="csSsf3" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf3 ? this.state.stagingPre2018Form.CsSsf3 : this.state.stagingPre2018Form.csSsf3}
                                                    dataSource="CstageLookupView" aliasNo="12" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Levels I-III, Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_3"
                                                    inValid={this.state.sAllItemFields["csSsf3"]} itemNbr="3007"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf3"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="8" field="csSsf8" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf8 ? this.state.stagingPre2018Form.CsSsf8 : this.state.stagingPre2018Form.csSsf8}
                                                    dataSource="CstageLookupView" aliasNo="17" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Extracapsular Extension Clinically, Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_8"
                                                    inValid={this.state.sAllItemFields["csSsf8"]} itemNbr="3177"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf8"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="13" field="csSsf13" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf13 ? this.state.stagingPre2018Form.CsSsf13 : this.state.stagingPre2018Form.csSsf13}
                                                    dataSource="CstageLookupView" aliasNo="22" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="" 
                                                    labelStyle="center" keyId="CS_SSF_13"
                                                    inValid={this.state.sAllItemFields["csSsf13"]} itemNbr="3182"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf13"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="18" field="csSsf18" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf18 ? this.state.stagingPre2018Form.CsSsf18 : this.state.stagingPre2018Form.csSsf18}
                                                    dataSource="CstageLookupView" aliasNo="27" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_18"
                                                    inValid={this.state.sAllItemFields["csSsf18"]} itemNbr="3187"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf18"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="23" field="csSsf23" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf23 ? this.state.stagingPre2018Form.CsSsf23 : this.state.stagingPre2018Form.csSsf23}
                                                    dataSource="CstageLookupView" aliasNo="32" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="" 
                                                    labelStyle="center" keyId="CS_SSF_23"
                                                    inValid={this.state.sAllItemFields["csSsf23"]} itemNbr="3192"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf23"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="4" field="csSsf4" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf4 ? this.state.stagingPre2018Form.CsSsf4 : this.state.stagingPre2018Form.csSsf4}
                                                    dataSource="CstageLookupView" aliasNo="13" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Levels IV-V and Retropharyngeal Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_4"
                                                    inValid={this.state.sAllItemFields["csSsf4"]} itemNbr="3008"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf4"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="9" field="csSsf9" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf9 ? this.state.stagingPre2018Form.CsSsf9 : this.state.stagingPre2018Form.csSsf9}
                                                    dataSource="CstageLookupView" aliasNo="18" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Extracapsular Extension Pathologically, Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_9"
                                                    inValid={this.state.sAllItemFields["csSsf9"]} itemNbr="3178"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf9"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="14" field="csSsf14" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf14 ? this.state.stagingPre2018Form.CsSsf14 : this.state.stagingPre2018Form.csSsf14}
                                                    dataSource="CstageLookupView" aliasNo="23" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_14"
                                                    inValid={this.state.sAllItemFields["csSsf14"]} itemNbr="3183"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf14"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="19" field="csSsf19" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf19 ? this.state.stagingPre2018Form.CsSsf19 : this.state.stagingPre2018Form.csSsf19}
                                                    dataSource="CstageLookupView" aliasNo="28" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_19"
                                                    inValid={this.state.sAllItemFields["csSsf19"]} itemNbr="3188"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf19"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="24" field="csSsf24" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf24 ? this.state.stagingPre2018Form.CsSsf24 : this.state.stagingPre2018Form.csSsf24}
                                                    dataSource="CstageLookupView" aliasNo="33" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_24"
                                                    inValid={this.state.sAllItemFields["csSsf24"]} itemNbr="3193"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf24"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="5" field="csSsf5" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf5 ? this.state.stagingPre2018Form.CsSsf5 : this.state.stagingPre2018Form.csSsf5}
                                                    dataSource="CstageLookupView" aliasNo="14" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Levels VI-VII and Facial Lymph Nodes for Head and Neck"
                                                    labelStyle="center" keyId="CS_SSF_5"
                                                    inValid={this.state.sAllItemFields["csSsf5"]} itemNbr="3009"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf5"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="10" field="csSsf10" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf10 ? this.state.stagingPre2018Form.CsSsf10 : this.state.stagingPre2018Form.csSsf10}
                                                    dataSource="CstageLookupView" aliasNo="19" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="Human Papilloma Virus (HPV) Status"
                                                    labelStyle="center" keyId="CS_SSF_10"
                                                    inValid={this.state.sAllItemFields["csSsf10"]} itemNbr="3179"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf10"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="15" field="csSsf15" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf15 ? this.state.stagingPre2018Form.CsSsf15 : this.state.stagingPre2018Form.csSsf15}
                                                    dataSource="CstageLookupView" aliasNo="24" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog=""
                                                    labelStyle="center" keyId="CS_SSF_15"
                                                    inValid={this.state.sAllItemFields["csSsf15"]} itemNbr="3184"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf15"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="20" field="csSsf20" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf20 ? this.state.stagingPre2018Form.CsSsf20 : this.state.stagingPre2018Form.csSsf20}
                                                    dataSource="CstageLookupView" aliasNo="29" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="" 
                                                    labelStyle="center" keyId="CS_SSF_20"
                                                    inValid={this.state.sAllItemFields["csSsf20"]} itemNbr="3189"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf20"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="25" field="csSsf25" lineStyle="oneLine"
                                                    value={this.state.stagingPre2018Form.CsSsf25 ? this.state.stagingPre2018Form.CsSsf25 : this.state.stagingPre2018Form.csSsf25}
                                                    dataSource="CstageLookupView" aliasNo="34" stagingPre2018={true}
                                                    flag={this.state.fnSchemaNbr}
                                                    //titleDialog="" 
                                                    labelStyle="center" keyId="CS_SSF_25"
                                                    inValid={this.state.sAllItemFields["csSsf25"]} itemNbr="3194"
                                                    lostfocused={(e) => { this.handleLostFocus(e, this) }}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf25"] : ""}
                                                    labelWidth="20px" width="35px"
                                                />

                                                <div className="SchemaNbrDbOverScreenCls">
                                                    <Input type="input" //focused={this.handleOnFocus}
                                                        changed={this.handleFormChange}
                                                        labelText="" ignoreRapid={true}
                                                        field="schemaNbrDb" value={this.state.stagingPre2018Form.schemaNbrDb}
                                                        //keyId="schemaNbrDb"
                                                        lostfocused={(e) => { this.handleLostFocus(e, this) }}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="col-md-4 stagCol4">
                                    <div className="frameInner A5CWLayout">
                                        <div className="header">&nbsp;</div>
                                        <div id="showDataInfos" style={{ /*height: "342px"*/ }}>
                                            <fieldset id="Derived_Collaborative_Stage_Data" className={this.state.derivedCollaborativeStageError === null || this.state.derivedCollaborativeStageError === "" ? "" : "hidden"}>
                                                <legend>Derived Collaborative Stage</legend>
                                                <div>
                                                    <fieldset className="aJCC6thEdition">
                                                        <legend>AJCC - 6th Edition</legend>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="label" labelText="" field="csTDescriptor" value={this.state.stagingPre2018Form.csTDescriptor} labelWidth="0px" width="15px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="csTDisplay" value={this.state.stagingPre2018Form.csTDisplay} labelWidth="0px" width="65px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="csNDescriptor" value={this.state.stagingPre2018Form.csNDescriptor} labelWidth="0px" width="15px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="csNDisplay" value={this.state.stagingPre2018Form.csNDisplay} labelWidth="0px" width="65px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="csMDescriptor" value={this.state.stagingPre2018Form.csMDescriptor} labelWidth="0px" width="15px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="csMDisplay" value={this.state.stagingPre2018Form.csMDisplay} labelWidth="0px" width="65px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="Grp" field="csStageGrpDisplay" value={this.state.stagingPre2018Form.csStageGrpDisplay} labelWidth="30px" width="55px" lineStyle="oneLine" />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div>
                                                    <fieldset className="aJCC7thEdition">
                                                        <legend>AJCC - 7th Edition</legend>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="label" labelText="" field="cs7tDescriptor" value={this.state.stagingPre2018Form.cs7tDescriptor} labelWidth="0px" width="15px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="cs7tDisplay" value={this.state.stagingPre2018Form.cs7tDisplay} labelWidth="0px" width="65px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="cs7nDescriptor" value={this.state.stagingPre2018Form.cs7nDescriptor} labelWidth="0px" width="15px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="cs7nDisplay" value={this.state.stagingPre2018Form.cs7nDisplay} labelWidth="0px" width="65px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="cs7mDescriptor" value={this.state.stagingPre2018Form.cs7mDescriptor} labelWidth="0px" width="15px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="" field="cs7mDisplay" value={this.state.stagingPre2018Form.cs7mDisplay} labelWidth="0px" width="65px" lineStyle="oneLine" />
                                                            <Input type="label" labelText="Grp" field="cs7stageGroupDisplay" value={this.state.stagingPre2018Form.cs7stageGroupDisplay} labelWidth="30px" width="55px" lineStyle="oneLine" />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div>
                                                    <fieldset>
                                                        <legend>Seer Summary 1977</legend>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="label" labelText="Summary Stage" field="csSs1977"
                                                                value={this.state.stagingPre2018Form.csSs1977}
                                                                labelWidth={Global.LabelWidth} width="70px" lineStyle="oneLine" itemNbr="" />
                                                        </div>
                                                        <div style={{ display: "flex" }} className="seerSummary1977">
                                                            <Input type="label" labelText="T" field="cs1977TDisplay"
                                                                value={this.state.stagingPre2018Form.cs1977TDisplay}
                                                                labelWidth="20px" width="55px" lineStyle="oneLine" itemNbr="" />
                                                            <Input type="label" labelText="N" field="cs1977NDisplay"
                                                                value={this.state.stagingPre2018Form.cs1977NDisplay}
                                                                labelWidth="20px" width="55px" lineStyle="oneLine"
                                                                labelStyle="center" itemNbr="" />
                                                            <Input type="label" labelText="M" field="cs1977MDisplay"
                                                                value={this.state.stagingPre2018Form.cs1977MDisplay}
                                                                labelWidth="20px" width="55px" lineStyle="oneLine"
                                                                labelStyle="center" itemNbr="" />
                                                            <Input type="label" labelText="Grp" field="cs1977StageDisplay"
                                                                value={this.state.stagingPre2018Form.cs1977StageDisplay}
                                                                labelWidth="30px" width="55px" lineStyle="oneLine"
                                                                labelStyle="center" itemNbr="" />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div>
                                                    <fieldset>
                                                        <legend>Seer Summary 2000</legend>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="label" labelText="Summary Stage" field="csSs2000"
                                                                value={this.state.stagingPre2018Form.csSs2000}
                                                                labelWidth={Global.LabelWidth} width="70px" lineStyle="oneLine" itemNbr="" />
                                                        </div>
                                                        <div style={{ display: "flex" }} className="seerSummary2000">
                                                            <Input type="label" labelText="T" field="cs2000TDisplay"
                                                                value={this.state.stagingPre2018Form.cs2000TDisplay}
                                                                labelWidth="20px" width="55px" lineStyle="oneLine" itemNbr="" />
                                                            <Input type="label" labelText="N" field="cs2000NDisplay"
                                                                value={this.state.stagingPre2018Form.cs2000NDisplay}
                                                                labelWidth="20px" width="55px" lineStyle="oneLine"
                                                                labelStyle="center" itemNbr="" />
                                                            <Input type="label" labelText="M" field="cs2000MDisplay"
                                                                value={this.state.stagingPre2018Form.cs2000MDisplay}
                                                                labelWidth="20px" width="55px" lineStyle="oneLine"
                                                                labelStyle="center" itemNbr="" />
                                                            <Input type="label" labelText="Grp" field="cs2000StageDisplay"
                                                                value={this.state.stagingPre2018Form.cs2000StageDisplay}
                                                                labelWidth="30px" width="55px" lineStyle="oneLine"
                                                                labelStyle="center" itemNbr="" />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                </div>
                                            </fieldset>
                                        </div>

                                        <fieldset id="Derived_Collaborative_Stage_Error" className={this.state.derivedCollaborativeStageError !== null && this.state.derivedCollaborativeStageError !== "" ? "" : "hidden"}>
                                            <legend>Derived Collaborative Stage</legend>
                                            <div id="showErrorInfos" style={{ width: "390px", height: "300px" }}>
                                                {
                                                    (this.state.derivedCollaborativeStageError !== null && this.state.derivedCollaborativeStageError !== "") ?
                                                        this.state.derivedCollaborativeStageError.split("\n").map(function (item, idx) {
                                                            return (
                                                                <span key={idx}>
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </fieldset>

                                        <div className="emptyLine"></div>

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText={this.state.stagingPre2018Form.stagingUser1Lbl ? this.state.stagingPre2018Form.stagingUser1Lbl : "Stg User 1"}
                                            field="stagingUser1" lineStyle="oneLine"
                                            labelWidth="auto" width="100px" labelStyle="padRightCls"
                                            value={this.state.stagingPre2018Form.StagingUser1 ? this.state.stagingPre2018Form.StagingUser1 : this.state.stagingPre2018Form.stagingUser1}
                                            dataSource="Global_Dir_Mv" titleDialog="Staging User 1" aliasNo="3976"
                                            inValid={this.state.sAllItemFields["stagingUser1"]} itemNbr="3976"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagingUser1"] : ""}
                                            flag="ERS_UserDefinedMaintenance"
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText={this.state.stagingPre2018Form.stagingUser2Lbl ? this.state.stagingPre2018Form.stagingUser2Lbl : "Stg User 2"}
                                            field="stagingUser2" lineStyle="oneLine"
                                            labelWidth="auto" width="100px" labelStyle="padRightCls"
                                            value={this.state.stagingPre2018Form.StagingUser2 ? this.state.stagingPre2018Form.StagingUser2 : this.state.stagingPre2018Form.stagingUser2}
                                            dataSource="Global_Dir_Mv" titleDialog="Staging User 2" aliasNo="3977"
                                            inValid={this.state.sAllItemFields["stagingUser2"]} itemNbr="3977"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagingUser2"] : ""}
                                            flag="ERS_UserDefinedMaintenance"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetStaging} formId="stagingPre2018Form"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Staging data loading...</p>;

        /*
        let loading = (
            this.state.processingFlag ? <div className="loader">Please wait...</div> : ""
        );
        */

        return (
            <React.Fragment>
                {stagingMaintenance}

                <React.Fragment>
                    {/*loading*/}
                </React.Fragment>

                <div id="textareaEditorForItemsCls" className={this.props.selectedPrimarySummary.diagnosisDateYear < 2018 ? "stgPre2018Maint" : "hidden"}>
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>
                                */
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

StagingPre2018Maintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedStagingRecord: state.patientAbstract.selectedStagingRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveStaging: (stagingData) => dispatch(actionCreators.saveStaging(stagingData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag, cid) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag, cid)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StagingPre2018Maintenance);