/* eslint-disable */
import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Help from "../../../UI/Help";
import Draggable from 'react-draggable';
import * as actions from '../../../../store/actions/index';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";

class GlobalChange extends Component {

    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "populationLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        changed: false,

        // globalChangeMv
        globalItemNumber: null,
        itemDescription: null,
        globalChangeMv: [
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
            {changeFrom: "",changeTo: ""},
        ],
        globalChangeMvLoading: false,
        selectedGlobalChangeMvRowIndex: 0,

        labelStructureList: null,
        labelMaxlengthInput: null,
        globalDirStructureList: null,
        globalDirMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowResultsMsg: false,
        showClockTimer: false,
        titleDialog: null,

        isShowConfirmChangeMsg: false,
        confirmed: false,

        reportStatus: null,
        showMessageError: null,
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);
        
        // Add all tabs in maintDialogs.js
        dialogTypes.dataManagmentTabDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        this.setState({ isLoading: true });

        if (!this.state.dialogForm && this.props.selectedReportLabelsInfo) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        // Loading form data...
        this.props.selectReportLabels();
    }


    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                },
                errorMessage: this.props.errorMessage,
            });

            this.props.clearErrorMessage();
            return false;
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "populationLabel"]: this.props.commonLookupItem.name },
                });
            } else {
                /*
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
                */
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedReportLabelsInfo)
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.reportLabelId !== this.props.selectedReportLabelsInfo.reportLabelId || prevProps.selectedReportLabelsInfo.reportLabel !== this.props.selectedReportLabelsInfo.reportLabel))
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.startTime !== this.props.selectedReportLabelsInfo.startTime || prevProps.selectedReportLabelsInfo.endTime !== this.props.selectedReportLabelsInfo.endTime))
            || (this.state.reload && this.props.selectedReportLabelsInfo)
        ) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        if (this.state.labelStructureList === null) {
            this.getLabelTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.labelStructureList && this.state.labelStructureList.length > 0 && this.state.labelMaxlengthInput) {
                let maxLengthValue = this.state.labelStructureList;
                let maxLengthItem = this.state.labelMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    labelMaxlengthInput: {
                        ...this.state.labelMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.globalDirStructureList === null) {
            this.getGlobalTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.globalDirStructureList && this.state.globalDirStructureList.length > 0 && this.state.globalDirMaxlengthInput) {
                let maxLengthValue = this.state.globalDirStructureList;
                let maxLengthItem = this.state.globalDirMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    globalDirMaxlengthInput: {
                        ...this.state.globalDirMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        //catch returned result from API that set in PatientAbstract store -> case actionTypes.runGlobalChangeSuccess:
        if (!this.state.runGlobalChangeIsCompleted && this.state.showClockTimer && !this.props.isDialogLoading && this.props.globalChangeInfo) {
            clearInterval(this.sInVal);
            const reportStatus = this.props.globalChangeInfo["reportStatus"];

            this.setState({ 
                runGlobalChangeIsCompleted: true,
                showClockTimer: false,
                isShowResultsMsg: true,
                reportStatus: reportStatus > 0 ? "Success - "+reportStatus+" Rows Affected" : "Success - No Cases Changed",
            });
            //this.newRecord();
        }
    }

    setFormData = (selectedReportLabelsInfo) => {
        this.setState({
            dialogForm: {
                ...selectedReportLabelsInfo,
            },
            isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,

            showClockTimer: false,
            titleDialog: null,
        });

        if ((!this.state.uAllItemFields && selectedReportLabelsInfo)) {
            let fields = {};
            Object.keys(selectedReportLabelsInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };
    }

    async getLabelTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Report_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            labelStructureList: res && res.data ? res.data.result : null
        });

        return false;
    }

    async getGlobalTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Global_Dir", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            globalDirStructureList: res && res.data ? res.data.result : null
        });

        return false;
    }

    handlePopLabelChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    handleGlobalItemChange = (event) => {
        const itemNbr = event.target.value
        //const itemDesc = event.target2.value



        if (itemNbr !== this.state.globalItemNumber) {
            this.setState({
                globalItemNumber: itemNbr,
                //itemDescription: itemDesc,
                isFormDirty: true
            })
        }
        return false;
    }

    handleGlobalChangeMvGridChange = (event, field, index) => {
        const codeValue = event.target.value;
        //const field = field
        const globalItems = [...this.state.globalChangeMv];
        globalItems[index][field] = codeValue
        this.setState({
            globalChangeMv: globalItems
        })
        return false;
    }

    handleGlobalChangeMvLostfocus = (event, field, index) => {
        if (event.target.value) {
            this.handleGlobalChangeMvGridChange(event, field, index);
        }
        return false;
    }

    handleLostFocus = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name == "globalItemNumber") {
            if (value && value != "") {
                let isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    let _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		        //update new accessToken again
		        var newAccessToken = res && res.data ? res.data.accessToken : null;
		        if (newAccessToken) {
		            localStorage.setItem('token', newAccessToken);
		        }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                try {
                                    _this.setState(prevState => {
                                        let newDesc = prevState.itemDescription;
                                        newDesc = globalDir.itemDesc;
                                        return { itemDescription: newDesc };
                                    });
                                } catch (ex) { }
                            } else {
                                //clear
                                try {
                                    _this.setState(prevState => {
                                        let newDesc = prevState.itemDescription;
                                        newDesc = "";
                                        return { itemDescription: newDesc };
                                    });
                                } catch (ex) { }
                            }
                            return false;
                        })
	            .catch(function (error) {
	                console.log(error);

	                var btnLogout = document.getElementById("logoutButtonArea");
	                if (btnLogout) {
	                    btnLogout.querySelector("a").click();
	                }
	                return false;
	            });
                    return false;
                }
            }
        }
        // this.setState({
        //     uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }
        // });
        return false;
    }

    handleOnFocus = (event, index, alias) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    addNewGlobalChangeMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.globalChangeMv];
            const firstItems = newItems[0];

            const newItmes = {};
            Object.keys(firstItems).map((t, k) => {
                newItmes[t] = "";
            });
            newItems.push(newItmes);

            return { globalChangeMv: newItems};
        });
    }

    deleteSelectedGlobalChangeMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedGlobalChangeMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.globalChangeMv.length) {
            selectedRow = this.state.globalChangeMv.length - 1;
        }

        if (this.state.globalChangeMv[selectedRow] && this.state.globalChangeMv[selectedRow]["id"] !== undefined && this.state.globalChangeMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true});
        } else {
            this.deleteGlobalChangeMvGrid("0");
        }
        return false;
    }

    deleteGlobalChangeMvGrid = (flg) => {
        let selectedRow = this.state.selectedGlobalChangeMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.globalChangeMv.length) {
            selectedRow = this.state.globalChangeMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.globalChangeMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.globalChangeMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "globalChangeId" || t === "globalChangeMvId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }
            return { globalChangeMv: newItems, selectedGlobalChangeMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    setSelectionGlobalChangeMvRow = (event, index) => {
        this.setState({ selectedGlobalChangeMvRowIndex: index });
        return false;
    }

    confirmChange = (e) => {
        try {e.preventDefault();} catch (ex) { };

        this.setState({
            confirm: false,
            isShowConfirmChangeMsg: true,
        })
        return false;
    }

    callRunGlobalChangeFnc = (e) => {
        try {e.preventDefault();} catch (ex) { };

        const _this = this;
        setTimeout(function () {
            if (_this.state.dialogForm.populationLabel == "") {
                return false;
            }
            let foundData = false
            _this.state.globalChangeMv.map((mv, index) => {
                if (mv.itemNbr != "" && mv.itemDesc != "") {
                    foundData = true;
                }
            });
            if (!foundData) return false;

            _this.callRunGlobalChange();
            _this.setState({showClockTimer: true, isShowConfirmChangeMsg: false, runGlobalChangeIsCompleted: false, startTime: new Date().toLocaleTimeString() });
            return false;
        },0 );

        this.sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.sInVal);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false;
    }
    
    callRunGlobalChange = () => {
        let formData = {
            populationLabel: null,
            itemNumber: null,
            itemDescription: null,
            changeValues: null,
            userId: null,
            startTime: null,
        }
        const dialogForm = this.state.dialogForm;

        formData.populationLabel = dialogForm.populationLabel;
        formData.itemNumber = this.state.globalItemNumber;
        formData.itemDescription = this.state.itemDescription;
        formData.userId = this.props.userId;

        let globalChangeMvReduced = this.state.globalChangeMv.filter(e => (e.changeFrom != "" || e.changeTo != ""));
        formData.changeValues = globalChangeMvReduced.map(e => e.changeFrom + "|" + e.changeTo).join("^");

        this.props.runGlobalChange(formData);
        return false;
    }

    cancelReport = (e, _this) => {
        e.preventDefault();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    closeCodeDialog = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };

        this.setState({ isHelpVisible: false });
        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_SELECT_POPULATION") ? document.querySelector("#BUTTON_SELECT_POPULATION").focus() : "";
        }, 0);

        return false;
    }

    hideResultsMsg = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };

        this.setState({ isShowResultsMsg: false });
        this.props.setChildrenOpening(false);
        return false;
    }

    hideConfirmChangeMsg = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { };

        if (event.target.value === "yes") {
            this.setState({confirmed: true, showClockTimer: true, isShowConfirmChangeMsg: false});
        }
        else
        {
            this.setState({confirmed: false, isShowConfirmChangeMsg: false});
        }
        return false;
    }

    newRecord = () => {
        this.setState({
            dialogForm: "",
            globalItemNumber: null,
            itemDescription: null,
            globalChangeMv: [
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
                {changeFrom: "",changeTo: ""},
            ],
            isFormDirty: false
        })
        return false
    }

    render () {

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.props.errorMessage ? this.props.errorMessage : this.state.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmChangeMsg;
        {
            confirmChangeMsg = this.state.isShowConfirmChangeMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Global Change</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmChangeMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Please confirm that you would like to proceed with this</label>
                                <br/>
                                <label>Global Change and that you have a backup of your data.</label>
                                <br/>
                                <br/>
                                <label>Click "Ok" to continue, otherwise click "Cancel"</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.callRunGlobalChangeFnc(e) }} value="yes" autoFocus>Ok</button>
                                <button onClick={(e) => { this.hideConfirmChangeMsg(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.cancelReport(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let resultsMsg;
        {
            resultsMsg = this.state.isShowResultsMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Global Change Results</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideResultsMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    {this.state.reportStatus}
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideResultsMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded && this.props.initialPageVisible ?
            (
                <div className="container-fluid2">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form id="GlobalChangeForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>

                        <div className="row">
                            <div className="col-md-7">
                                <div className="_frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset style={{ padding: "0", border: "none", borderBottom: "1px solid #ccc" }}>
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                    changed={this.handlePopLabelChange} width="430px"
                                                    labelText="Population Label"
                                                    field="populationLabel" lineStyle="oneLine"
                                                    value={this.state.dialogForm.populationLabel}
                                                    dataSource="Labels"
                                                    titleDialog="Population Lookup" labelWidth="110px"
                                                    inValid={this.state.uAllItemFields["populationLabel"]}
                                                    maxLength={this.state.labelMaxlengthInput ? this.state.labelMaxlengthInput["populationLabel"] : ""}
                                                    lostfocused={this.handleLostfocus} autoFocus
                                                />
                                                <div className="emptyLine"></div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="emptyLine"></div>

                                    <legend></legend>
                                    <div className="row" style={{ }}>
                                        <div className="col-sm-10" style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                changed={this.handleGlobalItemChange}
                                                labelText="Item Number" field="globalItemNumber"
                                                value={this.state.globalItemNumber}
                                                dataSource="Global_Dir" titleDialog="Item Numbers"
                                                width="80px" labelWidth="80px" 
                                                inValid={this.state.uAllItemFields["globalItemNumber"]}
                                                maxLength={this.state.globalDirMaxlengthInput ? this.state.globalDirMaxlengthInput["globalItemNumber"] : ""}
                                                lostfocused={this.handleLostFocus} focusingField={this.state.focusingField}
                                            />
                                            <Input type="label" focused={this.handleOnFocus}
                                                labelText="" field="itemDescription"
                                                value={this.state.itemDescription}
                                                titleDialog="Item Descriptions"
                                                width="350px" labelWidth="10px"
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-10">
                                            <div style={{ display: "flex" }}>
                                                <div className="_frameInner" style={{ width: "450px" }}>
                                                    {/* <div className="header">Selection Criteria</div> */}
                                                    <div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "18px" }}>&nbsp;</th>
                                                                    <th style={{ width: "120px" }}>Change From</th>
                                                                    <th style={{ width: "120px" }}>Change To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody></tbody>
                                                        </table>
                                                    </div>
                                                    <div id="globalChangeMvListArea" className="globalChangeMvListArea">
                                                        <div className="globalChangeMvChildrenArea innerBody" style={{ height: "400px", overflow: "auto" }}>
                                                            <table>
                                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "120px" }}>Change From</th>
                                                                        <th style={{ width: "120px" }}>Change To</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.dialogForm ? 
                                                                        this.state.globalChangeMv.map((mv, index) => (
                                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionGlobalChangeMvRow(e, index) }} className={this.state.selectedGlobalChangeMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                <td style={{ width: "18px" }}>
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedGlobalChangeMvRowIndex") }}
                                                                                        changed={(e) => { this.handleGlobalChangeMvGridChange(e, "changeFrom" ,index) }} 
                                                                                        labelText="" field="changeFrom"//{this.state.itemDescription}
                                                                                        value={mv.changeFrom} lineStyle="oneLine"
                                                                                        dataSource="Global_Dir_Mv" titleDialog={this.state.itemDescription}
                                                                                        aliasNo={this.state.globalItemNumber} flag={this.state.globalItemNumber}
                                                                                        width="100px" labelWidth="0px"
                                                                                        inValid={this.state.uAllItemFields["globalItemNumber"]}
                                                                                        maxLength={this.state.globalDirMaxlengthInput ? this.state.globalDirMaxlengthInput["dataValues"] : ""}
                                                                                        lostfocused={(e) => { this.handleGlobalChangeMvLostfocus(e, "changeFrom", index) }} // handle lost focus needed
                                                                                        keyId={"changeFrom" + index} focusingField={this.state.focusingField}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedGlobalChangeMvRowIndex") }}
                                                                                        changed={(e) => { this.handleGlobalChangeMvGridChange(e, "changeTo", index) }}
                                                                                        labelText="" field="changeTo"//{this.state.itemDescription}
                                                                                        value={mv.changeTo} lineStyle="oneLine"
                                                                                        dataSource="Global_Dir_Mv" titleDialog={this.state.itemDescription}
                                                                                        aliasNo={this.state.globalItemNumber} flag={this.state.globalItemNumber}
                                                                                        width="100px" labelWidth="0px"
                                                                                        inValid={this.state.uAllItemFields["globalItemNumber"]}
                                                                                        maxLength={this.state.globalDirMaxlengthInput ? this.state.globalDirMaxlengthInput["dataValues"] : ""}
                                                                                        lostfocused={(e) => { this.handleGlobalChangeMvLostfocus(e, "changeTo", index) }}
                                                                                        keyId={"changeTo" + index} focusingField={this.state.focusingField}
                                                                                        rowIndex={index}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="3">No records available</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="contactBtnArea right">
                                                            <button id="btnAddNewLatestGlobalChangeMv" onClick={this.addNewGlobalChangeMv}>Add</button>
                                                            <button id="btndeleteSelectedGlobalChangeMv" onClick={this.deleteSelectedGlobalChangeMv}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <fieldset className={this.props.showToggleBtn ? "bottomAreaButtonCls _hidden" : "bottomAreaButtonCls"}>
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout" style={{ display: this.props.showToggleBtn ? "none" : "" }}>
                                                    <button id="BUTTON_GLOBAL_CHANGE"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => {this.confirmChange(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_GLOBAL_CHANGE_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$code.execute.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.execute.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Ok
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout" style={{ width: "100px", display: this.props.showToggleBtn ? "none" : "" }}>
                                                    <button id="BUTTON_NEWRECORD"
                                                        className="ErsGrBlueButton _ScreenButton"
                                                        onClick={(e) => { this.newRecord() }}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.new.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            ) 
            : <p className="">Global Change loading...</p>

        return(
            <React.Fragment>
                <React.Fragment>
                    {confirmChangeMsg}
                </React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>
                <React.Fragment>
                    <div className={this.props.initialPageVisible ? "" : "hidden"}>
                        {dialogForm}
                    </div>
                </React.Fragment>
                <React.Fragment>
                    {resultsMsg}
                </React.Fragment>
            </React.Fragment>
        )
    }

}

GlobalChange.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}
const mapStateToProps = state => {
    return {
        selectedReportLabelsInfo: state.patientAbstract.selectedReportLabelsInfo, //may not needed, may need page specific function like this. 
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        globalChangeInfo: state.patientAbstract.globalChangeInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        runGlobalChange: (dataValues) => dispatch(actionCreators.runGlobalChange(dataValues)),
        selectReportLabels: (label) => dispatch(actionCreators.selectReportLabels(label)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),

        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalChange);