/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import Logo from '../Logo/Logo';
import axios from "../../../axios-instance";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import Draggable from 'react-draggable';

class ChangePassword extends Component {

    state = {
        uAllItemFields: [],
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        loaded: false,
        fieldInFocus: "Userid",
        isHelpVisible: false,
        isSaving: false,
        showingTestResult: false,
        testMessage: null,
        finished: false,
        globalMsg: "New Password and Confirm Password do not match.",
    }

    componentDidMount() {
        if (!this.state.dialogForm && !this.state.loaded) {
            axios.post("ers/LoadDataForUser", { email: "", accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setFormData(res.data.userInfo);
                        this.setState({ loaded: true });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (!this.state.dialogForm && !this.state.loaded)
            || (!this.state.finished && this.state.dialogForm && this.state.dialogForm.Userid !== null && this.state.dialogForm.Userid !== undefined && this.state.dialogForm.Userid !== "")
            || (this.state.initialDialogForm && this.state.initialDialogForm.Userid !== null && this.state.initialDialogForm.Userid !== undefined
            && this.state.dialogForm && this.state.dialogForm.Userid !== null && this.state.dialogForm.Userid !== undefined && this.state.dialogForm.Userid !== ""
            && this.state.initialDialogForm.Userid !== this.state.dialogForm.Userid)
        ) {
            axios.post("ers/LoadDataForUser",
                {
                    email: this.state.dialogForm.Userid,
                    itemDesc: "ChangePassword",
                    accessToken: localStorage.getItem('token'),
                    clientAlias: this.props.clientgroup
                })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setFormData(res.data.userInfo, true);
                        this.setState({ finished: true });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });
        }

        if (this.state.isFormDirty && this.state.isSaving && prevProps.isFormDirty != this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }

        if (this.props.error && !this.state.showingTestResult && this.state.isSaving) {
            this.setState({
                testMessage: this.props.errorMessage,
                isSaving: false,
            });
        }

        if (!this.props.error && this.props.selectedUserInfo != null
            && prevProps.selectedUserInfo != this.props.selectedUserInfo) {
            this.props.onSetAuthRedirectPath('/login');
            this.props.setAlphaUrl('/login');
            this.props.setDialog(dialogTypes.LOGIN);
        }
    }

    // 
    clearFormData = (event) => {
        event.preventDefault();
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                Userid: "",
                passwordNetDb: "",
                passwordNet: "",
                passwordNetConfirm: "",
            }
        });
        return false;
    }

    setFormData = (selectedUserInfo, loaded) => {
        if (loaded) {
            this.setState({
                initialDialogForm: {
                    ...selectedUserInfo,
                    Userid: selectedUserInfo.Userid !== undefined ? selectedUserInfo.Userid : selectedUserInfo.userid,
                },
                dialogForm: {
                    ...selectedUserInfo,
                    Userid: selectedUserInfo.Userid !== undefined ? selectedUserInfo.Userid : selectedUserInfo.userid,
                    passwordNetDb: "",
                    passwordNet: "",
                    passwordNetConfirm: "",
                }
            });
        } else {
            this.setState({
                initialDialogForm: {
                    ...selectedUserInfo,
                    Userid: selectedUserInfo.Userid !== undefined ? selectedUserInfo.Userid : selectedUserInfo.userid,
                },
                dialogForm: {
                    ...selectedUserInfo,
                    Userid: "",
                    passwordNetDb: "",
                    passwordNet: "",
                    passwordNetConfirm: "",
                }
            });
        }

        if (!this.state.uAllItemFields && selectedUserInfo) {
            let fields = {};
            Object.keys(selectedUserInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields });
        };
        return false;
    }

    hideTestResult = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingTestResult: false, testMessage: null });
        this.props.clearErrorMessage();

        if (document.getElementById("btnBackToLoginPage")) {
            document.getElementById("btnBackToLoginPage").click();
        }
        return false;
    }

    changePassword = (event) => {
        event.preventDefault();

        this.setState({ showingTestResult: false, testMessage: null });

        let dialogFormData = this.state.dialogForm;

        // TODO - check form validation
        let isValid = true;
        Object.keys(dialogFormData).map((t, k) => {
            if (t == "Userid" && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if (t == "passwordNetDb" && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "passwordNet" || t === "PasswordNet" || t === "passwordnet")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "passwordNetConfirm" || t === "PasswordNetConfirm" || t === "passwordnetconfirm")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            //
            if (dialogFormData["passwordNetConfirm"] !== dialogFormData["passwordNet"]) {
                const _t = "passwordNetConfirm";
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_t]: "IN_VALID" },
                });
                isValid = false;
            }
        });

        const formItems = this.state.uAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid) {
            return false;
        } else {
            var isValidPwdByPattern = true;
            // Finally, check by password validation pattern with message
            const patternTest = dialogFormData.passwordNet;
            var confirmMsg = "";

            var passValidation = dialogFormData.passValidation;
            var passValidationMsg = dialogFormData.passValidationMsg;

            // IGNORE check validate if case can't get any from system setting --START
            if (passValidation === null || passValidationMsg === null) {
                dialogFormData.FromPage = "Login";

                dialogFormData.email = dialogFormData.Userid;
                dialogFormData.password = dialogFormData.passwordNetDb;

                this.props.changePassword(dialogFormData);

                this.setState({
                    isSaving: true,
                    //showingTestResult: true,//set this to avoid show error message more again!!!
                });
                return false;
            }
            // --END

            const regex = new RegExp(passValidation, 'g');
            //var combineRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            let m;

            if ((m = regex.exec(patternTest)) !== null) {
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    console.log(`Found match, group ${groupIndex}: ${match}`);
                });
                isValidPwdByPattern = true;
            } else {
                isValidPwdByPattern = false;
                confirmMsg = passValidationMsg;
            }

            if (!isValidPwdByPattern) {
                this.setState({
                    showingTestResult: true,
                    testMessage: confirmMsg
                });
                return false;
            } else {
                dialogFormData.FromPage = "Login";

                dialogFormData.email = dialogFormData.Userid;
                dialogFormData.password = dialogFormData.passwordNetDb;

                this.props.changePassword(dialogFormData);

                this.setState({
                    isSaving: true,
                    //showingTestResult: true,//set this to avoid show error message more again!!!
                });
            }
        }
    }

    resetDataForm = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            loaded: false
        });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }, isFormDirty: true });
        }
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    render() {
        let resultTest;
        {
            resultTest = this.state.showingTestResult && this.state.testMessage !== null ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.props.errorMessage === "Your password has changed." ? "Success" : "Warning"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTestResult(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="container">
                                    <p>{this.state.testMessage}</p>
                                </div>
                                <div className="container center">
                                    <p>
                                        <button onClick={this.hideTestResult} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="LoginLayout">

                    <Logo />

                    <form onSubmit={this.changePassword} id="changePasswordForm">
                        <div className="emptyLine"></div>

                        <div style={{ display: "none" }}>
                            <input type="text" name="username" value="" />
                            <input type="password" name="password" value="" />
                        </div>

                        <div className="row">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="frameInner">
                                    <div className="header">Change Your Password</div>
                                    <fieldset>
                                        <legend></legend>
                                        <div className="col-sm-12">
                                            <Input type="input" changed={this.handleFormChange}
                                                labelText="User ID" field="Userid"
                                                value={this.state.dialogForm.Userid}
                                                isRequired labelWidth="150px" autoFocus
                                                inValid={this.state.uAllItemFields["Userid"]}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Input type="password" changed={this.handleFormChange}
                                                labelText="Original Password" field="passwordNetDb"
                                                value={this.state.dialogForm.passwordNetDb}
                                                isRequired labelWidth="150px"
                                                inValid={this.state.uAllItemFields["passwordNetDb"]}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Input type="password" changed={this.handleFormChange}
                                                labelText="New Password" field="passwordNet"
                                                value={this.state.dialogForm.passwordNet}
                                                isRequired labelWidth="150px"
                                                inValid={this.state.uAllItemFields["passwordNet"]}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Input type="password" changed={this.handleFormChange}
                                                labelText="Confirm Password" field="passwordNetConfirm"
                                                value={this.state.dialogForm.passwordNetConfirm}
                                                isRequired labelWidth="150px"
                                                inValid={this.state.uAllItemFields["passwordNetConfirm"]}
                                                globalMsg={this.state.globalMsg}
                                                replacementVal={this.state.dialogForm.passwordNetConfirm}
                                            />
                                        </div>

                                        <div className="center">
                                            <button type="submit">Change</button>
                                            <button onClick={this.clearFormData}>Clear</button>
                                        </div>

                                        <Link to="/login" className="btn btn-link" id="btnBackToLoginPage">Back to Login</Link>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>

                    </form>

                    <div className="emptyLine"></div>

                    <div className="hidden">
                        <Footer closeWindow={this.props.closeWindow}
                            refreshForm={this.resetDataForm}
                            formId="securityChangePwdForm"
                            isFormDirty={this.state.isFormDirty}
                            className="Footer2"
                            isPromptPopup={true}
                            exitText="Clear"
                            saveText="Change"
                        />
                    </div>
                </div>
            )
            : <p>Loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {resultTest}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

ChangePassword.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
    //email: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        isDialogLoading: state.patientAbstract.isDialogLoading,
        passExpired: state.auth.passExpired,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        selectedUserInfo: state.patientAbstract.selectedUserInfo,
        clientgroup: state.auth.clientgroup,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (dialogForm) => dispatch(actionCreators.changePassword(dialogForm)),
        setChangePwdDialogVisible: (flg) => dispatch(actionCreators.setChangePwdDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (flg) => dispatch(actionCreators.setChildrenOpening(flg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);