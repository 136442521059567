/* eslint-disable */
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import "./Productivity.css";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";

class Productivity extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        beginDate: "", //01/01/2022
        endDate: "", //08/12/2022
        hospNbr: "",
        hospital_Code: "",     //This line does not work as the default
        hospital_Code_Name: "",  //This line works as the default //"ERS Test 2 Hosp"
        abstractor: "",
        graphType: "Pie",
        reportType: "Cases Completed",
        outputType: "Screen",
        includePatientList: "",
        reportDisplayOption: "Chart",

        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        reportStatus: "Not Running", //This variable is used to control whether clock timers continue, reports display/download etc. -- "Not Running" == initial state | "Cancelled" == user has clicked the cancel button after attempting to run a report | "Report Running" == Report in the C# file has been called, but no response back recognized from it | "Report Finished" == Report in the C# function has successfully completed
    }

    Productivity_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    setStateBeginDate = (event) => {
        var value = event.target.value;
        const oldValue = this.state.beginDate;

        this.setState({
            beginDate: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateEndDate = (event) => {
        const value = event.target.value;
        const oldValue = this.state.endDate;

        this.setState({
            endDate: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateReportType = (event) => {
        const value = event.target.value;
        const oldValue = this.state.reportType;

        this.setState({
            reportType: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateGraphType = (event) => {
        const value = event.target.value;
        const oldValue = this.state.graphType;

        this.setState({
            graphType: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateReportDisplayOption = (event) => {
        //event.preventDefault();

        var name = event.target.name;
        var value = event.target.value;

        var dialogFormData = this.state.dialogForm;
        console.log("Report Display: " + value);
        dialogFormData[name] = value;

        var productivityInfo = this.props.productivityInfo;
        var html = productivityInfo.htmlOutput;
        if (value == "List") { html = productivityInfo.htmlOutput_Patient_List }
        console.log("Graph or List? "+name + " value: " + value);
        this.setState({
            reportDisplayOption: value,
            htmlOutput: html,
            dialogForm: {
                ...this.state.dialogForm,
                [name]: value
            },
        });

        setTimeout(function () {
            document.getElementById("fakeRadioControl") ? document.getElementById("fakeRadioControl").click() : null;

            //Change which report gets displayed
            var reportDisplay = document.getElementById("displayReportIframe");
            if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
                reportDisplay.src = "about:blank";

                var reportContent = reportDisplay.contentDocument;
                reportContent.write(html);

                //REA's version
                //var reportContent = reportDisplay.contentWindow || reportDisplay.contentDocument.document || reportDisplay.contentDocument;
                //if (reportContent.write) {
                //    reportContent.write("");
                //} else {
                //    reportContent.document.write("");
                //}
            }
        }, 10);
    }

    fakeRadioControlFnc = (event) => {
        event.preventDefault();
        let reportDisplayOption = this.state.reportDisplayOption;
        this.setState({ reportDisplayOption: reportDisplayOption });

        var productivityInfo = this.props.productivityInfo;
        var html = productivityInfo.htmlOutput;
        if (reportDisplayOption == "List") { html = productivityInfo.htmlOutput_Patient_List }

        setTimeout(function () {
            var reportDisplay = document.getElementById("displayReportIframe");
            if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
                reportDisplay.src = "about:blank";

                var reportContent = reportDisplay.contentDocument;
                reportContent.write(html);
                }
        }, 10);
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.registryMgmtReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        var _this = this;
        //await _this.props.generateRequestLog();  //The report doesn't run without this code, but why?  What does this function do?
        _this.props.generateProductivity();
        console.log("$$$ - Returned Values from Screen loading/generating: " + this.props.productivityInfo);

        if (!this.state.dialogForm) { // && !this.props.requestLogInfo
            this.setFormData(this.props.productivityInfo);

        }

        this.setState({ isLoading: true });
    }

    componentWillMount() {

    }
 
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.dialogForm) {
            this.setFormData(this.props.productivityInfo);
        }

        //Trying to get hospital control to work//
        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.uAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        var productivityInfo = this.props.productivityInfo;
       if (this.state.dialogForm && (prevProps.productivityInfo && this.props.productivityInfo && (prevProps.productivityInfo.startTime !== this.props.productivityInfo.startTime
            || prevProps.productivityInfo.endTime !== this.props.productivityInfo.endTime
        ))) {
            console.log("Display Report");
        //if (this.state.dialogForm && (prevProps.productivityInfo && this.props.productivityInfo)) {
        //    if (prevStartTime !== currentStartTime || prevEndTime !== currentEndTime) {
                clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
                this.setState({
                    showClockTimer: false, //If the report has finished, hide the clock
                    endTime: new Date().toLocaleTimeString(),
                    reportStatus: "Report Finished",
                });

                if (productivityInfo.output_Type == "Screen" && productivityInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                    this.displayReport(productivityInfo);
                else if (productivityInfo.output_Type == "Excel" && productivityInfo.urlOutputFile && !this.downloadFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                    var fileURL = this.props.clientgroup + "/DownloadFile";
                    this.downloadxlsx(fileURL, productivityInfo.urlOutputFile); //Download the Excel file produced by the report
                    this.downloadFile = true;
                }
            }
       // }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (productivityInfo) => {
        this.setState({
            initialDialogForm: { ...productivityInfo },
            dialogForm: { ...productivityInfo },

            //["outputType"]: requestLogInfo && requestLogInfo.sendReportTo ? requestLogInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
            reportStatus: null,
        });
        console.log("Report Status: ", this.state.reportStatus);

        this.setState({ reportStatus: "Not Currently Running", });
        return false;
    }

    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                //dialogForm: {
                //    ...this.state.dialogForm,
                //    [event.target.name]: event.target.value
                //},
                [event.target.name]: event.target.value, //11/07/2022 - Switching to this per REA to resolve the issue of typing in the hospital code directly
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {
        const name = event.target.name;

        if (event.target.name == "beginDate") {
            this.setState({
                beginDate: this.Productivity_Date_Format(event.target.value),
            });
        }
        if (event.target.name == "endDate") {
            this.setState({
                endDate: this.Productivity_Date_Format(event.target.value),
            });
        }

        if (event.target.name == "hospital_Code") {
            var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")

            dialogFormData.hospital_Code = event.target.value;
            this.setState({
                hospNbr: event.target.value,
                hospital_Code: event.target.value,
                isFormDirty: this.state.hospNbr != event.target.value ? true : this.state.isFormDirty,
                dialogForm: dialogFormData,
            });

            //this.props.checkValidInput(name, value, table, key, -1, flag);

        }
        console.log("Target Name: " + event.target.name);
        if (event.target.name == "abstractor") {
            var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
            console.log("Abstractor value: " + event.target.value);
            dialogFormData.abstractor = event.target.value;
            this.setState({
                abstractor: event.target.value,
                isFormDirty: this.state.abstractor != event.target.value ? true : this.state.isFormDirty,
                dialogForm: dialogFormData,
            });
        }
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "patientList") {
            const checked = this.state.includePatientList;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                includePatientList: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } 
    }

    Run_Productivity = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;

        dialogFormData.reportStatus = "Report In Progress";
        dialogFormData.Begin_Date = this.state.beginDate; //Putting this code here, since the onChange events won't set this for default values
        dialogFormData.End_Date = this.state.endDate;
        dialogFormData.Hospital_Code = this.state.hospNbr;
        dialogFormData.Abstractor = this.state.abstractor;
        dialogFormData.Report_Type = this.state.reportType;
        dialogFormData.Graph_Type = this.state.graphType;
        dialogFormData.Output_Type = this.state.outputType;
        dialogFormData.Include_Patient_List = this.state.includePatientList;

        dialogFormData.reportStatus = this.state.reportStatus;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        //alert("Running Request Log with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("Running Productivity with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));

        this.props.runProductivity(dialogFormData);
        
        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {

        }

        let beginDate = this.state.beginDate;
        let endDate = this.state.endDate;
        if ((beginDate && !moment(beginDate, "MM/DD/YYYY", true).isValid()) || (endDate && !moment(endDate, "MM/DD/YYYY", true).isValid())) {
            alert('Please input a valid date');
            if (!moment(beginDate, "MM/DD/YYYY", true).isValid() && beginDate) {
                document.getElementById("beginDate") ? document.getElementById("beginDate").focus() : null;
            } else if (!moment(endDate, "MM/DD/YYYY", true).isValid() && endDate) {
                document.getElementById("endDate") ? document.getElementById("endDate").focus() : null;
            }
            return false;
        }

        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString(), reportStatus: "Report Running" }); // set clock timer...

        this.Run_Productivity();
        //alert("Clock causing reload!");

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true
        });

        var reportDisplay = document.getElementById("displayReportIframe");
        if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            var reportContent = reportDisplay.contentDocument;
            reportContent.write(data.htmlOutput);
        }

        setTimeout(function () {
            var defaultRadioButton = document.getElementById("reportDefaultBtn");
            if (defaultRadioButton) {
                defaultRadioButton.click();
            }
        }, 1);

        return false;
    }


    hideDrawingCharts = (e) => {
        //try {
        //    e.preventDefault();  //What "default" action is being prevented by having this code here?
        //} catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            this.setState({
                beginDate: "",
                endDate: "",
                hospNbr: "",   //Not necessary here?
                hospital_Code: "", //This clears visual value on the screen
                hospital_Code_Name: "", //This clears the visual name on the screen
                abstractor: "",
                graphType: "Pie",
                reportType: "Cases Completed",
                outputType: "Screen",
                includePatientList: "",
                reportDisplayOption: "Chart",

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",

                isFormDirty: false,
            })
        } catch (ex) {}
    }

    defaultRadio = (e) => {
        this.setState({
            reportDisplayOption: "Chart",
        });
    }

    printReport = (e) => {
        var doc = document.getElementById("displayReportIframe").contentDocument;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Productivity</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window" style={{ cursor: "progress" }}>
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait <div class="waitingWaves"><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div></div></div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="productivityReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Productivity Report - {this.state.reportType} </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="productivityShowingCharts">
                                <iframe id="displayReportIframe" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>

                                        <div class="reportDisplayOptionRadio">
                                            <label className={this.state.includePatientList ? "reportDisplayOptionRadioLabel" : "productivityReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="Chart"
                                                    checked={this.state.reportDisplayOption === "Chart"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Chart
                                            </label>

                                            <label className={this.state.includePatientList ? "reportDisplayOptionRadioLabel" : "productivityReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="List"
                                                    checked={this.state.reportDisplayOption === "List"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Patient List
                                            </label>

                                            <input type="radio" id="fakeRadioControl" onClick={this.fakeRadioControlFnc} className="hidden" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div className="reportScreenTitle">Productivity Reports</div>
                    {this.state.dialogForm || this.state.loaded ?

                    <form onSubmit={false} id="productivityForm" className="">
                        <div className="frameInner">
                            <div id="beginDateDiv">
                                <label>Begin Date:</label>
                                    <input type="text" id="beginDate" name="beginDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.beginDate}
                                        onChange={(e) => this.setState({ beginDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                    labelText="Begin Date: "
                                    field="beginDate"
                                />
                            </div>

                            <div id="endDateDiv">
                                <label>End Date:</label>
                                <input type="text" id="endDate" name="endDate"
                                    placeholder="mm/dd/yyyy"
                                    value={this.state.endDate}
                                    onChange={(e) => this.setState({ endDate: e.target.value })}
                                    onBlur={this.handleLostFocus}
                                />
                            </div>

                                {/*<div className="col-sm-5" style={{ display: "flex" }}>*/}
                                {/*    <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}*/}
                                {/*        labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}*/}
                                {/*        value={this.state.dialogForm.hospitalCode}*/}
                                {/*        dataSource="Hospital2L" titleDialog="Hospital Lookup" codeLength="2"*/}
                                {/*        labelWidth="100px" width="150px"*/}
                                {/*        inValid={this.state.uAllItemFields["hospitalCode"]}*/}
                                {/*        lineStyle="oneLine" itemNbr=""*/}
                                {/*        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}*/}
                                {/*        flag={this.state.availableHosps ? this.state.availableHosps : ""}*/}
                                {/*        hospNbr={this.state.dialogForm.hospitalCode ? this.state.dialogForm.hospitalCode : this.state.availableHosps}*/}
                                {/*        shownType="dropdown" isMultiSelection={false}*/}
                                {/*    />*/}
                                {/*    <label type="label" id="hospitalCodeName" name="hospitalCodeName" >}{this.state.hospitalCodeName}{/*this.state.dialogForm.hospitalCodeName}</label>
                                {/*</div>*/}

                            <div className="col-sm-7" style={{ display: "flex" }}>
                                <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="Hospital" field="hospital_Code" lostfocused={this.handleLostFocus} name="hospital_Code"
                                    value={this.state.hospital_Code}
                                    dataSource="Hospital2L" titleDialog="Hospital Lookup" codeLength="2"
                                    labelWidth="90px" width="240px"
                                    lineStyle="oneLine" itemNbr=""
                                    flag={this.state.availableHosps ? this.state.availableHosps : ""}
                                    hospNbr={this.state.hospital_Code ? this.state.hospital_Code : this.state.availableHosps}
                                    shownType="dropdown" isMultiSelection={false}
                                />
                                <label type="label" id="hospital_Code_Name" name="hospital_Code_Name" >{this.state.hospital_Code_Name}</label>
                            </div>

                            
                            <div className="col-sm-7">
                                <Input type="codeSelect" name="abstractor"
                                    changed={this.handleFormChange} width="180px"
                                    labelText="Abstractor" autoFocus
                                    field="abstractor" lineStyle="oneLine" keyId="abstractor0"
                                    value={this.state.abstractor}
                                    dataSource="Registrars"
                                    titleDialog="Abstractors" labelWidth="90px"
                                    lostfocused={this.handleLostFocus} 
                                />
                            </div>

                            <div className="hidden">
                            <label id="abstractorLabel" for="cars">Abstracted By:</label>
                            <select id="abstractor" name="abstractor">
                                <option value=""></option>
                                <option value="JD">John Doe</option>
                                <option value="JPD">Jane Doe</option>
                                <option value="JDS">John Smith</option>
                                <option value="JS">Jane Smith</option>
                            </select>
                            </div>
                            <br />
                            <div className="row">
                                <div className="reportTypeRadio">
                                    <div id="reportTypeLabel">Productivity Report Type:</div>
                                    <label title="Cases Completed - Abstracted flag = A, State Report flag = T, Y, C or F, and Date Abstracted between the Begin and End Date entered on this screen">
                                        <input type="radio" name="reportType" field="reportType" value="Cases Completed"
                                            onClick={this.setStateReportType}
                                            checked={this.state.reportType === "Cases Completed"} />
                                        Cases Completed
                                    </label>
                                    <br />
                                    <label title="Cases Abstracted - Abstracted flag = A and Date Abstracted between the Begin and End Date entered on this screen">
                                        <input type="radio" name="reportType" field="reportType" value="Cases Abstracted"
                                            onClick={this.setStateReportType}
                                            checked={this.state.reportType === "Cases Abstracted"} />
                                        Cases Abstracted
                                    </label>
                                    <br />
                                    <label title="Follow-Up Entered - Has at least one Date of Last Contact from the Outcomes screen between the Begin and End Date entered on this screen">
                                        <input type="radio" name="reportType" field="reportType" value="Follow-Up Entered"
                                            onClick={this.setStateReportType}
                                            checked={this.state.reportType === "Follow-Up Entered"} />
                                        Follow-Up Entered
                                    </label>
                                    <br />
                                    <label title="Last Follow-Up Entered - Selects cases with a Combined Last Contact Date between the Begin and End Date entered on this screen">
                                        <input type="radio" name="reportType" field="reportType" value="Last Follow-Up Entered"
                                            onClick={this.setStateReportType}
                                            checked={this.state.reportType === "Last Follow-Up Entered"} />
                                        Last Follow-Up Entered
                                    </label>
                                </div>
                            </div>

                            <div className="">
                            <input type="checkbox" id="patientList" className="checkbox"
                                name="patientList"
                                value={this.state.includePatientList ? "true" : "false"}
                                checked={this.state.includePatientList}
                                onChange={this.toggleChecked} />
                            <label for="patientList" className="checkboxLabel"> Include Patient List </label>
                            </div>

                            <div className="row">
                                <div className="outputDeviceRadio">
                                    <div id="outputDeviceLabel">Send Report To:</div>
                                    <label>
                                        <input type="radio" name="outputType" field="outputType" value="Excel"
                                            onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Excel"} />
                                        Excel
                                    </label>
                                    <br />
                                    <label>
                                        <input type="radio" name="outputType" field="outputType" value="Screen"
                                            onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Screen"} />
                                        Screen
                                    </label>
                                </div>

                                <div className={this.state.outputType == "Excel" ? "graphTypeRadio" : "hidden"}>
                                    <div id="graphTypeLabel">Chart Type:</div>
                                    <label>
                                        <input type="radio" name="graphType" field="graphType" value="Pie"
                                            onClick={this.setStateGraphType}
                                            checked={this.state.graphType === "Pie"} />
                                        Pie
                                    </label>

                                    <label>
                                        <input type="radio" name="graphType" field="graphType" value="Bar"
                                            onClick={this.setStateGraphType}
                                            checked={this.state.graphType === "Bar"} />
                                        Bar
                                    </label>

                                    <label>
                                        <input type="radio" name="graphType" field="graphType" value="Column"
                                            onClick={this.setStateGraphType}
                                            checked={this.state.graphType === "Column"} />
                                        Column
                                    </label>
                                </div>
                            </div>
                            <br />

                            <div id="CONTAINER_JS_CLOCK" className="hidden">
                                <label>Selecting Cases</label>
                                <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                    Selecting Cases - Please Wait ...
                                </div>
                            </div>
                        </div>

                        <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showClockEvent(e) }}
                                //disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_RUN_ICON"
                                    src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                onClick={this.newRecord.bind(this)}
                                //onClick={(e) => { this.showClockEvent(e) }}
                                disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_NEWRECORD_ICON"
                                    src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                New
                            </button>

                            <button type="button" id="reportDefaultBtn" className="hidden" title=""
                                onClick={(e) => { this.defaultRadio(e) }} value="default" autoFocus>
                                Default
                            </button>
                        </fieldset>
                    </form>
                        : <p>Productivity report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        productivityInfo: state.patientAbstract.productivityInfo,           //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        //Trying to get hospital control to work//
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateProductivity: (flag) => dispatch(actionCreators.generateProductivity(flag)),
        runProductivity: (dialogForm) => dispatch(actionCreators.runProductivity(dialogForm)),
        //Trying to get hospital control to work//
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Productivity);