/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Help from "../../../UI/Help";
import Draggable from 'react-draggable';
import moment from "moment";
import "../AdminReportStyle.css";
import ConfigData from '../../../../store/config.json';

class ReportFile extends Component {

    downloadFile = false;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "reportLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        selectedOutputDevice: null,
        isCheckedOutputDevice: false,

        // RfLabelsReportMv
        rfLabelsReportMv: null,
        rfLabelsReportMvItemFields: null,
        rfLabelsReportMvLoading: false,
        selectedRfLabelsReportMvRowIndex: 0,
        rfLabelsReportMvGridFocusing: false,
        setEventHandlerForRfLabelsReportMvGrid: false,

        // RfLabelsSelectMv
        rfLabelsSelectMv: null,
        rfLabelsSelectMvItemFields: null,
        rfLabelsSelectMvLoading: false,
        selectedRfLabelsSelectMvRowIndex: 0,
        rfLabelsSelectMvGridFocusing: false,
        setEventHandlerForRfLabelsSelectMvGrid: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowConfirmDeleteMsg: false,
        isShowConfirmDeleteLabelMsg: false,
        confirmed: null,
        monitoring: null,

        rfLabelsReportMvMaxlengthInput: null,
        rfLabelsSelectMvMaxlengthInput: null,
        allowSaveAnyway: true,

        //
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        showMessageError: false,
        selectedCaseOption: "Screen",
    }

    keydownHandler = (event) => {
        if (event.target.getAttribute("class") === "copyRowBtnOnGrid") {
            if (event.key === "Tab" && !event.shiftKey) {
                if (event.target.parentNode.parentNode.nextSibling !== null) {
                    let rindex = event.target.getAttribute("data-index");
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("selectColumn" + rindex) ? document.getElementById("selectColumn" + rindex).focus() : "";
                    }, 100);
                } else {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestRfLabelsSelectMv") ? document.getElementById("btnAddNewLatestRfLabelsSelectMv").focus() : "";
                    }, 100);
                }
            }
        } else if (event.target.getAttribute("class") === "copyRowBtnOnGrid2") {
                if (event.key === "Tab" && !event.shiftKey) {
                    if (event.target.parentNode.parentNode.nextSibling !== null) {
                        let rindex = event.target.getAttribute("data-index");
                        rindex = parseInt(rindex) + 1;
                        setTimeout(function () {
                            document.getElementById("reportColumn" + rindex) ? document.getElementById("reportColumn" + rindex).focus() : "";
                        }, 100);
                    } else {
                        setTimeout(function () {
                            document.getElementById("btnAddNewLatestRfLabelsReportMv") ? document.getElementById("btnAddNewLatestRfLabelsReportMv").focus() : "";
                        }, 100);
                    }
                }
            }
    }

    //RF_Labels_Select_Mv
    copyRowRfLabelsSelectMvGrid = (event, selectedRow) => {
        event.preventDefault();

        var rfLabelsSelectMvList = [...this.state.rfLabelsSelectMv];
        if (selectedRow == rfLabelsSelectMvList.length - 1) {
            this.addNewRfLabelsSelectMv(event);
            return false;
        }

        this.setState(prevState => {
            const newItems = [...this.state.rfLabelsSelectMv];
            const selectedItem = newItems[selectedRow];

            const newLabels = {};
            Object.keys(selectedItem).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = selectedItem[t];
                }
            });
            newItems.splice(selectedRow + 1, 1);
            newItems.splice(selectedRow + 1, 0, newLabels);

            // 
            const newFields = [...this.state.rfLabelsSelectMvItemFields];
            const selectedFields = newFields[selectedRow];

            const newField = {};
            Object.keys(selectedFields).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(selectedRow + 1, 1);
            newFields.splice(selectedRow + 1, 0, newField);

            return {
                rfLabelsSelectMv: newItems,
                rfLabelsSelectMvItemFields: newFields,
                isFormDirty: true,
            };
        });

        return false;
    }

    //RF_Labels_Report_Mv
    copyRowRfLabelsReportMvGrid = (event, selectedRow) => {
        event.preventDefault();

        var rfLabelsReportMvList = [...this.state.rfLabelsReportMv];
        if (selectedRow == rfLabelsReportMvList.length - 1) {
            this.addNewRfLabelsReportMv(event);
            return false;
        }

        this.setState(prevState => {
            const newItems = [...this.state.rfLabelsReportMv];
            const selectedItem = newItems[selectedRow];

            const newLabels = {};
            Object.keys(selectedItem).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = selectedItem[t];
                }
            });
            newItems.splice(selectedRow + 1, 1);
            newItems.splice(selectedRow + 1, 0, newLabels);

            // 
            const newFields = [...this.state.rfLabelsReportMvItemFields];
            const selectedFields = newFields[selectedRow];

            const newField = {};
            Object.keys(selectedFields).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(selectedRow + 1, 1);
            newFields.splice(selectedRow + 1, 0, newField);

            return {
                rfLabelsReportMv: newItems,
                rfLabelsReportMvItemFields: newFields,
                isFormDirty: true,
            };
        });

        return false;
    }
    selectionCases = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedCaseOption: event.target.value,
            dialogForm: dialogFormData
        });
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    //-->
    deleteRfLabelsReportMvGrid = (flg) => {
        let selectedRow = this.state.selectedRfLabelsReportMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.rfLabelsReportMv.length) {
            selectedRow = this.state.rfLabelsReportMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.rfLabelsReportMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.rfLabelsReportMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "rfLabelsId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.rfLabelsReportMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.rfLabelsReportMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { rfLabelsReportMv: newItems, rfLabelsReportMvItemFields: newFields, selectedRfLabelsReportMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    deleteRfLabelsSelectMvGrid = (flg) => {
        let selectedRow = this.state.selectedRfLabelsSelectMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.rfLabelsSelectMv.length) {
            selectedRow = this.state.rfLabelsSelectMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.rfLabelsSelectMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.rfLabelsSelectMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "rfLabelsId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.rfLabelsSelectMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.rfLabelsSelectMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { rfLabelsSelectMv: newItems, rfLabelsSelectMvItemFields: newFields, selectedRfLabelsSelectMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "rfLabelsReportMv":
                    this.deleteRfLabelsReportMvGrid("1");
                    break;
                case "rfLabelsSelectMv":
                    this.deleteRfLabelsSelectMvGrid("1");
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    hideConfirmDeleteLabelMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: false });

        if (event.target.value === "yes") {
            this.deleteLabel();
        }
        return false;
    }

    insertNewRfLabelsReportMvGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.rfLabelsReportMv];
            const firstItems = newItems[0];

            const newLabelsSite = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newLabelsSite[t] = 0;
                } else {
                    newLabelsSite[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabelsSite);

            // 
            const newFields = [...this.state.rfLabelsReportMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            return {
                rfLabelsReportMv: newItems,
                rfLabelsReportMvItemFields: newFields,
            };
        });
        return false;
    }

    insertNewRfLabelsSelectMvGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.rfLabelsSelectMv];
            const firstItems = newItems[0];

            const newLabels = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabels);

            // 
            const newFields = [...this.state.rfLabelsSelectMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            return {
                rfLabelsSelectMv: newItems,
                rfLabelsSelectMvItemFields: newFields,
            };
        });
        return false;
    }

    addEventListenerFnc = (om) => {
        var rfLabelsReportMvGrid = document.getElementById('rfLabelsReportMvListArea');
        rfLabelsReportMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedRfLabelsReportMvRowIndex;
                om.insertNewRfLabelsReportMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('siteCode' + selectedIndex) ? document.getElementById('siteCode' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedRfLabelsReportMv();//btnDeleteSelectedRfLabelsReportMv
                return false;
            }
            return false;
        });

        var rfLabelsSelectMvGrid = document.getElementById('rfLabelsSelectMvListArea');
        rfLabelsSelectMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedRfLabelsSelectMvRowIndex;
                om.insertNewRfLabelsSelectMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('globalItemNumber' + selectedIndex) ? document.getElementById('globalItemNumber' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedRfLabelsSelectMv();//btnDeleteSelectedRfLabelsSelectMv
                return false;
            }
            return false;
        });

        return false;
    }

    // RfLabelsReportMv:: Site Codes grid
    handleRfLabelsReportMvLostfocus = (event, index) => {
        try {
            event.preventDefault();
        } catch (ex) { }

        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.rfLabelsReportMvItemFields];
                newItems[index][name] = "";
                return { rfLabelsReportMvItemFields: newItems };
            });
        }

        let currentValue = value;
        if (name == "displayLength" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.rfLabelsReportMv];
                newItems[index][name] = "20";
                return { rfLabelsReportMv: newItems };
            });
        } else if (name == "breakSortList" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.rfLabelsReportMv];
                newItems[index][name] = "L";
                return { rfLabelsReportMv: newItems };
            });
        }

        return false;
    }
    handleRfLabelsReportMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        let name2 = null;
        let value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.rfLabelsReportMv];
            newItems[index][name] = value;

            if (name2 && value2) {
                newItems[index][name2] = value2;

                if (name2 == "reportColumnName") newItems[index]["dataType"] = value2;
            }

            return { rfLabelsReportMv: newItems, isFormDirty: true };
        });

        return false;
    }

    addNewRfLabelsReportMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.rfLabelsReportMv];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);

            // 
            const newFields = [...this.state.rfLabelsReportMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { rfLabelsReportMv: newItems, rfLabelsReportMvItemFields: newFields };
        });
    }

    deleteSelectedRfLabelsReportMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedRfLabelsReportMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.rfLabelsReportMv.length) {
            selectedRow = this.state.rfLabelsReportMv.length - 1;
        }

        if (this.state.rfLabelsReportMv[selectedRow] && this.state.rfLabelsReportMv[selectedRow]["id"] !== undefined && this.state.rfLabelsReportMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "rfLabelsReportMv" });
        } else {
            this.deleteRfLabelsReportMvGrid("0");
        }
        return false;
    }

    // RfLabelsSelectMv:: Selection Creteria grid
    handleRfLabelsSelectMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            //this.props.checkValidInput(name, value, table, key, index);

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.rfLabelsSelectMvItemFields];
                newItems[index][name] = "";
                return { rfLabelsSelectMvItemFields: newItems };
            });
        }

        let currentValue = value;
        if (name == "relation" && currentValue != "=" && currentValue != ">=" && currentValue != "<=" && currentValue != ">" && currentValue != "<" && currentValue != "<>") {
            this.setState(prevState => {
                const newItems = [...prevState.rfLabelsSelectMv];
                newItems[index][name] = "=";
                return { rfLabelsSelectMv: newItems };
            });
        } else if (name == "logic" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.rfLabelsSelectMv];
                newItems[index][name] = "And";
                return { rfLabelsSelectMv: newItems };
            });

            //Fill_Dictionary_Name
        } else if (name == "globalItemNumber") {
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		                    //update new accessToken again
		                    var newAccessToken = res && res.data ? res.data.accessToken : null;
		                    if (newAccessToken) {
		                        localStorage.setItem('token', newAccessToken);
		                    }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                //
                                _this.setState(prevState => {
                                    const newItems = [...prevState.rfLabelsSelectMv];
                                    newItems[index]["itemDescription"] = globalDir.itemDesc;
                                    newItems[index]["fieldName"] = globalDir.columnName;
                                    newItems[index]["fileName"] = globalDir.fileName;
                                    newItems[index]["dataType"] = globalDir.dataType;

                                    return { rfLabelsSelectMv: newItems };
                                });
                            } else {
                                //clear all set values
                                _this.setState(prevState => {
                                    const newItems = [...prevState.rfLabelsSelectMv];
                                    newItems[index]["itemDescription"] = "";
                                    newItems[index]["fieldName"] = "";
                                    newItems[index]["fileName"] = "";
                                    newItems[index]["dataType"] = "";

                                    return { rfLabelsSelectMv: newItems };
                                });
                            }
                            return false;
                        })
	                    .catch(function (error) {
	                        console.log(error);

	                        var btnLogout = document.getElementById("logoutButtonArea");
	                        if (btnLogout) {
	                            btnLogout.querySelector("a").click();
	                        }
	                        return false;
                        });

                    return false;
                }
            } else {
                //clear all set values
                _this.setState(prevState => {
                    const newItems = [...prevState.rfLabelsSelectMv];
                    newItems[index]["itemDescription"] = "";
                    newItems[index]["fieldName"] = "";
                    newItems[index]["fileName"] = "";
                    newItems[index]["dataType"] = "";

                    return { rfLabelsSelectMv: newItems };
                });
            }
        } else {
            //
        }

        return false;
    }
    handleRfLabelsSelectMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        let name2 = null;
        let value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.rfLabelsSelectMv];
            newItems[index][name] = value;

            if (name2 && value2) {
                newItems[index][name2] = value2;
                
                if (name2 == "selectColumnName") newItems[index]["dataType"] = value2;
            }

            return { rfLabelsSelectMv: newItems, isFormDirty: true };
        });

        //Fill_Dictionary_Name
        if (name == "globalItemNumber") {
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		                    //update new accessToken again
		                    var newAccessToken = res && res.data ? res.data.accessToken : null;
		                    if (newAccessToken) {
		                        localStorage.setItem('token', newAccessToken);
		                    }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                //
                                _this.setState(prevState => {
                                    const newItems = [...prevState.rfLabelsSelectMv];
                                    newItems[index]["itemDescription"] = globalDir.itemDesc;
                                    newItems[index]["fieldName"] = globalDir.columnName;
                                    newItems[index]["fileName"] = globalDir.fileName;
                                    newItems[index]["dataType"] = globalDir.dataType;

                                    return { rfLabelsSelectMv: newItems };
                                });
                            } else {
                                //clear all set values
                                _this.setState(prevState => {
                                    const newItems = [...prevState.rfLabelsSelectMv];
                                    newItems[index]["itemDescription"] = "";
                                    newItems[index]["fieldName"] = "";
                                    newItems[index]["fileName"] = "";
                                    newItems[index]["dataType"] = "";

                                    return { rfLabelsSelectMv: newItems };
                                });
                            }
                            return false;
                        })
	            .catch(function (error) {
	                console.log(error);

	                var btnLogout = document.getElementById("logoutButtonArea");
	                if (btnLogout) {
	                    btnLogout.querySelector("a").click();
	                }
	                return false;
	            });
                    return false;
                }

            } else {
                //clear all set values
                this.setState(prevState => {
                    const newItems = [...prevState.rfLabelsSelectMv];
                    newItems[index]["itemDescription"] = "";
                    newItems[index]["fieldName"] = "";
                    newItems[index]["fileName"] = "";
                    newItems[index]["dataType"] = "";

                    return { rfLabelsSelectMv: newItems };
                });
            }
        } else {
            //
        }

        return false;
    }

    addNewRfLabelsSelectMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.rfLabelsSelectMv];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);

            // 
            const newFields = [...this.state.rfLabelsSelectMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "rfLabelsId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { rfLabelsSelectMv: newItems, rfLabelsSelectMvItemFields: newFields };
        });
    }

    deleteSelectedRfLabelsSelectMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedRfLabelsSelectMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.rfLabelsSelectMv.length) {
            selectedRow = this.state.rfLabelsSelectMv.length - 1;
        }

        if (this.state.rfLabelsSelectMv[selectedRow] && this.state.rfLabelsSelectMv[selectedRow]["id"] !== undefined && this.state.rfLabelsSelectMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "rfLabelsSelectMv" });
        } else {
            this.deleteRfLabelsSelectMvGrid("0");
        }
        return false;
    }

    setSelectionRfLabelsReportMvRow = (event, index) => {
        this.setState({ selectedRfLabelsReportMvRowIndex: index });
    }

    setSelectionRfLabelsSelectMvRow = (event, index) => {
        this.setState({ selectedRfLabelsSelectMvRowIndex: index });
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_RUN_REPORT") ? document.querySelector("#BUTTON_RUN_REPORT").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedRfLabelsPopInfo) {
            this.setFormData(this.props.selectedRfLabelsPopInfo);
        }
    }
    componentDidUnmount() {
        this.props.selectRfLabels("");
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null });
        this.props.clearErrorMessage();

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        if (this.props.error) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                    startTime: this.props.selectedRfLabelsPopInfo ? this.props.selectedRfLabelsPopInfo.startTime : "",
                    endTime: this.props.selectedRfLabelsPopInfo ? this.props.selectedRfLabelsPopInfo.endTime : "",
                    numberOfRecords: this.props.selectedRfLabelsPopInfo ? this.props.selectedRfLabelsPopInfo.numberOfRecords : "",
                }
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        /*
        if (!this.state.setEventHandlerForGrid) {
            if (document.getElementById('rfLabelsReportMvListArea')) {
                this.addEventListenerFnc(this);
                this.setState({ setEventHandlerForGrid: true });
            }
        }
        */

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // RfLabelsReportMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.rfLabelsReportMv];
                        const newFields = [...prevState.rfLabelsReportMvItemFields];

                        if (newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "reportColumn" && this.props.commonLookupItem.name) {
                            newItems[this.props.currentSelectedIndex]["dataType"] = this.props.commonLookupItem.name;
                        }
                        if (newFields[this.props.currentSelectedIndex]) {
                            newFields[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }

                        return { rfLabelsReportMv: newItems, rfLabelsReportMvItemFields: newFields };
                    });

                    // RfLabelsSelectMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.rfLabelsSelectMvItemFields];

                        if (newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "selectColumn" && this.props.commonLookupItem.name) {
                            newItems[this.props.currentSelectedIndex]["dataType"] = this.props.commonLookupItem.name;
                        }
                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }

                        return { rfLabelsSelectMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex != -1) {
                    // RfLabelsReportMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.rfLabelsReportMv];
                        const newFields = [...prevState.rfLabelsReportMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "reportColumn") {
                            newItems[this.props.currentSelectedIndex]["dataType"] = "";
                        }

                        if (newFields && newFields[this.props.currentSelectedIndex]) {
                            //newFields[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }

                        return { rfLabelsReportMv: newItems, rfLabelsReportMvItemFields: newFields };
                    });

                    // RfLabelsSelectMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.rfLabelsSelectMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "selectColumn") {
                            newItems[this.props.currentSelectedIndex]["dataType"] = "";
                        }

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            //newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { rfLabelsSelectMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedRfLabelsPopInfo)
            || (prevProps.selectedRfLabelsPopInfo && this.props.selectedRfLabelsPopInfo && (prevProps.selectedRfLabelsPopInfo.rfLabelsId !== this.props.selectedRfLabelsPopInfo.rfLabelsId || prevProps.selectedRfLabelsPopInfo.reportLabel !== this.props.selectedRfLabelsPopInfo.reportLabel))
            || (prevProps.selectedRfLabelsPopInfo && this.props.selectedRfLabelsPopInfo && (prevProps.selectedRfLabelsPopInfo.startTime !== this.props.selectedRfLabelsPopInfo.startTime || prevProps.selectedRfLabelsPopInfo.endTime !== this.props.selectedRfLabelsPopInfo.endTime))
            || (this.state.reload && this.props.selectedRfLabelsPopInfo)
        ) {
            this.setFormData(this.props.selectedRfLabelsPopInfo);
        }

        // rfLabelsReportMv List
        if (this.props.selectedRfLabelsPopInfo /*&& this.props.selectedRfLabelsPopInfo.rfLabelsId != "-1"*/ && !this.state.rfLabelsReportMv && !this.state.rfLabelsReportMvLoading) {
            this.setState({ rfLabelsReportMvLoading: true });
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetRfLabelsReportMvList?rfLabelsId=" + this.props.selectedRfLabelsPopInfo.rfLabelsId)
            axios.post(this.props.clientgroup + "/GetRfLabelsReportMvList",
                { rfLabelsId: this.props.selectedRfLabelsPopInfo.rfLabelsId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        _this.setState({ rfLabelsReportMv: res.data.rfLabelsReportMvList });

                        //
                        let maxLengthItem = [];//#1
                        if (!_this.state.rfLabelsReportMvItemFields && res.data.rfLabelsReportMvList) {
                            let _rfLabelsReportMvArr = [];
                            res.data.rfLabelsReportMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                _rfLabelsReportMvArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            _this.setState({ rfLabelsReportMvItemFields: _rfLabelsReportMvArr });
                        };

                        // get maxlength for RF_Labels_Report_Mv grid
                        //axios.post(_this.props.clientgroup + "/GetTableStructure?table=RF_Labels_Report_Mv")

                        let url = _this.props.clientgroup + "/GetTableStructure";
                        let params = { table: "RF_Labels_Report_Mv", accessToken: localStorage.getItem('token') };

                        axios.post(url, params)
                            .then(res => {
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    _this.setState({
                                        rfLabelsReportMvMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);

                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                return false;
                            });
                    }
                }).
                catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }
        // RfLabelsSelectMv List
        if (this.props.selectedRfLabelsPopInfo /*&& this.props.selectedRfLabelsPopInfo.rfLabelsId != "-1"*/ && !this.state.rfLabelsSelectMv && !this.state.rfLabelsSelectMvLoading) {
            this.setState({ rfLabelsSelectMvLoading: true });
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetRfLabelsSelectMvList?rfLabelsId=" + this.props.selectedRfLabelsPopInfo.rfLabelsId)
            axios.post(this.props.clientgroup + "/GetRfLabelsSelectMvList",
                { rfLabelsId: this.props.selectedRfLabelsPopInfo.rfLabelsId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        var rfLabelsSelectMvList = res.data.rfLabelsSelectMvList;//RfLabelsSelectMvList
                        let rfLabelsSelectMvArr = [];
                        //
                        let maxLengthItem = [];//#2
                        rfLabelsSelectMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                fields[t] = "";
                            });
                            rfLabelsSelectMvArr.push(fields);
                            maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#2
                        });

                        // get maxlength for RF_Labels_Select_Mv grid
                        //axios.post(_this.props.clientgroup + "/GetTableStructure?table=RF_Labels_Select_Mv")
                        let url = _this.props.clientgroup + "/GetTableStructure";
                        let params = { table: "RF_Labels_Select_Mv", accessToken: localStorage.getItem('token') };

                        axios.post(url, params)
                            .then(res => {
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error);

                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                return false;
                            });

                        _this.setState({
                            rfLabelsSelectMv: rfLabelsSelectMvList,
                            rfLabelsSelectMvItemFields: rfLabelsSelectMvArr,
                            rfLabelsSelectMvMaxlengthInput: { ...maxLengthItem },
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }
        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=RF_Labels";
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "RF_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });
    }

    drawingHtmlTable(dataForm) {
        this.setState({
            isDrawingHtmlTable: true,
            Fn_Html_Display_URL: dataForm.htmlDisplay,//fnHtmlDisplayUrl
            publicFolder: dataForm.publicFolder,
            showClockTimer: false,//hide clock timer
        });
        return false;
    }

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });

        //Deleting output file at public folder...
        const fileName = (this.state.publicFolder);
        var _this = this;
        axios.post(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/DeleteOutputFile", { file: fileName, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    _this.setState({ publicFolder: null });
                }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        return false;
    }

    printTable = () => {
        var printStyle = document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : this.state.printStyle;

        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Report for ' + (this.state.dialogForm ? this.state.dialogForm.tableName : "")+ '</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    setFormData = (selectedRfLabelsPopInfo) => {
        this.setState({
            initialDialogForm: {
                ...selectedRfLabelsPopInfo,
                ["requestDate"]: (selectedRfLabelsPopInfo != null && selectedRfLabelsPopInfo.requestDate !== null && selectedRfLabelsPopInfo.requestDate !== "" ? moment(new Date(selectedRfLabelsPopInfo.requestDate)).format("MM/DD/YYYY") : ""),
            },
            dialogForm: {
                ...selectedRfLabelsPopInfo,
                ["requestDate"]: (selectedRfLabelsPopInfo != null && selectedRfLabelsPopInfo.requestDate !== null && selectedRfLabelsPopInfo.requestDate !== "" ? moment(new Date(selectedRfLabelsPopInfo.requestDate)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,

            showClockTimer: false,
            titleDialog: null,

            ["selectedCaseOption"]: selectedRfLabelsPopInfo && selectedRfLabelsPopInfo.outputDevice ? selectedRfLabelsPopInfo.outputDevice : "Screen",//default value
        });

        if ((!this.state.uAllItemFields && selectedRfLabelsPopInfo)) {
            let fields = {};
            Object.keys(selectedRfLabelsPopInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };

        // Processing output...
        if (selectedRfLabelsPopInfo.outputDevice == "Screen" && selectedRfLabelsPopInfo.endTime && selectedRfLabelsPopInfo.fnHtmlDisplayUrl) {
            this.drawingHtmlTable(selectedRfLabelsPopInfo);
        } else if (!this.downloadFile && selectedRfLabelsPopInfo && selectedRfLabelsPopInfo.outputDevice &&
            ((selectedRfLabelsPopInfo.outputDevice == "Excel" && selectedRfLabelsPopInfo.fnExcelPath) ||
            (selectedRfLabelsPopInfo.outputDevice == "File CSV" && selectedRfLabelsPopInfo.fnCsvUrl) ||
            (selectedRfLabelsPopInfo.outputDevice == "File Fixed-Width" && selectedRfLabelsPopInfo.fnFixedWPath) ||
            (selectedRfLabelsPopInfo.outputDevice == "Xml" && selectedRfLabelsPopInfo.fnXmlDisplayUrl))
        ) {
            this.downloadFileOutput(selectedRfLabelsPopInfo, selectedRfLabelsPopInfo.outputDevice);

            if ((selectedRfLabelsPopInfo.outputDevice == "File CSV" && selectedRfLabelsPopInfo.fnCsvUrl) ||
                (selectedRfLabelsPopInfo.outputDevice == "File Fixed-Width" && selectedRfLabelsPopInfo.fnFixedWPath)) {
                this.setState({
                    Fn_CSV_Layout_URL: selectedRfLabelsPopInfo.fnCsvLayoutUrl,
                    Fn_FixedW_Layout_Path: selectedRfLabelsPopInfo.fnFixedWLayoutPath,
                });
            }
        } else {
            this.setState({
                Fn_CSV_Layout_URL: selectedRfLabelsPopInfo.fnCsvLayoutUrl,
                Fn_FixedW_Layout_Path: selectedRfLabelsPopInfo.fnFixedWLayoutPath,
                Fn_Xml_Display_URL: selectedRfLabelsPopInfo.fnXmlDisplayUrl,
            });
        }

        return false;
    }

    downloadFileLayout(selectedRfLabelsPopInfo, type) {
        var fileUrl = (type == "File CSV" ? selectedRfLabelsPopInfo.fnCsvLayoutUrl : selectedRfLabelsPopInfo.fnFixedWLayoutPath);
        if (!fileUrl || fileUrl == "null") fileUrl = "";

        var url = this.props.clientgroup + "/DownloadFile";
        var _this = this;
        axios.post(url, {
            urlOutputFile: fileUrl,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;

            // get file name from url
            var filename = fileUrl.substring(fileUrl.lastIndexOf('\\') + 1);
            console.log("filename=" + filename);

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            _this.downloadFile = true;
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    downloadFileOutput(selectedRfLabelsPopInfo, type) {
        var fileUrl = (type == "File CSV" ? selectedRfLabelsPopInfo.fnCsvUrl
            : (type == "File Fixed-Width" ? selectedRfLabelsPopInfo.fnFixedWPath
                : (type == "Xml" ? selectedRfLabelsPopInfo.fnXmlDisplayUrl : selectedRfLabelsPopInfo.fnExcelPath)));

        if (!fileUrl || fileUrl == "null") fileUrl = "";

        var url = this.props.clientgroup + "/DownloadFile";
        var _this = this;
        axios.post(url, {
            urlOutputFile: fileUrl,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            var filename = fileUrl.substring(fileUrl.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (selectedRfLabelsPopInfo.fnExcelPath.indexOf('/') != -1) {
                excel = selectedRfLabelsPopInfo.fnExcelPath.substring(selectedRfLabelsPopInfo.fnExcelPath.lastIndexOf('/') + 1);
            } else {
                excel = selectedRfLabelsPopInfo.fnExcelPath.substring(selectedRfLabelsPopInfo.fnExcelPath.lastIndexOf('\\') + 1);
            }

            if (type == "Excel") {
                link.href = this.props.clientgroup + "/" + excel;
            } else {
                const _url = window.URL.createObjectURL(new Blob([response.data]));
                link.href = _url;
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();

            _this.downloadFile = true;
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    //save form
    saveRfLabelsInfo = (event) => {
        event.preventDefault();

        this.props.setChildrenOpening(false);

        let dialogFormData = this.state.dialogForm;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        // TODO - check form validation
        let isValid = true;
        Object.keys(dialogFormData).map((t) => {
            if (dialogFormData[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let rfLabelsReportMv = this.state.rfLabelsReportMv;
            let rfLabelsSelectMv = this.state.rfLabelsSelectMv;

            dialogFormData.rfLabelsReportMv = rfLabelsReportMv;
            dialogFormData.rfLabelsSelectMv = rfLabelsSelectMv;
            dialogFormData.actionType = "Save_Only";
            dialogFormData.reportingDevice = this.state.selectedCaseOption ? this.state.selectedCaseOption : "Screen";

            if (dialogFormData.reportLabel && dialogFormData.reportLabel.length > 8 && dialogFormData.reportLabel.substr(0, 9) != "Reporter-") {
                dialogFormData.reportLabel = "Reporter-" + dialogFormData.reportLabel;
            } else if (dialogFormData.reportLabel && dialogFormData.reportLabel.length <= 8) {
                dialogFormData.reportLabel = "Reporter-" + dialogFormData.reportLabel;
            }

            dialogFormData.userId = this.props.userId;
            dialogFormData.clientAlias = this.props.clientgroup;

            this.props.saveRfLabelsInfo(dialogFormData);//{dialog.Object}.submit('Save_Only');
            return false;
        }
    }

    handleOnFocus = (event, index, alias) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        if (alias) {
            switch (alias) {
                case "selectedRfLabelsReportMvRowIndex":
                    this.setState({ selectedRfLabelsReportMvRowIndex: index });
                    break;
                case "selectedRfLabelsSelectMvRowIndex":
                    this.setState({ selectedRfLabelsSelectMvRowIndex: index });
                    break;
            }
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });

            // load all related infos after clicked
            if (event.target.name === "reportLabel") {
                var label = event.target.value;
                var _this = this;
                //let url = this.props.clientgroup + "/CheckRfLabelsHasExisted?label=" + label;

                let url = this.props.clientgroup + "/CheckRfLabelsHasExisted";
                let params = { label: label, accessToken: localStorage.getItem('token') };

                axios.post(url, params)
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        if (!res.data.error) {
                            setTimeout(function () {
                                _this.Get_Data(label);
                            }, 0);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;

                    });
                return false;
            }

        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    async Get_Data(rfLabel) {
        var _this = this;
        //Labels
        await _this.props.selectRfLabels(rfLabel);

        if (_this.props.selectedRfLabelsPopInfo) {
            setTimeout(function () {
                _this.setState({
                    reload: true,

                    // RfLabelsReportMv
                    rfLabelsReportMv: null,
                    rfLabelsReportMvItemFields: null,
                    rfLabelsReportMvLoading: false,
                    selectedRfLabelsReportMvRowIndex: 0,
                    rfLabelsReportMvGridFocusing: false,

                    // RfLabelsSelectMv
                    rfLabelsSelectMv: null,
                    rfLabelsSelectMvItemFields: null,
                    rfLabelsSelectMvLoading: false,
                    selectedRfLabelsSelectMvRowIndex: 0,
                    rfLabelsSelectMvGridFocusing: false,

                    isShowConfirmDeleteMsg: false,
                    isShowConfirmDeleteLabelMsg: false,
                    confirmed: null,
                    monitoring: null,

                    rfLabelsReportMvMaxlengthInput: null,
                    rfLabelsSelectMvMaxlengthInput: null,
                });
            }, 1000);
        }        

        return false;
    }

    User_Defined_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        /*
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== ""
            && event.target.name === "hospitalCode") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }
        */

        //
        if (event.target.name === "reportLabel") {
            var _this = this;
            var label = event.target.value;

            var _labelOrg = this.state.initialDialogForm["reportLabel"];

            //
            //let url = this.props.clientgroup + "/CheckRfLabelsHasExisted?label=" + label;
            let url = this.props.clientgroup + "/CheckRfLabelsHasExisted";
            let params = { label: label, accessToken: localStorage.getItem('token') };

            axios.post(url, params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && _labelOrg != label) {
                        setTimeout(function () {
                            _this.Get_Data(label);
                        }, 0);
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
            return false;

        } else if (event.target.name === "requestedBy" && event.target.value === "") {
        } else if (event.target.name === "requestDate") {
            //Check_Date_Format
            var fName = event.target.name;
            let thisValue = event.target.value;
            let thisDate = this.User_Defined_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [fName]: thisDate,
                    }
                });
            }
        }
    }
    // --END
        
    //Delete_Report_Label - call to server
    deleteLabel() {
        var dialogFormData = this.state.dialogForm;
        if (!dialogFormData.reportLabel || dialogFormData.reportLabel == "") {
            return false;
        }
        this.props.deleteReportLabel(dialogFormData);//alert('Deletion Complete');

        // clear this flag to refresh page after delete label successful
        this.setState({
            reload: true,

            rfLabelsReportMv: null,
            rfLabelsReportMvItemFields: null,
            rfLabelsReportMvLoading: false,

            rfLabelsSelectMv: null,
            rfLabelsSelectMvItemFields: null,
            rfLabelsSelectMvLoading: false,

            // RfLabelsReportMv
            rfLabelsReportMv: null,
            rfLabelsReportMvItemFields: null,
            rfLabelsReportMvLoading: false,
            selectedRfLabelsReportMvRowIndex: 0,
            rfLabelsReportMvGridFocusing: false,

            // RfLabelsSelectMv
            rfLabelsSelectMv: null,
            rfLabelsSelectMvItemFields: null,
            rfLabelsSelectMvLoading: false,
            selectedRfLabelsSelectMvRowIndex: 0,
            rfLabelsSelectMvGridFocusing: false,

            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,

            rfLabelsReportMvMaxlengthInput: null,
            rfLabelsSelectMvMaxlengthInput: null,
        });

        return false;
    }
    //Save_Run - call to server
    Save_Run() {
        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.reportLabel == "") {
            return false;
        }

        let rfLabelsReportMv = this.state.rfLabelsReportMv;
        let rfLabelsSelectMv = this.state.rfLabelsSelectMv;

        dialogFormData.rfLabelsReportMv = rfLabelsReportMv;
        dialogFormData.rfLabelsSelectMv = rfLabelsSelectMv;
        dialogFormData.actionType = "Save_Run";//->Run_Report_File
        dialogFormData.reportingDevice = this.state.selectedCaseOption ? this.state.selectedCaseOption : "Screen";

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        if (dialogFormData.reportLabel && dialogFormData.reportLabel.length > 8 && dialogFormData.reportLabel.substr(0, 9) != "Reporter-") {
            dialogFormData.reportLabel = "Reporter-" + dialogFormData.reportLabel;
        } else if (dialogFormData.reportLabel && dialogFormData.reportLabel.length <= 8) {
            dialogFormData.reportLabel = "Reporter-" + dialogFormData.reportLabel;
        }

        //
        this.props.saveRfLabelsInfo(dialogFormData);//{dialog.Object}.submit('Save_Run');

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    deleteLabelFnc(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: true });
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.reportLabel == "") {
            return false;
        }

        var _this = this;
        setTimeout(function () {
            _this.Save_Run();
            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });

            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.Get_Data("");
        return false;
    }

    downloadLayoutFile = (e, flg) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        if (flg == "csv") {
            //Send CSV Layout to an Iframe to initiate download
            var LayoutURL = this.state.Fn_CSV_Layout_URL;//{ dialog.Object }.getValue('Fn_CSV_Layout_URL');
            var downloadType = LayoutURL.substr(-3, 3);

            if (downloadType == "csv") {
                //var ifr = '{dialog.ComponentName}.V.R1.IFRAME_CSV_DOWNLOAD';
                //$(ifr).src = LayoutURL;

                this.downloadFileLayout(this.props.selectedRfLabelsPopInfo, "File CSV");
            }
        } else if (flg == "fwd") {
            //Download_txt_Layout
            this.downloadFileLayout(this.props.selectedRfLabelsPopInfo, "File Fixed-Width");
        }
        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError && this.state.dialogForm && this.state.dialogForm.actionType !== "Save_Run" ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't update this label.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmDeleteLabelMsg;
        {
            confirmDeleteLabelMsg = this.state.isShowConfirmDeleteLabelMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteLabelMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to Delete this Label?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let drawingHtmlTable;
        {
            drawingHtmlTable = this.state.isDrawingHtmlTable && this.state.Fn_Html_Display_URL ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargerWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Report for {this.state.dialogForm && this.state.dialogForm.tableName ? this.state.dialogForm.tableName : ""}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable" style={{ overflowY: this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <div id="useForPrintHtml" className={this.state.publicFolder && this.state.publicFolder != "" ? "_hidden" : ""}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.Fn_Html_Display_URL }} className="" />
                                </div>

                                <iframe src={this.state.publicFolder ? (this.state.publicFolder + "?alias=" + this.props.clientgroup) : this.state.Fn_Html_Display_URL} className={this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "hidden"} />

                                {
                                    /*
                                    this.state.Fn_Display && this.state.Fn_Display.indexOf("Sorry! Can't load this output file, it's too large.") != -1 ?
                                        <a href={this.state.Fn_Display_URL} target="_blank" rel="noreferrer" onClick={(e) => { this.showOutputFile(e) }}>Click here to open the output file in the new window.</a>
                                        : ""
                                    */
                                }
                            </div>

                            <div className="emptyLine"></div>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideDrawingHtmlTable} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded ?
            (
                <div className="container-fluid2">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveRfLabelsInfo} id="reportFileForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="_frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset>
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="row">
                                                    <div className="col-sm-7">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="300px"
                                                            labelText="Label" autoFocus
                                                            field="reportLabel" lineStyle="oneLine" keyId="reportLabel0"
                                                            value={this.state.dialogForm.reportLabel}
                                                            dataSource="RF_Labels"
                                                            titleDialog="" labelWidth="95px"
                                                            inValid={this.state.uAllItemFields["reportLabel"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reportLabel"] : ""}
                                                            lostfocused={this.handleLostFocus} shownType="dropdown" rowIndex="0"
                                                        />
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine" labelWidth="90px"
                                                            changed={this.handleFormChange} labelText="Requested By" width="130px"
                                                            field="requestedBy" value={this.state.dialogForm.requestedBy ? this.state.dialogForm.requestedBy : "CRStar User"} itemNbr=""
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["requestedBy"] : ""}
                                                            inValid={this.state.uAllItemFields["requestedBy"]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-7">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="200px"
                                                            labelText="Table Name"
                                                            field="tableName" lineStyle="oneLine" keyId="tableName0"
                                                            value={this.state.dialogForm.tableName}
                                                            dataSource="ReportFile_TableName"
                                                            titleDialog="" labelWidth="80px"
                                                            inValid={this.state.uAllItemFields["tableName"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tableName"] : ""}
                                                            lostfocused={this.handleLostFocus} shownType="dropdown"
                                                            displayingCodeOnly={true} rowIndex="0"
                                                            limitByWidth={true} hideFilter={true}
                                                        />
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Request Date" field="requestDate"
                                                            value={this.state.dialogForm.requestDate}
                                                            maxLength="10" labelWidth="90px" width="85px"
                                                            lineStyle="oneLine" itemNbr="" labelStyle=""
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy"
                                                            inValid={this.state.uAllItemFields["requestDate"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label>Reporting Device</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Excel"}
                                                                        name="outputDevice" field="outputDevice" value="Excel"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Excel</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "File CSV"}
                                                                        name="outputDevice" field="outputDevice" value="File CSV" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                File CSV</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "File Fixed-Width"}
                                                                        name="outputDevice" field="outputDevice" value="File Fixed-Width" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                File Fixed-Width</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Screen"}
                                                                        name="outputDevice" field="outputDevice" value="Screen" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Screen</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Xml"}
                                                                        name="outputDevice" field="outputDevice" value="Xml" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Xml</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="row">
                                                    <div className="col-sm-12">&nbsp;</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">&nbsp;</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <button id="BUTTON_CSV_LAYOUT_DOWNLOAD" type="button"
                                                            className={this.state.selectedCaseOption === "File CSV" && this.state.Fn_CSV_Layout_URL && this.state.Fn_CSV_Layout_URL != "" ? "ScreenButton" : "hidden"}
                                                            onClick={(e) => { this.downloadLayoutFile(e, "csv") }}>
                                                            <img id="IMAGE_CSV_LAYOUT_DOWNLOAD" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" className="" />
                                                            Download Layout</button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <button id="BUTTON_FIXEDWIDTH_LAYOUT_DOWNLOAD" type="button"
                                                            className={this.state.selectedCaseOption === "File Fixed-Width" && this.state.Fn_FixedW_Layout_Path && this.state.Fn_FixedW_Layout_Path != "" ? "ScreenButton" : "hidden"}
                                                            onClick={(e) => { this.downloadLayoutFile(e, "fwd") }}>
                                                            <img id="IMAGE_CSV_LAYOUT_DOWNLOAD" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" className="" />
                                                            Download Layout</button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div style={{ display: "flex" }}>
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <div>
                                                            <table className="RF_Labels_Select_Mv">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "205px" }}>Selection Attribute</th>
                                                                        <th style={{ width: "80px" }}>Relation</th>
                                                                        <th style={{ width: "190px" }}>Data Values</th>
                                                                        <th style={{ width: "auto" }}>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                        </div>
                                                        <div id="rfLabelsReportMvListArea" className="rfLabelsSelectMvListArea">
                                                            <div className="rfLabelsSelectMvChildrenArea innerBody" style={{ height: "93px", overflow: "auto" }}>
                                                                <table className="RF_Labels_Select_Mv">
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                            <th style={{ width: "205px" }}>Selection Attribute</th>
                                                                            <th style={{ width: "80px" }}>Relation</th>
                                                                            <th style={{ width: "190px" }}>Data Values</th>
                                                                            <th style={{ width: "auto" }}>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.rfLabelsSelectMvItemFields
                                                                            && this.state.rfLabelsSelectMv !== null && this.state.rfLabelsSelectMv.length > 0
                                                                            ? this.state.rfLabelsSelectMv.map((mv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionRfLabelsSelectMvRow(e, index) }} className={this.state.selectedRfLabelsSelectMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                    <td style={{ width: "18px" }}>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedRfLabelsSelectMvRowIndex") }}
                                                                                            changed={(e) => { this.handleRfLabelsSelectMvGridChange(e, index) }}
                                                                                            labelText="" field="selectColumn"
                                                                                            value={mv.selectColumn} lineStyle="oneLine"
                                                                                            dataSource="View_Columns" flag={this.state.dialogForm.tableName} titleDialog=""
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.rfLabelsSelectMvItemFields[index]["selectColumn"]}
                                                                                            maxLength={this.state.rfLabelsSelectMvMaxlengthInput ? this.state.rfLabelsSelectMvMaxlengthInput["selectColumn"] : ""}
                                                                                            lostfocused={(e) => { this.handleRfLabelsSelectMvLostfocus(e, index) }}
                                                                                            keyId={"selectColumn" + index} //flag={this.state.dialogForm.tableName}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                            disabled={!this.state.dialogForm.tableName || this.state.dialogForm.tableName == ""}
                                                                                            displayingCodeOnly={true}
                                                                                            //limitByWidth={true}
                                                                                            hideFilter={true}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedRfLabelsSelectMvRowIndex") }}
                                                                                            changed={(e) => { this.handleRfLabelsSelectMvGridChange(e, index) }}
                                                                                            labelText="" field="relation"
                                                                                            value={mv.relation} lineStyle="oneLine" aliasNo="2"//search: Starts with
                                                                                            dataSource="Lookup_Mv" titleDialog=""//filter: Item_Nbr = 2, order: Code_Desc
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.rfLabelsSelectMvItemFields[index]["relation"]}
                                                                                            maxLength={this.state.rfLabelsSelectMvMaxlengthInput ? this.state.rfLabelsSelectMvMaxlengthInput["relation"] : ""}
                                                                                            lostfocused={(e) => { this.handleRfLabelsSelectMvLostfocus(e, index) }}
                                                                                            keyId={"relation" + index}
                                                                                            rowIndex={index} shownType="dropdown" codeLabel="Relation"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedRfLabelsSelectMvRowIndex") }}
                                                                                            changed={(e) => { this.handleRfLabelsSelectMvGridChange(e, index) }}
                                                                                            labelText="" field="dataValues"
                                                                                            value={mv.dataValues} lineStyle="oneLine"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.rfLabelsSelectMvItemFields[index]["dataValues"]}
                                                                                            maxLength={this.state.rfLabelsSelectMvMaxlengthInput ? this.state.rfLabelsSelectMvMaxlengthInput["dataValues"] : ""}
                                                                                            lostfocused={(e) => { this.handleRfLabelsSelectMvLostfocus(e, index) }}
                                                                                            keyId={"dataValues" + index}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="copyRowBtnOnGrid"
                                                                                            style={{ width: "40px", top: "0px", position: "relative", padding: "1px" }}
                                                                                            id={"copyRowBtnOnGrid" + index} name={"copyRowBtnOnGrid" + index}
                                                                                            onKeyDown={this.keydownHandler} data-index={index}
                                                                                            //onClick={(event) => { this.copyRowRfLabelsSelectMvGrid(event, index) }}>Copy</button>
                                                                                            onClick={(event) => { this.insertNewRfLabelsSelectMvGrid(event, index) }}>Insert</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="5">No records available</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button id="btnAddNewLatestRfLabelsSelectMv" onClick={this.addNewRfLabelsSelectMv}>Add</button>
                                                                <button id="btnDeleteSelectedRfLabelsSelectMv" onClick={this.deleteSelectedRfLabelsSelectMv}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6"></div>
                                        </div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div style={{ display: "flex" }}>
                                                    <div className="frameInner" style={{ width: "790px" }}>
                                                        <div className="header">&nbsp;</div>
                                                        <div>
                                                            <table className="RF_Labels_Report_Mv">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "200px" }}>Reporting Attribute</th>
                                                                        <th style={{ width: "100px" }}>Display Length</th>
                                                                        <th style={{ width: "80px" }}>B/S/L</th>
                                                                        <th style={{ width: "auto" }}>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                        </div>
                                                        <div id="rfLabelsSelectMvListArea" className="rfLabelsReportMvListArea">
                                                            <div className="rfLabelsReportMvChildrenArea innerBody" style={{ height: "142px", overflowY: "auto", overflowX: "hidden" }}>
                                                                <table className="RF_Labels_Report_Mv">
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                            <th style={{ width: "200px" }}>Reporting Attribute</th>
                                                                            <th style={{ width: "100px" }}>Display Length</th>
                                                                            <th style={{ width: "80px" }}>B/S/L</th>
                                                                            <th style={{ width: "auto" }}>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.rfLabelsReportMvItemFields
                                                                            && this.state.rfLabelsReportMv !== null && this.state.rfLabelsReportMv.length > 0
                                                                            ? this.state.rfLabelsReportMv.map((mv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionRfLabelsReportMvRow(e, index) }} className={this.state.selectedRfLabelsReportMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                    <td style={{ width: "18px" }}>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedRfLabelsReportMvRowIndex") }}
                                                                                            changed={(e) => { this.handleRfLabelsReportMvGridChange(e, index) }}
                                                                                            labelText="" field="reportColumn"
                                                                                            value={mv.reportColumn} lineStyle="oneLine"
                                                                                            dataSource="View_Columns" flag={this.state.dialogForm.tableName} titleDialog=""
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.rfLabelsReportMvItemFields[index]["reportColumn"]}
                                                                                            maxLength={this.state.rfLabelsReportMvMaxlengthInput ? this.state.rfLabelsReportMvMaxlengthInput["reportColumn"] : ""}
                                                                                            lostfocused={(e) => { this.handleRfLabelsReportMvLostfocus(e, index) }}
                                                                                            keyId={"reportColumn" + index} //flag={this.state.dialogForm.tableName}
                                                                                            //focusingField={this.state.focusingField}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                            disabled={!this.state.dialogForm.tableName || this.state.dialogForm.tableName == ""}
                                                                                            displayingCodeOnly={true}
                                                                                            limitByWidth={true} hideFilter={true}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedRfLabelsReportMvRowIndex") }}
                                                                                            changed={(e) => { this.handleRfLabelsReportMvGridChange(e, index) }}
                                                                                            labelText="" field="displayLength"
                                                                                            value={mv.displayLength} lineStyle="oneLine"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.rfLabelsReportMvItemFields[index]["displayLength"]}
                                                                                            maxLength={this.state.rfLabelsReportMvMaxlengthInput ? this.state.rfLabelsReportMvMaxlengthInput["displayLength"] : ""}
                                                                                            lostfocused={(e) => { this.handleRfLabelsReportMvLostfocus(e, index) }}
                                                                                            keyId={"displayLength" + index}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedRfLabelsReportMvRowIndex") }}
                                                                                            changed={(e) => { this.handleRfLabelsReportMvGridChange(e, index) }}
                                                                                            labelText="" field="breakSortList"
                                                                                            value={mv.breakSortList} lineStyle="oneLine"
                                                                                            dataSource="Lookup_Mv" titleDialog="" aliasNo="3"//search: Starts with
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.rfLabelsReportMvItemFields[index]["breakSortList"]}
                                                                                            maxLength={this.state.rfLabelsReportMvMaxlengthInput ? this.state.rfLabelsReportMvMaxlengthInput["breakSortList"] : ""}
                                                                                            lostfocused={(e) => { this.handleRfLabelsReportMvLostfocus(e, index) }}
                                                                                            keyId={"breakSortList" + index}
                                                                                            rowIndex={index} shownType="dropdown" codeLabel="Type"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="copyRowBtnOnGrid2"
                                                                                            style={{ width: "40px", top: "0px", position: "relative", padding: "1px" }}
                                                                                            id={"copyRowBtnOnGrid2" + index} name={"copyRowBtnOnGrid2" + index}
                                                                                            onKeyDown={this.keydownHandler} data-index={index}
                                                                                            //onClick={(event) => { this.copyRowRfLabelsReportMvGrid(event, index) }}>Copy</button>
                                                                                            onClick={(event) => { this.insertNewRfLabelsReportMvGrid(event, index) }}>Insert</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="5">No records available</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button id="btnAddNewLatestRfLabelsReportMv" onClick={this.addNewRfLabelsReportMv}>Add</button>
                                                                <button id="btnDeleteSelectedRfLabelsReportMv" onClick={this.deleteSelectedRfLabelsReportMv}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>
                                    </fieldset>

                                    <div className="emptyLine"></div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Processed" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>

                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN_REPORT"
                                                        className={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        disabled={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_RUN_REPORT_ICON"
                                                            src={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={(this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" || this.state.isFormDirty) ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.newRecord(e) }}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={(this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" || this.state.isFormDirty) ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_DELETE"
                                                        className={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.deleteLabelFnc(e) }}
                                                        disabled={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_DELETE_ICON"
                                                            src={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? "https://crstar.ers-can.com/images/$$edit.delete.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$edit.delete.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Delete
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_SUBMIT"
                                                        className={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" && this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.saveRfLabelsInfo(e) }}
                                                        disabled={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" && this.state.isFormDirty ? false : true}
                                                    >
                                                        <img id="BUTTON_SUBMIT_ICON"
                                                            src={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" && this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$file.save.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$file.save.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            )
            : <p className="">Report For A File data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteLabelMsg}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>

                <React.Fragment>
                    {drawingHtmlTable}
                </React.Fragment>

            </React.Fragment>
        );
    }
}

ReportFile.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedRfLabelsPopInfo: state.patientAbstract.selectedRfLabelsPopInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveRfLabelsInfo: (dialogForm) => dispatch(actionCreators.saveRfLabels(dialogForm)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectRfLabels: (label) => dispatch(actionCreators.selectRfLabels(label)),
        deleteReportLabel: (dialogForm) => dispatch(actionCreators.deleteRfLabels(dialogForm)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportFile);