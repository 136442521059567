/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import "./UserMaintenance.css";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "../../../components/AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance.css";
import Help from "../../UI/Help";
import axios from "../../../axios-instance";
//import { Dialog } from "@progress/kendo-react-dialogs";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import Draggable from 'react-draggable';

class ChangePwdDialog extends Component {

    state = {
        uAllItemFields: [],
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        loaded: false,
        fieldInFocus: "passwordNet",
        isHelpVisible: false,
        isSaving: false,
        showingTestResult: false,
        testMessage: null,
        globalMsg: "New Password and Confirm Password do not match.",
        passwordNetDb: null
    }

    componentDidMount() {
        if (!this.state.dialogForm && !this.state.loaded) {
            axios.post("ers/LoadDataForUser", { email: this.props.userId, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ loaded: true });
                        this.setFormData(res.data.userInfo);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.state.dialogForm && !this.state.loaded) {
            axios.post("ers/LoadDataForUser", { email: this.props.userId, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ loaded: true });
                        this.setFormData(res.data.userInfo);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });
        }

        if (this.state.isFormDirty && this.state.isSaving && prevProps.isFormDirty != this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }

        if (this.props.error && !this.state.showingTestResult && this.state.isSaving) {
            this.setState({
                showingTestResult: true,
                testMessage: this.props.errorMessage,
                isSaving: false,
            });

            this.props.setChildrenOpening(true);//not allow parent popup is draggable
        }

        if (!this.props.error && this.props.selectedUserInfo != null
            && prevProps.selectedUserInfo != this.props.selectedUserInfo) {
            this.props.onSetAuthRedirectPath('/patient-abstract');
            this.props.setAlphaUrl('/patient-abstract');
            this.props.setDialog(dialogTypes.LOOKUP);

            //this.props.closeOtherTabItems();
            this.props.setPatientMenu(true);
            this.props.setPageLoaded(false);
            this.props.setDialog(dialogTypes.LOOKUP);
        }
    }

    // 
    clearFormData = (event) => {
        event.preventDefault();
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                passwordNetDb: "",
                passwordNet: "",
                passwordNetConfirm: "",
            }
        });
        return false;
    }

    setFormData = (selectedUserInfo) => {
        this.setState({
            initialDialogForm: { ...selectedUserInfo },
            dialogForm: {
                ...selectedUserInfo,
                passwordNetDb: "",
                passwordNet: "",
                passwordNetConfirm: "",
            },
            passwordNetDb: selectedUserInfo.passwordNetDb
        });

        if (!this.state.uAllItemFields && selectedUserInfo) {
            let fields = {};
            Object.keys(selectedUserInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields });
        };
        return false;
    }

    hideTestResult = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingTestResult: false, testMessage: null });
        this.props.clearErrorMessage();

        this.props.setChildrenOpening(false);

        let fieldInFocus = this.state.fieldInFocus;
        setTimeout(function () {
            document.getElementById(fieldInFocus) ? document.getElementById(fieldInFocus).focus() : "";
        }, 100);

        return false;
    }

    savePassword = (event) => {
        event.preventDefault();

        this.setState({ showingTestResult: false, testMessage: null });

        let dialogFormData = this.state.dialogForm;

        // TODO - check form validation
        let isValid = true;

        const expired = this.props.passExpired;

        Object.keys(dialogFormData).map((t, k) => {
            if (expired && t == "passwordNetDb" && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "passwordNet" || t === "PasswordNet" || t === "passwordnet")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "passwordNetConfirm" || t === "PasswordNetConfirm" || t === "passwordnetconfirm")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            //
            if (dialogFormData["passwordNet"] !== "" && dialogFormData["passwordNetConfirm"] !== ""
                && dialogFormData["passwordNetConfirm"] !== dialogFormData["passwordNet"]) {
                const _t = "passwordNetConfirm";
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_t]: "IN_VALID" },
                });
                isValid = false;
            }
        });

        /*
        const formItems = this.state.uAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });
        */

        if (!isValid) {
            return false;
        } else {
            var isValidPwdByPattern = true;
            // Finally, check by password validation pattern with message
            const patternTest = dialogFormData.passwordNet;
            var confirmMsg = "";

            var passValidation = dialogFormData.passValidation;
            var passValidationMsg = dialogFormData.passValidationMsg;

            const regex = new RegExp(passValidation, 'g');
            //var combineRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            let m;

            if ((m = regex.exec(patternTest)) !== null) {
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    console.log(`Found match, group ${groupIndex}: ${match}`);
                });
                isValidPwdByPattern = true;
            } else {
                isValidPwdByPattern = false;
                confirmMsg = passValidationMsg;
            }

            if (!isValidPwdByPattern) {
                this.setState({
                    showingTestResult: true,
                    testMessage: confirmMsg
                });

                this.props.setChildrenOpening(true);//not allow parent popup is draggable
                return false;
            } else {
                dialogFormData["email"] = dialogFormData.Userid ? dialogFormData.Userid : dialogFormData.userid;
                dialogFormData["password"] = this.state.initialDialogForm.passwordNet;
                dialogFormData["passwordNetDb"] = this.state.passwordNetDb;

                this.props.changePassword(dialogFormData);

                this.setState({
                    isSaving: true,
                    //showingTestResult: true,//set this to avoid show error message more again!!!
                });
            }
        }
    }

    resetDataForm = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            loaded: false
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }, isFormDirty: true });
        }
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    render() {
        let resultTest;
        {
            resultTest = this.state.showingTestResult && this.state.testMessage !== null ?
                /*
                <Dialog title="Warning"
                    onClose={this.hideTestResult}
                    width={300} height={"auto"}>
				*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTestResult(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="container">
                                    <p>{this.state.testMessage}</p>
                                </div>
                                <div className="container center">
                                    <p>
                                        <button onClick={this.hideTestResult} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.savePassword} id="securityChangePwdForm" className="DialogForm2">
                        <div className="emptyLine"></div>

                        <div style={{ display: "none" }}>
                            <input type="text" name="username" value="" />
                            <input type="password" name="password" value="" />
                        </div>

                        <div className="row0">
                            <div className="col0-sm-12" style={{ marginRight: "15px" }}>
                                <div className="frameInner">
                                    <div className="header">Security Info</div>
                                    <fieldset>
                                        <legend></legend>
                                        <div className="col-sm-12" style={{ display: (this.props.passExpired ? '' : 'none') }}>
                                            <Input type="password" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="Original Password" field="passwordNetDb"
                                                value={this.state.dialogForm.passwordNetDb}
                                                isRequired
                                                autoFocus={this.props.passExpired}
                                                inValid={this.state.uAllItemFields["passwordNetDb"]}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Input type="password" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="New Password" field="passwordNet"
                                                value={this.state.dialogForm.passwordNet}
                                                isRequired
                                                autoFocus={!this.props.passExpired}
                                                inValid={this.state.uAllItemFields["passwordNet"]}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Input type="password" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="Confirm Password" field="passwordNetConfirm"
                                                value={this.state.dialogForm.passwordNetConfirm}
                                                isRequired
                                                inValid={this.state.uAllItemFields["passwordNetConfirm"]}
                                                globalMsg={this.state.globalMsg}
                                                //alertTitle=""
                                                replacementVal={this.state.dialogForm.passwordNetConfirm}
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                        <div className="Footer2 center" style={{ bottom: "0", position: "absolute", width: "100%" }}>
                            <button type="submit">Change</button>
                            <button type="button" id="btnClearChangePwdForm" onClick={this.clearFormData}>Clear</button>
                        </div>

                    </form>

                    <div className="emptyLine"></div>

                    <div className="hidden">
                        <Footer closeWindow={this.props.closeWindow}
                            refreshForm={this.resetDataForm}
                            formId="securityChangePwdForm"
                            isFormDirty={this.state.isFormDirty}
                            className="Footer2"
                            isPromptPopup={true}
                            //ignoreExit={true}
                            exitText="Clear"
                            saveText="Change"
                        />
                    </div>
                </div>
            )
            : <p>Loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {resultTest}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

ChangePwdDialog.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
    //email: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        isDialogLoading: state.patientAbstract.isDialogLoading,
        //
        passExpired: state.auth.passExpired,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        selectedUserInfo: state.patientAbstract.selectedUserInfo,
        //
        clientgroup: state.auth.clientgroup,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (dialogForm) => dispatch(actionCreators.changePassword(dialogForm)),
        setChangePwdDialogVisible: (flg) => dispatch(actionCreators.setChangePwdDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),

        //
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (flg) => dispatch(actionCreators.setChildrenOpening(flg)),

        //        
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),        
        setTwoFactorAuthDialogVisible: (flg) => dispatch(actionCreators.setTwoFactorAuthDialogVisible(flg)),
        setTfaPopupTitle: (title, form) => dispatch(actionCreators.setTfaPopupTitle(title, form)),
        showingHelpInfoFunc: (value) => dispatch(actionCreators.showingHelpInfo(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePwdDialog);