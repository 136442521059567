/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./CaseAdminMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class CaseAdminMaintenance extends Component {

    // At Case Admin page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'caseAdminForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty caseAdminForm back to what it was on load
        initialcaseAdminForm: null,
        caseAdminForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "dateAbst",
        isHelpVisible: false,
        isDialogVisible: true,
        focusingField: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        setEnabledFields: false,

        //Registrars -> Initials
        userInitials: "",
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = () => {
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentWillUnmount() {
        this.setState({ setEnabledFields: false });
    }

    componentDidMount() {
        /*
        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            var _this = this;
            axios.get(this.props.clientgroup + "/GetUserInitials?uid=" + logedOnUser)
                .then(res => {
                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                            _this.setState({ userInitials: userInitials });
                        }
                    }
                });
        }
        */

        if (!this.state.caseAdminForm && this.props.selectedCaseAdminRecord) {
            this.setFormData();
        }

        //
        if (!this.state.setEnabledFields) {
            //
            var sunaFlag = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.abstSunaFlag : "";
            if (this.props.securityGroup && this.props.securityGroup.indexOf("Admin") == -1 && sunaFlag == 'A') {
                var _this = this;
                if (document.getElementById("dateAbst") != null
                    && document.getElementById("abstInit") != null
                    && document.getElementById("rqrsCase") != null) {
                    setTimeout(function () {
                        document.getElementById("dateAbst") ? document.getElementById("dateAbst").setAttribute('disabled', true) : "";

                        document.getElementById("abstInit") ? document.getElementById("abstInit").setAttribute('disabled', true) : "";
                        document.getElementById("abstInit") && document.getElementById("abstInit").nextSibling ? document.getElementById("abstInit").nextSibling.setAttribute('disabled', true) : "";

                        document.getElementById("rqrsCase") ? document.getElementById("rqrsCase").focus() : "";

                        _this.setState({ setEnabledFields: true });
                        return false;
                    }, 1500);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        /*
        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            var _this = this;
            axios.get(this.props.clientgroup + "/GetUserInitials?uid=" + logedOnUser)
                .then(res => {
                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                            _this.setState({ userInitials: userInitials });
                        }
                    }
                });
        }
        */

        if ((!this.state.caseAdminForm && this.props.selectedCaseAdminRecord)
            || (prevProps.selectedCaseAdminRecord && prevProps.selectedCaseAdminRecord !== this.props.selectedCaseAdminRecord)
            || (this.props.initialAbstractPages && this.props.selectedCaseAdminRecord)
        ) {
            this.setFormData();
        }

        if (!this.state.setEnabledFields) {
            //
            var sunaFlag = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.abstSunaFlag : "";
            if (this.props.securityGroup && this.props.securityGroup.indexOf("Admin") == -1 && sunaFlag == 'A') {
                var _this = this;
                if (document.getElementById("dateAbst") != null
                    && document.getElementById("abstInit") != null
                    && document.getElementById("rqrsCase") != null) {
                    setTimeout(function () {
                        document.getElementById("dateAbst") ? document.getElementById("dateAbst").setAttribute('disabled', true) : "";

                        document.getElementById("abstInit") ? document.getElementById("abstInit").setAttribute('disabled', true) : "";
                        document.getElementById("abstInit") && document.getElementById("abstInit").nextSibling ? document.getElementById("abstInit").nextSibling.setAttribute('disabled', true) : "";

                        document.getElementById("rqrsCase") ? document.getElementById("rqrsCase").focus() : "";

                        _this.setState({ setEnabledFields: true });
                        return false;
                    }, 1500);
                }
            }
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.caseAdminForm && this.state.sAllItemFields
        ) {
            console.log("[CaseAdmin] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    caseAdminForm: { ...this.state.caseAdminForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    caseAdminForm: { ...this.state.caseAdminForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Case_Admin", accessToken: localStorage.getItem('token')})
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    setFormData = () => {
        /*
        var sunaFlag = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.abstSunaFlag : "";

        var abstInit = this.props.selectedCaseAdminRecord !== null && this.props.selectedCaseAdminRecord.abstInit !== null ? this.props.selectedCaseAdminRecord.abstInit : "";
        var userInitials = this.state.userInitials && this.state.userInitials !== "" ? this.state.userInitials : "";

        var dirtyForm = false;
        if (sunaFlag === 'A' && userInitials !== "" && abstInit === "" && !this.props.inquiringMode) {
            abstInit = userInitials;
            dirtyForm = true;
        }

        var dateAbst = this.props.selectedCaseAdminRecord != null && this.props.selectedCaseAdminRecord.dateAbst !== null && this.props.selectedCaseAdminRecord.dateAbst !== "" ? moment(new Date(this.props.selectedCaseAdminRecord.dateAbst)).format("MM/DD/YYYY") : "";
        if (sunaFlag === 'A' && dateAbst === "" && !this.props.inquiringMode) {
            dateAbst = moment(new Date()).format("MM/DD/YYYY");
            dirtyForm = true;
        }

        this.setState({
            caseAdminForm: {
                ...this.props.selectedCaseAdminRecord,
                ["dateAbst"]: dateAbst,
                ["abstInit"]: abstInit,
            },
            isFormDirty: dirtyForm || this.props.isFormDirty
        });
        */

        // Initial and set form dirty
        var formDirty = this.props.selectedCaseAdminRecord && this.props.selectedCaseAdminRecord.formDirty && this.props.selectedCaseAdminRecord.formDirty == "1" ? true : false;

        var dateAbst = this.props.selectedCaseAdminRecord && this.props.selectedCaseAdminRecord.dateAbst !== null && this.props.selectedCaseAdminRecord.dateAbst !== "" ? moment(new Date(this.props.selectedCaseAdminRecord.dateAbst)).format("MM/DD/YYYY") : "";
        var abstInit = this.props.selectedCaseAdminRecord && this.props.selectedCaseAdminRecord.abstInit !== null ? this.props.selectedCaseAdminRecord.abstInit : "";

        if (formDirty && this.props.securityGroup && this.props.securityGroup == "Read-only user") {
            dateAbst = "";
            abstInit = "";
            formDirty = false;
        }

        this.setState({
            caseAdminForm: {
                ...this.props.selectedCaseAdminRecord,
                ["dateAbst"]: dateAbst,
                ["abstInit"]: abstInit,
            },
            isFormDirty: formDirty || this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedCaseAdminRecord) {
            let fields = {};
            Object.keys(this.props.selectedCaseAdminRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({
                sAllItemFields: fields,
                pageMaxlengthInput: fields,
            });
        }

        this.props.setInitialAbstractPages(false);

        return false;
    }

    saveCaseAdmin = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.sAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let caseAdminFormData = this.state.caseAdminForm;
            caseAdminFormData.cid = this.props.selectedMedicalRecord.cid;
            caseAdminFormData.formDirty = false;

            // Date Abstracted cannot use Unknow values so this logic is not needed
            //#20210125 - START
            // append date fields from m/d/y
            // Object.keys(caseAdminFormData).map((t, k) => {
            //     if (t === "dateAbstracted" || t === "DateAbstracted") {
            //         caseAdminFormData[t] = new Date(caseAdminFormData["dateAbstractedYear"] + "-" + caseAdminFormData["dateAbstractedMonth"] + "-" + caseAdminFormData["dateAbstractedDay"]);
            //     }
            // });
            //#20210125 - END

            this.props.saveCaseAdmin(caseAdminFormData);

            return false;
        }
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let textDialogData = this.state.textDialogForm;
            this.props.saveTextDialog(textDialogData);
        }
    }

    extraAction = (index) => {
        switch (index) {
            case 1:
                alert(1);
                break;
            case 2:
                alert(2);
                break;
            default:
                break;
        }
    }

    resetCaseAdmin = () => {
        this.setState({
            caseAdminForm: { ...this.state.initialcaseAdminForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                // clear all error style
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });

            let name = event.target.name;
            let value = event.target.value;
            if (name === "dateAbst") {
                //This code tries to fill in slashes and the two digit century when a user doesn't enter them
                var myDate = value;
                var fmtDate = "";

                if (myDate.length == 8 && myDate.indexOf('/') == -1) {
                    fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
                } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
                    var d = new Date().toLocaleDateString();
                    if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                        fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
                    } else {
                        fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
                    }
                } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
                    var d = new Date().toLocaleDateString();
                    if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                        fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
                    } else {
                        fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
                    }
                } else {
                    fmtDate = myDate;
                }

                if (myDate != fmtDate) {
                    this.setState({ caseAdminForm: { ...this.state.caseAdminForm, [name]: fmtDate } });
                }
            }
        }
    }
    // --END

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                caseAdminForm: {
                    ...this.state.caseAdminForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true
            });
        } else {
            this.setState({
                caseAdminForm: {
                    ...this.state.caseAdminForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true
            });
        }
    }

    render() {
        const caseAdminMaintenance = this.state.caseAdminForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveCaseAdmin} id="caseAdminForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" /></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-5 caseAdminCol1">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date Abstracted" field="dateAbst" lineStyle="oneLine"
                                                    //value={this.state.caseAdminForm.dateAbst != null && this.state.caseAdminForm.dateAbst !== "" ? moment(new Date(this.state.caseAdminForm.dateAbst)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.caseAdminForm.dateAbst}
                                                    labelWidth="150px" width="100px"
                                                    itemNbr="" autoFocus
                                                    // Date Abstracted cannot use Unknow values so this logic is not needed
                                                    //#20210125 - START
                                                    // Add day/month/year values
                                                    // inValidDay={this.state.sAllItemFields["dateAbstractedDay"]}
                                                    // inValidMonth={this.state.sAllItemFields["dateAbstractedMonth"]}
                                                    // inValidYear={this.state.sAllItemFields["dateAbstractedYear"]}
                                                    // dayVal={this.state.caseAdminForm.dateAbstractedDay}
                                                    // monthVal={this.state.caseAdminForm.dateAbstractedMonth}
                                                    // yearVal={this.state.caseAdminForm.dateAbstractedYear}
                                                    //#20210125 - END
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date Abstracted" field="dateAbst"
                                                    value={this.state.caseAdminForm.dateAbst}
                                                    maxLength="10" aliasNo="126" labelWidth="130px" width="95px"
                                                    lineStyle="oneLine onTopFrame" itemNbr="126"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy" autoFocus
                                                />

                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Abstractor Initials" field="abstInit" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.abstInit}
                                                    dataSource="Registrars" aliasNo="142" titleDialog="Abstractor Initials" labelWidth="130px" width="50px"
                                                    inValid={this.state.sAllItemFields["abstInit"]} itemNbr="142"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["abstInit"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="RQRS Case" field="rqrsCase" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.rqrsCase}
                                                    dataSource="Global_Dir_Mv" aliasNo="3693" titleDialog="RQRS Case" labelWidth="130px" width="30px"
                                                    inValid={this.state.sAllItemFields["rqrsCase"]} itemNbr="3693"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rqrsCase"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="State Rpt" field="stateRpt" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.stateRpt}
                                                    dataSource="Global_Dir_Mv" aliasNo="200" titleDialog="State Rpt" labelWidth="130px" width="30px"
                                                    inValid={this.state.sAllItemFields["stateRpt"]} itemNbr="200"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stateRpt"] : ""}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input type="label" changed={this.handleFormChange} labelText="Date Case Initiated" field="dtCaseInitiated"
                                                    value={this.state.caseAdminForm.dtCaseInitiated != null && this.state.caseAdminForm.dtCaseInitiated !== "" ? moment(new Date(this.state.caseAdminForm.dtCaseInitiated)).format("MM/DD/YYYY") : ""}
                                                    labelWidth="130px" width="90px" itemNbr="" lineStyle="oneLine onTopFrame" />
                                                <Input type="label" changed={this.handleFormChange} labelText="Date Case Comp Coc" field="dtCaseCompCOC"
                                                    value={this.state.caseAdminForm.dtCaseCompCoc != null && this.state.caseAdminForm.dtCaseCompCoc !== "" ? moment(new Date(this.state.caseAdminForm.dtCaseCompCoc)).format("MM/DD/YYYY") : ""}
                                                    labelWidth="130px" width="90px" itemNbr="" />
                                                <Input type="label" changed={this.handleFormChange} labelText="State Report Date" field="stateRptDate"
                                                    value={this.state.caseAdminForm.stateRptDate != null && this.state.caseAdminForm.stateRptDate !== "" ? moment(new Date(this.state.caseAdminForm.stateRptDate)).format("MM/DD/YYYY") : ""}
                                                    labelWidth="130px" width="90px" itemNbr="" />
                                                <Input type="label" changed={this.handleFormChange} labelText="Last Export To State" field="lastExporttoState"
                                                    value={this.state.caseAdminForm.lastExportToState != null && this.state.caseAdminForm.lastExportToState !== "" ? moment(new Date(this.state.caseAdminForm.lastExportToState)).format("MM/DD/YYYY") : ""}
                                                    labelWidth="130px" width="90px" itemNbr="" />
                                                <Input type="label" changed={this.handleFormChange} labelText="Date Last Modified" field="dtLstMod"
                                                    value={this.state.caseAdminForm.dtLstMod != null && this.state.caseAdminForm.dtLstMod !== "" ? moment(new Date(this.state.caseAdminForm.dtLstMod)).format("MM/DD/YYYY") : ""}
                                                    labelWidth="130px" width="90px" itemNbr="" />
                                                <Input type="label" changed={this.handleFormChange} labelText="Last Modified BY" field="lstModBy"
                                                    value={this.state.caseAdminForm.lstModBy} labelWidth="130px" width="60px" itemNbr="" />
                                                <Input type="label" changed={this.handleFormChange} labelText="Archive FIN" field="archiveFIN"
                                                    value={this.state.caseAdminForm.archiveFin} labelWidth="120px" width="110px" itemNbr="" />
                                                <Input type="label" changed={this.handleFormChange} labelText="NPI Archive FIN" field="npiArchiveFIN"
                                                    value={this.state.caseAdminForm.npiArchiveFin} labelWidth="120px" width="110px" itemNbr="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 extendDiv caseAdminCol2">
                                    <div className="frameInner">
                                        <div className="header">Coding System Information</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Coc Code Sys Curr" field="cocCodeSysCurr" lineStyle="oneLine onTopFrame"
                                            value={this.state.caseAdminForm.cocCodeSysCurr} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Coc Code Sys Curr" aliasNo="2125" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["cocCodeSysCurr"]} itemNbr="2125"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocCodeSysCurr"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Coc Code Sys Orig" field="cocCodeSysOrig" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.cocCodeSysOrig} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Coc Code Sys Orig" aliasNo="2126" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["cocCodeSysOrig"]} itemNbr="2126"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocCodeSysOrig"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Race Code Sys Curr" field="raceCodeSysCurr" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.raceCodeSysCurr} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Race Code Sys Curr" aliasNo="2127" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["raceCodeSysCurr"]} itemNbr="2127"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["raceCodeSysCurr"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Race Code Sys Orig" field="raceCodeSysOrig" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.raceCodeSysOrig} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Race Code Sys Orig" aliasNo="2128" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["raceCodeSysOrig"]} itemNbr="2128"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["raceCodeSysOrig"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Site Code Sys Curr" field="siteCodeSysCurr" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.siteCodeSysCurr} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Site Code Sys Curr" aliasNo="2129" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["siteCodeSysCurr"]} itemNbr="2129"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteCodeSysCurr"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Site Code Sys Orig" field="siteCodeSysOrig" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.siteCodeSysOrig} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Site Code Sys Orig" aliasNo="2130" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["siteCodeSysOrig"]} itemNbr="2130"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteCodeSysOrig"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Morph Code Sys Curr" field="morphCodeSysCurr" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.morphCodeSysCurr} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Morph Code Sys Curr" aliasNo="2131" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["morphCodeSysCurr"]} itemNbr="2131"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["morphCodeSysCurr"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Morph Code Sys Orig" field="morphCodeSysOrig" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.morphCodeSysOrig} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Morph Code Sys Orig" aliasNo="2132" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["morphCodeSysOrig"]} itemNbr="2132"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["morphCodeSysOrig"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="ICDO2 Converson" field="icdo2ConvFlag" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.icdo2ConvFlag} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="ICDO2 Converson" aliasNo="2133" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["icdo2ConvFlag"]} itemNbr="2133"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["icdo2ConvFlag"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="ICDO3 Converson" field="icdo3ConvFlag" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.icdo3ConvFlag} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="ICDO3 Converson" aliasNo="2134" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["icdo3ConvFlag"]} itemNbr="2134"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["icdo3ConvFlag"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Rx Code System" field="rxCodeSystem" lineStyle="oneLine"
                                            value={this.state.caseAdminForm.rxCodeSystem} width="30px"
                                            dataSource="Global_Dir_Mv" titleDialog="Rx Code System" aliasNo="2135" labelWidth="130px"
                                            inValid={this.state.sAllItemFields["rxCodeSystem"]} itemNbr="2135"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rxCodeSystem"] : ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 caseAdminCol3">
                                    <div className="frameInner">
                                        <div className="header">Overrides</div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Accession Class Seq" field="ovrAcsnClassSeq" lineStyle="oneLine onTopFrame"
                                                    value={this.state.caseAdminForm.ovrAcsnClassSeq} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Accession Class Seq" aliasNo="2138" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrAcsnClassSeq"]} itemNbr="2138"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrAcsnClassSeq"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Hosp Seq DxConf" field="ovrHospseqDxconf" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrHospseqDxconf} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Hosp Seq DxConf" aliasNo="2139" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrHospseqDxconf"]} itemNbr="2139"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrHospseqDxconf"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="CoC Site Type" field="ovrCocSiteType" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrCocSiteType} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="CoC Site Type" aliasNo="2140" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrCocSiteType"]} itemNbr="2140"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrCocSiteType"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Hosp Seq Site" field="ovrHospseqSite" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrHospseqSite} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Hosp Seq Site" aliasNo="2141" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrHospseqSite"]} itemNbr="2141"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrHospseqSite"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site TMN Group" field="ovrSiteTnmGroup" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSiteTnmGroup} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site TMN Group" aliasNo="2142" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteTnmGroup"]} itemNbr="2142"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteTnmGroup"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Age Site Morph" field="ovrAgeSiteMorph" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrAgeSiteMorph} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Age Site Morph" aliasNo="2143" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrAgeSiteMorph"]} itemNbr="2143"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrAgeSiteMorph"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Surg DxConf" field="ovrSurgDxconf" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSurgDxconf} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Surg DxConf" aliasNo="2144" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSurgDxconf"]} itemNbr="2144"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSurgDxconf"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site Type" field="ovrSiteType" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSiteType} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site Type" aliasNo="2145" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteType"]} itemNbr="2145"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteType"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Histology" field="ovrHist" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrHist} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Histology" aliasNo="2146" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrHist"]} itemNbr="2146"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrHist"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Leuk Lymph" field="ovrLeukLymph" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrLeukLymph} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Leuk Lymph" aliasNo="2147" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrLeukLymph"]} itemNbr="2147"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrLeukLymph"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site Behavior" field="ovrSiteBehav" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSiteBehav} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site Behavior" aliasNo="2148" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteBehav"]} itemNbr="2148"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteBehav"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Name Sex" field="ovrNameSex" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrNameSex} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Name Sex" aliasNo="4146" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrNameSex"]} itemNbr="4146"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrNameSex"] : ""}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site Lat Morph" field="ovrSiteLatMorph" lineStyle="oneLine onTopFrame"
                                                    value={this.state.caseAdminForm.ovrSiteLatMorph} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site Lat Morph" aliasNo="2149" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteLatMorph"]} itemNbr="2149"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteLatMorph"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Seq DxConf" field="ovrSeqDxconf" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSeqDxconf} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Seq DxConf" aliasNo="2150" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSeqDxconf"]} itemNbr="2150"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSeqDxconf"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site Lat Seq" field="ovrSiteLatSeq" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSiteLatSeq} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site Lat Seq" aliasNo="2151" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteLatSeq"]} itemNbr="2151"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteLatSeq"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Report Source" field="ovrRptSource" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrRptSource} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Report Source" aliasNo="2152" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrRptSource"]} itemNbr="2152"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrRptSource"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="ILL Defined Site" field="ovrIllSite" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrIllSite} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Ill Defined Site" aliasNo="2153" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrIllSite"]} itemNbr="2153"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrIllSite"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site Eod DxDate" field="ovrSiteEodDxdt" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSiteEodDxdt} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site Eod DxDate" aliasNo="2154" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteEodDxdt"]} itemNbr="2154"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteEodDxdt"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Site Eod Lat" field="ovrSiteEodLat" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrSiteEodLat} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="Site Eod Lat" aliasNo="2155" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSiteEodLat"]} itemNbr="2155"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteEodLat"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="SS Nodes Positive" field="ovrSsNodesPos" lineStyle="oneLine" width="25px"
                                                    value={this.state.caseAdminForm.ovrSsNodesPos}
                                                    dataSource="Global_Dir_Mv" titleDialog="SS Nodes Positive" aliasNo="2198" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSsNodesPos"]} itemNbr="2198"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSsNodesPos"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="SS TNM N" field="ovrSsTnmN" lineStyle="oneLine" width="25px"
                                                    value={this.state.caseAdminForm.ovrSsTnmN}
                                                    dataSource="Global_Dir_Mv" titleDialog="SS TNM N" aliasNo="2199" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSsTnmN"]} itemNbr="2199"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSsTnmN"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="SS TNM M" field="ovrSsTnmM" lineStyle="oneLine" width="25px"
                                                    value={this.state.caseAdminForm.ovrSsTnmM}
                                                    dataSource="Global_Dir_Mv" titleDialog="SS TNM M" aliasNo="2200" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrSsTnmM"]} itemNbr="2200"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSsTnmM"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="TNM Stage" field="ovrTnmStage" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrTnmStage} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="TNM Stage" aliasNo="4143" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrTnmStage"]} itemNbr="4143"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrTnmStage"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="TNM Tis" field="ovrTnmTis" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrTnmTis} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="TNM Tis" aliasNo="4144" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrTnmTis"]} itemNbr="4144"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrTnmTis"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="TNM 3" field="ovrTnm3" lineStyle="oneLine"
                                                    value={this.state.caseAdminForm.ovrTnm3} width="25px"
                                                    dataSource="Global_Dir_Mv" titleDialog="TNM 3" aliasNo="4145" labelWidth="135px"
                                                    inValid={this.state.sAllItemFields["ovrTnm3"]} itemNbr="4145"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrTnm3"] : ""}
                                                />
                                            </div>
                                        </div>
                                        {/*
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Accession Class Seq" field="ovrAcsnClassSeq" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrAcsnClassSeq} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Accession Class Seq" aliasNo="2138" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrAcsnClassSeq"]} itemNbr="2138"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrAcsnClassSeq"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site Lat Morph" field="ovrSiteLatMorph" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteLatMorph} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site Lat Morph" aliasNo="2149" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteLatMorph"]} itemNbr="2149"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteLatMorph"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Hosp Seq DxConf" field="ovrHospseqDxconf" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrHospseqDxconf} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Hosp Seq DxConf" aliasNo="2139" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrHospSeqDxConf"]} itemNbr="2139"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrHospSeqDxConf"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Seq DxConf" field="ovrSeqDxconf" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSeqDxconf} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Seq DxConf" aliasNo="2150" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSeqDxConf"]} itemNbr="2150"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSeqDxConf"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="CoC Site Type" field="ovrCocSiteType" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrCocSiteType} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="CoC Site Type" aliasNo="2140" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrCocSiteType"]} itemNbr="2140"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrCocSiteType"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site Lat Seq" field="ovrSiteLatSeq" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteLatSeq} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site Lat Seq" aliasNo="2151" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteLatSeq"]} itemNbr="2151"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteLatSeq"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Hosp Seq Site" field="ovrHospseqSite" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrHospseqSite} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Hosp Seq Site" aliasNo="2141" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrHospSeqSite"]} itemNbr="2141"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrHospSeqSite"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Report Source" field="ovrRptSource" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrRptSource} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Report Source" aliasNo="2152" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrRprtSource"]} itemNbr="2152"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrRprtSource"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site TMN Group" field="ovrSiteTnmGroup" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteTnmGroup} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site TMN Group" aliasNo="2142" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteTnmGroup"]} itemNbr="2142"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteTnmGroup"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Ill Defined Site" field="ovrIllSite" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrIllSite} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Ill Defined Site" aliasNo="2153" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrIllSite"]} itemNbr="2153"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrIllSite"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Age Site Morph" field="ovrAgeSiteMorph" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrAgeSiteMorph} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Age Site Morph" aliasNo="2143" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrAgeSiteMorph"]} itemNbr="2143"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrAgeSiteMorph"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site EoD DxDate" field="ovrSiteEodDxdt" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteEodDxdt} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site EoD DxDate" aliasNo="2154" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteEodDxDt"]} itemNbr="2154"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteEodDxDt"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Surg DxConf" field="ovrSurgDxconf" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSurgDxconf} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Surg DxConf" aliasNo="2144" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSurgDxConf"]} itemNbr="2144"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSurgDxConf"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site EoD Lat" field="ovrSiteEodLat" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteEodLat} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site EoD Lat" aliasNo="2155" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteEodLat"]} itemNbr="2155"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteEodLat"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site Type" field="ovrSiteType" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteType} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site Type" aliasNo="2145" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteType"]} itemNbr="2145"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteType"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="SS Nodes Positive" field="ovrSsNodesPos" lineStyle="oneLine" width="25px"
                                                value={this.state.caseAdminForm.ovrSsNodesPos ? this.state.caseAdminForm.ovrSsNodesPos : this.state.caseAdminForm.ovrSsNodesPos}
                                                dataSource="Global_Dir_Mv" titleDialog="SS Nodes Positive" aliasNo="2198" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSsNodesPos"]} itemNbr="2198"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSsNodesPos"] : ""}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Histology" field="ovrHist" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrHist} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Histology" aliasNo="2146" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrHist"]} itemNbr="2146"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrHist"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="SS TNM N" field="ovrSsTnmN" lineStyle="oneLine" width="25px"
                                                value={this.state.caseAdminForm.ovrSsTnmN ? this.state.caseAdminForm.ovrSsTnmN : this.state.caseAdminForm.ovrSsTnmN}
                                                dataSource="Global_Dir_Mv" titleDialog="SS TNM N" aliasNo="2199" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSsTnmN"]} itemNbr="2199"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSsTnmN"] : ""}
                                            />
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Leuk Lymph" field="ovrLeukLymph" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrLeukLymph} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Leuk Lymph" aliasNo="2147" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrLeukLymph"]} itemNbr="2147"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrLeukLymph"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="SS TNM M" field="ovrSsTnmM" lineStyle="oneLine" width="25px"
                                                value={this.state.caseAdminForm.ovrSsTnmM ? this.state.caseAdminForm.ovrSsTnmM : this.state.caseAdminForm.ovrSsTnmM}
                                                dataSource="Global_Dir_Mv" titleDialog="SS TNM M" aliasNo="2200" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSsTnmM"]} itemNbr="2200"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSsTnmM"] : ""}
                                            />
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Site Behavior" field="ovrSiteBehav" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrSiteBehav} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Site Behavior" aliasNo="2148" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrSiteBehav"]} itemNbr="2148"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrSiteBehav"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="TNM Stage" field="ovrTnmStage" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrTnmStage} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="TNM Stage" aliasNo="4143" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrTnmStage"]} itemNbr="4143"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrTnmStage"] : ""}
                                            />
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Name Sex" field="ovrNameSex" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrNameSex} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="Name Sex" aliasNo="4146" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrNameSex"]} itemNbr="4146"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrNameSex"] : ""}
                                            />
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="TNM Tis" field="ovrTnmTis" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrTnmTis} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="TNM Tis" aliasNo="4144" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrTnmTis"]} itemNbr="4144"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrTnmTis"] : ""}
                                            />
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="TNM 3" field="ovrTnm3" lineStyle="oneLine"
                                                value={this.state.caseAdminForm.ovrTnm3} width="25px"
                                                dataSource="Global_Dir_Mv" titleDialog="TNM 3" aliasNo="4145" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["ovrTnm3"]} itemNbr="4145"
                                                lostfocused={this.handleLostFocus}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ovrTnm3"] : ""}
                                            />
                                        </div>
                                        */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetCaseAdmin} formId="caseAdminForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        extraAction={this.extraAction}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Case Admin data loading...</p>;
        return (
            <React.Fragment>
                {caseAdminMaintenance}

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

CaseAdminMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedCaseAdminRecord: state.patientAbstract.selectedCaseAdminRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        userId: state.auth.userId,
        clientgroup: state.auth.clientgroup,
        securityGroup: state.auth.securityGroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        inquiringMode: state.auth.inquiringMode,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveCaseAdmin: (caseAdminData) => dispatch(actionCreators.saveCaseAdmin(caseAdminData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseAdminMaintenance);