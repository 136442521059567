/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import * as dialogTypes from "../../maintDialogs";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import "./PatientMaintenance.css";
import Help from "../../../UI/Help";
import moment from "moment";
import * as stateMaint from "../../StateMaint";
import Draggable from 'react-draggable';

class PatientMaintenance extends Component {

    state = {
        activeTab: "1",

        pAllItemFields: null,
        contactItemFields: null,
        primaryItemFields: null,

        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'patientForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty patientForm back to what it was on load
        initialPatientForm: null,
        patientForm: null,
        isFormDirty: false,

        contacts: null,
        primaries: null,
        initialContacts: null,
        initialPrimaries: null,
        isPrimaryDirty: false,

        contactMaxlengthInput: null,
        primariesMaxlengthInput: null,

        fieldInFocus: "socSecNbr",
        fieldInFocusByIndex: null,
        isHelpVisible: false,
        focusingField: null,

        hasErrorOccurred: false,
        // State
        hasNotificationStateMsg: false,
        updatedDataStateInfo: null,
        updatingItemState: null,
        updatingStateValue: null,
        updatingStateIndex: 0,
        // County
        hasNotificationCountyMsg: false,
        updatedDataCountyInfo: null,
        updatingItemCounty: null,
        updatingCountyValue: null,
        updatingCountyIndex: 0,
        // City
        hasNotificationCityMsg: false,
        updatedDataCityInfo: null,
        updatingItemCity: null,
        updatingCityValue: null,
        updatingCityIndex: 0,

        // Fix #PR00056: No SEQ change prompt from 00 --> 01 when 02 is added
        ignoreShowNotificationSeqPrimMsg: false,
        hasNotificationSeqPrimMsg: false,
        updatedDataSeqPrimInfo: null,
        updatingItemSeqPrim: null,
        updatingSeqPrimIndex: null,

        globalMsg: null,
        replacementVal: null,
        globalMsg1: null,//using for primaries grid - hosp
        replacementVal1: null,
        globalMsg2: null,//using for primaries grid - accNbr
        replacementVal2: null,
        globalMsg3: null,//using for primaries grid - medRecNbr
        replacementVal3: null,
        globalMsg4: null,//using for primaries grid - diagnosis date
        replacementVal4: null,

        deletedItem: null,
        deletedItemIndex: 0,
        isShowDeletionMsg: false,
        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        // add these flags to sure the URL is ONLY called in once.
        isLoadingContacts: false,
        isLoadingPrimaries: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        loaded: false,
        showHideDeleteBtn: true,
        loadedTableStructure: false,
        selectedContactsRowIndex: -1,
        reloadPrimaryData: false,

        selectedPrimaryRowIndex: -1,
        //Fixing #PR00538
        isShowTransmitTextDialog: false,
    }

    setSelectionContactsRow = (event, index) => {
        this.setState({ selectedContactsRowIndex: index });
    }

    setSelectionPrimaryRow = (event, index) => {
        this.setState({ selectedPrimaryRowIndex: index });
    }

    keydownHandler = (event) => {
        if (event.target.getAttribute("class") === "deletedDiagnosisBtn") {
            if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                if (!event.target.parentNode.parentNode.previousSibling || event.target.parentNode.parentNode.previousSibling === null) {
                    document.getElementById("linkOfRemarksTab").click();//#4
                }
            }
        } else if (event.target.getAttribute("class") === "selectedDiagnosisBtnOnGrid") {
            if (event.key === "Tab" && !event.shiftKey) {
                if (event.target.parentNode.parentNode.nextSibling !== null) {
                    let rindex = event.target.getAttribute("data-index");
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("deletedDiagnosisBtn" + rindex) ? document.getElementById("deletedDiagnosisBtn" + rindex).focus() : "";
                    }, 100);
                }
            }
        } else if (event.target.name === "btnDelLastCont") {
            if (event.key === "Tab" && !event.shiftKey) {
                document.getElementById("linkOfRemarksTab").click();
            }
        }
    }

    addEventListener = () => {
        window.addEventListener('keydown', function (e) {
            if (e.target.form && e.target.form.id !== "patientForm") return false;
            if (e.keyCode == 9) {
                var currentInput = e.target.name;
                if (currentInput === "navTabOnPatientMaint" || (e.target.className === "nav nav-tabs" && e.target.form.id === "patientForm")) {
                    document.getElementById("linkOfPatientTab") ? document.getElementById("linkOfPatientTab").click() : "";//#1
                }
            } else if (e.keyCode == 13) {
                var currentInput = e.target.name;
                if (currentInput === "navTabOnPatientMaint" || (e.target.className === "nav nav-tabs" && e.target.form.id === "patientForm")) {
                    document.getElementById("linkOfPatientTab") ? document.getElementById("linkOfPatientTab").click() : "";//#1
                }
            }
            return false;
        });

        /*
        var _this = this;
        setInterval(function () {
            if (!_this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") !== -1) {
                document.querySelector(".inquiringCls") ? document.querySelector(".inquiringCls").setAttribute("class", "inquiringCls hidden") : null;
            } else {
                document.querySelector(".inquiringCls") ? document.querySelector(".inquiringCls").setAttribute("class", "inquiringCls") : null;
            }
        }, 1000);
        */
    }

    componentWillUnmount() {
        this.setState({ patientForm: null, primaries: null });
    }

    componentDidMount() {
        this.setState({ patientForm: null, primaries: null });

        if (this.props.patientId !== 0)
            this.props.selectPatientLookupView(this.props.patientId);

        if (!this.state.patientForm && this.props.selectedPatient) {
            this.setFormData();
        }

        this.props.showPageLoading(true);

        var _this = this;
        const label = "Patient";
        if (_this.props.inquiringMode) {
            document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
        } else if ((!_this.props.inquiringMode && label == "Patient")
            || (!_this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") !== -1)
            || (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") != -1)
        ) {
            document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
        } else if ((!_this.props.inquiringMode && label != "Patient" &&
            (document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1) &&
            (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") == -1))
        ) {
            document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
        } else {
            document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
        }
    }

    componentDidUpdate(prevProps) {
        // Fixing item #PR00116
        this.addEventListener();

        //
        if ((!this.state.patientForm && this.props.selectedPatient)
            || (prevProps.selectedPatient && this.props.selectedPatient && prevProps.selectedPatient.pid !== this.props.selectedPatient.pid)
            || (prevProps.selectedPatient && this.props.selectedPatient && prevProps.selectedPatient !== this.props.selectedPatient)
            || (this.props.initialAbstractPages && this.props.selectedPatient)) {
            this.props.showPageLoading(true);
            this.setFormData();
            this.setState({ loaded: true });
        }

        if (prevProps.isFormDirty != this.props.isFormDirty) {
            this.setState({ isFormDirty: this.props.isFormDirty });
        }

        if ((this.props.savedPatientMaitenance && !this.state.reloadPrimaryData) || this.props.selectedPatient && !this.state.primaries && !prevProps.primaries && !this.state.isLoadingPrimaries) {
            this.setState({ isLoadingPrimaries: true, reloadPrimaryData: true });
            axios.post(this.props.clientgroup + "/GetPrimarySummaryList",
                { patientId: this.props.patientId, nlp: 1, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let initialPrimaries = [];
                        res.data.primarySummaryList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                fields[t] = obj[t];
                            });
                            initialPrimaries.push(fields);
                        });
                        this.setState({
                            primaries: res.data.primarySummaryList,
                            initialPrimaries: initialPrimaries,
                        });

                        // Primary List
                        if (!this.state.primaryItemFields && res.data.primarySummaryList) {
                            let primariesArr = [];
                            res.data.primarySummaryList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                primariesArr.push(fields);
                            });

                            this.setState({ primaryItemFields: primariesArr });

                            // get maxlength for Primary_Summary grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Primary_Summary", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = primariesArr && primariesArr[0] ? primariesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            primariesMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }

                                    //update new accessToken again
                                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    //redirect to Login page by clicking the Logout button
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };

                        var pm = this;

                        // Inquiry mode...
                        let url = this.props.clientgroup + "/GetUserByEmail";
                        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                const availableHospsData = res.data.user !== null && res.data.user.availableHospsData !== null ? res.data.user.availableHospsData : "";
                                if (!res.data.error && res.data.user !== null && availableHospsData !== null && availableHospsData !== "") {
                                    pm.setState({ availableHospsData: availableHospsData });
                                    return false;
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                                /*
                                //redirect to Login page by clicking the Logout button
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                //@TODO:...

                                return false;
                            });

                        setTimeout(function () {
                            pm.props.showPageLoading(false);
                        }, 2000);
                    } else {
                        var pm = this;
                        setTimeout(function () {
                            pm.props.showPageLoading(false);
                        }, 2000);
                    }
                }).catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.selectedPatient && !this.state.contacts && !prevProps.contacts
            && !this.state.isLoadingContacts) {
            this.setState({ isLoadingContacts: true });
            axios.post(this.props.clientgroup + "/GetContactsMvList",
                { patientId: this.props.patientId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let contactsArr = [];
                        res.data.contactsMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                let value = obj[t];
                                if (t === "contPhone" && value !== null && value !== "") {
                                    //var region = { dialog.Object }.getValue('FN_Region');
                                    var oldVal = value;
                                    var newVal = "";

                                    //if (region == 'USA') {
                                    newVal = oldVal.replace(/[^0-9]/gi, '');
                                    newVal = newVal.substring(0, 10);

                                    if (newVal.length >= 1 && newVal.length <= 3) {
                                        newVal = "(" + newVal.substring(0, 3) + ") "
                                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                                    } else if (newVal.length >= 8 /*&& newVal.length <= 10*/) {
                                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                                    }
                                    //value = newVal;
                                    fields[t] = newVal;
                                    //}
                                } else if (t === "contZip" && value !== null && value !== "") {
                                    var oldVal = value;
                                    var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();

                                    var regionUSA = false;
                                    if (oldVal != '') {
                                        //Canadian
                                        if (isNaN(newVal) == true) {
                                            if (newVal.length >= 4) {
                                                newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                                            }

                                            //US
                                        } else {
                                            newVal = oldVal.replace(/[^0-9]/gi, '');
                                            if (newVal.length >= 6) {
                                                newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                                            }
                                            regionUSA = true;
                                        }
                                    }
                                    fields[t] = newVal;
                                } else {
                                    fields[t] = value;
                                }
                            });
                            contactsArr.push(fields);
                        });

                        this.setState({
                            contacts: contactsArr,//res.data.contactsMvList,
                            initialContacts: contactsArr,//res.data.contactsMvList
                        });

                        // Contact List
                        if (!this.state.contactItemFields && res.data.contactsMvList) {
                            let contactsArr = [];
                            res.data.contactsMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                contactsArr.push(fields);
                            });

                            this.setState({ contactItemFields: contactsArr });

                            // get maxlength for Contacts grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Contacts_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = contactsArr && contactsArr[0] ? contactsArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            contactMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    //redirect to Login page by clicking the Logout button
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    } else {
                        this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.patientForm && this.state.pAllItemFields
        ) {
            console.log("[Patient] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // contact grid
                    this.setState(prevState => {
                        const newItems = [...prevState.contactItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { contactItemFields: newItems };
                    });

                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        patientForm: { ...this.state.patientForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1 && this.state.contactItemFields) {
                    // contact grid
                    this.setState(prevState => {
                        const newItems = [...prevState.contactItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { contactItemFields: newItems };
                    });

                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        patientForm: { ...this.state.patientForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Patient", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });

        //update new accessToken again
        var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }
    }

    setFormData = () => {
        let dataForm = this.props.selectedPatient;

        // store ids into session
        // Session.currentPID = current selected Patient id
        const currentPID = dataForm.pid;
        const sessionPID = sessionStorage.getItem('currentPID');
        if (currentPID !== undefined && currentPID !== null && currentPID !== 0
            && (sessionPID === null || sessionPID !== currentPID)) {
            sessionStorage.setItem('currentPID', currentPID);
        }

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            if (t === "socSecNbr" && dataVal !== null && dataVal !== "") {
                dataVal = dataVal.replace(/-/g, '');
                if (dataVal.length >= 4 && dataVal.length <= 5) {
                    dataVal = dataVal.substr(0, 3) + "-" + dataVal.substr(3, 2);
                } else {
                    dataVal = dataVal.substr(0, 3) + "-" + dataVal.substr(3, 2) + "-" + dataVal.substr(5);
                }
            } else if (t === "medicareBeneficiaryId" && dataVal !== null && dataVal !== "") {
                /*
                dataVal = dataVal.replace(/-/g, '');
                if (dataVal.length > 10) {
                    dataVal = dataVal.substr(0, 4) + "-" + dataVal.substr(4, 3) + "-" + dataVal.substr(7, 4);
                }
                */
                var formattedMBI = dataVal.replace(/[^0-9A-Za-z]/gi, '');
                formattedMBI = formattedMBI.substring(0, 11);
                if (formattedMBI.length >= 5 && formattedMBI.length <= 7) {
                    formattedMBI = formattedMBI.substring(0, 4) + "-" + formattedMBI.substring(4, 7);
                } else if (formattedMBI.length >= 8) {
                    formattedMBI = formattedMBI.substring(0, 4) + "-" + formattedMBI.substring(4, 7) + "-" + formattedMBI.substring(7);
                }
                dataVal = formattedMBI;
            } else if (t === "currZip" && dataVal !== null && dataVal !== "") {
                var oldVal = dataVal;
                var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                if (oldVal != '') {
                    //Canadian
                    if (isNaN(newVal) == true) {
                        if (newVal.length >= 4) {
                            newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                        }

                        //US
                    } else {
                        newVal = oldVal.replace(/[^0-9]/gi, '');
                        if (newVal.length >= 6) {
                            newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                        }
                    }

                    dataVal = newVal;
                }
            } else if ((t === "phoneNbr" || t === "secondaryPhoneNbr") && dataVal !== null && dataVal !== "") {
                // Format_Phone
                var region = '';//FN_Region
                var oldVal = dataVal;
                var newVal = oldVal;

                if (region == 'USA' || region == '') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                }

                dataVal = newVal;
            } else if (t === "secondaryPhoneNbr" && dataVal !== null && dataVal !== "") {
                //
            }

            dataForm[t] = dataVal;
        });

        this.setState({
            initialPatientForm: { ...dataForm },
            patientForm: { ...dataForm },
            isFormDirty: false,
        });

        // clone all fields to check validation and set maxlength for input fields
        if (!this.state.pAllItemFields && this.props.selectedPatient) {
            let fields = {};
            Object.keys(this.props.selectedPatient).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    toggleTab = (event, tab) => {
        this.setState({ activeTab: tab });
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("race") ? document.getElementById("race").focus() : "";
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("contFirstName0") ? document.getElementById("contFirstName0").focus() : "";
            }, 0);
        } else if (tab === "3") {
            setTimeout(function () {
                document.getElementById("suspenseRemarks") ? document.getElementById("suspenseRemarks").focus() : "";
            }, 0);
        }

        return false;
    }

    selectMedicalRecord = async (event, medical, index) => {
        event.preventDefault();

        // check if whether is using "Inquire Mode" or NOT changed anything, NOT ALLOW "SAVE" any data...
        if (this.props.isInquiringMode || !this.state.isFormDirty) {
            this.props.selectMedicalRecord(medical, this.props.patientId);
            this.props.setDialog(dialogTypes.DIAGNOSIS);

            // Enable all tabs in maintDialogs.js
            let currentShowingState = this.props.currentShowingState;
            dialogTypes.dialogDisplays.map((dialog, index) => {
                // check if whether the state fields have a state specific screen?
                if (dialog === dialogTypes.STATE_SPECIFIC) {
                    //console.log("currentShowingState=" + currentShowingState);
                    if (stateMaint.stateSpecificScreens.includes(currentShowingState)) {
                        this.props.setEnabledStatus(dialog);
                    } else {
                        this.props.setEnabledStatus(dialog, false);
                    }
                } else {
                    this.props.setEnabledStatus(dialog);
                }
            });

            return false;
        }

        // disabled button after clicked
        var selBtn = event.target;
        selBtn.disabled = true;

        var pm = this;
        // Case: Add new Patient -> Add new Primary Summary
        if (pm.props.patientId == "0" && medical.cid == "0") {
            // First, ONLY save Patient screen data entered items
            var patientFormData = pm.state.patientForm;
            var contactList = pm.state.contacts;
            var primaryList = pm.state.primaries;

            var url = pm.props.clientgroup + "/CreatePatient";
            axios.post(url, { patient: patientFormData, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        selBtn.disabled = true;

                        const patientId = res.data.patient.pid;
                        patientFormData.pid = patientId;
                        medical.pid = patientId;

                        // ------------------------------------------------------------------------------------|
                        let _url = pm.props.clientgroup + "/CreatePrimarySummary";
                        axios.post(_url, { primarySummary: medical, accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res && res.data && !res.data.error) {
                                    selBtn.disabled = true;

                                    medical.cid = res.data.primarySummary.cid;

                                    primaryList.map((prm, idx) => {
                                        if (idx === index) {
                                            primaryList[idx] = medical;
                                        }
                                    });
                                    // ------------------------------------------------------------------------------------|
                                    patientFormData.contactsMv = contactList;
                                    patientFormData.primarySummary = primaryList;

                                    pm.props.savePatient(patientFormData, false, patientId);
                                    pm.props.selectMedicalRecord(medical, patientId);
                                    pm.props.setDialog(dialogTypes.DIAGNOSIS);

                                    // Enable all tabs in maintDialogs.js
                                    let currentShowingState = pm.props.currentShowingState;
                                    dialogTypes.dialogDisplays.map((dialog, index) => {
                                        // check if whether the state fields have a state specific screen?
                                        if (dialog === dialogTypes.STATE_SPECIFIC) {
                                            if (stateMaint.stateSpecificScreens.includes(currentShowingState)) {
                                                pm.props.setEnabledStatus(dialog);
                                            } else {
                                                pm.props.setEnabledStatus(dialog, false);
                                            }
                                        } else {
                                            pm.props.setEnabledStatus(dialog);
                                        }
                                    });

                                    return false;
                                    // ------------------------------------------------------------------------------------|
                                } else {
                                    pm.setState({ hasErrorOccurred: true });
                                    selBtn.disabled = false;
                                }
                            }).catch(error => {
                                pm.setState({ hasErrorOccurred: true });
                                selBtn.disabled = false;
                            });

                        return false;
                        // ------------------------------------------------------------------------------------|
                    } else {
                        pm.setState({ hasErrorOccurred: true });
                        selBtn.disabled = false;
                    }
                }).catch(error => {
                    pm.setState({ hasErrorOccurred: true });
                    selBtn.disabled = false;
                });

            return false;
        } else if (pm.props.patientId != "0" && medical.cid == "0") {
            selBtn.disabled = true;

            // only save selected row then MUST determine CID before to do the next action
            var patientFormData = pm.state.patientForm;
            var contactList = pm.state.contacts;
            var primaryList = pm.state.primaries;

            if (!medical.pid || medical.pid == "0") {
                medical.pid = pm.props.patientId;
            }

            var url = pm.props.clientgroup + "/CreatePrimarySummary";
            axios.post(url, { primarySummary: medical, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        selBtn.disabled = true;

                        medical.cid = res.data.primarySummary.cid;

                        // MUST remove SELECTED row before saving Patient... to avoid duplicatd data
                        primaryList = primaryList.filter((prm, idx) => {
                            return idx != index;
                        });

                        patientFormData.contactsMv = contactList;
                        patientFormData.primarySummary = null;
                        patientFormData.primarySummary = primaryList;

                        pm.props.savePatient(patientFormData, false, pm.props.patientId);
                        pm.props.selectMedicalRecord(medical, pm.props.patientId);//
                        pm.props.setDialog(dialogTypes.DIAGNOSIS);

                        // Enable all tabs in maintDialogs.js
                        let currentShowingState = pm.props.currentShowingState;
                        dialogTypes.dialogDisplays.map((dialog, index) => {
                            // check if whether the state fields have a state specific screen?
                            if (dialog === dialogTypes.STATE_SPECIFIC) {
                                if (stateMaint.stateSpecificScreens.includes(currentShowingState)) {
                                    pm.props.setEnabledStatus(dialog);
                                } else {
                                    pm.props.setEnabledStatus(dialog, false);
                                }
                            } else {
                                pm.props.setEnabledStatus(dialog);
                            }
                        });

                        return false;
                        // ------------------------------------------------------------------------------------|
                    } else {
                        pm.setState({ hasErrorOccurred: true });
                        selBtn.disabled = false;
                    }
                }).catch(error => {
                    pm.setState({ hasErrorOccurred: true });
                    selBtn.disabled = false;
                });

            return false;
        }

        // ------------------------------------------------------------------------------------|
        selBtn.disabled = true;

        // ADD - 2021/07/05 --START
        // First, saving Patient screen data entered items
        var patientFormData = this.state.patientForm;
        var contactList = this.state.contacts;
        var primaryList = this.state.primaries;

        patientFormData.contactsMv = contactList;
        patientFormData.primarySummary = primaryList;
        // --END

        await this.props.savePatient(patientFormData, this.props.isAddNew, this.props.patientId);
        await setTimeout(function () {
            pm.props.selectMedicalRecord(medical, pm.props.patientId);

            pm.props.setDialog(dialogTypes.DIAGNOSIS);

            // Enable all tabs in maintDialogs.js
            var currentShowingState = pm.props.currentShowingState;
            dialogTypes.dialogDisplays.map((dialog, index) => {
                // check if whether the state fields have a state specific screen?
                if (dialog === dialogTypes.STATE_SPECIFIC) {
                    console.log("currentShowingState=" + currentShowingState);
                    if (stateMaint.stateSpecificScreens.includes(currentShowingState)) {
                        pm.props.setEnabledStatus(dialog);
                    } else {
                        pm.props.setEnabledStatus(dialog, false);
                    }
                } else {
                    pm.props.setEnabledStatus(dialog);
                }
            });
        }, 1000);

        return false;
        // ------------------------------------------------------------------------------------|
    }

    validateDate = (value) => {
        var day, A = value.match(/[1-9][\d]*/g);
        try {
            A[0] -= 1;
            day = new Date(+A[2], A[0], +A[1]);
            if (day.getMonth() == A[0] && day.getDate() == A[1]) return day;
            throw new Error('Bad Date');
        }
        catch (er) {
            return er.message;
        }
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                pAllItemFields: {
                    ...this.state.pAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            pAllItemFields: {
                                ...this.state.pAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    savePatient = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        let formItems = this.state.pAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "dob" || t === "Dob") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (isValid && !allowSaveAnyway) {
            const cItems = this.state.contactItemFields;
            cItems.map((contact) => {
                Object.keys(contact).map((t) => {
                    if (contact[t] === "IN_VALID" && t !== "class") {
                        return isValid = false;
                    }
                });
            });
        }

        if (isValid && !allowSaveAnyway) {
            let pItems = this.state.primaryItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((t) => {
                    // clear IN_VALID flag for all date fields
                    if (t == "diagnosisDate" || t == "DiagnosisDate") {
                        this.clearInvalidOfDateFields(prm, t, "primaryItemList", index);
                    }

                    if (prm[t] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let patientFormData = this.state.patientForm;
            let contactList = this.state.contacts;
            let primaryList = this.state.primaries;

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(patientFormData).map((t, k) => {
                if (t === "dob" || t === "Dob") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(patientFormData, t);
                }
            });
            //#20210125 - END

            if (primaryList) {
                primaryList.map((obj, index) => {
                    //#20210125 - START
                    Object.keys(obj).map((t) => {
                        if (t == "diagnosisDate" || t == "DiagnosisDate") {
                            isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "primaryList", index);
                        }
                    });
                    //#20210125 - END
                });
            }

            if (!isValidDateInput && !allowSaveAnyway) return false;

            patientFormData.contactsMv = contactList;
            patientFormData.primarySummary = primaryList;

            this.props.savePatient(patientFormData, this.props.isAddNew, this.props.patientId);
            this.setState({ isFormDirty: false, reloadPrimaryData: false });

            return false;
        }
    }

    resetPatient = () => {
        this.setState({
            patientForm: { ...this.state.initialPatientForm },
            isFormDirty: false,
        });
        return false;
    }

    handleOnFocus = (event, index) => {
        this.setState({
            fieldInFocus: event.target.name,
            fieldInFocusByIndex: event.target.id,
            focusingField: event.target
        });

        if (index && index > 0) {
            var name = event.target.name;
            var value = event.target.value;

            const primariesArr = this.state.primaries;
            const hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";

            let hospIdx = -1;
            primariesArr.map((prm, idx) => {
                if (prm["hosp"] === hosp && idx != index) {
                    hospIdx = idx;
                    return false;
                }
            });

            let setDefault = false;
            let defaultVal = "";

            if (event.target.name === "accNbr" && hosp !== "" && value === "" && hospIdx !== -1) {
                setDefault = true;
                defaultVal = primariesArr[hospIdx]["accNbr"];
            } else if (event.target.name === "medRecNbr" && hosp !== "" && value === "" && hospIdx !== -1) {
                setDefault = true;
                defaultVal = primariesArr[hospIdx]["medRecNbr"];
            } else if ((name === "seqPrim" || name === "hosp") && value !== "") {
                //setDefault = true;
                this.setState({
                    ignoreShowNotificationSeqPrimMsg: false,
                    hasNotificationSeqPrimMsg: false
                });
            }

            if (setDefault) {
                this.setState(prevState => {
                    const newItems = [...prevState.primaries];
                    newItems[index][name] = defaultVal;
                    return {
                        primaries: newItems,
                        //ignoreShowNotificationSeqPrimMsg: false,
                        //hasNotificationSeqPrimMsg: false
                    };
                });
            }
        }
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);
            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ patientForm: { ...this.state.patientForm, [event.target.name]: value } });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else if (mdy == 'year' && value.length == 2) {
                let currentYear = (new Date().getFullYear() + "");
                let yyVal = currentYear.substr(2, 2);
                if (yyVal < value) {
                    value = (currentYear.substr(0, 2) - 1) + value;
                } else {
                    value = currentYear.substr(0, 2) + value;
                }

                this.setState({ patientForm: { ...this.state.patientForm, [event.target.name]: value } });
            }
            //#20210125 - END
        } else {
            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });

            /*---------------------------------------------------------------------------------------------------------*/
            // check required fields input
            if (event.target.name === "suspenseRemarks" && event.target.value === "") {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });

                // check validation of date field input
            } else if (event.target._element && event.target._element.className === "k-widget k-datepicker" && event.target.dateInput._element.value !== "month/day/year") {
                if (event.target.dateInput._element.validity.valid) {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
                }
            } else {
                var name = event.target.name;
                var value = event.target.value;
                // format text again
                if (name === "socSecNbr" && value !== "") {
                    if (value === this.state.initialPatientForm["socSecNbr"]) {
                        return false;
                    }

                    if (value === this.state.initialPatientForm["socSecNbr"]
                        /*|| value === "999999999"*/ || value === "999-99-9999") return false;

                    let ssn = value.replace(/[^0-9]/gi, '');
                    const ssnstr = ssn;

                    if (ssn.length < 4) {
                        this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                    } else {
                        if (ssn.length >= 4 && ssn.length <= 5) {
                            ssn = ssn.substr(0, 3) + "-" + ssn.substr(3, 2);
                            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                        } else {
                            if (ssn.length < 9) {
                                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                            } else {
                                ssn = ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4);

                                if (ssn !== "999-99-9999") {
                                    const pid = this.state.patientForm.pid;

                                    var _para = { ssn: ssnstr, pid: pid, accessToken: localStorage.getItem('token') };
                                    axios.post(this.props.clientgroup + "/CheckExistedSsn", _para)
                                        .then(res => {
                                            //update new accessToken again
                                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                                            if (newAccessToken) {
                                                localStorage.setItem('token', newAccessToken);
                                            }

                                            if (res.data.error && res.data.message) {
                                                this.setState({
                                                    pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" },
                                                    globalMsg: "The Social Security Number, " + ssn + " has been used for Patient: " + res.data.message
                                                });
                                            } else {
                                                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                                            }
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            /*
                                            //redirect to Login page by clicking the Logout button
                                            var btnLogout = document.getElementById("logoutButtonArea");
                                            if (btnLogout) {
                                                btnLogout.querySelector("a").click();
                                            }
                                            */
                                            //@TODO:...

                                            return false;
                                        });
                                }
                            }
                        }
                    }
                    this.setState({ patientForm: { ...this.state.patientForm, [name]: ssn } });
                } else if (name === "medicareBeneficiaryId" && value !== "") {
                    if (value === this.state.initialPatientForm["medicareBeneficiaryId"]) return false;
                    var mbi = value.replace(/[^0-9A-Za-z]/gi, '');

                    if (mbi.length < 5) {
                        this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                    } else {
                        if (mbi.length >= 5 && mbi.length <= 7) {
                            mbi = mbi.substr(0, 4) + "-" + mbi.substr(4);
                            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                        } else if (mbi.length >= 8 && mbi.length <= 10) {
                            mbi = mbi.substr(0, 4) + "-" + mbi.substr(4, 3) + "-" + mbi.substr(7);
                            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                        } else {
                            mbi = mbi.substr(0, 4) + "-" + mbi.substr(4, 3) + "-" + mbi.substr(7, 4);
                            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                        }
                    }
                    this.setState({ patientForm: { ...this.state.patientForm, [name]: mbi } });
                } else if (name === "currZip" && value !== "") {
                    var oldVal = value;
                    var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                    var regionUSA = false;
                    if (oldVal != '') {
                        //Canadian
                        if (isNaN(newVal) == true) {
                            if (newVal.length >= 4) {
                                newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                            }

                            //US
                        } else {
                            newVal = oldVal.replace(/[^0-9]/gi, '');
                            if (newVal.length >= 6) {
                                newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                            }
                            regionUSA = true;
                        }
                    }

                    this.setState({ patientForm: { ...this.state.patientForm, [name]: newVal } });

                    // @TODO: zip code entry should tell me the city/state to let me select it
                    var state = this.state.patientForm.currSt !== null ? this.state.patientForm.currSt.toUpperCase() : "";
                    var city = this.state.patientForm.currCity !== null ? this.state.patientForm.currCity.toUpperCase() : "";
                    var county = this.state.patientForm.currCnty;

                    // ERSValidateCityStateZip/ers_citystate_from_zip
                    //if (regionUSA) {
                    var zip = newVal;
                    // 
                    //var url = "https://ziptasticapi.com/" + value;
                    var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
                    axios.get(url)
                        .then(response => {
                            const raw = response.data;
                            const parser = new DOMParser();
                            const xml = parser.parseFromString(raw, 'text/xml');//

                            var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                            var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                            if (zip === "88888") {
                                _city = "";
                                _state = "";
                            } else {
                                _city = _city.toUpperCase();
                                _state = _state.toUpperCase();
                            }

                            // City
                            if (_city !== "" && _city !== city && city !== null && city !== "") {
                                this.setState({
                                    hasNotificationCityMsg: true,
                                    updatedDataCityInfo: _city,
                                    updatingItemCity: "currCity",
                                });
                            } else if (_city !== "" && _city !== city && (city === null || city === "")) {
                                this.setState({
                                    patientForm: { ...this.state.patientForm, ["currCity"]: _city }
                                });
                            }

                            // State
                            if (_state !== "" && _state !== state && state !== null && state !== "") {
                                this.setState({
                                    hasNotificationStateMsg: true,
                                    updatedDataStateInfo: _state,
                                    updatingItemState: "currSt",
                                });
                            } else if (_state !== "" && _state !== state && (state === null || state === "")) {
                                this.setState({
                                    patientForm: { ...this.state.patientForm, ["currSt"]: _state }
                                });
                            }

                            var _param = { zip: value, accessToken: localStorage.getItem('token') };
                            axios.post(this.props.clientgroup + "/InquireRelatedInfo", _param)
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (!res.data.error && res.data.message !== null && res.data.message !== "") {
                                        const info = res.data.message;
                                        let arr = info.split('|');
                                        // County
                                        if (arr[1] !== "" && arr[1] !== county && county !== null && county !== "") {
                                            this.setState({
                                                hasNotificationCountyMsg: true,
                                                updatedDataCountyInfo: arr[1],
                                                updatingItemCounty: "currCnty",
                                            });
                                        } else if (arr[1] !== "" && arr[1] !== county && (county === null || county === "")) {
                                            this.setState({
                                                patientForm: { ...this.state.patientForm, ["currCnty"]: arr[1] }
                                            });
                                        }

                                        return false;
                                    }
                                }).catch(function (error) {
                                    console.log(error);
                                    /*
                                    //redirect to Login page by clicking the Logout button
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        });

                    //return false;
                    //}
                } else if (name === "emailAddress" && value !== "") {
                    // 
                    let regPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!regPattern.test(value)) {
                        this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                    } else {
                        this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                    }
                } else if ((name === "phoneNbr" || name === "secondaryPhoneNbr") && value !== "") {
                    //var region = { dialog.Object }.getValue('FN_Region');
                    var oldVal = value;
                    var newVal = "";

                    //if (region == 'USA') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                    //value = newVal;
                    //}

                    this.setState({
                        patientForm: { ...this.state.patientForm, [name]: newVal }
                    });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                }
            }
            /*---------------------------------------------------------------------------------------------------------*/
        }
    }

    handleLostFocusContactTab = (event, index) => {
        var name = event.target.name;
        var value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, index, flag);
        } else {
            if (name === "contZip" && value !== null && value !== "") {
                var oldVal = value;
                var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();

                var regionUSA = false;
                if (oldVal != '') {
                    //Canadian
                    if (isNaN(newVal) == true) {
                        if (newVal.length >= 4) {
                            newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                        }

                        //US
                    } else {
                        newVal = oldVal.replace(/[^0-9]/gi, '');
                        if (newVal.length >= 6) {
                            newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                        }
                        regionUSA = true;
                    }
                }

                //this.setState({ patientForm: { ...this.state.patientForm, [name]: newVal } });
                this.setState(prevState => {
                    const newItems = [...prevState.contacts];
                    newItems[index][name] = newVal;
                    return { contacts: newItems };
                });

                // @TODO: zip code entry should tell me the city/state to let me select it
                var state = this.state.contacts[index]["contSt"] !== null ? this.state.contacts[index]["contSt"].toUpperCase() : "";
                var city = this.state.contacts[index]["contCity"] !== null ? this.state.contacts[index]["contCity"].toUpperCase() : "";

                // ERSValidateCityStateZip/ers_citystate_from_zip
                //if (regionUSA) {
                // 
                var zip = newVal;
                var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
                axios.get(url)
                    .then(response => {
                        const raw = response.data;
                        const parser = new DOMParser();
                        const xml = parser.parseFromString(raw, 'text/xml');//

                        var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                        var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                        if (zip === "88888") {
                            _city = "";
                            _state = "";
                        } else {
                            _city = _city.toUpperCase();
                            _state = _state.toUpperCase();
                        }

                        // City
                        if (_city !== "" && _city !== city && city !== null && city !== "") {
                            this.setState({
                                hasNotificationCityMsg: true,
                                updatedDataCityInfo: _city,
                                updatingItemCity: "contCity",
                                updatingCityIndex: index,
                            });
                        } else if (_city !== "" && _city !== city && (city === null || city === "")) {
                            this.setState(prevState => {
                                const newItems = [...prevState.contacts];
                                newItems[index]["contCity"] = _city;
                                return { contacts: newItems };
                            });
                        }

                        // State
                        if (_state !== "" && _state !== state && state !== null && state !== "") {
                            this.setState({
                                hasNotificationStateMsg: true,
                                updatedDataStateInfo: _state,
                                updatingItemState: "contSt",
                                updatingStateIndex: index,
                            });
                        } else if (_state !== "" && _state !== state && (state === null || state === "")) {
                            this.setState(prevState => {
                                const newItems = [...prevState.contacts];
                                newItems[index]["contSt"] = _state;
                                return { contacts: newItems };
                            });
                        }
                    });

            } else if ((name === "contPhone") && value !== null && value !== "") {
                //var region = { dialog.Object }.getValue('FN_Region');
                var oldVal = value;
                var newVal = "";

                //if (region == 'USA') {
                newVal = oldVal.replace(/[^0-9]/gi, '');
                newVal = newVal.substring(0, 10);

                if (newVal.length >= 1 && newVal.length <= 3) {
                    newVal = "(" + newVal.substring(0, 3) + ") "
                } else if (newVal.length >= 4 && newVal.length <= 7) {
                    newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                } else if (newVal.length >= 8 && newVal.length <= 10) {
                    newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                }
                //value = newVal;
                //}

                this.setState(prevState => {
                    const newItems = [...prevState.contacts];
                    newItems[index][name] = newVal;
                    return { contacts: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.contactItemFields];
                    newItems[index][name] = "";
                    return { contactItemFields: newItems };
                });
            }
        }
    }

    handlePrimaryGridLostFocus = (event, index) => {
        var name = event.target.name;
        var value = event.target.value ? event.target.value : "";

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            if ((name === "abstSunaFlag" || name === "stateRpt") && value !== "") {
                value = value.toUpperCase();
                this.setState(prevState => {
                    const newItems = [...prevState.primaries];
                    newItems[index][name] = value.toUpperCase();
                    return {
                        primaries: newItems,
                        pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                    };
                });
            }

            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, index, flag);
            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                isvalid = false;
            }

            if (name === "diagnosisDateYear") {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.primaries];
                        newItems[index][name] = value;
                        return { primaries: newItems };
                    });
                    !isvalid ? isvalid = true : isvalid;
                }

                var initialPrimaries = this.state.initialPrimaries;
                //
                const origDxYear = initialPrimaries[index] ? initialPrimaries[index]["diagnosisDateYear"] : "";
                var thisDxYear = value;
                if (origDxYear > 2017 && thisDxYear > 1900 && thisDxYear < 2018) {
                    let glMsg4 = "Upon saving this change, any previously entered SSDI and/or " + origDxYear + "\n staging values not applicable for a " + thisDxYear + " Dx Year will be removed.";
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];
                        newItems[index]["diagnosisDateYear"] = "ON_WARN";
                        return {
                            primaryItemFields: newItems, globalMsg4: glMsg4, /*replacementVal4: "EMPTY",*/ dialogTitle4: "Notification"
                        };
                    });
                    return false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { primaryItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "";
                    return { primaryItemFields: newItems };
                });
            }
            //#20210125 - END
        } else {
            if ((name === "seqPrim" || name === "hosp") && value !== "") {
                if (name === "seqPrim") {
                    if (isNaN(value) == true) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = "";
                            return { primaries: newItems, isFormDirty: true };
                        });
                        return false;
                    }
                } else if (name === "hosp") {
                    if (isNaN(value) == true) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = "";
                            return { primaries: newItems, isFormDirty: true };
                        });
                        return false;
                    }

                    //let url = this.props.clientgroup + "/CheckValidHospNbr?userId=" + this.props.userId + "&hosp=" + value;
                    let url = this.props.clientgroup + "/GetUserByEmail";
                    axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            const availableHospsData = res.data.user !== null && res.data.user.availableHospsData !== null ? res.data.user.availableHospsData : "";
                            if (!res.data.error && res.data.user !== null
                                && availableHospsData !== null && availableHospsData !== "" //Fixing #PR00289
                                && availableHospsData.indexOf(value) === -1
                            ) {
                                const message = "Access is restricted. You have access to hosp codes: " + availableHospsData;
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index][name] = "IN_VALID";
                                    return { primaryItemFields: newItems, globalMsg1: message, replacementVal1: "EMPTY", showHideDeleteBtn: false };
                                });
                                return false;
                            } else {
                                /*
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index][name] = "";
                                    return { primaryItemFields: newItems, globalMsg1: null };
                                });
                                */
                                this.setState({ showHideDeleteBtn: true });
                            }
                        }).catch(function (error) {
                            console.log(error);
                            /*
                            //redirect to Login page by clicking the Logout button
                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                }

                //-- Fix issue of #PR00054 --START
                var primariesArr = this.state.primaries;
                var site = primariesArr[index]["siteCode"] ? primariesArr[index]["siteCode"] : "";
                var seq = primariesArr[index]["seqPrim"] ? primariesArr[index]["seqPrim"] : "";
                var hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";

                if (site !== '' && seq !== '' && hosp !== '') {
                    if (isNaN(seq) == true) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index]["seqPrim"] = "";
                            return { primaries: newItems, isFormDirty: true };
                        });
                    }
                    if (isNaN(hosp) == true) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index]["hosp"] = "";
                            return { primaries: newItems, isFormDirty: true };
                        });
                        return;
                    }

                    //If a 00 seq is being added and another abstract exists for this hospital, give a message saying this sequence should not be 00 since another abstract already exists for this case, and blank it out
                    if ((seq === '00' || seq === '60') && seq.length === 2) {
                        /*
                        var i = 0;
                        for (i = 1; i <= totalRows; i++) {
                            var iPhys = { dialog.object }._repeatingSectionLogicalToPhysicalRow('LISTOFPRIMARIES', i);
                            if ({ dialog.Object }.getValue('Abst_Suna_Flag:' + iPhys) == 'A' && seq.charAt(0) == { dialog.Object }.getValue('Seq_Prim:' + iPhys).charAt(0) && { dialog.Object }.getValue('Seq_Prim:' + iPhys).length == 2 && hosp == { dialog.Object }.getValue('Hosp:' + iPhys) && i != thisRow) {
                                //Give message with ok, and then blank out thisRow
                                A5.msgBox.show("Notification", "<div style='padding: 5px 15px; width: 425px; height: 80px; font-size: 11pt; font-weight:bold; font-family: Calibri,sans-serif ;' align='center'><br>This sequence cannot be " + seq + " since another abstract exists for hosp " + hosp + ".</div>", [{ html: 'OK', value: 'ok' }], function (button) {
                                    { dialog.Object }.setValue('Seq_Prim:' + thisPhysicalRow, '', false);
                                    { dialog.Object }.setFocus('Hosp', thisRow);
                                    return;
                                });
                            }
                        }
                        */
                        primariesArr.map((prm, idx) => {
                            const flag = prm["abstSunaFlag"] ? prm["abstSunaFlag"] : "";
                            const _seqPrim = prm["seqPrim"] ? prm["seqPrim"] : "";
                            const _hosp = prm["hosp"] ? prm["hosp"] : "";
                            if (flag === 'A' && seq.charAt(0) === _seqPrim.charAt(0) && _seqPrim.length === 2 && hosp === _hosp && idx !== index) {
                                let glMsg = "This sequence cannot be " + seq + " since another abstract exists for hosp " + hosp + ".";
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index]["seqPrim"] = "IN_VALID";
                                    /*
                                    const newPrimaries = [...prevState.primaries];
                                    newPrimaries[index]["seqPrim"] = "";
                                    */
                                    return {
                                        primaryItemFields: newItems, /*primaries: newPrimaries,*/ globalMsg1: glMsg, replacementVal1: "EMPTY", dialogTitle: "Notification"
                                    };
                                });
                                return false;
                            }
                        });
                    }

                    //If any 2nd duplicate seq is being added, blank it out
                    if (seq.length === 2) {
                        /*
                        var i = 0;
                        for (i = 1; i <= totalRows; i++) {
                            var iPhys = { dialog.object }._repeatingSectionLogicalToPhysicalRow('LISTOFPRIMARIES', i);
                            if ({ dialog.Object }.getValue('Abst_Suna_Flag:' + iPhys) == 'A' && seq == { dialog.Object }.getValue('Seq_Prim:' + iPhys) && hosp == { dialog.Object }.getValue('Hosp:' + iPhys) && i != thisRow) {
                                //Give message with ok, and then blank out thisRow
                                A5.msgBox.show("Notification", "<div style='padding: 5px 15px; width: 425px; height: 80px; font-size: 11pt; font-weight:bold; font-family: Calibri,sans-serif ;' align='center'>Sequence " + seq + " has already been used for hosp code " + hosp + ".<br><br>  Enter a unique sequence/hospital combination.</div>", [{ html: 'OK', value: 'ok' }], function (button) {
                                    { dialog.Object }.setValue('Seq_Prim:' + thisPhysicalRow, '', false);
                                    { dialog.Object }.setFocus('Hosp', thisRow);
                                    return;
                                });
                            }
                        }
                        */
                        primariesArr.map((prm, idx) => {
                            const flag = prm["abstSunaFlag"] ? prm["abstSunaFlag"] : "";
                            const _seqPrim = prm["seqPrim"] ? prm["seqPrim"] : "";
                            const _hosp = prm["hosp"] ? prm["hosp"] : "";
                            if (flag === 'A' && seq === _seqPrim && hosp === _hosp && idx != index) {
                                let glMsg = "Sequence " + seq + " has already been used for hosp code " + hosp + ". \n Enter a unique sequence/hospital combination.";
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index]["seqPrim"] = "IN_VALID";
                                    /*
                                    const newPrimaries = [...prevState.primaries];
                                    newPrimaries[index]["seqPrim"] = "";
                                    */
                                    return { primaryItemFields: newItems,/*primaries: newPrimaries,*/ globalMsg1: glMsg, replacementVal1: "EMPTY", dialogTitle: "Notification" };
                                });
                                return false;
                            }
                        });
                    }

                    //If a 01 seq is being added and there is an existing 00, give a message stating it should be 02, and blank it out.
                    if ((seq === '01' || seq === '61') && seq.length === 2) {
                        /*
                        var i = 0;
                        var check00 = 'No';
                        var checkSeq = '';
                        for (i = 1; i <= totalRows; i++) {
                            var iPhys = { dialog.object }._repeatingSectionLogicalToPhysicalRow('LISTOFPRIMARIES', i);
                            checkSeq = { dialog.Object }.getValue('Seq_Prim:' + iPhys);
                            if (((checkSeq == '00' && seq == '01') || (checkSeq == '60' && seq == '61')) && { dialog.Object }.getValue('Abst_Suna_Flag:' + iPhys) == 'A' && hosp == { dialog.Object }.getValue('Hosp:' + iPhys) && i != thisRow) {
                                check00 = 'Yes';
                            }
                        }
                        if (check00 == 'Yes') {
                            //Give message with ok, and then blank out thisRow
                            A5.msgBox.show("Notification", "<div style='padding: 5px 15px; width: 425px; height: 80px; font-size: 11pt; font-weight:bold; font-family: Calibri,sans-serif ;' align='center'>There is already an abstract entered for this patient at this hospital, so this sequence should not be " + seq + ".<br><br>  Enter a higher sequence or different hospital code.</div>", [{ html: 'OK', value: 'ok' }], function (button) {
                                { dialog.Object }.setValue('Seq_Prim:' + thisPhysicalRow, '', false);
                                { dialog.Object }.setFocus('Hosp', thisRow);
                            });
                        }
                        */
                        primariesArr.map((prm, idx) => {
                            const flag = prm["abstSunaFlag"] ? prm["abstSunaFlag"] : "";
                            const checkSeq = prm["seqPrim"] ? prm["seqPrim"] : "";
                            const _hosp = prm["hosp"] ? prm["hosp"] : "";
                            if (((checkSeq === '00' && seq === '01') || (checkSeq === '60' && seq === '61')) && flag === 'A' && hosp === _hosp && idx !== index) {
                                let glMsg = "There is already an abstract entered for this patient at this hospital, so this sequence should not be " + seq + ".\n Enter a higher sequence or different hospital code.";
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index]["seqPrim"] = "IN_VALID";
                                    /*
                                    const newPrimaries = [...prevState.primaries];
                                    newPrimaries[index]["seqPrim"] = "";
                                    */
                                    return { primaryItemFields: newItems, /*primaries: newPrimaries,*/ globalMsg1: glMsg, replacementVal1: "EMPTY", dialogTitle: "Notification" };
                                });
                                return false;
                            }
                        });
                    }

                    //If an 02 seq is being added and there is an existing 00, and no exiting 01, ask if the 00 should be changed to 01
                    if ((seq === '02' || seq === '62') && seq.length === 2) {
                        /*
                        var i = 0;
                        var rowToChange = '';
                        var check00 = 'No';
                        var check01 = 'No';
                        var checkSeq = '';
                        for (i = 1; i <= totalRows; i++) {
                            var iPhys = { dialog.object }._repeatingSectionLogicalToPhysicalRow('LISTOFPRIMARIES', i);
                            checkSeq = { dialog.Object }.getValue('Seq_Prim:' + iPhys);
                            if (((checkSeq == '00' && seq == '02') || (checkSeq == '60' && seq == '62')) && { dialog.Object }.getValue('Abst_Suna_Flag:' + iPhys) == 'A' && hosp == { dialog.Object }.getValue('Hosp:' + iPhys) && i != thisRow) {
                                check00 = 'Yes';
                                rowToChange = iPhys;
                            }
                            if (((checkSeq == '01' && seq == '02') || (checkSeq == '61' && seq == '62')) && { dialog.Object }.getValue('Abst_Suna_Flag:' + iPhys) == 'A' && hosp == { dialog.Object }.getValue('Hosp:' + iPhys) && i != thisRow) {
                                check01 = 'Yes';
                            }
                        }

                        if (check00 == 'Yes' && check01 == 'No') {
                            checkSeq = { dialog.Object }.getValue('Seq_Prim:' + rowToChange);
                            //Ask if user would like to change sequence
                            A5.msgBox.show("Notification", "<div style='padding: 5px 15px; width: 425px; height: 80px; font-size: 11pt; font-weight:bold; font-family: Calibri,sans-serif ;' align='center'><br>Would you like to update the " + checkSeq + " sequence to " + checkSeq.substring(0, 1) + "1?</div>", [{ html: 'Yes', value: 'ok' }, { html: 'No', value: 'cancel' }], function (button) {
                                if (button == 'ok') {
                                    { dialog.Object }.setValue('Seq_Prim:' + rowToChange, checkSeq.substring(0, 1) + '1', true);
                                }
                                if (button == 'cancel') {
                                    //
                                }
                                { dialog.Object }.setFocus('Hosp', thisRow);
                            });
                        }
                        */
                        var rowIdx = -1;
                        var check00 = 'No';
                        var check01 = 'No';
                        primariesArr.map((prm, idx) => {
                            const flag = prm["abstSunaFlag"] ? prm["abstSunaFlag"] : "";
                            let checkSeq = prm["seqPrim"] ? prm["seqPrim"] : "";
                            const _hosp = prm["hosp"] ? prm["hosp"] : "";

                            if (((checkSeq === '00' && seq === '02') || (checkSeq === '60' && seq === '62')) && flag === 'A' && hosp === _hosp && idx !== index) {
                                check00 = 'Yes';
                                rowIdx = idx;
                            }
                            if (((checkSeq === '01' && seq === '02') || (checkSeq === '61' && seq === '62')) && flag === 'A' && hosp == _hosp && idx !== index) {
                                check01 = 'Yes';
                            }

                            if (check00 == 'Yes' && check01 == 'No') {
                                checkSeq = primariesArr[rowIdx]["seqPrim"] ? primariesArr[rowIdx]["seqPrim"] : "";

                                //
                                let glMsg = "Would you like to update the " + checkSeq + " sequence to " + checkSeq.substring(0, 1) + "1 ?";
                                const replacementVal = checkSeq.substring(0, 1) + '1';
                                /*
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index]["seqPrim"] = "IN_VALID";
                                    /*
                                    const newPrimaries = [...prevState.primaries];
                                    newPrimaries[index]["seqPrim"] = "";
                                    * /
                                    return { primaryItemFields: newItems, /*primaries: newPrimaries,* / globalMsg1: glMsg, replacementVal1: replacementVal, dialogTitle: "Notification" };
                                });
                                */

                                // @TODO: make confirm dialog on this screen...
                                this.setState({
                                    hasNotificationSeqPrimMsg: true,
                                    //ignoreShowNotificationSeqPrimMsg: false,
                                    updatedDataSeqPrimInfo: checkSeq,
                                    updatingItemSeqPrim: "seqPrim",
                                    updatingSeqPrimIndex: rowIdx
                                });

                                return false;
                            }
                        });
                    }
                }
                //--END
            } else if (name === "accNbr") {
                if (value === "") {
                    // --START
                    const primariesArr = this.state.primaries;
                    const hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";

                    let hospIdx = -1;
                    primariesArr.map((prm, idx) => {
                        if (prm["hosp"] === hosp && idx !== index) {
                            hospIdx = idx;
                            return false;
                        }
                    });

                    let setDefault = false;
                    let defaultVal = "";

                    if (hosp !== "" && value === "" && hospIdx !== -1 && primariesArr[hospIdx]["accNbr"].length == 11) {
                        setDefault = true;
                        defaultVal = primariesArr[hospIdx]["accNbr"];
                    }

                    if (setDefault) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = defaultVal;
                            return {
                                primaries: newItems,
                                isFormDirty: true,
                                pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                            };
                        });
                    }
                    //--END
                } else if (isNaN(value) == true) {
                    this.setState(prevState => {
                        const newItems = [...prevState.primaries];
                        newItems[index][name] = "";
                        return {
                            primaries: newItems,
                            isFormDirty: true,
                            pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                        };
                    });
                    return false;
                } else if (value !== "" && value.length !== 4 && value.length !== 11) {
                    //Blank out if invalid non-numeric
                    if (isNaN(value) == true) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = "";
                            return {
                                primaries: newItems,
                                isFormDirty: true,
                                pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                            };
                        });
                        return false;
                    }

                    //show message if invalid length
                    const glMsg = "The length of the Accession Number entered (" + value + ") is invalid.";

                    // --START
                    const primariesArr = this.state.primaries;
                    const hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";

                    let hospIdx = -1;
                    primariesArr.map((prm, idx) => {
                        if (prm["hosp"] === hosp && idx != index) {
                            hospIdx = idx;
                            return false;
                        }
                    });

                    let setDefault = false;
                    let defaultVal = "";

                    if (hosp !== "" && value === "" && hospIdx !== -1 && primariesArr[hospIdx]["accNbr"] && primariesArr[hospIdx]["accNbr"].length == 11) {
                        setDefault = true;
                        defaultVal = primariesArr[hospIdx]["accNbr"];
                    }

                    if (setDefault) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = defaultVal;
                            return {
                                primaries: newItems,
                                isFormDirty: true,
                                pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                            };
                        });
                    }
                    //--END

                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];
                        newItems[index][name] = "IN_VALID";
                        return { primaryItemFields: newItems, globalMsg2: glMsg, replacementVal2: defaultVal };
                    });
                } else {//
                    var primariesArr = this.state.primaries;
                    var _hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";
                    var _accYear = primariesArr[index]["accNbr"] ? primariesArr[index]["accNbr"] : "";
                    //-- 
                    if (value.length == 4) {
                        var _para = { patientId: this.props.patientId, hosp: _hosp, accNbr: _accYear, accessToken: localStorage.getItem('token') };
                        axios.post(this.props.clientgroup + "/GetNextAccNbr", _para)
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data.error && res.data.message !== null && res.data.message !== "") {
                                    const info = res.data.message;
                                    let arr = info.split('|');
                                    if (arr[0] === "") {
                                        this.setState(prevState => {
                                            const newItems = [...prevState.primaryItemFields];
                                            newItems[index][name] = "IN_VALID";
                                            return {
                                                primaryItemFields: newItems,
                                                globalMsg2: "Unable to Auto Assign Accession Number. \n Hosp: " + _hosp + " Year: " + _accYear + " does not exist in Accession Number lookup table.",
                                                replacementVal2: "EMPTY"
                                            };
                                        });
                                    } else if (arr[1] === "Already Used") {
                                        this.setState(prevState => {
                                            const newItems = [...prevState.primaryItemFields];
                                            newItems[index][name] = "IN_VALID";
                                            return {
                                                primaryItemFields: newItems,
                                                globalMsg2: "The Accession Number, " + arr[0] + " has already been used for Patient: " + arr[2],
                                                replacementVal2: "EMPTY"
                                            };
                                        });
                                    } else if (arr[2] === "Update Acc_Nbrs" && arr[0] !== "") {
                                        const nextAccNbr = arr[0];
                                        this.setState(prevState => {
                                            const newItems = [...prevState.primaries];
                                            newItems[index][name] = nextAccNbr;
                                            return {
                                                primaries: newItems,
                                                isFormDirty: true,
                                                pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                                            };
                                        });
                                    }
                                    return false;
                                } else if (!res.data.error && (res.data.message === null || res.data.message === "")) {
                                    this.setState(prevState => {
                                        const newItems = [...prevState.primaryItemFields];
                                        newItems[index][name] = "IN_VALID";
                                        return {
                                            primaryItemFields: newItems,
                                            globalMsg2: "Unable to Auto Assign Accession Number. \n Hosp: " + _hosp + " Year: " + _accYear + " does not exist in Accession Number lookup table.",
                                            replacementVal2: "EMPTY"
                                        };
                                    });
                                }
                            }).catch(function (error) {
                                console.log(error);
                                /*
                                //redirect to Login page by clicking the Logout button
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                //@TODO:...

                                return false;
                            });
                    } else if (value.length == 11) {
                        var _para = { patientId: this.props.patientId, accNbr: _accYear, accessToken: localStorage.getItem('token') };
                        axios.post(this.props.clientgroup + "/CheckExistedAccNbr", _para)
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data.error && res.data.message !== null && res.data.message !== "") {
                                    const message = res.data.message;
                                    //if (message === "Already Used") {
                                    this.setState(prevState => {
                                        const newItems = [...prevState.primaryItemFields];
                                        newItems[index][name] = "IN_VALID";
                                        return {
                                            primaryItemFields: newItems,
                                            globalMsg2: "The Accession Number, " + _accYear + " has already been used for Patient: " + message,
                                            replacementVal2: "EMPTY"
                                        };
                                    });
                                    //}
                                    return false;
                                }
                            }).catch(function (error) {
                                console.log(error);
                                /*
                                //redirect to Login page by clicking the Logout button
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                //@TODO:...

                                return false;
                            });
                    }
                    //--

                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];
                        newItems[index][name] = "";
                        return { primaryItemFields: newItems };
                    });
                }
            } else if (name === "medRecNbr") {
                if (value === "") {
                    const primariesArr = this.state.primaries;
                    const hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";

                    let hospIdx = -1;
                    primariesArr.map((prm, idx) => {
                        if (prm["hosp"] === hosp && idx != index) {
                            hospIdx = idx;
                            return false;
                        }
                    });

                    let setDefault = false;
                    let defaultVal = "";

                    if (hosp !== "" && value === "" && hospIdx !== -1) {
                        setDefault = true;
                        defaultVal = primariesArr[hospIdx]["medRecNbr"];
                    }

                    if (setDefault) {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = defaultVal;
                            return {
                                primaries: newItems,
                                isFormDirty: true,
                                pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                            };
                        });
                    }
                } else {
                    if (value.length > 20) {
                        let _newVal = value.replace(/[^0-9A-Za-z]/gi, '').substring(0, 20);
                        this.setState(prevState => {
                            const newItems = [...prevState.primaries];
                            newItems[index][name] = _newVal;
                            return {
                                primaries: newItems,
                                isFormDirty: true,
                                pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                            };
                        });
                    }

                    const pid = this.state.patientForm.pid;

                    const primariesArr = this.state.primaries;
                    const hosp = primariesArr[index]["hosp"] ? primariesArr[index]["hosp"] : "";

                    var _params = { patientId: pid, hosp: hosp, medRecNbr: value, accessToken: localStorage.getItem('token') };
                    axios.post(this.props.clientgroup + "/CheckExistedMrn", _params)
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res.data.error && res.data.message && res.data.message !== "") {
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index][name] = "IN_VALID";
                                    return {
                                        primaryItemFields: newItems,
                                        globalMsg3: "Medical Record Number, " + value + " has been used for Patient: " + res.data.message,
                                        replacementVal3: "EMPTY"
                                    };
                                });
                            } else {
                                this.setState(prevState => {
                                    const newItems = [...prevState.primaryItemFields];
                                    newItems[index][name] = "";
                                    return { primaryItemFields: newItems };
                                });
                            }
                        }).catch(function (error) {
                            console.log(error);
                            /*
                            //redirect to Login page by clicking the Logout button
                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                }
            } else if (name == "abstSunaFlag" && value !== "") {
                this.setState(prevState => {
                    const newItems = [...prevState.primaries];
                    newItems[index][name] = value.toUpperCase();
                    return {
                        primaries: newItems,
                        pAllItemFields: { ...this.state.pAllItemFields, [name]: "" }
                    };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "";
                    return { primaryItemFields: newItems };
                });
            }
        }
    }
    // --END

    handleFormChange = (event) => {
        this.setState({
            patientForm: {
                ...this.state.patientForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true
        });
    }
    handleContactTabChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.contacts];
            newItems[index][name] = value;
            return { contacts: newItems, isFormDirty: true };
        });
    }
    addNewContact = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.contacts];
            const firstContact = newItems[0];

            const newContact = {};
            Object.keys(firstContact).map((t, k) => {
                if (t === "id" || t === "pid" || t === "cid") {
                    newContact[t] = 0;
                } else {
                    newContact[t] = "";
                }
            });
            newItems.push(newContact);

            //
            const newFields = [...this.state.contactItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                newField[t] = "";
            });
            newFields.push(newField);
            return { contacts: newItems, contactItemFields: newFields, isFormDirty: true };
        });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
            // set focus into current input
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    deleteSelectedContact = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedContactsRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.contacts.length) {
            selectedRow = this.state.contacts.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.contacts];
            if (newItems.length === 1) {
                const _newItems = [...this.state.contacts];
                const firstContact = _newItems[0];

                const newContact = {};
                Object.keys(firstContact).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        newContact[t] = 0;
                    } else {
                        newContact[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newContact);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.contactItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.contactItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { contacts: newItems, contactItemFields: newFields, isFormDirty: true, selectedContactsRowIndex: selectedRow };
        });
    }

    hideTransmitTextDialog = (event, next) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            isShowTransmitTextDialog: false,
            isShowDeletionMsg: true,//(next == "1" ? true : false),
            patientForm: {
                ...this.state.patientForm,
                ["caDeletion"]: "1"
            },
        });

        return false;
    }

    deleteMedicalRecord = (event, index) => {
        event.preventDefault();
        this.setState(prevState => {
            const primItems = [...this.state.primaries];
            const currentPrimary = primItems[index];

            const state = this.props.currentShowingState;
            var text = this.state.patientForm && this.state.patientForm.transmitText ? this.state.patientForm.transmitText : "";
            var stFlag = currentPrimary["stateRpt"] ? currentPrimary["stateRpt"] : "";

            //state == 'CA' && text == '' && CID != '' && (stFlag == 'C' || stFlag == 'F' || stFlag == 'T')
            if (state == 'CA' && text == '' && currentPrimary["cid"] && currentPrimary["cid"] != 0
                && (stFlag == 'C' || stFlag == 'F' || stFlag == 'T')) {
                this.setState({
                    deletedItem: currentPrimary,
                    deletedItemIndex: index,
                    isShowTransmitTextDialog: true,
                });
                return false;
            } else if (currentPrimary["cid"] && currentPrimary["cid"] != 0) {
                this.setState({ deletedItem: currentPrimary, deletedItemIndex: index, isShowDeletionMsg: true });
                return false;
            } else if (primItems.length > 3) {
                primItems.splice(index, 1);
                const primFields = [...this.state.primaryItemFields];
                primFields.splice(index, 1);

                return {
                    primaries: primItems,
                    primaryItemFields: primFields,
                    isFormDirty: true,
                };
            }

            const newPrimary = {};
            Object.keys(currentPrimary).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    //newPrimary[t] = 0;
                } else {
                    newPrimary[t] = "";
                }
            });
            primItems[index] = newPrimary;

            // clear invalid class
            const primFields = [...this.state.primaryItemFields];
            const currItem = primFields[index];

            const newItem = {};
            Object.keys(currItem).map((t) => {
                newItem[t] = "";
            });
            primFields[index] = newItem;

            return {
                primaries: primItems,
                primaryItemFields: primFields,
                isFormDirty: true,
            };
        });
    }

    handlePrimaryGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            newItems[index][name] = value;
            newItems[index]["isPrimaryDirty"] = true;

            const primFields = [...prevState.primaryItemFields];
            primFields[index][name] = "";

            return {
                primaries: newItems, primaryItemFields: primFields, isFormDirty: true,
                ignoreShowNotificationSeqPrimMsg: false,
                hasNotificationSeqPrimMsg: false,
            };
        });
    }

    addNewPrimary = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.primaries];
            const firstPrimary = newItems[0];

            const newPrimary = {};
            Object.keys(firstPrimary).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newPrimary[t] = 0;
                } else {
                    newPrimary[t] = "";
                }
            });
            newItems.push(newPrimary);

            // 
            const newFields = [...this.state.primaryItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { primaries: newItems, primaryItemFields: newFields, isFormDirty: true };
        });
    }

    hideNotificationStateMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationStateMsg: false });
        return false;
    }
    updateDataStateInfo = () => {
        const newState = this.state.updatedDataStateInfo;
        const fState = this.state.updatingItemState;
        if (fState === "currSt") {
            this.setState({
                patientForm: {
                    ...this.state.patientForm,
                    [fState]: newState
                },
                hasNotificationStateMsg: false,
                updatedDataStateInfo: null,
                isFormDirty: true,
                // clear IN_VALID flag if it's existing
                pAllItemFields: {
                    ...this.state.pAllItemFields,
                    [fState]: "",
                },
            });
        } else if (fState === "contSt") {
            const index = this.state.updatingStateIndex;

            this.setState(prevState => {
                const newItems = [...prevState.contacts];
                newItems[index][fState] = newState;

                const newFields = [...prevState.contactItemFields];
                newFields[index][fState] = "";

                return {
                    contacts: newItems,
                    hasNotificationStateMsg: false,
                    updatedDataStateInfo: null,
                    isFormDirty: true,
                    // clear IN_VALID flag if it's existing
                    contactItemFields: newFields
                };
            });
        }

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    hideNotificationCountyMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCountyMsg: false });
        return false;
    }
    updateDataCountyInfo = () => {
        const newCounty = this.state.updatedDataCountyInfo;
        this.setState({
            patientForm: {
                ...this.state.patientForm,
                currCnty: newCounty
            },
            hasNotificationCountyMsg: false,
            updatedDataCountyInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            pAllItemFields: {
                ...this.state.pAllItemFields,
                currCnty: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    hideNotificationCityMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCityMsg: false });
        return false;
    }
    updateDataCityInfo = () => {
        const newCity = this.state.updatedDataCityInfo;
        const fCity = this.state.updatingItemCity;
        if (fCity === "currCity") {
            this.setState({
                patientForm: {
                    ...this.state.patientForm,
                    [fCity]: newCity
                },
                hasNotificationCityMsg: false,
                updatedDataCityInfo: null,
                isFormDirty: true,
                // clear IN_VALID flag if it's existing
                pAllItemFields: {
                    ...this.state.pAllItemFields,
                    [fCity]: "",
                },
            });
        } else if (fCity === "contCity") {
            const index = this.state.updatingCityIndex;

            this.setState(prevState => {
                const newItems = [...prevState.contacts];
                newItems[index][fCity] = newCity;

                const newFields = [...prevState.contactItemFields];
                newFields[index][fCity] = "";

                return {
                    contacts: newItems,
                    hasNotificationCityMsg: false,
                    updatedDataCityInfo: null,
                    isFormDirty: true,
                    // clear IN_VALID flag if it's existing
                    contactItemFields: newFields
                };
            });
        }

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    hideErrorMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasErrorOccurred: false });
        return false;
    }

    hideDeletionMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }

        } catch (ex) { }
        this.setState({ isShowDeletionMsg: false });
        return false;
    }

    deletePrimaryItem = (event) => {
        event.preventDefault();
        var text = this.state.patientForm && this.state.patientForm.transmitText ? this.state.patientForm.transmitText : "";

        this.setState({
            isShowDeletionMsg: false,
            patientForm: {
                ...this.state.patientForm,
                ["transmitText"]: ""
            },
        });

        const pItem = this.state.deletedItem;
        const pItemIndex = this.state.deletedItemIndex;

        this.props.deletePrimaryItem(pItem, text);

        this.setState(prevState => {
            // Also remove deleted row on grid
            const primItems = [...this.state.primaries];
            const newPrimary = {};
            Object.keys(primItems[pItemIndex]).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    //newPrimary[t] = 0;
                    if (t === "cid") {
                        newPrimary[t] = 0;
                    }
                } else {
                    newPrimary[t] = "";
                }
            });
            primItems[pItemIndex] = newPrimary;

            // clear invalid class
            const primFields = [...this.state.primaryItemFields];
            const currItem = primFields[pItemIndex];

            const newItem = {};
            Object.keys(currItem).map((t) => {
                newItem[t] = "";
            });
            primFields[pItemIndex] = newItem;

            return {
                primaries: primItems,
                primaryItemFields: primFields,
            };
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocusByIndex ? this.state.fieldInFocusByIndex : this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    // #PR00056 --START
    hideNotificationSeqPrimMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            let seqPrimIndex = this.state.updatingSeqPrimIndex;
            seqPrimIndex = parseInt(seqPrimIndex) + 1;
            setTimeout(function () {
                document.getElementById("hosp" + seqPrimIndex) ? document.getElementById("hosp" + seqPrimIndex).focus() : "";
            }, 100);

        } catch (ex) { }
        this.setState({ hasNotificationSeqPrimMsg: false, ignoreShowNotificationSeqPrimMsg: true });
        return false;
    }
    updateDataSeqPrimInfo = () => {
        const newSeqPrim = this.state.updatedDataSeqPrimInfo;
        const seqPrimIndex = this.state.updatingSeqPrimIndex;

        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            newItems[seqPrimIndex]["seqPrim"] = newSeqPrim.substring(0, 1) + '1';

            // clear IN_VALID flag if it's existing
            const primFields = [...prevState.primaryItemFields];
            primFields[seqPrimIndex]["seqPrim"] = "";

            return {
                primaries: newItems,
                hasNotificationSeqPrimMsg: false,
                ignoreShowNotificationSeqPrimMsg: false,
                updatedDataSeqPrimInfo: null,
                // clear IN_VALID flag if it's existing
                primaryItemFields: primFields,
                isFormDirty: true
            };
        });

        try {
            let _seqPrimIndex = parseInt(seqPrimIndex) + 1;
            setTimeout(function () {
                document.getElementById("seqPrim" + _seqPrimIndex) ? document.getElementById("seqPrim" + _seqPrimIndex).focus() : "";
            }, 100);
        } catch (ex) { }
    }
    // --END

    render() {
        let transmitTextDialog;
        {
            const deletedItem = this.state.deletedItem;
            transmitTextDialog = this.state.isShowTransmitTextDialog && deletedItem !== null ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls California_Deletion_Text_Form">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">California Deletion Text</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close hidden">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTransmitTextDialog(e, '0') }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div>
                                            <Input type="textarea" lostfocused={this.handleOnBlur}
                                                focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Please document for the State of California the reason for deleting this record"
                                                labelWidth="auto" field="transmitText" value={this.state.patientForm.transmitText}
                                                width="470px" height="80px" //normalTaField={true}
                                                maxLength="150" autoFocus
                                                textPadFields={true} labelStyle="TextPadLbl"
                                                title="If you are deleting abstracts previously sent to the State, the text entered here will be included when these deletions are sent to the State."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button disabled={!this.state.patientForm.transmitText || this.state.patientForm.transmitText == "" ? true : false}
                                    onClick={(e) => { this.hideTransmitTextDialog(e, '1') }} className="validationErr_btnOk_Cls">OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let deletionMsg;
        {
            const deletedItem = this.state.deletedItem;

            let abstFlag = deletedItem !== null ? deletedItem["abstSunaFlag"] : "";
            let stFlag = deletedItem !== null ? deletedItem["stateRpt"] : "";
            if (abstFlag === "A") {
                abstFlag = "Abstract";
            } else {
                abstFlag = "Suspense Case";
            }

            deletionMsg = this.state.isShowDeletionMsg && deletedItem !== null ?
                /*
                <Window title="Deletion Confirmation"
                    onClose={this.hideDeletionMsg}
                    width={300} height={"auto"}
                    className={classes.title}
                    draggable={true}
                >
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Deletion Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDeletionMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to <em>permanently</em> delete this {abstFlag === "Abstract" ? abstFlag + " Primary" : abstFlag}?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <table className="container deletionConfirmationMsg">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Site</th>
                                        <th>Sequence</th>
                                        <th>Hosp</th>
                                        <th>Class</th>
                                        <th>Diagnosis Date</th>
                                        <th>Accession Nbr</th>
                                        <th>Med Rec Nbr</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{abstFlag}</td>
                                        <td>{deletedItem["siteCode"]}</td>
                                        <td>{deletedItem["seqPrim"]}</td>
                                        <td>{deletedItem["hosp"]}</td>
                                        <td>{deletedItem["class"]}</td>
                                        <td>{deletedItem["diagnosisDate"] != null && deletedItem["diagnosisDate"] !== "" ? moment(new Date(deletedItem["diagnosisDate"])).format("MM/DD/YYYY") : ""}</td>
                                        <td>{deletedItem["accNbr"]}</td>
                                        <td>{deletedItem["medRecNbr"]}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="emptyLine"></div>

                            {
                                abstFlag === "Abstract" && (stFlag === "C" || stFlag === "F" || stFlag === "T") ?
                                    <div>
                                        <p><b><span style={{ color: "#D30505" }}>Alert:</span> The State Report Flag indicates this abstract has been previously submitted to the state!</b></p>
                                        <div className="emptyLine"></div>
                                    </div>
                                    : ""
                            }

                            <div className="container right">
                                <button className="ErsGrBlueButton" onClick={this.deletePrimaryItem} value="yes" autoFocus>Yes</button>
                                <button className="ErsGrBlueButton" onClick={this.hideDeletionMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Window>*/}
                    </Draggable>
                </div>
                : null
        }

        let hasNotificationStateMsg;
        {
            hasNotificationStateMsg = this.state.hasNotificationStateMsg ?
                /*
                <Dialog title="Notification"
                    onClose={this.hideNotificationStateMsg}
                    width={450} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationStateMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested state for this zip code is '{this.state.updatedDataStateInfo}'.<br />
                                    Would you like to update the state to {this.state.updatedDataStateInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataStateInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationStateMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }
        let hasNotificationCountyMsg;
        {
            hasNotificationCountyMsg = this.state.hasNotificationCountyMsg ?
                /*
                <Dialog title="Notification"
                    onClose={this.hideNotificationCountyMsg}
                    width={450} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCountyMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested county for this zip code is '{this.state.updatedDataCountyInfo}'.<br />
                                    Would you like to update the county to {this.state.updatedDataCountyInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCountyInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCountyMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }
        let hasNotificationCityMsg;
        {
            hasNotificationCityMsg = this.state.hasNotificationCityMsg ?
                /*
                <Dialog title="Notification"
                    onClose={this.hideNotificationCityMsg}
                    width={450} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCityMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested city for this zip code is '{this.state.updatedDataCityInfo}'.<br />
                                    Would you like to update the city to {this.state.updatedDataCityInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCityInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCityMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let hasErrorOccurred;
        {
            hasErrorOccurred = this.state.hasErrorOccurred ?
                /*
                <Dialog title="Error"
                    onClose={this.hideErrorMsg}
                    width={450} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideErrorMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>The data did not save due to the following error: An internal ID (PID) <br />could not be derived for this patient record</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.hideErrorMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let hasNotificationSeqPrimMsg;
        {
            const checkSeq = this.state.updatedDataSeqPrimInfo ? this.state.updatedDataSeqPrimInfo : "";
            hasNotificationSeqPrimMsg = !this.state.ignoreShowNotificationSeqPrimMsg && this.state.hasNotificationSeqPrimMsg ?
                /*
                <Dialog title="Notification"
                    onClose={this.hideNotificationSeqPrimMsg}
                    width={450} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationSeqPrimMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>{"Would you like to update the " + checkSeq + " sequence to " + checkSeq.substring(0, 1) + "1?"}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataSeqPrimInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationSeqPrimMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const patientMaintenance = this.state.patientForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={(event) => { this.savePatient(event) }} id="patientForm" className="DialogForm TabParentPage">
                        <div className="row" style={{ margin: "0px" }}>
                            <div className="col-md-11"></div>
                            <div className="col-md-1 helpInfo0" style={{ textAlign: "center" }}>
                                <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />                                    </button>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                        <div className="row">
                            <div className="col-md-2 patCol1" width="235px">
                                <div className="frameInner col1">
                                    <div className="header">&nbsp;</div>

                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Soc Sec Nbr" field="socSecNbr"
                                        value={this.state.patientForm.socSecNbr} labelWidth="100px" width="90px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["socSecNbr"] : ""}
                                        inValid={this.state.pAllItemFields["socSecNbr"]} itemNbr="116"
                                        globalMsg={this.state.globalMsg} autoFocus
                                        tabIndex="1"
                                    />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="MBI" field="medicareBeneficiaryId" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.medicareBeneficiaryId} itemNbr="4138"
                                        inValid={this.state.pAllItemFields["medicareBeneficiaryId"]}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medicareBeneficiaryId"] : ""}
                                        tabIndex="2" />

                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Id Nbr" field="idNbr" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.idNbr} itemNbr="184"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["idNbr"] : ""}
                                        tabIndex="3" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Name Prefix" field="namePrefix" labelWidth="100px" width="60px"
                                        value={this.state.patientForm.namePrefix} itemNbr="794"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["namePrefix"] : ""}
                                        tabIndex="4" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Last" field="lastName" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.lastName} itemNbr="1"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                        tabIndex="5" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="First" field="firstName" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.firstName} itemNbr="20"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                        tabIndex="6" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Middle" field="middleName" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.middleName} itemNbr="365"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["middleName"] : ""}
                                        tabIndex="7" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Birth Surname" field="birthSurname" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.birthSurname} itemNbr="4479"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["birthSurname"] : ""}
                                        tabIndex="8" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Name Suffix" field="nameSuffix" labelWidth="100px" width="60px"
                                        value={this.state.patientForm.nameSuffix} itemNbr="795"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nameSuffix"] : ""}
                                        tabIndex="9" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Alias" field="alias" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.alias} itemNbr="52"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["alias"] : ""}
                                        tabIndex="10" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Phone Nbr" field="phoneNbr" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.phoneNbr} itemNbr="17"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["phoneNbr"] : ""}
                                        tabIndex="11" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Phone Nbr 2" field="secondaryPhoneNbr" labelWidth="100px" width="100px"
                                        value={this.state.patientForm.secondaryPhoneNbr} itemNbr="3143"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryPhoneNbr"] : ""}
                                        tabIndex="12" />
                                </div>
                            </div>
                            <div className="col-md-3 patCol2" width="315px">
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Email" field="emailAddress"
                                        value={this.state.patientForm.emailAddress} labelWidth="100px" width="198px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["emailAddress"] : ""}
                                        inValid={this.state.pAllItemFields["emailAddress"]} itemNbr="3144"
                                        errMsgType="is not a valid email."
                                        tabIndex="13"
                                    />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Curr Add" field="currAdd"
                                        value={this.state.patientForm.currAdd} labelWidth="100px" width="198px" itemNbr="11"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currAdd"] : ""}
                                        tabIndex="14" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Curr Add 2" field="currAdd2"
                                        value={this.state.patientForm.currAdd2} labelWidth="100px" width="198px" itemNbr="2100"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currAdd2"] : ""}
                                        tabIndex="15" />
                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Curr City" field="currCity"
                                        value={this.state.patientForm.currCity} labelWidth="100px" width="125px" itemNbr="19"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currCity"] : ""}
                                        tabIndex="16" />

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Curr St" field="currSt" lostfocused={this.handleLostFocus}
                                        value={this.state.patientForm.currSt ? this.state.patientForm.currSt : this.state.patientForm.CurrSt}
                                        dataSource="State_Codes" titleDialog="Current State" labelWidth="100px" width="40px"
                                        inValid={this.state.pAllItemFields["currSt"]} itemNbr="24" aliasNo="24"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currSt"] : ""}
                                        tabIndex="17"
                                    />

                                    <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Zip" field="currZip"
                                        value={this.state.patientForm.currZip} labelWidth="100px" width="80px" itemNbr="12"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currZip"] : ""}
                                        tabIndex="18" />

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Curr County" field="currCnty" lostfocused={this.handleLostFocus}
                                        value={this.state.patientForm.currCnty ? this.state.patientForm.currCnty : this.state.patientForm.CurrCnty}
                                        dataSource="County_Codes" titleDialog="Current County" labelWidth="100px" width="80px"
                                        inValid={this.state.pAllItemFields["currCnty"]} itemNbr="393" aliasNo="393"
                                        flag={this.state.patientForm.currSt ? this.state.patientForm.currSt : this.state.patientForm.CurrSt}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currCnty"] : ""}
                                        tabIndex="19"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Curr Country" field="currCountry" lostfocused={this.handleLostFocus}
                                        value={this.state.patientForm.currCountry ? this.state.patientForm.currCountry : this.state.patientForm.CurrCountry}
                                        dataSource="Country_Codes" titleDialog="Current Country" labelWidth="100px" width="80px"
                                        inValid={this.state.pAllItemFields["currCountry"]} itemNbr="771" aliasNo="771"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["currCountry"] : ""}
                                        tabIndex="20"
                                    />

                                    <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Dob" field="dob"
                                        value={this.state.patientForm.dob != null && this.state.patientForm.dob !== "" ? moment(new Date(this.state.patientForm.dob)).format("MM/DD/YYYY") : ""}
                                        labelWidth="100px" width="185px"
                                        lostfocused={this.handleLostFocus}
                                        // #20210125 - START
                                        // Add day/month/year values
                                        inValidDay={this.state.pAllItemFields["dobDay"]}
                                        inValidMonth={this.state.pAllItemFields["dobMonth"]}
                                        inValidYear={this.state.pAllItemFields["dobYear"]}
                                        dayVal={this.state.patientForm.dobDay}
                                        monthVal={this.state.patientForm.dobMonth}
                                        yearVal={this.state.patientForm.dobYear}
                                        //#20210125 - END
                                        itemNbr=""
                                        tabIndex="21"
                                    />

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Birth Country" field="pob" lostfocused={this.handleLostFocus}
                                        value={this.state.patientForm.pob ? this.state.patientForm.pob : this.state.patientForm.Pob}
                                        dataSource="Country_Codes" titleDialog="Birth Country" labelWidth="100px" width="80px"
                                        inValid={this.state.pAllItemFields["pob"]} itemNbr="6" aliasNo="6"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pob"] : ""}
                                        tabIndex="22"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Birth State" field="birthState" lostfocused={this.handleLostFocus}
                                        value={this.state.patientForm.birthState ? this.state.patientForm.birthState : this.state.patientForm.BirthState}
                                        dataSource="State_Codes" titleDialog="Birth State" labelWidth="100px" width="40px"
                                        inValid={this.state.pAllItemFields["birthState"]} itemNbr="4014" aliasNo="24"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["birthState"] : ""}
                                        tabIndex="23"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Sex" field="sex" lostfocused={this.handleLostFocus}
                                        value={this.state.patientForm.sex ? this.state.patientForm.sex : this.state.patientForm.Sex}
                                        dataSource="Global_Dir_Mv" aliasNo="9" titleDialog="Sex" labelWidth="100px" width="40px"
                                        inValid={this.state.pAllItemFields["sex"]} itemNbr="9"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sex"] : ""}
                                        tabIndex="24"
                                    />
                                </div>
                            </div>
                            <div className="col-md-7 patCol3" style={{ width: "700px", maxWidth: "700px" }}>
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <div style={{ height: "240px", /*border: "1px solid #eee",*/ marginBottom: "15px" }}>
                                        <Nav tabs tabIndex={0} autoFocus name="navTabOnPatientMaint">
                                            <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(event) => { this.toggleTab(event, "1") }} href="#" id="linkOfPatientTab">
                                                Patient
                                            </NavLink>
                                            <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(event) => { this.toggleTab(event, "2") }} href="#" id="linkOfContactsTab">
                                                Contacts
                                            </NavLink>
                                            <NavLink className={classnames({ active: this.state.activeTab === "3" })} onClick={(event) => { this.toggleTab(event, "3") }} href="#" id="linkOfRemarksTab">
                                                *Remarks
                                            </NavLink>
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab} >
                                            <TabPane tabId="1">
                                                <div className="row">
                                                    <div className="col-sm-3 patClm1-- ">
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Race1" field="race" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.race ? this.state.patientForm.race : this.state.patientForm.Race}
                                                                labelWidth="50px" width="30px" dataSource="Global_Dir_Mv" aliasNo="18" titleDialog="Race"
                                                                inValid={this.state.pAllItemFields["race"]} itemNbr="18"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["race"] : ""}
                                                                tabIndex="25"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Race2" field="race2" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.race2 ? this.state.patientForm.race2 : this.state.patientForm.Race2}
                                                                labelWidth="50px" width="30px" dataSource="Global_Dir_Mv" aliasNo="1823" titleDialog="Race 2"
                                                                inValid={this.state.pAllItemFields["race2"]} itemNbr="1823"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["race2"] : ""}
                                                                tabIndex="26"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Race3" field="race3" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.race3 ? this.state.patientForm.race3 : this.state.patientForm.Race3}
                                                                labelWidth="50px" width="30px" dataSource="Global_Dir_Mv" aliasNo="1824" titleDialog="Race 3"
                                                                inValid={this.state.pAllItemFields["race3"]} itemNbr="1824"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["race3"] : ""}
                                                                tabIndex="27"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Race4" field="race4" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.race4 ? this.state.patientForm.race4 : this.state.patientForm.Race4}
                                                                labelWidth="50px" width="30px" dataSource="Global_Dir_Mv" aliasNo="1825" titleDialog="Race 4"
                                                                inValid={this.state.pAllItemFields["race4"]} itemNbr="1825"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["race4"] : ""}
                                                                tabIndex="28"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Race5" field="race5" value={this.state.patientForm.race5 ? this.state.patientForm.race5 : this.state.patientForm.Race5}
                                                                labelWidth="50px" width="30px" dataSource="Global_Dir_Mv" aliasNo="1826" titleDialog="Race 5"
                                                                inValid={this.state.pAllItemFields["race5"]} itemNbr="1826" lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["race5"] : ""}
                                                                tabIndex="29"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Ethn" field="ethn" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.ethn ? this.state.patientForm.ethn : this.state.patientForm.Ethn}
                                                                labelWidth="50px" width="30px" dataSource="Global_Dir_Mv" aliasNo="8" titleDialog="Ethn"
                                                                inValid={this.state.pAllItemFields["ethn"]} itemNbr="8"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ethn"] : ""}
                                                                tabIndex="30"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 patClm1">
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Fam Ca Hist" field="famCaHistory" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.famCaHistory ? this.state.patientForm.famCaHistory : this.state.patientForm.FamCaHistory}
                                                                labelWidth="75px" width="30px" dataSource="Global_Dir_Mv" aliasNo="798" titleDialog="Fam Ca History"
                                                                inValid={this.state.pAllItemFields["famCaHistory"]} itemNbr="798"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["famCaHistory"] : ""}
                                                                tabIndex="31"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Tobacco Hist" field="tobaccoHistory" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.tobaccoHistory ? this.state.patientForm.tobaccoHistory : this.state.patientForm.TobaccoHistory}
                                                                labelWidth="75px" width="30px" dataSource="Global_Dir_Mv" aliasNo="797" titleDialog="Tobacco History"
                                                                inValid={this.state.pAllItemFields["tobaccoHistory"]} itemNbr="797"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobaccoHistory"] : ""}
                                                                tabIndex="32"
                                                            />
                                                        </div>
                                                        {/* --Moved to Dx Maint --
                                                            <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Tobacco 22+" field="tobaccoUseSmokingStatus" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.tobaccoUseSmokingStatus ? this.state.patientForm.tobaccoUseSmokingStatus : this.state.patientForm.TobaccoUseSmokingStatus}
                                                                labelWidth="75px" width="30px" dataSource="Global_Dir_Mv" aliasNo="4521" titleDialog="Tobacco Use Smoking Status 2022+"
                                                                inValid={this.state.pAllItemFields["tobaccoUseSmokingStatus"]} itemNbr="4521"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobaccoUseSmokingStatus"] : ""}
                                                                tabIndex="33"
                                                            />
                                                        </div> */}
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Alcohol Hist" field="alcoholHistory" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.alcoholHistory ? this.state.patientForm.alcoholHistory : this.state.patientForm.AlcoholHistory}
                                                                labelWidth="75px" width="30px" dataSource="Global_Dir_Mv" aliasNo="799" titleDialog="Alcohol History"
                                                                inValid={this.state.pAllItemFields["alcoholHistory"]} itemNbr="799"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["alcoholHistory"] : ""}
                                                                tabIndex="34"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Religion" field="religion" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.religion ? this.state.patientForm.religion : this.state.patientForm.Religion}
                                                                labelWidth="75px" width="30px" dataSource="Global_Dir_Mv" aliasNo="503" titleDialog="Religion"
                                                                inValid={this.state.pAllItemFields["religion"]} itemNbr="503"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["religion"] : ""}
                                                                tabIndex="35"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Pt Contact" field="noPatientContact" lostfocused={this.handleLostFocus}
                                                                value={this.state.patientForm.noPatientContact ? this.state.patientForm.noPatientContact : this.state.patientForm.NoPatientContact}
                                                                labelWidth="75px" width="30px" dataSource="Global_Dir_Mv" aliasNo="4548" titleDialog="Patient Contact"
                                                                inValid={this.state.pAllItemFields["noPatientContact"]} itemNbr="4548"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["noPatientContact"] : ""}
                                                                tabIndex="36"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 usualCommentCls">
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Usual Occ" field="usualOcc" value={this.state.patientForm.usualOcc} labelWidth="100px" itemNbr="325"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["usualOcc"] : ""} hasDoubleClickEvent
                                                                tabIndex="37" maxlen="100" width="175px"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="input" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Usual Bus Ind" field="usualBusInd" value={this.state.patientForm.usualBusInd} labelWidth="100px" itemNbr="326"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["usualBusInd"] : ""} hasDoubleClickEvent
                                                                tabIndex="38" maxlen="100" width="175px"
                                                            />
                                                        </div>
                                                        <div style={{ marginRight: "15px" }}>
                                                            <Input type="textarea" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Comments" labelWidth="auto" field="comments" value={this.state.patientForm.comments} itemNbr="13"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comments"] : ""}
                                                                tabIndex="39" width="280px" height="100px"
                                                                hasDoubleClickEvent //ONLY set for fields have DoubleClick event (="warning": show confirm message if data has changed)
                                                                maxlen="350" //this property using for Text Editor popup's setting to maxlength...
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="contactListArea">
                                                    {this.state.contactItemFields && this.state.contacts !== null && this.state.contacts.length > 0 ? this.state.contacts.map((cont, index) => (
                                                        <div onClick={(e) => { this.setSelectionContactsRow(e, index) }} key={index}
                                                            className={(this.state.selectedContactsRowIndex === index ? "selectedRowCls" : "") + (index > 0 ? " visual_separater" : "")}>
                                                            <div className="indexCls">
                                                                <label>{index + 1}</label>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="First Name" field="contFirstName" value={cont.contFirstName} labelWidth="90px" itemNbr="453"
                                                                        keyId={"contFirstName" + index}
                                                                        //maxLength="50"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contFirstName"] : ""}
                                                                        tabIndex={40 + (index * 9)} />
                                                                    <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="Street" field="contStreet" value={cont.contStreet} labelWidth="90px" itemNbr="452"
                                                                        //maxLength="50"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contStreet"] : ""}
                                                                        tabIndex={42 + (index * 9)} />
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                            labelText="State" field="contSt" lostfocused={(e) => { this.handleLostFocusContactTab(e, index) }}
                                                                            value={cont.contSt ? cont.contSt : cont.ContSt}
                                                                            dataSource="State_Codes" titleDialog="State Lookup"
                                                                            inValid={this.state.contactItemFields[index] ? this.state.contactItemFields[index]["contSt"] : ""}
                                                                            labelWidth="90px" width="50px" itemNbr="456"
                                                                            //maxLength="2"
                                                                            maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contSt"] : ""}
                                                                            tabIndex={44 + (index * 9)} aliasNo="456"
                                                                            rowIndex={index}
                                                                            keyId={"contSt" + index}
                                                                        />
                                                                        <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                            labelText="Zip" field="contZip" value={cont.contZip} width="84px"
                                                                            labelWidth="50px" labelStyle="center" itemNbr="457"
                                                                            //maxLength="20"
                                                                            maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contZip"] : ""}
                                                                            lostfocused={(e) => { this.handleLostFocusContactTab(e, index) }}
                                                                            tabIndex={45 + (index * 9)} />
                                                                    </div>
                                                                    <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="Phone" field="contPhone" value={cont.contPhone}
                                                                        labelWidth="90px" itemNbr="458"
                                                                        //maxLength="15"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contPhone"] : ""}
                                                                        lostfocused={(e) => { this.handleLostFocusContactTab(e, index) }}
                                                                        tabIndex={47 + (index * 9)} />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="Last Name" field="contLastName" value={cont.contLastName}
                                                                        labelWidth="90px" itemNbr="452"
                                                                        //maxLength="50"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contLastName"] : ""}
                                                                        tabIndex={41 + (index * 9)} />
                                                                    <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="City" field="contCity" value={cont.contCity}
                                                                        labelWidth="90px" itemNbr="455"
                                                                        //maxLength="50"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contCity"] : ""}
                                                                        tabIndex={43 + (index * 9)} />
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="Country" field="contCountry" labelWidth="90px" itemNbr="4002"
                                                                        value={cont.contCountry ? cont.contCountry : cont.ContCountry}
                                                                        dataSource="Country_Codes" titleDialog="Select Country:"
                                                                        inValid={this.state.contactItemFields[index] ? this.state.contactItemFields[index]["contCountry"] : ""}
                                                                        //maxLength="3"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contCountry"] : ""}
                                                                        lostfocused={(e) => { this.handleLostFocusContactTab(e, index) }}
                                                                        tabIndex={46 + (index * 9)} aliasNo="4002"
                                                                        rowIndex={index}
                                                                        keyId={"contCountry" + index}
                                                                    />
                                                                    <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleContactTabChange(e, index) }}
                                                                        labelText="Relation" field="contRelation" value={cont.contRelation}
                                                                        labelWidth="90px" itemNbr="459"
                                                                        //maxLength="30"
                                                                        maxLength={this.state.contactMaxlengthInput ? this.state.contactMaxlengthInput["contRelation"] : ""}
                                                                        tabIndex={48 + (index * 9)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : '...'
                                                    }
                                                </div>
                                                <div className="contactBtnArea right" style={{ marginTop: "0px" }}>
                                                    <button name="btnAddNewCont" onClick={this.addNewContact} tabIndex={this.state.contacts !== null && this.state.contacts.length > 0 ? (39 + (9 * this.state.contacts.length)) : 49}>Add</button>
                                                    <button name="btnDelLastCont" onClick={this.deleteSelectedContact} tabIndex={this.state.contacts !== null && this.state.contacts.length > 0 ? (40 + (9 * this.state.contacts.length)) : 50} onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <div className="col">
                                                    <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Suspense Remarks" labelWidth="auto" field="suspenseRemarks"
                                                        value={this.state.patientForm.suspenseRemarks}
                                                        inValid={this.state.pAllItemFields["suspenseRemarks"]} itemNbr="4069"
                                                        tabIndex={this.state.contacts !== null && this.state.contacts.length > 0 ? (41 + (9 * this.state.contacts.length)) : 51}
                                                        //lostfocused={this.handleLostFocus}
                                                        rows="6" hasDoubleClickEvent="warning"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["suspenseRemarks"] : ""}
                                                    />
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>

                                    <div className="primaryListArea">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "52px" }}>&nbsp;</th>
                                                    <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="S/A" field="abstSunaFlag" /></th>
                                                    <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="Flag" field="stateRpt" /></th>
                                                    <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Site" field="siteCode" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Seq" field="seqPrim" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hsp" field="hosp" /></th>
                                                    <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Class" field="class" /></th>
                                                    <th style={{ width: "112px" }}>
                                                        <Input type="gridHeader" labelWidth="auto" labelText="Diagnosis Date" field="diagnosisDate" />
                                                    </th>
                                                    <th style={{ width: "92px" }}><Input type="gridHeader" labelWidth="auto" labelText="Acc Nbr" field="accNbr" /></th>
                                                    <th style={{ width: "92px" }}>
                                                        <Input type="gridHeader" labelWidth="auto" labelText="Med Rec Nbr" field="medRecNbr" />
                                                    </th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                <tr>
                                                    <td colSpan="11">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="innerBody" style={{ width: "680px", height: "100px", overflow: "auto" }}>
                                            <table>
                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                    <tr>
                                                        <th style={{ width: "52px" }}>&nbsp;</th>
                                                        <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="S/A" field="abstSunaFlag" /></th>
                                                        <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="Flag" field="stateRpt" /></th>
                                                        <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Site" field="siteCode" /></th>
                                                        <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Seq" field="seqPrim" /></th>
                                                        <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hsp" field="hosp" /></th>
                                                        <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Class" field="class" /></th>
                                                        <th style={{ width: "112px" }}>
                                                            <Input type="gridHeader" labelWidth="auto" labelText="Diagnosis Date" field="diagnosisDate" />
                                                        </th>
                                                        <th style={{ width: "92px" }}><Input type="gridHeader" labelWidth="auto" labelText="Acc Nbr" field="accNbr" /></th>
                                                        <th style={{ width: "92px" }}>
                                                            <Input type="gridHeader" labelWidth="auto" labelText="Med Rec Nbr" field="medRecNbr" />
                                                        </th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.primaryItemFields
                                                        && this.state.primaries !== null && this.state.primaries.length > 0
                                                        ? this.state.primaries.map((prm, index) => (
                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionPrimaryRow(e, index) }} className={this.state.selectedPrimaryRowIndex === index ? "selectedRowCls" : ""}>
                                                                <td>
                                                                    {
                                                                        /*
                                                                         * (Abst_Suna_Flag != '' 
                                                                         * or State_Rpt != '' 
                                                                         * or  Site_Code != '' 
                                                                         * or Seq_Prim != '' 
                                                                         * or Hosp != '' 
                                                                         * or Class != '' 
                                                                         * or Diagnosis_Date != '' 
                                                                         * or Acc_Nbr != '' 
                                                                         * or Med_Rec_Nbr != '') and (FN_ROWDISABLED != '1')
                                                                         * */
                                                                        ((prm.abstSunaFlag !== null && prm.abstSunaFlag !== "")
                                                                            || (prm.stateRpt !== null && prm.stateRpt !== "")
                                                                            || (prm.siteCode !== null && prm.siteCode !== "")
                                                                            || (prm.seqPrim !== null && prm.seqPrim !== "")
                                                                            || (prm.hosp !== null && prm.hosp !== "")
                                                                            || (prm.class !== null && prm.class !== "")
                                                                            || (prm.diagnosisDate !== null && prm.diagnosisDate !== "" && prm.diagnosisDate.toString() !== "Invalid Date")
                                                                            || (prm.accNbr !== null && prm.accNbr !== "")
                                                                            || (prm.medRecNbr !== null && prm.medRecNbr !== ""))
                                                                            && (!this.state.availableHospsData || this.state.availableHospsData.indexOf(prm.hosp) != -1)
                                                                            ?
                                                                            <button id={"deletedDiagnosisBtn" + index} className="deletedDiagnosisBtn" name={"deletedDiagnosisBtn" + index}
                                                                                onKeyDown={this.keydownHandler} data-index={index}
                                                                                style={{ display: this.props.securityGroup !== "Read-only user" ? "" : "none", width: "50px", top: "0x" }}
                                                                                onClick={(event) => { this.deleteMedicalRecord(event, index) }}>Delete</button>
                                                                            : ""
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} labelWidth="0px" labelStyle={{ display: "none" }}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="abstSunaFlag" value={prm.abstSunaFlag ? prm.abstSunaFlag : ""}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Abst Suna Flag" aliasNo="4020" width="25px"
                                                                        inValid={this.state.primaryItemFields[index]["abstSunaFlag"]} lineStyle="oneLine"
                                                                        keyId={"abstSunaFlag" + index}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["abstSunaFlag"] : ""}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        rowIndex={index}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} labelWidth="0px"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="stateRpt" value={prm.stateRpt}
                                                                        dataSource="Global_Dir_Mv" titleDialog="State Rpt" aliasNo="200" width="25px"
                                                                        inValid={this.state.primaryItemFields[index]["stateRpt"]} lineStyle="oneLine"
                                                                        keyId={"stateRpt" + index} itemNbr="200"
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput && this.state.primariesMaxlengthInput["stateRpt"] ? this.state.primariesMaxlengthInput["stateRpt"] : "1"}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        rowIndex={index}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="siteCode" value={prm.siteCode} labelWidth="0px"
                                                                        dataSource="Site_Codes" titleDialog="Site Code" aliasNo="117" width="27px"
                                                                        inValid={this.state.primaryItemFields[index]["siteCode"]} lineStyle="oneLine"
                                                                        keyId={"siteCode" + index} itemNbr="117"
                                                                        //maxLength="2"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["siteCode"] : ""}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        rowIndex={index}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="input" focused={this.handleOnFocus} labelWidth="0px"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} labelText="" field="seqPrim"
                                                                        value={prm.seqPrim} lineStyle="oneLine" width="30px"
                                                                        alertTitle={this.state.dialogTitle ? this.state.dialogTitle : "Validation Error"}
                                                                        keyId={"seqPrim" + index} itemNbr="118"
                                                                        rowIndex={index}
                                                                        //maxLength="2"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["seqPrim"] : ""}
                                                                        globalMsg={this.state.globalMsg1}
                                                                        replacementVal={this.state.replacementVal1}
                                                                        inValid={this.state.primaryItemFields[index]["seqPrim"]}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="input" focused={this.handleOnFocus} labelWidth="0px"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} labelText="" field="hosp"
                                                                        value={prm.hosp} lineStyle="oneLine" width="30px"
                                                                        keyId={"hosp" + index} itemNbr="55"
                                                                        rowIndex={index}
                                                                        //maxLength="5"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hosp"] : ""}
                                                                        globalMsg={this.state.globalMsg1}
                                                                        alertTitle={this.state.dialogTitle ? this.state.dialogTitle : "Access Denied"}
                                                                        replacementVal={this.state.replacementVal1}
                                                                        inValid={this.state.primaryItemFields[index]["hosp"]}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="class" value={prm.class} labelWidth="0px"
                                                                        dataSource="Global_Dir_Mv" titleDialog="Class" aliasNo="147" width="27px"
                                                                        inValid={this.state.primaryItemFields[index]["class"]} lineStyle="oneLine"
                                                                        keyId={"class" + index} itemNbr="147"
                                                                        rowIndex={index}
                                                                        //maxLength="2"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["class"] : ""}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="date" focused={this.handleOnFocus} changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="diagnosisDate" labelWidth="0px"
                                                                        monthWidth="24px" dayWidth="24px" yearWidth="40px"
                                                                        rowIndex={index}
                                                                        value={prm.diagnosisDate != null && prm.diagnosisDate !== "" ? moment(new Date(prm.diagnosisDate)).format("MM/DD/YYYY") : ""}
                                                                        inValid={this.state.primaryItemFields[index]["diagnosisDate"]} lineStyle="oneLine"

                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.primaryItemFields[index]["diagnosisDateDay"]}
                                                                        inValidMonth={this.state.primaryItemFields[index]["diagnosisDateMonth"]}
                                                                        inValidYear={this.state.primaryItemFields[index]["diagnosisDateYear"]}
                                                                        dayVal={prm.diagnosisDateDay}
                                                                        monthVal={prm.diagnosisDateMonth}
                                                                        yearVal={prm.diagnosisDateYear}
                                                                        //#20210125 - END
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}

                                                                        globalMsg={this.state.globalMsg4}
                                                                        alertTitle={this.state.dialogTitle4 ? this.state.dialogTitle4 : "Validation Error"}
                                                                        replacementVal={this.state.replacementVal4}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="input" focused={(e) => { this.handleOnFocus(e, index) }} labelWidth="0px"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} labelText="" field="accNbr"
                                                                        value={prm.accNbr} lineStyle="oneLine" width="90px"
                                                                        keyId={"accNbr" + index}
                                                                        rowIndex={index}
                                                                        //maxLength="11"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["accNbr"] : ""}
                                                                        globalMsg={this.state.globalMsg2}
                                                                        replacementVal={this.state.replacementVal2}
                                                                        inValid={this.state.primaryItemFields[index]["accNbr"]}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="input" focused={(e) => { this.handleOnFocus(e, index) }} labelWidth="0px"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} labelText="" field="medRecNbr"
                                                                        value={prm.medRecNbr} width="90px" lineStyle="oneLine"
                                                                        keyId={"medRecNbr" + index}
                                                                        rowIndex={index}
                                                                        //maxLength="20"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["medRecNbr"] : ""}
                                                                        globalMsg={this.state.globalMsg3}
                                                                        replacementVal={this.state.replacementVal3}
                                                                        inValid={this.state.primaryItemFields[index]["medRecNbr"]}
                                                                        lostfocused={(e) => { this.handlePrimaryGridLostFocus(e, index) }}
                                                                        disabled={prm.cid && this.state.availableHospsData && this.state.availableHospsData.indexOf(prm.hospOrg) == -1}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {
                                                                        /*
                                                                         * Abst_Suna_Flag = 'S' or (Abst_Suna_Flag = 'A' and Site_Code != '' and Seq_Prim != '' and Hosp != '' and Class != '' and (Diagnosis_Date_Month != '' or Diagnosis_Date_Day != '' or  Diagnosis_Date_Year != '') and Acc_Nbr != '')
                                                                        */
                                                                        prm.abstSunaFlag === "S"
                                                                            || (prm.abstSunaFlag === "A" && prm.siteCode && prm.siteCode !== ""
                                                                                && prm.seqPrim && prm.seqPrim !== ""
                                                                                && prm.hosp && prm.hosp !== ""
                                                                                && prm.class && prm.class !== ""
                                                                                //&& prm.diagnosisDate && prm.diagnosisDate !== ""
                                                                                && prm.diagnosisDateMonth && prm.diagnosisDateMonth !== ""
                                                                                && prm.diagnosisDateDay && prm.diagnosisDateDay !== ""
                                                                                && prm.diagnosisDateYear && prm.diagnosisDateYear !== ""
                                                                                && prm.accNbr && prm.accNbr !== ""
                                                                            ) ?
                                                                            <button className="selectedDiagnosisBtnOnGrid"
                                                                                style={{ width: "50px", top: "0px", position: "relative" }}
                                                                                id={"selectedDiagnosisOnGridBtn" + index} name={"selectedDiagnosisOnGridBtn" + index}
                                                                                onKeyDown={this.keydownHandler} data-index={index}
                                                                                onClick={(event) => { this.selectMedicalRecord(event, prm, index) }}>Select</button>
                                                                            : ""
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td colSpan="11">No records available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="primaryBtnArea">
                                        <button className="ErsGrBlueButton" id="pformAddNewPrimary" onClick={this.addNewPrimary}>New Primary</button>
                                    </div>
                                    <div className="row right">
                                        <div className="col-sm-6">
                                            <label>Date of Last Contact: {this.props.selectedPatientOnGrid && this.props.selectedPatientOnGrid.combinedLastContactDate != null && this.props.selectedPatientOnGrid.combinedLastContactDate !== "" ? moment(new Date(this.props.selectedPatientOnGrid.combinedLastContactDate)).format("MM/DD/YYYY") : ""}</label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label>Patient Status: {this.props.selectedPatientOnGrid != null ? this.props.selectedPatientOnGrid.combinedLastPtStatus : ""}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <Footer showNavigation={false}
                        fieldInFocus={this.state.fieldInFocus}
                        closeWindow={this.props.closeWindow}
                        _refreshForm={this.resetPatient}
                        formId="patientForm" isFormDirty={this.state.isFormDirty}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">Patient data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {transmitTextDialog}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationStateMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCountyMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCityMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationSeqPrimMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasErrorOccurred}
                </React.Fragment>
                <React.Fragment>
                    {deletionMsg}
                </React.Fragment>

                <React.Fragment>
                    {patientMaintenance}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PatientMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedPatientOnGrid: state.patientAbstract.selectedPatientOnGrid,
        selectedPatient: state.patientAbstract.selectedPatient,
        isPatientLoading: state.patientAbstract.isPatientLoading,
        isAddNew: state.patientAbstract.isAddNew,
        patientId: state.patientAbstract.patientId,
        isFormDirty: state.patientAbstract.isFormDirty,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        // state specific screen
        currentShowingState: state.patientAbstract.currentShowingState,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        securityGroup: state.auth.securityGroup,
        savedPatientMaitenance: state.patientAbstract.savedPatientMaitenance,

        //
        //isRapidAbstract: state.patientAbstract.rapidAbstract,//Rapid_Abstract
        isInquiringMode: state.auth.inquiringMode,//FN_InquiryMode
        inquiringMode: state.auth.inquiringMode,
        //rapidAbstract: state.patientAbstract.rapidAbstract,
        alphaUrl: window.location ? window.location.pathname : "",
        availableHospsData: state.auth.availableHospsData,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectMedicalRecord: (medicalRecord, patientId) => dispatch(actionCreators.selectMedicalRecord(medicalRecord, patientId)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        savePatient: (patientData, isAddNew, patientId, moveToNextPage) => dispatch(actionCreators.savePatient(patientData, isAddNew, patientId, moveToNextPage)),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        deletePrimaryItem: (pItem, text) => dispatch(actionCreators.deletePrimaryItem(pItem, text)),

        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        selectPatientLookupView: (patientId) => dispatch(actionCreators.selectPatientLookupView(patientId)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
        showingMessagePatientAbstracts: (err, code, msg) => dispatch(actionCreators.showingMessagePatientAbstracts(err, code, msg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientMaintenance);