/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../TreatmentMaintenance.css";
import "../SurgeryMaintenance/SurgeryMaintenance.css";
import "./HematopoieticMaintenance.css";
import moment from "moment";
import Help from "../../../../UI/Help";
import Draggable from 'react-draggable';
import * as Global from "../../../../../store/Global";

class HematopoieticMaintenance extends Component {

    state = {
        dAllItemFields: null,

        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'surgeryForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty surgeryForm back to what it was on load
        initialSurgeryForm: null,
        surgeryForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "hemaUser1",
        isHelpVisible: false,
        focusingField: null,

        primaryItemFields: null,
        primaries: null,
        initialPrimaries: null,
        isPrimaryDirty: false,
        primTopo: null,
        primSite: null,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,
        isSaving: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        allowSaveAnyway: true,

        primariesMaxlengthInput: null,

        selectedRowIndex: -1,

        isShowAlertMsg: false,
        rowIndex: 0,
    }

    setSelectionRow = (event, index) => {
        this.setState({ selectedRowIndex: index });
    }

    componentDidMount() {
        if (!this.state.surgeryForm && this.props.selectedSurgeryRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.surgeryForm && this.props.selectedSurgeryRecord) || (prevProps.selectedSurgeryRecord && prevProps.selectedSurgeryRecord !== this.props.selectedSurgeryRecord)) {
            this.setFormData();
        }

        if (this.state.isFormDirty && this.state.isSaving && prevProps.isFormDirty != this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }

        if (this.props.selectedPrimarySummary && !this.state.primaries) {
            axios.post(this.props.clientgroup + "/GetRxHematoMvList", { primarySummaryId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ primaries: res.data.rxHematoMvList, initialPrimaries: res.data.rxHematoMvList });
                        this.setState({ surgeryForm: { ...this.state.surgeryForm, primarySummary: res.data.rxHematoMvList } });

                        // Primary List
                        if (!this.state.primaryItemFields && res.data.rxHematoMvList) {
                            let primariesArr = [];
                            res.data.rxHematoMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                primariesArr.push(fields);
                            });

                            this.setState({ primaryItemFields: primariesArr });

                            // get maxlength for Primary grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Rx_Hemato_Mv", accessToken: localStorage.getItem('token')})
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = primariesArr && primariesArr[0] ? primariesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            primariesMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.surgeryForm && this.state.dAllItemFields
        ) {
            console.log("[Surgery] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        surgeryForm: { ...this.state.surgeryForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        surgeryForm: { ...this.state.surgeryForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Rx_Summary", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        this.setState({
            surgeryForm: { ...this.props.selectedSurgeryRecord },
            isFormDirty: this.props.isFormDirty,

            primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : ""
        });

        if (this.props.selectedPrimarySummary) {
            this.setState({
                primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "",
                primSite: this.props.selectedMedicalRecord.topoCode ? this.props.selectedMedicalRecord.topoCode.substring(2) : "",
            });
        }

        if (!this.state.dAllItemFields && this.props.selectedSurgeryRecord) {
            let fields = {};
            Object.keys(this.props.selectedSurgeryRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveSurgery = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        var allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (isValid) {
            let pItems = this.state.primaryItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "hemaStartDate" || field === "hemaEndDate") {
                        this.clearInvalidOfDateFields(prm, field, "primaryItemList", index);
                    }
                    /*
                    let val = this.state.primaries[index][field];
                    let hemaFsFlag = this.state.primaries[index]["hemaFsFlag"];
                    if (((field === "hemaStartDateYear" || field === "hemaStartDateMonth" || field === "hemaStartDateDay" || field.startsWith("hemaStartDate")) && val !== null && val !== undefined && val.toString() !== "Invalid Date" && (hemaFsFlag === null || hemaFsFlag === ""))) {
                        // update status invalid on view
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            if (newItems[index]) {
                                newItems[index]["hemaFsFlag"] = "IN_VALID";
                            }
                            return { primaryItemFields: newItems };
                        });

                        isValid = false;
                        return;
                    }
                    */
                });
            });
        }

        let primaryList = this.state.primaries;
        var rowIdx = 0;
        var isValidFsFlag = true;
        primaryList.map((obj, index) => {
            if (obj["hemaStartDateYear"] && obj["hemaStartDateYear"] != ""
                && obj["hemaStartDateMonth"] && obj["hemaStartDateMonth"] != ""
                && obj["hemaStartDateDay"] && obj["hemaStartDateDay"] != ""
                && obj["hemaFsFlag"] != "F" && obj["hemaFsFlag"] != "S") {
                isValidFsFlag = false;
                rowIdx = index;
                return false;
            }
        });

        if (!isValidFsFlag /*&& !allowSaveAnyway*/) {
            /*
            alert('Must have a First Course/Subsequent flag if start date is present.');
            document.getElementById("hemaFsFlag" + rowIdx) ? document.getElementById("hemaFsFlag" + rowIdx).focus() : "";
            */
            this.setState({ isShowAlertMsg: true, rowIndex: rowIdx });
            this.props.setChildrenOpening(true);
            return false;
        } else {
            let surgeryFormData = this.state.surgeryForm;

            let primaryList = this.state.primaries;

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;

            primaryList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "hemaStartDate" || t === "hemaEndDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "primaryList", index);
                    }
                });
            });
            //#20210125 - END

            if (!isValidDateInput && !allowSaveAnyway) return false;

            surgeryFormData.rxHematoMv = primaryList;
            surgeryFormData.aliasForm = "Hema";

            this.props.saveSurgery(surgeryFormData);

            this.setState({
                isSaving: true,
            });

            return false;
        }
    }

    resetSurgery = () => {
        this.setState({
            surgeryForm: { ...this.state.initialSurgeryForm },
            isFormDirty: false,
            primaries: null,
            primaryItemFields: null,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        return false;
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            // check required fields input
            if (event.target.name === "suspenseRemarks" && event.target.value === "") {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                // check validation of date field input
            } else if (event.target._element && event.target._element.className === "k-widget k-datepicker" && event.target.dateInput._element.value !== "month/day/year") {
                if (event.target.dateInput._element.validity.valid) {
                    //this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
                }
            } else {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    handlePrimaryLostfocus = (event, index) => {
        var name = event.target.name;
        var value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            if (name === "hemaFsFlag" && value !== "") {
                var fsVal = value.toUpperCase();
                value = fsVal;
                this.setState(prevState => {
                    const newItems = [...prevState.primaries];
                    newItems[index][name] = fsVal;

                    const iFields = [...prevState.primaryItemFields];
                    iFields[index][name] = "";
                    return {
                        primaries: newItems,
                        primaryItemFields: iFields
                    };
                });
            }

            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "";
            this.props.checkValidInput(name, value, table, key, index, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.primaries];
                        newItems[index][name] = value;
                        return { primaries: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { primaryItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "";
                    return { primaryItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.primaryItemFields];
                newItems[index][name] = "";
                return { primaryItemFields: newItems };
            });
        }
    }

    handlePrimaryGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            newItems[index][name] = value;
            newItems[index]["isPrimaryDirty"] = true;
            return { primaries: newItems, isFormDirty: true };
        });
    }

    addNewPrimary = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.primaries];
            const firstPrimary = newItems[0];

            const newPrimary = {};
            Object.keys(firstPrimary).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newPrimary[t] = 0;
                } else {
                    newPrimary[t] = "";
                }
            });
            newItems.push(newPrimary);

            // 
            const newFields = [...this.state.primaryItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { primaries: newItems, primaryItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedItemPrimary = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.primaries.length) {
            selectedRow = this.state.primaries.length - 1;
        }

        if (this.state.primaries[selectedRow] && this.state.primaries[selectedRow]["id"] !== undefined && this.state.primaries[selectedRow]["id"] !== 0) {
            this.props.setChildrenOpening(true);
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "hema" });
        } else {
            this.deleteItemOnGrid();
        }
        return false;
    }

    deleteItemOnGrid = () => {
        let selectedRow = this.state.selectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.primaries.length) {
            selectedRow = this.state.primaries.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            if (newItems.length === 1) {
                const _newItems = [...this.state.primaries];
                const firstPrim = _newItems[0];

                const newPrim = {};
                Object.keys(firstPrim).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newPrim[t] = 0;
                    } else {
                        newPrim[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newPrim);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.primaryItemFields];
            if (newFields.length == 1) {
                const _newFields = [...this.state.primaryItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { primaries: newItems, primaryItemFields: newFields, selectedRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowConfirmDeleteMsg: false });

        this.props.setChildrenOpening(false);

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "hema":
                    this.deleteItemOnGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    //
    hideAlertMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowAlertMsg: false });
        this.props.setChildrenOpening(false);

        var rowIdx = this.state.rowIndex;

        setTimeout(function () {
            document.getElementById("hemaFsFlag" + rowIdx) ? document.getElementById("hemaFsFlag" + rowIdx).focus() : "";
        }, 100);

        return false;
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let alertMsg;
        {
            alertMsg = this.state.isShowAlertMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideAlertMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Must have a First Course/Subsequent flag if start date is present.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideAlertMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const surgeryMaintenance = this.state.surgeryForm && !this.props.isChildrenLoading ?
            (
                <div className="container-fluid">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveSurgery} id="surgeryForm" className="DialogForm PopupChildrenPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}>
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="frameInner" style={{ width: "" }}>
                            <div className="header">&nbsp;</div>
                            <div className="emptyLine"></div>
                            <div className="" style={{ display: "flex" }}>
                                <div className="">
                                    <div className="" style={{ display: "flex" }}>
                                        <div className="" style={{ width: "255px" }}>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText={this.state.surgeryForm.hemaUserLblText1 ? this.state.surgeryForm.hemaUserLblText1 : "Hema 1"}//labelText="Hema 1"
                                                field="hemaUser1" lineStyle="oneLine" autoFocus
                                                value={this.state.surgeryForm.hemaUser1 ? this.state.surgeryForm.hemaUser1 : this.state.surgeryForm.hemaUser1}
                                                dataSource="Global_Dir_Mv" aliasNo="3701" autoFocus
                                                titleDialog="Hema User 1" labelWidth={Global.LabelWidth} width="100px"
                                                inValid={this.state.dAllItemFields["hemaUser1"]} itemNbr=""
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hemaUser1"] : ""}
                                                flag="ERS_UserDefinedMaintenance"
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText={this.state.surgeryForm.hemaUserLblText2 ? this.state.surgeryForm.hemaUserLblText2 : "Hema 2"}//labelText="Hema 2"
                                                field="hemaUser2" lineStyle="oneLine"
                                                value={this.state.surgeryForm.hemaUser2 ? this.state.surgeryForm.hemaUser2 : this.state.surgeryForm.hemaUser2}
                                                dataSource="Global_Dir_Mv" aliasNo="3702"
                                                titleDialog="Hema User 2" labelWidth={Global.LabelWidth} width="100px"
                                                inValid={this.state.dAllItemFields["hemaUser2"]} itemNbr=""
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hemaUser2"] : ""}
                                                flag="ERS_UserDefinedMaintenance"
                                            />
                                        </div>
                                        <div className="" style={{ width: "495px" }}>
                                            <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Hema / Other / Palliative Text"
                                                field="hemaText" lineStyle="oneLine"
                                                value={this.state.surgeryForm.hemaText}
                                                labelWidth={Global.LabelWidth} width="350px" height="100px" rows="3" itemNbr=""
                                                hasDoubleClickEvent="warning" maxlen="1000" />
                                        </div>

                                        <div className="" style={{ width: "225px", /*position: "absolute", right: "30px"*/ }}>
                                            <div className="labelAreaCls">
                                                <div className="col-md-12">
                                                    <Input type="label" labelWidth={Global.LabelWidth} labelText="Start Date Sum"
                                                        field="hemaDateSumm"
                                                        value={this.state.surgeryForm.hemaDateSumm !== null && this.state.surgeryForm.hemaDateSumm !== "" ? moment(new Date(this.state.surgeryForm.hemaDateSumm)).format("MM/DD/YYYY") : ""}
                                                    />
                                                    <Input type="label" labelWidth={Global.LabelWidth} labelText="Hema Code"
                                                        field="hemaCode" value={this.state.surgeryForm.hemaCode} />
                                                    <Input type="label" labelWidth={Global.LabelWidth} labelText="Days From Dx"
                                                        field="daysDxToHema" value={this.state.surgeryForm.daysDxToHema} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="emptyLine"></div>
                                    <br /><br /><br />

                                    <div className="">
                                        <div className="">
                                            <div className="primaryListArea">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "15px" }}>&nbsp;</th>
                                                            <th style={{ width: "133px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Start Date" field="hemaStartDate"
                                                                />
                                                            </th>
                                                            <th style={{ width: "131px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="End Date" field="hemaEndDate"
                                                                />
                                                            </th>
                                                            <th style={{ width: "60px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Hema" field="hemaTransEndo"
                                                                />
                                                            </th>
                                                            <th style={{ width: "60px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Comp St" field="hemaCompStatus"
                                                                />
                                                            </th>
                                                            <th style={{ width: "70px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Rsn Stop" field="hemaRsnStop"
                                                                />
                                                            </th>
                                                            <th style={{ width: "60px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="F/S Flag" field="hemaFsFlag"
                                                                />
                                                            </th>
                                                            <th style={{ width: "105px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Physician" field="hemaPhysician"
                                                                />
                                                            </th>
                                                            <th style={{ width: "100px" }}>
                                                                <Input type="gridHeader" labelWidth="auto"
                                                                    labelText="Hospital" field="hemaRxHosp"
                                                                />
                                                            </th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                        <tr>
                                                            <td colSpan="10">&nbsp;</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="innerBody" style={{ overflow: "auto", width: "776px", height: "185px" }}>
                                                    <table>
                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                            <tr>
                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                <th style={{ width: "133px" }}>Start Date</th>
                                                                <th style={{ width: "131px" }}>End Date</th>
                                                                <th style={{ width: "60px" }}>Hema</th>
                                                                <th style={{ width: "60px" }}>Comp St</th>
                                                                <th style={{ width: "70px" }}>Rsn Stop</th>
                                                                <th style={{ width: "60px" }}>F/S Flag</th>
                                                                <th style={{ width: "105px" }}>Physician</th>
                                                                <th style={{ width: "100px" }}>Hospital</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.primaryItemFields
                                                                && this.state.primaries !== null && this.state.primaries.length > 0
                                                                ? this.state.primaries.map((prm, index) => (
                                                                    <tr onClick={(e) => { this.setSelectionRow(e, index) }} className={this.state.selectedRowIndex === index ? "selectedRowCls" : ""}>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td>
                                                                            <Input type="date" focused={this.handleOnFocus}
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                                labelText="" field="hemaStartDate" lineStyle="oneLine"
                                                                                value={prm.hemaStartDate != null && prm.hemaStartDate !== "" ? moment(new Date(prm.hemaStartDate)).format("MM/DD/YYYY") : ""}
                                                                                rowIndex={index}//keyId={"hemaStartDate_" + index}
                                                                                labelWidth="0px" monthWidth="33px" dayWidth="30px" yearWidth="50px"
                                                                                //#20210125 - START
                                                                                // Add day/month/year values
                                                                                inValidDay={this.state.primaryItemFields[index]["hemaStartDateDay"]}
                                                                                inValidMonth={this.state.primaryItemFields[index]["hemaStartDateMonth"]}
                                                                                inValidYear={this.state.primaryItemFields[index]["hemaStartDateYear"]}
                                                                                dayVal={prm.hemaStartDateDay}
                                                                                monthVal={prm.hemaStartDateMonth}
                                                                                yearVal={prm.hemaStartDateYear}
                                                                                //#20210125 - END
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="date" focused={this.handleOnFocus}
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                                labelText="" field="hemaEndDate" lineStyle="oneLine"
                                                                                value={prm.hemaEndDate != null && prm.hemaEndDate !== "" ? moment(new Date(prm.hemaEndDate)).format("MM/DD/YYYY") : ""}
                                                                                rowIndex={index}//keyId={"hemaEndDate_" + index}
                                                                                labelWidth="0px" monthWidth="33px" dayWidth="30px" yearWidth="50px"
                                                                                //#20210125 - START
                                                                                // Add day/month/year values
                                                                                inValidDay={this.state.primaryItemFields[index]["hemaEndDateDay"]}
                                                                                inValidMonth={this.state.primaryItemFields[index]["hemaEndDateMonth"]}
                                                                                inValidYear={this.state.primaryItemFields[index]["hemaEndDateYear"]}
                                                                                dayVal={prm.hemaEndDateDay}
                                                                                monthVal={prm.hemaEndDateMonth}
                                                                                yearVal={prm.hemaEndDateYear}
                                                                                //#20210125 - END
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                                labelText="" field="hemaTransEndo" value={prm.hemaTransEndo} keyId={"hemaTransEndo_" + index}
                                                                                dataSource="Global_Dir_Mv" aliasNo="2124"
                                                                                titleDialog="Hema Trans Endo" lineStyle="oneLine"
                                                                                labelWidth="0px" width="40px"
                                                                                inValid={this.state.primaryItemFields[index]["hemaTransEndo"]}
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                                //maxLength="2"
                                                                                maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hemaTransEndo"] : ""}
                                                                                keyId={"hemaTransEndo" + index}
                                                                                rowIndex={index}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }} keyId={"hemaCompStatus" + index}
                                                                                labelText="" field="hemaCompStatus" value={prm.hemaCompStatus}
                                                                                dataSource="Global_Dir_Mv" titleDialog="Hema Comp Status" aliasNo="3687"
                                                                                labelWidth="0px" width="40px"
                                                                                inValid={this.state.primaryItemFields[index]["hemaCompStatus"]}
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                                //maxLength="2"
                                                                                maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hemaCompStatus"] : ""}
                                                                                keyId={"hemaCompStatus" + index}
                                                                                rowIndex={index}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                                labelText="" field="hemaRsnStop" value={prm.hemaRsnStop} keyId={"hemaRsnStop" + index}
                                                                                dataSource="Global_Dir_Mv" titleDialog="Hema Rsn Stop" aliasNo="3682"
                                                                                labelWidth="0px" width="50px"
                                                                                inValid={this.state.primaryItemFields[index]["hemaRsnStop"]}
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                                //maxLength="2"
                                                                                maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hemaRsnStop"] : ""}
                                                                                keyId={"hemaRsnStop" + index}
                                                                                rowIndex={index}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                                labelText="" field="hemaFsFlag" value={prm.hemaFsFlag} keyId={"hemaFsFlag" + index}
                                                                                dataSource="Global_Dir_Mv" titleDialog="Hema Fs Flag" aliasNo="3938"
                                                                                labelWidth="0px" width="40px"
                                                                                inValid={this.state.primaryItemFields[index]["hemaFsFlag"]}
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                                //maxLength="1"
                                                                                maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hemaFsFlag"] : ""}
                                                                                keyId={"hemaFsFlag" + index}
                                                                                rowIndex={index}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="Physician" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }} keyId={"hemaPhysician" + index}
                                                                                labelText="" field="hemaPhysician" value={prm.hemaPhysician}
                                                                                dataSource="Physician" titleDialog="Hema Physician" aliasNo="4507"
                                                                                labelWidth="0px" width="85px"
                                                                                inValid={this.state.primaryItemFields[index]["hemaPhysician"]}
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                                //maxLength="15"
                                                                                maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hemaPhysician"] : ""}
                                                                                keyId={"hemaPhysician" + index}
                                                                                rowIndex={index}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="Hospital" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                changed={(e) => { this.handlePrimaryGridChange(e, index) }} keyId={"hemaRxHosp" + index}
                                                                                labelText="" field="hemaRxHosp" value={prm.hemaRxHosp}
                                                                                dataSource="Hospital" titleDialog="Hospital Lookup" aliasNo="3939"
                                                                                labelWidth="0px" width="80px"
                                                                                inValid={this.state.primaryItemFields[index]["hemaRxHosp"]}
                                                                                lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                                //maxLength="15"
                                                                                maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["hemaRxHosp"] : ""}
                                                                                keyId={"hemaRxHosp" + index}
                                                                                rowIndex={index}
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                )) : <tr><td colSpan="10">No records available</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="contactBtnArea right" style={{ width: "776px" }}>
                                                    <button onClick={this.addNewPrimary}>Add</button>
                                                    <button onClick={this.deleteSelectedItemPrimary}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetSurgery} formId="surgeryForm"
                        isFormDirty={this.state.isFormDirty}
                        isDisabledOnChildren={true}
                        isChildren={true}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p>Hematopoietic data loading</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>
                <React.Fragment>
                    {alertMsg}
                </React.Fragment>
                <React.Fragment>
                    {surgeryMaintenance}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

HematopoieticMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedSurgeryRecord: state.patientAbstract.selectedSurgeryRecord,
        isChildrenLoading: state.patientAbstract.isChildrenLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem2,//#2
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,

        isFormDirty: state.patientAbstract.isFormDirty,
        childrenOpening: state.patientAbstract.childrenOpening,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveSurgery: (surgeryData) => dispatch(actionCreators.saveSurgery(surgeryData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem2()),//#2
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HematopoieticMaintenance);