/* eslint-disable */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import ConfigData from '../config.json';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
        error: null
    };
};

export const showMultiGroup = (list) => {
    return {
        type: actionTypes.MULTI_GROUP,
        userList: list
    };
};

export const _showMultiGroup = (list) => {
    return dispatch => {
        dispatch(showMultiGroup(list));
    }
};

export const authSuccess = (
    token, userId, hasChangePass, enable2fa,
    clientgroup, securityGroup,
    passValidation, passValidationMsg, lastAuth,
    lastResult, key2FA, expiresIn, expired,
    availableMenuGroups, customizations
) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        hasChangePass: hasChangePass,
        enable2fa: enable2fa,
        clientgroup: clientgroup,
        securityGroup: securityGroup,
        passValidation: passValidation,
        passValidationMsg: passValidationMsg,
        lastAuth: lastAuth,
        lastResult: lastResult,
        key2FA: key2FA,
        expiresIn: (expiresIn && expiresIn != '0' ? expiresIn : ConfigData.CRSTAR_SESSION_TIMEOUT),
        passExpired: expired,
        error: null,
        availableMenuGroups: availableMenuGroups,
        customizations: customizations,
    };
};

export const setInquiringMode = (flag) => {
    return {
        type: actionTypes.INQUIRING_MODE,
        inquiringMode: flag
    };
};

export const setAvailableHospsDataOfUser = (hosp) => {
    return {
        type: actionTypes.AVAILABLE_HOSPS_DATA_OF_USER,
        availableHospsData: hosp
    };
};
export const setCustomizationsOfUser = (data) => {
    return {
        type: actionTypes.CUSTOMIZATIONS_OF_USER,
        customizations: data
    };
};

export const authFail = (error) => {
    console.log(error);
    return {
        type: actionTypes.AUTH_FAIL,
        error: error && error.data !== "" ? (error.data ? error.data : error.message) : "Error: Network Error"
    };
};

export const doSubmitSuccess = (email) => {
    return {
        type: actionTypes.DO_SUCCESS,
        isSubmitForm: true,
        loading: true
    };
};

export const doSubmitFail = (error) => {
    return {
        type: actionTypes.DO_FAIL,
        error: error,
        isSubmitForm: false
    };
};

export const logout = (email, cg, timeout, msg) => {
    /*
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('crstar_user');
    localStorage.removeItem('user_hasChangePass');
    localStorage.removeItem('clientAlias');
    localStorage.removeItem('identificationSystemAlive');
    */
    var okta = localStorage.getItem('okta-token-storage');

    sessionStorage.clear();
    localStorage.clear();

    sessionStorage.setItem('CrstarLogout', true);
    if (okta) {
        localStorage.setItem('okta-token-storage', okta);
    }

    let url = ConfigData.CRStarAPI_URL + "ers/logout";
    const authData = {
        email: email,
        clientGroup: cg,
        sessionTimeout: timeout,
        message: msg ? msg : ""
    };

    axios.post(url, authData)
        .then(response => {
            console.log(response);
        })
        .catch(err => {
            console.log(err);
        });

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime, cg, email) => {
    return dispatch => {
        setTimeout(() => {
            //dispatch(logout(email, cg, expirationTime));
            //console.log("[auth::checkAuthTimeout] Logged out...");
        }, expirationTime * 1000);
    };
};

export const auth = (email, password, isSignup) => {
    return dispatch => {
        dispatch(authStart());

        /*
        var localDatetime = new Date();
        var timezoneOffset = localDatetime.getTimezoneOffset();

        const authData = {
            Email: email,
            Password: password,
            returnSecureToken: true,
            timezoneOffset: timezoneOffset,
        };

        /* Fixing #PR00463 - Comment out these lines to avoid the error messages in database
        let cg = null;
        try {
            cg = email.split("@")[1];
            if (cg.indexOf(".") !== -1) {
                cg = cg.split(".")[0];
            }
        } catch (ex) { }
        if (cg !== null && cg !== "") {
            let link = ConfigData.CRStarAPI_URL + cg + "/preAuth";
            axios.post(link, authData)
                .then(response => {
                    return false;
                })
                .catch(err => {
                });
        }
        */
        let clientgroup = email ? email.split("@")[1].substr(0, email.split("@")[1].indexOf(".")) : "ERS";
        let combineStr = email + ":" + clientgroup + ":" + password;
        var authData = {
            combStr: base64_encode(combineStr)
        };

        let url = ConfigData.CRStarAPI_URL + "ers/authenticate";
        axios.post(url, authData)
            .then(response => {
                if (response.data.username === "" || response.data.username === null
                    || response.data.error) {
                    dispatch(authFail({ data: response.data.message && response.data.message !== "" ? response.data.message : "User ID or password is incorrect" }));

                    dispatch(setAuthRedirectPath('/change-pwd'));//#PR00524
                    return false;
                }

                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 60 * 1000);
                localStorage.setItem('token', response.data.acessToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.username);
                localStorage.setItem('clientAlias', response.data.clientgroup);

                //
                localStorage.setItem('crstar_user', JSON.stringify(response.data));

                dispatch(
                    authSuccess(
                        response.data.idToken,
                        response.data.username,
                        response.data.hasChangePass,
                        response.data.enable2fa,
                        response.data.clientgroup,
                        response.data.securityGroup,
                        response.data.passValidation,
                        response.data.passValidationMsg,
                        response.data.lastAuth,
                        response.data.lastResult,
                        response.data.key2FA,
                        response.data.expiresIn,
                        response.data.expiresIn === 0 ? true : false,
                        response.data.availableMenuGroups,
                        response.data.customizations,
                    )
                );

                dispatch(setInquiringMode(response.data.securityGroup === 'Read-only user' /*|| (response.data.availableHospsData && response.data.availableHospsData.indexOf() != -1)*/));
                dispatch(setAvailableHospsDataOfUser(response.data.availableHospsData));
                dispatch(setCustomizationsOfUser(response.data.customizations));
                //dispatch(checkAuthTimeout(response.data.expiresIn * 60, response.data.clientgroup, response.data.username));

                debugger
                // check loged on status to set direct path
                if (response.data.hasChangePass == null || response.data.hasChangePass == "") {
                    dispatch(setAuthRedirectPath('/change-pwd'));
                } else if (response.data.enable2fa === "1" && (response.data.lastAuth === null || response.data.lastResult === "All")) {
                    if (response.data.enable2fa === "1") {
                        dispatch(setAuthRedirectPath('/two-factor-auth'));
                    } else {
                        dispatch(setAuthRedirectPath('/alpha-dashboard'));
                    }
                } else {
                    dispatch(setAuthRedirectPath('/alpha-dashboard'));
                }

                sessionStorage.removeItem('CrstarLogout');

                return false;
            })
            .catch(err => {
                dispatch(authFail(err));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        //const clientgroup = sessionStorage.getItem('clientgroup');

        if (!token) {
            //dispatch(logout(userId));
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));

            if (expirationDate <= new Date()) {
                dispatch(logout(userId));
            } else {
                const user = JSON.parse(localStorage.getItem('crstar_user'));

                var user_hasChangePass = localStorage.getItem('user_hasChangePass');
                if (user_hasChangePass && user_hasChangePass == 'right!') {
                    user_hasChangePass = true;
                } else {
                    user_hasChangePass = user.hasChangePass;
                }

                const newExpirationDate = new Date(new Date().getTime() + user.expiresIn * 60 * 1000);
                localStorage.setItem('expirationDate', newExpirationDate);

                dispatch(
                    authSuccess(
                        user.idToken,
                        user.username,
                        user_hasChangePass,
                        user.enable2fa,
                        user.clientgroup,
                        user.securityGroup,
                        user.passValidation,
                        user.passValidationMsg,
                        user.lastAuth,
                        user.lastResult,
                        user.key2FA,
                        user.expiresIn,
                        user.expiresIn === 0 ? true : false,
                        user.availableMenuGroups,
                        user.customizations,
                    )
                );

                dispatch(setInquiringMode(user.securityGroup === 'Read-only user'));
                dispatch(setAvailableHospsDataOfUser(user.availableHospsData));
                dispatch(setCustomizationsOfUser(user.customizations));
                
                //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000), user.clientgroup, userId);

                dispatch(setAuthRedirectPath('/alpha-dashboard'));

                sessionStorage.removeItem('CrstarLogout');

                return false;
            }
        }
    };
};

export const forgotPassword = (email) => {
    return dispatch => {
        const authData = {
            Email: email
        };

        let url = ConfigData.CRStarAPI_URL + "ers/forgotpassword";

        axios.post(url, authData)
            .then(response => {
                dispatch(doSubmitSuccess(email));
            })
            .catch(err => {
                dispatch(doSubmitFail(err.response ? err.response.data : err));
            });
    };
};

export const oktaLoginSuccess = (user) => {
    return dispatch => {
        const newExpirationDate = new Date(new Date().getTime() + user.expiresIn * 60 * 1000);
        localStorage.setItem('expirationDate', newExpirationDate);

        dispatch(
            authSuccess(
                user.idToken,
                user.username,
                false,//user_hasChangePass,
                user.enable2fa,
                user.clientgroup,
                user.securityGroup,
                user.passValidation,
                user.passValidationMsg,
                user.lastAuth,
                user.lastResult,
                user.key2FA,
                user.expiresIn,
                user.expiresIn === 0 ? true : false,
                user.availableMenuGroups,
                user.customizations,
            )
        );

        dispatch(setInquiringMode(user.securityGroup === 'Read-only user'));
        dispatch(setAvailableHospsDataOfUser(user.availableHospsData));
        dispatch(setCustomizationsOfUser(user.customizations));

        dispatch(setAuthRedirectPath('/alpha-dashboard'));
    }
}